import React, { useState, useEffect, useCallback } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, isObject, isNumber } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button, message, TimePicker, Tag, Menu, Input } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { BaseUrls } from '../../app/Constants';
import {
  Defaults,
  getLoadStatus,
  GlobalParse,
  CellRenderers,
  EventRenderers,
  buildUrl,
  sleep,
  parseJSON,
  LogRenderers,
} from '../../../common/utils';
import {
  GoodIcon,
  GoodOutlinedIcon,
  IncidentPredictIcon,
  ChangeEventIcon,
  RootCauseIcon,
  FlagNewIcon,
  SunnyIcon,
  RainingIcon,
} from '../../../lib/fui/icons';
import {
  Modal,
  Container,
  AutoSizer,
  List,
  CellMeasurerCache,
  CellMeasurer,
  Popover,
  Dropdown,
} from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import { EChart } from '../../share';

import { appMessages, appFieldsMessages, appButtonsMessages, appMenusMessages } from '../../../common/app/messages';
import { eventMessages, eventActionMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';

import RenderLeadToIncident from './RenderLeadToIncident';
import TimeSelectModal from '../../metric/components/TimeSelectModal';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import TriageReportModal from './TriageReportModal';
import ReportJiraModal from '../../metric/components/ReportJiraModal';
import ReportServiceNowModal from '../../metric/components/ReportServiceNowModal';
import LikelyRootCauses from './LikelyRootCauses';
import ProjectSelectorModal from './ProjectSelectorModal';
import EventActionModal from '../../metric/components/EventActionModal';
import InsightQueryBoxModal from './InsightQueryBoxModal';
import fetchGet from '../../../common/apis/fetchGet';
import LikelyRootCausesTableRCA from './LikelyRootCausesTableRCA';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  currentLocale: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  push: Function,
  loadStatus: Object,
  // eslint-disable-next-line
  timezoneOffset: Number,
  // eslint-disable-next-line
  userName: String,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  systemsMap: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  customerName: String,
  environmentId: String,
  systemId: String,
  // eslint-disable-next-line
  startTime: Number,
  // eslint-disable-next-line
  endTime: Number,
  intervalInMinutes: Number,
  systemTimestamp: Number,
  queryStartTime: Number,
  queryEndTime: Number,
  seriesId: String,
  // eslint-disable-next-line
  timelines: Array<Object>,
  // eslint-disable-next-line
  selectInstance: String,
  onClose: Function,

  globalInfo: Object,
  globalSystemLevelTimelines: Array<Object>,

  // eslint-disable-next-line
  rankMap: Object,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  currentTheme: String,
};

class EventShortListModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);
    const { selectInstance, intervalInMinutes, systemTimestamp, queryStartTime, queryEndTime } = props;

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.dataLoader = 'dashboard_global_health_chart_events';
    this.healthChartInterval = intervalInMinutes * 60 * 1000;
    this.eventListInterval = 24 * 60 * 60 * 1000;
    this.pieSize = 140;

    const dates =
      queryStartTime && queryEndTime
        ? [moment.utc(queryStartTime), moment.utc(queryEndTime)]
        : [
            moment.utc(systemTimestamp - (intervalInMinutes + 10) * 60 * 1000),
            moment.utc(systemTimestamp + (intervalInMinutes + 10) * 60 * 1000),
          ];

    this.state = {
      reloadSystem: null,
      isLoading: false,
      isLoadingTimelines: false,

      dates,
      startTimeObj: dates[0],
      endTimeObj: dates[1],

      filterEventList: [],
      ignoreFilter: true,
      optionTimelineRange: undefined,

      // parserDataOld
      anomalyFilter: 'anomalyInstances',
      instanceFilter: selectInstance || null,
      instanceComponentList: [],
      events: [],
      typeFilter: null,
      localEvents: [],
      showDetailsModal: false,
      showTriageReportModal: false,
      showTakeLogActionModal: false,
      showProjectSelector: false,
      onConfirmProjectSelect: null,
      showEventActionModal: false,
      showInsightQueryBox: false,
      queryLogProjects: [],
      queryParams: {},
      activeEvent: null,

      summarySettings: [],

      projectNameSearch: '',
      instanceNameSearch: '',
    };
    this.leadIncidentPatternNameStrMap = {};
  }

  componentDidMount() {
    const { environmentId, systemId, globalInfo } = this.props;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo && !systemInfo.hasAllInstanceInfo) {
      this.loadSystemData(this.props);
    } else {
      this.reloadData(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.reloadSystem !== prevState.reloadSystem) {
      this.reloadData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.globalSystemLevelTimelines !== this.props.globalSystemLevelTimelines ||
      this.props.seriesId !== nextProps.seriesId
    ) {
      this.parserGlobalSystemLevelTimelines(nextProps);
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  loadSystemData(props) {
    const { userInfo, createLoadAction } = props;
    const { customerName, environmentId, systemId, startTime } = props;
    createLoadAction(
      AppActionTypes.LOAD_INFO_SYSTEM,
      {
        startTime,
        endTime: startTime,
        customerName: userInfo.isAdmin ? customerName : userInfo.userName,
        systemName: systemId,
        environmentId,
        anomalyInstanceOnly: false,
      },
      this.dataLoader,
      true,
      this.callbackReloadData,
    );
  }

  @autobind
  callbackReloadData() {
    this.setState({ reloadSystem: moment.utc().valueOf() });
  }

  @autobind
  async reloadData(props) {
    const { userInfo, createLoadAction, customerName, environmentId, systemId, seriesId, timelines, globalInfo } =
      props;
    const { dates } = this.state;
    await sleep(300);

    if (((userInfo.isAdmin && customerName) || !userInfo.isAdmin) && environmentId && systemId) {
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      const anomalyType = ['incidentLine', 'predictionIncidentLine', 'fixedIncidentLine', 'actionLine'].includes(
        seriesId,
      )
        ? 'incident'
        : seriesId === 'deploymentLine'
        ? 'deployment'
        : 'all';

      if (timelines && timelines.length > 0) {
        if (seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine') {
          this.parserData({ props, anomalyTimelines: timelines, fromTimelines: true });
        } else {
          this.parserDataOld({ props, anomalyTimelines: timelines, fromTimelines: true });
        }
      } else {
        this.setState({ isLoadingTimelines: true });
        createLoadAction(
          ActionTypes.LOAD_GLOBAL_SYSTEM_LEVEL_TIMELINES,
          {
            level: 'system',
            environmentId,
            customerName: systemInfo.ownerUserName,
            systemIds: [systemId],
            startTime: dates[0].valueOf(),
            endTime: dates[1].valueOf(),
            anomalyType,
          },
          this.dataLoader,
          false,
          this.callbackSetState,
        );
      }
    }
  }

  @autobind
  callbackSetState(err) {
    this.setState({ isLoadingTimelines: false });
  }

  @autobind
  parserGlobalSystemLevelTimelines(props) {
    const { globalSystemLevelTimelines, systemId, seriesId } = props;
    const globalSystemLevelTimeline = R.find(
      (timeline) => timeline.level === 'system' && timeline.id === systemId,
      globalSystemLevelTimelines,
    );
    const { anomalyTimelines } = globalSystemLevelTimeline || {};
    if (seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine') {
      this.parserData({ props, anomalyTimelines });
    } else {
      this.parserDataOld({ props, anomalyTimelines });
    }
  }

  @autobind
  async parserData({ props, anomalyTimelines, fromTimelines }) {
    const {
      intl,
      rankMap,
      globalInfo,
      environmentId,
      systemId,
      userInfo,
      projects,
      selectAnomalyInstance,
      seriesId,
      currentTheme,
    } = props;
    const { sortBy, sortDirection, dates } = this.state;
    this.setState({ isLoading: true });
    await sleep(300);

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    let events = anomalyTimelines || [];

    const whitelistSamePatternMap = {};
    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    if (!fromTimelines) {
      events = R.filter((anomaly) => !anomaly.isIgnored, events);
      events = R.filter((anomaly) => {
        if (anomaly.startTimestamp !== anomaly.endTimestamp) {
          return anomaly.startTimestamp <= dates[1].valueOf() && dates[0].valueOf() <= anomaly.endTimestamp;
        }
        return dates[0].valueOf() <= anomaly.startTimestamp && anomaly.startTimestamp <= dates[1].valueOf();
      }, events);

      if (seriesId === 'fixedIncidentLine') {
        events = R.filter((event) => event.isFixedIncident, events);
      } else if (seriesId === 'actionLine') {
        events = R.filter((event) => R.toLower(event.type) === 'incident' && event.timeLineType === 'future', events);
      } else if (seriesId === 'incidentLine') {
        events = R.filter((event) => R.toLower(event.type) === 'incident' && event.timeLineType !== 'future', events);
      } else if (seriesId === 'predictionIncidentLine') {
        events = R.filter(
          (event) => R.toLower(event.type) === 'incident' && event.timeLineType === 'future' && !event.isFixedIncident,
          events,
        );
      } else if (seriesId === 'deploymentLine') {
        events = R.filter((event) => R.toLower(event.type) === 'deployment', events);
      } else if (seriesId === 'healthLine' || seriesId === 'predictionHealthLine') {
        events = R.filter(
          (event) =>
            R.toLower(event.type) !== 'deployment' &&
            !(R.toLower(event.type) === 'incident' && event.timeLineType === 'future'),
          events,
        );
      } else if (seriesId) {
        events = R.filter((event) => R.toLower(event.type) !== 'deployment', events);
      }
    }

    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];
      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const instanceName = pair.instanceId || pair.instanceName;
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.realInstanceName} (${componentName})`
            : item.realInstanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);
      const onlyInstanceString = get(item, ['containerInfo', 'instanceName'], item.realInstanceString);

      // get status
      const leadToIncident = get(item.rootCauseResultInfo, 'leadToIncident');
      const hasTrailing = get(item.rootCauseResultInfo, 'hasTrailingEvent');
      const causedByChange = get(item.rootCauseResultInfo, 'causedByChangeEvent');
      const hasRootCause = get(item.rootCauseResultInfo, 'hasPrecedingEvent');
      const isWhitelistSamePattern = item.typeList.includes('whitelist');

      // only for whitelist pattern
      const patternKey = `${item.projectName}-${item.instanceNameString}-${item.patternId}`;
      if (isWhitelistSamePattern) {
        whitelistSamePatternMap[patternKey] = item.avgAnomalyScore;
      }

      let rawDataJson;
      try {
        rawDataJson = JSON.parse(item.rawData);
        if (!isObject(rawDataJson)) rawDataJson = undefined;
      } catch (error) {
        // console.debug(error);
      }

      // get falg isTrace from event's project, build trace info
      const { projectOwner } = item;
      let { projectName } = item;
      projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
      const project = R.find((project) => projectName === project.projectName, projects || []);
      let { isTrace } = project || {};
      isTrace = isTrace && item.tabName === 'detectedAlerts';
      const traceId = get(rawDataJson, 'trace_id');
      let traceInfo;
      if (isTrace) {
        const traceId = get(rawDataJson, 'trace_id');
        const parentId = get(rawDataJson, 'parent_span_id');
        const spanId = get(rawDataJson, 'span_id');
        const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentNameString;
        const name = get(rawDataJson, 'method_name');
        const startTime = get(rawDataJson, 'start_time');
        // use microseconds for timestamp and duration
        const duration = Number(get(rawDataJson, 'duration', 0)) * 1000;
        const timestamp = moment.utc(startTime).valueOf() * 1000;
        traceInfo = {
          traceId,
          parentId,
          id: spanId,
          name,
          timestamp,
          duration,
          serviceName,
          color: '#ffccc7',
          tags: {},
        };
      }

      // build id, and mergeKey
      const mergeKey =
        item.category === 'metric'
          ? `${item.projectName}-${item.instanceNameString}-${item.patternId}-${
              item.isIncident ? 'incident' : 'notIncident'
            }-${item.metricNameString}`
          : isTrace
          ? `${item.projectName}-${item.instanceNameString}-${traceId}` // merge by traceId for trace data
          : `${item.projectName}-${item.instanceNameString}-${item.patternId}`;

      return {
        ...item,
        mergeKey,
        patternKey,
        rawDataJson,
        rootCauseJson: { rootCauseDetailsArr },
        appName,
        componentNameString,
        onlyInstanceString,
        instanceAppNames,
        componentList,
        timeRangeKey: `${item.startTimestamp}-${item.endTimestamp}`,
        leadToIncident,
        hasTrailing,
        causedByChange,
        hasRootCause,
        isWhitelistSamePattern,
        patternAndTrace: isTrace ? traceId : item.patternId,
        isTrace,
        traceId,
        traceInfo,
      };
    }, events);
    // reset rare event flag/avgScore if is also whitelist
    events = R.map((item) => {
      if (!item.isWhitelistSamePattern && R.has(item.patternKey, whitelistSamePatternMap)) {
        return { ...item, isWhitelistSamePattern: true, avgAnomalyScore: whitelistSamePatternMap[item.patternKey] };
      }
      return item;
    }, events);
    // filter selectAnomalyInstance or ignore
    if (selectAnomalyInstance) {
      events = R.filter((anomaly) => {
        return anomaly.instanceList.includes(selectAnomalyInstance);
      }, events);
    }

    // reset rangeStartTime
    const rangeStartTime = dates[0].valueOf();
    const rangeEndTime = dates[1].valueOf();

    // merge by mergeKey
    let eventList = this.mergeAnomalyEvents(props, events);

    // build timeline chart option
    const optionTimelineRange = GlobalParse.buildOptionTimelineRange({ rangeStartTime, rangeEndTime });
    eventList = R.map((item) => {
      const optionTimeline = GlobalParse.buildOptionTimeline({
        intl,
        rankMap,
        rangeStartTime,
        rangeEndTime,
        item,
        currentTheme,
      });
      return { ...item, optionTimeline };
    }, eventList);

    // sort
    const filterEventList = this.sortData(eventList, sortBy, sortDirection);
    if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.setState({
      isLoading: false,
      filterEventList,
      optionTimelineRange,
    });
  }

  @autobind
  parserDataOld({ props, anomalyTimelines, fromTimelines }) {
    const { anomalyFilter, dates } = this.state;
    let { instanceFilter } = this.state;
    const { globalInfo, environmentId, systemId, seriesId, selectInstance } = props;
    this.setState({ isLoading: true });

    // get all instance info and component info
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});

    // filter events
    let events = anomalyTimelines || [];
    if (!fromTimelines) {
      events = R.filter((anomaly) => !anomaly.isIgnored, events);
      events = R.filter((anomaly) => {
        if (anomaly.startTimestamp !== anomaly.endTimestamp) {
          return anomaly.startTimestamp <= dates[1].valueOf() && dates[0].valueOf() <= anomaly.endTimestamp;
        }
        return dates[0].valueOf() <= anomaly.startTimestamp && anomaly.startTimestamp <= dates[1].valueOf();
      }, events);

      if (seriesId === 'fixedIncidentLine') {
        events = R.filter((event) => event.isFixedIncident, events);
      } else if (seriesId === 'actionLine') {
        events = R.filter((event) => R.toLower(event.type) === 'incident' && event.timeLineType === 'future', events);
      } else if (seriesId === 'incidentLine') {
        events = R.filter((event) => R.toLower(event.type) === 'incident' && event.timeLineType !== 'future', events);
      } else if (seriesId === 'predictionIncidentLine') {
        events = R.filter(
          (event) => R.toLower(event.type) === 'incident' && event.timeLineType === 'future' && !event.isFixedIncident,
          events,
        );
      } else if (seriesId === 'deploymentLine') {
        events = R.filter((event) => R.toLower(event.type) === 'deployment', events);
      } else if (seriesId === 'healthLine' || seriesId === 'predictionHealthLine') {
        events = R.filter(
          (event) =>
            R.toLower(event.type) !== 'deployment' &&
            !(R.toLower(event.type) === 'incident' && event.timeLineType === 'future'),
          events,
        );
      } else if (seriesId) {
        events = R.filter((event) => R.toLower(event.type) !== 'deployment', events);
      }
    }

    // get all instances
    let hasAnomalyInstances = [];
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];
      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const realInstance = pair.instanceId || pair.instanceName;
          let instanceName = realInstance;
          if (pair.containerName && !realInstance.includes('_')) {
            instanceName = `${pair.containerName}_${realInstance}`;
          }
          const componentName =
            get(instanceComponentMap, realInstance) || get(instanceComponentMap, instanceName) || instanceName;
          componentList.push(componentName);
          const appName =
            componentName && componentName !== instanceName && componentName !== realInstance
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        const { realInstanceName } = item;
        const componentName =
          get(instanceComponentMap, realInstanceName) ||
          get(instanceComponentMap, item.instanceName) ||
          item.instanceName;
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== item.realInstanceName
            ? `${item.instanceName} (${componentName})`
            : item.instanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      // both container and host instance
      hasAnomalyInstances.push(item.instanceName);

      // reducer anomaly count
      let typeList = [];
      if (item.category === 'metric' && item.type !== 'Incident') {
        typeList = ['metricanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', item.type).split('&'));
      }

      return {
        ...item,
        rootCauseJson: { rootCauseDetailsArr },
        appName: R.join(', ', instanceAppNames),
        instanceAppNames,
        componentListStr: R.join(', ', componentList),
        componentList,
        typeList,
      };
    }, events);

    hasAnomalyInstances = R.uniq(hasAnomalyInstances);

    // build all instance info list
    let instanceContainerList = [];
    R.forEach((instance) => {
      const { containerList, id, componentId } = instance;

      // has anomaly instance
      const hasAnomaly = hasAnomalyInstances.includes(id);
      if (anomalyFilter !== 'anomalyInstances' || hasAnomaly) {
        instanceContainerList.push({
          type: 'instance',
          id,
          instanceId: id,
          componentId,
          hasAnomaly,
          isDefaultSelected: selectInstance && id === selectInstance,
        });
      }

      // has anomaly container
      R.forEach((container) => {
        const containerId = `${container.id}_${id}`;
        const hasAnomaly = hasAnomalyInstances.includes(containerId);
        if (anomalyFilter !== 'anomalyInstances' || hasAnomaly) {
          instanceContainerList.push({
            type: 'container',
            id: containerId,
            instanceId: id,
            componentId,
            hasAnomaly,
            isDefaultSelected: selectInstance && containerId === selectInstance,
          });
        }
      }, containerList);
    }, get(systemInfo, ['instanceList'], []));
    instanceContainerList = R.sortWith(
      [
        R.ascend(R.compose(R.toLower, R.prop('componentId'))),
        R.descend(R.prop('isDefaultSelected')),
        R.descend(R.prop('hasAnomaly')),
        R.ascend(R.compose(R.toLower, R.prop('id'))),
      ],
      instanceContainerList,
    );

    // build componentList and componentInstanceMap
    const componentInstanceMap = {};
    let instanceContainerOptions = [];
    R.forEach((item) => {
      if (!R.has(item.componentId, componentInstanceMap)) {
        componentInstanceMap[item.componentId] = [];
      }
      componentInstanceMap[item.componentId].push(item);
    }, instanceContainerList);
    const componentList = R.uniq(R.keys(componentInstanceMap));

    // build instanceContainerOptions
    R.forEachObjIndexed((componentId) => {
      const instanceContainers = componentInstanceMap[componentId] || [];
      instanceContainerOptions.push({
        type: 'component',
        id: componentId,
        instanceContainers,
        hasAnomaly: R.reduce(
          R.or,
          false,
          R.map((item) => item.hasAnomaly, instanceContainers),
        ),
        isDefaultSelected: R.reduce(
          R.or,
          false,
          R.map((item) => item.isDefaultSelected, instanceContainers),
        ),
      });
    }, componentList);
    instanceContainerOptions = R.sortWith(
      [
        R.descend(R.prop('isDefaultSelected')),
        R.descend(R.prop('hasAnomaly')),
        R.ascend(R.compose(R.toLower, R.prop('id'))),
      ],
      instanceContainerOptions,
    );

    // build instanceComponentList
    let instanceComponentList = [];
    R.forEachObjIndexed((item) => {
      const { instanceContainers, ...rest } = item;
      instanceComponentList.push({ ...rest });
      instanceComponentList = R.concat(instanceComponentList, instanceContainers);
    }, instanceContainerOptions);
    instanceComponentList = R.uniqBy((item) => item.id, instanceComponentList);

    // sort
    events = R.sortWith([R.ascend(R.prop('startTimestamp'))], events);

    // set defaul instanceFilter
    const anomalyInstanceItem = R.find((item) => item.type !== 'component' && item.hasAnomaly)(instanceComponentList);
    instanceFilter = instanceFilter || (anomalyInstanceItem ? anomalyInstanceItem.id : null);

    this.setState({ isLoading: false, instanceComponentList, events, instanceFilter }, () => {
      this.filterData(props, { ...this.state, events, instanceFilter });
    });
  }

  @autobind
  filterData(props, state) {
    const { credentials } = this.props;
    const { events, instanceFilter, typeFilter } = state;
    let filterEvents = events || [];

    if (instanceFilter) {
      filterEvents = R.filter((event) => {
        return event.instanceName === instanceFilter;
      }, filterEvents);
    }
    if (typeFilter) {
      filterEvents = R.filter((event) => event.typeList.includes(typeFilter), filterEvents);
    }
    if (filterEvents.length > 0) {
      const { projectOwner, category } = filterEvents[0];
      let { projectName } = filterEvents[0];
      projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
      if (!R.includes(category, ['metric'])) {
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        })
          .then((summarySettings) => {
            this.setState({ summarySettings });
          })
          .catch((err) => {
            this.setState({ summarySettings: [] });
          });
      }
    }

    this.setState({ localEvents: filterEvents });
  }

  @autobind
  filterIgnoreData(eventList) {
    const { ignoreFilter } = this.state;
    let filterList = eventList || [];
    if (ignoreFilter) {
      filterList = R.filter((event) => !event.isIgnored, filterList);
    }
    return filterList;
  }

  @autobind
  mergeAnomalyEvents(props, eventList) {
    let newEvents = [];

    const eventsMap = {};
    R.forEach((event) => {
      const {
        mergeKey,
        isNewAlert,
        isImportant,
        isIgnored,
        hasRootCause,
        hasTrailing,
        leadToIncident,
        causedByChange,
        typeList,
      } = event;
      if (!R.has(mergeKey, eventsMap)) {
        eventsMap[mergeKey] = {
          ...event,
          timelines: [event],
        };
      } else {
        eventsMap[mergeKey] = {
          ...eventsMap[mergeKey],
          typeList: R.uniq([...eventsMap[mergeKey].typeList, ...typeList]),
          isNewAlert: eventsMap[mergeKey].isNewAlert || isNewAlert,
          isImportant: eventsMap[mergeKey].isImportant || isImportant,
          isIgnored: eventsMap[mergeKey].isIgnored || isIgnored,
          hasRootCause: eventsMap[mergeKey].hasRootCause || hasRootCause,
          hasTrailing: eventsMap[mergeKey].hasTrailing || hasTrailing,
          leadToIncident: eventsMap[mergeKey].leadToIncident || leadToIncident,
          causedByChange: eventsMap[mergeKey].causedByChange || causedByChange,

          timelines: [...eventsMap[mergeKey].timelines, event],
        };
      }
    }, eventList || []);
    newEvents = R.values(eventsMap);

    // get overlall info
    newEvents = R.map((item) => {
      // uniq by time
      const timelines = R.uniqWith(R.eqBy(R.prop('timeRangeKey')), item.timelines || []);
      let allStartTimestamp = Infinity;
      let allEndTimestamp = 0;
      R.forEach((event) => {
        allStartTimestamp = R.min(allStartTimestamp, event.startTimestamp);
        allEndTimestamp = R.max(allEndTimestamp, event.endTimestamp);
      }, timelines);
      return { ...item, timelines, allStartTimestamp, allEndTimestamp };
    }, newEvents);

    return newEvents;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('avgAnomalyScore'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  handleTimeChange(category, timeObj) {
    if (category === 'startTime') {
      this.setState({ startTimeObj: timeObj });
    } else {
      this.setState({ endTimeObj: timeObj });
    }
  }

  @autobind
  scoreRender(rowData) {
    const { avgAnomalyScore, anomalyColor } = rowData;
    return (
      <Tag color={anomalyColor}>{numeral(avgAnomalyScore).format(avgAnomalyScore > 1 ? '0,0' : '0,0.[0000]')}</Tag>
    );
  }

  @autobind
  renderInstanceContent(cellData, rowData) {
    const { intl } = this.props;
    const { projectDisplayName, componentNameString, onlyInstanceString, containerName } = rowData;
    return (
      <Popover
        title={null}
        content={
          <div style={{ maxWidth: 350, maxHeight: 300, overflowY: 'auto' }}>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.project)}:
              </span>
              <span>{projectDisplayName}</span>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.component)}:
              </span>
              <span>{componentNameString}</span>
            </div>
            <div style={{ wordBreak: 'break-all' }}>
              <span className="light-label bold inline-block" style={{ width: 80 }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </span>
              <span>{onlyInstanceString}</span>
            </div>
            {containerName && (
              <div style={{ wordBreak: 'break-all' }}>
                <span className="light-label bold inline-block" style={{ width: 80 }}>
                  {intl.formatMessage(appFieldsMessages.container)}:
                </span>
                <span>{containerName}</span>
              </div>
            )}
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <span className="max-width inline-block hidden-line-with-ellipsis">{cellData}</span>
      </Popover>
    );
  }

  @autobind
  renderTrace(rowData) {
    const { intl } = this.props;
    return (
      <Popover
        title={null}
        content={`${intl.formatMessage(DashboardMessages.detectedTrace)}: ${rowData.traceId}`}
        mouseEnterDelay={0.3}
        placement="right"
      >
        <span className="max-width inline-block hidden-line-with-ellipsis">{rowData.traceId}</span>
      </Popover>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { patternNameStr } = Defaults.PatternIdNameStr(
      { patternName: rowData.patternName, patternId: rowData.patternId },
      { hasFullName: true },
    );
    return (
      <Popover title={null} content={patternNameStr} mouseEnterDelay={0.3} placement="right">
        <span className="max-width inline-block hidden-line-with-ellipsis">{rowData.patternId}</span>
      </Popover>
    );
  }

  @autobind
  renderCategory(rowData) {
    const { intl } = this.props;
    const { typeList, category, isNewAlert, tabName } = rowData;
    return (
      <>
        {category === 'log' &&
          R.map((type) => {
            return CellRenderers.logShortTypeRenderer({ intl, type });
          }, typeList)}
        {category === 'metric' && CellRenderers.logShortTypeRenderer({ intl, type: 'metric' })}
        {isNewAlert && (
          <Popover
            placement="top"
            mouseEnterDelay={0.3}
            content={intl.formatMessage(
              tabName === 'detectedAlerts' ? eventMessages.newAlert : eventMessages.newIncident,
            )}
          >
            <FlagNewIcon style={{ color: 'red', fontSize: 14, margin: '0 4px 2px 0' }} />
          </Popover>
        )}
      </>
    );
  }

  @autobind
  statusRender(rowData) {
    const { intl, timezoneOffset, credentials, updateLastActionInfo } = this.props;
    const { tabName } = rowData;
    const { id, isIgnored, isImportant, isNewAlert, reporterRecordMap } = rowData;
    const { leadToIncident, hasTrailing, causedByChange, hasRootCause } = rowData;

    return (
      <div className="flex-row flex-wrap full-width">
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          actionName={
            isImportant
              ? intl.formatMessage(eventMessages.markedAsImportant)
              : intl.formatMessage(eventMessages.markedAsUnimportant)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={get(reporterRecordMap, isImportant ? 'importance' : 'unImportance', [])}
        >
          {isImportant ? (
            <GoodIcon
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: '#32C880' }}
              onClick={() => this.handleIgnoreClick({ event: rowData, category: 'important' })}
            />
          ) : (
            <GoodOutlinedIcon
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={() => this.handleIgnoreClick({ event: rowData, category: 'important' })}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>
        <EventRenderers.RenderPatternReporterTooltip
          intl={intl}
          actionName={
            isIgnored
              ? intl.formatMessage(eventMessages.markedAsIgnored)
              : intl.formatMessage(eventMessages.markedAsUnignored)
          }
          timezoneOffset={timezoneOffset}
          reporterRecordSet={get(reporterRecordMap, isIgnored ? 'ignore' : 'unIgnore', [])}
        >
          {isIgnored ? (
            <EyeInvisibleOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          ) : (
            <EyeOutlined
              style={{ fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleIgnoreClick({ event: rowData, category: 'ignore' });
              }}
            />
          )}
        </EventRenderers.RenderPatternReporterTooltip>

        {false && leadToIncident && (
          <Popover
            placement="top"
            content={
              <RenderLeadToIncident
                intl={intl}
                credentials={credentials}
                incident={rowData}
                leadIncidentPatternNameStr={this.leadIncidentPatternNameStrMap[id]}
                setIncidentPatternNameStr={(leadIncidentPatternNameStr) => {
                  this.leadIncidentPatternNameStrMap[id] = leadIncidentPatternNameStr;
                }}
                updateLastActionInfo={updateLastActionInfo}
              />
            }
            mouseEnterDelay={0.3}
          >
            <ExclamationCircleOutlined
              style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAClick({ tabName, event: rowData });
              }}
            />
          </Popover>
        )}
        {false && hasTrailing && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.incidentHasTrailing)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="top"
          >
            <IncidentPredictIcon
              style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAClick({ tabName, event: rowData });
              }}
            />
          </Popover>
        )}
        {false && causedByChange && (
          <Popover
            content={
              <div>
                <div>
                  {tabName === 'detectedAlerts'
                    ? intl.formatMessage(DashboardMessages.causedByChangeAlert)
                    : intl.formatMessage(DashboardMessages.causedByChangeIncident)}
                </div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="top"
          >
            <ChangeEventIcon
              style={{ color: 'orange', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAClick({ tabName, event: rowData });
              }}
            />
          </Popover>
        )}
        {false && hasRootCause && (
          <Popover
            content={
              <div>
                <div>{intl.formatMessage(DashboardMessages.incidentHasRootCause)}</div>
                <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
              </div>
            }
            mouseEnterDelay={0.3}
            placement="top"
          >
            <RootCauseIcon
              style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
              onClick={(event) => {
                event.stopPropagation();
                this.handleRCAClick({ tabName, event: rowData });
              }}
            />
          </Popover>
        )}
        {false && isNewAlert && (
          <Popover
            placement="top"
            mouseEnterDelay={0.3}
            content={intl.formatMessage(
              tabName === 'detectedAlerts' ? eventMessages.newAlert : eventMessages.newIncident,
            )}
          >
            <FlagNewIcon style={{ color: 'red', fontSize: 14, margin: '0 4px 2px 0' }} />
          </Popover>
        )}
      </div>
    );
  }

  @autobind
  handleIgnoreClick({ event, category }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag = (category === 'ignore' && isIgnored) || (category === 'important' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          }),
      onOk: this.handleIgnoreSumbit(event, category),
    });
  }

  @autobind
  handleIgnoreClickOld({ event, category }) {
    const { intl } = this.props;
    const { isIgnored, isImportant, patternName, patternId } = event;
    const flag = (category === 'ignore' && isIgnored) || (category === 'important' && isImportant);
    this.ignoreModal = Modal.confirm({
      title: intl.formatMessage(appButtonsMessages.confirm),
      content: flag
        ? intl.formatMessage(eventMessages.resetIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          })
        : intl.formatMessage(eventMessages.markIgnoreStatus, {
            pattern: patternName || patternId,
            status: category === 'ignore' ? 'Ignore' : 'Important',
          }),
      okText: intl.formatMessage(appButtonsMessages.submit),
      cancelText: intl.formatMessage(appButtonsMessages.cancel),
      onOk: this.handleIgnoreSumbit(event, category),
    });
  }

  @autobind
  handleIgnoreSumbit(incident, category) {
    return () => {
      const { intl, credentials, userInfo, systemId, seriesId } = this.props;
      const { isIgnored, isImportant } = incident;
      const { projectOwner, anomalyLogInstance, instanceName, patternId, type } = incident;
      let { projectName } = incident;
      projectName = projectOwner !== userInfo.userName ? `${projectName}@${projectOwner}` : projectName;

      if (this.ignoreModal) {
        this.ignoreModal.update({
          okButtonProps: { loading: true },
          cancelButtonProps: { disabled: true },
        });
      }

      let content;
      if (category === 'ignore' && !isIgnored) {
        const summaryList = R.map((event) => {
          return EventRenderers.BuildMetricAnomalySummary({ event });
        }, incident.rootCausesDetailsList || []);
        content = R.join('\n', summaryList);
      }

      let operation;
      switch (category) {
        case 'ignore':
          operation = isIgnored ? 'cancelIgnoreFlag' : 'setIgnoreFlag';
          break;
        case 'important':
          operation = isImportant ? 'cancelImportantFlag' : 'setImportantFlag';
          break;
        default:
          break;
      }

      this.props.updateLastActionInfo();
      return fetchPost(
        getEndpoint('events', 1),
        {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceName,
          operation,
          nid: patternId,
          type: type === 'Incident' ? 'incident' : undefined,
          content,
        },
        {},
        false,
      )
        .then((data) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          if (this.ignoreModal) this.ignoreModal.destroy();
          if (seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine') {
            this.reloadData(this.props);
          } else {
            this.props.onClose([systemId]);
          }
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          if (this.ignoreModal) {
            this.ignoreModal.update({
              okButtonProps: { loading: false },
              cancelButtonProps: { disabled: false },
            });
          }
        });
    };
  }

  @autobind
  handleRCAClick({ tabName, event }) {
    const { environmentId, customerName, globalInfo, systemId, seriesId } = this.props;

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    const {
      isIncident,
      isTrace,
      category,
      type,
      day,
      startTimestamp,
      projectName,
      patternId,
      instanceList,
      metricList,
      isIgnored,
    } = event;
    const startTime = day || moment.utc(startTimestamp).format(Defaults.DateFormat);
    let query;
    if (seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine') {
      query = {
        environmentId,
        customerName,
        systemId: systemInfo.id,
        startTime,
        endTime: startTime,

        hideIgnore: !isIgnored,

        eventCategory: isIncident ? 'incident' : category === 'metric' ? 'metric' : isTrace ? 'trace' : 'log',
        eventPatternType: isIncident ? 'incident' : category === 'metric' ? 'metric' : type,
        eventProjectName: projectName,
        eventPatternId: patternId,
        eventInstanceName: instanceList[0],
        eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
        eventTimestamp: startTimestamp,
      };
    } else {
      query = {
        environmentId,
        startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        customerName: systemInfo.ownerUserName,
        systemId,

        eventCategory: isIncident ? 'incident' : category === 'metric' ? 'metric' : isTrace ? 'trace' : 'log',
        eventPatternType: isIncident ? 'incident' : category === 'metric' ? 'metric' : type,
        eventPatternId: patternId,
        eventProjectName: projectName,
        evqueryentTimestamp: startTimestamp,
        hideNoResult: 'false',
      };
    }
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    // if (['detectedIncidents'].includes(tabName)) {
    //   const query = {
    //     environmentId,
    //     customerName,
    //     systemId: systemInfo.id,
    //     startTime,
    //     endTime: startTime,

    //     eventCategory: 'incident',
    //     eventPatternType: 'incident',
    //     eventProjectName: projectName,
    //     eventPatternId: patternId,
    //     eventInstanceName: instanceList[0],
    //     eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
    //     eventTimestamp: startTimestamp,
    //     hideIgnore: !isIgnored,
    //   };
    //   window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    // } else {
    //   const query = {
    //     environmentId,
    //     customerName,
    //     systemId: systemInfo.id,
    //     startTime,
    //     endTime: startTime,

    //     hideIgnore: !isIgnored,

    //     eventCategory: isIncident ? 'incident' : category === 'metric' ? 'metric' : isTrace ? 'trace' : 'log',
    //     eventPatternType: isIncident ? 'incident' : category === 'metric' ? 'metric' : type,
    //     eventProjectName: projectName,
    //     eventPatternId: patternId,
    //     eventInstanceName: instanceList[0],
    //     eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
    //     eventTimestamp: startTimestamp,
    //   };
    //   window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
    // }
  }

  @autobind
  renderControl(event) {
    const { intl, seriesId } = this.props;
    const { tabName, category, typeList, neuronId, timeLineType } = event;
    return (
      <Dropdown
        icon={<SearchOutlined />}
        itemClick={({ key }) => {
          switch (key) {
            case 'details':
              this.handleRCAClick({ tabName, event });
              break;
            case 'lineChart':
              this.handleDetailsClick(event);
              break;
            case 'logAnalysis':
              this.handleDetailsClick(event);
              break;
            case 'originalNormalPattern':
              this.handleOriginalNormalPatternClick({ event });
              break;
            case 'context':
              this.handleLogContextClick(event);
              break;
            case 'setPatternName':
              this.handleActionClick(event, 'setPatternName');
              break;
            case 'editTriageReport':
              this.handleTriageReportClick({ event });
              break;
            case 'reportJira':
              this.handleReportJiraClick({ event });
              break;
            case 'reportServiceNow':
              this.handleReportServiceNowClick({ event });
              break;
            default:
              break;
          }
        }}
      >
        <>
          {/* {['detectedIncidents', 'detectedAlerts'].includes(tabName) && (
            <Menu.Item key="details">{intl.formatMessage(appFieldsMessages.details)}</Menu.Item>
          )} */}
          {timeLineType !== 'future' && seriesId !== 'deploymentLine' && (
            <Menu.Item key="details">{intl.formatMessage(appFieldsMessages.details)}</Menu.Item>
          )}
          {category === 'metric' && (
            <Menu.Item key="lineChart">{intl.formatMessage(DashboardMessages.lineChart)}</Menu.Item>
          )}
          {category !== 'metric' && (
            <Menu.Item key="logAnalysis">{intl.formatMessage(appMenusMessages.logAnalysis)}</Menu.Item>
          )}
          {category !== 'metric' && typeList.includes('rare') && isNumber(neuronId) && (
            <Menu.Item key="originalNormalPattern">
              {intl.formatMessage(DashboardMessages.originalNormalPattern)}
            </Menu.Item>
          )}
          {/* {['detectedAlerts'].includes(tabName) && event.category === 'log' && (
            <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>
          )} */}
          {false && event.category === 'log' && (
            <Menu.Item key="context">{intl.formatMessage(eventMessages.context)}</Menu.Item>
          )}
          <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item>
          <Menu.Item key="editTriageReport">{intl.formatMessage(eventMessages.editTriageReport)}</Menu.Item>
          <Menu.Item key="reportJira">{intl.formatMessage(eventMessages.reportJira)}</Menu.Item>
          <Menu.Item key="reportServiceNow">{intl.formatMessage(eventMessages.reportServiceNow)}</Menu.Item>
        </>
      </Dropdown>
    );
  }

  @autobind
  handleDetailsClick(event) {
    const { userInfo, environmentId, customerName, projects, globalInfo, systemId } = this.props;
    const {
      projectOwner,
      category,
      startTimestamp,
      endTimestamp,
      anomalyLogInstance,
      instanceList,
      metricList,
      type,
      patternId,
    } = event;

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const startTimeObj = moment.utc(startTimestamp).startOf('day');
    const endTimeObj = moment.utc(endTimestamp).endOf('day');
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    if (category === 'metric') {
      const query = {
        startTime: startTimeObj.format(Defaults.DateFormat),
        endTime: endTimeObj.format(Defaults.DateFormat),
        customerName,
        environmentId,
        systemId: systemInfo.id,

        projectName,
        instanceGroup,
        modelType,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        justInstanceList: R.join(',', instanceList),
        justSelectMetric: R.join(',', metricList),
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    } else {
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const query = {
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        activeTab: type === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: project?.owner,
        anomalyType: type.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleOriginalNormalPatternClick({ event }) {
    const { userInfo, projects } = this.props;
    const { projectOwner, startTimestamp, anomalyLogInstance, instanceList, neuronId, type } = event;

    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const query = {
      projectName,
      instanceName: anomalyLogInstance || instanceList[0],
      startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      activeTab: 'clusters',
      activePatternId: neuronId,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner,
      anomalyType: type.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  handleLogContextClick(event) {
    const { userInfo, projects } = this.props;
    const { startTimestamp, endTimestamp } = event;
    const activeEvent = {
      ...event,
      instanceName: event.anomalyLogInstance || event.instanceName,
      timestamp: event.startTimestamp,
    };
    const activeEventProjectName =
      userInfo.userName !== activeEvent.projectOwner
        ? `${activeEvent.projectName}@${activeEvent.projectOwner}`
        : activeEvent.projectName;
    const activeEventProject = R.find((project) => activeEventProjectName === project.projectName, projects);
    this.setState({
      showTimeSelectModal: true,
      activeEvent,
      activeEventProjectName,
      activeEventProject,
      selectInstance: activeEvent.instanceName,
      selectStartTimestamp: startTimestamp - 60 * 1000,
      selectEndTimestamp: endTimestamp + 60 * 1000,
    });
  }

  @autobind
  onCloseTimeSelect(props) {
    const { projectName, instanceName, startTimestamp, endTimestamp, keywordFilter } = props || {};
    if (startTimestamp && endTimestamp) {
      this.setState({
        showTimeSelectModal: false,
        showContextModal: true,
        selectProject: projectName,
        selectInstance: instanceName,
        selectStartTimestamp: startTimestamp,
        selectEndTimestamp: endTimestamp,
        contextKeywordFilter: keywordFilter,
      });
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  handleActionClick(event, actionName) {
    const { userInfo, projects } = this.props;
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const activeEvent = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    const activeEventProjectName =
      userInfo.userName !== activeEvent.projectOwner
        ? `${activeEvent.projectName}@${activeEvent.projectOwner}`
        : activeEvent.projectName;
    const activeEventProject = R.find((project) => activeEventProjectName === project.projectName, projects);
    this.setState({
      showTakeLogActionModal: true,
      actionName,
      activeEvent,
      activeEventProjectName,
      activeEventProject,
    });
  }

  @autobind
  handlePatternNameChanged(patternName, patternId) {
    const { systemId, seriesId } = this.props;
    this.setState({ showTakeLogActionModal: false }, () => {
      if (seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine') {
        this.reloadData(this.props);
      } else {
        this.props.onClose([systemId]);
      }
    });
  }

  @autobind
  handleTriageReportClick({ event }) {
    this.setState({ showTriageReportModal: true, activeEvent: event });
  }

  @autobind
  handleReportJiraClick({ event }) {
    this.setState({ activeEvent: event, showReportJiraModal: true });
  }

  @autobind
  handleReportServiceNowClick({ event }) {
    this.setState({ activeEvent: event, showReportServiceNowModal: true });
  }

  @autobind
  renderListInstance({ key, index: rowIndex, style, parent }) {
    const { projectNameSearch, instanceNameSearch } = this.state;
    let { filterEventList } = this.state;
    if (projectNameSearch) {
      filterEventList = R.filter(
        (event) => R.toLower(event.projectDisplayName).includes(R.toLower(projectNameSearch)),
        filterEventList,
      );
    }
    if (instanceNameSearch) {
      filterEventList = R.filter(
        (event) => R.toLower(event.onlyInstanceString).includes(R.toLower(instanceNameSearch)),
        filterEventList,
      );
    }
    const item = filterEventList[rowIndex];
    if (!item) return null;
    const { projectDisplayName, componentNameString, onlyInstanceString } = item;
    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style }}>
          <div className="row-column" style={{ width: 80 }}>
            {this.scoreRender(item)}
          </div>
          <div className="row-column" style={{ minWidth: 100, flex: 1, maxWidth: 120 }}>
            {this.renderInstanceContent(projectDisplayName, item)}
          </div>
          <div className="row-column" style={{ minWidth: 100, flex: 1 }}>
            {this.renderInstanceContent(`${onlyInstanceString} (${componentNameString})`, item)}
          </div>
          <div className="row-column" style={{ width: 100 }}>
            {item.isTrace ? this.renderTrace(item) : this.renderPattern(item)}
          </div>
          <div className="row-column" style={{ width: 75 }}>
            {this.renderCategory(item)}
          </div>
          <div className="row-column" style={{ minWidth: 100, flex: 2 }}>
            <EChart width="100%" height={40} option={item.optionTimeline} onEvents={{}} />
          </div>
          <div className="row-column" style={{ width: 50, padding: 0 }}>
            {this.statusRender(item)}
          </div>
          <div className="row-column" style={{ width: 40 }}>
            {this.renderControl(item)}
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, filterEventList } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const newEventList = this.sortData(filterEventList, name, sortDir);
          this.setState({ filterEventList: newEventList });
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handleInstanceClick(rowData) {
    this.setState({ instanceFilter: rowData.id }, () => {
      this.filterData(this.props, { ...this.state, instanceFilter: rowData.id });
    });
  }

  @autobind
  renderListInstanceOld(instanceComponentList, instanceFilter, instanceDisplayNameMap) {
    return ({ key, index: rowIndex, style, parent }) => {
      const { intl } = this.props;
      const rowData = instanceComponentList[rowIndex];
      if (!rowData) return null;

      const { type, id, hasAnomaly } = rowData;
      let content;
      if (type === 'component') {
        content = (
          <div
            key={rowIndex}
            className="bold flex-row border-bottom odd-row"
            style={{ ...style, fontSize: 13, padding: '16px 8px 2px 8px' }}
          >
            <div style={{ width: 90, minWidth: 90 }}>{intl.formatMessage(appFieldsMessages.component)}:</div>
            <Popover placement="right" content={id}>
              <span className="hidden-line-with-ellipsis">{id}</span>
            </Popover>
          </div>
        );
      } else {
        const active = instanceFilter === id;
        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, id);
        content = (
          <div
            key={rowIndex}
            className={`event-list-row ${active ? ' active' : ''}`}
            style={{ ...style, cursor: 'pointer' }}
            onClick={() => this.handleInstanceClick(rowData)}
          >
            <div className="row-column" style={{ width: 30 }}>
              {!hasAnomaly && <SunnyIcon style={{ color: '#ffc800', fontSize: 16 }} />}
              {hasAnomaly && <RainingIcon style={{ color: Defaults.Colorbrewer[0], fontSize: 16 }} />}
            </div>
            <div className="row-column" style={{ width: 160, flex: 1 }}>
              <Popover placement="right" content={instanceStr}>
                <div className="hidden-line-with-ellipsis">{instanceStr}</div>
              </Popover>
            </div>
          </div>
        );
      }

      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          {content}
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderEvent(rowData, index) {
    const { intl } = this.props;
    const { startTimestamp, endTimestamp, patternId, isFixedIncident } = rowData;

    return (
      <div key={index} className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`} style={{ minHeight: 40 }}>
        <div className="row-column" style={{ width: 120, flexDirection: 'column', alignItems: 'flex-start' }}>
          <div>{moment.utc(startTimestamp).format(Defaults.ShortTimeFormat)}</div>
          <div>{moment.utc(endTimestamp).format(Defaults.ShortTimeFormat)}</div>
        </div>
        <div className="row-column" style={{ width: 80 }}>
          {patternId}
        </div>
        <div className="row-column flex-col" style={{ width: 130, alignItems: 'start' }}>
          {CellRenderers.logTypeRenderer({
            intl,
            rowData,
            isPrediction: rowData.isPrediction,
            withPredictionPrefix: true,
            isFixedIncident,
          })}
        </div>
        <div className="row-column" style={{ width: 120, flex: 1 }}>
          {this.renderDetails(rowData)}
        </div>
        <div className="row-column" style={{ width: 100 }}>
          {this.renderControlOld(rowData)}
        </div>
      </div>
    );
  }

  @autobind
  renderControlOld(event) {
    const { intl } = this.props;

    return (
      <Button
        type="primary"
        size="small"
        icon={<InfoCircleOutlined />}
        onClick={() => this.setState({ showDetailsModal: true, activeEvent: event })}
      >
        {intl.formatMessage(eventMessages.fieldDetails)}
      </Button>
    );
  }

  @autobind
  renderDetails(rowData) {
    const { intl, currentTheme } = this.props;
    const { rawData, rootCauseJson } = rowData;
    const { summarySettings } = this.state;
    const rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);

    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
      if (!isObject(rawDataJson)) rawDataJson = undefined;
    } catch (error) {
      // console.debug(error);
    }

    return (
      <div className="flex-col full-width">
        {rootCauseDetailsArr.length > 0 && (
          <div>
            <div style={{}}>
              {R.addIndex(R.map)(
                (event, index) => EventRenderers.RenderMetricAnomalySummary({ intl, event, index, metricUnitMap: {} }),
                R.take(3, rootCauseDetailsArr),
              )}
              {rootCauseDetailsArr.length > 3 && <div>...</div>}
            </div>
          </div>
        )}
        {rawData && (
          <LogRenderers.RenderLogContent
            intl={intl}
            rawData={rawData}
            rawDataJson={rawDataJson}
            owner={rowData}
            summarySettings={summarySettings}
            currentTheme={currentTheme}
          />
        )}
      </div>
    );
  }

  @autobind
  handleChangePatternNameClick({ event }) {
    const fullProjectName = `${event.projectName}@${event.projectOwner}`;
    const actionIncident = {
      ...event,
      fullProjectName,
      instanceName: event.anomalyLogInstance || event.instanceName,
      isLog: event.category === 'log',
      timestamp: event.startTimestamp,
      predictFlag: event.isPrediction,
    };
    this.setState({ showTakeLogActionModal: true, actionName: 'setPatternName', activeEvent: actionIncident });
  }

  @autobind
  handleClick({ isPredicted, patternSearch, typeSearch, event }) {
    const { userInfo, projects, environmentId, startTime, endTime } = this.props;
    const {
      category,
      projectOwner,
      anomalyLogInstance,
      patternId,
      type: eventType,
      fromStorage,
      startTimestamp,
    } = event;
    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);

    if (category !== 'log') {
      const query = {
        projectName,
        instanceGroup,
        startTime,
        endTime: endTime || startTime,

        patternSearch,
        statusFilterAll: isPredicted && fromStorage ? 'historicalPredictions' : 'all',
      };
      window.open(buildUrl(BaseUrls.MetricEvents, {}, query), '_blank');
    } else if (category === 'log') {
      const project = R.find((project) => {
        return projectName === project.projectName;
      }, projects || []);
      const isAlert = get(project, ['isAlert'], false);
      const isIncident = get(project, ['isIncident'], false);
      const day = moment.utc(startTimestamp).format(Defaults.DateFormat);
      const query = {
        projectName,
        instanceName: anomalyLogInstance,
        startTime: day,
        endTime: day,
        activeTab: eventType === 'rare' ? 'important' : 'clusters',
        activePatternId: patternId,
        ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
        customerName: project?.owner,
        anomalyType: eventType.toLowerCase(),
        isJump: true,
      };
      window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleCausalGraphClick({ event }) {
    const { customerName, environmentId } = this.props;
    const { projectName, instanceName, startTimestamp, endTimestamp, patternId, type, causalGraphInfo } = event;
    const { startTime, endTime, causalKey, duration, joinDependency } = causalGraphInfo;
    const rootCauseDetailsArr = get(event, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    const causalInstance =
      rootCauseDetailsArr && rootCauseDetailsArr.length > 0
        ? rootCauseDetailsArr[0].instanceId || rootCauseDetailsArr[0].instanceName
        : instanceName;
    const metric =
      rootCauseDetailsArr && rootCauseDetailsArr.length > 0
        ? rootCauseDetailsArr[0].rootCauseMetric || rootCauseDetailsArr[0].metricName
        : null;

    if (causalKey && startTime && startTime) {
      const query = {
        causalKey,
        customerName,
        causalGraphVersion: 'newCausal',
        tasksStartTime: startTime,
        tasksEndTime: endTime,
        joinDependency,
      };
      window.open(buildUrl(BaseUrls.CausalAnalysis, {}, query), '_blank');
    } else {
      const query = {
        customerName,
        environmentId,
        projectName,
        instanceName: causalInstance,
        startTimestamp: startTime || startTimestamp,
        endTimestamp: endTime || endTimestamp,
        needCausalProperty: true,
        nid: patternId,
        type,
        metric,
        causalGraphVersion: 'newCausal',
        duration,
        count: 1,
        probability: '0.0',
        joinDependency,
        showHaveDataGraph: true,
      };
      window.open(buildUrl(BaseUrls.CausalGroupAnalysis, {}, query), '_blank');
    }
  }

  @autobind
  handleLineChartClick({ event }) {
    const { userInfo, customerName, environmentId, systemId, startTime, endTime } = this.props;
    const { projectOwner, metricList, instanceList } = event;
    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,

      projectName,
      instanceGroup,
      modelType,
      startTimestamp,
      endTimestamp,
      justSelectMetric: R.join(',', metricList),
      justInstanceList: R.join(',', instanceList),
      // withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  handleLineChartJump({ event }) {
    const { globalInfo, customerName, startTime, endTime, environmentId, systemId } = this.props;

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = environment ? environment.systemList : [];
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const projectNameSet = get(systemInfo, ['projectNameSet'], []);
    const metricProjects = R.filter((project) => project.dataType === 'Metric', projectNameSet);
    if (metricProjects.length > 1) {
      this.setState({
        showProjectSelector: true,
        onConfirmProjectSelect: this.handleLineChartsJumpConfirm({ event }),
      });
    } else {
      const { projectNameReal } = metricProjects[0];
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';
      const query = {
        startTime,
        endTime: startTime,
        customerName,
        environmentId,
        systemId,
        projectName: projectNameReal,
        instanceGroup,
        modelType,
        startTimestamp,
        endTimestamp,
        justInstanceList: event.realInstanceName,
        // withBaseline: true,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    }
  }

  @autobind
  handleLineChartsJumpConfirm({ event }) {
    return (projectName) => {
      const { customerName, startTime, endTime, environmentId, systemId } = this.props;
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
      let modelType = 'Holistic';
      if (instanceGroup !== 'All') modelType = 'splitByEnv';
      const query = {
        startTime,
        endTime: startTime,
        customerName,
        environmentId,
        systemId,
        projectName,
        instanceGroup,
        modelType,
        startTimestamp,
        endTimestamp,
        justInstanceList: event.realInstanceName,
        // withBaseline: true,
      };
      window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
    };
  }

  @autobind
  handleIPClick({ event }) {
    const { globalInfo, environmentId, systemId } = this.props;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);

    const { startTimestamp, projectName, patternId } = event;
    const query = {
      environmentId,
      startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      endTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
      customerName: systemInfo.ownerUserName,
      systemId,

      hideIgnore: 'false',
      typeFilter: 'all',

      eventPatternId: patternId,
      eventProjectName: projectName,
      eventTimestamp: startTimestamp,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemPrediction, {}, query), '_blank');
  }

  @autobind
  handleSuggestedActionsClick({ event }) {
    const { userInfo } = this.props;
    let { projectName } = event;
    const {
      projectOwner,
      startTimestamp,
      category,
      anomalyLogInstance,
      instanceName,
      suggestActionList,
      anomalyRatio,
      rootCausesDetailsList,
    } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
    const isLog = category === 'log';

    const activeIncident = {
      ...event,
      isLog,
      projectName,
      projectOwner,
      instanceName: isLog ? anomalyLogInstance || instanceName : instanceName,
      timestamp: startTimestamp,
      hasSuggestedAction: suggestActionList.length > 0,
      suggestActionList,
      anomalyScore: anomalyRatio,
      rootCausesDetailsList,
    };
    this.setState({ activeEvent: activeIncident, showEventActionModal: true });
  }

  @autobind
  getProjectNameList() {
    const { systemsMap, systemId } = this.props;
    const systemList = R.values(systemsMap);
    const systemInfo = R.find((system) => system.systemId === systemId, systemList);
    const projectNameList = R.map(
      (item) => `${item.projectName}@${item.customerName}`,
      get(systemInfo, 'projectDetailsList', []),
    );
    return projectNameList;
  }

  @autobind
  handleDeleteIncidentClick({ event }) {
    const { intl, credentials, userInfo, systemId } = this.props;
    const { anomalyLogInstance, instanceName, patternId, incidentTimestamp, startTimestamp } = event || {};
    const { projectOwner } = event;
    let { projectName } = event;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;

    this.props.updateLastActionInfo();
    return fetchPost(getEndpoint('deleteincident'), {
      ...credentials,
      projectName,
      instanceName: anomalyLogInstance || instanceName,
      timestamp: incidentTimestamp || startTimestamp,
      patternId,
    })
      .then((data) => {
        const { success, message: msg } = data || {};

        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.props.onClose([systemId]);
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${msg}`);
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  onPredictionSourceLineChartClick(event, predictionSourceInfo) {
    const { customerName, startTime, endTime, environmentId, systemId, userName } = this.props;
    const { predictionTime, startTimestamp: predictionOccurrenceTime } = event;
    const { sourceProjectName, sourceProjectOwner, sourceInstanceName, containerId, sourceDetail } =
      predictionSourceInfo || {};
    const { content } = sourceDetail || {};

    let projectName = sourceProjectName;
    const isOwner = sourceProjectOwner === userName;
    if (!isOwner) {
      projectName = `${projectName}@${sourceProjectOwner}`;
    }
    let startTimestamp;
    const predictionTimeObj = moment.utc(predictionTime);
    if (predictionTimeObj.hours() < 2) {
      startTimestamp = moment.utc(predictionTime).subtract(1, 'days').startOf('day').valueOf();
    } else {
      startTimestamp = moment.utc(predictionTime).startOf('day').valueOf();
    }
    const endTimestamp = moment.utc(predictionOccurrenceTime).endOf('day').valueOf();
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    if (instanceGroup !== 'All') modelType = 'splitByEnv';

    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,

      projectName,
      instanceGroup,
      modelType,
      startTimestamp,
      endTimestamp,
      justSelectMetric: content,
      justInstanceList:
        containerId && !sourceInstanceName.includes('_') ? `${containerId}_${sourceInstanceName}` : sourceInstanceName,
      withBaseline: true,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  onPredictionSourceJumpClick(event, predictionSourceInfo) {
    const { userName, projects } = this.props;
    const { eventTimestamp, sourceProjectName, sourceProjectOwner, sourceDetail, sourceInstanceName } =
      predictionSourceInfo || {};
    const { type, nid, instanceNameInThisProject } = parseJSON(sourceDetail) || {};

    let projectName = sourceProjectName;
    const isOwner = sourceProjectOwner === userName;
    if (!isOwner) {
      projectName = `${projectName}@${sourceProjectOwner}`;
    }
    const project = R.find((project) => {
      return sourceProjectName === project.projectName;
    }, projects || []);
    const isAlert = get(project, ['isAlert'], false);
    const isIncident = get(project, ['isIncident'], false);
    const day = moment.utc(eventTimestamp).format(Defaults.DateFormat);
    const query = {
      projectName,
      instanceName: instanceNameInThisProject || sourceInstanceName,
      startTime: day,
      endTime: day,
      activeTab: R.toLower(type) === 'rare' ? 'important' : 'clusters',
      activePatternId: nid,
      ...(isAlert || isIncident ? { hasAlert: true } : { hasLog: true }),
      customerName: project?.owner || sourceProjectOwner,
      anomalyType: type.toLowerCase(),
      isJump: true,
    };
    window.open(buildUrl(BaseUrls.LogAnalysis, {}, query), '_blank');
  }

  @autobind
  onPredictionSourceTrendPatternsClick(event, predictionSourceInfo) {
    const { userName } = this.props;
    const { eventTimestamp, sourceProjectName, sourceProjectOwner, sourceDetail } = predictionSourceInfo || {};
    const { nid, instanceNameInThisProject } = parseJSON(sourceDetail) || {};

    let projectName = sourceProjectName;
    const isOwner = sourceProjectOwner === userName;
    if (!isOwner) {
      projectName = `${projectName}@${sourceProjectOwner}`;
    }
    const startTimeObj = moment.utc(eventTimestamp).subtract(7, 'days').startOf('day');
    const endTimeObj = moment.utc(eventTimestamp).endOf('day');

    const query = {
      t: '953de6a33d8a4b96ac9c100bf69ba3fc',
      projectName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      instanceName: instanceNameInThisProject,
      pattern: nid,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
  }

  @autobind
  onPredictionSourceLogQueryClick(event, predictionSourceInfo) {
    const { userName, projectDisplayMap } = this.props;
    const { sourceProjectName, sourceProjectOwner } = predictionSourceInfo || {};
    const projectName =
      sourceProjectOwner !== userName ? `${sourceProjectName}@${sourceProjectOwner}` : sourceProjectName;
    const projectDisplayName = get(projectDisplayMap, projectName, projectName);
    this.setState({
      showInsightQueryBox: true,
      queryLogProjects: [{ projectNameReal: projectName, projectDisplayName }],
    });
  }

  @autobind
  onConfirmInsightQueryProjectSelect(params) {
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
    } = params;
    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      keyword,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      pattern,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      duration,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showInsightQueryBox: false });
  }

  @autobind
  handleProjectNameSearchChange(projectNameSearch) {
    this.setState({ projectNameSearch });
  }

  @autobind
  handleInstanceNameSearchChange(instanceNameSearch) {
    this.setState({ instanceNameSearch });
  }

  render() {
    const {
      intl,
      loadStatus,
      environmentId,
      globalInfo,
      systemId,
      seriesId,
      timezoneOffset,
      currentLocale,
      credentials,
      projects,
      projectDisplayMap,
      updateLastActionInfo,
      currentTheme,
    } = this.props;
    const {
      isLoading,
      isLoadingTimelines,
      dates,
      startTimeObj,
      endTimeObj,
      sortBy,
      sortDirection,
      optionTimelineRange,
      events,
      instanceComponentList,
      instanceFilter,
      localEvents,
      projectNameSearch,
      instanceNameSearch,
    } = this.state;
    let { filterEventList } = this.state;

    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    const { instanceDisplayNameMap } = systemInfo || {};

    const hasDataLog =
      seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine'
        ? filterEventList.length > 0
        : events.length > 0;
    const timeChange = startTimeObj && endTimeObj && (startTimeObj !== dates[0] || endTimeObj !== dates[1]);

    if (projectNameSearch) {
      filterEventList = R.filter(
        (event) => R.toLower(event.projectDisplayName).includes(R.toLower(projectNameSearch)),
        filterEventList,
      );
    }
    if (instanceNameSearch) {
      filterEventList = R.filter(
        (event) => R.toLower(event.onlyInstanceString).includes(R.toLower(instanceNameSearch)),
        filterEventList,
      );
    }

    const isIncidentLine =
      ['predictionIncidentLine', 'incidentLine', 'fixedIncidentLine', 'actionLine'].indexOf(seriesId) !== -1;
    const isDeploymentLine = seriesId === 'deploymentLine';
    const isActionLine = seriesId === 'actionLine';
    const isAlertLine = seriesId === 'alertLine';
    const showDetails = (isIncidentLine || !seriesId) && events.length === 1;
    const isFixedIncidentLine = ['fixedIncidentLine'].indexOf(seriesId) !== -1;
    const isPredictionIncidentLine = ['predictionIncidentLine'].indexOf(seriesId) !== -1;

    const { errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    return (
      <Modal
        width={1300}
        title={intl.formatMessage(appFieldsMessages.details)}
        visible
        footer={null}
        onCancel={() => this.props.onClose()}
        maskClosable={false}
      >
        <Container
          className={`flex-col flex-min-height${isLoading || isLoadingTimelines ? ' loading ' : ''}`}
          style={{ height: 500, fontSize: 12 }}
        >
          {errorMessage && <div className="ui mini error message">{errorMessage}</div>}
          {!errorMessage && (
            <div className="flex-grow flex-col flex-min-height">
              {!(isIncidentLine || isAlertLine || !seriesId) && (
                <>
                  <div className="flex-row flex-min-width flex-center-align" style={{ marginBottom: 8 }}>
                    <div style={{ fontWeight: 'bold', marginRight: 8 }}>
                      {intl.formatMessage(logMessages.timeRange)}:
                    </div>
                    <TimePicker
                      size="small"
                      allowClear={false}
                      value={startTimeObj}
                      onSelect={(timeObj) => this.handleTimeChange('startTime', timeObj)}
                      popupClassName="antd-timepicker"
                    />
                    <div style={{ margin: '0 4px' }}>~</div>
                    <TimePicker
                      size="small"
                      allowClear={false}
                      value={endTimeObj}
                      onSelect={(timeObj) => this.handleTimeChange('endTime', timeObj)}
                      popupClassName="antd-timepicker"
                    />
                    <Popover
                      mouseEnterDelay={0.3}
                      visible={timeChange}
                      title={null}
                      content={timeChange ? intl.formatMessage(appMessages.clickToReload) : null}
                    >
                      <Button
                        size="small"
                        style={{ marginLeft: 8 }}
                        onClick={() => {
                          this.setState({ dates: [startTimeObj, endTimeObj] }, () => {
                            this.reloadData(this.props);
                          });
                        }}
                      >
                        {intl.formatMessage(appButtonsMessages.reload)}
                      </Button>
                    </Popover>
                    <Input.Search
                      size="small"
                      placeholder={intl.formatMessage(eventMessages.projectName)}
                      style={{ width: 200, marginLeft: 10 }}
                      allowClear
                      enterButton
                      onSearch={(value) => this.handleProjectNameSearchChange(value)}
                    />
                    <Input.Search
                      size="small"
                      placeholder={intl.formatMessage(eventMessages.instanceName)}
                      style={{ width: 200, marginLeft: 10 }}
                      allowClear
                      enterButton
                      onSearch={(value) => this.handleInstanceNameSearchChange(value)}
                    />
                  </div>
                  <div className="flex-grow flex-row flex-min-height">
                    <AutoSizer>
                      {({ width, height }) => (
                        <div className="event-list">
                          <div
                            className="event-list-header"
                            style={{ height: 30, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                          >
                            <div
                              className="header-column"
                              style={{ width: 80 }}
                              onClick={this.headerClick('avgAnomalyScore')}
                            >
                              <span>{intl.formatMessage(eventMessages.anomalyScore)}</span>
                              {this.sortIcon(sortBy, sortDirection, 'avgAnomalyScore')}
                            </div>
                            <div
                              className="header-column"
                              style={{ minWidth: 100, flex: 1, maxWidth: 120 }}
                              onClick={this.headerClick('projectDisplayName')}
                            >
                              <span>{intl.formatMessage(eventMessages.projectName)}</span>
                              {this.sortIcon(sortBy, sortDirection, 'projectDisplayName')}
                            </div>
                            <div
                              className="header-column"
                              style={{ minWidth: 100, flex: 1 }}
                              onClick={this.headerClick('onlyInstanceString')}
                            >
                              <span>{intl.formatMessage(eventMessages.instanceName)}</span>
                              {this.sortIcon(sortBy, sortDirection, 'onlyInstanceString')}
                            </div>
                            <div
                              className="header-column"
                              style={{ minWidth: 100 }}
                              onClick={this.headerClick('patternAndTrace')}
                            >
                              <span>{intl.formatMessage(logMessages.patternAndTrace)}</span>
                              {this.sortIcon(sortBy, sortDirection, 'patternAndTrace')}
                            </div>
                            <div className="header-column" style={{ minWidth: 75 }}>
                              {intl.formatMessage(appFieldsMessages.type)}
                            </div>
                            <div className="header-column" style={{ minWidth: 100, flex: 2 }}>
                              {optionTimelineRange && (
                                <EChart width="100%" height={40} option={optionTimelineRange} onEvents={{}} />
                              )}
                            </div>
                            <div className="header-column" style={{ minWidth: 50 }} />
                            <div className="header-column" style={{ minWidth: 40 }} />
                          </div>
                          <List
                            className="event-list-grid"
                            ref={(listNode) => {
                              this.listNode = listNode;
                            }}
                            width={width}
                            height={height - 30}
                            rowCount={hasDataLog ? filterEventList.length : 0}
                            overscanRowCount={10}
                            deferredMeasurementCache={this.cellMeasureCache}
                            rowHeight={this.cellMeasureCache.rowHeight}
                            rowRenderer={this.renderListInstance}
                            onScrollbarPresenceChange={({ horizontal, vertical }) => {
                              if (vertical) {
                                this.listNodeHeaderScrollbar = true;
                              } else {
                                this.listNodeHeaderScrollbar = false;
                              }
                              this.forceUpdate();
                            }}
                          />
                        </div>
                      )}
                    </AutoSizer>
                  </div>
                </>
              )}
              {(isIncidentLine || isAlertLine || !seriesId) && (
                <>
                  {!showDetails && (
                    <div className="flex-grow flex-row flex-min-height">
                      {instanceComponentList.length > 0 && (
                        <div className="flex-col" style={{ width: 240, marginRight: 8 }}>
                          <AutoSizer>
                            {({ width, height }) => (
                              <div className="event-list">
                                <div className="event-list-header" style={{ height: 30, width }}>
                                  <div className="header-column" style={{ width: 30 }} />
                                  <div className="header-column" style={{ width: 160, flex: 1 }}>
                                    {intl.formatMessage(eventMessages.instanceName)}
                                  </div>
                                </div>
                                <List
                                  className="event-list-grid"
                                  ref={(listNode) => {
                                    this.listNode = listNode;
                                  }}
                                  width={width}
                                  height={height - 30}
                                  rowCount={hasDataLog ? instanceComponentList.length : 0}
                                  overscanRowCount={10}
                                  deferredMeasurementCache={this.cellMeasureCache}
                                  rowHeight={this.cellMeasureCache.rowHeight}
                                  rowRenderer={this.renderListInstanceOld(
                                    instanceComponentList,
                                    instanceFilter,
                                    instanceDisplayNameMap,
                                  )}
                                />
                              </div>
                            )}
                          </AutoSizer>
                        </div>
                      )}

                      <div className="event-list flex-grow flex-col flex-min-height">
                        <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                          <div className="header-column" style={{ width: 120 }}>
                            {intl.formatMessage(
                              isFixedIncidentLine || isPredictionIncidentLine
                                ? eventMessages.predictedOccurrenceTime
                                : eventMessages.timeLabel,
                            )}
                          </div>
                          <div className="header-column" style={{ width: 80 }}>
                            {intl.formatMessage(eventMessages.patternId)}
                          </div>
                          <div className="header-column" style={{ width: 130 }}>
                            {intl.formatMessage(eventMessages.fieldAnomalyType)}
                          </div>
                          <div className="header-column" style={{ width: 120, flex: 1 }}>
                            {intl.formatMessage(eventMessages.shortDescription)}
                          </div>
                          <div className="header-column" style={{ width: 100 }} />
                        </div>
                        <div className="event-list-grid flex-grow overflow-y-auto">
                          {R.addIndex(R.map)((rowData, index) => this.renderEvent(rowData, index), localEvents)}
                        </div>
                      </div>
                    </div>
                  )}
                  {showDetails && (
                    <EventDetailsContent
                      intl={intl}
                      globalInfo={globalInfo}
                      timezoneOffset={timezoneOffset}
                      currentLocale={currentLocale}
                      updateLastActionInfo={updateLastActionInfo}
                      credentials={credentials}
                      projects={projects}
                      projectDisplayMap={projectDisplayMap}
                      environmentId={environmentId}
                      systemInfo={systemInfo}
                      incident={events[0]}
                      instanceFilter={instanceFilter}
                      handleIgnoreClick={this.handleIgnoreClick}
                      handleChangePatternNameClick={this.handleChangePatternNameClick}
                      handleTriageReportClick={this.handleTriageReportClick}
                      handleClick={this.handleClick}
                      handleOriginalNormalPatternClick={this.handleOriginalNormalPatternClick}
                      handleCausalGraphClick={this.handleCausalGraphClick}
                      handleLineChartClick={this.handleLineChartClick}
                      handleLineChartJump={this.handleLineChartJump}
                      handleIPClick={this.handleIPClick}
                      handleRCAClick={this.handleRCAClick}
                      handleSuggestedActionsClick={this.handleSuggestedActionsClick}
                      handleReportJiraClick={this.handleReportJiraClick}
                      handleReportServiceNowClick={this.handleReportServiceNowClick}
                      handleDeleteIncidentClick={this.handleDeleteIncidentClick}
                      onPredictionSourceLineChartClick={this.onPredictionSourceLineChartClick}
                      onPredictionSourceJumpClick={this.onPredictionSourceJumpClick}
                      onPredictionSourceTrendPatternsClick={this.onPredictionSourceTrendPatternsClick}
                      onPredictionSourceLogQueryClick={this.onPredictionSourceLogQueryClick}
                      isIncidentLine={isIncidentLine}
                      isActionLine={isActionLine}
                      isDeploymentLine={isDeploymentLine}
                      currentTheme={currentTheme}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </Container>

        {this.state.showDetailsModal && (
          <Modal
            width={1300}
            title={intl.formatMessage(appFieldsMessages.details)}
            centered
            visible
            footer={null}
            onCancel={() => this.setState({ showDetailsModal: false })}
            maskClosable={false}
          >
            <Container className="flex-col flex-min-height" style={{ maxHeight: 600 }}>
              <EventDetailsContent
                intl={intl}
                globalInfo={globalInfo}
                timezoneOffset={timezoneOffset}
                currentLocale={currentLocale}
                updateLastActionInfo={updateLastActionInfo}
                credentials={credentials}
                projects={projects}
                projectDisplayMap={projectDisplayMap}
                environmentId={environmentId}
                systemInfo={systemInfo}
                incident={this.state.activeEvent}
                instanceFilter={instanceFilter}
                handleIgnoreClick={this.handleIgnoreClickOld}
                handleChangePatternNameClick={this.handleChangePatternNameClick}
                handleTriageReportClick={this.handleTriageReportClick}
                handleClick={this.handleClick}
                handleOriginalNormalPatternClick={this.handleOriginalNormalPatternClick}
                handleCausalGraphClick={this.handleCausalGraphClick}
                handleLineChartClick={this.handleLineChartClick}
                handleLineChartJump={this.handleLineChartJump}
                handleIPClick={this.handleIPClick}
                handleRCAClick={this.handleRCAClick}
                handleSuggestedActionsClick={this.handleSuggestedActionsClick}
                handleReportJiraClick={this.handleReportJiraClick}
                handleReportServiceNowClick={this.handleReportServiceNowClick}
                handleDeleteIncidentClick={this.handleDeleteIncidentClick}
                onPredictionSourceLineChartClick={this.onPredictionSourceLineChartClick}
                onPredictionSourceJumpClick={this.onPredictionSourceJumpClick}
                onPredictionSourceTrendPatternsClick={this.onPredictionSourceTrendPatternsClick}
                onPredictionSourceLogQueryClick={this.onPredictionSourceLogQueryClick}
                isIncidentLine={isIncidentLine}
                isActionLine={isActionLine}
                isDeploymentLine={isDeploymentLine}
                currentTheme={currentTheme}
              />
            </Container>
          </Modal>
        )}

        {this.state.showTimeSelectModal && (
          <TimeSelectModal
            projectName={this.state.activeEventProjectName}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            onClose={this.onCloseTimeSelect}
            timeIntervals={1}
            showKeywordSearch
          />
        )}
        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeEvent}
            projectName={this.state.selectProject}
            instanceName={this.state.selectInstance}
            startTimestamp={this.state.selectStartTimestamp}
            endTimestamp={this.state.selectEndTimestamp}
            keywordFilter={this.state.contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
          />
        )}
        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={this.state.actionName}
            incident={this.state.activeEvent}
            project={this.state.activeEventProject}
            projectName={this.state.activeEvent.fullProjectName}
            instanceGroup={
              seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine'
                ? 'All'
                : environmentId
            }
            eventType={this.state.activeEvent.type}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {this.state.showTriageReportModal && (
          <TriageReportModal
            environmentId={environmentId}
            systemId={systemInfo.id}
            incident={this.state.activeEvent}
            onClose={() => this.setState({ showTriageReportModal: false })}
          />
        )}
        {this.state.showReportJiraModal && (
          <ReportJiraModal
            incident={this.state.activeEvent}
            projectName={
              seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine'
                ? this.state.activeEventProjectName
                : this.state.activeEvent.projectName
            }
            onClose={() => this.setState({ showReportJiraModal: false })}
          />
        )}
        {this.state.showReportServiceNowModal && (
          <ReportServiceNowModal
            incident={this.state.activeEvent}
            projectName={
              seriesId === 'healthLine' || seriesId === 'deploymentLine' || seriesId === 'predictionHealthLine'
                ? this.state.activeEventProjectName
                : this.state.activeEvent.projectName
            }
            onClose={() => this.setState({ showReportServiceNowModal: false })}
          />
        )}
        {this.state.showProjectSelector && (
          <ProjectSelectorModal
            system={systemInfo}
            onConfirm={this.state.onConfirmProjectSelect}
            onClose={() => this.setState({ showProjectSelector: false })}
          />
        )}
        {this.state.showEventActionModal && (
          <EventActionModal
            incident={this.state.activeEvent}
            projectName={this.state.activeEvent.projectName}
            projectNameList={this.getProjectNameList()}
            onClose={() => this.setState({ showEventActionModal: false })}
          />
        )}
        {this.state.showInsightQueryBox && (
          <InsightQueryBoxModal
            projects={this.state.queryLogProjects}
            queryParams={this.state.queryParams}
            onConfirm={this.onConfirmInsightQueryProjectSelect}
            onClose={() => this.setState({ showInsightQueryBox: false })}
          />
        )}
      </Modal>
    );
  }
}

const EventDetailsContent = ({
  intl,
  globalInfo,
  timezoneOffset,
  currentLocale,
  updateLastActionInfo,
  credentials,
  projects,
  projectDisplayMap,
  environmentId,
  systemInfo,
  incident,
  instanceFilter,
  handleIgnoreClick,
  handleChangePatternNameClick,
  handleTriageReportClick,
  handleClick,
  handleOriginalNormalPatternClick,
  handleCausalGraphClick,
  handleLineChartClick,
  handleLineChartJump,
  handleIPClick,
  handleRCAClick,
  handleSuggestedActionsClick,
  handleReportJiraClick,
  handleReportServiceNowClick,
  handleDeleteIncidentClick,
  onPredictionSourceLineChartClick,
  onPredictionSourceJumpClick,
  onPredictionSourceTrendPatternsClick,
  onPredictionSourceLogQueryClick,
  isIncidentLine,
  isActionLine,
  isDeploymentLine,
  currentTheme,
}: Object) => {
  const { category, isPrediction, triggeredActionList, validationTimeList } = incident;
  const {
    projectOwner,
    anomalyLogInstance,
    instanceName,
    startTimestamp,
    endTimestamp,
    patternId,
    type,
    timeLineType,
    rootCauseTableKey,
    typeList,
    neuronId,
  } = incident;
  let { projectName } = incident;
  projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
  let predictionSourceInfoList = incident.predictionSourceInfoList || [];
  predictionSourceInfoList = R.addIndex(R.map)((item, index) => {
    return { ...item, hop: index + 1 };
  }, predictionSourceInfoList || []);

  const environment = R.find((e) => e.id === environmentId, globalInfo || []);
  const systemList = get(environment, 'systemList', []);
  const { instanceDisplayNameMap } = R.find((system) => system.id === systemInfo?.systemId, systemList) || {};

  const [isLoadingRootCause, setLoadingRootCause] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [rootCausesData, setRootCausesData] = useState(null);
  const [predictedData, setPredictedData] = useState(null);
  const [summarySettings, setSummarySettings] = useState([]);

  useEffect(() => {
    if (!isPrediction && ['Deployment', 'Incident'].includes(type)) {
      setLoadingRootCause(true);
      // parse params to get root cause
      const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
      const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
      const event = {
        nid: patternId,
        eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
      };
      const request = [
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceName,
          startTime,
          endTime,
          operation: 'rootCauseEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceName,
          startTime,
          endTime,
          operation: 'predictedEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      ];
      updateLastActionInfo();
      Promise.all(request)
        .then((results) => {
          const rootCausesData = results[0];
          const predictedData = results[1];
          setRootCausesData(rootCausesData);
          setPredictedData(predictedData);
          setLoadingRootCause(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingRootCause(false);
        });
    }
    if (!R.includes(category, ['metric'])) {
      fetchGet(getEndpoint('logsummarysettings'), {
        ...credentials,
        projectName,
      })
        .then((results) => {
          const summarySettings = results;
          setSummarySettings(summarySettings);
        })
        .catch((err) => console.log(err));
    }
  }, [refresh]);

  const reloadData = useCallback(() => {
    setRefresh(moment.utc().valueOf());
  }, []);

  const RenderEntry = EventRenderers.RenderEventEntry({
    intl,
    projects,
    idx: 1,
    event: incident,
    instanceName: incident.instanceName || instanceFilter,
    style: {},
    category,
    metricUnitMap: {},
    showIgnore: true,
    showImportant: true,
    onIgnoreClick: handleIgnoreClick,
    showChangePatternName: true,
    onChangePatternNameClick: handleChangePatternNameClick,
    onTriageReportClick: handleTriageReportClick,
    showPatternClick: !isIncidentLine && !isDeploymentLine,
    onPatternClick: handleClick,
    showOriginalNormalPatternClick: typeList.includes('rare') && isNumber(neuronId),
    onOriginalNormalPatternClick: handleOriginalNormalPatternClick,
    showCausalGraph: !isIncidentLine && !isDeploymentLine,
    onCausalGraphClick: handleCausalGraphClick,
    onLineChartClick: handleLineChartClick,
    showLineChartJump: systemInfo && systemInfo.hasMetricProject,
    onLineChartJump: handleLineChartJump,
    showIPJump: timeLineType === 'future',
    onIPClick: handleIPClick,
    showRCAJump: timeLineType !== 'future' && !isDeploymentLine,
    onRCAClick: handleRCAClick,
    showReportJiraModal: true,
    onReportJiraClick: handleReportJiraClick,
    showReportServiceNow: true,
    onReportServiceNowClick: handleReportServiceNowClick,
    showDeleteIncident: isIncidentLine,
    onDeleteIncidentClick: handleDeleteIncidentClick,
    summarySettings,
    environmentId,
    systemInfo,
    isLoading: isLoadingRootCause,
    incident,
    rootCausesData,
    predictedData,
    projectDisplayMap,
    currentTheme,
    instanceDisplayNameMap,
  });
  const RenderActionList = (
    <div>
      {triggeredActionList.length > 0 && (
        <div className="flex-row" style={{ marginBottom: 4, lineHeight: '24px' }}>
          <span style={{ fontSize: 14, fontWeight: 700 }}>{intl.formatMessage(logMessages.triggeredAction)}:</span>
        </div>
      )}
      {triggeredActionList.length > 0 && (
        <div className="event-list" style={{ marginBottom: 16 }}>
          <div className="event-list-header" style={{ height: 32, width: '100%' }}>
            <div className="header-column" style={{ width: 120, flex: 1 }}>
              {intl.formatMessage(eventActionMessages.triggeredTime)}
            </div>
            <div className="header-column" style={{ width: 120, flex: 1 }}>
              {intl.formatMessage(eventActionMessages.actionName)}
            </div>
          </div>
          <div
            className="event-list-grid"
            style={{
              maxHeight: 120,
              overflowY: 'auto',
            }}
          >
            {R.addIndex(R.map)((rowData, index) => {
              const { actionName, timestamp } = rowData;
              return (
                <div
                  key={index}
                  className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                  style={{ minHeight: 40 }}
                >
                  <div className="row-column" style={{ width: 120, flex: 1 }}>
                    {moment.utc(timestamp).format(Defaults.DateTimeFormat)}
                  </div>
                  <div className="row-column" style={{ width: 120, flex: 1 }}>
                    {actionName}
                  </div>
                </div>
              );
            }, triggeredActionList)}
          </div>
        </div>
      )}
      {isPrediction && validationTimeList.length > 0 && (
        <div className="flex-row" style={{ marginBottom: 4, lineHeight: '24px' }}>
          <span style={{ fontSize: 14, fontWeight: 700 }}>{intl.formatMessage(logMessages.validationTime)}:</span>
        </div>
      )}
      {isPrediction && validationTimeList.length > 0 && (
        <div className="event-list" style={{ marginBottom: 16 }}>
          <div className="event-list-header" style={{ height: 32, width: '100%' }}>
            <div className="header-column" style={{ width: 120, flex: 1 }}>
              {intl.formatMessage(appFieldsMessages.startTime)}
            </div>
            <div className="header-column" style={{ width: 120, flex: 1 }}>
              {intl.formatMessage(appFieldsMessages.endTime)}
            </div>
          </div>
          <div
            className="event-list-grid"
            style={{
              maxHeight: 120,
              overflowY: 'auto',
            }}
          >
            {R.addIndex(R.map)((rowData, index) => {
              const { startTime, endTime } = rowData;
              return (
                <div
                  key={index}
                  className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                  style={{ minHeight: 40 }}
                >
                  <div className="row-column" style={{ width: 120, flex: 1 }}>
                    {moment.utc(startTime).format(Defaults.DateTimeFormat)}
                  </div>
                  <div className="row-column" style={{ width: 120, flex: 1 }}>
                    {moment.utc(endTime).format(Defaults.DateTimeFormat)}
                  </div>
                </div>
              );
            }, validationTimeList)}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="full-width full-height overflow-y-auto overflow-x-hidden" style={{ paddingRight: 8 }}>
      {isActionLine && RenderActionList}
      {RenderEntry}
      {isPrediction && (
        <EventRenderers.RenderPredictionTimelines
          intl={intl}
          projects={projects || []}
          metricUnitMap={{}}
          predictionSourceInfoList={predictionSourceInfoList}
          event={incident}
          onLineChartClick={handleLineChartClick}
          onLineChartJump={handleLineChartJump}
          onPredictionSourceLineChartClick={onPredictionSourceLineChartClick}
          onPredictionSourceJumpClick={onPredictionSourceJumpClick}
          onPredictionSourceTrendPatternsClick={onPredictionSourceTrendPatternsClick}
          onPredictionSourceLogQueryClick={onPredictionSourceLogQueryClick}
          summarySettings={summarySettings}
          currentTheme={currentTheme}
          instanceDisplayNameMap={instanceDisplayNameMap}
        />
      )}
      {!isPrediction && (
        <div style={{ height: 500 }}>
          {type === 'Incident' ? (
            <LikelyRootCausesTableRCA
              needRC
              environmentId={environmentId}
              systemId={systemInfo.systemId}
              isLoading={isLoadingRootCause}
              incident={incident}
              rootCausesData={rootCausesData}
              predictedData={predictedData}
              onReload={reloadData}
            />
          ) : (
            <LikelyRootCauses
              environmentId={environmentId}
              systemId={systemInfo.systemId}
              isLoading={isLoadingRootCause}
              incident={incident}
              rootCausesData={rootCausesData}
              predictedData={predictedData}
              onReload={reloadData}
            />
          )}
        </div>
      )}
    </div>
  );
};

const EventShortListModal = injectIntl(EventShortListModalCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const {
      loadStatus,
      timezoneOffset,
      globalInfo,
      currentLocale,
      projects,
      projectDisplayMap,
      systemsMap,
      currentTheme,
      allProjects,
    } = state.app;
    const { credentials, userInfo } = state.auth;
    const { userName } = state.auth.userInfo;
    const { globalSystemLevelTimelines } = state.dashboard;

    return {
      location,
      loadStatus,
      timezoneOffset,
      currentLocale,
      userName,
      credentials,
      userInfo,
      projects,
      projectDisplayMap,
      systemsMap,
      globalInfo,
      globalSystemLevelTimelines,
      currentTheme,
      allProjects,
    };
  },
  { push, createLoadAction, updateLastActionInfo },
)(EventShortListModal);
