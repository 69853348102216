/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import { get, isNumber } from 'lodash';
import { autobind } from 'core-decorators';

import { Container, Table, Column, CellMeasurer, CellMeasurerCache } from '../../../lib/fui/react';
import { Defaults, CellRenderers } from '../../../common/utils';
import { Defaults as DefaultsColor } from '../../../common/app/index';
import { metricUnitParser } from '../../../common/apis/magicParsers';

type Props = {
  width: Number,
  height: Number,
  appNameMapping: Object,
  queryResult: Object,
  queryParams: Object,
};

class AnomalousPattern extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.gridOffsetY = 40;
    this.patternList = [];
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
  }

  componentDidMount() {
    this.preprocessData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.queryResult !== nextProps.queryResult) {
      this.cellMeasureCache.clearAll();
      this.preprocessData(nextProps);
    }
  }

  @autobind
  patternNameRenderer({ rowData }) {
    return Defaults.PatternIdNameStr(rowData, { hasPrefix: true }).patternNameStr;
  }

  @autobind
  preprocessData(props) {
    const { queryResult } = props;
    const { patternResult } = queryResult || {};
    let patternList = JSON.parse(patternResult || '[]') || [];
    patternList = R.map((p) => {
      const detail = [];
      const patternRules = JSON.parse(p.patternRules || '[]') || [];
      R.forEach((pr) => {
        R.forEach((prc) => detail.push(prc), pr.patternContentList || []);
      }, patternRules);
      return {
        ...p,
        detail,
      };
    }, patternList);
    // patternList = R.filter(n => parseInt(n.patternName, 10) >= 0 && n.detail.length > 0, patternList);
    patternList = R.filter((n) => n.detail.length > 0, patternList);
    patternList = R.sortWith([R.descend(R.prop('anomalyRatio'))])(patternList);

    this.patternList = patternList;
  }

  @autobind
  contentRender(props) {
    const { queryResult } = this.props;
    const appNameMapping = get(this.props, ['appNameMapping'], {});
    const { metricUnitMapping } = queryResult;
    const { dataKey, parent, rowIndex, cellData, style } = props;
    const logicCompare = 'AND';

    const msgList = [];
    if (cellData) {
      R.forEach((s) => {
        const { value: normal, sign: direction, instanceName, metricName } = s;
        const metricUnit = R.find(R.propEq('metric', metricName))(metricUnitMapping);
        const unit = metricUnit ? metricUnitParser(metricUnit.unit) : '';
        const compare = direction === 'higher' ? '>' : '<';
        const msg = [appNameMapping[instanceName] || instanceName, metricName, compare, normal, unit];
        msgList.push(msg);
      }, cellData);
    }

    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ ...style }}>
          {msgList.length > 0 &&
            msgList
              .map((t, idx) => (
                <span key={idx}>
                  <span key={t[0]} style={{ color: 'green' }}>
                    {t[0]}
                  </span>&nbsp;
                  <span key={t[1]} style={{ color: 'blue' }}>
                    {t[1]}
                  </span>&nbsp;
                  <span key={t[2]}>{t[2]}</span>&nbsp;
                  <span key={t[3]} style={{ color: 'red' }}>
                    {t[3]}
                  </span>&nbsp;
                  <span key={t[4]}>{t[4]}</span>
                </span>
              ))
              .reduce((prev, curr) => [
                prev,
                <span key={prev}>
                  {' '}
                  <span key={prev} style={{ backgroundColor: 'grey', color: 'white' }}>
                    {' '}
                    {logicCompare}{' '}
                  </span>{' '}
                </span>,
                curr,
              ])}
        </div>
      </CellMeasurer>
    );
  }

  render() {
    const { width, height } = this.props;
    const patternList = this.patternList || [];

    return (
      <Container>
        <Container className="toolbar" style={{ zIndex: 299, paddingTop: 12 }}>
          <Container className="section">
            <h4>Top Anomalous Pattern</h4>
          </Container>
          <Container className="section float-right" />
        </Container>
        <Table
          className="with-border"
          width={width}
          height={height - this.gridOffsetY}
          deferredMeasurementCache={this.cellMeasureCache}
          headerHeight={40}
          rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
          rowHeight={this.cellMeasureCache.rowHeight}
          rowCount={patternList.length}
          rowGetter={({ index }) => patternList[index]}
        >
          <Column width={160} label="Pattern name" dataKey="patternName" cellRenderer={this.patternNameRenderer} />
          <Column width={60} label="Count" dataKey="count" />
          <Column
            width={100}
            label="Anomaly score"
            dataKey="anomalyRatio"
            cellRenderer={CellRenderers.anomalyScoreRenderer}
          />
          <Column
            width={width - 200}
            flexGrow={1}
            label="Details"
            dataKey="detail"
            cellRenderer={this.contentRender}
            className="raw-data"
          />
        </Table>
      </Container>
    );
  }
}

export default AnomalousPattern;
