import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { message, Spin, Select, TreeSelect, Pagination, Divider, Alert, Checkbox } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Options, BackgroundCall } from '../../../../common/utils';
import { DatePicker, Container, Modal } from '../../../../lib/fui/react';
import { Defaults } from '../../../../common/app';
import { updateLastActionInfo, createSetAction } from '../../../../common/app/actions';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  credentials: Object,
  projectName: String,
  // eslint-disable-next-line react/no-unused-prop-types
  projects: Array<Object>,
  onClose: Function,

  // eslint-disable-next-line react/no-unused-prop-types
  projectInstanceComponentMap: Object,
  updateLastActionInfo: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  createSetAction: Function,
  maintenanceDataMap: Object,
  changeMaintenanceDataMap: Function,
};

class MaintenanceDayModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSubmitting: false,

      period: 'range',
      startTime: moment.utc().startOf('day').valueOf(),
      endTime: moment.utc().startOf('day').valueOf(),
      dayInPeriod: '',
      startHour: null,
      startMinute: null,
      endHour: null,
      endMinute: null,

      treeData: [],
      instanceComponentList: [],
      instancePage: 1,
      instanceDropdownOpen: false,
      instanceOnBlurDisable: false,

      fetchingInstanceValue: '',
      fetchingTreeData: [],
    };
    this.periodOptions = [
      { label: 'Range', value: 'range' },
      { label: 'Every day', value: 'day' },
      { label: 'Every Week', value: 'week' },
      { label: 'Every Month', value: 'month' },
    ];
    this.dayOptions = [
      { label: 'First', value: 'first' },
      { label: 'Last', value: 'last' },
    ];
    this.componentInsMap = {};
    this.componentAndInsMap = {};
    this.valueMap = {};
    this.instancePageSize = 10;
    this.refInstanceSelection = React.createRef();
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projectName !== this.props.projectName) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  handleOnClose(created) {
    const { onClose } = this.props;
    if (onClose) {
      onClose(created);
    }
  }

  @autobind
  reloadData(props) {
    const {
      intl,
      credentials,
      projectName,
      projects,
      projectInstanceComponentMap,
      createSetAction,
      updateLastActionInfo,
      maintenanceDataMap,
      changeMaintenanceDataMap,
    } = props;
    this.setState({ isLoading: true });

    const project = R.find((project) => project.projectName === projectName, projects || []) || {};

    if (!R.isEmpty(maintenanceDataMap)) {
      this.componentInsMap = maintenanceDataMap.componentInsMap;
      this.componentAndInsMap = maintenanceDataMap.componentAndInsMap;
      this.valueMap = maintenanceDataMap.valueMap;
      this.setState({ isLoading: false, treeData: maintenanceDataMap.treeData });
      return;
    }

    updateLastActionInfo();
    fetchPost(getEndpoint('loadProjectsMetaDataInfo'), {
      ...credentials,
      projectList: JSON.stringify([{ projectName: project.projectShortName, customerName: project.owner }]),
      startTime: moment.utc().startOf('day').subtract(1, 'days').valueOf(),
      endTime: moment.utc().endOf('day').valueOf(),
      includeInstance: true,
    })
      .then(async (data) => {
        const projectMetaData = get(data, 'data', [])[0];
        const instanceStructureSet = get(projectMetaData, ['instanceStructureSet'], []);
        let instanceNameList = [];
        R.forEach((inc) => {
          const { i, c } = inc || {};
          if (i) {
            instanceNameList = [...instanceNameList, i];
            R.forEach((item) => {
              instanceNameList = [...instanceNameList, `${item}_${i}`];
            }, c || []);
          }
        }, instanceStructureSet || []);

        // get all components from instances
        const prevInstanceComponentMap = get(projectInstanceComponentMap, projectName, {});
        const { hasError, allInstanceComponentMap } = await BackgroundCall.GetSetComponent({
          updateLastActionInfo,
          createSetAction,
          credentials,
          prevInstanceComponentMap,
          projectName,
          instanceNameList,
        });

        // build appNameList
        if (hasError) {
          throw new Error('Get components faild.');
        }
        const appNameList = !R.isEmpty(allInstanceComponentMap)
          ? { ...prevInstanceComponentMap, ...allInstanceComponentMap }
          : prevInstanceComponentMap;

        R.forEach((inst) => {
          if (!appNameList?.[inst]) {
            appNameList[inst] = inst;
          }
        }, instanceNameList);

        // build tree list
        const componentInsMap = {};
        const componentAndInsMap = {};
        R.forEachObjIndexed((cmp, ins) => {
          if (!R.has(cmp, componentInsMap)) componentInsMap[cmp] = [];
          componentInsMap[cmp].push(ins);
          if (!R.has(cmp, componentAndInsMap)) componentAndInsMap[cmp] = [];
          componentAndInsMap[cmp].push(`${cmp}-${ins}`);
        }, appNameList || {});

        const valueMap = {};
        let treeData = [];
        R.forEachObjIndexed((insList, cmp) => {
          const children = R.map((ins) => {
            const key = `${cmp}-${ins}`;
            valueMap[key] = { isInstance: true, value: ins };
            return {
              title: ins,
              value: key,
              key,
            };
          }, insList);
          const key = cmp;
          valueMap[key] = { isComponent: true, value: cmp };
          treeData.push({
            title: cmp,
            value: key,
            key,
            children,
          });
        }, componentInsMap);
        treeData = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('title')))], treeData);

        changeMaintenanceDataMap({ componentInsMap, componentAndInsMap, valueMap, treeData });

        this.componentInsMap = componentInsMap;
        this.componentAndInsMap = componentAndInsMap;
        this.valueMap = valueMap;
        this.setState({ isLoading: false, treeData });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, treeData: [] });
      });
  }

  @autobind
  handleSumbit() {
    const { intl, credentials, projectName } = this.props;
    const { period, startTime, endTime, dayInPeriod, startHour, startMinute, endHour, endMinute } = this.state;
    const { instanceComponentList } = this.state;
    const self = this;

    if (projectName && period) {
      self.setState({ isSubmitting: true });

      let dateKey = '';
      let params = {};
      if (['day', 'week', 'month'].includes(period)) {
        dateKey = dayInPeriod;
        if (startHour && startMinute && endHour && endMinute) {
          const startTimeObj = { hour: startHour, minute: startMinute };
          const endTimeObj = { hour: endHour, minute: endMinute };
          params = { ...params, startTimeObj: JSON.stringify(startTimeObj), endTimeObj: JSON.stringify(endTimeObj) };
        }
      } else if (period === 'range' && startTime && endTime) {
        dateKey = `${startTime},${endTime}`;
      }

      // get instances from tree select
      let instanceNameList = [];
      R.forEach((item) => {
        const data = this.valueMap[item];
        if (data) {
          const { value, isInstance } = data;
          if (isInstance) {
            instanceNameList.push(value);
          } else {
            const insList = this.componentInsMap[value] || [];
            instanceNameList = [...instanceNameList, ...insList];
          }
        }
      }, instanceComponentList);

      params = {
        ...credentials,
        ...params,
        projectName,
        key: period,
        dateKey,
        ...(instanceNameList && instanceNameList.length > 0
          ? { instanceNameList: JSON.stringify(instanceNameList || []) }
          : {}),
      };

      this.props.updateLastActionInfo();
      fetchPost(getEndpoint('specialday'), params, {}, false)
        .then((d) => {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          self.setState({ isSubmitting: false });
          this.handleOnClose(true);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          self.setState({ isSubmitting: false });
        });
    }
  }

  @autobind
  handleTimeChange({ time }) {
    return (dateObj) => {
      if (dateObj) {
        const selectTime = dateObj.valueOf();
        this.setState({ [time]: selectTime });
      }
    };
  }

  @autobind
  handleInstancePageChange(instancePage) {
    this.setState({ instancePage });
  }

  @autobind
  handleInstanceSearch(fetchingInstanceValue) {
    const fetchingTreeData = [];
    if (fetchingInstanceValue) {
      const { treeData } = this.state;
      R.forEach((item) => {
        if (item.title.toLowerCase().indexOf(fetchingInstanceValue.toLowerCase()) >= 0) {
          // filter component
          fetchingTreeData.push(item);
        } else {
          // filter instance
          let children = item.children || [];
          children = R.filter((c) => c.title.toLowerCase().indexOf(fetchingInstanceValue.toLowerCase()) >= 0, children);
          if (children.length > 0) {
            fetchingTreeData.push({
              ...item,
              children,
            });
          }
        }
      }, treeData);
    }

    // end searching
    this.setState({ fetchingInstanceValue, fetchingTreeData, instancePage: 1 });
  }

  render() {
    const { intl } = this.props;
    const {
      isLoading,
      isSubmitting,
      period,
      dayInPeriod,
      startTime,
      endTime,
      startHour,
      startMinute,
      endHour,
      endMinute,
      treeData,
      instanceComponentList,
      instancePage,
      fetchingInstanceValue,
      fetchingTreeData,
    } = this.state;

    let dayOptions = [];
    if (period === 'week') {
      dayOptions = Options.WeekOptions;
    } else if (period === 'month') {
      dayOptions = R.concat(
        this.dayOptions,
        R.map((d) => ({ label: d.toString(), value: d.toString() }), R.range(1, 31)),
      );
    }
    const startTimeObj = moment.utc(startTime);
    const endTimeObj = moment.utc(endTime);

    const instanceError = instanceComponentList.length === 0;
    let hasError = false;
    if (period === 'range') {
      hasError = !startTime || !endTime || instanceError;
    } else if (period === 'day') {
      hasError = !startHour || !startMinute || !endHour || !endMinute || instanceError;
    } else {
      hasError = !dayInPeriod || !startHour || !startMinute || !endHour || !endMinute || instanceError;
    }

    const displayTreeData = fetchingInstanceValue ? fetchingTreeData : treeData;
    const treeDataWithPaging = R.slice(
      (instancePage - 1) * this.instancePageSize,
      instancePage * this.instancePageSize,
      displayTreeData,
    );
    return (
      <Modal
        title={intl.formatMessage(appButtonsMessages.add)}
        visible
        maskClosable={false}
        onCancel={() => this.handleOnClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isSubmitting }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <Container className="flex-col">
            <Alert
              description="All maintenance windows are described in the UTC timezone."
              type="info"
              showIcon
              style={{ marginBottom: 12 }}
            />

            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.mode)}:</div>
              <Select
                size="small"
                style={{ width: 200 }}
                value={period}
                onChange={(period) => this.setState({ period })}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.value}>{item.label}</Select.Option>
                  ),
                  this.periodOptions || [],
                )}
              </Select>
              <div className="flex-grow" />
            </div>
            {['week', 'month'].includes(period) && (
              <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
                <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.on)}:</div>
                <Select
                  size="small"
                  style={{ width: 100 }}
                  value={dayInPeriod}
                  onChange={(dayInPeriod) => this.setState({ dayInPeriod })}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.value}>{item.label}</Select.Option>
                    ),
                    dayOptions || [],
                  )}
                </Select>
                <div style={{ width: 30, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.day)}</div>
                <div className="flex-grow" />
              </div>
            )}
            {period !== 'range' && (
              <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
                <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.from)}:</div>
                <Select
                  size="small"
                  style={{ width: 100 }}
                  value={startHour}
                  onChange={(startHour) => this.setState({ startHour })}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.value}>{item.label}</Select.Option>
                    ),
                    Options.HourOptions || [],
                  )}
                </Select>
                <div style={{ width: 50, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.hour)}</div>
                <Select
                  size="small"
                  style={{ width: 100 }}
                  value={startMinute}
                  onChange={(startMinute) => this.setState({ startMinute })}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.value}>{item.label}</Select.Option>
                    ),
                    Options.MinuteOptions || [],
                  )}
                </Select>
                <div style={{ width: 50, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.minute)}</div>
              </div>
            )}
            {period !== 'range' && (
              <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
                <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.to)}:</div>
                <Select
                  size="small"
                  style={{ width: 100 }}
                  value={endHour}
                  onChange={(endHour) => this.setState({ endHour })}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.value}>{item.label}</Select.Option>
                    ),
                    Options.HourOptions || [],
                  )}
                </Select>
                <div style={{ width: 50, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.hour)}</div>
                <Select
                  size="small"
                  style={{ width: 100 }}
                  value={endMinute}
                  onChange={(endMinute) => this.setState({ endMinute })}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.value}>{item.label}</Select.Option>
                    ),
                    Options.MinuteOptions || [],
                  )}
                </Select>
                <div style={{ width: 50, marginLeft: 4 }}>{intl.formatMessage(appFieldsMessages.minute)}</div>
              </div>
            )}

            {period === 'range' && (
              <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
                <div style={{ width: 100, textAlign: 'left' }}>{intl.formatMessage(appFieldsMessages.from)}:</div>
                <div className="ui input datePicker">
                  <DatePicker
                    utcOffset={0}
                    className="time"
                    style={{ width: 130 }}
                    displayDateFormat="yyyy-MM-dd HH:mm"
                    dateFormat={Defaults.TimeFormat}
                    todayButton={intl.formatMessage(appFieldsMessages.today)}
                    showYearDropdown
                    showMonthDropdown
                    showTimeSelect
                    selected={startTimeObj}
                    onChange={this.handleTimeChange({ time: 'startTime' })}
                  />
                </div>
                <div style={{ width: 50, textAlign: 'left', marginLeft: 16 }}>
                  {intl.formatMessage(appFieldsMessages.to)}:
                </div>
                <div className="ui input datePicker">
                  <DatePicker
                    utcOffset={0}
                    className="time"
                    style={{ width: 130 }}
                    displayDateFormat="yyyy-MM-dd HH:mm"
                    dateFormat={Defaults.TimeFormat}
                    todayButton={intl.formatMessage(appFieldsMessages.today)}
                    showYearDropdown
                    showMonthDropdown
                    showTimeSelect
                    selected={endTimeObj}
                    onChange={this.handleTimeChange({ time: 'endTime' })}
                  />
                </div>
              </div>
            )}

            <div className="flex-row flex-center-align" style={{ marginBottom: 12 }}>
              <div style={{ width: 100, textAlign: 'left' }}>
                {intl.formatMessage(appFieldsMessages.instance)}
                <span style={{ color: 'red' }}>*</span>:
              </div>
              <div className="flex-grow overflow-hidden">
                <DebounceTreeSelect
                  fetchOptions={this.handleInstanceSearch}
                  compoenntRef={this.refInstanceSelection}
                  open={this.state.instanceDropdownOpen}
                  size="small"
                  style={{ width: '100%' }}
                  maxTagCount={10}
                  treeCheckable
                  showCheckedStrategy={TreeSelect.SHOW_PARENT}
                  treeNodeFilterProp="title"
                  treeData={treeDataWithPaging}
                  value={instanceComponentList}
                  onChange={(instanceComponentList) => {
                    let instanceNameList = [];
                    R.forEach((item) => {
                      const data = this.valueMap[item];
                      if (data) {
                        const { value, isInstance } = data;
                        if (isInstance) {
                          instanceNameList.push(item);
                        } else {
                          const findInfo = R.find((item) => item.value === value, treeDataWithPaging || []);
                          instanceNameList = [
                            ...instanceNameList,
                            ...R.map((item) => item.value, findInfo.children || []),
                          ];
                        }
                      }
                    }, instanceComponentList);
                    this.setState({ instanceComponentList: instanceNameList });
                  }}
                  filterTreeNode={false}
                  showSearch
                  autoClearSearchValue={false}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650, minWidth: 370, maxHeight: 400 }}
                  onDropdownVisibleChange={(open) => {
                    if (!this.state.instanceOnBlurDisable) {
                      const newState = { instanceDropdownOpen: open };
                      if (!open) {
                        newState.fetchingInstanceValue = '';
                        newState.fetchingTreeData = [];
                      }
                      this.setState(newState);
                    }
                  }}
                  dropdownRender={(menu) => {
                    let allInstances = [];
                    let selectAll = [];
                    let instanceNameList = [];
                    R.forEach((item) => {
                      const data = this.valueMap[item];
                      if (data) {
                        const { value, isInstance } = data;
                        if (isInstance) {
                          instanceNameList.push(item);
                        } else {
                          const insList = this.componentAndInsMap[value] || [];
                          instanceNameList = [...instanceNameList, ...insList];
                        }
                      }
                    }, instanceComponentList);
                    allInstances = R.unnest(
                      R.map((item) => R.map((_item) => _item.value, item.children || []), treeDataWithPaging || []),
                    );
                    selectAll = R.difference(allInstances, instanceNameList).length === 0;

                    return (
                      <div>
                        <div
                          className="flex-row"
                          style={{ padding: '5px 24px' }}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={selectAll}
                            onChange={(e) => {
                              const { checked } = e.target;
                              const filterList = R.difference(instanceNameList, allInstances);
                              if (checked) {
                                this.setState({ instanceComponentList: [...filterList, ...allInstances] });
                              } else {
                                this.setState({ instanceComponentList: filterList });
                              }
                            }}
                          />
                          <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                        </div>
                        <Divider style={{ margin: '4px 0' }} />
                        {menu}
                        <Divider style={{ margin: '2px 0' }} />
                        <div
                          style={{ padding: '4px 8px' }}
                          onMouseDown={(event) => event.preventDefault()}
                          onMouseEnter={() => this.setState({ instanceOnBlurDisable: true })}
                          onMouseLeave={() => this.setState({ instanceOnBlurDisable: false })}
                        >
                          <Pagination
                            size="small"
                            total={displayTreeData.length}
                            current={instancePage}
                            onChange={(current) => {
                              this.handleInstancePageChange(current);
                              this.refInstanceSelection.current.focus();
                            }}
                            pageSize={this.instancePageSize}
                            showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
                            showSizeChanger
                            pageSizeOptions={['10', '20', '40', '60', '80', '100']}
                            onShowSizeChange={(current, pageSize) => {
                              this.instancePageSize = pageSize;
                              this.handleInstancePageChange(current);
                              this.refInstanceSelection.current.focus();
                            }}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </Container>
        </Spin>
      </Modal>
    );
  }
}

// eslint-disable-next-line
const DebounceTreeSelect = ({ debounceTimeout = 800, fetchOptions, compoenntRef, ...props }) => {
  return (
    <TreeSelect
      ref={compoenntRef}
      onSearch={React.useMemo(() => {
        const loadOptions = (value) => {
          fetchOptions(value);
        };
        return debounce(loadOptions, debounceTimeout);
      }, [fetchOptions, debounceTimeout])}
      {...props}
    />
  );
};

const MaintenanceDayModal = injectIntl(MaintenanceDayModalCore);
export default connect(
  (state) => {
    const { projectInstanceComponentMap } = state.app;
    return { projectInstanceComponentMap };
  },
  { updateLastActionInfo, createSetAction },
)(MaintenanceDayModal);
