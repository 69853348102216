/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';
import * as R from 'ramda';

import type { Deps } from '../../types';
import { postLogPatternName } from '../../apis';
import { setLoadingComponents, updateLastActionInfo } from '../../app/actions';
import { apiEpicErrorHandle } from '../../errors';
import { updateLogPatternName, updateLogPatternSequenceName } from '../actions';

const setPatternNameEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('SET_LOG_PATTERN_NAME').concatMap((action) => {
    const state = getState();
    const {
      projectName,
      view,
      instanceName,
      patternId,
      incidentId,
      patternName,
      components,
      isPatternSequence,
      sequencePatterns,
    } = action.payload;
    const { credentials } = state.auth;

    const apiAction$ = Observable.from(
      postLogPatternName(credentials, projectName, {
        incidentId,
        instanceName,
        patternId,
        patternName,
        isPatternSequence,
        sequencePatterns,
      }),
    )
      .concatMap(() => {
        if (isPatternSequence) {
          return Observable.of(updateLogPatternSequenceName(view, patternId, patternName));
        }
        return Observable.of(updateLogPatternName(view, patternId, patternName));
      })
      .catch((err) => {
        return apiEpicErrorHandle(err);
      });

    return Observable.concat(
      Observable.of(setLoadingComponents(components)),
      Observable.of(updateLastActionInfo()),
      apiAction$,
      Observable.of(setLoadingComponents(R.mapObjIndexed(() => false, components))),
    );
  });

export default setPatternNameEpic;
