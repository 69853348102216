/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';

import KPIPredictionChart from './views/KPIPredictionChart';
import AnomalyEvent from './views/AnomalyEvent';
import TicketEvent from './views/TicketEvent';
import TracingEvent from './views/TracingEvent';
import NormalBehavior from './views/NormalBehavior';
import RawContent from './views/RawContent';
// import InstanceStatus from './views/InstanceStatus';
import RareLogEvent from './views/RareLogEvent';
import LogKeywordEvent from './views/LogKeywordEvent';
import AnomalyLogEvent from './views/AnomalyLogEvent';
import AnomalousPattern from './views/AnomalousPattern';
import TopestList from './views/TopestList';
import ProjectCausalGraph from './views/ProjectCausalGraph';
import MetricTreemap from './views/MetricTreemap';
// import MetricCyclicPattern from './views/MetricCyclicPattern';
// import AnomaliesEvent from './views/AnomaliesEvent';
// import PredictedResourceUsage from './views/PredictedResourceUsage';
// import KPIAnomalies from './views/KPIAnomalies';
import KPIViolationPrediction from './views/KPIViolationPrediction';
import AllLogPatterns from './views/AllLogPatterns';
import CommonlyLogPatterns from './views/CommonlyLogPatterns';
// import CommonlySeenLogSequencies from './views/CommonlySeenLogSequencies';
// import APMAnomalies from './views/APMAnomalies';
import SummaryMetricReport from './views/SummaryMetricReport';
import MetricAppForecast from './views/MetricAppForecast';
import RelatedMetricAnomalies from './views/RelatedMetricAnomalies';
import RecentEvent from './views/RecentEvent';
import AllLogEvent from './views/AllLogEvent';
import LogAllEntries from './views/LogAllEntries';
import LiveTailLogTail from './views/LiveTailLogTail';

const createResultViewer = ({ typeId, meta, result, allInstances, ...rest }: Object, props, queryParams) => {
  const componentProps = {
    key: typeId,
    id: typeId,
    metadata: meta,
    queryResult: result,
    otherResult: rest,
    queryParams,
    allInstances,
    ...props,
  };
  const { templateId } = queryParams;

  const typeIdComponentMap = {
    '649e741241f2457e9c6539a15d50128b': NormalBehavior,
    '8f8f61ab21c44eb3aafa6805685eb8ff': KPIPredictionChart,
    '535c5454d87d49bd970f18e311029a36': AnomalyEvent,
    // '8d49a00c0dcb4c45b65d792b37990e2e': InstanceStatus,
    '0377137ccb9f4415b14908cb7e41a7fa': RareLogEvent,
    f96e81986acd4baca8c6ded33e1f4c8c: AnomalyLogEvent,
    '44295d4bd7c0486693af4e6a2ae5e943': AnomalousPattern,
    c550706ee50d4b77a076b6127f1ca2ce: TopestList,
    de593074c127470b9758fe0182e6cb4b: ProjectCausalGraph,
    e3c3c5e8172f4a1396988747e6dc09be: MetricTreemap,
    // '680e10ddf5ac44b8867690c0ff472c67': MetricCyclicPattern,
    // '9759f4d187b2444098b6aad09fb9fb15': AnomaliesEvent,
    // d3554c441470434a958e0fc26cd7fb5a: PredictedResourceUsage,
    // '6fff86729e6749be963227242f39d176': KPIAnomalies,
    '668119e595b3420dad08dd78b4299be6': KPIViolationPrediction,
    de9b464361c544efb3615eaeb1136b87: AllLogPatterns,
    '9076db738dc24cf38e4f9db53ba1deb3': CommonlyLogPatterns,
    // b4c574e94f514557b649afc59115d3c1: CommonlySeenLogSequencies,
    // a9081107b5a040b196d58712e266913a: APMAnomalies,
    d3095679cdb34e4ab7ec599f82ac87a6: SummaryMetricReport,
    '6f0627d11427480b95e8d012f83c83f0': MetricAppForecast,
    edcd5fef4e8243faa907a02a39d0fea5: RelatedMetricAnomalies,
    '78e1072bae6b48b783214a0658425e14': TicketEvent,
    a73d0c1ac08146eba9bfbf7bd09c5f20: TracingEvent,
    f1f82460f99711e9aaef0800200c9a66: RecentEvent,
    eadc0e347ad24cae84bb32aaefe72418: AllLogEvent,
    // eslint-disable-next-line camelcase
  };

  let component = typeIdComponentMap[typeId] || RawContent;

  // TODO: Change the view for different query
  if (templateId === '668119e595b3420dad08dd78b4299be6') {
    component = KPIViolationPrediction;
  } else if (templateId === 'b81364228d574502b7f6c3e454b9a21b') {
    component = LogKeywordEvent;
  } else if (templateId === 'search_all_log_entries') {
    component = LogAllEntries;
  } else if (templateId === 'live_tail_log_entries') {
    component = LiveTailLogTail;
  }

  return React.createElement(component, componentProps);
};

export default createResultViewer;
