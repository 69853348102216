import * as R from 'ramda';
import React from 'react';

import { appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import { settingsMessages } from '../../../common/settings/messages';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

export const getCacheData = (key = {}) => {
  let cacheDataMap = sessionStorage.getItem('dashboardApiMap');
  try {
    cacheDataMap = JSON.parse(cacheDataMap) || {};
  } catch (error) {
    cacheDataMap = {};
  }
  key = R.trim(R.values(key).join('-')).toLowerCase();
  return cacheDataMap[key];
};

export const setCacheData = (key = {}, data) => {
  let cacheDataMap = sessionStorage.getItem('dashboardApiMap');
  try {
    cacheDataMap = JSON.parse(cacheDataMap) || {};
  } catch (error) {
    cacheDataMap = {};
  }
  key = R.trim(R.values(key).join('-')).toLowerCase();
  cacheDataMap[key] = data;
  sessionStorage.setItem('dashboardApiMap', JSON.stringify(cacheDataMap));
};

export const getInstanceDisplayNameFn = ({
  instanceDisplayNameMap = {},
  instanceName = '',
  instanceTitle,
  intl,
  isCost,
  style,
}) => {
  const {
    instanceDisplayName,
    instanceName: onlyInstanceName,
    containerName,
  } = getInstanceDisplayName(instanceDisplayNameMap, instanceName);
  const width = instanceDisplayName ? 140 : 110;

  const viewContent = (
    <>
      {containerName && (
        <div>
          <span className="formatter-lable" style={{ width, ...style }}>
            {intl.formatMessage(eventMessages.containerName)}:
          </span>
          <span className="formatter-content">{containerName}</span>
        </div>
      )}
      <div>
        <span className="formatter-lable" style={{ width, ...style }}>
          {isCost && instanceTitle
            ? instanceTitle
            : intl.formatMessage(containerName ? settingsMessages.podName : appFieldsMessages.instanceName)}
          :
        </span>
        <span className="formatter-content">{onlyInstanceName}</span>
      </div>
      {instanceDisplayName && (
        <div>
          <span className="formatter-lable" style={{ width, ...style }}>
            {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
          </span>
          <span className="formatter-content">{instanceDisplayName}</span>
        </div>
      )}
    </>
  );

  return { instanceDisplayName, viewContent, onlyInstanceName, width };
};
