/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React, { useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import { Spin } from 'antd';

// eslint-disable-next-line
const MediaPlayer = ({ url }) => {
  const [mediaReady, setmMdiaReady] = useState(false);
  return (
    <Spin spinning={!mediaReady}>
      <ReactPlayer url={url} controls onReady={() => setmMdiaReady(true)} />
    </Spin>
  );
};

export default MediaPlayer;
