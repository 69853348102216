/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import { get, isInteger } from 'lodash';
import momenttz from 'moment-timezone';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { RightOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Switch,
  Form,
  Input as AntInput,
  TreeSelect,
  Checkbox,
  Divider,
  Select as AntSelect,
  Alert,
  message,
} from 'antd';

import { appButtonsMessages, appMessages } from '../../../../common/app/messages';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import fetchGet from '../../../../common/apis/fetchGet';
import { Container } from '../../../../lib/fui/react';

const SPLITKEY = '&SPLITKEY&';

type Props = {
  intl: Object,
  credentials: Object,
  currentProject: Object,
};

class EndpointSettingNagiosCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      hasError: false,
      errorMessage: '',
      verified: true,
      isVerifying: false,
      hostURL: '',
      apiKey: '',
      hosts: [],
      hostOptions: [],
      instanceTimezone: '',
    };

    this.dataTypeOptions = [{ label: 'Alert', value: 'nagiosXIAlert' }];

    this.filterHostOptions = {};
    this.allHosts = [];

    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  reloadData(props) {
    const { credentials, projectName, currentProject, dataType } = props;
    const cloudType = get(currentProject, ['cloudType']);

    this.setState({ isLoading: true });
    fetchGet(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      projectName,
      cloudType,
    })
      .then((res) => {
        const { success, message, hostURL, apiKey, instances, zone } = res;
        if (success || success === undefined) {
          this.parseHostOptions(res);
          const hosts = [];
          R.forEachObjIndexed((value, key) => {
            hosts.push(`${value || 'unknow'}${SPLITKEY}${key}`);
          }, instances);
          this.setState({
            hosts,
            hostURL,
            apiKey,
            instanceTimezone: zone,
            isLoading: false,
            hasError: false,
            errorMessage: undefined,
          });
        } else {
          this.setState({
            hasError: true,
            errorMessage: message,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  @autobind
  handleVerifyClick() {
    this.setState({
      isVerifying: true,
    });
    const { intl, credentials } = this.props;
    const { hostURL, apiKey } = this.state;
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: 'nagiosXIAlert',
      hostURL,
      apiKey,
    })
      .then((d) => {
        const { success, message: errMsg } = d || {};
        if (success === undefined || success) {
          this.filterHostOptions = d;
          d.allHosts = d?.hosts;
          this.parseHostOptions(d);

          this.setState({
            hosts: [],
            isVerifying: false,
            verified: true,
            errorMessage: undefined,
            hasError: false,
            instanceTimezone: 'Etc/GMT',
          });
        } else {
          this.filterHostOptions = {};
          this.setState({
            isVerifying: false,
            verified: false,
            errorMessage: errMsg,
            hostOptions: [],
            hosts: [],
            hasError: true,
          });
        }
      })
      .catch((err) => {
        this.filterHostOptions = {};
        this.setState({
          isVerifying: false,
          verified: false,
          errorMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          hosts: [],
          hostOptions: [],
          hasError: true,
        });
      });
  }

  @autobind
  parseHostOptions(data, searchValue) {
    data = R.clone(data);
    const { hostGroups = {} } = data || {};
    let { allHosts: hosts = [] } = data || {};

    let uniqHost = [];

    R.forEachObjIndexed((values, key) => {
      uniqHost = [...uniqHost, ...values];
    }, hostGroups);
    hosts = R.difference(hosts, uniqHost);
    const hostOptions = [];
    if (hosts.length > 0) {
      hostGroups.unknow = hosts;
    }

    R.addIndex(R.forEachObjIndexed)((value, key, record, index) => {
      const children = [];
      R.forEach((item) => {
        if (searchValue && item.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
          children.push({ key: `${key}${SPLITKEY}${item}`, value: `${key}${SPLITKEY}${item}`, label: item });
        } else if (!searchValue) {
          children.push({ key: `${key}${SPLITKEY}${item}`, value: `${key}${SPLITKEY}${item}`, label: item });
        }
      }, value || []);

      hostOptions.push({ key, value: key, label: key, children });
    }, hostGroups || {});
    let allHosts = [];
    R.forEachObjIndexed((value, key) => {
      allHosts = [...allHosts, ...R.map((item) => item.value, value.children)];
    }, hostOptions);
    this.allHosts = allHosts;
    this.setState({
      hostOptions,
    });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const { intl, credentials, currentProject, projectName } = this.props;

    const { hosts, hostURL, apiKey, instanceTimezone } = this.state;
    const { cloudType } = currentProject;
    const hostObj = {};
    R.forEach((item) => {
      try {
        const [service, host] = item.split(SPLITKEY);
        hostObj[host] = service === 'unknow' ? '' : service;
      } catch (error) {
        console.log(error);
      }
    }, hosts);
    this.setState({ isLoading: true });
    fetchPost(getEndpoint('thirdpartysetting', 2), {
      ...credentials,
      hosts: JSON.stringify(hostObj),
      hostURL,
      apiKey,
      instanceTimezone,
      cloudType,
      projectName,
    })
      .then((res) => {
        const { success, message: err } = res;
        if (success || success === undefined) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({
            isLoading: false,
            errorMessage: undefined,
            hasError: false,
          });
        } else {
          this.setState({
            isLoading: false,
            errorMessage: err.message || String(err),
            hasError: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
          errorMessage: err.message || String(err),
          hasError: true,
        });
      });
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { intl } = this.props;
    const {
      dataType,
      verified,
      isVerifying,
      hostURL,
      apiKey,
      hostOptions,
      hosts,
      instanceTimezone,
      hasError,
      errorMessage,
      isLoading,
    } = this.state;

    const hasVerifyError = !hostURL || !apiKey;
    const hasAlertError = hasVerifyError || !hosts.length || !verified;

    return (
      <Container
        fullHeight
        className={`overflow-y-auto ${isLoading ? ' loading' : ''}`}
        style={{ fontSize: 14, rowGap: 16 }}
      >
        {hasError && (
          <Alert message="Error" description={errorMessage} type="error" showIcon style={{ marginBottom: 16 }} />
        )}
        <Form layout="vertical">
          <>
            <Form.Item required label="Service URL">
              <AntInput.Password
                value={hostURL}
                onChange={(e) => this.setState({ hostURL: e.target.value })}
                visibilityToggle={false}
              />
            </Form.Item>

            <Form.Item required label="Api key">
              <AntInput.Password
                value={apiKey}
                onChange={(e) => this.setState({ apiKey: e.target.value })}
                visibilityToggle={false}
              />
            </Form.Item>
          </>
        </Form>
        <Button
          type="primary"
          loading={isVerifying}
          disabled={hasVerifyError}
          onClick={this.handleVerifyClick}
          style={{ marginBottom: 20 }}
        >
          Verify
        </Button>
        <Form layout="vertical">
          <Form.Item required label="Hosts">
            <TreeSelect
              className="no-count-num"
              treeDefaultExpandAll
              autoClearSearchValue={false}
              treeData={hostOptions}
              showSearch
              multiple
              treeCheckable
              value={hosts}
              onChange={(hosts) => this.setState({ hosts })}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              style={{ width: '100%' }}
              onSearch={(val) => {
                this.parseHostOptions(this.filterHostOptions, val);
              }}
              dropdownRender={(menu) => {
                return (
                  <div>
                    <div
                      className="flex-row"
                      style={{ padding: '5px 12px' }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={hosts.length === this.allHosts.length}
                        onChange={(e) => {
                          const { checked } = e.target;
                          this.setState({
                            hosts: checked ? this.allHosts : [],
                          });
                        }}
                      />
                      Select all
                    </div>
                    <Divider style={{ margin: '4px 0' }} />
                    {menu}
                  </div>
                );
              }}
            />
          </Form.Item>

          <Form.Item label="Instance timezone">
            <AntSelect
              options={this.timezoneOptions}
              showSearch
              value={instanceTimezone}
              onChange={(instanceTimezone) => this.setState({ instanceTimezone })}
            />
          </Form.Item>
        </Form>
        <Button type="primary" disabled={hasAlertError} onClick={this.handleConfirmClick}>
          {intl.formatMessage(appButtonsMessages.update)}
        </Button>
      </Container>
    );
  }
}

const EndpointSettingNagios = injectIntl(EndpointSettingNagiosCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(EndpointSettingNagios);
