import React from 'react';
import { Loader } from '../../../lib/fui/react';
import logo from '../../../../images/InsightFinder_Horizontal.svg';
import logoWhite from '../../../../images/InsightFinder_Horizontal_White.svg';

type Props = {
  visible: boolean,
  currentTheme: String,
};

const AppLoader = ({ visible, currentTheme }: Props) =>
  visible ? <Loader fullScreen imageSrc={currentTheme === 'light' ? logo : logoWhite} size={120} visible /> : null;

export default AppLoader;
