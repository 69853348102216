/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 **/

import createCausalGroupEpic from './createCausalGroupEpic';
import createCausalIncidentEpic from './createCausalIncidentEpic';
import loadCausalIncidentEpic from './loadCausalIncidentEpic';
import updateCausalGroupEpic from './updateCausalGroupEpic';
import removeCausalGroupEpic from './removeCausalGroupEpic';
import loadEventMetricMapEpic from './loadEventMetricMapEpic';
import shareCausalGroupEpic from './shareCausalGroupEpic';

const epics = [
  createCausalGroupEpic,
  createCausalIncidentEpic,
  loadCausalIncidentEpic,
  updateCausalGroupEpic,
  removeCausalGroupEpic,
  loadEventMetricMapEpic,
  shareCausalGroupEpic,
];

export default epics;
