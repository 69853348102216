/* @flow */
import React from 'react';
import cx from 'classnames';

type Props = {
  fullScreen: boolean,
  visible: boolean,
  size: number,
  className: string,
  imageSrc: string,
  children: any,
};

const Loader = ({ imageSrc, fullScreen = false, visible = false, size, className, children, ...rest }: Props) => {
  const classes = cx(
    'fui',
    {
      'full-screen': fullScreen,
      hidden: !visible,
    },
    'loader',
    className,
    'content-bg',
  );

  if (imageSrc) {
    return (
      <div className={classes} {...rest}>
        <div className="fui image content" style={{ width: size, height: size }}>
          <img alt="" src={imageSrc} style={{ width: size }} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes} {...rest}>
      <div className="content" style={{ width: size, height: size }}>
        {children}
      </div>
    </div>
  );
};

export default Loader;
