/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
**/

import loadLogAnalysisLogic from './loadLogAnalysisLogic';
import loadLogAnomalyTimelinesLogic from './loadLogAnomalyTimelinesLogic';
import loadLogClusterInfoLogic from './loadLogClusterInfoLogic';
import loadLogInstanceInfoLogic from './loadLogInstanceInfoLogic';
import loadLogSequenceInfoLogic from './loadLogSequenceInfoLogic';
import loadLogAnomalySequenceInfoLogic from './loadLogAnomalySequenceInfoLogic';
import loadLogKeywordInfoLogic from './loadLogKeywordInfoLogic';
import loadLogHotColdEntryListLogic from './loadLogHotColdEntryListLogic';
import loadLogKeywordEntryListLogic from './loadLogKeywordEntryListLogic';
import loadLogStatsLogic from './loadLogStatsLogic';
import loadLogInstancesStatsLogic from './loadLogInstancesStatsLogic';
import loadLogSeqEntryListLogic from './loadLogSeqEntryListLogic';
import loadLogPatternEntryListLogic from './loadLogPatternEntryListLogic';
import loadLogEntryContextListLogic from './loadLogEntryContextListLogic';
import loadLogEntryCausalListLogic from './loadLogEntryCausalListLogic';
import loadLogDayEntryListLogic from './loadLogDayEntryListLogic';
import loadLogFeatureEntryInfoLogic from './loadLogFeatureEntryInfoLogic';

import loadEventTriageLogic from './loadEventTriageLogic';
import saveEventTriageLogic from './saveEventTriageLogic';
import loadEventTriageReportListLogic from './loadEventTriageReportListLogic';
import saveEventTriageReportListLogic from './saveEventTriageReportListLogic';
import loadFeatureKeywordLogic from './loadFeatureKeywordLogic';
import loadLogKeywordFrequencyEntryLogic from './loadLogKeywordFrequencyEntryLogic';
import loadLogKeywordStatsLogic from './loadLogKeywordStatsLogic';
import loadLogImportantListLogic from './loadLogImportantListLogic';
import saveLogPushLogic from './saveLogPushLogic';

export default [
  loadLogAnalysisLogic,
  loadLogAnomalyTimelinesLogic,
  loadLogInstanceInfoLogic,
  loadLogSequenceInfoLogic,
  loadLogAnomalySequenceInfoLogic,
  loadLogKeywordInfoLogic,
  loadLogHotColdEntryListLogic,
  loadLogKeywordEntryListLogic,
  loadLogKeywordFrequencyEntryLogic,
  loadLogClusterInfoLogic,
  loadLogStatsLogic,
  loadLogInstancesStatsLogic,
  loadLogSeqEntryListLogic,
  loadLogPatternEntryListLogic,
  loadLogEntryContextListLogic,
  loadLogEntryCausalListLogic,
  loadLogKeywordStatsLogic,
  loadEventTriageLogic,
  saveEventTriageLogic,
  loadEventTriageReportListLogic,
  saveEventTriageReportListLogic,
  loadFeatureKeywordLogic,
  loadLogImportantListLogic,
  saveLogPushLogic,
  loadLogDayEntryListLogic,
  loadLogFeatureEntryInfoLogic,
];
