/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import React from 'react';
import { autobind } from 'core-decorators';
import * as R from 'ramda';
import moment from 'moment';
import { isEqual } from 'lodash';

import Dygraph from '../../../dygraph';
import { Defaults } from '../../../../common/app';

type Props = {
  className: string,
  style: Object,

  width: Number,
  height: Number,
  interval: Number,
  xRangePad: Number,
  data: any,
  labels: Object,
  colors: Array<Object>,
  showLegend: Boolean,

  dateWindow: any,
  onDateWindowChange: Function,
  underlayCallback: Function,
  highlightCallback: Function,
  drawCallback: Function,
  clickCallback: Function,
  unhighlightCallback: Function,
  barColorCallback: Function,
};

class BarChart extends React.PureComponent {
  props: Props;
  static defaultProps = {
    xRangePad: 0,
    interval: 10 * 60 * 1000,
    showLegend: false,
    colors: Defaults.Colorbrewer5,
  };

  constructor(props) {
    super(props);

    this.legendOffset = 16;
    this.legendWidth = 104;

    this.chartNode = null;
    this.dygraph = null;
    this.predicatedRangeFillStyle = 'rgba(205, 234, 214, 0.6)';
    this.timeFormat = 'YYYY-MM-DD HH:mm';

    this.dygraphOptions = {
      includeZero: true,
      xRangePad: props.xRangePad,
      labelsUTC: true,
      axisLabelFontSize: 12,
      ylabel: props.labels[1],
      yLabelWidth: 14,
      gridLineColor: 'rgba(0, 0, 0, 0.36)',
      strokeWidth: 1,
      highlightSeriesOpts: { strokeWidth: 0, highlightCircleSize: 0 },
      highlightSeriesBackgroundAlpha: 1,
      // hideOverlayOnMouseOut: false,
      showLabelsOnHighlight: props.showLegend,
      colors: props.colors,
      // legendFormatter: this.legendFormatter,
      plotter: this.barChartPlotter,
      underlayCallback: this.handleUnderlayCallback,
      drawCallback: this.handleDrawCallback,
      highlightCallback: this.handleHighlightCallback,
      unhighlightCallback: this.handleUnhighlightCallback,
      clickCallback: this.handleClickCallback,
      axes: { y: { drawAxis: true, drawGrid: true, axisLabelWidth: 48, pixelsPerLabel: 20 }, x: { drawGrid: false } },
    };
  }

  @autobind
  legendFormatter(data) {
    const { x } = data;
    if (!x) return '';

    const time = moment.utc(x);
    const html = `<div class="time">${time.format(this.timeFormat)}</div>`;
    return html;
  }

  @autobind
  handleClickCallback(e, x, points) {
    const { clickCallback } = this.props;

    if (clickCallback) {
      clickCallback(e, x, points, this.dygraph);
    }
  }

  @autobind
  handleHighlightCallback(e, x, points, row, seriesName) {
    const { highlightCallback } = this.props;

    if (highlightCallback) {
      highlightCallback(e, x, points, row, seriesName, this.dygraph);
    }
  }

  @autobind
  handleUnhighlightCallback(e) {
    const { unhighlightCallback } = this.props;
    if (unhighlightCallback) {
      unhighlightCallback(e, this.dygraph);
    }
  }

  @autobind
  handleDrawCallback(g, initial) {
    const { onDateWindowChange, drawCallback } = this.props;
    if (onDateWindowChange && !initial) {
      const dw = g.xAxisRange();
      if (!isEqual(dw, this.currentDateWindow)) {
        onDateWindowChange(g.xAxisRange());
        this.currentDateWindow = dw;
      }
    }

    if (drawCallback) {
      drawCallback(g, initial);
    }
  }

  @autobind
  getSeriesColor(index) {
    const { colors } = this.props;
    const idx = R.mathMod(index, colors.length);
    return colors[idx];
  }

  @autobind
  barChartPlotter(e) {
    // We need to handle all the series simultaneously.
    if (e.seriesIndex !== 0) return;

    const { interval, barColorCallback } = this.props;
    const g = e.dygraph;
    const ctx = e.drawingContext;
    const sets = e.allSeriesPoints;
    const yBottom = g.toDomYCoord(0);
    const barWidth = g.toDomXCoord(interval) - g.toDomXCoord(0);
    const padding = Math.max(barWidth / 10, 3);

    for (let j = 0; j < sets.length; j += 1) {
      const color = this.getSeriesColor(j);
      for (let i = 0; i < sets[j].length; i += 1) {
        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        const p = sets[j][i];
        const top = p.canvasy;
        if (p.yval && top) {
          if (barColorCallback) {
            const c = barColorCallback(p.xval, j, p.name, p.yval);
            if (c) {
              ctx.fillStyle = c;
              ctx.strokeStyle = c;
            }
          }
          const centerX = p.canvasx;
          const xLeft = centerX - barWidth / 2 + padding / 2;
          const h = Math.max(3, yBottom - top);

          ctx.fillRect(xLeft, yBottom - h - 1, barWidth - padding, h);
        }
      }
    }
  }

  @autobind
  handleUnderlayCallback(canvas, area, g) {
    const { underlayCallback } = this.props;

    if (underlayCallback) {
      underlayCallback(canvas, area, g);
    }
  }

  componentDidMount() {
    if (this.chartNode) {
      const { data, labels, dateWindow } = this.props;
      let options = { ...this.dygraphOptions, labels };
      if (dateWindow) {
        options = { ...options, dateWindow };
      }
      this.dygraph = new Dygraph(this.chartNode, data, options);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { width, height } = nextProps;
    if (width && height && this.dygraph && (width !== this.props.width || height !== this.props.height)) {
      this.dygraph.resize(width, height);
    }
  }

  componentWillUpdate(nextProps) {
    if (this.dygraph) {
      const { data, labels, dateWindow } = nextProps;
      if (data !== this.props.data || labels !== this.props.labels || dateWindow !== this.props.dateWindow) {
        let options = { file: data, labels };
        if (dateWindow) {
          options = { ...options, dateWindow };
        }
        this.dygraph.updateOptions(options);
      }
    }
  }

  componentWillUnmount() {
    if (this.dygraph) {
      this.dygraph.destroy();
      this.dygraph = null;
    }
  }

  getGraphNode() {
    return this.dygraph;
  }

  render() {
    const { className, style, width, height } = this.props;
    return (
      <div className={`fui barchart ${className || ''}`} style={{ width, height }}>
        <div style={{ ...style, width, height }} ref={(c) => (this.chartNode = c)} />
      </div>
    );
  }
}

export default BarChart;
