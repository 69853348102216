/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { get, isNumber, round } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getGlobalCapacityScore = (credentials: Credentials, params: Object) => {
  const { customerName, requestLevel, instanceGroup, evn, endTimestamp, projectSystemMap } = params;
  const requests = [];
  requests.push(
    fetchGet(getEndpoint('fetchrealtimecapacityscore'), {
      ...credentials,
      customerName,
      requestLevel,
      evn,
      endTimestamp,
    }).then((data) => {
      const systemCapacityMap = [];
      let systemCapacityList = [];
      R.forEachObjIndexed((groupVal, projectName) => {
        const value = get(groupVal, instanceGroup, {});
        const { aggregatedWeightedScore, aggregatedPredictedWeightedScore } = value;
        const systemId = get(projectSystemMap, projectName);
        if (systemId) {
          if (!R.has(systemId, systemCapacityMap)) {
            systemCapacityMap[systemId] = [];
          }
          systemCapacityMap[systemId].push({
            projectName,
            customerName,
            aggregatedWeightedScore: isNumber(aggregatedWeightedScore) ? round(aggregatedWeightedScore * 100) : 0,
            aggregatedPredictedWeightedScore: isNumber(aggregatedPredictedWeightedScore)
              ? round(aggregatedPredictedWeightedScore * 100)
              : 0,
          });
        }
      }, data);

      R.forEachObjIndexed((capacityVal, systemId) => {
        let maxScore = 0;
        let maxScorePredicted = 0;

        R.forEach((item) => {
          maxScore = R.max(maxScore, item.aggregatedWeightedScore);
          maxScorePredicted = R.max(maxScorePredicted, item.aggregatedPredictedWeightedScore);
        }, capacityVal);
        systemCapacityList.push({
          id: systemId,
          systemId,
          maxScore,
          maxScorePredicted,
          capacityVal,
        });
      }, systemCapacityMap);
      systemCapacityList = R.sortWith(
        [R.descend(R.prop('maxScore')), R.descend(R.prop('maxScorePredicted')), R.ascend(R.prop('systemId'))],
        systemCapacityList,
      );

      return systemCapacityList;
    }),
  );

  return Promise.all(requests).then((results) => {
    const globalCapacitySummary = results[0] || [];
    return {
      globalCapacitySummary,
    };
  });
};
export default getGlobalCapacityScore;
