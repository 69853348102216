import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import VLink from 'valuelink';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Spin, Switch } from 'antd';

import { buildUrl, Defaults, getLoadStatus } from '../../../common/utils';
import { Modal } from '../../../../artui/react';
import { createLoadAction, loadProjectInfo } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/log/actions';
import { Input, Select, DatePicker } from '../../../lib/fui/react';
import { BaseUrls } from '../../app/Constants';
import { appFieldsMessages } from '../../../common/app/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';

type Props = {
  intl: Object,
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  loaderStatus: Object,
  isAdmin: Boolean,
  createLoadAction: Function,
  loadProjectInfo: Function,
  projectList: Array<Object>,
  instanceInfoList: Array<Object>,

  system: Object,
  startTime: String,
  environmentId: String,
  onClose: Function,
};

class RecentLogsSelectorModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'recent_logs_model';

    const { system } = this.props;
    const projectNameSet = get(system, ['projectNameSet'], []);
    const logProjects = R.filter((project) => project.dataType !== 'Metric', projectNameSet);
    this.state = {
      instanceInfoList: [],

      projectName: logProjects.length > 0 ? logProjects[0].projectNameReal : '',
      instanceName: '',
      isLatestLogs: true,
      startTimeObj: null,
      endTimeObj: null,
    };
  }

  componentDidMount() {
    const { intl } = this.props;
    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);

    this.handleGetProjectInfo();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl, instanceInfoList } = nextProps;
    if (intl.locale !== this.props.intl.locale) {
      moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);
    }

    if (instanceInfoList !== this.props.instanceInfoList) {
      this.setState({ instanceInfoList: instanceInfoList || [] });
    }
  }

  @autobind
  handleConfirm() {
    const { projectName, instanceName, isLatestLogs, startTimeObj, endTimeObj } = this.state;

    let query = {};
    if (isLatestLogs) {
      query = {
        t: 'f1f82460f99711e9aaef0800200c9a66',
        projectName,
        time: moment.utc().valueOf(),
        interval: 15 * 60 * 1000,
        instanceName,
      };
    } else {
      query = {
        t: 'eadc0e347ad24cae84bb32aaefe72418',
        projectName,
        startTimestamp: startTimeObj.valueOf(),
        endTimestamp: endTimeObj.valueOf(),
        instanceName,
      };
    }
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
  }

  @autobind
  handleProjectChange(newValue) {
    const projectName = newValue ? newValue.value : null;
    this.setState({ projectName }, this.handleGetProjectInfo);
  }

  @autobind
  handleStartTimeChange(timeObj) {
    this.setState({ startTimeObj: timeObj });
  }
  @autobind
  handleEndTimeChange(timeObj) {
    this.setState({ endTimeObj: timeObj });
  }

  @autobind
  handleGetProjectInfo() {
    const { createLoadAction, startTime } = this.props;
    const { projectName } = this.state;
    if (projectName && startTime) {
      createLoadAction(ActionTypes.LOAD_LOG_INSTANCEINFO_LIST, { projectName, day: startTime }, this.dataLoader);
    }
  }

  render() {
    const { intl, onClose, loadStatus, system } = this.props;
    const { instanceInfoList, projectName, isLatestLogs, startTimeObj, endTimeObj } = this.state;
    const projectNameSet = get(system, ['projectNameSet'], []);
    const logProjects = R.filter((project) => project.dataType !== 'Metric', projectNameSet);

    const nowObj = moment.utc();
    const instanceNameLink = VLink.state(this, 'instanceName').check((value) => Boolean(value), 'instance is required');
    const { isLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);

    const hasError =
      !projectName ||
      instanceNameLink.error ||
      (!isLatestLogs && !(startTimeObj && endTimeObj)) ||
      (startTimeObj && endTimeObj && startTimeObj.valueOf() > endTimeObj.valueOf());
    return (
      <Modal ref={(modal) => (this.modal = modal)} size="small" onClose={onClose}>
        <div className="flex-col content" style={{ fontSize: 12 }}>
          <Spin spinning={isLoading}>
            <div className="flex-row flex-center-align" style={{ height: 28 }}>
              <span className="label" style={{ width: 100, fontWeight: 'bold', marginLeft: 20 }}>
                Project:
              </span>
              <Select
                name="project"
                style={{ width: 200 }}
                value={projectName}
                onChange={this.handleProjectChange}
                options={R.map(
                  (project) => ({ label: project.projectNameReal, value: project.projectNameReal }),
                  logProjects,
                )}
              />
            </div>
            <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
              <span className="label" style={{ width: 100, fontWeight: 'bold', marginLeft: 20 }}>
                {intl.formatMessage(appFieldsMessages.instance)}:
              </span>
              <Select
                name="Instance"
                style={{ width: 200 }}
                placeholder="instance"
                labelKey="name"
                valueKey="id"
                options={instanceInfoList}
                valueLink={instanceNameLink}
              />
            </div>

            <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
              <span className="label" style={{ width: 100, fontWeight: 'bold', marginLeft: 20 }}>
                {intl.formatMessage(DashboardMessages.latestLogs)}:
              </span>
              <Switch
                size="small"
                checked={isLatestLogs}
                onChange={(checked, event) => this.setState({ isLatestLogs: checked })}
              />
            </div>

            {!isLatestLogs && (
              <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
                <span className="label" style={{ width: 100, fontWeight: 'bold', marginLeft: 20 }}>
                  {intl.formatMessage(appFieldsMessages.startTime)}:
                </span>
                <DatePicker
                  utcOffset={0}
                  className="time"
                  todayButton={intl.formatMessage(appFieldsMessages.today)}
                  dateFormat={Defaults.TimeFormat}
                  maxDate={nowObj}
                  showMonthDropdown
                  showTimeSelect
                  timeIntervals={1}
                  selected={startTimeObj}
                  onChange={this.handleStartTimeChange}
                />
              </div>
            )}
            {!isLatestLogs && (
              <div className="flex-row flex-center-align" style={{ height: 28, marginTop: 16 }}>
                <span className="label" style={{ width: 100, fontWeight: 'bold', marginLeft: 20 }}>
                  {intl.formatMessage(appFieldsMessages.endTime)}:
                </span>
                <DatePicker
                  utcOffset={0}
                  className="time"
                  todayButton={intl.formatMessage(appFieldsMessages.today)}
                  dateFormat={Defaults.TimeFormat}
                  maxDate={nowObj}
                  showMonthDropdown
                  showTimeSelect
                  timeIntervals={1}
                  selected={endTimeObj}
                  onChange={this.handleEndTimeChange}
                />
              </div>
            )}

            <div className="flex-row" style={{ marginTop: 16 }}>
              <div className="flex-grow" />
              <div className={`ui button orange ${hasError ? ' disabled' : ''}`} onClick={this.handleConfirm}>
                <i className="save icon" />
                <span>Confirm</span>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
    );
  }
}

const RecentLogsSelectorModal = injectIntl(RecentLogsSelectorModalCore);
export default connect(
  (state: State) => {
    const { isAdmin } = state.auth.userInfo;
    const { location } = state.router;
    const { loadStatus, loaderStatus, projects } = state.app;
    const instanceInfoList = state.log.instanceInfoList || [];
    return {
      location,
      loadStatus,
      loaderStatus,
      isAdmin,
      projectList: projects,
      instanceInfoList,
    };
  },
  { push, replace, createLoadAction, loadProjectInfo },
)(RecentLogsSelectorModal);
