import React, { useEffect } from 'react';
import { sortableHandle } from 'react-sortable-hoc';
import { Spin, Empty } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { buildUrl } from '../../../common/utils';
import { MoveIcon } from '../../../lib/fui/icons';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsAnomalousInstancesStats = (props) => {
  // eslint-disable-next-line
  const { intl, name } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,
    // eslint-disable-next-line
    globalInfo,

    // eslint-disable-next-line
    isLoadingAnomalyInstance,
    // eslint-disable-next-line
    anomalyInstanceCountInfo,
  } = props;

  useEffect(() => {
    // let cancel = false;
    // return () => (cancel = true);
  }, [refresh, refreshTime, environmentId, systemId, customerName]);

  const handleJumpClick = ({ name }) => {
    const query = {
      environmentId,
      systemId,
      customerName,
      startTime,
      endTime: startTime,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  };

  const cellMeasureCache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 28,
  });

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold link"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
        onClick={() => {
          push(buildUrl(BaseUrls.GlobalSystemRootCause, {}, { startTime, customerName, environmentId, systemId }));
        }}
      >
        {name}
      </div>
      <DragHandle />

      <div
        className="flex-grow flex-col flex-center-align flex-center-justify flex-min-height"
        style={{ padding: '0px 8px' }}
      >
        <Spin spinning={isLoadingAnomalyInstance} wrapperClassName="spin-full-height full-width full-height">
          {(anomalyInstanceCountInfo || []).length === 0 && (
            <div className="full-height flex-col flex-center-align flex-center-justify">
              <Empty imageStyle={{ height: 60 }} />
            </div>
          )}
          {(anomalyInstanceCountInfo || []).length > 0 && (
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowCount={anomalyInstanceCountInfo.length}
                  overscanRowCount={4}
                  deferredMeasurementCache={cellMeasureCache}
                  rowHeight={cellMeasureCache.rowHeight}
                  rowRenderer={({ key, index: rowIndex, style, parent }) => {
                    const item = anomalyInstanceCountInfo[rowIndex];
                    if (!item) return null;

                    let color = '#797D92';
                    switch (rowIndex) {
                      case 0:
                        color = '#FF5142';
                        break;
                      case 1:
                        color = '#FF8A61';
                        break;
                      case 2:
                        color = '#FEB207';
                        break;
                      default:
                        break;
                    }
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cellMeasureCache}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={rowIndex}
                      >
                        <div
                          className="flex-row flex-min-width flex-center-align clickable"
                          style={{ ...style, fontSize: 14 }}
                          onClick={() => handleJumpClick(item)}
                        >
                          <div className="circle" style={{ marginRight: 16, background: color }}>
                            <div className="circle-text">{rowIndex + 1}</div>
                          </div>
                          <div className="flex-grow flex-min-width">
                            <Popover title={null} content={item.name} placement="right" mouseEnterDelay={0.3}>
                              <div className="max-width hidden-line-with-ellipsis inline-block">{item.name}</div>
                            </Popover>
                          </div>
                          <div
                            className="flex-row flex-end-justify"
                            style={{ minWidth: 40, color, margin: '0 8px 0 4px' }}
                          >
                            {item.count}
                          </div>
                        </div>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default InsightsAnomalousInstancesStats;
