import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { HomeOutlined } from '@ant-design/icons';
import { Button, DatePicker, Select, Spin, Empty, Popover, Breadcrumb, message } from 'antd';

import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import { BaseUrls } from '../app/Constants';
import { Container, AutoSizer } from '../../lib/fui/react';
import {
  Defaults,
  parseLocation,
  buildLocation,
  pickNotNil,
  parseJSON,
  BackgroundCall,
  buildUrl,
} from '../../common/utils';
import { hideAppLoader, showAppLoader, updateLastActionInfo } from '../../common/app/actions';

import { appButtonsMessages, appFieldsMessages, appMenusMessages, appMessages } from '../../common/app/messages';
import { logMessages } from '../../common/log/messages';
import { eventMessages } from '../../common/metric/messages';

import { EChart } from '../share';
import DateSelectModal from '../metric/components/DateSelectModal';
import InsightQueryBoxModal from '../dashboard/components/InsightQueryBoxModal';
import AllSystemsChart from './components/AllSystemsChart';
import AllProjectsChart from './components/AllProjectsChart';
import fetchPost from '../../common/apis/fetchPost';

type Props = {
  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  isReadUser: Boolean,

  // eslint-disable-next-line
  hideAppLoader: Function,
  showAppLoader: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,

  userList: Array<Object>,
  systemList: Array,
  // eslint-disable-next-line
  globalInfo: Object,

  currentTheme: String,
};

class LogCalendarChartCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl, location } = props;
    const params = parseLocation(location);
    const { startMonth, endMonth } = params;

    this.state = {
      startMonthObj: startMonth ? moment.utc(startMonth, Defaults.MonthFormat) : null,
      endMonthObj: endMonth ? moment.utc(endMonth, Defaults.MonthFormat) : null,
      isLoadingYearData: false,
      isLoadingMonthData: false,

      monthStrList: [],
      yearDatas: [],
      monthDatas: [],
      yearChartOption: null,
      monthChartOption: null,

      dataCategory: 'totalEvents',

      showTimeSelectModal: false,
      showInsightQueryBox: false,
      queryParams: {},
    };
    this.monthNameMap = {
      '01': intl.formatMessage(appFieldsMessages.Jan),
      '02': intl.formatMessage(appFieldsMessages.Feb),
      '03': intl.formatMessage(appFieldsMessages.Mar),
      '04': intl.formatMessage(appFieldsMessages.Apr),
      '05': intl.formatMessage(appFieldsMessages.May),
      '06': intl.formatMessage(appFieldsMessages.Jun),
      '07': intl.formatMessage(appFieldsMessages.Jul),
      '08': intl.formatMessage(appFieldsMessages.Aug),
      '09': intl.formatMessage(appFieldsMessages.Sep),
      10: intl.formatMessage(appFieldsMessages.Oct),
      11: intl.formatMessage(appFieldsMessages.Nov),
      12: intl.formatMessage(appFieldsMessages.Dec),
    };

    this.categoryTitleMap = {
      totalEvents: intl.formatMessage(logMessages.totalLog),
      totalPatterns: intl.formatMessage(logMessages.clusters),
      newPatterns: intl.formatMessage(logMessages.newClusters),
      rareEvents: intl.formatMessage(logMessages.rareEvents),
      hotEvents: intl.formatMessage(logMessages.hotEventsPeriods),
      coldEvents: intl.formatMessage(logMessages.coldEventsPeriods),
      criticalEvents: intl.formatMessage(logMessages.numOfCriticalEvent),
      whitelistEvents: intl.formatMessage(logMessages.keywordAlerts),
      incidentEvents: intl.formatMessage(eventMessages.incidents),
      anomalySize: intl.formatMessage(logMessages.anomalousLogSize),
      originalSize: intl.formatMessage(logMessages.originalSize),
      compressedSize: intl.formatMessage(logMessages.compressedSize),
      decreaseRatio: intl.formatMessage(logMessages.decreaseRatio),
      federatedLearning: intl.formatMessage(logMessages.federatedLearning),
      alertReductionRatio: intl.formatMessage(logMessages.alertReductionRatio),
    };
    this.categoryColorMap = {
      totalEvents: '#678dff',
      totalPatterns: '#32C67F',
      newPatterns: 'red',
      rareEvents: '#F2711C',
      hotEvents: 'orange',
      coldEvents: '#2185D0',
      criticalEvents: 'darkred',
      whitelistEvents: 'orange',
      incidentEvents: 'red',
      anomalySize: '#668CFF',
      originalSize: '#668CFF',
      compressedSize: '#668CFF',
      decreaseRatio: '#32C67F',
      federatedLearning: '#32C67F',
      alertReductionRatio: '#32C67F',
    };
  }

  componentDidMount() {
    const { hideAppLoader } = this.props;
    hideAppLoader();
    if (!this.applyParamsAndRedirect(this.props)) {
      this.reloadYearData(this.props, { needResetCategory: true });
      this.reloadMonthData(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, currentTheme } = this.props;

    const { location: prevLocation, currentTheme: prevCurrentTheme } = prevProps;
    const prevParams = parseLocation(prevLocation);
    const params = parseLocation(location);
    if (!this.applyParamsAndRedirect(this.props)) {
      if (
        params.forceRefreshTime !== prevParams.forceRefreshTime ||
        params.projectName !== prevParams.projectName ||
        params.startMonth !== prevParams.startMonth ||
        params.endMonth !== prevParams.endMonth
      ) {
        const needResetCategory = params.projectName !== prevParams.projectName;
        this.reloadYearData(this.props, { needResetCategory });
        this.reloadMonthData(this.props);
      } else if (params.selectMonth !== prevParams.selectMonth) {
        this.reloadMonthData(this.props);
      }
    }
    if (currentTheme !== prevCurrentTheme) {
      const { monthStrList, yearDatas, monthDatas } = this.state;
      const { yearChartOption } = this.buildOptionYear(monthStrList, yearDatas);
      const { monthChartOption } = this.buildOptionMonth(monthDatas);
      this.setState({ yearChartOption, monthChartOption });
    }
  }

  applyParamsAndRedirect(props) {
    // Apply the default params if missing and redirect to new location if params changes.
    // When redirection happens, we should not need to reload the data. The return value
    // indicates whethre rediction happens.
    let { projects } = props;
    const { location, replace, systemList: systemLists, userInfo, globalInfo } = props;
    const params = parseLocation(location);
    const { hasAlert, customerName } = params;
    let { projectName, startMonth, endMonth, selectMonth, hasLog, systemId } = params;

    let systemList =
      systemLists.length > 0
        ? [...systemLists, { systemName: 'Projects with no system', systemId: 'projectsWithNoSystem' }]
        : [];
    if (hasLog) {
      systemList = [
        { systemName: 'All systems', systemId: 'allSystems' },
        { systemName: 'All projects', systemId: 'allProjects' },
        ...systemList,
      ];
    }
    let redirect = false;

    if (!hasLog && !hasAlert) {
      hasLog = 'true';
    }

    const hasProjectName = this.flagProjectName({
      projectName,
      projects,
      hasLog,
      hasAlert,
      systemList,
      systemId,
      userInfo,
      customerName,
    });
    if (!hasProjectName) {
      projectName = '';
    }

    if (!projectName) {
      // filter projects
      projects = R.filter(
        (project) =>
          (!hasLog && !hasAlert) ||
          (hasLog === 'true' && (project.isLog || project.isDeployment)) ||
          (hasAlert === 'true' && (project.isAlert || project.isIncident)),
        projects,
      );
      projects = R.filter((project) => project.ps !== 'Deleting', projects);

      // System select
      if (userInfo.isAdmin || userInfo.isLocalAdmin) {
        systemList = R.filter(
          (item) =>
            item.owner === customerName ||
            item.systemId === 'projectsWithNoSystem' ||
            item.systemId === 'allSystems' ||
            item.systemId === 'allProjects' ||
            (item.shareUserSet || []).includes(customerName),
          systemList,
        );
      }
      R.forEach((item) => {
        item.hasProjectList = [];
        R.forEach((_item) => {
          if (_item.systemId && item.systemId === _item.systemId) {
            item.hasProjectList.push(_item);
          } else if (item.systemId === 'projectsWithNoSystem' && !_item.systemId) {
            item.hasProjectList.push(_item);
          }
        }, projects);
      }, systemList);
      systemList = R.filter(
        (item) => item.hasProjectList.length > 0 || item.systemId === 'allSystems' || item.systemId === 'allProjects',
        systemList,
      );
      if (systemList.length > 0 && (!systemId || !R.find((s) => s.systemId === systemId, systemList))) {
        // eslint-disable-next-line prefer-destructuring
        systemId = systemList[hasLog ? 2 : 0]?.systemId;
      }

      projects = R.filter(
        (item) =>
          systemId === 'projectsWithNoSystem'
            ? item.systemId === undefined
            : item.systemId === systemId && item.systemId !== undefined,
        projects,
      );
      if (projects.length > 0) {
        projectName = projects[0].projectName || '';
      }
    }

    if (!startMonth && !endMonth) {
      const startMonthObj = moment.utc().startOf('month').subtract(1, 'year');
      const endMonthObj = moment.utc().startOf('month');
      startMonth = startMonthObj.format(Defaults.MonthFormat);
      endMonth = endMonthObj.format(Defaults.MonthFormat);
      this.setState({ startMonthObj, endMonthObj });
    }

    if (!selectMonth) {
      selectMonth = endMonth;
    }

    // Remove the empty params, otherwise it will affect the comparation.
    const newParams = pickNotNil({
      ...params,
      projectName,
      startMonth,
      endMonth,
      selectMonth,
      hasLog,
      hasAlert,
      systemId,
      environmentId: globalInfo[0]?.id || 'All',
    });

    // Compare the new params with the origin params, if changed, redirect to new location.
    if (!R.equals(newParams, params)) {
      redirect = true;
      if (!hasProjectName) {
        redirect = false;
      }
      replace(buildLocation(location.pathname, {}, newParams));
    }

    return redirect;
  }

  @autobind
  flagProjectName({ projectName, projects, hasLog, hasAlert, systemList, systemId, userInfo, customerName }) {
    let hasProjectName = true;
    if (projectName) {
      projects = R.filter(
        (project) =>
          (!hasLog && !hasAlert) ||
          (hasLog === 'true' && (project.isLog || project.isDeployment)) ||
          (hasAlert === 'true' && (project.isAlert || project.isIncident)),
        projects,
      );
      projects = R.filter((project) => project.ps !== 'Deleting', projects);
      const findProjectName = R.find((item) => projectName === item.projectName, projects || []);

      if (findProjectName) {
        if (userInfo.isAdmin || userInfo.isLocalAdmin) {
          systemList = R.filter(
            (item) =>
              item.owner === customerName ||
              item.systemId === 'projectsWithNoSystem' ||
              item.systemId === 'allSystems' ||
              item.systemId === 'allProjects' ||
              (item.shareUserSet || []).includes(customerName),
            systemList,
          );
        }
        R.forEach((item) => {
          item.hasProjectList = [];
          R.forEach((_item) => {
            if (_item.systemId && item.systemId === _item.systemId) {
              item.hasProjectList.push(_item);
            } else if (item.systemId === 'projectsWithNoSystem' && !_item.systemId) {
              item.hasProjectList.push(_item);
            }
          }, projects);
        }, systemList);
        systemList = R.filter(
          (item) => item.hasProjectList.length > 0 || item.systemId === 'allSystems' || item.systemId === 'allProjects',
          systemList,
        );
        if (systemList.length > 0 && (!systemId || !R.find((s) => s.systemId === systemId, systemList))) {
          hasProjectName = false;
        }
      } else {
        hasProjectName = false;
      }
    }
    return hasProjectName;
  }

  @autobind
  handleRefreshClick() {
    const { location, replace } = this.props;
    let query = parseLocation(location);
    const { startMonthObj, endMonthObj } = this.state;
    const startMonth = startMonthObj.format(Defaults.MonthFormat);
    const endMonth = endMonthObj.format(Defaults.MonthFormat);

    // update start/end time if changed
    if (startMonth !== query.startMonth || endMonth !== query.endMonth) {
      query = { ...query, startMonth, endMonth };
      if (
        moment.utc(query.selectMonth, Defaults.MonthFormat) > endMonthObj ||
        moment.utc(query.selectMonth, Defaults.MonthFormat) < startMonthObj
      ) {
        const selectMonth = endMonth;
        query = { ...query, selectMonth };
      }
    }

    // force refresh
    replace(buildLocation(location.pathname, {}, { ...query, forceRefreshTime: moment.utc().valueOf() }));
  }

  @autobind
  reloadYearData(props, { needResetCategory }) {
    const { updateLastActionInfo, location, credentials, projects } = props;
    const { dataCategory } = this.state;
    const params = parseLocation(location);
    const { projectName, startMonth, endMonth } = params;
    const project = R.find((project) => project.projectName === projectName, projects);

    if (project) {
      this.setState({ isLoadingYearData: true, dataCategory: needResetCategory ? 'totalEvents' : dataCategory });
      const startMonthObj = moment.utc(startMonth, Defaults.MonthFormat);
      const endMonthObj = moment.utc(endMonth, Defaults.MonthFormat);
      const startYear = startMonthObj.year();
      const endYear = endMonthObj.year();

      const requests = [];
      R.forEach((year) => {
        const yearToDisplay = moment.utc().year(year).startOf('year').valueOf();
        requests.push(
          fetchGet(getEndpoint('logstreaming'), {
            ...credentials,
            operation: 'yearly',
            projectName,
            yearToDisplay,
          }),
        );
      }, R.range(startYear, endYear + 1));
      R.forEach((year) => {
        const yearToDisplay = moment.utc().year(year).startOf('year').valueOf();
        requests.push(
          fetchGet(getEndpoint('logAlertPatternServlet'), {
            ...credentials,
            operation: 'monthly',
            customerName: project.owner,
            projectName,
            dayTimeMillis: yearToDisplay,
          }),
        );
      }, R.range(startYear, endYear + 1));

      updateLastActionInfo();
      Promise.all(requests)
        .then((results) => {
          const { monthStrList, yearDatas } = this.praseDataYear(results, startMonthObj, endMonthObj);
          const { yearChartOption } = this.buildOptionYear(monthStrList, yearDatas);
          this.setState({ isLoadingYearData: false, monthStrList, yearDatas, yearChartOption });
        })
        .catch((err) => {
          this.setState({ isLoadingYearData: false });
        });
    } else {
      this.setState({ isLoadingYearData: false, yearChartOption: null, yearDatas: [], monthStrList: [] });
    }
  }

  @autobind
  reloadMonthData(props) {
    const { updateLastActionInfo, location, credentials, projects } = props;
    const params = parseLocation(location);
    const { projectName, selectMonth } = params;
    const project = R.find((project) => project.projectName === projectName, projects);

    if (project) {
      this.setState({ isLoadingMonthData: true });
      const monthlyDate = moment.utc(selectMonth, Defaults.MonthFormat).valueOf();

      updateLastActionInfo();
      Promise.all([
        fetchGet(getEndpoint('logstreaming'), {
          ...credentials,
          operation: 'list',
          projectName,
          monthlyDate,
        }),
        fetchGet(getEndpoint('logAlertPatternServlet'), {
          ...credentials,
          operation: 'daily',
          customerName: project.owner,
          projectName,
          dayTimeMillis: monthlyDate,
        }),
      ])
        .then((results) => {
          const monthDatas = this.praseDataMonth(results);
          const { monthChartOption } = this.buildOptionMonth(monthDatas);
          this.setState({ isLoadingMonthData: false, monthDatas, monthChartOption });
        })
        .catch((err) => {
          this.setState({ isLoadingMonthData: false });
        });
    } else {
      this.setState({ isLoadingMonthData: false, monthChartOption: null, monthDatas: [] });
    }
  }

  @autobind
  handleDataCategoryChange(dataCategory) {
    const { monthStrList, yearDatas, monthDatas } = this.state;

    this.setState({ dataCategory }, () => {
      const { yearChartOption } = this.buildOptionYear(monthStrList, yearDatas);
      const { monthChartOption } = this.buildOptionMonth(monthDatas);
      this.setState({ yearChartOption, monthChartOption });
    });
  }

  @autobind
  praseDataYear(results, startMonthObj, endMonthObj) {
    const resultNum = results.length / 2;
    let events = [];
    R.forEach((result) => {
      events = [...events, ...get(result, ['data', 'incidents'], [])];
    }, R.slice(0, resultNum, results));
    let alertRatios = [];
    R.forEach((result) => {
      alertRatios = [...alertRatios, ...(parseJSON(get(result, 'total alert reduction ratio')) || [])];
    }, R.slice(resultNum, results.length, results));

    // build monthly data
    const yearDataMap = {};
    R.forEach((item) => {
      const { incidentStartTime } = item;
      const monthStr = moment.utc(incidentStartTime).format(Defaults.MonthFormat);

      yearDataMap[monthStr] = item;
    }, events);

    // set reductionRatio
    R.forEach((item) => {
      const { dailyTimeStamp, reductionRatio } = item;
      const monthStr = moment.utc(dailyTimeStamp).format(Defaults.MonthFormat);
      if (yearDataMap[monthStr]) yearDataMap[monthStr].reductionRatio = reductionRatio;
    }, alertRatios);

    // build monthly data range
    const monthStrList = [];
    let loopMonthObj = startMonthObj;
    while (loopMonthObj <= endMonthObj) {
      monthStrList.push(loopMonthObj.format(Defaults.MonthFormat));
      loopMonthObj = loopMonthObj.add(1, 'month');
    }

    const yearDatas = R.map((monthStr) => {
      const data = yearDataMap[monthStr]
        ? yearDataMap[monthStr].isShadowProject
          ? {
              ...yearDataMap[monthStr],
              federatedLearning: 1 - yearDataMap[monthStr].anomalySize / yearDataMap[monthStr].originalSize,
            }
          : yearDataMap[monthStr]
        : {};
      const monthVal = moment.utc(monthStr, Defaults.MonthFormat).format(Defaults.MonthOnlyFormat);
      const monthName = this.monthNameMap[monthVal];
      return { monthStr, monthName, ...data };
    }, monthStrList);
    return { monthStrList, yearDatas };
  }

  @autobind
  praseDataMonth(results) {
    const { location } = this.props;
    const params = parseLocation(location);
    const { selectMonth } = params;
    const monthObj = moment.utc(selectMonth, Defaults.MonthFormat);
    const dayNames = R.map((dayStr) => String(dayStr).padStart(2, '0'), R.range(1, monthObj.daysInMonth() + 1));

    const resultNum = results.length / 2;
    let events = [];
    R.forEach((result) => {
      events = [...events, ...get(result, ['data', 'incidents'], [])];
    }, R.slice(0, resultNum, results));
    let alertRatios = [];
    R.forEach((result) => {
      alertRatios = [...alertRatios, ...(parseJSON(get(result, 'total alert reduction ratio')) || [])];
    }, R.slice(resultNum, results.length, results));

    // build monthly data
    const monthDataMap = {};
    R.forEach((item) => {
      const { incidentStartTime } = item;
      const startTime = moment.utc(incidentStartTime).startOf('day');
      const day = startTime.format(Defaults.DateFormat);
      const dayStr = startTime.format('DD');

      monthDataMap[dayStr] = { ...item, day, dayStr };
    }, events);

    // set reductionRatio
    R.forEach((item) => {
      const { dailyTimeStamp, reductionRatio } = item;
      const startTime = moment.utc(dailyTimeStamp).startOf('day');
      const dayStr = startTime.format('DD');
      if (monthDataMap[dayStr]) monthDataMap[dayStr].reductionRatio = reductionRatio;
    }, alertRatios);

    // build monthly data range
    const monthDatas = R.map((dayStr) => {
      const data = monthDataMap[dayStr]
        ? monthDataMap[dayStr].isShadowProject
          ? {
              ...monthDataMap[dayStr],
              federatedLearning: 1 - monthDataMap[dayStr].anomalySize / monthDataMap[dayStr].originalSize,
            }
          : monthDataMap[dayStr]
        : {};
      return { ...data, dayStr };
    }, dayNames);

    return monthDatas;
  }

  @autobind
  getFieldByCategory(dataCategory) {
    let field = 'logentrycount';
    switch (dataCategory) {
      case 'totalEvents':
        field = 'logentrycount';
        break;
      case 'totalPatterns':
        field = 'numOfCluster';
        break;
      case 'newPatterns':
        field = 'numOfNewCluster';
        break;
      case 'rareEvents':
        field = 'rareEventsSize';
        break;
      case 'hotEvents':
        field = 'anomalyHigherCount';
        break;
      case 'coldEvents':
        field = 'anomalyLowerCount';
        break;
      case 'criticalEvents':
        field = 'numOfCriticalEvent';
        break;
      case 'whitelistEvents':
        field = 'numOfWhitelistEvent';
        break;
      case 'incidentEvents':
        field = 'numOfIncidentEvent';
        break;
      case 'anomalySize':
        field = 'anomalySize';
        break;
      case 'originalSize':
        field = 'originalSize';
        break;
      case 'compressedSize':
        field = 'compressedSize';
        break;
      case 'decreaseRatio':
        field = 'compressionRatio';
        break;
      case 'federatedLearning':
        field = 'federatedLearning';
        break;
      case 'alertReductionRatio':
        field = 'reductionRatio';
        break;
      default:
        break;
    }
    return field;
  }

  @autobind
  buildOptionYear(monthStrList, yearDatas) {
    const { intl, location } = this.props;
    const { dataCategory } = this.state;
    const params = parseLocation(location);
    const { hasAlert } = params;

    let dataTitle = this.categoryTitleMap[dataCategory];
    if (dataCategory === 'totalEvents' && hasAlert === 'true') {
      dataTitle = intl.formatMessage(logMessages.totalAlert);
    }
    const dataField = this.getFieldByCategory(dataCategory);

    let maxVal = 0;
    const datas = R.map((item) => {
      const value = get(item, dataField, 0);
      maxVal = R.max(maxVal, value);
      return { value, info: item };
    }, yearDatas);

    const yearChartOption = {
      backgroundColor: 'transparent',
      title: {
        text: `${intl.formatMessage(logMessages.Monthly)} ${dataTitle} ${intl.formatMessage(logMessages.Analysis)}`,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        textStyle: {
          color: 'var(--text-color)',
        },
        // confine: true,
        // appendToBody: true,
        // alwaysShowContent: true,
        position: (pos, params, dom, rect, size) => {
          const leftOffset = pos[0] >= size.viewSize[0] - dom.offsetWidth ? pos[0] - dom.offsetWidth - 30 : pos[0] + 30;
          return [leftOffset, pos[1] + 30];
        },
        formatter: (params, ticket, callback) => {
          const { name, data } = params[0];
          const { info } = data;
          const {
            logentrycount,
            numOfCluster,
            numOfNewCluster,
            rareEventsSize,
            anomalyHigherCount,
            anomalyLowerCount,
            numOfCriticalEvent,
            numOfWhitelistEvent,
            numOfIncidentEvent,
            isShadowProject,
            anomalySize,
            originalSize,
            compressedSize,
            federatedLearning,
            compressionRatio,
            reductionRatio,
          } = info;
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {name}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.totalEvents }} />
              {`${intl.formatMessage(hasAlert === 'true' ? logMessages.totalAlerts : logMessages.totalLogs)}: ${numeral(
                logentrycount,
              ).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.totalPatterns }} />
              {`${intl.formatMessage(logMessages.clusters)}: ${numeral(numOfCluster).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.newPatterns }} />
              {`${intl.formatMessage(logMessages.newClusters)}: ${numeral(numOfNewCluster).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.rareEvents }} />
              {`${intl.formatMessage(logMessages.rareEvents)}: ${numeral(rareEventsSize).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.hotEvents }} />
              {`${intl.formatMessage(logMessages.hotEventsPeriods)}: ${numeral(anomalyHigherCount).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.coldEvents }} />
              {`${intl.formatMessage(logMessages.coldEventsPeriods)}: ${numeral(anomalyLowerCount).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.criticalEvents }} />
              {`${intl.formatMessage(logMessages.numOfCriticalEvent)}: ${numeral(numOfCriticalEvent).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.whitelistEvents }} />
              {`${intl.formatMessage(logMessages.keywordAlerts)}: ${numeral(numOfWhitelistEvent).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.incidentEvents }} />
              {`${intl.formatMessage(eventMessages.incidents)}: ${numeral(numOfIncidentEvent).format('0,0')}`}
              <br />
              {isShadowProject && (
                <>
                  <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.anomalySize }} />
                  {`${intl.formatMessage(logMessages.anomalousLogSize)}: ${numeral(anomalySize).format('0.0ib')}`}
                  <br />
                </>
              )}
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.originalSize }} />
              {`${intl.formatMessage(logMessages.originalSize)}: ${numeral(originalSize).format('0.0ib')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.compressedSize }} />
              {`${intl.formatMessage(logMessages.compressedSize)}: ${numeral(compressedSize).format('0.0ib')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.decreaseRatio }} />
              {`${intl.formatMessage(logMessages.decreaseRatio)}: ${numeral(compressionRatio).format('0.0%')}`}
              <br />
              {isShadowProject && (
                <>
                  <span
                    className="ecahrt-tooltip-dot"
                    style={{ backgroundColor: this.categoryColorMap.federatedLearning }}
                  />
                  {`${intl.formatMessage(logMessages.federatedLearning)}: ${numeral(federatedLearning).format(
                    '0.00%',
                  )}`}
                </>
              )}
              {hasAlert === 'true' && <br />}
              {hasAlert === 'true' && (
                <span>
                  <span
                    className="ecahrt-tooltip-dot"
                    style={{ backgroundColor: this.categoryColorMap.alertReductionRatio }}
                  />
                  {`${intl.formatMessage(logMessages.alertReductionRatio)}: ${numeral(reductionRatio).format('0.0%')}`}
                </span>
              )}
            </div>,
          );
        },
      },
      grid: {
        left: 40,
        right: 20,
        top: 40,
        bottom: 10,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: R.map(
          (monthStr) => this.monthNameMap[moment.utc(monthStr, Defaults.MonthFormat).format(Defaults.MonthOnlyFormat)],
          monthStrList,
        ),
        axisLine: { show: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          type: 'bar',
          data: datas,
          barMaxWidth: 20,
          zlevel: 20,
          itemStyle: { color: this.categoryColorMap[dataCategory] },
        },
        {
          type: 'bar',
          data: R.map((item) => ({ ...item, value: maxVal }), datas),
          barMaxWidth: 20,
          barGap: '-100%',
          itemStyle: { color: 'gray', opacity: 0.1 },
        },
      ],
    };
    return { yearChartOption };
  }

  @autobind
  buildOptionMonth(monthDatas) {
    const { intl, location } = this.props;
    const { dataCategory } = this.state;
    const params = parseLocation(location);
    const { selectMonth, hasAlert } = params;

    let dataTitle = this.categoryTitleMap[dataCategory];
    if (dataCategory === 'totalEvents' && hasAlert === 'true') {
      dataTitle = intl.formatMessage(logMessages.totalAlert);
    }
    const dataField = this.getFieldByCategory(dataCategory);

    let maxVal = 0;
    const datas = R.map((item) => {
      const value = get(item, dataField, 0);
      maxVal = R.max(maxVal, value);
      return { value, info: item };
    }, monthDatas);

    const monthObj = moment.utc(selectMonth, Defaults.MonthFormat);
    const dayNames = R.map((dayStr) => String(dayStr).padStart(2, '0'), R.range(1, monthObj.daysInMonth() + 1));

    const monthChartOption = {
      backgroundColor: 'transparent',
      title: {
        text: `${intl.formatMessage(logMessages.Daily)} ${dataTitle} ${intl.formatMessage(logMessages.Analysis)}`,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        textStyle: {
          color: 'var(--text-color)',
        },
        formatter: (params, ticket, callback) => {
          const { data } = params[0];
          const { info } = data;
          const {
            day,
            logentrycount,
            numOfCluster,
            numOfNewCluster,
            rareEventsSize,
            anomalyHigherCount,
            anomalyLowerCount,
            numOfCriticalEvent,
            numOfWhitelistEvent,
            numOfIncidentEvent,
            isShadowProject,
            anomalySize,
            originalSize,
            compressedSize,
            federatedLearning,
            compressionRatio,
            reductionRatio,
          } = info;
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {day}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.totalEvents }} />
              {`${intl.formatMessage(hasAlert === 'true' ? logMessages.totalAlerts : logMessages.totalLogs)}: ${numeral(
                logentrycount,
              ).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.totalPatterns }} />
              {`${intl.formatMessage(logMessages.clusters)}: ${numeral(numOfCluster).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.newPatterns }} />
              {`${intl.formatMessage(logMessages.newClusters)}: ${numeral(numOfNewCluster).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.rareEvents }} />
              {`${intl.formatMessage(logMessages.rareEvents)}: ${numeral(rareEventsSize).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.hotEvents }} />
              {`${intl.formatMessage(logMessages.hotEventsPeriods)}: ${numeral(anomalyHigherCount).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.coldEvents }} />
              {`${intl.formatMessage(logMessages.coldEventsPeriods)}: ${numeral(anomalyLowerCount).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.criticalEvents }} />
              {`${intl.formatMessage(logMessages.numOfCriticalEvent)}: ${numeral(numOfCriticalEvent).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.whitelistEvents }} />
              {`${intl.formatMessage(logMessages.keywordAlerts)}: ${numeral(numOfWhitelistEvent).format('0,0')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.incidentEvents }} />
              {`${intl.formatMessage(eventMessages.incidents)}: ${numeral(numOfIncidentEvent).format('0,0')}`}
              <br />
              {isShadowProject && (
                <>
                  <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.anomalySize }} />
                  {`${intl.formatMessage(logMessages.anomalousLogSize)}: ${numeral(anomalySize).format('0.0ib')}`}
                  <br />
                </>
              )}
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.originalSize }} />
              {`${intl.formatMessage(logMessages.originalSize)}: ${numeral(originalSize).format('0.0ib')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.compressedSize }} />
              {`${intl.formatMessage(logMessages.compressedSize)}: ${numeral(compressedSize).format('0.0ib')}`}
              <br />
              <span className="ecahrt-tooltip-dot" style={{ backgroundColor: this.categoryColorMap.decreaseRatio }} />
              {`${intl.formatMessage(logMessages.decreaseRatio)}: ${numeral(compressionRatio).format('0.0%')}`}
              <br />
              {isShadowProject && (
                <>
                  <span
                    className="ecahrt-tooltip-dot"
                    style={{ backgroundColor: this.categoryColorMap.federatedLearning }}
                  />
                  {`${intl.formatMessage(logMessages.federatedLearning)}: ${numeral(federatedLearning).format(
                    '0.00%',
                  )}`}
                </>
              )}
              {hasAlert === 'true' && <br />}
              {hasAlert === 'true' && (
                <span>
                  <span
                    className="ecahrt-tooltip-dot"
                    style={{ backgroundColor: this.categoryColorMap.alertReductionRatio }}
                  />
                  {`${intl.formatMessage(logMessages.alertReductionRatio)}: ${numeral(reductionRatio).format('0.0%')}`}
                </span>
              )}
            </div>,
          );
        },
      },
      grid: {
        left: 40,
        right: 20,
        top: 40,
        bottom: 10,
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        data: dayNames,
      },
      yAxis: {
        type: 'value',
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          type: 'bar',
          data: datas,
          barMaxWidth: 20,
          zlevel: 20,
          itemStyle: { color: this.categoryColorMap[dataCategory] },
        },
        {
          type: 'bar',
          data: R.map((item) => ({ ...item, value: maxVal }), datas),
          barMaxWidth: 20,
          barGap: '-100%',
          itemStyle: { color: 'gray', opacity: 0.1 },
        },
      ],
    };
    return { monthChartOption };
  }

  @autobind
  handleProjectChange(projectName) {
    const { location, replace } = this.props;
    const query = parseLocation(location);

    // force refresh
    replace(buildLocation(location.pathname, {}, { ...query, projectName }));
  }

  @autobind
  handleStartMonthChange(startMonthObj) {
    this.setState({ startMonthObj });
  }

  @autobind
  handleEndMonthChange(endMonthObj) {
    this.setState({ endMonthObj });
  }

  @autobind
  handleMonthValueChange(selectMonth) {
    const { location, replace } = this.props;
    const query = parseLocation(location);

    // force refresh
    replace(buildLocation(location.pathname, {}, { ...query, selectMonth }));
  }

  @autobind
  handleYearChartClick(item) {
    const { info } = item.data || {};
    const { monthStr } = info || {};
    this.handleMonthValueChange(monthStr);
  }

  @autobind
  handleMonthChartClick(item) {
    const { info } = item.data || {};
    const { day } = info || {};

    const { location, push } = this.props;
    const query = parseLocation(location);
    delete query.activePatternId;
    delete query.anomalyType;
    // force refresh
    push(
      buildLocation(
        BaseUrls.LogAnalysis,
        {},
        { ...query, instanceName: undefined, startTime: day, endTime: day, activeTab: 'important' },
      ),
    );
  }

  @autobind
  handleUpdateClick() {
    this.setState({
      showTimeSelectModal: true,
    });
  }

  @autobind
  onCloseTimeSelect(props) {
    const { startTimestamp, endTimestamp, projectName, userName, patternCategory, close, ...rest } = props || {};
    if (close) {
      this.setState({
        showTimeSelectModal: false,
      });
      return;
    }
    if (startTimestamp && endTimestamp && projectName && userName && patternCategory) {
      this.setState(
        {
          showTimeSelectModal: false,
        },
        () => {
          this.updateLogCalendar({
            selectStartTimestamp: startTimestamp,
            selectEndTimestamp: endTimestamp,
            projectName,
            userName,
            patternCategory,
            ...rest,
          });
        },
      );
    } else {
      this.setState({ showTimeSelectModal: false });
    }
  }

  @autobind
  updateRerunOtherProject(query) {
    const { intl } = this.props;
    fetchPost(getEndpoint('logreruntoanotherproject'), query)
      .then((res) => {
        this.setState({
          showTimeSelectModal: false,
        });
        message.success(intl.formatMessage(appMessages.apiSuccess));
      })
      .catch((e) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
      });
  }

  @autobind
  updateLogCalendar(state) {
    const { intl, credentials } = this.props;
    const { selectStartTimestamp, selectEndTimestamp, projectName, userName, patternCategory } = state;
    const isSameUser = userName === credentials.userName;
    const startTimestamp = moment.utc(selectStartTimestamp).startOf('day').valueOf();
    const endTimestamp = moment.utc(selectEndTimestamp).endOf('day').valueOf();

    this.props.updateLastActionInfo();
    if (patternCategory === 'rerunCalendar') {
      const url = `${window.BASE_URL || ''}/mergeCalendarInfo`;
      const params = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        startTime: moment.utc(startTimestamp).format(Defaults.DateFormat),
        endTime: moment.utc(endTimestamp).format(Defaults.DateFormat),
      };
      BackgroundCall.CallUrlJob({ intl, method: 'GET', url, params, isJsonResponse: false });
    } else if (patternCategory === 'rerunLog') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'LOG_RERUN',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunLogIncident') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'LOG_RERUN_INCIDENT',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunRare') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'RARE_EVENT',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'newPattern') {
      const url = `${window.BASE_URL || ''}/RerunPattern`;
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        startTimeString: moment.utc(startTimestamp).format(Defaults.DateFormat),
        endTimeString: moment.utc(endTimestamp).format(Defaults.DateFormat),
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunHotEvent') {
      const url = getEndpoint('logrerunhotevent');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        dayTimeMillis: startTimestamp,
        startTimestamp,
        endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: false });
    } else if (patternCategory === 'rerunWhitelistDetection') {
      const url = getEndpoint('rerunwhitelistevents');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        startTimestamp,
        endTimestamp,
        // instanceName
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: false });
    } else if (patternCategory === 'rerunFeatureCollection') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'FEATURE',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunOutlierDetection') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'FEATURE_OUTLIER_DETECTION',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunOutlierModelCreation') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'FEATURE_OUTLIER_MODEL',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunLogToMetric') {
      const url = getEndpoint('logrerunanomaly');
      const { metricProjectName } = state;
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'LOG_TO_METRIC',
        startTime: startTimestamp,
        endTime: endTimestamp,
        metricProjectName,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunJsonField') {
      const url = getEndpoint('collectjsonfields');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        UserName: userName,
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({
        intl,
        method: 'POST',
        url,
        params: {},
        data,
        isJsonResponse: true,
        readMessage: true,
      });
    } else if (patternCategory === 'rerunAnomalyFeature') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'INCIDENT_ANOMALY_FEATURE',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunMISCCluster') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'LOG_RERUN_MISC',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunAllpatternFeature') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        customerName: userName,
        type: 'LOG_ALL_FEATURE',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunServiceNowNotes') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        customerName: userName,
        type: 'EXTERNAL_SERVICE_NOTES',
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'LogDataDeletion') {
      const url = getEndpoint('logdeletebyrange');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        customerName: userName,
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunLogPatternName') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        customerName: userName,
        type: 'PATTERN_NAME',
        startTime: 0,
        endTime: 0,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunZoneCollection') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'ZONE_NAME',
        startTime: 0,
        endTime: 0,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunCDFDataStream') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'CDF_DATA_STREAM',
        customerName: userName,
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    } else if (patternCategory === 'rerunCDFDataCollect') {
      const url = getEndpoint('logrerunanomaly');
      const data = {
        ...credentials,
        projectName: !isSameUser ? `${projectName}@${userName}` : projectName,
        type: 'CDF_DATA_COLLECT',
        customerName: userName,
        startTime: startTimestamp,
        endTime: endTimestamp,
      };
      BackgroundCall.CallUrlJob({ intl, method: 'POST', url, params: {}, data, isJsonResponse: true });
    }
  }

  @autobind
  handleInsightQueryClick(event) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      showInsightQueryBox: true,
      queryParams: {},
    });
  }

  @autobind
  onConfirmInsightQueryProjectSelect(params) {
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
      customerName,
    } = params;
    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime: startTimeObj.valueOf(),
      endTime: endTimeObj.valueOf(),
      keyword,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      pattern,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      customerName,
      duration,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    this.setState({ showInsightQueryBox: false });
  }

  @autobind
  handleCustomerNameChange(customerName) {
    const { location, showAppLoader } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    setTimeout(() => {
      const query = parseLocation(location);
      const { pathname, search } = buildLocation(
        location.pathname,
        {},
        { ...query, customerName, projectName: undefined, systemId: undefined },
      );
      window.location.href = pathname + search;
    }, 1);
  }

  @autobind
  handleSystemIdChange(systemId) {
    const { push, location } = this.props;
    const params = parseLocation(location);

    push(buildLocation(location.pathname, {}, { ...params, systemId, projectName: undefined }));
  }

  render() {
    const {
      intl,
      location,
      isReadUser,
      projects,
      userInfo,
      userList,
      systemList: systemLists,
      credentials,
      replace,
      currentTheme,
    } = this.props;
    const {
      startMonthObj,
      endMonthObj,
      isLoadingYearData,
      isLoadingMonthData,
      monthStrList,
      yearDatas,
      yearChartOption,
      monthChartOption,
      dataCategory,
    } = this.state;
    const params = parseLocation(location);
    const {
      projectName,
      startMonth,
      endMonth,
      selectMonth,
      hasLog,
      hasAlert,
      customerName,
      systemId,
      forceRefreshTime,
    } = params;

    let systemList =
      systemLists.length > 0
        ? [...systemLists, { systemName: 'Projects with no system', systemId: 'projectsWithNoSystem' }]
        : [];
    if (hasLog) {
      systemList = [
        { systemName: 'All systems', systemId: 'allSystems' },
        { systemName: 'All projects', systemId: 'allProjects' },
        ...systemList,
      ];
    }

    let filterProjects = R.filter(
      (project) =>
        (!hasLog && !hasAlert) ||
        (hasLog === 'true' && (project.isLog || project.isDeployment)) ||
        (hasAlert === 'true' && (project.isAlert || project.isIncident)),
      projects,
    );
    filterProjects = R.filter((project) => project.ps !== 'Deleting', filterProjects);

    if (userInfo.isAdmin || userInfo.isLocalAdmin) {
      systemList = R.filter(
        (item) =>
          item.owner === customerName ||
          item.systemId === 'projectsWithNoSystem' ||
          item.systemId === 'allSystems' ||
          item.systemId === 'allProjects' ||
          (item.shareUserSet || []).includes(customerName),
        systemList,
      );
    }
    R.forEach((item) => {
      item.hasProjectList = [];
      R.forEach((_item) => {
        if (_item.systemId && item.systemId === _item.systemId) {
          item.hasProjectList.push(_item);
        } else if (item.systemId === 'projectsWithNoSystem' && !_item.systemId) {
          item.hasProjectList.push(_item);
        }
      }, filterProjects);
    }, systemList);
    systemList = R.filter(
      (item) => item.hasProjectList.length > 0 || item.systemId === 'allSystems' || item.systemId === 'allProjects',
      systemList,
    );

    filterProjects = R.filter(
      (item) =>
        systemId === 'projectsWithNoSystem'
          ? item.systemId === undefined
          : item.systemId === systemId && item.systemId !== undefined,
      filterProjects,
    );
    const monthInfo = R.find((item) => item.monthStr === selectMonth, yearDatas);

    const disableRefresh = endMonthObj < startMonthObj;
    const timeChange =
      startMonthObj &&
      endMonthObj &&
      (startMonth !== startMonthObj.format(Defaults.MonthFormat) ||
        endMonth !== endMonthObj.format(Defaults.MonthFormat));

    return (
      <Container fullHeight withGutter className="flex-col flex-min-height">
        <Container breadcrumb className="flex-row">
          <div className="flex-grow  flex-row flex-center-align">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {intl.formatMessage(hasLog ? appMenusMessages.logAnalysis : appMenusMessages.alertAnalysis)}
              </Breadcrumb.Item>
            </Breadcrumb>

            {!userInfo.isReadUser && (
              <Button type="primary" size="small" style={{ marginLeft: 16 }} onClick={this.handleUpdateClick}>
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            )}
            <Button type="primary" size="small" style={{ marginLeft: 8 }} onClick={this.handleInsightQueryClick}>
              {intl.formatMessage(appMenusMessages.logQuery)}
            </Button>
          </div>
          <div className="flex-row flex-center-align">
            {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
              <span style={{ fontWeight: 700, padding: '0 0.8em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
            )}
            {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
              <Select
                showSearch
                size="small"
                value={customerName}
                style={{ width: 100 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.handleCustomerNameChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            )}
            <span style={{ fontWeight: 700, padding: '0 0.8em' }}>{intl.formatMessage(appFieldsMessages.system)}</span>
            <Select
              showSearch
              size="small"
              value={systemId}
              style={{ width: 130 }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={this.handleSystemIdChange}
              dropdownMatchSelectWidth={false}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.systemId} value={item.systemId}>
                    {`${item.systemName}${item.owner ? ` (${item.owner})` : ''}`}
                  </Select.Option>
                ),
                systemList || [],
              )}
            </Select>
            <span style={{ fontWeight: 700, padding: '0 0.8em' }}>{intl.formatMessage(appFieldsMessages.project)}</span>
            <Select
              size="small"
              style={{ width: 140 }}
              showSearch
              filterOption
              optionFilterProp="value"
              value={projectName}
              status={projectName ? '' : 'error'}
              onChange={this.handleProjectChange}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              disabled={systemId === 'allSystems' || systemId === 'allProjects'}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.projectName} value={item.projectName} title={item.projectName}>
                    {`${item.projectDisplayName}${
                      userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName
                        ? `@${item.owner}`
                        : ''
                    }`}
                  </Select.Option>
                ),
                filterProjects || [],
              )}
            </Select>
            <span style={{ fontWeight: 700, padding: '0 0.8em' }}>
              {intl.formatMessage(appFieldsMessages.startMonth)}
            </span>
            <DatePicker.MonthPicker
              size="small"
              allowClear={false}
              value={startMonthObj}
              onChange={this.handleStartMonthChange}
            />
            <span style={{ fontWeight: 700, padding: '0 0.8em' }}>
              {intl.formatMessage(appFieldsMessages.endMonth)}
            </span>
            <DatePicker.MonthPicker
              size="small"
              allowClear={false}
              value={endMonthObj}
              onChange={this.handleEndMonthChange}
            />
            <Popover
              mouseEnterDelay={0.3}
              placement="bottomRight"
              visible={timeChange}
              title={null}
              content={timeChange ? intl.formatMessage(appMessages.clickToReload) : null}
            >
              <Button
                size="small"
                disabled={disableRefresh}
                onClick={this.handleRefreshClick}
                style={{ marginLeft: 8 }}
              >
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </Popover>
          </div>
        </Container>
        {systemId === 'allSystems' && (
          <Container
            className="flex-grow flex-col flex-min-height content-bg corner-10"
            style={{ margin: '0 16px 8px 16px' }}
          >
            <AllSystemsChart
              intl={intl}
              credentials={credentials}
              customerName={customerName}
              startMonth={startMonth}
              endMonth={endMonth}
              forceRefreshTime={forceRefreshTime}
              selectMonth={selectMonth}
              location={location}
              replace={replace}
              userInfo={userInfo}
              systemList={systemList}
              currentTheme={currentTheme}
            />
          </Container>
        )}
        {systemId === 'allProjects' && (
          <Container
            className="flex-grow flex-col flex-min-height content-bg corner-10"
            style={{ margin: '0 16px 8px 16px' }}
          >
            <AllProjectsChart
              intl={intl}
              credentials={credentials}
              customerName={customerName}
              startMonth={startMonth}
              endMonth={endMonth}
              forceRefreshTime={forceRefreshTime}
              selectMonth={selectMonth}
              location={location}
              replace={replace}
              userInfo={userInfo}
              currentTheme={currentTheme}
              projects={projects}
            />
          </Container>
        )}
        {systemId !== 'allSystems' && systemId !== 'allProjects' && (
          <Container
            className="flex-grow flex-col flex-min-height content-bg corner-10"
            style={{ margin: '0 16px 8px 16px' }}
          >
            <div className="flex-row flex-center-align flex-center-justify overflow-x-auto" style={{ height: 60 }}>
              {R.map((monthStr) => {
                return (
                  <div
                    key={monthStr}
                    style={{
                      cursor: 'pointer',
                      margin: '0 12px',
                      ...(monthStr === selectMonth
                        ? { fontWeight: 'bold', fontSize: 20, borderBottom: '2px solid #ff5142' }
                        : { fontSize: 14 }),
                    }}
                    onClick={() => this.handleMonthValueChange(monthStr)}
                  >
                    {this.monthNameMap[moment.utc(monthStr, Defaults.MonthFormat).format(Defaults.MonthOnlyFormat)]}
                  </div>
                );
              }, monthStrList)}
            </div>

            <div className="flex-grow health-panel content-bg corner-8" style={{ margin: '0 16px' }}>
              <Spin spinning={isLoadingYearData} wrapperClassName="full-height spin-full-width flex-row flex-min-width">
                <div
                  className="flex-row flex-center-align flex-center-justify"
                  style={{ width: 400, padding: '8px 16px' }}
                >
                  {!monthInfo && <Empty />}

                  {monthInfo && (
                    <div className="flex-row">
                      <div className="flex-grow flex-col">
                        <div className="flex-grow" style={{ fontSize: 28, fontWeight: 'bold' }}>
                          {monthInfo.monthStr}
                        </div>
                        <div className="flex-grow flex-col">
                          <div style={{ fontSize: 24, color: this.categoryColorMap.totalEvents }}>
                            {numeral(get(monthInfo, 'logentrycount', 0)).format('0,0')}
                          </div>
                          <div
                            className="link light-label bold"
                            onClick={() => this.handleDataCategoryChange('totalEvents')}
                          >
                            <span style={{ borderBottom: dataCategory === 'totalEvents' ? '2px solid #ff5142' : null }}>
                              {intl.formatMessage(
                                hasAlert === 'true' ? logMessages.totalAlerts : logMessages.totalLogs,
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="flex-grow flex-col">
                          <div style={{ fontSize: 18, color: this.categoryColorMap.totalPatterns }}>
                            {numeral(get(monthInfo, 'numOfCluster', 0)).format('0,0')}
                          </div>
                          <div
                            className="link light-label bold"
                            onClick={() => this.handleDataCategoryChange('totalPatterns')}
                          >
                            <span
                              style={{ borderBottom: dataCategory === 'totalPatterns' ? '2px solid #ff5142' : null }}
                            >
                              {intl.formatMessage(logMessages.clusters)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow flex-col">
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('newPatterns')}
                          >
                            <span style={{ borderBottom: dataCategory === 'newPatterns' ? '2px solid #ff5142' : null }}>
                              {intl.formatMessage(logMessages.newClusters)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.newPatterns }}>
                            {numeral(get(monthInfo, 'numOfNewCluster', 0)).format('0,0')}
                          </div>
                        </div>
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('rareEvents')}
                          >
                            <span style={{ borderBottom: dataCategory === 'rareEvents' ? '2px solid #ff5142' : null }}>
                              {intl.formatMessage(logMessages.rareEvents)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.rareEvents }}>
                            {numeral(get(monthInfo, 'rareEventsSize', 0)).format('0,0')}
                          </div>
                        </div>
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('hotEvents')}
                          >
                            <span style={{ borderBottom: dataCategory === 'hotEvents' ? '2px solid #ff5142' : null }}>
                              {intl.formatMessage(logMessages.hotEventsPeriods)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.hotEvents }}>
                            {numeral(get(monthInfo, 'anomalyHigherCount', 0)).format('0,0')}
                          </div>
                        </div>
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('coldEvents')}
                          >
                            <span style={{ borderBottom: dataCategory === 'coldEvents' ? '2px solid #ff5142' : null }}>
                              {intl.formatMessage(logMessages.coldEventsPeriods)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.coldEvents }}>
                            {numeral(get(monthInfo, 'anomalyLowerCount', 0)).format('0,0')}
                          </div>
                        </div>
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('criticalEvents')}
                          >
                            <span
                              style={{ borderBottom: dataCategory === 'criticalEvents' ? '2px solid #ff5142' : null }}
                            >
                              {intl.formatMessage(logMessages.numOfCriticalEvent)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.criticalEvents }}>
                            {numeral(get(monthInfo, 'numOfCriticalEvent', 0)).format('0,0')}
                          </div>
                        </div>
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('whitelistEvents')}
                          >
                            <span
                              style={{ borderBottom: dataCategory === 'whitelistEvents' ? '2px solid #ff5142' : null }}
                            >
                              {intl.formatMessage(logMessages.keywordAlerts)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.whitelistEvents }}>
                            {numeral(get(monthInfo, 'numOfWhitelistEvent', 0)).format('0,0')}
                          </div>
                        </div>
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('incidentEvents')}
                          >
                            <span
                              style={{ borderBottom: dataCategory === 'incidentEvents' ? '2px solid #ff5142' : null }}
                            >
                              {intl.formatMessage(eventMessages.incidents)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.incidentEvents }}>
                            {numeral(get(monthInfo, 'numOfIncidentEvent', 0)).format('0,0')}
                          </div>
                        </div>

                        {monthInfo.isShadowProject && (
                          <div className="flex-row line-height-20">
                            <div
                              className="link light-label bold"
                              style={{
                                width: 150,
                                marginRight: 8,
                                textAlign: 'right',
                              }}
                              onClick={() => this.handleDataCategoryChange('anomalySize')}
                            >
                              <span
                                style={{ borderBottom: dataCategory === 'anomalySize' ? '2px solid #ff5142' : null }}
                              >
                                {intl.formatMessage(logMessages.anomalousLogSize)}
                              </span>
                            </div>
                            <div style={{ color: this.categoryColorMap.anomalySize }}>
                              {numeral(get(monthInfo, 'anomalySize', 0)).format('0.0ib')}
                            </div>
                          </div>
                        )}
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('originalSize')}
                          >
                            <span
                              style={{ borderBottom: dataCategory === 'originalSize' ? '2px solid #ff5142' : null }}
                            >
                              {intl.formatMessage(logMessages.originalSize)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.originalSize }}>
                            {numeral(get(monthInfo, 'originalSize', 0)).format('0.0ib')}
                          </div>
                        </div>
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('compressedSize')}
                          >
                            <span
                              style={{ borderBottom: dataCategory === 'compressedSize' ? '2px solid #ff5142' : null }}
                            >
                              {intl.formatMessage(logMessages.compressedSize)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.compressedSize }}>
                            {numeral(get(monthInfo, 'compressedSize', 0)).format('0.0ib')}
                          </div>
                        </div>
                        <div className="flex-row line-height-20">
                          <div
                            className="link light-label bold"
                            style={{
                              width: 150,
                              marginRight: 8,
                              textAlign: 'right',
                            }}
                            onClick={() => this.handleDataCategoryChange('decreaseRatio')}
                          >
                            <span
                              style={{ borderBottom: dataCategory === 'decreaseRatio' ? '2px solid #ff5142' : null }}
                            >
                              {intl.formatMessage(logMessages.decreaseRatio)}
                            </span>
                          </div>
                          <div style={{ color: this.categoryColorMap.decreaseRatio }}>
                            {numeral(get(monthInfo, 'compressionRatio', 0)).format('0.0%')}
                          </div>
                        </div>
                        {monthInfo.isShadowProject && (
                          <div className="flex-row line-height-20 flex-center-align">
                            <div
                              className="link light-label bold"
                              style={{
                                width: 150,
                                marginRight: 8,
                                textAlign: 'right',
                              }}
                              onClick={() => this.handleDataCategoryChange('federatedLearning')}
                            >
                              <span
                                style={{
                                  borderBottom: dataCategory === 'federatedLearning' ? '2px solid #ff5142' : null,
                                }}
                              >
                                {intl.formatMessage(logMessages.federatedLearning)}
                              </span>
                            </div>
                            <div style={{ color: this.categoryColorMap.federatedLearning }}>
                              {numeral(get(monthInfo, 'federatedLearning', 0)).format('0.00%')}
                            </div>
                          </div>
                        )}
                        {hasAlert === 'true' && (
                          <div className="flex-row line-height-20">
                            <div
                              className="link light-label bold"
                              style={{
                                width: 150,
                                marginRight: 8,
                                textAlign: 'right',
                              }}
                              onClick={() => this.handleDataCategoryChange('alertReductionRatio')}
                            >
                              <span
                                style={{
                                  borderBottom: dataCategory === 'alertReductionRatio' ? '2px solid #ff5142' : null,
                                }}
                              >
                                {intl.formatMessage(logMessages.alertReductionRatio)}
                              </span>
                            </div>
                            <div style={{ color: this.categoryColorMap.alertReductionRatio }}>
                              {numeral(get(monthInfo, 'reductionRatio', 0)).format('0.0%')}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex-grow">
                  {yearChartOption && (
                    <AutoSizer>
                      {({ height, width }) => (
                        <EChart
                          width={width}
                          height={height}
                          option={yearChartOption}
                          onClick={this.handleYearChartClick}
                          theme={currentTheme}
                        />
                      )}
                    </AutoSizer>
                  )}
                </div>
              </Spin>
            </div>

            <div className="flex-grow health-panel content-bg corner-8" style={{ margin: 16 }}>
              <Spin
                spinning={isLoadingMonthData}
                wrapperClassName="full-height spin-full-height flex-col flex-min-height"
              >
                {monthChartOption && (
                  <AutoSizer>
                    {({ height, width }) => (
                      <EChart
                        width={width}
                        height={height}
                        option={monthChartOption}
                        onClick={this.handleMonthChartClick}
                        theme={currentTheme}
                      />
                    )}
                  </AutoSizer>
                )}
              </Spin>
            </div>
          </Container>
        )}

        {this.state.showTimeSelectModal && (
          <DateSelectModal
            onClose={this.onCloseTimeSelect}
            updateRerunOtherProject={this.updateRerunOtherProject}
            startTimestamp={
              selectMonth ? moment.utc(selectMonth, Defaults.MonthFormat).startOf('month').valueOf() : undefined
            }
            endTimestamp={
              selectMonth
                ? R.min(
                    moment.utc(selectMonth, Defaults.MonthFormat).endOf('month').valueOf(),
                    moment.utc().endOf('day').valueOf(),
                  )
                : undefined
            }
            needProject
            needLogProject={hasLog}
            needAlertProject={!hasLog}
            needPattern
            defaultProjectName={projectName}
          />
        )}
        {this.state.showInsightQueryBox && (
          <InsightQueryBoxModal
            projects={[{ projectNameReal: projectName, projectDisplayName: projectName }]}
            queryParams={this.state.queryParams}
            onConfirm={this.onConfirmInsightQueryProjectSelect}
            onClose={() => this.setState({ showInsightQueryBox: false })}
          />
        )}
      </Container>
    );
  }
}

const LogCalendarChart = injectIntl(LogCalendarChartCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, projects, systemsMap, globalInfo, currentTheme } = state.app;
    let { userList } = state.app;
    const { credentials, userInfo, isReadUser } = state.auth;

    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    let systemList = R.values(systemsMap || {});
    systemList = R.sortWith([R.ascend(R.prop('systemName'))])(systemList);
    return {
      location,
      loadStatus,
      projects,
      credentials,
      userInfo,
      isReadUser,
      userList,
      systemList,
      globalInfo,
      currentTheme,
    };
  },
  { hideAppLoader, showAppLoader, updateLastActionInfo, push, replace },
)(LogCalendarChart);
