import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { message, Spin } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';
import { getCacheData, setCacheData } from '../utils';
import { DashboardMessages } from '../../../common/dashboard/messages';

const handleIsNil = (value) => {
  return R.isNil(value) ? 'N/A' : value;
};

export default function AutoFixedIncidents(props: Object) {
  const { intl, systemId, startTime, endTime, credentials, systemIdsWithShare } = props;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    loading: false,
    totalFixedDetections: 0,
    totalFixedPredictions: 0,
  });
  const { loading, totalFixedDetections, totalFixedPredictions } = state;

  const parseData = (data) => {
    const system = R.find((item) => item.systemKey === systemId, data || []);
    if (!system) {
      return;
    }

    const { totalFixedDetections, totalFixedPredictions } = system;
    setState({
      totalFixedDetections: handleIsNil(totalFixedDetections),
      totalFixedPredictions: handleIsNil(totalFixedPredictions),
    });
  };

  const reloadData = () => {
    const startDate = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endDate = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();

    const filterSystem = R.filter((item) => item.id === systemId, systemIdsWithShare || []);

    const params = {
      ...credentials,
      systemIdsWithShare: JSON.stringify(filterSystem),
      startTime: startDate,
      endTime: endDate,
      cacheKey: 'IncidentPredictionAccuracy',
    };

    const apiData = getCacheData(params);
    if (apiData) {
      parseData(apiData);
      return;
    }

    setState({ loading: true });
    fetchPost(getEndpoint('dashboard-summary'), R.clone(params))
      .then((data) => {
        setState({ loading: false });
        parseData(data);
        setCacheData(params, data);
      })
      .catch((e) => {
        setState({ loading: false });
        if (e?.code >= 500) {
          console.error(e.message || String(e));
        } else {
          message.error(e.message || String(e));
        }
      });
  };

  useEffect(() => {
    reloadData();
  }, [systemId]);

  return (
    <Spin spinning={loading} wrapperClassName="spin-base overflow-x-hidden overflow-y-auto full-width full-height">
      <div className="full-height flex-col">
        <div className="font-14 bold flex-row flex-space-between flex-center-align">
          <span>{intl.formatMessage(DashboardMessages.autoFixedIncidents)}</span>
        </div>
        <div className="flex-row flex-center-align full-width flex-center-justify flex-grow" style={{ marginTop: 8 }}>
          <div style={{ marginRight: 24 }}>
            <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
              {intl.formatMessage(DashboardMessages.fixedDetection)}
            </div>
            <div style={{ fontSize: 18 }}>{totalFixedDetections}</div>
          </div>
          <div>
            <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
              {intl.formatMessage(DashboardMessages.fixedPrediction)}
            </div>
            <div style={{ fontSize: 18 }}>{totalFixedPredictions}</div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
