import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import numeral from 'numeral';
import moment from 'moment';
import { get, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import {
  EyeInvisibleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  ExclamationCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { Spin, Switch, message, Checkbox } from 'antd';
import ThundraTraceChart from 'thundra-trace-chart';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { Defaults, EventRenderers, LogRenderers, buildUrl, sleep, CellRenderers } from '../../../common/utils';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer, Popover } from '../../../lib/fui/react';
import { updateLastActionInfo, createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/dashboard/actions';
import {
  RootCauseIcon,
  IncidentPredictIcon,
  ChangeEventIcon,
  AlertEventIcon,
  FlagNewIcon,
} from '../../../lib/fui/icons';

import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';

import IncidentListModal from './IncidentListModal';
import RenderLeadToIncident from './RenderLeadToIncident';
import TraceListModal from './TraceListModal';

const AlertChangesAllOptions = ['Log alerts', 'Metric alerts', 'Change events'];

type Props = {
  style: Object,
  // eslint-disable-next-line
  activeKey: String,
  // eslint-disable-next-line
  startTime: String,
  // eslint-disable-next-line
  endTime: String,
  // eslint-disable-next-line
  customerName: String,
  // eslint-disable-next-line
  environmentId: String,
  // eslint-disable-next-line
  refreshTime: Number,
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  zoomStartTime: Number,
  // eslint-disable-next-line
  zoomEndTime: Number,
  // eslint-disable-next-line
  selectAnomalyInstance: String,
  // eslint-disable-next-line
  systemHealth: Object,
  // eslint-disable-next-line
  systemInfo: Object,
  // eslint-disable-next-line
  isLoading: Boolean,
  // eslint-disable-next-line
  reloadSystem: Number,
  // eslint-disable-next-line
  onReloadSystemIncidentTimelines: Function,
  handleUpdateSystemRankMap: Function,
  handleUpdateSystemTimelines: Function,
  // eslint-disable-next-line
  updateState: Function,

  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createLoadAction: Function,

  // eslint-disable-next-line
  globalSystemAlertTimelines: Array<Object>,

  forceRefreshTime: String,

  dataTotalCount: Array,
  getReloadDataTotalCount: Function,
  currentTheme: String,
};

class GlobalPanelAlertsCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });
    this.state = {
      isLoading: false,
      isAutoReload: false,
      systemTrending: { current: 0, trending: 0 },
      // events: [],
      eventList: [],
      patternNameMap: {},
      summarySettingsMap: {},
      activeEvent: null,

      ignoreFilter: true,

      showDetailsModal: false,
      showTraceDetailsModal: false,

      filterEventList: [],
      filterPlainOption: AlertChangesAllOptions,
    };
    this.patternCountMap = {};
    this.patternCountProjectMap = {};
    this.leadIncidentPatternNameStrMap = {};

    this.defaultDisplayAlertSize = 0;
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.startTime !== this.props.startTime ||
      nextProps.endTime !== this.props.endTime ||
      nextProps.customerName !== this.props.customerName ||
      nextProps.environmentId !== this.props.environmentId ||
      nextProps.refreshTime !== this.props.refreshTime ||
      nextProps.refresh !== this.props.refresh ||
      nextProps.systemInfo !== this.props.systemInfo ||
      nextProps.reloadSystem !== this.props.reloadSystem
    ) {
      const isAutoReload = nextProps.refreshTime !== this.props.refreshTime;
      if (!isAutoReload || (isAutoReload && !nextProps.zoomStartTime && !nextProps.zoomEndTime)) {
        this.setState({ filterPlainOption: AlertChangesAllOptions }, () => {
          this.reloadData(nextProps, { isAutoReload });
        });
      }
    } else if (nextProps.selectAnomalyInstance !== this.props.selectAnomalyInstance) {
      if (nextProps.systemInfo) {
        this.setState({ filterPlainOption: AlertChangesAllOptions }, () => {
          this.parseData(nextProps);
        });
      }
    } else if (nextProps.forceRefreshTime !== this.props.forceRefreshTime) {
      const isAutoReload = nextProps.refreshTime !== this.props.refreshTime;
      if (!isAutoReload || (isAutoReload && !nextProps.zoomStartTime && !nextProps.zoomEndTime)) {
        this.setState({ filterPlainOption: AlertChangesAllOptions }, () => {
          this.reloadData(nextProps, { isAutoReload });
        });
      }
    }
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData(props, params) {
    const { environmentId, startTime, endTime, customerName, systemInfo } = props;
    const { createLoadAction, userInfo } = props;
    const { isAutoReload } = params || {};

    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      startTime &&
      endTime &&
      systemInfo &&
      systemInfo.hasAllInstanceInfo
    ) {
      this.setState({ isLoading: !isAutoReload, isAutoReload, patternNameMap: {}, summarySettingsMap: {} });
      this.props.updateState({ isLoadingPanelAlert: !isAutoReload });
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      createLoadAction(
        ActionTypes.LOAD_GLOBAL_SYSTEM_ALERT_TIMELINES,
        {
          isAutoReload,
          level: 'system',
          environmentId,
          systemIds: [systemInfo.id],
          startTime: startTimestamp,
          endTime: endTimestamp,
        },
        false,
        false,
        this.callbackSetAnomalyTimelineState(),
      );
    }
  }

  @autobind
  callbackSetAnomalyTimelineState() {
    return () => {
      this.reloadDataTotalCount();
    };
  }

  // @autobind
  // reloadDataTotalCount() {
  //   const { intl, credentials } = this.props;
  //   const { environmentId, startTime, endTime, systemInfo } = this.props;
  //   const { isAutoReload, ignoreFilter } = this.state;

  //   this.setState({ isLoading: !isAutoReload });
  //   this.props.updateState({ isLoadingPanelAlert: !isAutoReload });
  //   const startTimestampPrev = moment.utc(startTime, Defaults.DateFormat).subtract(1, 'days').startOf('day').valueOf();
  //   const endTimestampPrev = moment.utc(startTime, Defaults.DateFormat).subtract(1, 'days').endOf('day').valueOf();
  //   const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
  //   const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
  //   Promise.all([
  //     fetchGet(getEndpoint('insightsAlertPatternCount'), {
  //       ...credentials,
  //       environmentName: environmentId,
  //       customerName: systemInfo.ownerUserName,
  //       systemName: systemInfo.id,
  //       startTime: startTimestampPrev,
  //       endTime: endTimestampPrev,
  //       isIncludeIgnored: false,
  //     }),
  //     fetchGet(getEndpoint('insightsAlertPatternCount'), {
  //       ...credentials,
  //       environmentName: environmentId,
  //       customerName: systemInfo.ownerUserName,
  //       systemName: systemInfo.id,
  //       startTime: startTimestamp,
  //       endTime: endTimestamp,
  //       isIncludeIgnored: !ignoreFilter,
  //     }),
  //   ])
  //     .then((results) => {
  //       const alertPatternStatsMapPrev = get(results[0], 'alertPatternStatsMap', []);
  //       let totalCountPrev = 0;
  //       R.forEach((item) => {
  //         const { patternCount } = item[0];
  //         totalCountPrev += patternCount;
  //       }, alertPatternStatsMapPrev);

  //       const alertPatternStatsMap = get(results[1], 'alertPatternStatsMap', []);
  //       let totalCount = 0;
  //       const patternCountMap = {};
  //       const patternCountProjectMap = {};
  //       R.forEach((item) => {
  //         const { projectName, componentName, instanceName, patternId, patternCount } = item[0];
  //         const key = `${projectName}-${componentName || instanceName}-${patternId}`;
  //         if (!R.has(key, patternCountMap)) {
  //           patternCountMap[key] = 0;
  //         }
  //         patternCountMap[key] += patternCount;

  //         const keyProject = `${projectName}-${patternId}`;
  //         if (!R.has(keyProject, patternCountProjectMap)) {
  //           patternCountProjectMap[keyProject] = 0;
  //         }
  //         patternCountProjectMap[keyProject] += patternCount;

  //         totalCount += patternCount;
  //       }, alertPatternStatsMap);
  //       const systemTrending = {
  //         current: totalCount || 0,
  //         trending: (totalCount || 0) - (totalCountPrev || 0),
  //       };

  //       this.patternCountMap = patternCountMap;
  //       this.patternCountProjectMap = patternCountProjectMap;
  //       this.setState({ systemTrending });
  //       this.parseData(this.props);
  //     })
  //     .catch((err) => {
  //       message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
  //       this.patternCountMap = {};
  //       this.patternCountProjectMap = {};
  //       this.setState({ isLoading: false, systemTrending: { current: 0, trending: 0 } });
  //       this.props.updateState({ isLoadingPanelAlert: false });
  //     });
  // }

  @autobind
  reloadDataTotalCount() {
    const { dataTotalCount } = this.props;
    const { isAutoReload } = this.state;
    this.setState({ isLoading: !isAutoReload });
    this.props.updateState({ isLoadingPanelAlert: !isAutoReload });
    if ((dataTotalCount || []).length > 0) {
      const alertPatternStatsMapPrev = get(dataTotalCount[0], 'result', []);
      let totalCountPrev = 0;
      R.forEach((item) => {
        const { totalAlerts } = item;
        totalCountPrev += totalAlerts;
      }, alertPatternStatsMapPrev);

      const alertPatternStatsMap = get(dataTotalCount[1], 'result', []);
      let totalCount = 0;
      R.forEach((item) => {
        const { totalAlerts } = item;
        totalCount += totalAlerts;
      }, alertPatternStatsMap);
      const systemTrending = {
        current: totalCount || 0,
        trending: (totalCount || 0) - (totalCountPrev || 0),
      };
      this.setState({ systemTrending });
      this.parseData(this.props);
    } else {
      this.setState({ isLoading: false, systemTrending: { current: 0, trending: 0 } });
      this.props.updateState({ isLoadingPanelAlert: false });
    }
  }

  @autobind
  async parseData(props) {
    const { userInfo, projects, selectAnomalyInstance, globalSystemAlertTimelines, systemInfo } = props;
    const { isAutoReload, sortBy, sortDirection, activeEvent, filterPlainOption } = this.state;

    this.setState({ isLoading: !isAutoReload, isAutoReload: false });
    this.props.updateState({ isLoadingPanelAlert: !isAutoReload });
    await sleep(300);

    // parse anomaly timelines
    const systemAlertTimelines = (R.find((item) => item.id === systemInfo.id, globalSystemAlertTimelines) || {})
      .anomalyTimelines;

    let events = systemAlertTimelines || [];

    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];

      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const instanceName = pair.instanceId || pair.instanceName;
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.instanceName} (${componentName})`
            : item.instanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);

      let typeList = [];
      if (item.category === 'metric' && item.type !== 'Incident') {
        typeList = ['metricanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', item.type).split('&'));
      }

      // build log json info
      let rawDataJson;
      try {
        rawDataJson = JSON.parse(item.rawData);
        if (!isObject(rawDataJson)) rawDataJson = undefined;
      } catch (error) {
        // console.debug(error);
      }

      // get falg isTrace from event's project, build trace info
      const { projectOwner } = item;
      let { projectName } = item;
      projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
      const project = R.find((project) => projectName === project.projectName, projects || []);
      const { isTrace } = project || {};
      const traceId = get(rawDataJson, 'trace_id');
      let traceInfo;
      if (isTrace) {
        const traceId = get(rawDataJson, 'trace_id');
        const parentId = get(rawDataJson, 'parent_span_id');
        const spanId = get(rawDataJson, 'span_id');
        const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentNameString;
        const name = get(rawDataJson, 'method_name');
        const startTime = get(rawDataJson, 'start_time');
        // use microseconds for timestamp and duration
        const duration = Number(get(rawDataJson, 'duration', 0)) * 1000;
        const timestamp = moment.utc(startTime).valueOf() * 1000;
        traceInfo = {
          traceId,
          parentId,
          id: spanId,
          name,
          timestamp,
          duration,
          serviceName,
          color: '#ffccc7',
          tags: {},
        };
      }

      return {
        ...item,
        rawDataJson,
        rootCauseJson: { rootCauseDetailsArr },
        appName,
        componentNameString,
        instanceAppNames,
        componentList,
        typeList,
        isRare: typeList.includes('rare'),
        isHot: typeList.includes('hot'),
        patternAndTrace: isTrace ? traceId : item.patternId,
        isTrace,
        traceId,
        traceInfo,
      };
    }, events);

    // filter
    if (selectAnomalyInstance) {
      events = R.filter((anomaly) => {
        return anomaly.instanceList.includes(selectAnomalyInstance);
      }, events);
    }
    let eventList = this.filterData(events);
    // merge
    eventList = this.mergeAnomalyEvents(props, eventList);
    // add rank number
    eventList = R.sortWith(
      [
        R.descend(R.prop('leadToIncident')),
        R.descend(R.prop('hasTrailing')),
        R.descend(R.prop('isNewAlert')),
        R.descend(R.prop('isRare')),
        R.descend(R.prop('isHot')),
        R.descend(R.prop('eventsCount')),
        R.ascend(R.prop('patternId')),
      ],
      eventList,
    );
    const rankMap = {};
    const labelKeyMap = {};
    let rank = 1;
    eventList = R.addIndex(R.map)((event, index) => {
      if (!R.has(event.labelKey, labelKeyMap)) {
        labelKeyMap[event.labelKey] = rank;
        rank += 1;
      }
      R.forEach((item) => {
        rankMap[item.id] = labelKeyMap[event.labelKey];
      }, event.timelines);
      return { ...event, rank: labelKeyMap[event.labelKey] };
    }, eventList);

    // default display alerts
    const alerts = [];
    eventList = R.addIndex(R.map)((event, index) => {
      const { isDeployment, timelines } = event;
      if (isDeployment || index < this.defaultDisplayAlertSize) {
        R.forEach((item) => {
          alerts.push(item);
        }, timelines || []);
        return { ...event, isDisplayInChart: true };
      }
      return { ...event, isDisplayInChart: false };
    }, eventList);
    this.props.handleUpdateSystemRankMap(systemInfo.id, { rankMap, alerts });

    // sort
    eventList = this.sortData(eventList, sortBy, sortDirection);

    // get new incident
    let newActiveIncident;
    if (activeEvent) {
      newActiveIncident = R.find((item) => item.id === activeEvent.id, eventList);
    }

    if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.setState(
      {
        isLoading: false,
        eventList,
        activeEvent: newActiveIncident,
      },
      () => {
        this.onChangeAlert(filterPlainOption);
      },
    );
    this.props.updateState({ isLoadingPanelAlert: false });
  }

  @autobind
  filterData(eventList) {
    const { ignoreFilter } = this.state;
    let filterList = eventList || [];

    if (ignoreFilter) {
      filterList = R.filter((event) => !event.isIgnored, filterList);
    }
    return filterList;
  }

  @autobind
  mergeAnomalyEvents(props, eventList) {
    let newEvents = [];

    const eventsMap = {};
    R.forEach((event) => {
      const { projectName, componentNameString, patternId, isImportant, isIgnored, isNewAlert, rootCauseResultInfo } =
        event;
      const { isTrace, traceId } = event;
      const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');
      const hasTrailing = get(rootCauseResultInfo, 'hasTrailingEvent');
      const leadToIncident = get(rootCauseResultInfo, 'leadToIncident');
      const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');

      const key = `${projectName}-${componentNameString}-${isTrace ? traceId : patternId}`;
      const newEvent = { ...event, key, labelKey: key, hasRootCause, hasTrailing, leadToIncident, causedByChange };
      if (!R.has(key, eventsMap)) {
        eventsMap[key] = {
          ...newEvent,

          timelines: [newEvent],
        };
      } else {
        eventsMap[key] = {
          ...eventsMap[key],
          isImportant: eventsMap[key].isImportant || isImportant,
          isIgnored: eventsMap[key].isIgnored || isIgnored,
          hasRootCause: eventsMap[key].hasRootCause || hasRootCause,
          hasTrailing: eventsMap[key].hasTrailing || hasTrailing,
          leadToIncident: eventsMap[key].leadToIncident || leadToIncident,
          causedByChange: eventsMap[key].causedByChange || causedByChange,
          isNewAlert: eventsMap[key].isNewAlert || isNewAlert,

          timelines: [...eventsMap[key].timelines, newEvent],
        };
      }
    }, eventList || []);
    newEvents = R.values(eventsMap);

    // get overall info
    newEvents = R.map((event) => {
      let lastSeenTime = 0;
      R.forEach((item) => {
        const { endTimestamp } = item;
        lastSeenTime = R.max(lastSeenTime, endTimestamp);
      }, event.timelines || []);

      // get totle count from api: insightsIncidentPatternCount
      const eventsCount = get(this.patternCountMap, event.key);

      return { ...event, eventsCount: eventsCount || event.timelines.length, lastSeenTime };
    }, newEvents);

    return newEvents;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [
      R.descend(R.prop('leadToIncident')),
      R.descend(R.prop('hasTrailing')),
      R.descend(R.prop('isNewAlert')),
      R.descend(R.prop('isRare')),
      R.descend(R.prop('isHot')),
      R.descend(R.prop('eventsCount')),
      R.ascend(R.prop('patternId')),
    ];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  onChangeFilterIgnore(ignoreFilter) {
    this.setState({ ignoreFilter }, async () => {
      await this.props.getReloadDataTotalCount();
      await this.reloadData(this.props);
    });
  }

  @autobind
  handleAlertDisplayClick(event, rowIndex, item) {
    const { systemInfo } = this.props;
    const { filterEventList } = this.state;
    const { labelKey, timelines } = item;

    const isDisplayInChart = event.target.checked;
    filterEventList[rowIndex].isDisplayInChart = isDisplayInChart;

    this.setState({ eventList: [...filterEventList] }, () => {
      this.props.handleUpdateSystemTimelines(systemInfo.id, {
        hideAlertsLabelKey: !isDisplayInChart ? labelKey : undefined,
        addAlerts: isDisplayInChart ? timelines : undefined,
      });
    });
  }

  @autobind mergeTimelines(timelines) {
    const { startTime, endTime, zoomEndTime, zoomStartTime } = this.props;
    return timelines;
  }

  @autobind
  handleAlertDisplayAllClick(event) {
    const { systemInfo } = this.props;
    const { filterEventList } = this.state;
    const isDisplayInChart = event.target.checked;
    R.forEach((event) => {
      event.isDisplayInChart = isDisplayInChart;
    }, filterEventList);

    this.setState({ eventList: [...filterEventList] }, () => {
      let allAlerts = [];
      if (isDisplayInChart) {
        R.forEach((event) => {
          allAlerts = [...allAlerts, ...event.timelines];
        }, filterEventList);
      }
      allAlerts = this.mergeTimelines(allAlerts);
      this.props.handleUpdateSystemTimelines(systemInfo.id, { allAlerts });
    });
  }

  @autobind
  renderListItem({ key, index: rowIndex, style, parent }, { absoluteWidth }) {
    const { intl } = this.props;
    const { filterEventList } = this.state;
    const item = filterEventList[rowIndex];
    if (!item) return null;

    return (
      <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
        <div
          className={`event-list-row clickable ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
          style={{ ...style }}
          onClick={() => this.handleDetailsClick(item)}
        >
          <div className="row-column flex-row flex-center-justify" style={{ minWidth: 50, width: 50 }}>
            {!item.isDeployment && <AlertEventIcon style={{ color: 'red', fontSize: 20 }} />}
            {item.isDeployment && <ChangeEventIcon style={{ color: 'orange', fontSize: 20 }} />}
            <span className="text-center bold" style={{ width: 20, marginLeft: -20, color: 'white' }}>
              {item.rank}
            </span>
          </div>
          <div className="row-column" style={{ minWidth: 80, flex: 1 }}>
            <Popover
              title={null}
              content={
                <div className="flex-col overflow-y-auto" style={{ maxWidth: 450, maxHeight: 180 }}>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.projectName)}:
                    </div>
                    <div className="flex-grow">{item.projectDisplayName}</div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.componentName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.componentNameString}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.instanceName)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {item.instanceNameString}
                    </div>
                  </div>
                  {item.containerName && (
                    <div className="flex-row">
                      <div style={{ fontWeight: 'bold', width: 120 }}>
                        {intl.formatMessage(appFieldsMessages.container)}:
                      </div>
                      <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                        {item.containerName}
                      </div>
                    </div>
                  )}
                  <div className="flex-row">
                    <div style={{ fontWeight: 'bold', width: 120 }}>
                      {intl.formatMessage(eventMessages.lastSeenTime)}:
                    </div>
                    <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                      {moment.utc(item.lastSeenTime).format(Defaults.ShortTimeFormat)}
                    </div>
                  </div>
                </div>
              }
              placement="right"
              mouseEnterDelay={0.3}
            >
              <div className="max-width hidden-line-with-ellipsis">{item.componentNameString}</div>
            </Popover>
          </div>
          <div className="row-column" style={{ width: 60, flex: 1 }}>
            {item.isTrace ? this.renderTrace(item) : this.renderPattern(item)}
          </div>
          <div className="row-column" style={{ width: 60 }}>
            {item.eventsCount}
          </div>
          {absoluteWidth >= 400 && (
            <div className="row-column" style={{ minWidth: 80, flex: 1 }}>
              {moment.utc(item.lastSeenTime).format(Defaults.ShortTimeFormat)}
            </div>
          )}
          <div className="row-column" style={{ width: 40, minWidth: 40, padding: 0 }}>
            {this.statusRender(item)}
          </div>
          <div className="row-column" style={{ minWidth: 30, width: 30 }}>
            <Popover
              placement="right"
              content={
                item.isDisplayInChart
                  ? `Click to hide this ${item.isDeployment ? 'change event' : 'alert'} in health chart.`
                  : `Click to display this ${item.isDeployment ? 'change event' : 'alert'} in health chart.`
              }
              mouseEnterDelay={0.3}
            >
              <Checkbox
                size="small"
                checked={item.isDisplayInChart}
                onClick={(e) => e.stopPropagation()}
                onChange={(event) => this.handleAlertDisplayClick(event, rowIndex, item)}
              />
            </Popover>
          </div>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { intl, credentials, systemInfo } = this.props;
    const ownerUserName = get(systemInfo, 'ownerUserName');

    const { projectDisplayName, componentNameString, instanceNameString, containerName, lastSeenTime } = rowData;
    const { category, patternId, anomalyLogInstance, projectOwner, startTimestamp } = rowData;
    let { projectName } = rowData;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const key = `${projectName}-${category === 'log' ? anomalyLogInstance : null}`;

    const RendererPatternTitle = ({ rowData }) => {
      const { patternNameMap, summarySettingsMap } = this.state;

      const [patternName, setPatternName] = useState(get(patternNameMap, [key, patternId]));
      const [loading, setLoading] = useState(false);
      useEffect(() => {
        if (patternName === undefined) {
          setLoading(true);
          this.props.updateLastActionInfo();
          if (!R.includes(category, ['metric'])) {
            fetchGet(getEndpoint('logsummarysettings'), {
              ...credentials,
              projectName,
            })
              .then((summarySettings) => {
                if (!R.has(projectName, summarySettingsMap)) {
                  summarySettingsMap[projectName] = summarySettings;
                  this.setState({ summarySettingsMap });
                  this.forceUpdate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          fetchGet(getEndpoint('getpatternname', 1), {
            ...credentials,
            customerName: ownerUserName,
            projectName,
            instanceName: category === 'log' ? anomalyLogInstance : null,
            patternId,
            startTime: startTimestamp,
          })
            .then((data) => {
              const { patternName } = data;
              if (!patternNameMap[key]) patternNameMap[key] = {};
              patternNameMap[key][patternId] = patternName || '';

              this.setState({ patternNameMap });
              setLoading(false);
              setPatternName(patternName || '');
              this.forceUpdate();
            })
            .catch((err) => {
              message.error(intl.formatMessage(appMessages.apiFaild));
              console.log(err);
            });
        }
      }, []);
      const { patternNameStr } = Defaults.PatternIdNameStr(
        { patternName, patternId },
        { hasFullName: true, hasPrefix: true },
      );
      return (
        <div>
          {loading && <Spin size="small" />}
          {!loading && (
            <div className="flex-col overflow-y-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
              <div className="flex-row font-14">
                <div style={{ fontWeight: 'bold', width: 120 }}>
                  {intl.formatMessage(appFieldsMessages.patternIdName)}:
                </div>
                <div className="flex-grow">{patternNameStr}</div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(appFieldsMessages.type)}:</div>
                <div className="flex-grow flex-row">
                  {CellRenderers.logTypeRenderer({ intl, rowData, isHorizontal: true })}
                </div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.projectName)}:</div>
                <div className="flex-grow">{projectDisplayName}</div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.componentName)}:</div>
                <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                  {componentNameString}
                </div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.instanceName)}:</div>
                <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                  {instanceNameString}
                </div>
              </div>
              {containerName && (
                <div className="flex-row">
                  <div style={{ fontWeight: 'bold', width: 120 }}>
                    {intl.formatMessage(appFieldsMessages.container)}:
                  </div>
                  <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                    {containerName}
                  </div>
                </div>
              )}
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', width: 120 }}>{intl.formatMessage(eventMessages.lastSeenTime)}:</div>
                <div className="flex-grow" style={{ wordBreak: 'break-all' }}>
                  {moment.utc(lastSeenTime).format(Defaults.ShortTimeFormat)}
                </div>
              </div>
              <div className="flex-row">
                <div style={{ fontWeight: 'bold', minWidth: 120 }}>
                  {intl.formatMessage(eventMessages.shortDescription)}:
                </div>
                <div className="flex-grow">{this.renderDetails(rowData, projectName)}</div>
              </div>
            </div>
          )}
        </div>
      );
    };

    return (
      <Popover
        title={null}
        content={<RendererPatternTitle rowData={rowData} />}
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="hidden-line-with-ellipsis max-width">{patternId}</div>
      </Popover>
    );
  }

  @autobind
  renderTrace(rowData) {
    // const { intl, userInfo, projects } = this.props;
    const { traceId, timelines } = rowData;
    const traceList = R.map((item) => item.traceInfo, timelines || []);

    // const spanDetails = {};
    // R.forEach((item) => {
    //   const { id } = item.traceInfo || {};
    //   if (!R.has(id, spanDetails)) {
    //     spanDetails[id] = (
    //       <EventRenderers.RenderTraceDetails intl={intl} userInfo={userInfo} projects={projects} incident={item} />
    //     );
    //   }
    // }, timelines);

    return (
      <Popover
        title={null}
        content={
          <div className="overflow-y-auto overflow-x-auto" style={{ maxWidth: 480, maxHeight: 350 }}>
            <div className="flex-col" style={{ width: 650 }}>
              <div className="trace-timelines">
                <ThundraTraceChart
                  traceId={traceId}
                  traceSummary={traceList}
                  // spanDetails={spanDetails}
                  showHeader={false}
                  showMiniTrace={false}
                  showSpanDetailTitle={false}
                  showSpanDetail={false}
                />
              </div>
              <div className="flex-col">
                {R.addIndex(R.map)((item, index) => {
                  const { outlierValue, traceInfo } = item || {};
                  const { serviceName, name } = traceInfo;
                  return (
                    <div key={index} className="flex-col" style={{ marginBottom: 8 }}>
                      <div className="flex-row" style={{ wordBreak: 'break-word' }}>
                        <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
                          Service Name:
                        </span>
                        <span style={{ marginLeft: 8 }}>{serviceName}</span>
                      </div>
                      <div className="flex-row" style={{ wordBreak: 'break-word' }}>
                        <span className="light-label bold" style={{ whiteSpace: 'nowrap' }}>
                          Span Info:
                        </span>
                        <span style={{ marginLeft: 8 }}>{name}</span>
                      </div>
                      <LogRenderers.RenderOutlierValue
                        className="flex-row"
                        style={{ wordBreak: 'break-word' }}
                        outlierValue={outlierValue}
                        isTrace
                      />
                    </div>
                  );
                }, timelines)}
              </div>
            </div>
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="hidden-line-with-ellipsis max-width">{traceId}</div>
      </Popover>
    );
  }

  @autobind
  statusRender(rowData) {
    const { intl, credentials, updateLastActionInfo } = this.props;
    const { id, leadToIncident, hasTrailing, causedByChange, hasRootCause, isIgnored, isNewAlert, category, typeList } =
      rowData;

    let statusFlag;
    if (leadToIncident) {
      statusFlag = (
        <Popover
          placement="top"
          content={
            <RenderLeadToIncident
              intl={intl}
              credentials={credentials}
              incident={rowData}
              leadIncidentPatternNameStr={this.leadIncidentPatternNameStrMap[id]}
              setIncidentPatternNameStr={(leadIncidentPatternNameStr) => {
                this.leadIncidentPatternNameStrMap[id] = leadIncidentPatternNameStr;
              }}
              updateLastActionInfo={updateLastActionInfo}
            />
          }
          mouseEnterDelay={0.3}
        >
          <ExclamationCircleOutlined
            style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
            onClick={(event) => {
              event.stopPropagation();
              this.handleDetailsClick(rowData);
            }}
          />
        </Popover>
      );
    } else if (hasTrailing) {
      statusFlag = (
        <Popover
          content={
            <div>
              <div>{intl.formatMessage(DashboardMessages.incidentHasTrailing)}</div>
              <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="top"
        >
          <IncidentPredictIcon
            style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
            onClick={(event) => {
              event.stopPropagation();
              this.handleAlertJumpClick(rowData, 'hasTrailing');
            }}
          />
        </Popover>
      );
    } else if (causedByChange) {
      statusFlag = (
        <Popover
          content={
            <div>
              <div>{intl.formatMessage(DashboardMessages.causedByChangeAlert)}</div>
              <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="top"
        >
          <ChangeEventIcon
            style={{ color: 'orange', fontSize: 14, margin: '0 4px 2px 0' }}
            onClick={(event) => {
              event.stopPropagation();
              this.handleAlertJumpClick(rowData, 'causedByChange');
            }}
          />
        </Popover>
      );
    } else if (hasRootCause) {
      statusFlag = (
        <Popover
          content={
            <div>
              <div>{intl.formatMessage(DashboardMessages.incidentHasRootCause)}</div>
              <div>{intl.formatMessage(DashboardMessages.clickForDetails)}</div>
            </div>
          }
          mouseEnterDelay={0.3}
          placement="top"
        >
          <RootCauseIcon
            style={{ color: '#FF5142', fontSize: 14, margin: '0 4px 2px 0' }}
            onClick={(event) => {
              event.stopPropagation();
              this.handleAlertJumpClick(rowData, 'hasRootCause');
            }}
          />
        </Popover>
      );
    }

    return (
      <div className="flex-row flex-wrap full-width">
        {statusFlag}
        {isIgnored && (
          <Popover content={intl.formatMessage(eventMessages.ignored)} mouseEnterDelay={0.3} placement="top">
            <EyeInvisibleOutlined style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }} />
          </Popover>
        )}
        {isNewAlert && (
          <Popover placement="top" mouseEnterDelay={0.3} content={intl.formatMessage(eventMessages.newAlert)}>
            <FlagNewIcon style={{ fontSize: 14, margin: '0 4px 2px 0', color: 'red' }} />
          </Popover>
        )}
        {category === 'log' &&
          R.map((type) => {
            if (type === 'whitelist') {
              type = 'logalert';
            }
            return CellRenderers.logShortTypeRenderer({ intl, type });
          }, typeList)}
        {category === 'metric' && CellRenderers.logShortTypeRenderer({ intl, type: 'metric' })}
      </div>
    );
  }

  @autobind
  handleAlertJumpClick(event, fieldName) {
    const { systemInfo, environmentId, customerName } = this.props;
    const { timelines } = event || {};
    const incident = R.find((item) => Boolean(get(item, fieldName)), timelines || []) || event;
    const {
      isIncident,
      isTrace,
      category,
      type,
      day,
      startTimestamp,
      projectName,
      patternId,
      instanceList,
      metricList,
    } = incident;
    const startTime = day || moment.utc(startTimestamp).format(Defaults.DateFormat);

    const query = {
      environmentId,
      customerName,
      systemId: systemInfo.id,
      startTime,
      endTime: startTime,

      eventCategory: isIncident ? 'incident' : category === 'metric' ? 'metric' : isTrace ? 'trace' : 'log',
      eventPatternType: isIncident ? 'incident' : category === 'metric' ? 'metric' : type,
      eventProjectName: projectName,
      eventPatternId: patternId,
      eventInstanceName: instanceList[0],
      eventRootCauseMetric: metricList.length > 0 ? metricList[0] : undefined,
      eventTimestamp: startTimestamp,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, query), '_blank');
  }

  @autobind
  renderDetails(rowData, projectName) {
    const { intl, currentTheme } = this.props;
    const { rawData, rawDataJson, anomalyWords, outlierValue, rootCauseJson } = rowData;
    const rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);
    const { summarySettingsMap } = this.state;
    return (
      <div>
        {anomalyWords && anomalyWords.length > 0 && (
          <LogRenderers.RenderAnomalyWords
            style={{ whiteSpace: 'normal', wordBreak: 'break-all', marginBottom: 12 }}
            anomalyWordList={anomalyWords}
          />
        )}
        {outlierValue && !R.isEmpty(outlierValue) && (
          <LogRenderers.RenderOutlierValue
            style={{ whiteSpace: 'normal', wordBreak: 'break-all', marginBottom: 12 }}
            outlierValue={outlierValue}
          />
        )}
        {rawData && (
          <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
            {!rawDataJson &&
              R.join(
                '\n',
                R.filter((x) => Boolean(x), (rawData || '').split('\n')),
              )}
            {/* {rawDataJson && <LogRenderers.JsonTree data={rawDataJson} />} */}
            {rawDataJson && (
              <div className="flex-grow flex-min-width">
                <LogRenderers.RenderLogContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  owner={rowData}
                  summarySettings={summarySettingsMap[projectName] || []}
                  currentTheme={currentTheme}
                />
              </div>
            )}
          </div>
        )}
        {rootCauseDetailsArr.length > 0 && (
          <div style={{ wordBreak: 'break-all' }}>
            {R.addIndex(R.map)(
              (event, index) => EventRenderers.RenderMetricAnomalySummary({ intl, event, index }),
              rootCauseDetailsArr || [],
            )}
          </div>
        )}
      </div>
    );
  }

  @autobind
  handleDetailsClick(rowData) {
    const { userInfo, projects } = this.props;

    // get falg isTrace from event's project
    const { projectOwner } = rowData;
    let { projectName } = rowData;
    projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
    const project = R.find((project) => {
      return projectName === project.projectName;
    }, projects || []);
    const { isTrace } = project || {};
    if (isTrace) {
      this.setState({ showTraceDetailsModal: true, activeEvent: rowData });
    } else {
      this.setState({ showDetailsModal: true, activeEvent: rowData });
    }
  }

  @autobind
  handleDetailsModalClose(reload, notCloseModal) {
    this.setState({ showDetailsModal: Boolean(notCloseModal) }, () => {
      if (reload) {
        this.reloadData(this.props);
      }
    });
  }

  @autobind
  handleTraceDetailsModalClose(reload, notCloseModal) {
    this.setState({ showTraceDetailsModal: Boolean(notCloseModal) }, () => {
      if (reload) {
        this.reloadData(this.props);
      }
    });
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection, eventList, filterPlainOption } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const newEventList = this.sortData(eventList, name, sortDir);
          this.setState({ eventList: newEventList }, () => {
            this.onChangeAlert(filterPlainOption);
          });
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  onChangeAlert(value) {
    const { systemInfo } = this.props;
    const { eventList } = this.state;
    let newEventList;
    if (value.length === 3) {
      // 全部
      newEventList = eventList;
    } else {
      // 不是全部
      const hasLog = R.includes('Log alerts', value);
      const hasMetric = R.includes('Metric alerts', value);
      const hasChange = R.includes('Change events', value);

      newEventList = R.filter((item) => {
        let matched = false;

        if (hasLog) {
          const metricFlag =
            item.category === 'log'
              ? !R.includes('metricalert', item.typeList)
              : !R.includes('metricanomaly', item.typeList);
          matched = metricFlag && !R.includes('deployment', item.typeList);
        }
        if (matched) return true;

        if (hasMetric) {
          matched =
            item.category === 'log'
              ? R.includes('metricalert', item.typeList)
              : R.includes('metricanomaly', item.typeList);
        }
        if (matched) return true;

        if (hasChange) {
          matched = R.includes('deployment', item.typeList);
        }

        return matched;
      }, eventList || []);

      newEventList = this.sortData(newEventList);
    }

    this.setState({ filterPlainOption: value, filterEventList: newEventList }, () => {
      let allAlerts = [];
      R.forEach((event) => {
        if (event.isDisplayInChart) {
          allAlerts = [...allAlerts, ...event.timelines];
        }
      }, newEventList);
      this.props.handleUpdateSystemTimelines(systemInfo.id, { allAlerts });
    });
  }

  render() {
    const { intl, style } = this.props;
    const { systemInfo, startTime, endTime, isLoading: isLoadingSystemChart } = this.props;
    const { sortBy, sortDirection } = this.state;
    const { isLoading, systemTrending, activeEvent, ignoreFilter, filterPlainOption, filterEventList } = this.state;
    const trendingHouts =
      ((moment.utc(endTime, Defaults.DateFormat).valueOf() - moment.utc(startTime, Defaults.DateFormat).valueOf()) /
        86400000 +
        1) *
      24;

    const isDisplayInChartAll = !R.find((item) => !item.isDisplayInChart, filterEventList);

    return (
      <div className="flex-grow flex-col" style={{ ...style, minWidth: 150, padding: 8 }}>
        <Popover
          content="The increase or decrease in the number of alert/change events compared to the previous day."
          mouseEnterDelay={0.3}
        >
          <div className="flex-row flex-center-align flex-center-justify font-16">
            {systemTrending.trending >= 0 ? (
              <ArrowUpOutlined style={{ color: '#FF5143' }} />
            ) : (
              <ArrowDownOutlined style={{ color: '#32C880' }} />
            )}
            <span className="light-label" style={{ marginRight: 8 }}>{`Trending(${trendingHouts}h)`}</span>
            <span className="bold">{` ${numeral(systemTrending.current).format('0,0')}(${numeral(
              systemTrending.trending,
            ).format('+0,0')})`}</span>
          </div>
        </Popover>
        <div className="flex-row flex-center-align font-14" style={{ height: 24 }}>
          <div className="flex-grow hidden-line-with-ellipsis">
            <AlertEventIcon style={{ color: 'red', marginRight: 4 }} />
            <span style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.alerts)}</span>
            /
            <ChangeEventIcon style={{ color: 'orange', marginLeft: 8, marginRight: 4 }} />
            <span style={{ marginRight: 8 }}>{intl.formatMessage(appFieldsMessages.changes)}</span>
          </div>

          <Popover placement="top" content={intl.formatMessage(logMessages.hideIgnoredEvents)} mouseEnterDelay={0.3}>
            <Switch size="small" checked={ignoreFilter} onChange={this.onChangeFilterIgnore} />
          </Popover>
        </div>

        <div className="flex-row flex-end-justify flex-center-align" style={{ paddingBottom: 3 }}>
          <Checkbox.Group
            options={AlertChangesAllOptions}
            value={filterPlainOption}
            defaultValue={AlertChangesAllOptions}
            onChange={this.onChangeAlert}
            disabled={isLoadingSystemChart || isLoading}
            style={{ height: 16 }}
            className="alerts-changes-checkbox-group flex-row"
          />
        </div>

        <div className="flex-grow">
          <Spin wrapperClassName="full-width full-height spin-full-height" spinning={isLoadingSystemChart || isLoading}>
            <AutoSizer>
              {({ width, height }) => (
                <div className="event-list">
                  <div
                    className="event-list-header"
                    style={{ height: 40, width, paddingRight: this.listNodeHeaderScrollbar ? 17 : 0 }}
                  >
                    <div
                      className="header-column"
                      style={{ minWidth: 50, width: 50 }}
                      onClick={this.headerClick('rank')}
                    >
                      <span>{intl.formatMessage(logMessages.rankNumber)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'rank')}
                    </div>
                    <div
                      className="header-column"
                      style={{ width: 80, flex: 1 }}
                      onClick={this.headerClick('componentNameString')}
                    >
                      <span>{intl.formatMessage(appFieldsMessages.component)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'componentNameString')}
                    </div>
                    <div
                      className="header-column break-word"
                      style={{ width: 60, flex: 1 }}
                      onClick={this.headerClick('patternAndTrace')}
                    >
                      <span>{intl.formatMessage(logMessages.patternAndTrace)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'patternAndTrace')}
                    </div>
                    <div className="header-column" style={{ width: 60 }} onClick={this.headerClick('eventsCount')}>
                      <span>{intl.formatMessage(appFieldsMessages.count)}</span>
                      {this.sortIcon(sortBy, sortDirection, 'eventsCount')}
                    </div>
                    {width >= 400 && (
                      <div
                        className="header-column"
                        style={{ minWidth: 80, flex: 1 }}
                        onClick={this.headerClick('lastSeenTime')}
                      >
                        <span>{intl.formatMessage(eventMessages.lastSeenTime)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'lastSeenTime')}
                      </div>
                    )}
                    <div className="header-column" style={{ width: 40, minWidth: 40, padding: 0 }} />
                    <div
                      className="header-column"
                      style={{ width: 30, minWidth: 30 }}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Checkbox size="small" checked={isDisplayInChartAll} onChange={this.handleAlertDisplayAllClick} />
                    </div>
                  </div>
                  <List
                    className="event-list-grid"
                    ref={(listNode) => {
                      this.listNode = listNode;
                    }}
                    width={width}
                    height={height - 30}
                    rowCount={filterEventList.length}
                    overscanRowCount={4}
                    deferredMeasurementCache={this.cellMeasureCache}
                    rowHeight={this.cellMeasureCache.rowHeight}
                    rowRenderer={(props) => this.renderListItem(props, { absoluteWidth: width })}
                    onScrollbarPresenceChange={({ horizontal, vertical }) => {
                      if (vertical) {
                        this.listNodeHeaderScrollbar = true;
                      } else {
                        this.listNodeHeaderScrollbar = false;
                      }
                      if (this.listNode) this.listNode.forceUpdateGrid();
                    }}
                  />
                </div>
              )}
            </AutoSizer>
          </Spin>
        </div>

        {this.state.showDetailsModal && (
          <IncidentListModal
            title={intl.formatMessage(DashboardMessages.detectedAlerts)}
            tabName="detectedAlerts"
            systemInfo={systemInfo}
            events={get(activeEvent, 'timelines', [])}
            activeEvent={activeEvent}
            // events={events}
            isLoading={isLoading}
            onClose={this.handleDetailsModalClose}
          />
        )}
        {this.state.showTraceDetailsModal && (
          <TraceListModal
            title={`${intl.formatMessage(DashboardMessages.detectedTrace)}: ${activeEvent.traceId}`}
            systemInfo={systemInfo}
            events={get(activeEvent, 'timelines', [])}
            // events={events}
            isLoading={isLoading}
            onClose={this.handleTraceDetailsModalClose}
          />
        )}
      </div>
    );
  }
}

const GlobalPanelAlerts = injectIntl(GlobalPanelAlertsCore);
export default connect(
  (state) => {
    const { loadStatus, projects, systemsMap, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    const { globalSystemAlertTimelines } = state.dashboard;
    return {
      loadStatus,
      projects,
      systemsMap,
      credentials,
      userInfo,

      globalSystemAlertTimelines,
      currentTheme,
    };
  },
  { updateLastActionInfo, createLoadAction },
)(GlobalPanelAlerts);
