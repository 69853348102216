import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getDisplayedMenus = (credentials) => {
  return fetchGet(getEndpoint('userrole'), {
    ...credentials,
  })
    .then((data) => {
      const { possibleMenu } = data || {};
      return possibleMenu;
    })
    .catch((err) => {
      console.error('[IF_API] Failed get userrole info', err);
      return {};
    });
};

export default getDisplayedMenus;
