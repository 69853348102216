/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import moment from 'moment';
import { get, isString, isNumber, isObject, isFinite, round, isArray } from 'lodash';
import { Progress } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ThundraTraceChart from 'thundra-trace-chart';

import getEndpoint from '../apis/getEndpoint';
import { Defaults, parseLocation, BackgroundCall, parseJSON, EventRenderers, CausalParser, LogRenderers } from '.';
import {
  TriangleDownPath,
  FixedPath,
  ActionPath,
  PinPath,
  PinLegendIcon,
  ChangeEventPath,
  ChangeEventIcon,
  AlertEventPath,
  AlertEventIcon,
  RestoreSvgPath,
  AimOutlinedPath,
  GoodPath,
} from '../../lib/fui/icons';
import { colorMap } from '../../web/share';
import { calcColorOfHealthScore } from './calcColorOfAnomalyLevel';

import { appFieldsMessages } from '../app/messages';
import { DashboardMessages } from '../dashboard/messages';
import { eventMessages, eventActionMessages } from '../metric/messages';
import { logMessages } from '../log/messages';
import fetchPost from '../apis/fetchPost';
// import { logMessages } from '../../common/log/messages';
// import { settingsMessages } from '../../common/settings/messages';

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

const GlobalParse = {
  getInstanceGroupShort: (environmentId) => {
    if (!environmentId) return environmentId;
    // "All-MetricReplay" will use 'All'
    const environmentOnly = R.split('(', environmentId)[0];
    return environmentId.indexOf('All') === 0 ? 'All' : environmentId.startsWith('PROD') ? 'All' : environmentOnly;
  },
  getInstanceGroupByEnv: (environmentId) => {
    if (!environmentId) return environmentId;

    // "All-MetricReplay" will use 'All'
    const environmentOnly = R.split('(', environmentId)[0];
    return environmentId.indexOf('All') === 0
      ? 'All'
      : environmentId.startsWith('PROD(')
      ? 'All'
      : `Env:${environmentOnly}`;
  },
  getEnvByInstanceGroup: (instanceGroup) => {
    return instanceGroup === 'All'
      ? `PROD(${instanceGroup})`
      : instanceGroup.indexOf(':') >= 0
      ? R.split(':', instanceGroup)[1]
      : instanceGroup;
  },
  getShortInstanceId: (iid) => {
    const idx = (iid || '').indexOf('_');
    return idx > 0 ? iid.substr(idx + 1) : iid;
  },
  getContainerInstanceId: (iid) => {
    const idx = (iid || '').indexOf('_');
    return idx > 0 ? iid.substr(0, idx) : iid;
  },
  updateProjectInstanceInfo: ({ data, projectName }) => {
    // Find the instance and update the instanceList
    const projectInfo = R.find((p) => p.projectName === projectName, data);
    if (projectInfo) {
      projectInfo.instanceInfoList = R.map((i) => {
        return { value: i, label: i };
      }, projectInfo.instanceList);
    }
    return data;
  },
  updateGlobalView: ({ intl, location, state, credentials, callback = () => {} }) => {
    const {
      selectStartTimestamp,
      selectEndTimestamp,
      globalUpdate,
      updateTotalMax,
      project,
      projectName,
      projectType,
      systemName,
      systemOwner,
      systemIds,
      systemIdsWithShare,
    } = state;

    const query = parseLocation(location);
    const { customerName, environmentId: environmentName } = query;
    const { isStationary, isMetric, owner } = project || {};
    const isSameUser = owner === credentials.userName;
    const startTimeMillis = moment.utc(selectStartTimestamp).startOf('day').valueOf();
    const endTimeMillis = moment.utc(selectEndTimestamp).endOf('day').valueOf();

    let isGet = true;
    let url = '';
    let params = {};
    let data = {};
    switch (globalUpdate) {
      case 'RerunKbPrediction': {
        data = {
          ...credentials,
          startTime: selectStartTimestamp,
          endTime: selectEndTimestamp,
          systemIdsWithShare,
        };
        url = getEndpoint('rerun-knowledgebase-prediction', 2);
        isGet = false;
        break;
      }
      case 'GlobalView': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          updateTotalMax,
          rerunFunction: 'globalview',
          startTimeMillis,
          endTimeMillis,
          systemIdsWithShare,
        };
        break;
      }
      case 'RerunInsightSummary': {
        const startTime = moment.utc(selectEndTimestamp).format(Defaults.DateFormat);
        url = getEndpoint('insights/InsightsRerunCron') || `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          customerName: systemOwner,
          environmentName,
          systemName,
          startTime,
          startTimeMillis,
          endTimeMillis,
        };
        break;
      }
      case 'RerunServiceMap': {
        url = `${window.BASE_URL || ''}/localcron/createservicemap`;
        params = {
          ...credentials,
          customerName: systemOwner,
          environmentName,
          systemName,
          startTime: startTimeMillis,
          endTime: endTimeMillis,
          startTimeMillis,
          endTimeMillis,
        };
        break;
      }
      case 'RerunCompositeIncidentDetection': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          rerunFunction: 'GLOBAL_KB_DETECTION',
          environmentName,
          startTime: startTimeMillis,
          endTime: endTimeMillis,
          systemIdsWithShare,
        };
        break;
      }
      case 'RerunIncidentPrediction': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          rerunFunction: 'incidentPredictiontimeline',
          environmentName,
          startTimeMillis,
          endTimeMillis,
          systemIdsWithShare,
        };
        break;
      }
      case 'RerunRootCauseAnalysis': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          rerunFunction: 'rootCauseAnalysis',
          environmentName,
          startTimeMillis,
          endTimeMillis,
          systemIdsWithShare,
        };
        break;
      }
      case 'RerunKnowledgeBase': {
        url = getEndpoint('updateRuleSource');
        params = {
          ...credentials,
          customerName: systemOwner,
          systemName,
        };
        break;
      }
      case 'RerunHistoricalIncidents': {
        url = getEndpoint('updateHistoricalIncidents');
        params = {
          ...credentials,
          customerName: systemOwner,
          environmentName,
          systemName,
          startTimeMillis,
          endTimeMillis,
        };
        break;
      }

      case 'MetricDrivenPrediction': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          customerName: owner,
          rerunFunction: 'updateevents',
          projectName,
          projectType,
          asofTimestamp: endTimeMillis,
          windowMillis: endTimeMillis - startTimeMillis,
        };
        break;
      }
      case 'Baseline': {
        url = `${window.BASE_URL || ''}/localcron/baseline`;
        params = {
          ...credentials,
          UserName: owner || customerName || credentials.userName,
          projectName,
          startTime: startTimeMillis,
          endTime: endTimeMillis,
        };
        break;
      }
      case 'RerunTraining': {
        if (isArray(projectName)) {
          url = `${window.BASE_URL || ''}/localcron/rerun`;
          params = {
            ...credentials,
            customerName: owner,
            rerunFunction: 'metrictraining',
            projectList: JSON.stringify(
              R.map(
                (item) => ({
                  projectName: item.projectShortName,
                  customerName: item.owner,
                  isStationary: item.isStationary,
                }),
                projectName,
              ),
            ),
            projectType,
            asofTimestamp: endTimeMillis,
            windowMillis: endTimeMillis - startTimeMillis,
            runAllData: false,
            startTime: startTimeMillis,
            endTime: endTimeMillis,
          };
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isMetric) {
            if (isStationary) {
              url = `${window.BASE_URL || ''}/api/v1/metricreplay`;
              params = {
                ...credentials,
                projectName,
                customerName: owner,
                projectType,
                startTime: startTimeMillis,
                endTime: endTimeMillis,
                doTraining: true,
                doDetection: false,
                doNormalStats: false,
                doGV: false,
                runAllData: false,
              };
            } else {
              url = `${window.BASE_URL || ''}/localcron/rerun`;
              params = {
                ...credentials,
                customerName: owner,
                rerunFunction: 'metrictraining',
                projectName,
                projectType,
                asofTimestamp: endTimeMillis,
                windowMillis: endTimeMillis - startTimeMillis,
                runAllData: false,
                startTime: startTimeMillis,
                endTime: endTimeMillis,
              };
            }
          } else {
            url = `${window.BASE_URL || ''}/localcron/cronlogtrainmaster`;
            params = {
              ...credentials,
              projectName,
              userName: owner,
            };
          }
        }

        break;
      }
      case 'RerunMetricPrediction': {
        if (isArray(projectName)) {
          url = `${window.BASE_URL || ''}/localcron/rerun`;
          params = {
            ...credentials,
            customerName: owner,
            rerunFunction: 'metricprediction',
            projectList: JSON.stringify(
              R.map(
                (item) => ({
                  projectName: item.projectShortName,
                  customerName: item.owner,
                  isStationary: item.isStationary,
                }),
                projectName,
              ),
            ),
            projectType,
            asofTimestamp: endTimeMillis,
            windowMillis: endTimeMillis - startTimeMillis,
            modelTypeNew: 'Holistic',
            runAllData: false,
            startTime: startTimeMillis,
            endTime: endTimeMillis,
          };
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isMetric) {
            url = `${window.BASE_URL || ''}/localcron/rerun`;
            params = {
              ...credentials,
              customerName: owner,
              rerunFunction: 'metricprediction',
              projectName,
              projectType,
              asofTimestamp: endTimeMillis,
              windowMillis: endTimeMillis - startTimeMillis,
              modelTypeNew: 'Holistic',
              runAllData: false,
              startTime: startTimeMillis,
              endTime: endTimeMillis,
            };
          }
        }

        break;
      }
      case 'RerunDetection': {
        if (isArray(projectName)) {
          url = `${window.BASE_URL || ''}/localcron/rerun`;
          params = {
            ...credentials,
            customerName: owner,
            rerunFunction: 'metricdetection',
            projectList: JSON.stringify(
              R.map(
                (item) => ({
                  projectName: item.projectShortName,
                  customerName: item.owner,
                  isStationary: item.isStationary,
                }),
                projectName,
              ),
            ),
            projectType,
            asofTimestamp: endTimeMillis,
            windowMillis: endTimeMillis - startTimeMillis,
            modelTypeNew: 'Holistic',
            runAllData: false,
            startTime: startTimeMillis,
            endTime: endTimeMillis,
          };
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isMetric) {
            if (isStationary) {
              url = `${window.BASE_URL || ''}/api/v1/metricreplay`;
              params = {
                ...credentials,
                projectName,
                customerName: owner,
                projectType,
                startTime: startTimeMillis,
                endTime: endTimeMillis,
                doTraining: false,
                doDetection: true,
                doNormalStats: true,
                doGV: true,
                runAllData: false,
              };
            } else {
              url = `${window.BASE_URL || ''}/localcron/rerun`;
              params = {
                ...credentials,
                customerName: owner,
                rerunFunction: 'metricdetection',
                projectName,
                projectType,
                asofTimestamp: endTimeMillis,
                windowMillis: endTimeMillis - startTimeMillis,
                modelTypeNew: 'Holistic',
                runAllData: false,
                startTime: startTimeMillis,
                endTime: endTimeMillis,
              };
            }
          } else {
            url = `${window.BASE_URL || ''}/localcron/logdetect`;
            params = {
              ...credentials,
              projectName,
              userName: owner,
              projectType,
            };
          }
        }

        break;
      }
      case 'RerunCapacityPlanning': {
        if (isArray(projectName)) {
          url = `${window.BASE_URL || ''}/localcron/rerun`;
          params = {
            ...credentials,
            customerName: owner,
            rerunFunction: 'metricProvision',
            projectList: JSON.stringify(
              R.map(
                (item) => ({
                  projectName: item.projectShortName,
                  customerName: item.owner,
                  isStationary: item.isStationary,
                }),
                projectName,
              ),
            ),
            projectType,
            asofTimestamp: endTimeMillis,
            windowMillis: endTimeMillis - startTimeMillis,
            modelTypeNew: 'Holistic',
            runAllData: false,
            startTime: startTimeMillis,
            endTime: endTimeMillis,
          };
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isMetric) {
            if (isStationary) {
              url = `${window.BASE_URL || ''}/api/v1/metricreplay`;
              params = {
                ...credentials,
                projectName,
                customerName: owner,
                projectType,
                startTime: startTimeMillis,
                endTime: endTimeMillis,
                doTraining: false,
                doDetection: true,
                doNormalStats: true,
                doGV: true,
                runAllData: false,
              };
            } else {
              url = `${window.BASE_URL || ''}/localcron/rerun`;
              params = {
                ...credentials,
                customerName: owner,
                rerunFunction: 'metricProvision',
                projectName,
                projectType,
                asofTimestamp: endTimeMillis,
                windowMillis: endTimeMillis - startTimeMillis,
                modelTypeNew: 'Holistic',
                runAllData: false,
                startTime: startTimeMillis,
                endTime: endTimeMillis,
              };
            }
          } else {
            url = `${window.BASE_URL || ''}/localcron/logdetect`;
            params = {
              ...credentials,
              projectName,
              userName: owner,
              projectType,
            };
          }
        }

        break;
      }
      case 'RerunMetricInstanceMetadata': {
        url = `${window.BASE_URL || ''}/localcron/updatemetricsettingandinstancemetadata`;
        params = {
          ...credentials,
          UserName: owner || customerName || credentials.userName,
          projectList: JSON.stringify(
            R.map((item) => ({ projectName: item.projectShortName, customerName: item.owner }), projectName),
          ),
          startMillis: startTimeMillis,
          endMillis: endTimeMillis,
        };
        break;
      }
      case 'RerunInstanceDownIncident': {
        url = `${window.BASE_URL || ''}/localcron/eventsupport`;
        params = {
          ...credentials,
          UserName: owner || customerName || credentials.userName,
          projectName,
          startTime: startTimeMillis,
          endTime: endTimeMillis,
        };
        break;
      }
      case 'RerunMetricCollect': {
        url = `${window.BASE_URL || ''}/localcron/collect`;
        params = {
          UserName: owner || customerName || credentials.userName,
          projectName,
          startTimeMillis,
          endTimeMillis,
          fromCronFlag: false,
        };
        break;
      }
      case 'RerunMetricProjectInfo': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        const projectList = R.map(
          (project) => ({ projectName: project.projectShortName, customerName: project.owner, isStationary: false }),
          projectName,
        );
        params = {
          rerunFunction: 'updateProjectInfo',
          projectList: JSON.stringify(projectList),
          startTime: startTimeMillis,
          endTime: endTimeMillis,
        };
        break;
      }
      case 'MetricProjectStatistics': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        const projectList = R.map(
          (project) => ({ projectName: project.projectShortName, customerName: project.owner, isStationary: false }),
          projectName,
        );
        params = {
          rerunFunction: 'metricOverviewStatus',
          projectList: JSON.stringify(projectList),
          startTime: startTimeMillis,
          endTime: endTimeMillis,
        };
        break;
      }
      case 'NormalBehaviorStatistics': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          customerName: owner,
          rerunFunction: 'appbehaviorstats',
          projectName,
          projectType,
          startTimeMillis,
          endTimeMillis,
        };
        break;
      }
      case 'AppForecast': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          customerName: owner,
          rerunFunction: 'appforecast',
          projectName,
          projectType,
          startTimeMillis,
          endTimeMillis,
        };
        break;
      }
      case 'KPIPrediction': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          projectName,
          customerName: owner || customerName || credentials.userName,
          rerunFunction: 'kpiPrediction',
          environmentName,
          startTimeMillis,
          endTimeMillis,
        };
        break;
      }

      case 'LogDetection': {
        url = getEndpoint('logrerunanomaly');
        data = {
          ...credentials,
          projectName: !isSameUser ? `${projectName}@${owner}` : projectName,
          type: 'LOG_RERUN',
          startTime: startTimeMillis,
          endTime: endTimeMillis,
        };
        isGet = false;
        break;
      }
      case 'LogIncident': {
        url = getEndpoint('logrerunanomaly');
        data = {
          ...credentials,
          projectName: !isSameUser ? `${projectName}@${owner}` : projectName,
          type: 'LOG_RERUN_INCIDENT',
          startTime: startTimeMillis,
          endTime: endTimeMillis,
        };
        isGet = false;
        break;
      }
      case 'LogDrivenPrediction': {
        url = getEndpoint('logRerunPrediction');
        params = {
          ...credentials,
          projectName: !isSameUser ? `${projectName}@${owner}` : projectName,
          startTime: startTimeMillis,
          endTime: endTimeMillis,
        };
        break;
      }
      case 'LogCommonPattern': {
        url = `${window.BASE_URL || ''}/localcron/rerun`;
        params = {
          ...credentials,
          projectName,
          customerName: owner || customerName || credentials.userName,
          rerunFunction: 'logCommonPattern',
        };
        break;
      }
      case 'LogHistoricalDataCollect': {
        url = getEndpoint('logcollecthistoricaldata');
        params = {
          UserName: owner || customerName || credentials.userName,
          projectName: project.projectName,
          startTime: selectStartTimestamp,
          endTime: selectEndTimestamp,
        };
        break;
      }
      case 'LogDataDeletion': {
        url = getEndpoint('logdeletebyrange');
        params = {
          projectName: project.projectName,
          customerName: project.owner,
          startTime: selectStartTimestamp,
          endTime: selectEndTimestamp,
        };
        break;
      }

      case 'DeployBuggyRelease': {
        url = `${window.BASE_URL || ''}/api/v1/deploymentInjection`;
        params = {
          ...credentials,
          customerName: owner || customerName || credentials.userName,
        };
        break;
      }
      case 'RerunHnowledgeBaseTraining': {
        url = `${window.BASE_URL || ''}/localcron/knowledgebase-training`;

        params = {
          ...credentials,
          systemIdsWithShare,
          startTime: startTimeMillis,
          endTime: endTimeMillis,
          rerunPredictionAndTimeline: false,
        };
        break;
      }
      case 'RerunSystemLevelIncidentPrediction': {
        url = `${window.BASE_URL || ''}/localcron/long-term-incidentpredictiontimeline`;
        params = {
          ...credentials,
          systemIdsWithShare,
          startTime: startTimeMillis,
          endTime: endTimeMillis,
          rerunPredictionAndTimeline: true,
        };
        break;
      }
      default:
    }

    if (isGet) {
      BackgroundCall.CallUrlJob({
        intl,
        method: 'GET',
        url,
        params,
        isJsonResponse: false,
        apiType: 'submit',
        callback,
      });
    } else {
      BackgroundCall.CallUrlJob({
        intl,
        method: 'POST',
        url,
        params,
        data,
        isJsonResponse: true,
        apiType: 'submit',
        callback,
      });
    }
  },
  getHealthChartOption: ({
    intl,
    credentials,
    currentLocale,
    defaultTimezone,
    isAdmin,
    userName,
    systemHealth,
    nowTimestamp,
    isCurrentDay,
    intervalInMinutes,
    anomalyScoresList,
    showHistoricalPredictions,
    showDetectedIncidents,
    healthScore,
    selectAnomalyInstance,
    selectInstanceIncidentsTimes,
    selectInstancePredictionIncidentsTimes,
    selectInstanceActionIncidentsTimes,
    selectInstanceFixedIncidentsTimes,
    selectInstanceDeploymentsTimes,
    selectInstanceAlertsTimes,
    summarySettingsMap,
    currentTheme,
  }) => {
    let healthScoresList = anomalyScoresList;
    healthScoresList = R.map(
      (item) => ({ ...item, score: isNumber(item.score) ? R.max(0, item.score) : null, realScore: item.score }),
      healthScoresList,
    );

    // get prediction window area
    let pastStartTime = null;
    let predictionStartP = 0;
    let predictionStartTime = null;
    if (isCurrentDay) {
      R.addIndex(R.forEach)((item, idx) => {
        if (idx === 0) {
          pastStartTime = item.timestamp;
        }
        if (nowTimestamp >= item.timestamp) {
          predictionStartP = idx;
          predictionStartTime = item.timestamp;
        }
        if (idx === 0) {
          pastStartTime = item.timestamp;
        }
      }, healthScoresList);
    } else if (healthScoresList.length > 0) {
      predictionStartP = healthScoresList.length - 1;
      predictionStartTime = healthScoresList[healthScoresList.length - 1].timestamp;
    }
    const predictionStartTimePercent =
      healthScoresList.length > 0
        ? (Number(predictionStartTime) - healthScoresList[0].timestamp) /
          (healthScoresList[healthScoresList.length - 1].timestamp - healthScoresList[0].timestamp)
        : 1;

    const healthScoreReal = healthScore || 100;
    const dataList = R.addIndex(R.map)((item, index) => {
      const isHealth = item.score > healthScoreReal;
      return {
        value: [index, item.score],
        realScore: item.realScore,
        symbol: 'circle',
        symbolSize: !isHealth ? 10 : 1,
        itemStyle: !isHealth ? { color: '#FF8961' } : {},
      };
    }, healthScoresList);

    const incidentTooltip = (incidentType) => ({
      show: true,
      padding: 8,
      trigger: 'item',
      formatter: (params, ticket, callback) => {
        const { name, data } = params;
        const { timelineStartTime, tooltipTimelines, projectName } = data;
        let { predictionTime } = data;
        const time = moment.utc(timelineStartTime || Number(name)).format(Defaults.ShortTimeFormat);
        predictionTime = moment.utc(predictionTime || Number(name)).format(Defaults.ShortTimeFormat);
        return GlobalParse.RenderGHVTooltip({
          intl,
          systemHealth,
          incidentType,
          time,
          tooltipTimelines,
          projectName,
          summarySettingsMap,
          currentTheme,
          predictionTime,
        });
      },
      position: (pos, params, dom, rect, size) => {
        const boxWidth = size.contentSize[0];
        const boxHeight = size.contentSize[1];
        const viewWidth = size.viewSize[0];
        // const viewHeight = size.viewSize[1];
        const rectX = rect.x;
        const rectY = rect.y;
        const rectWidth = rect.width;
        // const rectHeight = rect.height;

        let pointX = rectX + rectWidth / 2 - boxWidth / 2;
        const pointY = rectY - boxHeight - 6;

        if (pointX + boxWidth > viewWidth) {
          pointX = viewWidth - boxWidth;
        }

        return [pointX, pointY];
      },
    });

    // build timelins map info
    const incidentTimelinesMap = {};

    const dataListAction = [];
    const dataListFixedIncident = [];
    const dataListIncident = [];
    const dataListalert = [];
    const dataListDeployment = [];
    const dataListpredictionIncident = [];

    R.addIndex(R.forEach)((anomalyScores, index) => {
      const dataListActionFn = () => {
        const { actionList } = anomalyScores;
        if (actionList.length === 0) {
          return null;
        } else if (selectAnomalyInstance && selectInstanceActionIncidentsTimes.indexOf(anomalyScores.timestamp) < 0) {
          // If select instance and event timestamp dosn't have incident
          return null;
        }
        const timelines = selectAnomalyInstance
          ? R.filter((item) => item.instanceName === selectAnomalyInstance, actionList)
          : actionList;
        incidentTimelinesMap[`actionLine-${anomalyScores.timestamp}`] = timelines;
        let timelineStartTime = null;
        const tooltipTimelines = [];
        R.forEach((timeline) => {
          const { patternId, patternName, triggeredActionList } = timeline;
          R.forEach((item) => {
            if (item.timestamp) {
              timelineStartTime = timelineStartTime ? R.min(timelineStartTime, item.timestamp) : item.timestamp;
            }
          }, triggeredActionList);
          R.forEach((item) => tooltipTimelines.push({ ...item, patternId, patternName }), triggeredActionList);
          return { triggeredActionList };
        }, timelines);
        const object = {
          dataId: 'actionLine',
          value: [index, 100],
          realScore: 100,
          tooltipTimelines,
          timelineStartTime,
          itemStyle: { color: '#037AEF' },
          symbol: `path://${ActionPath}`,
          symbolSize: 14,
          symbolOffset: [0, -28],
          tooltip: incidentTooltip('action'),
        };
        if (object) {
          dataListAction.push(object);
        }
        return null;
      };

      const dataListFixedIncidentFn = () => {
        const { fixedIncidentList } = anomalyScores;
        if (fixedIncidentList.length === 0) {
          return null;
        } else if (selectAnomalyInstance && selectInstanceFixedIncidentsTimes.indexOf(anomalyScores.timestamp) < 0) {
          // If select instance and event timestamp dosn't have incident
          return null;
        }
        const timelines = selectAnomalyInstance
          ? R.filter((item) => item.instanceName === selectAnomalyInstance, fixedIncidentList)
          : fixedIncidentList;
        incidentTimelinesMap[`fixedIncidentLine-${anomalyScores.timestamp}`] = timelines;
        let timelineStartTime = null;
        const tooltipTimelines = R.map((timeline) => {
          const {
            category,
            startTimestamp,
            patternId,
            patternName,
            instanceName,
            componentName,
            rootCauseJson,
            rawData,
          } = timeline;
          if (startTimestamp) {
            timelineStartTime = timelineStartTime ? R.min(timelineStartTime, startTimestamp) : startTimestamp;
          }
          return {
            category,
            patternId,
            patternName,
            instanceName,
            componentName,
            cloneRawData: rawData,
            rawData: rawData && rawData.length > 60 ? `${R.take(60, rawData)}...` : rawData,
            ...(rootCauseJson && rootCauseJson.rootCauseDetailsArr && rootCauseJson.rootCauseDetailsArr.length > 0
              ? { rootCauseJson }
              : {}),
          };
        }, timelines);

        const { projectOwner } = timelines[0] || {};
        let { projectName } = timelines[0] || {};
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;

        const object = {
          dataId: 'fixedIncidentLine',
          value: [index, 100],
          realScore: 100,
          tooltipTimelines,
          timelineStartTime,
          itemStyle: { color: '#ff5142' },
          symbol: `path://${FixedPath}`,
          symbolSize: 14,
          symbolOffset: [16, -28],
          tooltip: incidentTooltip('fixedIncident'),
          projectName,
        };
        if (object) {
          dataListFixedIncident.push(object);
        }
        return null;
      };

      const dataListIncidentFn = () => {
        // const { incidentList } = anomalyScores;
        const incidentList = showDetectedIncidents ? anomalyScores.incidentList : [];
        // const incidentInfo = R.find((item) => item.timestamp === anomalyScores.timestamp, incidentList);
        if (incidentList.length === 0) {
          return null;
        } else if (selectAnomalyInstance && selectInstanceIncidentsTimes.indexOf(anomalyScores.timestamp) < 0) {
          // If select instance and event timestamp dosn't have incident
          return null;
        }
        const timelines = selectAnomalyInstance
          ? R.filter((item) => item.instanceName === selectAnomalyInstance, incidentList)
          : incidentList;
        incidentTimelinesMap[`incidentLine-${anomalyScores.timestamp}`] = timelines;
        let minRank = Infinity;
        let timelineStartTime = null;
        let timelineEndTime = null;
        let tooltipTimelines = R.map((timeline) => {
          const {
            category,
            startTimestamp,
            patternId,
            patternName,
            instanceName,
            componentName,
            rootCauseJson,
            rawData,
            rank,
            endTimestamp,
          } = timeline;

          if (startTimestamp) {
            timelineStartTime = timelineStartTime ? R.min(timelineStartTime, startTimestamp) : startTimestamp;
          }
          if (endTimestamp) {
            timelineEndTime = timelineEndTime ? R.min(timelineEndTime, endTimestamp) : endTimestamp;
          }
          if (isNumber(rank)) {
            minRank = R.min(minRank, rank);
          }
          return {
            rank,
            category,
            patternId,
            patternName,
            instanceName,
            componentName,
            cloneRawData: rawData,
            rawData: rawData && rawData.length > 60 ? `${R.take(60, rawData)}...` : rawData,
            ...(rootCauseJson && rootCauseJson.rootCauseDetailsArr && rootCauseJson.rootCauseDetailsArr.length > 0
              ? { rootCauseJson }
              : {}),
          };
        }, timelines);

        const { projectOwner, patternName } = timelines[0] || {};
        let { projectName } = timelines[0] || {};
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
        tooltipTimelines = R.sortWith([R.ascend(R.prop('rank'))], tooltipTimelines);
        const showLabel = minRank && minRank !== Infinity;

        const object = {
          dataId: 'incidentLine',
          value: [index, 100],
          realScore: 100,
          tooltipTimelines,
          timelineStartTime,
          timelineEndTime,
          itemStyle: { color: '#FF5142' },
          symbol: `path://${PinPath}`,
          symbolSize: [16, 18],
          symbolOffset: [0, -10],
          tooltip: incidentTooltip('detectedIncident'),
          projectName,
          label: {
            show: showLabel,
            color: 'white',
            fontSize: 10,
            fontWeight: 'bold',
            offset: [0, 4],
            formatter: String(minRank),
          },
        };
        if (object) {
          dataListIncident.push(object);
        }
        return null;
      };

      const dataListalertFn = () => {
        const { alertList } = anomalyScores;
        if (alertList.length === 0) {
          return null;
        } else if (selectAnomalyInstance && selectInstanceAlertsTimes.indexOf(anomalyScores.timestamp) < 0) {
          // If select instance and event timestamp dosn't have deployment
          return null;
        }
        const timelines = selectAnomalyInstance
          ? R.filter((item) => item.instanceName === selectAnomalyInstance, alertList)
          : alertList;
        incidentTimelinesMap[`alertLine-${anomalyScores.timestamp}`] = timelines;
        let minRank = Infinity;
        let timelineStartTime = null;
        let tooltipTimelines = R.map((timeline) => {
          const {
            category,
            splitRangeTimestamp,
            startTimestamp,
            patternId,
            patternName,
            instanceName,
            componentName,
            rootCauseJson,
            rawData,
            rank,
          } = timeline;
          if (splitRangeTimestamp || startTimestamp) {
            timelineStartTime = timelineStartTime
              ? R.min(timelineStartTime, splitRangeTimestamp || startTimestamp)
              : splitRangeTimestamp || startTimestamp;
          }
          if (isNumber(rank)) {
            minRank = R.min(minRank, rank);
          }
          return {
            rank,
            category,
            patternId,
            patternName,
            instanceName,
            componentName,
            cloneRawData: rawData,
            rawData: rawData && rawData.length > 60 ? `${R.take(60, rawData)}...` : rawData,
            ...(rootCauseJson && rootCauseJson.rootCauseDetailsArr && rootCauseJson.rootCauseDetailsArr.length > 0
              ? { rootCauseJson }
              : {}),
          };
        }, timelines);

        const { projectOwner } = timelines[0] || {};
        let { projectName } = timelines[0] || {};
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;

        tooltipTimelines = R.sortWith([R.ascend(R.prop('rank'))], tooltipTimelines);
        const showLabel = isNumber(minRank) && minRank !== Infinity;
        const object = {
          dataId: 'alertLine',
          value: [index, 0],
          realScore: 0,
          tooltipTimelines,
          timelineStartTime,
          itemStyle: { color: 'red' },
          symbol: `path://${AlertEventPath}`,
          symbolSize: 18,
          symbolOffset: [0, 12],
          tooltip: incidentTooltip('alert'),
          projectName,
          label: {
            show: showLabel,
            color: 'white',
            fontSize: 10,
            fontWeight: 'bold',
            formatter: String(minRank),
          },
        };
        if (object) {
          dataListalert.push(object);
        }
        return null;
      };

      const dataListDeploymentFn = () => {
        const { deploymentIncidentList } = anomalyScores;
        // const incidentInfo = R.find((item) => item.timestamp === anomalyScores.timestamp, deploymentIncidentList);
        if (deploymentIncidentList.length === 0) {
          return null;
        } else if (selectAnomalyInstance && selectInstanceDeploymentsTimes.indexOf(anomalyScores.timestamp) < 0) {
          // If select instance and event timestamp dosn't have deployment
          return null;
        }
        const timelines = selectAnomalyInstance
          ? R.filter((item) => item.instanceName === selectAnomalyInstance, deploymentIncidentList)
          : deploymentIncidentList;
        incidentTimelinesMap[`deploymentLine-${anomalyScores.timestamp}`] = timelines;
        let minRank = Infinity;
        let timelineStartTime = null;
        let tooltipTimelines = R.map((timeline) => {
          const {
            category,
            startTimestamp,
            patternId,
            patternName,
            instanceName,
            componentName,
            rootCauseJson,
            rawData,
            rank,
          } = timeline;
          if (startTimestamp) {
            timelineStartTime = timelineStartTime ? R.min(timelineStartTime, startTimestamp) : startTimestamp;
          }
          if (isNumber(rank)) {
            minRank = R.min(minRank, rank);
          }
          return {
            rank,
            category,
            patternId,
            patternName,
            instanceName,
            componentName,
            cloneRawData: rawData,
            rawData: rawData && rawData.length > 60 ? `${R.take(60, rawData)}...` : rawData,
            ...(rootCauseJson && rootCauseJson.rootCauseDetailsArr && rootCauseJson.rootCauseDetailsArr.length > 0
              ? { rootCauseJson }
              : {}),
          };
        }, timelines);

        const { projectOwner } = timelines[0] || {};
        let { projectName } = timelines[0] || {};
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;

        tooltipTimelines = R.sortWith([R.ascend(R.prop('rank'))], tooltipTimelines);
        const showLabel = isNumber(minRank) && minRank !== Infinity;
        const object = {
          dataId: 'deploymentLine',
          value: [index, 0],
          realScore: 0,
          tooltipTimelines,
          timelineStartTime,
          itemStyle: { color: 'darkorange' },
          symbol: `path://${ChangeEventPath}`,
          symbolSize: 18,
          symbolOffset: [0, 12],
          tooltip: incidentTooltip('deployment'),
          projectName,
          label: {
            show: showLabel,
            color: 'white',
            fontSize: 10,
            fontWeight: 'bold',
            formatter: String(minRank),
          },
        };
        if (object) {
          dataListDeployment.push(object);
        }
        return null;
      };

      const dataListpredictionIncidentFn = () => {
        const predictionIncidentList = showHistoricalPredictions
          ? anomalyScores.predictionIncidentList
          : R.filter(
              // (item) => item.startTimestamp >= nowTimestamp || item.isFixedIncident,
              (item) => item.startTimestamp >= nowTimestamp,
              anomalyScores.predictionIncidentList,
            );
        // const incidentInfo = R.find((item) => item.timestamp === anomalyScores.timestamp, predictionIncidentList);
        if (predictionIncidentList.length === 0) {
          return null;
        } else if (
          selectAnomalyInstance &&
          selectInstancePredictionIncidentsTimes.indexOf(anomalyScores.timestamp) < 0
        ) {
          // If select instance and event timestamp dosn't have incident
          return null;
        }
        const timelines = selectAnomalyInstance
          ? R.filter((item) => item.instanceName === selectAnomalyInstance, predictionIncidentList)
          : predictionIncidentList;
        incidentTimelinesMap[`predictionIncidentLine-${anomalyScores.timestamp}`] = timelines;

        let timelineStartTime = null;
        const tooltipTimelines = R.map((timeline) => {
          const {
            category,
            startTimestamp,
            patternId,
            patternName,
            instanceName,
            componentName,
            rootCauseJson,
            rawData,
          } = timeline;
          if (startTimestamp) {
            timelineStartTime = timelineStartTime ? R.min(timelineStartTime, startTimestamp) : startTimestamp;
          }
          return {
            category,
            patternId,
            patternName,
            instanceName,
            componentName,
            cloneRawData: rawData,
            rawData: rawData && rawData.length > 60 ? `${R.take(60, rawData)}...` : rawData,
            ...(rootCauseJson && rootCauseJson.rootCauseDetailsArr && rootCauseJson.rootCauseDetailsArr.length > 0
              ? { rootCauseJson }
              : {}),
          };
        }, timelines);

        const { projectOwner, predictionTime, patternName } = timelines[0] || {};
        let { projectName } = timelines[0] || {};
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;

        const object = {
          dataId: 'predictionIncidentLine',
          value: [index, 100],
          realScore: 100,
          incidentOwnTime: anomalyScores.incidentOwnTime || anomalyScores.timestamp,
          tooltipTimelines,
          timelineStartTime,
          itemStyle: { color: '#ffad66' },
          symbol: `path://${PinPath}`,
          symbolSize: [12, 14],
          symbolOffset: [0, -12],
          tooltip: incidentTooltip('pridectedIncident'),
          projectName,
          predictionTime,
        };
        if (object) {
          dataListpredictionIncident.push(object);
        }
        return null;
      };
      const dataListTraceFn = () => {
        const { traceList } = anomalyScores;
        if (traceList.length === 0) {
          return null;
        } else if (selectAnomalyInstance && selectInstanceDeploymentsTimes.indexOf(anomalyScores.timestamp) < 0) {
          return null;
        }

        const timelines = selectAnomalyInstance
          ? R.filter((item) => item.instanceName === selectAnomalyInstance, traceList)
          : traceList;
        incidentTimelinesMap[`traceLine-${anomalyScores.timestamp}`] = timelines;
        let minRank = Infinity;
        let timelineStartTime = null;
        let tooltipTimelines = R.map((timeline) => {
          const {
            category,
            startTimestamp,
            patternId,
            patternName,
            instanceName,
            componentName,
            rootCauseJson,
            rawData,
            rank,
          } = timeline;
          if (startTimestamp) {
            timelineStartTime = timelineStartTime ? R.min(timelineStartTime, startTimestamp) : startTimestamp;
          }
          if (isNumber(rank)) {
            minRank = R.min(minRank, rank);
          }
          return {
            rank,
            category,
            patternId,
            patternName,
            instanceName,
            componentName,
            cloneRawData: rawData,
            rawData: rawData && rawData.length > 60 ? `${R.take(60, rawData)}...` : rawData,
            ...(rootCauseJson && rootCauseJson.rootCauseDetailsArr && rootCauseJson.rootCauseDetailsArr.length > 0
              ? { rootCauseJson }
              : {}),
          };
        }, timelines);

        const { projectOwner } = timelines[0] || {};
        let { projectName } = timelines[0] || {};
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;

        tooltipTimelines = R.sortWith([R.ascend(R.prop('rank'))], tooltipTimelines);
        const showLabel = isNumber(minRank) && minRank !== Infinity;
        const object = {
          dataId: 'traceLine',
          value: [index, 100],
          realScore: 100,
          tooltipTimelines,
          timelineStartTime,
          itemStyle: { color: '#ff5142' },
          symbol: `path://${AimOutlinedPath}`,
          symbolSize: 24,
          symbolOffset: [0, -18],
          tooltip: incidentTooltip('trace'),
          projectName,
          label: {
            show: showLabel,
            color: '#ff5142',
            fontSize: 10,
            fontWeight: 'bold',
            formatter: String(minRank),
          },
        };
        if (object) {
          dataListDeployment.push(object);
        }
        return null;
      };

      dataListActionFn();
      dataListFixedIncidentFn();
      dataListIncidentFn();
      dataListalertFn();
      dataListDeploymentFn();
      dataListpredictionIncidentFn();
      dataListTraceFn();
    }, healthScoresList);

    const dataListIncidentAll = [
      ...dataListAction,
      ...dataListFixedIncident,
      ...dataListIncident,
      ...dataListalert,
      ...dataListDeployment,
    ];
    const seriesIncident = {
      id: 'incidentLine',
      data: dataListIncidentAll,
      type: 'scatter',
      // lineStyle: { width: 0 },
      showSymbol: true,
      showAllSymbol: true,
      hoverAnimation: false,
      cursor: 'pointer',
    };

    const seriesPredictionIncident = {
      id: 'predictionIncidentLine',
      data: dataListpredictionIncident,
      type: 'effectScatter',
      rippleEffect: {
        period: 1.5,
        scale: 3,
        brushType: 'stroke',
      },
      showSymbol: true,
      showAllSymbol: true,
      hoverAnimation: false,
      cursor: 'pointer',
    };

    return {
      incidentTimelinesMap,
      option: {
        tooltip: {
          trigger: 'axis',
          confine: false,
          enterable: true,
          borderWidth: 0,
          backgroundColor: 'var(--content-background)',
          textStyle: { fontSize: 12 },
          extraCssText: 'box-shadow: 0 0 3px rgba(197, 197, 197, 0.8);',
          appendToBody: true,
          formatter: (params, ticket, callback) => {
            // params is array if trigger === 'axis'
            const { name, data } = params[0];
            const { realScore } = data;
            const time = moment.utc(Number(name)).format(Defaults.ShortTimeFormat);
            const { color: currentScoreColor } = calcColorOfHealthScore(realScore);
            return ReactDOMServer.renderToStaticMarkup(
              <div className="flex-row flex-center-align">
                <div className="font-12 font-line-12 font-bold text-primary">
                  {intl.formatMessage(DashboardMessages.healthScore)}:
                </div>
                <div className="flex-row flex-center-align flex-center-justify font-line-14 width-px-130">
                  <div className="text-primary font-12">{time}</div>
                  <Progress
                    type="dashboard"
                    className="margin-x-10"
                    width={25}
                    strokeColor={currentScoreColor}
                    strokeWidth={8}
                    percent={Math.abs(isFinite(realScore) ? realScore : 0)}
                    format={(percent2) => {
                      return (
                        <div className={`color-${currentScoreColor}`}>
                          {isFinite(realScore) ? round(realScore) : 'N/A'}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>,
            );
          },
        },
        toolbox: {
          show: true,
          showTitle: true,
          // orient: 'vertical',
          itemGap: 22,
          left: 6,
          top: 62,
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
              icon: null,
              title: { zoom: 'Zoom', back: 'Back' },
              brushStyle: { color: 'gray', opacity: 0.4 },
            },
            restore: {
              title: 'Return to the full timeline',
              icon: `path://${RestoreSvgPath}`,
              emphasis: {
                iconStyle: {
                  textPosition: 'top',
                  textAlign: 'left',
                  textPadding: [0, 0, 10, 0],
                },
              },
            },
          },
        },
        animation: false,
        grid: { left: 66, right: 16, top: 70, bottom: 22 },
        xAxis: {
          show: false,
          boundaryGap: false,
          type: 'category',
          data: R.map((a) => a.timestamp, healthScoresList),
        },
        yAxis: {
          show: false,
          boundaryGap: false,
          type: 'value',
        },
        series: [
          {
            id: 'healthLine',
            data: R.slice(0, predictionStartP + 1, dataList),
            type: 'line',
            showSymbol: false,
            symbol: 'circle',
            cursor: 'pointer',
            itemStyle: { color: 'rgba(103, 141, 255, 1)' },
            lineStyle: { width: 1.5 },
            areaStyle: { color: 'rgba(103, 141, 255, 0.3)' },
            markPoint: isCurrentDay
              ? {
                  symbol: `path://${TriangleDownPath}`,
                  symbolSize: 5,
                  symbolOffset: [0, -52],
                  silent: true,
                  data: [
                    {
                      name: 'pastTime',
                      xAxis: 0,
                      yAxis: 100,
                      label: {
                        position: 'top',
                        distance: -2,
                        offset: [50, 0],
                        padding: [4, 0],
                        align: 'right',
                        color: 'var(--text-color)',
                        formatter: moment.utc(Number(pastStartTime)).format(Defaults.ShortTimeFormat),
                      },
                    },
                    {
                      name: 'currentTime',
                      xAxis: predictionStartP,
                      yAxis: 100,
                      label: {
                        position: 'top',
                        distance: -2,
                        // offset label if current time >= 80% or >= 20%
                        offset: [
                          predictionStartTimePercent >= 0.8 ? -160 : predictionStartTimePercent >= 0.2 ? -140 : -4,
                          0,
                        ],
                        padding: [4, 0],
                        align: 'left',
                        color: 'var(--text-color)',
                        formatter: `${intl.formatMessage(appFieldsMessages.currentTime)}: ${moment
                          .utc(nowTimestamp)
                          .format(Defaults.ShortTimeFormat)}`,
                      },
                    },
                  ],
                  itemStyle: { color: 'var(--text-color)', borderWidth: 1 },
                }
              : {},
          },
          {
            id: 'predictionHealthLine',
            data: R.slice(predictionStartP, Infinity, dataList),
            type: 'line',
            showSymbol: false,
            symbol: 'circle',
            cursor: 'pointer',
            itemStyle: { color: 'rgba(28, 190, 203, 1)' },
            lineStyle: { width: 1.5 },
            areaStyle: { color: 'rgba(28, 190, 203, 0.3)' },
          },
          seriesIncident,
          seriesPredictionIncident,
        ],
      },
    };
  },
  RenderGHVTooltip: ({
    intl,
    systemHealth,
    incidentType,
    time,
    tooltipTimelines,
    projectName,
    summarySettingsMap,
    currentTheme,
    predictionTime,
  }) => {
    const patterns = {};
    let actionName = '';
    R.forEach((t) => {
      const { patternId, patternName } = t;
      patterns[patternId] = patternName;
      if (t.actionName !== '' && !actionName) {
        actionName = t.actionName;
      }
    }, tooltipTimelines);

    let incidentTime = time;
    if (incidentType === 'detectedIncident') {
      const ts = R.sort((a, b) => a.timelineStartTime - b.timelineStartTime, tooltipTimelines);
      if (ts.length > 1 && ts[0].timelineStartTime !== ts[ts.length - 1].timelineStartTime) {
        incidentTime = `${moment.utc(ts[0].timelineStartTime).format(Defaults.ShortDateTimeFormat)} ~ ${moment
          .utc(ts[ts.length - 1].timelineEndTime || ts[ts.length - 1].timelineStartTime)
          .format(Defaults.ShortDateTimeFormat)}`;
      }
    }

    const renderHeader =
      `<div onclick="window.onClickHandleGHVChartTipHide('${systemHealth.id}')"` +
      `style="position: absolute; right: 15px; top: 8px; cursor: pointer; height: 14px">` +
      `${ReactDOMServer.renderToStaticMarkup(<CloseOutlined className="font-14" />)}</div>`;

    const content = ReactDOMServer.renderToStaticMarkup(
      <div className="full-height flex-col flex-min-height font-line-20">
        <div className="flex-row flex-space-between bold text-secondary font-line-16">
          {incidentType === 'pridectedIncident' ? (
            <div>Prediction time: {predictionTime}</div>
          ) : (
            <div>{incidentTime}</div>
          )}
          <div className="margin-r-30">
            {intl.formatMessage(DashboardMessages.clickBalloonForDetails, {
              name: 'icon',
            })}
          </div>
        </div>
        {incidentType === 'pridectedIncident' && (
          <div className="flex-row bold font-14" style={{ paddingTop: 4 }}>
            Predicted Incidents
          </div>
        )}
        {incidentType === 'detectedIncident' && (
          <div className="flex-row bold font-14" style={{ paddingTop: 4 }}>
            Detected Incidents
          </div>
        )}
        {incidentType === 'deployment' && (
          <div className="flex-row bold font-14" style={{ paddingTop: 4 }}>
            Change Events
          </div>
        )}
        {incidentType === 'action' && (
          <div className="flex-row bold font-14" style={{ paddingTop: 4 }}>
            Triggered Actions
          </div>
        )}
        {incidentType === 'fixedIncident' && (
          <div className="flex-row bold font-14" style={{ paddingTop: 4 }}>
            Fixed Incidents
          </div>
        )}
        {incidentType === 'trace' && (
          <div className="flex-row bold font-14" style={{ paddingTop: 4 }}>
            Trace anomalies
          </div>
        )}
        {!!actionName && (
          <div className="flex-row font-12" style={{ paddingTop: 4 }}>
            <span>Action name:</span>
            <span
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                paddingRight: 15,
              }}
            >
              <span style={{ paddingLeft: 8 }}>{actionName}</span>
            </span>
          </div>
        )}
        <div className="flex-row font-12" style={{ paddingTop: 4 }}>
          <span>{intl.formatMessage(appFieldsMessages.patternIdName)}:</span>
          <span
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              wordBreak: 'break-word',
              whiteSpace: 'normal',
              paddingRight: 15,
            }}
          >
            {R.map((patternId) => {
              const patternName = patterns[patternId];
              const { patternNameStr } = Defaults.PatternIdNameStr(
                { patternName, patternId },
                { hasFullName: true, hasPrefix: true },
              );
              return (
                <span key={patternId} style={{ paddingLeft: 8 }}>
                  {patternNameStr}
                </span>
              );
            }, R.keys(patterns))}
          </span>
        </div>
        {incidentType === 'pridectedIncident' && <div style={{ paddingTop: 4 }}>Predicted occurrence time: {time}</div>}
      </div>,
    );

    return (
      `<div class="ghv-chart-tooltip flex-col flex-min-height" ` +
      `style="width: 360px;max-height: 160px; overflow-x: auto;">${renderHeader}${content}</div>`
    );
  },
  parseRootCauseInfo: ({
    instanceComponentMap,
    operation,
    chains,
    credentials,
    projectDisplayMap,
    incident,
    isExpand,
    isCompareIncidents,
  }) => {
    let relatedEventList = [];
    let allSuggestActions = [];
    let allInstances = [];
    let allMetrics = [];

    // parse chains
    relatedEventList = R.addIndex(R.map)((chain, idx) => {
      const { rootCauseKey, rootCauseChain, relevance, isIntra } = chain;
      const { predictionSourceInfoList } = rootCauseChain;

      const instanceList = [];
      const metricList = [];
      const actionRootCauseKeyList = [];
      let suggestActions = [];
      const rootCausePatterns = [];
      let overallProcessId = incident?.id;
      const sourceInfoList = R.map((sourceInfo) => {
        const {
          sourceDetail,
          suggestionActions,
          eventTimestamp,
          componentName,
          sourceInstanceName,
          metricInstanceName,
          containerId,
          sourceProjectName,
          sourceProjectOwner,
          type,
          isDeployment,
          timePairStr,
        } = sourceInfo;
        let { sourceKey } = sourceInfo;
        if (isCompareIncidents && isIntra) {
          const [p, i, m] = R.split('@', sourceKey || '');
          sourceKey = `${p}@${m}`;
        }

        const instanceName = sourceInstanceName;
        instanceList.push(instanceName);

        let newSourceDetail = parseJSON(sourceDetail) || {};
        const { nid, type: newType, metricDirection, content } = newSourceDetail;
        const { eventType } = CausalParser.getRelationLogType(newType);
        const isMetric = Boolean(metricDirection);
        newSourceDetail = { ...newSourceDetail, isMetric, eventType: isDeployment ? 'deployment' : eventType };
        if (isMetric) {
          metricList.push(content);
        }

        let newSuggestionActions = parseJSON(suggestionActions || '[]') || [];
        newSuggestionActions = R.map((item) => {
          return parseJSON(item) || {};
        }, newSuggestionActions);
        suggestActions = [...suggestActions, ...newSuggestionActions];

        actionRootCauseKeyList.push(sourceKey);
        // const projectNameReal =
        //   sourceProjectOwner !== credentials.userName
        //     ? `${sourceProjectName}@${sourceProjectOwner}`
        //     : sourceProjectName;
        // const projectDisplayName = get(projectDisplayMap, projectNameReal, projectNameReal);
        rootCausePatterns.push(`${nid} (${instanceName}: ${isMetric ? content : 'Log'})`);

        const timePairs = timePairStr ? parseJSON(timePairStr) || [] : [];
        const id = `${metricInstanceName || instanceName}-${eventTimestamp}`;
        overallProcessId += `-->${id}`;

        return {
          ...sourceInfo,
          rootCauseKey,
          sourceDetail: newSourceDetail,
          suggestionActions: newSuggestionActions,
          displayTimestamp: eventTimestamp,
          componentName,
          instanceName,
          metricInstanceName,
          containerId,
          projectName: sourceProjectName,
          owner: sourceProjectOwner,
          type, // Log, Metric, Incident
          isMetric,
          isDeployment,
          timePairs,
          id,

          patternId: nid,
          appName:
            componentName && componentName !== instanceName ? `${instanceName} (${componentName})` : instanceName,
        };
      }, predictionSourceInfoList);
      allSuggestActions = [...allSuggestActions, ...suggestActions];
      const actionRootCauseKey = R.join('@', actionRootCauseKeyList);
      allInstances = R.concat(allInstances, instanceList);
      allMetrics = R.concat(allMetrics, metricList);

      // add rank and color:
      const rank = idx + 1;
      let color = '#797D92';
      switch (rank) {
        case 1:
          color = '#FF5142';
          break;
        case 2:
          color = '#FF8A61';
          break;
        case 3:
          color = '#FEB207';
          break;
        default:
          break;
      }

      // parse relevance and reportUser
      const { relevanceLevel, relevanceReporterManagement } = parseJSON(relevance) || {};
      let reporterRecordSet = [];
      const { reporterMap } = relevanceReporterManagement || {};
      R.forEachObjIndexed((val, action) => {
        const { reporterDetailMap } = val || {};
        R.forEach((item) => {
          const { reporterName, reportTimestampSet } = item;
          R.forEach((timestamp) => {
            reporterRecordSet.push({
              timestamp,
              reporterName,
              action,
            });
          }, reportTimestampSet || []);
        }, R.values(reporterDetailMap || {}));
      }, reporterMap);
      reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

      return {
        ...chain,
        operation,
        rootCauseKey,
        actionRootCauseKey,
        sourceInfoList,
        relevance: relevanceLevel,
        reporterRecordSet,
        rootCausePatterns,
        suggestActions,
        instanceList,
        metricList,
        rank,
        rankColor: color,
        overallProcessId,
        isExpand,
      };
    }, chains || []);
    allInstances = R.uniq(allInstances);
    allMetrics = R.uniq(allMetrics);

    return { relatedEventList, allSuggestActions, allInstances, allMetrics };
  },
  parseSuggestActions: ({ currentLocale, rootCauseSuggestActions, suggestActionListFromTimeLine }) => {
    let suggestActions = rootCauseSuggestActions || [];

    // parse suggest action list from timeline
    let suggestActionList = [];
    if (suggestActionListFromTimeLine) {
      if (isString(suggestActionListFromTimeLine)) {
        suggestActionList = JSON.parse(suggestActionListFromTimeLine);
      } else {
        suggestActionList = R.map((item) => {
          if (item instanceof String) {
            return JSON.parse(item);
          }
          return item;
        }, suggestActionListFromTimeLine);
      }
      suggestActions = R.concat(suggestActions, suggestActionList);
    }

    const isEnglish = currentLocale === 'en';
    suggestActions = R.map((item) => {
      const { metricInstanceName, componentName, instanceName } = item;
      let action = null;
      action = isEnglish ? item.actionMap.English : item.actionMap.Chinese;
      const key = `${action}-${instanceName}-${componentName}`;
      return { key, action, componentName, instanceName, metricInstanceName };
    }, suggestActions);
    suggestActions = R.uniqWith(R.eqBy(R.prop('key')), suggestActions);
    R.addIndex(R.map)((item, idx) => {
      const rank = idx + 1;
      return { ...item, rank };
    }, suggestActions);

    return suggestActions;
  },
  buildOptionTimelineRange: ({ rangeStartTime, rangeEndTime, isDark = false }) => {
    return {
      useUTC: true,
      backgroundColor: 'transparent',
      grid: { left: 20, right: 20, top: 0, bottom: 28 },
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      xAxis: {
        type: 'time',
        min: rangeStartTime,
        max: rangeEndTime,
        boundaryGap: false,
        splitLine: { show: false },
        axisLabel: { hideOverlap: true },
        axisLine: { lineStyle: {} },
        axisTick: { lineStyle: {} },
      },
      yAxis: { type: 'value', show: false, boundaryGap: false, splitLine: { show: false } },
      series: [{ type: 'line', data: [] }],
    };
  },
  buildOptionTimeline: ({
    intl,
    rankMap,
    rangeStartTime,
    rangeEndTime,
    item,
    summarySettingsMap,
    projectName,
    currentTheme,
  }) => {
    const {
      timelines,
      isIncident,
      isDeployment,
      isAlert,
      anomalyColor,
      isTrace,
      traceId,
      metricRootCause,
      anomalyFeatureStr: anomalyFeatureArr,
    } = item;

    let isSpecial = false;
    let symbol = 'circle';
    let symbolColor = anomalyColor;
    if (isIncident) {
      isSpecial = true;
      symbol = `path://${PinPath}`;
      symbolColor = '#FF5142';
    } else if (isDeployment) {
      isSpecial = true;
      symbol = `path://${ChangeEventPath}`;
      symbolColor = 'orange';
    } else if (isAlert) {
      isSpecial = true;
      symbol = `path://${AlertEventPath}`;
      symbolColor = 'red';
    }

    // Whether the starttimestamp and endtimestamp of timelines are within rangestarttime and rangeendtime.
    // If yes, it is true, but not false
    const timeFlagArr = R.reduce((pre, val) => {
      const { startTimestamp, endTimestamp } = val;
      if (startTimestamp < rangeStartTime && endTimestamp > rangeEndTime) {
        pre.push(true);
      } else {
        pre.push(false);
      }
      return pre;
    }, [])(timelines);

    // If both are present
    // go to the minimum value of starttimestamp and the maximum value of endtimestamp in the timelines array
    let sectionTime = {};
    if (!R.includes(false, timeFlagArr)) {
      sectionTime = R.reduce(
        (pre, val) => {
          const { startTimestamp, endTimestamp } = val;
          pre.minTime = R.min(startTimestamp, pre.minTime);
          pre.maxTime = R.max(endTimestamp, pre.maxTime);
          return pre;
        },
        { minTime: Infinity, maxTime: 0 },
      )(timelines);
    }

    // build range lines series
    const series = [];
    R.forEach((event) => {
      const { startTimestamp, endTimestamp, id } = event;

      const { minTime, maxTime } = sectionTime;
      event.sectionStartTime = minTime ? (minTime < rangeStartTime ? rangeStartTime : minTime) : startTimestamp;
      event.sectionEndTime = maxTime ? (maxTime > rangeEndTime ? rangeEndTime : maxTime) : endTimestamp;
      const { sectionStartTime, sectionEndTime } = event;

      let rank = 0;
      if (isSpecial) {
        rank = get(rankMap, id, 0);
      }
      series.push({
        type: 'line',
        symbol,
        symbolSize: isSpecial ? 16 : 12,
        itemStyle: {
          color: symbolColor,
          borderColor: 'white',
          borderWidth: isSpecial ? 0 : 0.2,
        },
        lineStyle: { width: 3 },
        data: [
          { name: startTimestamp, value: [sectionStartTime, 0], range: { startTimestamp, endTimestamp } },
          { name: endTimestamp, value: [sectionEndTime, 0], range: { startTimestamp, endTimestamp } },
        ],
        label: {
          show: rank > 0,
          color: 'white',
          fontSize: 10,
          fontWeight: 'bold',
          textBorderWidth: 0,
          offset: [0, isIncident ? 18 : 19],
          formatter: String(rank),
        },
      });
    }, timelines || []);

    return {
      backgroundColor: 'transparent',
      useUTC: true,
      animation: false,
      grid: { left: 20, right: 20, top: 0, bottom: 0 },
      xAxis: { type: 'time', show: false, boundaryGap: false, min: rangeStartTime, max: rangeEndTime },
      yAxis: { type: 'value', show: false, boundaryGap: false, min: -1, max: 1 },
      series,
      tooltip: {
        trigger: 'item',
        confine: false,
        enterable: true,
        appendToBody: true,
        textStyle: { fontSize: 12, color: 'var(--text-color)' },
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        position: (pos, params, dom, rect, size) => {
          const boxWidth = size.contentSize[0];
          const boxHeight = size.contentSize[1];
          const viewWidth = size.viewSize[0] + 100;
          const rectX = rect.x;
          let pointX = rectX - boxWidth / 2;
          const pointY = -boxHeight + 5;
          // if (dom.getBoundingClientRect().top - boxHeight + 5 <= 0) {
          //   pointY = rect.y + rect.height / 2 + 5;
          // }
          if (pointX + boxWidth > viewWidth) {
            pointX = viewWidth - boxWidth;
          }
          return [pointX, pointY];
        },
        formatter: (params) => {
          const { data, seriesIndex } = params || {};
          const { range } = data || {};

          // used for tooltip
          const { category, rawData, anomalyWords, outlierValue, rootCauseJson, timestampMap } =
            timelines[seriesIndex] || item;
          let rawDataJson;
          try {
            rawDataJson = JSON.parse(rawData);
          } catch (error) {
            // console.debug(error);
          }
          const rootCauseDetailsArr = get(rootCauseJson, ['rootCauseDetailsArr'], []);

          let metricRootCauseObj;
          let frequencyStr = '';
          try {
            metricRootCauseObj = JSON.parse(metricRootCause);
          } catch (e) {
            // console.debug(error)
          }
          if (metricRootCauseObj && category.toLowerCase() === 'log') {
            const { anomalyValue, percentage, sign } = metricRootCauseObj;
            const percent = `${Math.abs(percentage).toFixed(2)}%`;
            frequencyStr = `${
              isNumber(anomalyValue) ? `Count: ${anomalyValue}. ` : ''
            }Frequency is ${percent} ${sign} than normal.`;
          }

          let anomalyFeatureStr;
          if (anomalyFeatureArr && category.toLowerCase() === 'log') {
            anomalyFeatureStr = R.map((item) => {
              const [label] = item.label.split(':');
              const name = get(item, 'name');
              return { label: name || label, value: item.value };
            }, anomalyFeatureArr);
            anomalyFeatureStr = R.sort(R.ascend(R.prop('label')), anomalyFeatureStr);
            const featureGroup = R.groupBy((item) => item.label)(anomalyFeatureStr);

            anomalyFeatureStr = R.map((item) => item, R.toPairs(featureGroup));
          }

          const maxDuration = R.reduce(
            R.max,
            0,
            R.map((t) => Number(get(t?.rawDataJson, 'duration', 0)) * 1000, timelines),
          );
          const contentInfo = ReactDOMServer.renderToStaticMarkup(
            <>
              {!isTrace && (
                <>
                  <div className="flex-row">
                    <div className="font-bold width-px-12o" style={{ width: 74 }}>
                      {intl.formatMessage(eventMessages.startTime)}:
                    </div>
                    <div className="flex-grow word-break-all">
                      {`${moment.utc(range.startTimestamp).format(Defaults.ShortTimeFormat)}`}
                    </div>
                  </div>
                  <div className="flex-row">
                    <div className="font-bold width-px-12o" style={{ width: 74 }}>
                      {intl.formatMessage(eventMessages.endTime)}:
                    </div>
                    <div className="flex-grow word-break-all">
                      {`${moment.utc(range.endTimestamp).format(Defaults.ShortTimeFormat)}`}
                    </div>
                  </div>
                  {category !== 'metric' && (
                    <div className="flex-row">
                      <div className="font-bold width-px-12o" style={{ width: 74 }}>
                        {intl.formatMessage(eventMessages.count)}:
                      </div>
                      <div className="flex-grow word-break-all">{(timestampMap || {})[range.startTimestamp] || 1}</div>
                    </div>
                  )}
                </>
              )}
              {isTrace && (
                <div className="flex-col width-px-580">
                  <div className="flex-col">
                    {R.addIndex(R.map)((item, index) => {
                      const { outlierValue, componentName: componentListStr, id, rawDataJson } = item;
                      const parentId = get(rawDataJson, 'parent_span_id');
                      const spanId = get(rawDataJson, 'span_id');
                      const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentListStr;
                      const name = get(rawDataJson, 'method_name');
                      const startTime = get(rawDataJson, 'start_time');
                      const duration = Number(get(rawDataJson, 'duration', 0)) * 1000;
                      const timestamp = moment.utc(startTime).valueOf() * 1000;
                      const traceId = uuidv4();
                      const traceInfo = {
                        traceId,
                        parentId,
                        id: spanId,
                        name,
                        timestamp,
                        duration,
                        serviceName,
                        color: '#ffccc7',
                        tags: {},
                      };
                      return (
                        <div key={index} className="flex-col m-l-8">
                          <div className="flex-row">
                            <div className="font-bold width-px-12o">{intl.formatMessage(eventMessages.startTime)}:</div>
                            <div className="flex-grow word-break-all">
                              {`${moment.utc(item.startTimestamp).format(Defaults.ShortTimeFormat)}`}
                            </div>
                          </div>
                          <div className="flex-row">
                            <div className="font-bold width-px-12o">{intl.formatMessage(eventMessages.endTime)}:</div>
                            <div className="flex-grow word-break-all">
                              {`${moment.utc(item.endTimestamp).format(Defaults.ShortTimeFormat)}`}
                            </div>
                          </div>
                          <div className="flex-row">
                            <div className="font-bold width-px-12o">
                              {intl.formatMessage(DashboardMessages.detectedTrace)}:
                            </div>
                            <div className="flex-grow word-break-all">{item?.traceInfo?.traceId || ''}</div>
                          </div>
                          <div className="flex-row word-break-all">
                            <span className="light-label bold white-space-no-wrap">Service Name:</span>
                            <span className="m-l-8">{serviceName}</span>
                          </div>
                          <div className="flex-row word-break-word">
                            <span className="light-label bold white-space-no-wrap">Span Info:</span>
                            <span className="m-l-8">{name}</span>
                          </div>
                          <LogRenderers.RenderOutlierValue
                            className="flex-row word-break-word"
                            outlierValue={outlierValue}
                            isTrace
                          />
                          <div className="trace-timelines with-full-timeline">
                            <ThundraTraceChart
                              traceId={traceId}
                              traceSummary={[
                                traceInfo,
                                { ...traceInfo, duration: maxDuration, id: `${traceInfo.id}_full` },
                              ]}
                              showHeader={false}
                              showMiniTrace={false}
                              showSpanDetailTitle
                              showSpanDetail={false}
                            />
                          </div>
                        </div>
                      );
                    }, timelines)}
                  </div>
                </div>
              )}
            </>,
          );

          const contentShortDescriptionTitle = ReactDOMServer.renderToStaticMarkup(
            <div className="font-bold min-width-120">{intl.formatMessage(eventMessages.shortDescription)}:</div>,
          );

          const anomalyWordsAndOutlierValueHtml = ReactDOMServer.renderToStaticMarkup(
            <>
              {anomalyWords && anomalyWords.length > 0 && (
                <>
                  anomaly word:
                  <LogRenderers.RenderAnomalyWords
                    className="word-break-all white-space-normal m-b-12"
                    anomalyWordList={anomalyWords}
                  />
                </>
              )}
              {outlierValue && !R.isEmpty(outlierValue) && (
                <LogRenderers.RenderOutlierValue
                  className="word-break-all white-space-normal m-b-12"
                  outlierValue={outlierValue}
                />
              )}
            </>,
          );

          const frequencyStrAndNotRawDataJsonHtml = ReactDOMServer.renderToStaticMarkup(
            <>
              {!R.isEmpty(frequencyStr) && !rawDataJson && <div className="color-blue">{frequencyStr}</div>}
              {!rawDataJson && anomalyFeatureStr && (
                <div className="max-width flex-col word-break-all color-blue">
                  {R.addIndex(R.map)((item, index) => {
                    const [label, values] = item;
                    const content = R.addIndex(R.map)(
                      ({ value }, i) => (
                        <span className="m-x-4" key={`featureStr${index}${i}`}>
                          {value}
                          {values.length - 1 === i ? '' : ','}
                        </span>
                      ),
                      values,
                    );
                    return (
                      <div className="flex-row" key={`${index}+${label}`}>
                        <div className="white-space-no-wrap">{label} :</div>
                        <div className="flex-row flex-wrap">{content}</div>
                      </div>
                    );
                  }, anomalyFeatureStr)}
                </div>
              )}
            </>,
          );

          const logMessageAndRawDataJson = ReactDOMServer.renderToStaticMarkup(
            <>
              {!rawDataJson &&
                R.join(
                  '\n',
                  R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                )}
              {rawDataJson && (
                <LogRenderers.RenderLogContent
                  intl={intl}
                  rawData={rawData}
                  rawDataJson={rawDataJson}
                  owner={item}
                  summarySettings={(summarySettingsMap || {})[projectName] || []}
                  enableExpansion={false}
                  autoExpand
                  currentTheme={currentTheme}
                  frequencyStr={frequencyStr}
                  anomalyFeatureStr={anomalyFeatureStr}
                  noExpand
                />
              )}
            </>,
          );

          const metricHtml = ReactDOMServer.renderToStaticMarkup(
            <>
              {category === 'metric' && (
                <div className="full-width word-break-all white-space-normal">
                  {R.addIndex(R.map)(
                    (event, index) =>
                      EventRenderers.RenderMetricAnomalySummary({
                        intl,
                        // category: 'shortDisplay',
                        event,
                        index,
                      }),
                    rootCauseDetailsArr || [],
                  )}
                </div>
              )}
            </>,
          );

          const logContentStr = R.join(
            '-QvQ-',
            R.filter((x) => Boolean(x), (rawData || '').split('\n')),
          );

          const rootHtml = `
            <div class="flex-col overflow-y-auto max-width-600 max-height-320 p-r-8">
              ${contentInfo}
              ${
                !isTrace
                  ? `<div class="flex-row">
                  ${contentShortDescriptionTitle}
                  <div class="flex-grow flex-min-width">
                    ${
                      category === 'log'
                        ? `${anomalyWordsAndOutlierValueHtml}
                      <div class="full-width white-space-pre-wrap word-break-all">${frequencyStrAndNotRawDataJsonHtml}${
                            !rawDataJson &&
                            `<span class="m-n-b-4 color-link font-14 copyContent clickable" onclick="window.handleCopyClick('${logContentStr}', true)"><svg viewBox="64 64 896 896" focusable="false" data-icon="snippets" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M832 112H724V72c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v40H500V72c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v40H320c-17.7 0-32 14.3-32 32v120h-96c-17.7 0-32 14.3-32 32v632c0 17.7 14.3 32 32 32h512c17.7 0 32-14.3 32-32v-96h96c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM664 888H232V336h218v174c0 22.1 17.9 40 40 40h174v338zm0-402H514V336h.2L664 485.8v.2zm128 274h-56V456L544 264H360v-80h68v32c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-32h152v32c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-32h68v576z"></path></svg></span>`
                          }${logMessageAndRawDataJson}
                      </div>`
                        : ''
                    }
                    ${metricHtml}
                  </div>
                </div>`
                  : ''
              }
            </div>
          `;
          return rootHtml;
        },
      },
    };
  },
};

export default GlobalParse;
