/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import CollapsibleContent from './CollapsibleContent';

const CollapsibleLogContent = ({ ownerObject, message, style, ...options }: Object) => {
  let { content } = options;
  // replace html tag
  if (content) {
    content = R.replace(/</g, '&lt;', content);
    content = R.replace(/>/g, '&gt;', content);
  }
  if (message && message instanceof String) {
    message = R.replace(/</g, '&lt;', message);
    message = R.replace(/>/g, '&gt;', message);
  }

  // return item = <div style={{ ...style }} className="hidden-line-with-ellipsis"> {message} </div>;
  return <CollapsibleContent ownerObject={ownerObject} content={content || message} style={style} {...options} />;
};

export default CollapsibleLogContent;
