/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import type { Action } from '../types';

export const ActionTypes = {
  LOAD_LOG_ANALYSIS: 'LOAD_LOG_ANALYSIS',
  LOAD_LOG_ANOMALY_TIMELINES: 'LOAD_LOG_ANOMALY_TIMELINES',
  SET_LOG_ANOMALY_TIMELINES: 'SET_LOG_ANOMALY_TIMELINES',
  LOAD_LOG_STATS: 'LOAD_LOG_STATS',
  SET_LOG_STATS: 'SET_LOG_STATS',
  LOAD_LOG_INSTANCES_STATS: 'LOAD_LOG_INSTANCES_STATS',
  SET_LOG_INSTANCES_STATS: 'SET_LOG_INSTANCES_STATS',
  LOAD_LOG_INSTANCEINFO_LIST: 'LOAD_LOG_INSTANCEINFO_LIST',
  LOAD_LOG_KEYWORD_STATS: 'LOAD_LOG_KEYWORD_STATS',
  SET_LOG_KEYWORD_STATS: 'SET_LOG_KEYWORD_STATS',
  SET_LOG_INSTANCEINFO_LIST: 'SET_LOG_INSTANCEINFO_LIST',
  LOAD_LOG_CLUSTERINFO_LIST: 'LOAD_LOG_CLUSTERINFO_LIST',
  SET_LOG_CLUSTERINFO_LIST: 'SET_LOG_CLUSTERINFO_LIST',
  LOAD_LOG_SEQUENCEINFO_LIST: 'LOAD_LOG_SEQUENCEINFO_LIST',
  SET_LOG_SEQUENCEINFO_LIST: 'SET_LOG_SEQUENCEINFO_LIST',
  LOAD_LOG_ANOMALY_SEQUENCEINFO_LIST: 'LOAD_LOG_ANOMALY_SEQUENCEINFO_LIST',
  SET_LOG_ANOMALY_SEQUENCEINFO_LIST: 'SET_LOG_ANOMALY_SEQUENCEINFO_LIST',
  LOAD_LOG_KEYWORDINFO_LIST: 'LOAD_LOG_KEYWORDINFO_LIST',
  SET_LOG_KEYWORDINFO_LIST: 'SET_LOG_KEYWORDINFO_LIST',
  LOAD_LOG_HOTCOLD_ENTRY_LIST: 'LOAD_LOG_HOTCOLD_ENTRY_LIST',
  SET_LOG_HOTCOLD_ENTRY_LIST: 'SET_LOG_HOTCOLD_ENTRY_LIST',
  LOAD_LOG_ENTRY_CONTEXT_LIST: 'LOAD_LOG_ENTRY_CONTEXT_LIST',
  SET_LOG_ENTRY_CONTEXT_LIST: 'SET_LOG_ENTRY_CONTEXT_LIST',
  LOAD_LOG_SEQ_ENTRY_LIST: 'LOAD_LOG_SEQ_ENTRY_LIST',
  SET_LOG_SEQ_ENTRY_LIST: 'SET_LOG_SEQ_ENTRY_LIST',
  LOAD_LOG_KEYWORD_ENTRY_LIST: 'LOAD_LOG_KEYWORD_ENTRY_LIST',
  SET_LOG_KEYWORD_ENTRY_LIST: 'SET_LOG_KEYWORD_ENTRY_LIST',
  LOAD_LOG_PATTERN_ENTRY_LIST: 'LOAD_LOG_PATTERN_ENTRY_LIST',
  SET_LOG_PATTERN_ENTRY_LIST: 'SET_LOG_PATTERN_ENTRY_LIST',
  LOAD_LOG_ENTRY_CAUSAL_LIST: 'LOAD_LOG_ENTRY_CAUSAL_LIST',
  SET_LOG_ENTRY_CAUSAL_LIST: 'SET_LOG_ENTRY_CAUSAL_LIST',
  LOAD_KEYWORD_FREQUENCY_ENTRY: 'LOAD_KEYWORD_FREQUENCY_ENTRY',
  SET_KEYWORD_FREQUENCY_ENTRY: 'SET_KEYWORD_FREQUENCY_ENTRY',
  LOAD_LOG_IMPORTANT_ENTRY_LIST: 'LOAD_LOG_IMPORTANT_ENTRY_LIST',
  SET_LOG_IMPORTANT_ENTRY_LIST: 'SET_LOG_IMPORTANT_ENTRY_LIST',
  SAVE_LOG_PUSH: 'SAVE_LOG_PUSH',

  LOAD_EVENT_TRIAGE: 'LOAD_EVENT_TRIAGE',
  SET_EVENT_TRIAGE: 'SET_EVENT_TRIAGE',
  SAVE_EVENT_TRIAGE: 'SAVE_EVENT_TRIAGE',
  LOAD_EVENT_TRIAGE_REPORT_LIST: 'LOAD_EVENT_TRIAGE_REPORT_LIST',
  SET_EVENT_TRIAGE_REPORT_LIST: 'SET_EVENT_TRIAGE_REPORT_LIST',
  SAVE_EVENT_TRIAGE_REPORT_LIST: 'SAVE_EVENT_TRIAGE_REPORT_LIST',
  LOAD_FEATURE_KEYWORD: 'LOAD_FEATURE_KEYWORD',
  SER_FEATURE_KEYWORD: 'SER_FEATURE_KEYWORD',
  LOAD_LOG_DAY_ENTRY_LIST: 'LOAD_LOG_DAY_ENTRY_LIST',
  SET_LOG_DAY_ENTRY_LIST: 'SET_LOG_DAY_ENTRY_LIST',
  LOAD_LOG_FEATURE_ENTRY_INFO: 'LOAD_LOG_FEATURE_ENTRY_INFO',
  SET_LOG_FEATURE_ENTRY_INFO: 'SET_LOG_FEATURE_ENTRY_INFO',
};

/**
 * Action used to load log incident list, handled by epic.
 */
export const loadLogIncidentList = (params: Object, force: Boolean = false, loader: ?any): Action => ({
  type: 'LOAD_LOG_INCIDENT_LIST',
  payload: { params, force, loader },
});

/**
 * Select the pattern in the view. Handled by reducer.
 */
export const selectLogPattern = (view: String, patternId: String): Action => ({
  type: 'SELECT_LOG_PATTERN',
  payload: { view, patternId },
});

export const selectLogPatternSequence = (view: String, sequenceId: String): Action => ({
  type: 'SELECT_LOG_PATTERN_SEQUENCE',
  payload: { view, sequenceId },
});

export const setLogPatternName = (
  projectName: String,
  incidentId: String,
  view: String,
  patternId: any,
  patternName: String,
  components: Object,
  instanceName: String,
  isPatternSequence: Boolean,
  sequencePatterns: String,
): Action => ({
  type: 'SET_LOG_PATTERN_NAME',
  payload: {
    projectName,
    view,
    incidentId,
    patternId,
    patternName,
    components,
    instanceName,
    isPatternSequence,
    sequencePatterns,
  },
});

export const updateLogPatternFlag = (params: Object, loader: ?any): Action => ({
  type: 'UPDATE_LOG_PATTERN_FLAG',
  payload: { params, loader },
});

export const setLogPatternFlag = (params: Object): Action => ({
  type: 'SET_LOG_PATTERN_FLAG',
  payload: { params },
});

export const updateLogPatternNameInList = (patternId: String, patternName: String): Action => ({
  type: 'UPDATE_LOG_PATTERN_NAME_INLIST',
  payload: { patternId, patternName },
});

export const updateLogPatternName = (view: String, patternId: String, patternName: String): Action => ({
  type: 'UPDATE_LOG_PATTERN_NAME',
  payload: { view, patternId, patternName },
});

export const updateLogPatternSequenceName = (view: String, patternId: String, patternSequenceName: String): Action => ({
  type: 'UPDATE_LOG_PATTERN_SEQUENCE_NAME',
  payload: { view, patternId, patternSequenceName },
});

/**
 * Load the events based on condition and store at the related storePath.
 */
export const loadLogEventList = (
  projectName: String,
  instanceName: String,
  view: String,
  params: Object,
  components: Object,
): Action => ({
  type: 'LOAD_LOG_EVENTLIST',
  payload: { projectName, view, instanceName, params, components },
});

export const loadLogSequenceEventList = (
  projectName: String,
  view: String,
  params: Object,
  components: Object,
): Action => ({
  type: 'LOAD_LOG_SEQUENCE_EVENTLIST',
  payload: { projectName, view, params, components },
});

/**
 * Rerun the log detection.
 */
export const rerunLogDetection = (projectName: String, incidentId: String): Action => ({
  type: 'RERUN_LOG_DETECTION',
  payload: { projectName, incidentId },
});

/**
 * This action will set the log state, it's handled by reducer.
 */
export const setLogInfo = (info: Object): Action => ({
  type: 'SET_LOG_INFO',
  payload: info,
});

export const setLogGradientBarsInfo = (info: Object): Action => ({
  type: 'SET_LOG_GRADIENT_BARS',
  payload: info,
});
export const setLogGradientViewInfo = (info: Object): Action => ({
  type: 'SET_LOG_GRADIENT_VIEW',
  payload: info,
});
