/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 ***/

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchDelete from './fetchDelete';

const deleteCausalGroup = (credentials: Credentials, params: Object) => {
  const { causalKey, customerName } = params;
  return fetchDelete(getEndpoint('causalgroup'), { ...credentials, causalKey, customerName }, {}, false).then(() => {
    return {};
  });
};

export default deleteCausalGroup;
