import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';

import { Modal } from '../../../../artui/react';
import EventGroup from '../../../../components/log/loganalysis/event-group';

type Props = {
  intl: Object,
  location: Object,
  loadStatus: Object,
  projects: Array<Object>,

  projectName: String,
  instanceName: String,
  entryList: Array<Object>,
  hasType: Boolean,
  hasContext: Boolean,
  showLinkedJump: Boolean,
  onClose: Function,
  highlightWord: any,
  showTimeRange: Boolean,
  titleMsg: String,
  isCritical: Boolean,
};

class LogEntryListModalCore extends React.Component {
  props: Props;

  @autobind
  handleClose() {
    this.props.onClose();
  }

  render() {
    const { projects } = this.props;
    const {
      hasType,
      hasContext,
      showLinkedJump,
      projectName,
      instanceName,
      entryList,
      highlightWord,
      showTimeRange,
      titleMsg,
      isCritical,
    } = this.props;

    let logEntryList = entryList;

    if (projectName) {
      const project = R.find(
        (project) => project.projectName === projectName || project.projectShortName === projectName,
        projects,
      );
      const projectLinkInfo = get(project, 'projectLinkInfo', []);
      if (projectLinkInfo.length > 0) {
        logEntryList = R.map((logEntry) => {
          return { ...logEntry, projectLinkInfo };
        }, logEntryList);
      }
    }

    const height = 560;
    return (
      <Modal size="big" onClose={this.handleClose}>
        {Boolean(instanceName) && (
          <div className="flex-row flex-center-align" style={{ height: 40, padding: '0px 20px' }}>
            <div>
              <div style={{ fontWeight: 'bold', paddingRight: 16 }}>Instance: </div>
              <div>{instanceName}</div>
            </div>
          </div>
        )}
        {titleMsg && (
          <div className="flex-row" style={{ padding: '10px 20px 0 20px', fontSize: 12 }}>
            <div>{titleMsg}</div>
          </div>
        )}
        <div className="flex-grow" style={{ paddingBottom: 8, height, width: '100%' }}>
          <EventGroup
            name=""
            eventDataset={logEntryList}
            showFE={false}
            showTimeRange={showTimeRange}
            highlightWord={highlightWord}
            hasType={hasType}
            hasNid
            hasContext={hasContext}
            isCritical={isCritical}
            showLinkedJump={showLinkedJump}
          />
        </div>
      </Modal>
    );
  }
}

const LogEntryListModal = injectIntl(LogEntryListModalCore);
export default connect((state: State) => {
  const { location } = state.router;
  const { loadStatus, projects } = state.app;

  return {
    location,
    loadStatus,
    projects,
  };
}, {})(LogEntryListModal);
