import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import * as CryptoJS from 'crypto-js';
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Spin } from 'antd';
import { get } from 'lodash';

import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { InboxOutlined } from '@ant-design/icons';
import { Regex, sleep } from '../../../../common/utils';
import { AutoSizer, Column, Container, Table } from '../../../../lib/fui/react';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';

import { settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages } from '../../../../common/app/messages';
import { eventActionMessages } from '../../../../common/metric/messages';

import WebhookHeaderModal from './WebhookHeaderModal';

const { Item } = Form;

type Props = {
  intl: Object,
  credentials: Object,
  systemsMap: Object,

  projectSettings: Object,
  projectName: String,
  currentProject: Object,
  saveProjectSettings: Function,
  settingsLoader: String,
  userInfo: Object,
};

class WebhookSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { currentProject } = props;

    const selectAllOption = ['metric', 'log', 'predictedIncident', 'detectedIncident', 'detectedIncidentWithRC'];
    let webhookTypeSetStr = get(props, ['projectSettings', 'webhookTypeSetStr'], []);
    if (!webhookTypeSetStr || (webhookTypeSetStr || []).length === 0) {
      webhookTypeSetStr = selectAllOption;
    }

    this.state = {
      isLoading: false,
      systemId: currentProject.systemId,

      webhookUrl: get(props, ['projectSettings', 'webhookUrl'], ''),
      proxy: get(props, ['projectSettings', 'proxy'], ''),
      webhookAlertDampening: get(props, ['projectSettings', 'webhookAlertDampening'], 0) / 60000,
      maxWebHookRequestSize: get(props, ['projectSettings', 'maxWebHookRequestSize'], 1),
      webhookHeaderList: get(props, ['projectSettings', 'webhookHeaderList'], []),
      webhookTypeSetStr,
      webhookBlackListSetStr: get(props, ['projectSettings', 'webhookBlackListSetStr'], []),
      webhookCriticalKeywordSetStr: get(props, ['projectSettings', 'webhookCriticalKeywordSetStr'], []),

      showWebhookHeaderModal: false,
      isWebhookRemoveCheckedAll: false,
      oldValMap: {
        webhookUrl: get(props, ['projectSettings', 'webhookUrl'], ''),
        proxy: get(props, ['projectSettings', 'proxy'], ''),
        webhookAlertDampening: get(props, ['projectSettings', 'webhookAlertDampening'], 0) / 60000,
        maxWebHookRequestSize: get(props, ['projectSettings', 'maxWebHookRequestSize'], 1),
        webhookHeaderList: get(props, ['projectSettings', 'webhookHeaderList'], []),
        webhookTypeSetStr,
        webhookBlackListSetStr: get(props, ['projectSettings', 'webhookBlackListSetStr'], []),
        webhookCriticalKeywordSetStr: get(props, ['projectSettings', 'webhookCriticalKeywordSetStr'], []),
      },
    };

    this.webhookTypeSetStrOption = [
      { label: 'Metric anomalies', value: 'metric' },
      { label: 'Log anomalies', value: 'log' },
      { label: 'Predicted incidents', value: 'predictedIncident' },
      { label: 'Detected incidents', value: 'detectedIncident' },
      { label: 'Detected incidents with root causes', value: 'detectedIncidentWithRC' },
    ];
    this.selectAllOption = selectAllOption;
    this.settingFieldList = [
      'webhookUrl',
      'proxy',
      'webhookAlertDampening',
      'maxWebHookRequestSize',
      'webhookHeaderList',
      'webhookTypeSetStr',
      'webhookBlackListSetStr',
      'webhookCriticalKeywordSetStr',
    ];
    this.localSystemId = currentProject.systemId;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.projectSettings !== this.props.projectSettings) {
      const newState = {};
      let changed = false;

      const projectSettings = nextProps.projectSettings || {};
      const nextPropsFields = this.getSettingFields(projectSettings, this.settingFieldList);
      const statesFields = this.getSettingFields(this.state, this.settingFieldList);
      R.forEachObjIndexed((value, key) => {
        if (!R.identical(value, get(statesFields, key, ''))) {
          newState[key] = value;
          changed = true;
        } else if (key === 'webhookTypeSetStr') {
          newState[key] = value;
          changed = true;
        }
      }, nextPropsFields);

      if (changed) {
        const selectAllOption = ['metric', 'log', 'predictedIncident', 'detectedIncident', 'detectedIncidentWithRC'];
        let webhookTypeSetStr = get(projectSettings, ['webhookTypeSetStr'], []);
        if (!webhookTypeSetStr || (webhookTypeSetStr || []).length === 0) {
          webhookTypeSetStr = selectAllOption;
        }
        const oldValMap = {
          webhookUrl: get(projectSettings, ['webhookUrl'], ''),
          proxy: get(projectSettings, ['proxy'], ''),
          webhookAlertDampening: get(projectSettings, ['webhookAlertDampening'], 0) / 60000,
          maxWebHookRequestSize: get(projectSettings, ['maxWebHookRequestSize'], 1),
          webhookHeaderList: get(projectSettings, ['webhookHeaderList'], []),
          webhookTypeSetStr,
          webhookBlackListSetStr: get(projectSettings, ['webhookBlackListSetStr'], []),
          webhookCriticalKeywordSetStr: get(projectSettings, ['webhookCriticalKeywordSetStr'], []),
        };
        newState.oldValMap = oldValMap;
        this.setState(newState);
      }
    }
  }

  @autobind
  getSettingFields(props, fieldList) {
    const settingVals = {};
    R.forEach((f) => {
      if (f === 'webhookAlertDampening') {
        settingVals[f] = get(props, f, 0) / 60000;
      } else if (f === 'webhookTypeSetStr') {
        let webhookTypeSetStr = get(props, f, []);
        if (!webhookTypeSetStr || (webhookTypeSetStr || []).length === 0) {
          webhookTypeSetStr = this.selectAllOption;
        }
        settingVals[f] = webhookTypeSetStr;
      } else {
        settingVals[f] = get(props, f, '');
      }
    }, fieldList);
    return settingVals;
  }

  @autobind
  groupSettings() {
    const { webhookUrl, webhookHeaderList, proxy, webhookAlertDampening, maxWebHookRequestSize } = this.state;
    const { webhookTypeSetStr, webhookBlackListSetStr, webhookCriticalKeywordSetStr } = this.state;
    const newWebhookHeaderList = R.map((i) => ({ key: i.key, value: i.value }), webhookHeaderList);
    return {
      webhookUrl,
      webhookHeaderList: newWebhookHeaderList,
      proxy,
      webhookAlertDampening,
      maxWebHookRequestSize,
      webhookTypeSetStr,
      webhookBlackListSetStr,
      webhookCriticalKeywordSetStr,
    };
  }

  @autobind
  async handleSaveClick() {
    const { credentials, projectName, currentProject, systemsMap, saveProjectSettings, settingsLoader, userInfo } =
      this.props;
    const { oldValMap, systemId } = this.state;
    const { isMetric, projectShortName, owner } = currentProject;

    let settings = this.groupSettings();

    if (settings.webhookAlertDampening) {
      settings.webhookAlertDampening *= 60000;
    }

    if (!isMetric) {
      settings = R.pick(
        [
          'webhookUrl',
          'webhookHeaderList',
          'proxy',
          'webhookAlertDampening',
          'maxWebHookRequestSize',
          'webhookTypeSetStr',
          'webhookBlackListSetStr',
          'webhookCriticalKeywordSetStr',
        ],
        settings,
      );
    }

    const systemInfo = systemsMap[systemId === 'emptySystem' ? currentProject.systemId : systemId];
    const hasSetData = { oldValMap, newValMap: settings };
    const requests = [
      saveProjectSettings(
        projectName.indexOf('@') >= 0 ? projectName : `${projectShortName}@${owner}`,
        { ...settings, hasSetData: JSON.stringify(hasSetData) },
        {
          [settingsLoader]: true,
        },
      ),
    ];
    if (systemInfo && this.localSystemId !== systemId) {
      const { systemName, owner } = systemInfo;
      const sessionToken = userInfo?.sessionToken;
      const systemInfoStr = JSON.stringify([
        {
          customerName: owner,
          systemName: systemId === 'emptySystem' ? currentProject.systemId : systemId,
          systemDisplayName: systemName,
          projectNameSet: [
            {
              projectName: projectName.split('@')[0],
              userName: projectName.split('@')[1] || credentials.userName,
            },
          ],
        },
      ]);
      const systemInfoHashed = CryptoJS.HmacMD5(systemInfoStr, sessionToken).toString();
      requests.push(
        fetchPost(
          getEndpoint('systemframework', 2),
          {
            ...credentials,
            customerName: credentials.userName,
            operation: systemId === 'emptySystem' ? 'removeProjectFromSystem' : 'addProjectToSystem',
            systemInfo: systemInfoStr,
            'systemInfo-hashed': systemInfoHashed,
          },
          {},
          false,
        ).catch((err) => {
          Modal.error({ content: 'Unfortunately, an error has occurred. Please try again!' });
        }),
      );
    }

    this.setState({ isLoading: true });
    await sleep(300);
    Promise.all(requests)
      .then((results) => {
        this.setState({ isLoading: false });
        if (this.localSystemId !== systemId) {
          window.location.reload();
        }
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        Modal.error({ content: 'Unfortunately, an error has occurred. Please try again!' });
      });
  }

  @autobind
  handleAddWebhookHeaderClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showWebhookHeaderModal: true });
  }

  @autobind
  handleWebhookHeaderRemove() {
    const { webhookHeaderList } = this.state;
    const newWebhookHeaderList = R.filter((item) => !item.isChecked, webhookHeaderList);
    this.setState({ webhookHeaderList: newWebhookHeaderList, isWebhookRemoveCheckedAll: false });
  }

  @autobind
  handleWebhookIsAllChecked({ target: { checked } }) {
    const { webhookHeaderList } = this.state;
    R.forEach((item) => {
      item.isChecked = checked;
    }, webhookHeaderList || []);

    this.setState({ isWebhookRemoveCheckedAll: checked });
  }

  @autobind
  removeWebhookCheckBoxRender({ rowData, rowIndex }) {
    const { isChecked } = rowData;
    const { webhookHeaderList } = this.state;

    return (
      <Checkbox
        checked={isChecked}
        onChange={(e) => {
          this.setState(
            {
              webhookHeaderList: update(webhookHeaderList, {
                [rowIndex]: { $set: { ...rowData, isChecked: e.target.checked } },
              }),
            },
            () => {
              const { webhookHeaderList } = this.state;
              this.setState({
                isWebhookRemoveCheckedAll: !(
                  R.find(R.propEq('isChecked', false), webhookHeaderList) ||
                  R.find(R.propEq('isChecked', undefined), webhookHeaderList)
                ),
              });
            },
          );
        }}
      />
    );
  }

  @autobind
  selectNoDataRender() {
    const { intl } = this.props;
    return (
      <div className="flex-col flex-center-align flex-center-justify font-16" style={{ height: 100 }}>
        <div>{intl.formatMessage(settingsMessages.manualEntryToAddNewContent)}</div>
        <div style={{ fontSize: 36, marginTop: 8 }}>
          <InboxOutlined />
        </div>
      </div>
    );
  }

  render() {
    const { intl } = this.props;
    const { webhookUrl, proxy, webhookAlertDampening, maxWebHookRequestSize, isLoading } = this.state;
    const { webhookHeaderList = [], showWebhookHeaderModal, isWebhookRemoveCheckedAll } = this.state;
    const { webhookTypeSetStr, webhookBlackListSetStr, webhookCriticalKeywordSetStr } = this.state;
    const { oldValMap } = this.state;

    const deleteWebhookHeaderList = R.filter((item) => item.isChecked, webhookHeaderList);

    const verifyWebhookUrl = !webhookUrl || Regex.url.test(webhookUrl);
    const verifyProxy = !proxy || Regex.url.test(proxy);
    const verifywebhookAlertDampening = !webhookAlertDampening || Number(webhookAlertDampening) >= 0;
    const verifymaxWebHookRequestSize = maxWebHookRequestSize || Number(maxWebHookRequestSize) >= 0;

    const settings = this.groupSettings();
    const btnDisabled = R.equals(oldValMap, settings);
    const hasError =
      !verifyWebhookUrl || !verifyProxy || !verifywebhookAlertDampening || !verifymaxWebHookRequestSize || btnDisabled;

    return (
      <Spin spinning={isLoading} wrapperClassName="full-width full-height pr-10 overflow-y-auto">
        <Form layout="vertical" wrapperCol={{ span: 24 }}>
          <Row wrap>
            <Col span={24}>
              <Item
                label={intl.formatMessage(settingsMessages.webhookUrl)}
                help={verifyWebhookUrl ? undefined : 'Url is incorrect'}
                validateStatus={verifyWebhookUrl ? 'success' : 'error'}
              >
                <Input
                  value={webhookUrl}
                  onChange={({ target: { value: webhookUrl } }) => this.setState({ webhookUrl })}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                label={intl.formatMessage(settingsMessages.proxy)}
                help={verifyProxy ? undefined : 'Url is incorrect'}
                validateStatus={verifyProxy ? 'success' : 'error'}
              >
                <Input value={proxy} onChange={({ target: { value: proxy } }) => this.setState({ proxy })} />
              </Item>
            </Col>
            <Col span={24}>
              <Item label={intl.formatMessage(eventActionMessages.emailAlertType)}>
                <Select
                  allowClear
                  mode="multiple"
                  value={webhookTypeSetStr}
                  options={this.webhookTypeSetStrOption}
                  onChange={(webhookTypeSetStr) => this.setState({ webhookTypeSetStr })}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                label={intl.formatMessage(settingsMessages.webhookDampeningPeriod)}
                help={verifywebhookAlertDampening ? undefined : 'Please input a number greater than 0'}
                validateStatus={verifywebhookAlertDampening ? 'success' : 'error'}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={1}
                  value={webhookAlertDampening}
                  onChange={(webhookAlertDampening) => this.setState({ webhookAlertDampening })}
                  precision={0}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item
                label={intl.formatMessage(settingsMessages.maximumPayloadSize)}
                help={verifymaxWebHookRequestSize ? undefined : 'Please input a number greater than 0'}
                validateStatus={verifymaxWebHookRequestSize ? 'success' : 'error'}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  min={1}
                  value={maxWebHookRequestSize}
                  onChange={(maxWebHookRequestSize) => this.setState({ maxWebHookRequestSize })}
                  precision={0}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item label={intl.formatMessage(settingsMessages.characterBlacklistSetting)}>
                <Select
                  allowClear
                  mode="tags"
                  value={webhookBlackListSetStr}
                  options={[]}
                  notFoundContent={this.selectNoDataRender()}
                  onChange={(webhookBlackListSetStr) => this.setState({ webhookBlackListSetStr })}
                />
              </Item>
            </Col>
            <Col span={24}>
              <Item label={intl.formatMessage(settingsMessages.criticalKeywordSetting)}>
                <Select
                  allowClear
                  mode="tags"
                  value={webhookCriticalKeywordSetStr}
                  options={[]}
                  notFoundContent={this.selectNoDataRender()}
                  onChange={(webhookCriticalKeywordSetStr) => this.setState({ webhookCriticalKeywordSetStr })}
                />
              </Item>
            </Col>
            <Col style={{ marginBottom: 50 }} span={24}>
              <Item label={intl.formatMessage(settingsMessages.webhookHeaders)}>
                <div className="flex-row" style={{ marginBottom: 12 }}>
                  <Button
                    type="primary"
                    onClick={this.handleAddWebhookHeaderClick}
                    style={{ marginBottom: 10 }}
                    size="small"
                  >
                    {intl.formatMessage(appButtonsMessages.add)}
                  </Button>
                  <div className="flex-grow" />
                  <Button
                    type="primary"
                    disabled={deleteWebhookHeaderList.length === 0}
                    onClick={this.handleWebhookHeaderRemove}
                    size="small"
                  >
                    {intl.formatMessage(appButtonsMessages.delete)}
                  </Button>
                  {showWebhookHeaderModal && (
                    <WebhookHeaderModal
                      intl={intl}
                      webhookHeaderList={webhookHeaderList}
                      saveWebhookHeaderList={(webhookHeaderList) =>
                        this.setState({ webhookHeaderList, showWebhookHeaderModal: false })
                      }
                      onClose={() => {
                        this.setState({ showWebhookHeaderModal: false });
                      }}
                    />
                  )}
                </div>
                <Container className="flex-grow flex-col">
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <Table
                        className="with-border"
                        width={width}
                        height={200}
                        headerHeight={40}
                        rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                        rowHeight={40}
                        rowCount={webhookHeaderList.length}
                        rowGetter={({ index }) => webhookHeaderList[index]}
                      >
                        <Column width={150} label="Header key" dataKey="key" />
                        <Column width={280} label="Header value" dataKey="value" flexGrow={1} />
                        <Column
                          width={45}
                          label=""
                          dataKey="key"
                          flexShrink={1}
                          headerRenderer={() => (
                            <Checkbox checked={isWebhookRemoveCheckedAll} onChange={this.handleWebhookIsAllChecked} />
                          )}
                          cellRenderer={this.removeWebhookCheckBoxRender}
                        />
                      </Table>
                    )}
                  </AutoSizer>
                </Container>
              </Item>
            </Col>
          </Row>
        </Form>
        <div className="flex-row flex-end-justify" style={{ position: 'fixed', right: 50, bottom: 50 }}>
          <Button
            onClick={this.handleSaveClick}
            size="small"
            disabled={hasError}
            type="primary"
            style={{ width: 110 }}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.update)}
          </Button>
        </div>
      </Spin>
    );
  }
}

const WebhookSetting = injectIntl(WebhookSettingCore);
export default connect((state: State) => {
  const { credentials, userInfo } = state.auth;
  const { systemsMap } = state.app;
  const { isAdmin, isLocalAdmin } = userInfo || {};
  const { projectSettings } = state.settings;

  return {
    credentials,
    userInfo,
    isAdmin,
    isLocalAdmin,
    systemsMap,

    projectSettings,
  };
}, {})(WebhookSetting);
