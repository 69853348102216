import React from 'react';
import { Button } from 'antd';
import { SnippetsOutlined } from '@ant-design/icons';

export default function CopyTextContent({ content = '' }: Object) {
  return (
    <Button
      type="link"
      icon={<SnippetsOutlined style={{ color: 'var(--link-color)' }} />}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.handleCopyClick(content);
      }}
    />
  );
}
