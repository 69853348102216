/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import { purgeStoredState } from 'redux-persist';

import { ActionTypes, clearCredentials } from '../actions';
import { setCollapsed } from '../../app/actions';
import { logout } from '../../apis';

const logoffLogic = createLogic({
  type: [ActionTypes.LOGOFF],
  latest: true,
  process: ({ getState, action, storageEngine }, dispatch, done) => {
    // Purge all stored state and then reload the page.
    const state = getState();
    const { credentials, loginRedirectURL } = action.payload;
    const { appName } = state.app;

    // clear tokenExpiredTime
    window.localStorage.removeItem('tokenExpiredTime');
    window.localStorage.removeItem('refreshTokenFnTime');

    // call log off api
    logout(credentials, {})
      .then(async () => {
        await purgeStoredState({
          storage: storageEngine,
          keyPrefix: `${appName}:auth`,
        });
        if (loginRedirectURL) window.sessionStorage.setItem('loginRedirectURL', loginRedirectURL);

        dispatch(clearCredentials());
        dispatch(setCollapsed(false));
      })
      .catch(async () => {
        await purgeStoredState({
          storage: storageEngine,
          keyPrefix: `${appName}:auth`,
        });
        if (loginRedirectURL) window.sessionStorage.setItem('loginRedirectURL', loginRedirectURL);

        dispatch(clearCredentials());
        dispatch(setCollapsed(false));
      })
      .then(() => {
        const urlRex = 'https://sandbox.insightfinder.com';
        const url = window.BASE_URL || window.location.origin || '';
        window.location.href = url === urlRex ? '/auth/sandbox-signup' : '/';
        done();
      });
  },
});

export default logoffLogic;
