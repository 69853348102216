/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const deleteProject = (credentials: Credentials, params: Object) => {
  return fetchPost(getEndpoint('remove-project'), {
    ...credentials,
    ...params,
  }).then((data) => {
    return data;
  });
};

export default deleteProject;
