/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import * as R from 'ramda';
import { isString } from 'lodash';
import moment from 'moment';

const parseJSON = (str) => {
  let ret = str;
  if (isString(str)) {
    try {
      ret = JSON.parse(str);
    } catch (ex) {
      ret = str;
    }
  }
  return ret;
};

const parseIncidentList = (incidentResult, params) => {
  let eventList = [];

  R.forEachObjIndexed((val, key) => {
    const value = parseJSON(val);
    let events = R.map((event) => {
      const { incidentData, incidentKey, predictionFlag, patternId, incidentKeyword } = event;
      const { incidentCompositeKey, timestamp } = incidentKey || {};
      const { id, projectName, userName } = incidentCompositeKey || {};
      const startTimestamp = moment.utc(timestamp).valueOf();

      let predictionSourceInfo = parseJSON(event.predictionSourceInfo);
      if (predictionSourceInfo) {
        const { sourceDetail, ...rest } = predictionSourceInfo;
        predictionSourceInfo = {
          sourceDetail: parseJSON(sourceDetail),
          ...rest,
        };
      }

      return {
        ...event,
        key,
        incidentData,
        incidentKey,
        projectName,
        userName,
        user: userName,
        isIncidentEvent: true,
        isLog: true,
        isPrediction: !!predictionFlag,
        predictionSourceInfo,
        type: 'incident',
        patternName: incidentKeyword || String(patternId),
        patternId,
        neuronId: patternId,
        anomalyRatio: 1,
        timestamp: startTimestamp,
        startTimestamp,
        endTimestamp: startTimestamp,
        instanceName: id,
        anomalyScoreList: [],
        rootCauseJson: { rawData: incidentData },
      };
    }, value || []);
    events = R.map((event) => {
      return { ...event, timeLineEvents: [event] };
    }, events);

    eventList = [...eventList, ...events];
  }, incidentResult || {});

  eventList = R.sort((a, b) => b.timestamp - a.timestamp, eventList);

  return eventList;
};

export default parseIncidentList;
