/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import * as R from 'ramda';
import { get } from 'lodash';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getGlobalSystemHealth } from '../../apis';
import { appMessages } from '../../app/messages';

const loadGlobalSystemHealthLogic = createLogic({
  type: [ActionTypes.LOAD_GLOBAL_SYSTEM_HEALTH],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { environmentId } = params;
    const { globalInfo } = state.app;

    // Get the system id for the environment
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    const environmentName = get(environment, 'name');
    const systemIds = get(environment, 'systemIds', []);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    getGlobalSystemHealth(credentials, { ...params, environmentName, systemIds })
      .then((d) => {
        dispatch(createSetAction(ActionTypes.SET_GLOBAL_SYSTEM_HEALTH, params, d));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get global system health', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        done();
      });
  },
});

export default loadGlobalSystemHealthLogic;
