/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React, { useState } from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Spin, message, DatePicker } from 'antd';

import fetchDelete from '../../../../common/apis/fetchDelete';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { parseLocation } from '../../../../common/utils';
import {
  Container,
  Table,
  Column,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Modal,
  Popover,
} from '../../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo } from '../../../../common/app/actions';
import { ActionTypes } from '../../../../common/settings/actions';

import { appFieldsMessages, appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

import ModelTileList from './ModelTileList';
import ModelExportModal from './ModelExportModal';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  credentials: Object,
  userInfo: Object,
  updateLastActionInfo: Function,
  currentLoadingComponents: Object,
  projectSettingsParams: Object,
  projectName: String,
  refreshTime: Number,
  instanceGroup: String,
  // eslint-disable-next-line
  data: Object,
  setting: String,
  projects: Array<Object>,
  pickProjectModel: Function,
  exportProjectModel: Function,

  models: Array<Object>,
  holidaymodels: Array<Object>,
};

class ModelSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.stateKey = props.setting === 'model' ? 'models' : 'holidaymodels';

    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.selectedModel = null;
    this.modelMetrics = [];
    this.selectionIsPicked = false;
    this.state = {
      isLoading: true,
      models: [],

      metricInputValue: '',
      metricInputText: '',
      selectedModelKey: '',
      showExportModal: false,
      showModelDeleteModal: false,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const params = parseLocation(this.props.location);
    const nextParams = parseLocation(nextProps.location);
    if (this.props.refreshTime !== nextProps.refreshTime || params.instanceName !== nextParams.instanceName) {
      this.reloadData(nextProps);
    } else if (nextProps.models !== this.props.models || nextProps.holidaymodels !== this.props.holidaymodels) {
      this.parseDate(nextProps, this.state);
    }
  }

  @autobind
  reloadData(props) {
    const { location, projects, createLoadAction, setting, projectName } = props;
    const params = parseLocation(location);
    const project = R.find((p) => p.projectName === projectName, projects) || {};
    const dataType = get(project, 'dataType', '').toLowerCase();
    const { instanceGroup, instanceName, startTime, endTime } = params;
    if (
      projectName &&
      (dataType === 'metric' ? instanceGroup : instanceName || true) &&
      (setting === 'model' ? startTime && endTime : true)
    ) {
      this.setState({ isLoading: true });
      createLoadAction(
        ActionTypes.LOAD_PROJECT_MODELS,
        {
          setting,
          projectName,
          instanceGroup,
          instanceName,
          startTime,
          endTime,
          isHoliday: setting === 'holidaymodel',
        },
        false,
        false,
        this.callbackSetState,
      );
    }
  }

  @autobind
  callbackSetState() {
    this.setState({ isLoading: false });
  }

  @autobind
  parseDate(props, state) {
    const models = get(props, this.stateKey, []);
    const isEmpty = models.length === 0;

    // Get the select model to show on the left side, if no selection, get the first picked model
    // or the first model.
    const { selectedModelKey, metricInputText } = state;
    let selectedModel = null;
    let selectionIsPicked = false;
    if (!isEmpty) {
      selectedModel = R.find((m) => m.modelKey === selectedModelKey, models);
      if (!selectedModel) {
        selectedModel = R.find((m) => m.picked, models) || models[0];
      }
      selectionIsPicked = selectedModel.picked;
    }
    this.selectionIsPicked = selectionIsPicked;
    if (selectedModel) {
      this.selectedModel = selectedModel;
      this.modelMetrics = selectedModel.metrics;
    }

    if (metricInputText) {
      this.modelMetrics = R.filter(
        (m) => R.toLower(m.name).indexOf(R.toLower(metricInputText)) >= 0,
        this.modelMetrics,
      );
    }

    this.cellMeasureCache.clearAll();
    if (this.dataTable) {
      this.dataTable.forceUpdate();
      this.dataTable.forceUpdateGrid();
    }

    this.setState({ models });
  }

  @autobind
  handleModelSelect(key) {
    this.parseDate(this.props, {
      ...this.state,
      selectedModelKey: key,
      metricInputValue: '',
      metricInputText: '',
    });
    this.setState({
      selectedModelKey: key,
      metricInputValue: '',
      metricInputText: '',
    });
  }

  @autobind
  handleModelExport(key) {
    this.parseDate(this.props, { ...this.state, selectedModelKey: key });
    this.setState({
      selectedModelKey: key,
      showExportModal: true,
    });
  }

  @autobind
  contentRender(props) {
    const { dataKey, parent, rowIndex, cellData } = props;
    return (
      <CellMeasurer cache={this.cellMeasureCache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ wordBreak: 'break-all' }}>{cellData}</div>
      </CellMeasurer>
    );
  }

  @autobind()
  handleMetricInputTextChange(e) {
    this.setState({ metricInputValue: e.target.value || '' });
  }

  @autobind()
  handleMetricInputSearch(e) {
    const { metricInputValue } = this.state;
    this.parseDate(this.props, { ...this.state, metricInputText: metricInputValue });
    this.setState({ metricInputText: metricInputValue });
  }

  @autobind
  handleModelPick(modelKey) {
    // projectSettingsParams is used for reload
    const { projectName, instanceGroup, pickProjectModel, projectSettingsParams } = this.props;
    pickProjectModel(projectName, instanceGroup, modelKey, projectSettingsParams);
  }

  @autobind
  handleModelRemove(modelKey) {
    // projectSettingsParams is used for reload
    const { credentials, projectName } = this.props;
    const { models } = this.state;
    const model = R.find((m) => m.modelKey === modelKey, models);
    if (model) {
      const params = parseLocation(this.props.location);
      const { instanceGroup, instanceName } = params;
      const { startTimestamp, endTimestamp } = model;
      const modelKeyObj = { startTimestamp, endTimestamp, modelKey };

      this.setState({ isLoading: true });
      this.props.updateLastActionInfo();
      fetchDelete(
        getEndpoint('modelPicking'),
        {
          ...credentials,
          operation: 'single',
          projectName,
          instanceGroup,
          instanceName,

          modelKeyObj: JSON.stringify(modelKeyObj),
          startTime: startTimestamp,
          endTime: endTimestamp,
        },
        {},
        false,
      )
        .then((resp) => {
          message.success('Model deletion successful.');
          this.reloadData(this.props);
        })
        .catch((err) => {
          message.error('Model deletion failed.');
          this.setState({ isLoading: false });
        });
    }
  }

  @autobind
  showDeleteConfirm() {
    this.setState({
      showModelDeleteModal: true,
    });
  }

  @autobind
  dataRender({ dataKey, parent, rowIndex, cellData }) {
    return (
      <Popover content={cellData} placement="right" mouseEnterDelay={0.3}>
        <div className="hidden-line-with-ellipsis inline-block max-width">{cellData}</div>
      </Popover>
    );
  }

  render() {
    const { intl, updateLastActionInfo, credentials, userInfo, projectName, instanceGroup, projects } = this.props;
    const { setting, exportProjectModel, currentLoadingComponents } = this.props;
    const { isLoading, models, metricInputValue, selectedModelKey } = this.state;
    const params = parseLocation(this.props.location);
    const { instanceName } = params;

    const isEmpty = models.length === 0;
    const isHolidayModel = setting === 'holidaymodel';

    const { selectedModel } = this;
    const { modelMetrics } = this;
    // const { selectionIsPicked } = this;

    const hasError = isEmpty;

    const project = R.find((p) => p.projectName === projectName, projects) || {};
    const dataType = get(project, 'dataType', '').toLowerCase();
    return (
      <Container fullHeight className="model-settings">
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-width">
          {hasError && (
            <Container fullHeight style={{ width: '100%', fontSize: 12 }}>
              <div
                className="ui error message"
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(
                    isHolidayModel ? settingsMessages.errorNoProjectHolidayModel : settingsMessages.errorNoProjectModel,
                    { projectName },
                  ),
                }}
              />
            </Container>
          )}

          {!hasError && (
            <div className="flex-row">
              <Container className="flex-col" style={{ width: 500, padding: '0 6px 0 0' }}>
                <div className="flex-row" style={{ padding: '0 0 4px 0' }}>
                  <input
                    value={metricInputValue}
                    style={{ width: 250 }}
                    className="ui input"
                    placeholder={intl.formatMessage(appFieldsMessages.metric)}
                    onChange={this.handleMetricInputTextChange}
                  />
                  <div style={{ lineHeight: '26px' }}>
                    <i className="icon search" style={{ cursor: 'pointer' }} onClick={this.handleMetricInputSearch} />
                  </div>
                </div>
                <Container className="flex-grow">
                  {modelMetrics.length > 0 && (
                    <AutoSizer>
                      {({ width, height }) => (
                        <Table
                          className="with-border"
                          width={width}
                          height={height}
                          deferredMeasurementCache={this.cellMeasureCache}
                          headerHeight={28}
                          rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                          rowHeight={this.cellMeasureCache.rowHeight}
                          rowCount={modelMetrics.length}
                          rowGetter={({ index }) => modelMetrics[index]}
                          ref={(table) => {
                            this.dataTable = table;
                          }}
                        >
                          <Column
                            width={260}
                            className="raw-data"
                            flexGrow={1}
                            label={intl.formatMessage(appFieldsMessages.metric)}
                            dataKey="name"
                            cellRenderer={this.contentRender}
                          />
                          {dataType === 'metric' && this.selectedModel.hasMetricOrganizer && (
                            <Column
                              width={200}
                              label={intl.formatMessage(appFieldsMessages.instance)}
                              dataKey="instanceName"
                              cellRenderer={this.dataRender}
                            />
                          )}
                          {dataType === 'metric' && this.selectedModel.hasMetricOrganizer && (
                            <Column
                              width={100}
                              label={intl.formatMessage(appFieldsMessages.group)}
                              dataKey="group"
                              cellRenderer={this.dataRender}
                            />
                          )}
                          <Column
                            width={100}
                            label={intl.formatMessage(appFieldsMessages.max)}
                            dataKey="max"
                            cellRenderer={this.dataRender}
                          />
                          <Column
                            width={100}
                            label={intl.formatMessage(appFieldsMessages.min)}
                            dataKey="min"
                            cellRenderer={this.dataRender}
                          />
                        </Table>
                      )}
                    </AutoSizer>
                  )}
                </Container>
              </Container>
              <Container className="flex-grow flex-min-width flex-col">
                <div className="flex-row" style={{ marginBottom: 8 }}>
                  <div style={{ fontSize: 12 }}>
                    <i className="icon circle info" />
                    <span>{intl.formatMessage(settingsMessages.modelDesc)}</span>
                  </div>
                  <div style={{ margin: '0 0 0 16px' }}>
                    {userInfo.isAdmin && (
                      <Button type="danger" size="small" onClick={this.showDeleteConfirm}>
                        {intl.formatMessage(appButtonsMessages.delete)}
                      </Button>
                    )}
                  </div>
                </div>
                <div className="flex-grow flex-min-height">
                  <AutoSizer>
                    {({ width, height }) => (
                      <ModelTileList
                        intl={intl}
                        width={width}
                        height={height}
                        modelList={models}
                        projects={projects}
                        credentials={credentials}
                        userInfo={userInfo}
                        projectName={projectName}
                        instanceGroup={instanceGroup}
                        onModelSelect={this.handleModelSelect}
                        onModelRemove={this.handleModelRemove}
                        onModelPick={this.handleModelPick}
                        onModelExport={this.handleModelExport}
                        selectedModelKey={selectedModelKey}
                      />
                    )}
                  </AutoSizer>
                </div>
              </Container>
            </div>
          )}
        </Spin>

        {this.state.showExportModal && (
          <ModelExportModal
            currentLoadingComponents={currentLoadingComponents}
            projects={projects}
            projectName={projectName}
            instanceGroup={instanceGroup}
            modelKey={selectedModelKey}
            seasonType={selectedModel.seasonType}
            exportProjectModel={exportProjectModel}
            onClose={() => this.setState({ showExportModal: false })}
          />
        )}
        {this.state.showModelDeleteModal && (
          <ModelDeleteRange
            intl={intl}
            updateLastActionInfo={updateLastActionInfo}
            credentials={credentials}
            projectName={projectName}
            instanceName={instanceName}
            onClose={(reload) =>
              this.setState({ showModelDeleteModal: false }, () => {
                if (reload) {
                  this.reloadData(this.props);
                }
              })
            }
          />
        )}
      </Container>
    );
  }
}

const ModelDeleteRange = ({ intl, updateLastActionInfo, credentials, projectName, instanceName, onClose }: Object) => {
  const [isLoading, setLoading] = useState(false);
  const [startTimeObj, setStartTime] = useState(moment.utc().startOf('day'));
  const [endTimeObj, setEndTime] = useState(moment.utc().endOf('day'));

  const hasError =
    isLoading ||
    !startTimeObj ||
    !endTimeObj ||
    (startTimeObj && endTimeObj && startTimeObj.valueOf() > endTimeObj.valueOf());
  const handleOk = (event) => {
    setLoading(true);
    const startTime = startTimeObj.startOf('day').valueOf();
    const endTime = endTimeObj.endOf('day').valueOf();
    updateLastActionInfo();
    fetchDelete(
      getEndpoint('modelPicking'),
      {
        ...credentials,
        operation: 'batch',
        projectName,
        instanceName,
        startTime,
        endTime,
      },
      {},
      false,
    )
      .then((resp) => {
        message.success('Model deletion successful.');
        onClose(true);
      })
      .catch((err) => {
        message.error('Model deletion failed.');
        setLoading(false);
      });
  };
  return (
    <Modal
      title={intl.formatMessage(appFieldsMessages.dateRange)}
      visible
      onCancel={onClose}
      onOk={handleOk}
      okButtonProps={{ disabled: hasError }}
      maskClosable={false}
    >
      <Spin spinning={isLoading}>
        <div className="flex-row flex-center-align content" style={{ fontSize: 12, height: 28 }}>
          <span className="label" style={{ width: 80, fontWeight: 'bold' }}>
            {intl.formatMessage(appFieldsMessages.startDate)}:
          </span>
          <DatePicker
            size="small"
            allowClear={false}
            showToday
            value={startTimeObj}
            disabledDate={(current) => {
              return current && current > moment.utc().add(1, 'days').endOf('day');
            }}
            onChange={setStartTime}
          />
          <span className="label" style={{ width: 80, fontWeight: 'bold', marginLeft: 20 }}>
            {intl.formatMessage(appFieldsMessages.endDate)}:
          </span>
          <DatePicker
            size="small"
            allowClear={false}
            showToday
            value={endTimeObj}
            disabledDate={(current) => {
              return current && current > moment.utc().add(1, 'days').endOf('day');
            }}
            onChange={setEndTime}
          />
        </div>
      </Spin>
    </Modal>
  );
};

const ModelSetting = injectIntl(ModelSettingCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { models, holidaymodels } = state.settings;
    const { userInfo } = state.auth;

    return { location, loadStatus, userInfo, models, holidaymodels };
  },
  { createLoadAction, updateLastActionInfo },
)(ModelSetting);
