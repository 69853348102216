import React, { useEffect, useReducer } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import { get } from 'lodash';
import { Alert, Button, Popconfirm, Spin, Tabs, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, GlobalRenderers, parseJSON } from '../../../common/utils';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Modal, Popover } from '../../../lib/fui/react';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventActionMessages, eventMessages } from '../../../common/metric/messages';
import { logMessages } from '../../../common/log/messages';
import NewTriageReportModal from './NewTriageReportModal';
import fetchPost from '../../../common/apis/fetchPost';

const OperatorNotes = (props: Object) => {
  const { intl, isReadUser, incident, credentials, updateLastActionInfo, instanceDisplayNameMap } = props || {};
  const { timezoneOffset } = props || {};
  const { id, projectOwner, projectName, anomalyLogInstance, instanceList, patternId } = incident || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    triageReportList: [],
    loading: false,
    showAddTriageReportModal: false,
    activeEvent: null,
  });
  const { triageReportList, loading, showAddTriageReportModal, activeEvent } = state;

  const reloadDetectedIncident = async () => {
    setState({ loading: true });

    const request = [
      fetchGet(getEndpoint('logpatterntriage'), {
        ...credentials,
        customerName: projectOwner,
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        patternId,
      }),
    ];

    updateLastActionInfo();
    await Promise.all(request)
      .then((results) => {
        let triageReportList = [];
        triageReportList = parseJSON(get(results[0], 'triageReportList')) || [];

        triageReportList = R.map((item) => {
          const { instanceName, triageReport } = item;
          const { timestamp, fieldName, report, triageReporterManagement, reportSource } = triageReport;
          const key = `${instanceName}-${timestamp}-${fieldName}-${report}`;

          let reporterRecordSet = [];
          const { reporterMap } = triageReporterManagement || {};
          const { create, edit } = reporterMap || {};
          R.forEachObjIndexed((val, owner) => {
            const { reporterName, reportTimestampSet } = val || {};
            R.forEach((timestamp) => {
              reporterRecordSet.push({ reporterName, timestamp, action: 'create' });
            }, reportTimestampSet || []);
            reporterRecordSet.push();
          }, create?.reporterDetailMap || {});
          R.forEachObjIndexed((val, owner) => {
            const { reporterName, reportTimestampSet } = val || {};
            R.forEach((timestamp) => {
              reporterRecordSet.push({ reporterName, timestamp, action: 'edit' });
            }, reportTimestampSet || []);
            reporterRecordSet.push();
          }, edit?.reporterDetailMap || {});
          reporterRecordSet = R.sortWith([R.descend(R.prop('timestamp'))], reporterRecordSet);

          return {
            instanceName,

            key,
            timestamp,
            fieldName,
            report,
            triageReporterManagement,
            reporterRecordSet,
            reportSource,
          };
        }, triageReportList);
        triageReportList = R.sortWith([R.ascend(R.prop('timestamp'))], triageReportList);
        setState({ triageReportList, loading: false });
      })
      .catch((err) => {
        setState({ triageReportList: [], loading: false });
        message.error(err.message || String(err));
      });
  };

  useEffect(() => {
    reloadDetectedIncident();
  }, [id]);

  const handleTriageReportClick = () => {
    setState({ showAddTriageReportModal: true, activeEvent: undefined });
  };

  const handleTriageReportClose = (reload) => {
    setState({ showAddTriageReportModal: false });
    if (reload) reloadDetectedIncident();
  };

  const handleTriageReportEdit = (activeEvent) => {
    setState({ showAddTriageReportModal: true, activeEvent });
  };

  const handleTriageReportRemove = (rowData) => {
    const { key, instanceName } = rowData;
    const setTriageReportList = R.filter((item) => item.instanceName === instanceName, triageReportList);
    const rowIndex = R.findIndex((item) => item.key === key)(setTriageReportList);
    const newReportList = JSON.stringify(
      R.remove(
        rowIndex,
        1,
        R.map((item) => {
          const { timestamp, fieldName, report } = item;
          return { timestamp, fieldName, report };
        }, setTriageReportList),
      ),
    );

    setState({ loading: true });
    updateLastActionInfo();
    fetchPost(
      getEndpoint('logpatterntriage', 1),
      {
        ...credentials,
        customerName: projectOwner,
        projectName,
        patternId,
        instanceName,
        triageReportList: newReportList,
      },
      {},
      false,
    )
      .then((d) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        reloadDetectedIncident();
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        setState({ loading: false });
      });
  };

  return (
    <>
      <Spin spinning={loading} wrapperClassName="full-height spin-full-height">
        <div className="flex-col full-height">
          <div className="flex-row" style={{ marginBottom: 4, lineHeight: '28px' }}>
            <div className="flex-grow" style={{ paddingLeft: 8 }}>
              <Popover
                content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                mouseEnterDelay={0.3}
                placement="left"
              >
                <Button size="small" type="primary" disabled={isReadUser} onClick={() => handleTriageReportClick()}>
                  {intl.formatMessage(appButtonsMessages.add)}
                </Button>
              </Popover>
            </div>
          </div>
          {triageReportList.length === 0 && (
            <div style={{ marginBottom: 16 }}>
              <Alert message="" description={intl.formatMessage(logMessages.noTriageReport)} type="info" showIcon />
            </div>
          )}
          {triageReportList.length > 0 && (
            <div className="event-list flex-grow flex-col flex-min-height" style={{ marginBottom: 16 }}>
              <div className="event-list-header" style={{ height: 40, width: '100%' }}>
                <div className="header-column" style={{ width: 160 }}>
                  {intl.formatMessage(eventActionMessages.reportIncidentOccurrenceTime)}
                </div>
                <div className="header-column" style={{ width: 200 }}>
                  {intl.formatMessage(appFieldsMessages.instanceName)}
                </div>
                <div className="header-column" style={{ width: 120 }}>
                  {intl.formatMessage(eventActionMessages.reporterName)}
                </div>
                <div className="header-column" style={{ width: 120 }}>
                  {intl.formatMessage(appFieldsMessages.title)}
                </div>
                <div className="header-column" style={{ width: 150, flex: 1 }}>
                  {intl.formatMessage(eventActionMessages.triageReport)}
                </div>
                <div className="header-column" style={{ width: 180 }} />
              </div>
              <div className="event-list-grid flex-grow flex-min-height overflow-y-auto">
                {R.addIndex(R.map)((rowData, index) => {
                  const { instanceName, timestamp, fieldName, report, reporterRecordSet } = rowData;
                  const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName);
                  return (
                    <div
                      key={index}
                      className={`event-list-row${index % 2 === 1 ? ' odd-row' : ''}`}
                      style={{ minHeight: 50 }}
                    >
                      <div className="row-column" style={{ width: 160 }}>
                        {moment.utc(Number(timestamp)).format(Defaults.ShortDateTimeFormat)}
                      </div>
                      <div
                        className="row-column hidden-line-with-ellipsis-multiline"
                        style={{ width: 200, wordBreak: 'break-word', paddingRight: 8 }}
                      >
                        <Popover
                          content={
                            <div
                              style={{
                                maxWidth: 400,
                                wordBreak: 'break-word',
                                maxHeight: 300,
                                overflowY: 'auto',
                              }}
                            >
                              {instanceStr}
                            </div>
                          }
                          mouseEnterDelay={0.3}
                          placement="top"
                        >
                          <span className="clickable">{instanceStr}</span>
                        </Popover>
                      </div>
                      <div
                        className="row-column"
                        style={{ width: 120, flexWrap: 'wrap', maxHeight: 42, overflowY: 'auto' }}
                      >
                        <GlobalRenderers.RenderReporter
                          intl={intl}
                          timezoneOffset={timezoneOffset}
                          reporterRecordSet={reporterRecordSet || []}
                        />
                      </div>
                      <div
                        className="row-column hidden-line-with-ellipsis-multiline"
                        style={{ width: 120, wordBreak: 'break-word' }}
                      >
                        <Popover
                          content={
                            <div
                              style={{
                                maxWidth: 400,
                                wordBreak: 'break-word',
                                maxHeight: 300,
                                overflowY: 'auto',
                              }}
                            >
                              {fieldName}
                            </div>
                          }
                          mouseEnterDelay={0.3}
                          placement="top"
                        >
                          <span className="clickable">{fieldName}</span>
                        </Popover>
                      </div>
                      <div
                        className="row-column hidden-line-with-ellipsis-multiline"
                        style={{ width: 150, flex: 1, wordBreak: 'break-word' }}
                      >
                        <Popover
                          content={
                            <div
                              style={{
                                maxWidth: 400,
                                wordBreak: 'break-word',
                                maxHeight: 300,
                                overflowY: 'auto',
                              }}
                            >
                              {report}
                            </div>
                          }
                          mouseEnterDelay={0.3}
                          placement="top"
                        >
                          <span className="clickable">{report}</span>
                        </Popover>
                      </div>
                      <div className="row-column" style={{ width: 180 }}>
                        <Popover
                          content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                          mouseEnterDelay={0.3}
                          placement="left"
                        >
                          <Button
                            size="small"
                            type="primary"
                            disabled={isReadUser}
                            onClick={() => handleTriageReportEdit(rowData)}
                          >
                            <EditOutlined /> {intl.formatMessage(appButtonsMessages.edit)}
                          </Button>
                        </Popover>
                        <Popover
                          content={isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
                          mouseEnterDelay={0.3}
                          placement="left"
                        >
                          <Popconfirm
                            placement="topRight"
                            title={intl.formatMessage(appMessages.continueConfirm)}
                            onConfirm={() => handleTriageReportRemove(rowData)}
                            onCancel={(event) => event.stopPropagation()}
                            disabled={isReadUser}
                          >
                            <Button
                              size="small"
                              className="button-color-grey"
                              style={{ marginLeft: 8 }}
                              disabled={isReadUser}
                              onClick={(event) => event.stopPropagation()}
                            >
                              <DeleteOutlined /> {intl.formatMessage(appButtonsMessages.remove)}
                            </Button>
                          </Popconfirm>
                        </Popover>
                      </div>
                    </div>
                  );
                }, triageReportList)}
              </div>
            </div>
          )}
        </div>
      </Spin>
      {showAddTriageReportModal && (
        <NewTriageReportModal
          triageReportIncident={incident}
          triageReportList={triageReportList}
          activeEvent={activeEvent}
          onClose={handleTriageReportClose}
        />
      )}
    </>
  );
};

function ConfigNotesPredictionModalCore(props: Object) {
  const { intl, onClose } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    activeKey: 'operatorNotes',
  });
  const { activeKey } = state;

  return (
    <Modal
      visible
      title={intl.formatMessage(appFieldsMessages.Notes)}
      onCancel={() => onClose()}
      width={1300}
      bodyStyle={{ height: 500 }}
      footer={[
        <Button size="small" key="cancel" onClick={onClose}>
          {intl.formatMessage(appButtonsMessages.cancel)}
        </Button>,
      ]}
    >
      <Tabs
        type="card"
        className="full-height ant-tabs-content-full-height flex-col"
        activeKey={activeKey}
        onChange={(activeKey) => setState({ activeKey })}
      >
        <Tabs.TabPane tab={intl.formatMessage(eventActionMessages.triageReport)} key="operatorNotes">
          <OperatorNotes {...props} />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}

const ConfigNotesPredictionModal = injectIntl(ConfigNotesPredictionModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, timezoneOffset } = state.app;
    const { userInfo, credentials } = state.auth;
    const { isReadUser } = userInfo || {};
    return {
      location,
      loadStatus,
      timezoneOffset,
      userInfo,
      credentials,
      isReadUser,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(ConfigNotesPredictionModal);
