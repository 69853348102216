/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import update from 'immutability-helper';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { RightOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Alert, Input, InputNumber, Select, DatePicker, Checkbox, Divider, TreeSelect, Card } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { MetricParser } from '../../../../common/utils';
import { AutoSizer, Table, Column } from '../../../../lib/fui/react';

import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
};

class MicrosoftAzureSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;

    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      azureClientId: '',
      azureClientSecret: '',
      azureTenantId: '',
      subscriptionId: '',
      dataType: 'Metric',

      resources: [],
      selectedMetrics: [],
      workspaceId: null,
      logTables: [],

      samplingInterval: 5,
      samplingUnit: 60,
      historicalDates: [null, null],
      projectModelFlag: false,
      processPauseFlag: false,

      // options
      resourceOptions: [],
      resourceList: [],
      resourceMetricMap: {},
      worksapceOptions: [],
      worksapceTableMap: {},
      worksapceTableFieldMap: {},
    };

    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.dataTypeOption = [
      { label: 'Metric', value: 'Metric' },
      { label: 'Log', value: 'Log' },
    ];
    this.samplingUnitOption = [
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
  }

  componentDidMount() {
    this.props.saveProjectInfo('MicrosoftAzure', { dataType: this.state.dataType }, this.state);
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { intl, credentials } = this.props;
    const { azureClientId, azureClientSecret, azureTenantId, subscriptionId, dataType } = this.state;
    const projectCreationType = dataType === 'Metric' ? 'Azure_Metric' : 'Azure_Log';

    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType,
      azureClientId,
      azureClientSecret,
      azureTenantId,
      subscriptionId,
      dataType,
    })
      .then((data) => {
        const { success, message: errMsg, logInfos, metricInfos } = data || {};
        if (success === undefined || success) {
          if (dataType === 'Metric') {
            // parse metric
            this.parseDataMetric(metricInfos);
          } else {
            // parselog
            this.parseDataLog(logInfos);
          }

          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              samplingInterval: dataType !== 'Metric' ? 10 : 5,
            },
            () => {
              this.props.saveProjectInfo('MicrosoftAzure', { dataType }, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
            },
            () => {
              this.props.saveProjectInfo('MicrosoftAzure', { dataType }, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
          },
          () => {
            this.props.saveProjectInfo('MicrosoftAzure', { dataType }, this.state);
          },
        );
      });
  }

  @autobind
  parseDataMetric(metricInfos) {
    const resourceOptions = [];
    const resourceList = [];
    const resourceMetricMap = {};
    const metricResourceMap = {};
    R.forEach((metricInfo) => {
      const { resourceName, resourceId, metrics } = metricInfo;
      resourceOptions.push({ value: resourceId, label: resourceName });
      resourceList.push(resourceId);
      resourceMetricMap[resourceId] = metrics;
    }, metricInfos || []);
    R.forEachObjIndexed((metrics, resourceId) => {
      R.forEach((metric) => {
        metricResourceMap[metric] = resourceId;
      }, metrics);
    }, resourceMetricMap);

    this.setState({ resourceOptions, resourceList, resourceMetricMap, metricResourceMap });
  }

  @autobind
  parseDataLog(logInfos) {
    const worksapceOptions = [];
    const worksapceTableMap = {};
    const worksapceTableFieldMap = {};
    R.forEach((logInfo) => {
      const { logDetailMap, tables, workspaceName, workspaceId } = logInfo;
      worksapceOptions.push({ value: workspaceId, label: workspaceName });
      worksapceTableMap[workspaceId] = tables;
      worksapceTableFieldMap[workspaceId] = {};

      R.forEachObjIndexed((val) => {
        const { logDetail } = val || {};
        R.forEach((item) => {
          const { Type, ...rest } = item || {};
          const fields = R.keys(rest);
          if (!R.has(Type, worksapceTableFieldMap[workspaceId])) {
            worksapceTableFieldMap[workspaceId][Type] = fields;
          }
        }, logDetail || []);
      }, logDetailMap);
    }, logInfos || []);

    this.setState({ worksapceOptions, worksapceTableMap, worksapceTableFieldMap });
  }

  @autobind
  handleResourcesChange(resources) {
    this.setState({ resources }, () => {
      const { resourceMetricMap } = this.state;

      // build tree data
      let allMetrics = [];
      R.forEachObjIndexed((metrics) => {
        allMetrics = [...allMetrics, ...metrics];
      }, R.pick(resources, resourceMetricMap));
      const { treeData, treeDataKeys } = MetricParser.getMetricTreeStructure(allMetrics, false);
      this.setState({ treeData, treeDataKeys, selectedMetrics: [] });
    });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { azureClientId, azureClientSecret, azureTenantId, subscriptionId, dataType } = this.state;
    const { selectedMetrics, workspaceId, logTables } = this.state;
    const { samplingInterval, samplingUnit, historicalDates, projectModelFlag, processPauseFlag } = this.state;
    const { resourceOptions, metricResourceMap } = this.state;

    // parse metricInfos
    const metricInfos = [];
    if (dataType === 'Metric') {
      const metricInfosMap = {};
      R.forEach((metric) => {
        const resourceId = metricResourceMap[metric];
        if (resourceId) {
          if (!R.has(resourceId, metricInfosMap)) metricInfosMap[resourceId] = [];
          metricInfosMap[resourceId].push(metric);
        }
      }, selectedMetrics);
      R.forEachObjIndexed((metrics, resourceId) => {
        const { label: resourceName } = R.find((item) => item.value === resourceId, resourceOptions) || {};
        metricInfos.push({ resourceId, resourceName, metrics });
      }, metricInfosMap);
    }

    this.props.createProject(
      'MicrosoftAzure',
      {
        operation: 'register',
        azureClientId,
        azureClientSecret,
        azureTenantId,
        subscriptionId,
        dataType,
        ...(dataType === 'Metric'
          ? { metricInfos: JSON.stringify(metricInfos || []) }
          : { workspaceId, logTables: JSON.stringify(logTables) }),
        samplingInterval: Number(samplingInterval) * samplingUnit,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
        projectModelFlag,
        processPauseFlag,
      },
      this.state,
    );
  }

  @autobind
  handleAddLogTables() {
    const { logTables } = this.state;
    const newLogTables = [...logTables, { tableName: null, instanceField: null, timestampField: null }];
    this.setState({ logTables: newLogTables }, () => {
      if (this.tableNode) {
        this.tableNode.scrollToRow(newLogTables.length - 1);
      }
    });
  }

  @autobind
  renderLogTableName({ rowData, cellData, rowIndex }) {
    const { tableOptions } = this.state;
    return (
      <Select
        allowClear={false}
        showSearch
        options={tableOptions || []}
        value={cellData}
        onChange={(tableName) => {
          this.setState((prevState) => ({
            logTables: update(prevState.logTables, {
              [rowIndex]: {
                $set: {
                  ...(prevState.logTables[rowIndex] || {}),
                  tableName,
                  instanceField: null,
                  timestampField: null,
                },
              },
            }),
          }));
        }}
      />
    );
  }

  @autobind
  renderLogInstance({ rowData, cellData, rowIndex }) {
    const { workspaceId, worksapceTableFieldMap } = this.state;
    const { tableName } = rowData;
    const fieldOptions = R.map(
      (value) => ({ value, label: value }),
      get(worksapceTableFieldMap, [workspaceId, tableName], []),
    );
    return (
      <Select
        allowClear={false}
        showSearch
        options={fieldOptions || []}
        value={cellData}
        onChange={(instanceField) => {
          this.setState((prevState) => ({
            logTables: update(prevState.logTables, {
              [rowIndex]: {
                $set: {
                  ...(prevState.logTables[rowIndex] || {}),
                  instanceField,
                },
              },
            }),
          }));
        }}
      />
    );
  }

  @autobind
  renderLogTimestamp({ rowData, cellData, rowIndex }) {
    const { workspaceId, worksapceTableFieldMap } = this.state;
    const { tableName } = rowData;
    const fieldOptions = R.map(
      (value) => ({ value, label: value }),
      get(worksapceTableFieldMap, [workspaceId, tableName], []),
    );
    return (
      <Select
        allowClear={false}
        showSearch
        options={fieldOptions || []}
        value={cellData}
        onChange={(timestampField) => {
          this.setState((prevState) => ({
            logTables: update(prevState.logTables, {
              [rowIndex]: {
                $set: {
                  ...(prevState.logTables[rowIndex] || {}),
                  timestampField,
                },
              },
            }),
          }));
        }}
      />
    );
  }

  @autobind
  controlRenderer({ rowIndex }) {
    const { intl } = this.props;

    return (
      <Button
        size="small"
        className="button-color-grey"
        onClick={() => {
          const { logTables } = this.state;
          const newLogTables = R.remove(rowIndex, 1, logTables);
          this.setState({ logTables: newLogTables });
        }}
      >
        {intl.formatMessage(appButtonsMessages.remove)}
      </Button>
    );
  }

  @autobind
  handleDataTypeChange(dataType) {
    this.setState(
      {
        dataType,
        verified: false,
      },
      () => {
        this.props.saveProjectInfo('MicrosoftAzure', { dataType }, this.state);
      },
    );
  }

  render() {
    const { intl, hasError, isLoading } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,

      azureClientId,
      azureClientSecret,
      azureTenantId,
      subscriptionId,
      dataType,

      resources,
      selectedMetrics,
      workspaceId,
      logTables,

      samplingInterval,
      samplingUnit,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
    } = this.state;
    const { treeData, resourceOptions, resourceList, worksapceOptions, worksapceTableMap } = this.state;
    const isMetric = dataType === 'Metric';
    const hasVerifyError = !azureClientId || !azureClientSecret || !azureTenantId || !subscriptionId || !dataType;
    let hasErrorRegister = hasVerifyError || !samplingInterval || !samplingUnit || hasError;
    if (isMetric) {
      hasErrorRegister = hasErrorRegister || R.isEmpty(resources) || selectedMetrics.length === 0;
    } else {
      hasErrorRegister =
        hasErrorRegister ||
        !workspaceId ||
        logTables.length === 0 ||
        R.reduce(
          R.or,
          false,
          R.map((item) => {
            return !item.tableName || !item.instanceField || !item.timestampField;
          }, logTables),
        );
    }
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.MicrosoftAzureIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item
              label="Client Id"
              validateStatus={!azureClientId ? 'error' : 'success'}
              help={!azureClientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={azureClientId}
                onChange={(e) => this.setState({ azureClientId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Client Secret"
              validateStatus={!azureClientSecret ? 'error' : 'success'}
              help={!azureClientSecret ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={azureClientSecret}
                onChange={(e) => this.setState({ azureClientSecret: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Tenant Id"
              validateStatus={!azureTenantId ? 'error' : 'success'}
              help={!azureTenantId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={azureTenantId}
                onChange={(e) => this.setState({ azureTenantId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Subscription Id"
              validateStatus={!subscriptionId ? 'error' : 'success'}
              help={!subscriptionId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.Password
                autoComplete="new-password"
                value={subscriptionId}
                onChange={(e) => this.setState({ subscriptionId: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Data type"
              validateStatus={!dataType ? 'error' : 'success'}
              help={!dataType ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select showSearch options={this.dataTypeOption} value={dataType} onChange={this.handleDataTypeChange} />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>
        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            {isMetric && (
              <>
                <Form.Item
                  label="Resources"
                  validateStatus={R.isEmpty(resources) ? 'error' : 'success'}
                  help={R.isEmpty(resources) ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    mode="multiple"
                    allowClear
                    autoClearSearchValue={false}
                    maxTagCount={1}
                    value={resources}
                    onChange={this.handleResourcesChange}
                    showSearch
                    dropdownRender={(menu) => {
                      const selectAll = R.difference(resourceList, resources).length === 0;
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 12px' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={selectAll}
                              onChange={(e) => {
                                const { checked } = e.target;
                                if (checked) {
                                  this.handleResourcesChange(resourceList);
                                } else {
                                  this.handleResourcesChange([]);
                                }
                              }}
                            />
                            <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                  >
                    {R.map((item) => {
                      return (
                        <Select.Option className="hide-icon" key={item.value}>
                          <Checkbox
                            checked={resources.includes(item.value)}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {item.label}
                        </Select.Option>
                      );
                    }, resourceOptions)}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Metrics"
                  required
                  validateStatus={selectedMetrics.length === 0 ? 'error' : 'success'}
                  help={selectedMetrics.length === 0 ? 'This input is required.' : undefined}
                >
                  <TreeSelect
                    style={{ width: '100%' }}
                    allowClear
                    maxTagCount={1}
                    showSearch
                    autoClearSearchValue={false}
                    treeDefaultExpandAll={false}
                    treeCheckable
                    treeNodeLabelProp="title"
                    treeData={treeData || []}
                    value={selectedMetrics}
                    onChange={(selectedMetrics) => this.setState({ selectedMetrics })}
                  />
                </Form.Item>
              </>
            )}

            {!isMetric && (
              <>
                <Form.Item
                  label="Workspace"
                  validateStatus={!workspaceId ? 'error' : 'success'}
                  help={!workspaceId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    allowClear={false}
                    showSearch
                    options={worksapceOptions}
                    value={workspaceId}
                    onChange={(workspaceId) =>
                      this.setState({ workspaceId }, () => {
                        const tableOptions = R.map(
                          (value) => ({ value, label: value }),
                          worksapceTableMap[workspaceId] || [],
                        );
                        this.setState({ tableOptions, logTables: [] });
                      })
                    }
                  />
                </Form.Item>
                <Form.Item label="Log Tables">
                  <div style={{ marginBottom: 8 }}>
                    <Button size="small" type="primary" icon={<PlusOutlined />} onClick={this.handleAddLogTables}>
                      {intl.formatMessage(appButtonsMessages.add)}
                    </Button>
                  </div>
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <Table
                        className="with-border"
                        width={width}
                        height={150}
                        headerHeight={30}
                        rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                        rowHeight={40}
                        rowCount={logTables.length}
                        rowGetter={({ index }) => logTables[index]}
                        ref={(tableNode) => {
                          this.tableNode = tableNode;
                        }}
                      >
                        <Column
                          width={120}
                          label="Table"
                          dataKey="tableName"
                          cellRenderer={this.renderLogTableName}
                          flexGrow={1}
                        />
                        <Column
                          width={120}
                          label="Instance Field"
                          dataKey="instanceField"
                          cellRenderer={this.renderLogInstance}
                          flexGrow={1}
                        />
                        <Column
                          width={120}
                          label="Timestamp Field"
                          dataKey="timestampField"
                          cellRenderer={this.renderLogTimestamp}
                          flexGrow={1}
                        />
                        <Column width={110} label="" dataKey="tableName" cellRenderer={this.controlRenderer} />
                      </Table>
                    )}
                  </AutoSizer>
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Sampling Interval"
              validateStatus={!samplingInterval ? 'error' : 'success'}
              help={!samplingInterval ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-row flex-center-align">
                <Input
                  style={{ width: 200 }}
                  value={samplingInterval}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d+$/.test(val) || R.isEmpty(val)) {
                      this.setState({ samplingInterval: Number(val) });
                    }
                  }}
                />
                <Select
                  style={{ width: 120, marginLeft: 4 }}
                  options={this.samplingUnitOption}
                  value={samplingUnit}
                  onChange={(samplingUnit) => this.setState({ samplingUnit })}
                />
              </div>
            </Form.Item>
            <Form.Item label="Historical Date Range">
              <DatePicker.RangePicker
                allowClear
                style={{ width: '50%', margin: '0 8px 0 0' }}
                value={historicalDates}
                disabledDate={(current) => {
                  return current && current > moment.utc().add(1, 'days').endOf('day');
                }}
                onChange={(historicalDates) => this.setState({ historicalDates })}
              />
              {!isMetric && (
                <div className="flex-row" style={{ marginTop: 16 }}>
                  <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                    Enable holistic model
                  </div>
                  <Checkbox
                    checked={projectModelFlag}
                    onChange={(e) => {
                      this.setState({ projectModelFlag: e.target.checked });
                    }}
                  />
                </div>
              )}
              {!isMetric && (
                <div className="flex-row" style={{ marginTop: 16 }}>
                  <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                    Enable initial processing pause
                  </div>
                  <Checkbox
                    checked={processPauseFlag}
                    onChange={(e) => {
                      this.setState({ processPauseFlag: e.target.checked });
                    }}
                  />
                </div>
              )}
            </Form.Item>
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const MicrosoftAzureSetting = injectIntl(MicrosoftAzureSettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(MicrosoftAzureSetting);
