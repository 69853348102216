/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import * as R from 'ramda';
import update from 'immutability-helper';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import {
  Tabs,
  Popover,
  Button,
  notification,
  Select,
  Input,
  message,
  AutoComplete,
  InputNumber,
  Divider,
  Checkbox,
  Popconfirm,
} from 'antd';

import { CaretDownOutlined, CaretUpOutlined, PauseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import fetchPostForm from '../../../../common/apis/fetchPostForm';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { getLoadStatus, parseLocation } from '../../../../common/utils';
import { logMessages } from '../../../../common/log/messages';
import { createLoadAction } from '../../../../common/app/actions';
import { ActionTypes } from '../../../../common/settings/actions';
import { State } from '../../../../common/types';
import {
  Container,
  Table,
  Column,
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer,
  SortDirection,
  defaultTableRowRenderer,
  Tooltip,
} from '../../../../lib/fui/react';

import { appFieldsMessages, appButtonsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { eventMessages } from '../../../../common/metric/messages';
import { DashboardMessages } from '../../../../common/dashboard/messages';
import PatternNameRegexModal from './PatternNameRegexModal';
import getInstanceDisplayName from '../../../../common/utils/getInstanceDisplayName';

const GroupBox = {
  border: '1px dashed var(--border-color-base)',
  padding: 20,
  borderRadius: 12,
  marginBottom: 40,
  height: 400,
};

type Props = {
  // Common props
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,

  // Special props
  refreshTime: Number,
  projectName: String,
  data: Object,
  keywordsList: Object,

  credentials: Object,
  entry: String,
  refs: Function,
  handleDeleteCategory: Function,
  currentProject: Object,
  instanceDisplayNameMap: Object,
};

const SortableTable = SortableContainer(Table, { withRef: true });
const SortableTableRowRenderer = SortableElement(defaultTableRowRenderer);
const DragHandle = sortableHandle(({ intl }) => (
  <Tooltip title={intl.formatMessage(appFieldsMessages.dragAndDrop)} placement="top" mouseEnterDelay={0.3}>
    <span
      className="hover-display-item"
      style={{ cursor: 'move', pointerEvents: 'auto', marginLeft: 8 }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <PauseOutlined style={{ transform: 'rotate(90deg)', fontSize: 18, cursor: 'move' }} />
    </span>
  </Tooltip>
));

class KeywordsSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { intl, location, keywordsList, entry } = props;

    this.entry = entry;
    const query = parseLocation(location);
    const {
      whitelist,
      trainingWhitelist,
      instanceNameLabels,
      trainingBlacklist,
      extractionBlacklist,
      featurelist,
      kpilist,
      categorylist,
      incidentlist,
      patternNameLabels,
      triagelist,
      anomalyFeature,
      dataFilterLabels,
    } = keywordsList || {};

    this.submitLoader = 'setting_keywords_loading';

    this.gridOffsetY = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheTrainingWhitelist = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheInstanceNameLabels = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheTrainingBlacklist = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheExtractionBlacklist = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheFeature = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheKPI = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheCategory = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheIncident = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCachePatternNameLabels = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheTriageReport = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheDataFilter = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.cellMeasureCacheAnomalyFeature = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.regexPlaceHolderForWhiteList = [
      intl.formatMessage(logMessages.wordSentenceRegex),
      intl.formatMessage(logMessages.wordSentenceRegex),
      // intl.formatMessage(logMessages.regexPlaceHolderForWhiteList),
    ];
    this.regexPlaceHolderForIncident = [
      intl.formatMessage(logMessages.wordSentenceRegex),
      intl.formatMessage(logMessages.wordSentenceRegex),
      // intl.formatMessage(logMessages.regexPlaceHolderForIncident),
      // intl.formatMessage(logMessages.regexPlaceHolderForWhiteList),
    ];
    this.regexPlaceHolderForOther = [
      intl.formatMessage(logMessages.wordSentenceRegex),
      intl.formatMessage(logMessages.regexPlaceHolderForOther),
    ];
    this.cmp = (x, y) =>
      x.keyword === y.keyword &&
      x.type === y.type &&
      x.isClique === y.isClique &&
      x.isCritical === y.isCritical &&
      x.isHotEventOnly === y.isHotEventOnly &&
      // x.jsonKey === y.jsonKey &&
      x.name === y.name;
    this.cmpTraining = (x, y) => x.keyword === y.keyword && x.type === y.type && x.jsonKey === y.jsonKey;
    this.cmpInstanceNameLabels = (x, y) => x.keyword === y.keyword && x.type === y.type && x.jsonKey === y.jsonKey;
    this.cmpExtraction = (x, y) => x.keyword === y.keyword && x.type === y.type && x.jsonKey === y.jsonKey;
    this.cmpFeature = (x, y) =>
      x.keyword === y.keyword &&
      x.type === y.type &&
      x.priority === y.priority &&
      x.jsonKey === y.jsonKey &&
      x.name === y.name;
    this.cmpCategory = (x, y) => x.keyword === y.keyword && x.category === y.category;
    this.cmpIncident = (x, y) =>
      x.keyword === y.keyword &&
      x.type === y.type &&
      x.instanceSet === y.instanceSet &&
      x.countThreshold === y.countThreshold &&
      x.patternName === y.patternName;
    this.cmpPatternNameLabel = (x, y) =>
      x.keyword === y.keyword &&
      x.type === y.type &&
      x.instanceSet === y.instanceSet &&
      x.countThreshold === y.countThreshold &&
      x.patternName === y.patternName &&
      x.patternNameKey === y.patternNameKey;
    this.cmpTriageReport = (x, y) => x.keyword === y.keyword && x.type === y.type && x.jsonKey === y.jsonKey;
    this.cmpDataFilter = (x, y) => x.keyword === y.keyword && x.type === y.type && x.jsonKey === y.jsonKey;

    this.typeList = [
      { label: intl.formatMessage(settingsMessages.jsonFieldName), value: 'fieldName' },
      { label: intl.formatMessage(settingsMessages.stringContent), value: 'content' },
    ];
    this.priorityTypeList = [
      { label: 'None', value: -1 },
      { label: 'Primary', value: 0 },
      { label: 'Secondary', value: 1 },
      { label: 'Tertiary', value: 2 },
    ];
    this.priorityTypeListWithoutPrimary = [
      { label: 'None', value: -1 },
      { label: 'Secondary', value: 1 },
      { label: 'Tertiary', value: 2 },
    ];

    // Make a full copy of the data to avoid side affect with other components.
    this.localKeywordsList = R.clone(whitelist || []);
    this.localKeywordsTrainingWhitelist = R.clone(trainingWhitelist || []);
    this.localKeywordsInstanceNameLabels = R.clone(instanceNameLabels || []);
    this.localKeywordsTrainingBlacklist = R.clone(trainingBlacklist || []);
    this.localKeywordsExtractionBlacklist = R.clone(extractionBlacklist || []);
    this.localKeywordsFeature = R.clone(featurelist || []);
    this.localKeywordsKPI = R.clone(kpilist || []);
    this.localKeywordsCategory = R.clone(categorylist || []);
    this.localKeywordsIncident = R.clone(incidentlist || []);
    this.localPatternNameLabels = R.clone(patternNameLabels || []);
    this.localKeywordsTriageReport = R.clone(triagelist || []);
    this.localKeywordsDataFilter = R.clone(dataFilterLabels || []);
    this.localKeywordsAnomalyFeature = R.clone(anomalyFeature || []);

    this.typeCacheMap = {
      trainingBlacklist: this.cellMeasureCacheTrainingBlacklist,
      trainingWhitelist: this.cellMeasureCacheTrainingWhitelist,
      instanceNameLabels: this.cellMeasureCacheInstanceNameLabels,
      extractionBlacklist: this.cellMeasureCacheExtractionBlacklist,
      anomalyFeature: this.cellMeasureCacheAnomalyFeature,
    };

    this.typeLocalKeyWordMap = {
      trainingBlacklist: this.localKeywordsTrainingBlacklist,
      trainingWhitelist: this.localKeywordsTrainingWhitelist,
      instanceNameLabels: this.localKeywordsInstanceNameLabels,
      extractionBlacklist: this.localKeywordsExtractionBlacklist,
      anomalyFeature: this.localKeywordsAnomalyFeature,
    };

    this.typeTableMap = {
      trainingBlacklist: 'tableTrainingBlacklist',
      trainingWhitelist: 'tableTrainingWhitelist',
      instanceNameLabels: 'tableInstanceNameLabels',
      extractionBlacklist: 'tableExtractionBlacklist',
      anomalyFeature: 'tableAnomalyFeature',
    };

    this.checkboxCellRender = (node, handleChangeFuc) => {
      return ({ dataKey, rowData, cellData }) => (
        <input
          className="fui input"
          type="checkbox"
          checked={cellData || false}
          onChange={
            handleChangeFuc ? handleChangeFuc(rowData, dataKey, node) : this.handleInputChanged(rowData, dataKey, node)
          }
        />
      );
    };
    this.inputCellRender = (node) => {
      return ({ dataKey, rowData, cellData }) => (
        <Input value={cellData} onChange={this.handleInputChanged(rowData, dataKey, node)} />
      );
    };

    this.state = {
      showKeywordsUpdateModal: false,
      activeKey: get(query, 'keywordCategory', 'whitelist'),
      fieldNameOptions: [],
      systemKeywordSettingList: [],
      systemKeywordSettingValue: [],

      incidentPatterMappingList: [],
      incidentPatterMappingValue: [],

      patternNameLabelsMappingList: [],
      patternNameLabelsMappingValue: [],

      whitelistCheckedAll: false,
      trainingWhitelistCheckedAll: false,
      instanceNameLabelsCheckedAll: false,
      trainingBlacklistCheckedAll: false,
      extractionBlacklistCheckedAll: false,
      featurelistCheckedAll: false,
      anomalyFeatureCheckedAll: false,
      categorylistCheckedAll: false,
      incidentlistCheckedAll: false,
      patternNameLabelsCheckedAll: false,
      triagelistCheckedAll: false,
      dataFilterCheckedAll: false,

      knownSystemType: 'content',
      knownSystemJsonKey: null,
      knownSystemJsonKeys: [],

      keywordSortBy: null,
      keywordSortDirection: null,

      keywordLoading: false,

      showPatternNameRegex: false,
      activeEvent: null,
    };
    this.instanceListOptions = [];
    this.searchValue = '';
    this.searchSystem = '';
    this.dataLoader = 'get_keywords_loading';

    this.incidentListData = [];
  }

  async componentDidMount() {
    await this.getGlobalKBParam();
    const { intl, projectName, createLoadAction, credentials, entry, refs } = this.props;
    if (entry === 'batch') {
      refs(this);
      return;
    }
    createLoadAction(ActionTypes.LOAD_PROJECT_KEYWORDS_SETTING, { projectName }, this.dataLoader);
    fetchGet(getEndpoint('logjsontype'), {
      ...credentials,
      projectName,
    })
      .then((data) => {
        const fieldNameOptions = R.map((item) => ({ value: item.jsonKey, label: item.jsonKey }), data || []);
        this.setState({ fieldNameOptions });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ fieldNameOptions: [] });
      });
    this.getSystemErrorLogKeywordSetting();
    this.getIncidentpatternnamemapping();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { projectName, keywordsList, createLoadAction, entry } = nextProps;
    // If project changed, reload the holiday
    if (
      this.props.projectName !== projectName ||
      (this.props.refreshTime !== nextProps.refreshTime && entry !== 'batch')
    ) {
      createLoadAction(ActionTypes.LOAD_PROJECT_KEYWORDS_SETTING, { projectName }, this.dataLoader);
    } else if (this.props.keywordsList !== nextProps.keywordsList) {
      const {
        whitelist,
        trainingWhitelist,
        instanceNameLabels,
        trainingBlacklist,
        extractionBlacklist,
        featurelist,
        categorylist,
        incidentlist,
        patternNameLabels,
        triagelist,
        anomalyFeature,
        dataFilterLabels,
      } = keywordsList || {};
      this.localKeywordsList = R.clone(whitelist || []);
      this.localKeywordsTrainingWhitelist = R.clone(trainingWhitelist || []);
      this.localKeywordsInstanceNameLabels = R.clone(instanceNameLabels || []);
      this.localKeywordsTrainingBlacklist = R.clone(trainingBlacklist || []);
      this.localKeywordsExtractionBlacklist = R.clone(extractionBlacklist || []);
      this.localKeywordsAnomalyFeature = R.clone(anomalyFeature || []);
      this.typeLocalKeyWordMap = {
        trainingBlacklist: this.localKeywordsTrainingBlacklist,
        trainingWhitelist: this.localKeywordsTrainingWhitelist,
        instanceNameLabels: this.localKeywordsInstanceNameLabels,
        extractionBlacklist: this.localKeywordsExtractionBlacklist,
        anomalyFeature: this.localKeywordsAnomalyFeature,
      };
      this.localKeywordsFeature = R.clone(featurelist || []);
      this.localKeywordsCategory = R.clone(categorylist || []);
      this.localKeywordsIncident = R.clone(incidentlist || []);
      this.localPatternNameLabels = R.clone(patternNameLabels || []);
      this.localKeywordsTriageReport = R.clone(triagelist || []);
      this.localKeywordsDataFilter = R.clone(dataFilterLabels || []);
      this.cellMeasureCache.clearAll();
      this.cellMeasureCacheTrainingWhitelist.clearAll();
      this.cellMeasureCacheTrainingBlacklist.clearAll();
      this.cellMeasureCacheExtractionBlacklist.clearAll();
      this.cellMeasureCacheFeature.clearAll();
      this.cellMeasureCacheCategory.clearAll();
      this.cellMeasureCacheIncident.clearAll();
      this.cellMeasureCachePatternNameLabels.clearAll();
      this.cellMeasureCacheTriageReport.clearAll();
      this.cellMeasureCacheDataFilter.clearAll();

      this.parseData(nextProps);
    } else if (this.props.data !== nextProps.data) {
      this.parseData(nextProps);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { keywordSortBy: prevKeywordSortBy, keywordSortDirection: prevKeywordSortDirection } = this.state;
    if (nextState.keywordSortBy !== prevKeywordSortBy || nextState.keywordSortDirection !== prevKeywordSortDirection) {
      const { keywordSortBy, keywordSortDirection } = nextState;
      if (keywordSortBy) {
        this.localKeywordsList = R.sortWith([R.ascend(R.prop(keywordSortBy))])(this.localKeywordsList);
        if (keywordSortDirection === SortDirection.DESC) {
          this.localKeywordsList = R.sortWith([R.descend(R.prop(keywordSortBy))])(this.localKeywordsList);
        }
        if (this.table) {
          this.table.forceUpdateGrid();
          this.forceUpdate();
        }
      }
    }
  }

  @autobind
  getGlobalKBParam() {
    const { credentials } = this.props;
    return fetchGet(getEndpoint('global-kb-param', 1), {
      ...credentials,
    })
      .then((data) => {
        const { success, message: msg, incidentList } = data || {};
        if (success || success === undefined) {
          let incidentListData = R.map((item) => ({ label: item, value: item }), incidentList || []);
          incidentListData = R.sortWith([R.ascend(R.prop('label'))], incidentListData);
          this.incidentListData = incidentListData;
        } else {
          console.error(msg);
        }
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  }

  @autobind
  getSystemErrorLogKeywordSetting() {
    const { credentials } = this.props;
    fetchGet(getEndpoint('SystemErrorLogKeywordSetting'), {
      ...credentials,
    })
      .then((data) => {
        const { success } = data || {};
        if (success || success === undefined) {
          const systemKeywordSettingList = [];
          R.forEachObjIndexed((val, key) => {
            if (val) {
              systemKeywordSettingList.push({ label: key, value: key, data: val });
            }
          }, data || {});
          this.setState({ systemKeywordSettingList });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });
  }

  @autobind
  getIncidentpatternnamemapping() {
    const { credentials } = this.props;
    fetchGet(getEndpoint('incidentpatternnamemapping', 2), {
      ...credentials,
    })
      .then((data) => {
        const { success } = data || {};
        if (success || success === undefined) {
          const incidentPatterMappingList = [];
          const patternNameLabelsMappingList = [];
          R.forEachObjIndexed((val, key) => {
            incidentPatterMappingList.push({ label: key, value: key, data: val });
            patternNameLabelsMappingList.push({ label: key, value: key, data: val });
          }, data || {});
          this.setState({ incidentPatterMappingList, patternNameLabelsMappingList });
        }
      })
      .catch((err) => {
        message.error(err.message || String(err));
      });
  }

  @autobind
  parseData(props) {
    const { data } = props;
    const instanceListOptions = R.map(
      (p) => ({ label: p.instanceName, value: p.instanceId }),
      get(data, 'instanceList', []),
    );
    this.instanceListOptions = instanceListOptions;
  }

  @autobind
  handleInputChanged(rowData, dataKey, node) {
    return (e) => {
      const { target } = e;
      const newVal = target.type === 'checkbox' ? target.checked : target.value || '';

      // Save the data and force update.
      rowData[dataKey] = newVal;
      this[node].forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  getKeywordList(rowData) {
    const { keyword, type, isNew } = rowData;
    const keywordList = [];
    R.forEach((_item) => {
      if (type === 'fieldName' && (_item || '').includes('=')) {
        const jsonKey = R.slice(0, R.indexOf('=', _item || ''), _item || '');
        const keyword = R.slice(R.indexOf('=', _item || '') + 1, Infinity, _item || '');
        keywordList.push({ jsonKey, keyword });
      } else if (type === 'fieldName' && !(_item || '').includes('=') && isNew) {
        const jsonKey = '';
        const keyword = _item || '';
        keywordList.push({ jsonKey, keyword });
      } else if (type === 'fieldName' && !(_item || '').includes('=')) {
        const jsonKey = _item || '';
        const keyword = '';
        keywordList.push({ jsonKey, keyword });
      } else if (type === 'content' || !type) {
        keywordList.push({ keyword: _item });
      }
    }, (keyword ?? '').split(' AND '));
    return keywordList;
  }

  @autobind
  getKeyword(rowData) {
    const keywordList = this.getKeywordList(rowData);
    let keyword = R.map((item) => `${item.jsonKey || ''}${item.keyword ? `=${item.keyword}` : ''}`, keywordList || []);
    if (rowData.type === 'content' || !rowData.type) {
      keyword = R.map((item) => item.keyword, keywordList || []);
    }
    keyword = keyword.join(' AND ');
    return keyword;
  }

  @autobind
  typeChangeList(rowData, dataKey, node) {
    return (newVal) => {
      const keywordList = this.getKeywordList(rowData);

      let newData = R.map(
        (item) => (`${item.jsonKey || ''}=${item.keyword}` === '=' ? '' : `${item.jsonKey || ''}=${item.keyword}`),
        keywordList || [],
      );
      if (newVal === 'content' || !newVal) {
        newData = R.map((item) => item.keyword, keywordList || []);
      }
      newData = newData.join(' AND ');

      // Save the data and force update.
      if (node === 'tablePatternNameLabels') {
        rowData.patternNameKey = undefined;
      }
      rowData.keyword = newData;
      rowData[dataKey] = newVal;
      this[node].forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  renderTypeSelectList(node) {
    return ({ dataKey, rowData, cellData }) => (
      <Select
        className="full-width"
        size="small"
        value={cellData}
        onChange={this.typeChangeList(rowData, dataKey, node)}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {R.map(
          (item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ),
          this.typeList,
        )}
      </Select>
    );
  }

  @autobind
  handleTypeChangeList(keywordList, index, dataKey, key, rowData, node) {
    return (newVal) => {
      keywordList = update(keywordList, {
        [index]: {
          $set: {
            ...keywordList[index],
            ...{ [key]: newVal },
          },
        },
      });
      let newData = R.map(
        (item) => (`${item.jsonKey || ''}=${item.keyword}` === '=' ? '' : `${item.jsonKey || ''}=${item.keyword}`),
        keywordList || [],
      );
      newData = newData.join(' AND ');
      // Save the data and force update.
      rowData[dataKey] = newData;
      this[node].forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleKeywordsChangedList(keywordList, index, dataKey, key, rowData, node) {
    return (e) => {
      const newVal = e.target.value || '';
      const { type } = rowData;
      keywordList = update(keywordList, {
        [index]: {
          $set: {
            ...keywordList[index],
            ...{ [key]: newVal },
          },
        },
      });
      let newData = R.map(
        (item) => (`${item.jsonKey || ''}=${item.keyword}` === '=' ? '' : `${item.jsonKey || ''}=${item.keyword}`),
        keywordList || [],
      );
      if (type === 'content' || !type) {
        newData = R.map((item) => item.keyword, keywordList || []);
      }
      newData = newData.join(' AND ');
      // Save the data and force update.
      rowData[dataKey] = newData;
      this[node].forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleAddAnd(keywordList, dataKey, rowData, cache, node) {
    const { type } = rowData;
    let newData = [];
    if (type === 'fieldName') {
      newData = [...keywordList, { jsonKey: '', keyword: '' }];
      newData = R.map((item) => `${item.jsonKey}=${item.keyword}`, newData || []);
    } else if (type === 'content' || !type) {
      newData = [...keywordList, { keyword: '' }];
      newData = R.map((item) => item.keyword, newData || []);
    }
    newData = newData.join(' AND ');
    // Save the data and force update.
    rowData[dataKey] = newData;
    this[cache].clearAll();
    this[node].forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleDeleteAnd(keywordList, index, dataKey, rowData, cache, node) {
    if (keywordList.length > 1) {
      const { type } = rowData;
      const newKeywordList = R.remove(index, 1, keywordList);
      let newData = R.map(
        (item) => (`${item.jsonKey || ''}=${item.keyword}` === '=' ? '' : `${item.jsonKey || ''}=${item.keyword}`),
        newKeywordList || [],
      );
      if (type === 'content' || !type) {
        newData = R.map((item) => item.keyword, newKeywordList || []);
      }
      newData = newData.join(' AND ');
      // Save the data and force update.
      rowData[dataKey] = newData;
      this[cache].clearAll();
      this[node].forceUpdateGrid();
      this.forceUpdate();
    }
  }

  @autobind
  renderKeywordsInputList(cache, node) {
    const { intl } = this.props;
    const { fieldNameOptions } = this.state;
    const regexPlaceHolder = node === 'table' ? this.regexPlaceHolderForWhiteList : this.regexPlaceHolderForIncident;
    return ({ dataKey, parent, rowIndex, cellData, rowData }) => {
      const widthNum = R.includes(node, ['table', 'tableIncident']) ? 150 : 180;
      const { type } = rowData;
      const keywordList = this.getKeywordList(rowData);
      return (
        <CellMeasurer cache={this[cache]} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
          <div style={{ padding: '4px 0 8px 0' }}>
            {R.addIndex(R.map)((_item, _index) => {
              return (
                <div className="flex-row flex-center-align" style={{ marginTop: 4 }} key={_index}>
                  {type === 'fieldName' && (
                    <>
                      <AutoComplete
                        size="small"
                        allowClear
                        options={fieldNameOptions}
                        value={_item.jsonKey}
                        placeholder={intl.formatMessage(logMessages.regexPlaceHolderForWhiteListJsonKey)}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf((_item.jsonKey || '').toUpperCase()) !== -1
                        }
                        onChange={this.handleTypeChangeList(keywordList, _index, dataKey, 'jsonKey', rowData, node)}
                        style={{ minWidth: widthNum, maxWidth: widthNum }}
                        className={`${_item.jsonKey ? '' : 'jsonKeyNoneError'}`}
                      />
                      <span style={{ margin: '0 10px' }}>=</span>
                    </>
                  )}
                  <Input
                    size="small"
                    placeholder={
                      type === 'content' ? regexPlaceHolder[0] : type === 'fieldName' ? regexPlaceHolder[1] : ''
                    }
                    style={{ minWidth: widthNum, maxWidth: widthNum }}
                    value={_item.keyword}
                    onChange={this.handleKeywordsChangedList(keywordList, _index, dataKey, 'keyword', rowData, node)}
                    className={`${type === 'content' && !_item.keyword ? 'inputIsNil' : ''}`}
                  />
                  <Button
                    size="small"
                    style={{ marginLeft: 8 }}
                    onClick={() => this.handleAddAnd(keywordList, dataKey, rowData, cache, node)}
                  >
                    AND
                  </Button>
                  <Button
                    size="small"
                    style={{ marginLeft: 8 }}
                    disabled={keywordList.length === 1}
                    onClick={() => this.handleDeleteAnd(keywordList, _index, dataKey, rowData, cache, node)}
                  >
                    {intl.formatMessage(appButtonsMessages.delete)}
                  </Button>
                </div>
              );
            }, keywordList)}
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderKeywordsInput(cache, node) {
    const { intl } = this.props;
    const { fieldNameOptions } = this.state;
    // const regexPlaceHolder =
    //   node === 'tableFeature'
    //     ? this.regexPlaceHolderForOther
    //     : node === 'table'
    //     ? this.regexPlaceHolderForWhiteList
    //     : this.regexPlaceHolderForIncident;
    const regexPlaceHolder = node === 'table' ? this.regexPlaceHolderForWhiteList : this.regexPlaceHolderForIncident;

    return ({ dataKey, parent, rowIndex, cellData, rowData }) => (
      <CellMeasurer cache={this[cache]} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div className="flex-row flex-center-align">
          {/* {rowData.type === 'fieldName' && node !== 'tableFeature' && ( */}
          {rowData.type === 'fieldName' && (
            <>
              <AutoComplete
                size="small"
                allowClear
                options={fieldNameOptions}
                value={rowData.jsonKey}
                placeholder={intl.formatMessage(logMessages.regexPlaceHolderForWhiteListJsonKey)}
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf((rowData.jsonKey || '').toUpperCase()) !== -1
                }
                onChange={this.handleTypeChange(rowData, 'jsonKey', node)}
                style={{ minWidth: 200, maxWidth: 200 }}
                className={`${rowData.jsonKey ? '' : 'jsonKeyNoneError'}`}
              />
              <span style={{ margin: '0 10px' }}>=</span>
            </>
          )}
          <Input
            size="small"
            placeholder={
              rowData.type === 'content' ? regexPlaceHolder[0] : rowData.type === 'fieldName' ? regexPlaceHolder[1] : ''
            }
            value={cellData}
            onChange={this.handleKeywordsChanged(rowData, dataKey, node)}
            className={`${rowData.type === 'content' && !rowData.keyword ? 'inputIsNil' : ''}`}
          />
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  renderFeatureKeywordsInput(props) {
    const { dataKey, parent, rowIndex, cellData, rowData } = props;
    return <Input value={cellData} onChange={this.handleFeatureKeywordsChanged(rowData, dataKey)} />;
  }

  @autobind
  renderKeywordsInputBlacklist(type) {
    const { intl } = this.props;
    const { fieldNameOptions } = this.state;
    return ({ dataKey, parent, rowIndex, cellData, rowData }) => {
      return (
        <CellMeasurer cache={this.typeCacheMap[type]} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
          <div className="flex-row flex-center-align">
            {rowData.type === 'fieldName' && (
              <>
                <AutoComplete
                  size="small"
                  allowClear
                  options={fieldNameOptions}
                  value={rowData.jsonKey}
                  placeholder={intl.formatMessage(logMessages.regexPlaceHolderForWhiteListJsonKey)}
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf((rowData.jsonKey || '').toUpperCase()) !== -1
                  }
                  onChange={this.handleTypeChange(rowData, 'jsonKey', this.typeTableMap[type])}
                  style={{ minWidth: 200, maxWidth: 200 }}
                  className={`${rowData.jsonKey ? '' : 'jsonKeyNoneError'}`}
                />
                <span style={{ margin: '0 10px' }}>=</span>
              </>
            )}
            <Input
              size="small"
              value={cellData}
              placeholder={
                rowData.type === 'content'
                  ? this.regexPlaceHolderForWhiteList[0]
                  : rowData.type === 'fieldName'
                  ? this.regexPlaceHolderForWhiteList[1]
                  : ''
              }
              onChange={this.handleKeywordsChangedBlacklist(rowData, dataKey, type)}
              className={`${rowData.type === 'content' && !rowData.keyword ? 'inputIsNil' : ''}`}
            />
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleKeywordsChanged(rowData, dataKey, node) {
    return (e) => {
      const { target } = e;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this[node].forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleFeatureKeywordsChanged(rowData, dataKey) {
    return (e) => {
      const { target } = e;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this.table.forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  handleKeywordsChangedBlacklist(rowData, dataKey, type) {
    return (e) => {
      const { target } = e;
      const newVal = target.value || '';
      // Save the data and force update.
      rowData[dataKey] = newVal;
      if (type === 'trainingBlacklist') {
        this.tableTrainingBlacklist.forceUpdateGrid();
      } else if (type === 'trainingWhitelist') {
        this.tableTrainingWhitelist.forceUpdateGrid();
      } else if (type === 'instanceNameLabels') {
        this.tableInstanceNameLabels.forceUpdateGrid();
      } else if (type === 'anomalyFeature') {
        this.tableAnomalyFeature.forceUpdateGrid();
      } else {
        this.tableExtractionBlacklist.forceUpdateGrid();
      }
      this.forceUpdate();
    };
  }

  @autobind
  renderInstanceSelect(cache, node) {
    const { instanceDisplayNameMap, currentProject } = this.props;
    const allValues = R.map((x) => x.value, this.instanceListOptions);
    return ({ dataKey, style, parent, rowIndex, cellData, rowData, columnIndex }) => (
      <CellMeasurer cache={this[cache]} columnIndex={columnIndex} key={dataKey} parent={parent} rowIndex={rowIndex}>
        <div style={{ ...style, padding: '8px 0' }}>
          <Select
            className="full-width no-count-num"
            size="small"
            showSearch
            autoClearSearchValue={false}
            allowClear
            placeholder="Select instances"
            dropdownMatchSelectWidth={600}
            mode="multiple"
            value={cellData || []}
            onChange={(val) => {
              rowData[dataKey] = val;
              this[cache].clearAll();
              this[node].forceUpdateGrid();
              this.forceUpdate();
            }}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            dropdownRender={(menu) => {
              return (
                <>
                  <div style={{ padding: '4px 12px 0px', display: 'flex' }}>
                    <Checkbox
                      indeterminate={
                        (cellData || []).length !== 0 && (cellData || []).length !== this.instanceListOptions.length
                      }
                      checked={
                        (cellData || []).length === this.instanceListOptions.length &&
                        this.instanceListOptions.length !== 0
                      }
                      onChange={(e) => {
                        rowData[dataKey] = e.target.checked ? allValues : [];
                        this[cache].clearAll();
                        this[node].forceUpdateGrid();
                        this.forceUpdate();
                      }}
                    />
                    <div className="bold" style={{ paddingLeft: '4px' }}>{`Selected (${(cellData || []).length})`}</div>
                  </div>
                  <Divider style={{ margin: '4px 0' }} />
                  {menu}
                </>
              );
            }}
          >
            {R.map((item) => {
              const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item.label, {
                pn: currentProject?.projectShortName,
                owner: currentProject?.owner,
              });
              return (
                <Select.Option key={item.value} value={item.value} label={instanceStr}>
                  {instanceStr}
                </Select.Option>
              );
            }, this.instanceListOptions)}
          </Select>
        </div>
      </CellMeasurer>
    );
  }

  @autobind
  handleTypeChange(rowData, dataKey, node) {
    return (newVal) => {
      // Save the data and force update.
      rowData[dataKey] = newVal;
      this[node].forceUpdateGrid();
      this.forceUpdate();
    };
  }

  @autobind
  renderTypeSelect(node, colName) {
    return ({ dataKey, rowData, cellData }) => (
      <Select
        className="full-width"
        size="small"
        value={cellData}
        onChange={this.handleTypeChange(rowData, dataKey, node)}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {R.map(
          (item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ),
          this.typeList,
        )}
      </Select>
    );
  }

  @autobind
  renderGroupingPrioritySelect({ dataKey, rowData, cellData }) {
    return (
      <Select
        className="full-width"
        size="small"
        value={cellData}
        onChange={this.handlePrimaryKeyChange(rowData, dataKey, 'tableFeature')}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {R.map(
          (item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ),
          this.priorityTypeList,
        )}
      </Select>
    );
  }

  @autobind
  handleSaveEscalateToIncidentLabel(rowData) {
    const { credentials, projectName } = this.props;
    const rest = { projectName, type: 'whitelist' };
    const keyword = this.retureFieldNameKeyWord(rowData);
    const { type, countThreshold, instanceSet, patternName } = rowData;
    const newRowData = { type, countThreshold: countThreshold || 0, instanceSet, keyword };

    const formData = new FormData();
    formData.append('operation', 'delete');
    formData.append('keywords', JSON.stringify([keyword]));
    R.forEachObjIndexed((value, key) => {
      formData.append(key, value);
    }, rest || {});
    fetchPostForm(getEndpoint('projectkeywords'), formData)
      .then((data) => {
        const { success, message: msg } = data;
        if (success || success === undefined) {
          this.localKeywordsIncident = [...this.localKeywordsIncident, newRowData];
          this.localKeywordsIncident = R.addIndex(R.map)(
            (item, idx) => ({ ...item, order: idx + 1 }),
            this.localKeywordsIncident,
          );
          this.handleSaveClickIncident();
        } else {
          console.error(msg);
        }
      })
      .catch((err) => console.error(String(err)));
  }

  @autobind
  removeRenderer(node, propList, localList, removeType) {
    const { intl, instanceDisplayNameMap, currentProject } = this.props;
    return ({ rowData }) => {
      const { patternName, countThreshold, instanceSet } = rowData;
      const allValues = R.map((x) => x.value, this.instanceListOptions);
      return (
        <div>
          {removeType === 'whitelist' && this.entry !== 'batch' && (
            <Popconfirm
              placement="right"
              icon={null}
              title={
                <div>
                  {false && (
                    <div className="flex-row flex-center-align">
                      <span style={{ flexShrink: 0, width: 95 }}>{intl.formatMessage(logMessages.patternName)}:</span>
                      <AutoComplete
                        allowClear
                        size="small"
                        value={patternName}
                        options={this.incidentListData}
                        style={{ width: 200 }}
                        dropdownMatchSelectWidth={false}
                        dropdownStyle={{ maxWidth: 650 }}
                        placeholder={intl.formatMessage(logMessages.patternName)}
                        onChange={(incident) => {
                          rowData.patternName = incident;
                          this[node].forceUpdateGrid();
                          this.forceUpdate();
                        }}
                        filterOption={(inputValue, option) =>
                          option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
                        }
                      />
                    </div>
                  )}
                  <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                    <span style={{ flexShrink: 0, width: 95 }}>{intl.formatMessage(DashboardMessages.threshold)}:</span>
                    <InputNumber
                      min={0}
                      size="small"
                      style={{ width: 200 }}
                      value={countThreshold || 0}
                      placeholder="Please input integer"
                      onChange={(value) => {
                        rowData.countThreshold = value;
                        this[node].forceUpdateGrid();
                        this.forceUpdate();
                      }}
                    />
                  </div>
                  <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
                    <span style={{ flexShrink: 0, width: 95 }}>{intl.formatMessage(DashboardMessages.instances)}:</span>
                    <Select
                      className="full-width no-count-num"
                      size="small"
                      style={{ width: 200 }}
                      showSearch
                      autoClearSearchValue={false}
                      allowClear
                      placeholder="Select instances"
                      dropdownMatchSelectWidth={600}
                      mode="multiple"
                      value={instanceSet || []}
                      onChange={(val) => {
                        rowData.instanceSet = val;
                        this[node].forceUpdateGrid();
                        this.forceUpdate();
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            <div style={{ padding: '4px 12px 0px', display: 'flex' }}>
                              <Checkbox
                                indeterminate={
                                  (instanceSet || []).length !== 0 &&
                                  (instanceSet || []).length !== this.instanceListOptions.length
                                }
                                checked={
                                  (instanceSet || []).length === this.instanceListOptions.length &&
                                  this.instanceListOptions.length !== 0
                                }
                                onChange={(e) => {
                                  rowData.instanceSet = e.target.checked ? allValues : [];
                                  this[node].forceUpdateGrid();
                                  this.forceUpdate();
                                }}
                              />
                              <div className="bold" style={{ paddingLeft: '4px' }}>{`Selected (${
                                (instanceSet || []).length
                              })`}</div>
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            {menu}
                          </>
                        );
                      }}
                    >
                      {R.map((item) => {
                        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item.label, {
                          pn: currentProject?.projectShortName,
                          owner: currentProject?.owner,
                        });
                        return (
                          <Select.Option key={item.value} value={item.value} label={instanceStr}>
                            {instanceStr}
                          </Select.Option>
                        );
                      }, this.instanceListOptions)}
                    </Select>
                  </div>
                </div>
              }
              onConfirm={() => this.handleSaveEscalateToIncidentLabel(rowData)}
              onCancel={() => {
                rowData.patternName = null;
                rowData.countThreshold = 0;
                rowData.instanceSet = [];
                this[node].forceUpdateGrid();
                this.forceUpdate();
              }}
              okText={intl.formatMessage(appButtonsMessages.update)}
              cancelText={intl.formatMessage(appButtonsMessages.cancel)}
              disabled={this.fieldIsNullItem(rowData)}
            >
              <Button size="small" style={{ marginRight: 8 }} disabled={this.fieldIsNullItem(rowData)}>
                {intl.formatMessage(appButtonsMessages.escalateToIncidentLabel)}
              </Button>
            </Popconfirm>
          )}
          <Button
            size="small"
            style={{ backgroundColor: 'var(--gray)', color: 'white' }}
            onClick={this.handleRemove(rowData, node, propList, localList, removeType)}
          >
            {intl.formatMessage(appButtonsMessages.remove)}
          </Button>
          {removeType === 'whitelist' && !this.searchSystem && <DragHandle intl={intl} />}
          {removeType === 'incidentlist' && !this.searchSystem && <DragHandle intl={intl} />}
          {removeType === 'patternName' && <DragHandle intl={intl} />}
        </div>
      );
    };
  }

  @autobind
  removeRendererBlacklist(type) {
    const { intl } = this.props;
    return ({ rowData, cellData }) => {
      return (
        <Button
          size="small"
          style={{ backgroundColor: 'var(--gray)', color: 'white' }}
          onClick={this.handleRemoveBlacklist(rowData, type)}
        >
          {intl.formatMessage(appButtonsMessages.remove)}
        </Button>
      );
    };
  }

  @autobind
  handleRemove(rowData, node, propList, localList, removeType) {
    return () => {
      const { keywordsList, projectName, createLoadAction, handleDeleteCategory } = this.props;
      const keywordList = get(keywordsList, [propList], []);
      const self = this;
      const cmp = removeType === 'categorylist' ? this.cmpCategory : this.cmp;
      const diff = R.differenceWith(cmp, self[localList], keywordList || []);
      const newDiff = R.map((item) => ({ ...item, keywordStr: this.retureFieldNameKeyWord(item) }), diff || []);
      const data = R.find(R.propEq('keywordStr', this.retureFieldNameKeyWord(rowData)))(newDiff);
      if (data) {
        if (self.entry === 'batch') {
          const categories = R.filter(
            (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(data),
            self[localList],
          );
          handleDeleteCategory(R.difference(self[localList], categories));
          self[localList] = categories;
        } else {
          self[localList] = R.filter(
            (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(data),
            self[localList],
          );
        }
        if (R.includes(removeType, ['whitelist', 'incidentlist', 'patternName'])) {
          self[localList] = R.addIndex(R.map)((item, idx) => ({ ...item, order: idx + 1 }), self[localList]);
        }
        this[node].forceUpdateGrid();
        this.forceUpdate();
      } else if (removeType === 'categorylist') {
        let localKeywordsCategory = self[localList];
        if (self.entry === 'batch') {
          const categories = R.filter(
            (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(rowData),
            self[localList],
          );
          const removeCategories = R.map(
            (n) => ({ category: n.category, keyword: '' }),
            R.difference(self[localList], categories),
          );
          handleDeleteCategory(removeCategories);
          self[localList] = categories;

          this[node].forceUpdateGrid();
          this.forceUpdate();
          return;
        }

        localKeywordsCategory = R.map((item) => {
          if (item.category === rowData.category) {
            return { ...item, keyword: '' };
          }
          return item;
        }, localKeywordsCategory);
        createLoadAction(
          ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
          { projectName, type: 'categorylist', keywords: localKeywordsCategory },
          this.submitLoader,
        );
      } else {
        if (self.entry === 'batch') {
          const categories = R.filter(
            (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(rowData),
            self[localList],
          );
          const removeCategories = R.map(
            (n) => ({ category: n.category, keyword: '' }),
            R.difference(self[localList], categories),
          );
          handleDeleteCategory(removeCategories);
          self[localList] = categories;
          this[node].forceUpdateGrid();
          this.forceUpdate();
          return;
        }
        createLoadAction(
          ActionTypes.DELETE_PROJECT_KEYWORDS_SETTING,
          {
            projectName,
            type: removeType,
            keywords: [this.retureFieldNameKeyWord(rowData)],
          },
          this.submitLoader,
        );
      }
    };
  }

  @autobind
  handleRemoveBlacklist(rowData, type) {
    return () => {
      const { keywordsList, projectName, createLoadAction } = this.props;
      const { trainingWhitelist, instanceNameLabels, trainingBlacklist, extractionBlacklist, anomalyFeature } =
        keywordsList || {};
      const self = this;
      const isBatch = self.entry === 'batch';
      let diff = [];
      if (type === 'trainingBlacklist') {
        diff = R.differenceWith(this.cmp, self.localKeywordsTrainingBlacklist, trainingBlacklist || []);
      } else if (type === 'trainingWhitelist') {
        diff = R.differenceWith(this.cmp, self.localKeywordsTrainingWhitelist, trainingWhitelist || []);
      } else if (type === 'instanceNameLabels') {
        diff = R.differenceWith(
          this.cmpInstanceNameLabels,
          self.localKeywordsInstanceNameLabels,
          instanceNameLabels || [],
        );
      } else if (type === 'anomalyFeature') {
        diff = R.differenceWith(this.cmp, self.localKeywordsAnomalyFeature, anomalyFeature || []);
      } else {
        diff = R.differenceWith(this.cmp, self.localKeywordsExtractionBlacklist, extractionBlacklist || []);
      }
      const newDiff = R.map((item) => ({ ...item, keywordStr: this.retureFieldNameKeyWord(item) }), diff || []);
      const data = R.find(R.propEq('keywordStr', this.retureFieldNameKeyWord(rowData)))(newDiff);
      if (data) {
        if (type === 'trainingBlacklist') {
          self.localKeywordsTrainingBlacklist = R.filter(
            (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(isBatch ? rowData : data),
            self.localKeywordsTrainingBlacklist,
          );
          this.typeLocalKeyWordMap[type] = self.localKeywordsTrainingBlacklist;
          this.tableTrainingBlacklist.forceUpdateGrid();
        } else if (type === 'trainingWhitelist') {
          self.localKeywordsTrainingWhitelist = R.filter(
            (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(isBatch ? rowData : data),
            self.localKeywordsTrainingWhitelist,
          );
          this.typeLocalKeyWordMap[type] = self.localKeywordsTrainingWhitelist;
          this.tableTrainingWhitelist.forceUpdateGrid();
        } else if (type === 'instanceNameLabels') {
          self.localKeywordsInstanceNameLabels = R.filter(
            (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(isBatch ? rowData : data),
            self.localKeywordsInstanceNameLabels,
          );
          this.typeLocalKeyWordMap[type] = self.localKeywordsInstanceNameLabels;
          this.tableInstanceNameLabels.forceUpdateGrid();
        } else if (type === 'anomalyFeature') {
          self.localKeywordsAnomalyFeature = R.filter(
            (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(isBatch ? rowData : data),
            self.localKeywordsAnomalyFeature,
          );
          this.typeLocalKeyWordMap[type] = self.localKeywordsAnomalyFeature;
          this.tableAnomalyFeature.forceUpdateGrid();
        } else {
          self.localKeywordsExtractionBlacklist = R.filter((n) => {
            return this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(isBatch ? rowData : data);
          }, self.localKeywordsExtractionBlacklist);
          this.typeLocalKeyWordMap[type] = self.localKeywordsExtractionBlacklist;
          this.tableExtractionBlacklist.forceUpdateGrid();
        }
        this.forceUpdate();
      } else {
        if (isBatch) {
          if (type === 'trainingBlacklist') {
            self.localKeywordsTrainingBlacklist = R.filter(
              (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(rowData),
              self.localKeywordsTrainingBlacklist,
            );
            this.typeLocalKeyWordMap[type] = self.localKeywordsTrainingBlacklist;
            this.tableTrainingBlacklist.forceUpdateGrid();
          } else if (type === 'trainingWhitelist') {
            self.localKeywordsTrainingWhitelist = R.filter(
              (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(rowData),
              self.localKeywordsTrainingWhitelist,
            );
            this.typeLocalKeyWordMap[type] = self.localKeywordsTrainingWhitelist;
            this.tableTrainingWhitelist.forceUpdateGrid();
          } else if (type === 'instanceNameLabels') {
            self.localKeywordsInstanceNameLabels = R.filter(
              (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(rowData),
              self.localKeywordsInstanceNameLabels,
            );
            this.typeLocalKeyWordMap[type] = self.localKeywordsInstanceNameLabels;
            this.tableInstanceNameLabels.forceUpdateGrid();
          } else if (type === 'anomalyFeature') {
            self.localKeywordsAnomalyFeature = R.filter(
              (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(rowData),
              self.localKeywordsAnomalyFeature,
            );
            this.typeLocalKeyWordMap[type] = self.localKeywordsAnomalyFeature;
            this.tableAnomalyFeature.forceUpdateGrid();
          } else {
            self.localKeywordsExtractionBlacklist = R.filter(
              (n) => this.retureFieldNameKeyWord(n) !== this.retureFieldNameKeyWord(rowData),
              self.localKeywordsExtractionBlacklist,
            );
            this.typeLocalKeyWordMap[type] = self.localKeywordsExtractionBlacklist;
            this.tableExtractionBlacklist.forceUpdateGrid();
          }
          this.forceUpdate();
          return;
        }
        createLoadAction(
          ActionTypes.DELETE_PROJECT_KEYWORDS_SETTING,
          {
            projectName,
            type,
            keywords: [this.retureFieldNameKeyWord(rowData)],
          },
          this.submitLoader,
        );
      }
    };
  }

  @autobind
  handleAddClick(e) {
    e.preventDefault();
    this.localKeywordsList.unshift({
      keyword: '',
      isCritical: false,
      isHotEventOnly: false,
    });
    this.localKeywordsList = R.addIndex(R.map)((item, idx) => ({ ...item, order: idx + 1 }), this.localKeywordsList);
    this.table.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleBlacklistAddClick(type) {
    return (e) => {
      e.preventDefault();
      if (type === 'trainingBlacklist') {
        this.localKeywordsTrainingBlacklist.unshift({
          keyword: '',
        });
        this.tableTrainingBlacklist.forceUpdateGrid();
      } else if (type === 'trainingWhitelist') {
        this.localKeywordsTrainingWhitelist.unshift({
          keyword: '',
        });
        this.tableTrainingWhitelist.forceUpdateGrid();
      } else if (type === 'instanceNameLabels') {
        this.localKeywordsInstanceNameLabels.unshift({
          keyword: '',
        });
        this.tableInstanceNameLabels.forceUpdateGrid();
      } else if (type === 'anomalyFeature') {
        this.localKeywordsAnomalyFeature.unshift({
          keyword: '',
          name: '',
        });
        this.tableAnomalyFeature.forceUpdateGrid();
      } else {
        this.localKeywordsExtractionBlacklist.unshift({
          keyword: '',
        });
        this.tableExtractionBlacklist.forceUpdateGrid();
      }
      this.forceUpdate();
    };
  }

  @autobind
  handleFeatureAddClick(e) {
    e.preventDefault();
    this.localKeywordsFeature.unshift({
      keyword: '',
      isImportant: false,
      priority: -1,
      name: '',
    });
    this.tableFeature.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleKPIAddClick(e) {
    e.preventDefault();
    this.localKeywordsKPI.push({
      keyword: '',
      metricProject: '',
    });
    this.tableKPI.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleCategoryAddClick(event) {
    event.preventDefault();
    this.localKeywordsCategory.unshift({
      category: '',
      keyword: '',
    });
    this.tableCategory.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleIncidentAddClick(event) {
    event.preventDefault();
    this.localKeywordsIncident.unshift({
      keyword: '',
      isNew: true,
      countThreshold: 0,
    });
    this.localKeywordsIncident = R.addIndex(R.map)(
      (item, idx) => ({ ...item, order: idx + 1 }),
      this.localKeywordsIncident,
    );
    this.cellMeasureCacheIncident.clearAll();
    this.tableIncident.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handlePatternNameLabelsAddClick(event) {
    event.preventDefault();
    this.localPatternNameLabels.unshift({
      keyword: '',
      isNew: true,
    });
    this.localPatternNameLabels = R.addIndex(R.map)(
      (item, idx) => ({ ...item, order: idx + 1 }),
      this.localPatternNameLabels,
    );
    this.cellMeasureCachePatternNameLabels.clearAll();
    this.tablePatternNameLabels.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handleTriageReportAddClick(event) {
    event.preventDefault();
    this.localKeywordsTriageReport.unshift({
      keyword: '',
    });
    this.tableTriageReport.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  retureFieldNameKeyWord(key, typeStr = 'fieldName') {
    return `${key.type === typeStr && key.jsonKey ? `${key.jsonKey}` : ''}${
      key.type === typeStr && key.jsonKey && key.keyword ? '=' : ''
    }${key.keyword}`;
  }

  @autobind
  handleSaveClick(e) {
    if (e) e.preventDefault();
    const { projectName, createLoadAction } = this.props;
    const keywords = {};
    const diff = this.localKeywordsList;
    R.forEach((k) => {
      const keyword = this.getKeyword(k);
      if (keyword) {
        keywords[keyword] = {
          type: k.type,
          keyword,
          isCritical: k.isCritical,
          isHotEventOnly: k.isHotEventOnly,
          systemTag: k.systemTag,
          patternNameLabels: k.patternNameLabels,
          order: k.order,
        };
      }
    }, diff);
    // const filterJsonkeyHasNone = R.filter((item) => item.type === 'fieldName' && !item.jsonKey, diff);
    // if (filterJsonkeyHasNone.length > 0) return;
    // R.forEach((k) => {
    //   keywords[this.retureFieldNameKeyWord(k)] = {
    //     type: k.type,
    //     keyword: this.retureFieldNameKeyWord(k),
    //     isCritical: k.isCritical,
    //     isHotEventOnly: k.isHotEventOnly,
    //     systemTag: k.systemTag,
    //   };
    // }, diff);
    createLoadAction(
      ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
      { projectName, type: 'whitelist', keywords },
      this.submitLoader,
    );
  }

  @autobind
  handleSaveClickBlacklist(type) {
    return (e) => {
      e.preventDefault();
      const { projectName, createLoadAction } = this.props;
      const keywords = {};
      let diff = [];
      if (type === 'trainingBlacklist') {
        diff = this.localKeywordsTrainingBlacklist;
      } else if (type === 'trainingWhitelist') {
        diff = this.localKeywordsTrainingWhitelist;
      } else if (type === 'instanceNameLabels') {
        diff = this.localKeywordsInstanceNameLabels;
      } else if (type === 'anomalyFeature') {
        diff = this.localKeywordsAnomalyFeature;
      } else {
        diff = this.localKeywordsExtractionBlacklist;
      }
      const filterJsonkeyHasNone = R.filter((item) => item.type === 'fieldName' && !item.jsonKey, diff);
      if (filterJsonkeyHasNone.length > 0) return;
      if (type === 'anomalyFeature') {
        R.forEach((k) => {
          keywords[this.retureFieldNameKeyWord(k)] = {
            type: k.type,
            keyword: this.retureFieldNameKeyWord(k),
            name: k.name,
          };
        }, diff);
      } else {
        R.forEach((k) => {
          keywords[this.retureFieldNameKeyWord(k)] = {
            type: k.type,
            keyword: this.retureFieldNameKeyWord(k),
          };
        }, diff);
      }

      createLoadAction(ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING, { projectName, type, keywords }, this.submitLoader);
    };
  }

  @autobind
  handlePrimaryKeyChange(rowData, dataKey, node) {
    return (newVal) => {
      rowData.isImportant = newVal !== -1;
      rowData[dataKey] = newVal;
      this[node].forceUpdateGrid();
      this.forceUpdate();

      if (R.filter((item) => item.priority === 0, this.localKeywordsFeature).length > 1) {
        notification.error({
          message: 'Notification',
          description: 'Feature list can only have one primary key set.',
        });
      }
    };
  }

  @autobind
  handleSaveClickFeature(e) {
    e.preventDefault();
    const { projectName, createLoadAction } = this.props;
    const keywords = {};
    const diff = this.localKeywordsFeature;
    const filterJsonkeyHasNone = R.filter((item) => item.type === 'fieldName' && !item.jsonKey, diff);
    if (filterJsonkeyHasNone.length > 0) return;
    R.forEach((k) => {
      keywords[this.retureFieldNameKeyWord(k)] = {
        type: k.type,
        keyword: this.retureFieldNameKeyWord(k),
        isImportant: k.isImportant,
        priority: k.priority,
        name: k.name,
      };
    }, diff);

    createLoadAction(
      ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
      { projectName, type: 'featurelist', keywords },
      this.submitLoader,
    );
  }

  @autobind
  handleSaveClickKPI(e) {
    e.preventDefault();
    const { projectName, createLoadAction } = this.props;
    const diff = this.localKeywordsKPI;

    createLoadAction(
      ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
      { projectName, type: 'kpilist', keywords: diff },
      this.submitLoader,
    );
  }

  @autobind
  handleSaveClickCategory(event) {
    event.preventDefault();
    const { projectName, createLoadAction } = this.props;
    const diff = this.localKeywordsCategory;

    createLoadAction(
      ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
      { projectName, type: 'categorylist', keywords: diff },
      this.submitLoader,
    );
  }

  @autobind
  handleSaveClickPatternNameLabels(event) {
    if (event) event.preventDefault();
    const { projectName, createLoadAction } = this.props;
    const keywords = {};
    const diff = this.localPatternNameLabels;
    R.forEach((k) => {
      const keyword = this.getKeyword(k);
      if (keyword) {
        keywords[keyword] = {
          type: k.type,
          keyword,
          // instanceSet: k.instanceSet,
          // countThreshold: k.countThreshold || 0,
          patternName: k.patternName,
          patternNameKey: k.patternNameKey,
          order: k.order,
          systemTag: k.systemTag,
        };
      }
    }, diff);
    createLoadAction(
      ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
      { projectName, type: 'patternName', keywords },
      this.submitLoader,
    );
  }

  @autobind
  handleSaveClickIncident(event) {
    if (event) event.preventDefault();
    const { projectName, createLoadAction } = this.props;
    const keywords = {};
    const diff = this.localKeywordsIncident;
    R.forEach((k) => {
      const keyword = this.getKeyword(k);
      if (keyword) {
        keywords[keyword] = {
          type: k.type,
          keyword,
          instanceSet: k.instanceSet,
          countThreshold: k.countThreshold || 0,
          // patternName: k.patternName,
          order: k.order,
          systemTag: k.systemTag,
          patternNameLabels: k.patternNameLabels,
        };
      }
    }, diff);
    createLoadAction(
      ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
      { projectName, type: 'incidentlist', keywords },
      this.submitLoader,
    );
  }

  @autobind
  handleSaveClickTriageReport(event) {
    event.preventDefault();
    const { projectName, createLoadAction } = this.props;
    const keywords = {};
    const diff = this.localKeywordsTriageReport;
    const filterJsonkeyHasNone = R.filter((item) => item.type === 'fieldName' && !item.jsonKey, diff);
    if (filterJsonkeyHasNone.length > 0) return;
    R.forEach((k) => {
      keywords[this.retureFieldNameKeyWord(k)] = {
        type: k.type,
        keyword: this.retureFieldNameKeyWord(k),
      };
    }, diff);

    createLoadAction(
      ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
      { projectName, type: 'triagelist', keywords },
      this.submitLoader,
    );
  }

  @autobind
  handleSaveClickDataFilter(event) {
    event.preventDefault();
    const { projectName, createLoadAction } = this.props;
    const keywords = {};
    const diff = this.localKeywordsDataFilter;
    const filterJsonkeyHasNone = R.filter((item) => item.type === 'fieldName' && !item.jsonKey, diff);
    if (filterJsonkeyHasNone.length > 0) return;
    R.forEach((k) => {
      keywords[this.retureFieldNameKeyWord(k)] = {
        type: k.type,
        keyword: this.retureFieldNameKeyWord(k),
      };
    }, diff);

    createLoadAction(
      ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
      { projectName, type: 'dataFilter', keywords },
      this.submitLoader,
    );
  }

  @autobind
  handleRegexRender(props) {
    const { intl } = this.props;
    const { label } = props;
    return (
      <span className="clickable flex-row flex-center-align" style={{ marginBottom: 4 }}>
        <span style={{ marginRight: 4 }}>{label}</span>
        <Popover title={null} content={intl.formatMessage(settingsMessages.regexIsCaseSensitive)} placement="right">
          <QuestionCircleOutlined />
        </Popover>
      </span>
    );
  }

  @autobind
  handleSaveSystemKeyword() {
    const { credentials, currentProject, projectName, createLoadAction } = this.props;
    const { systemKeywordSettingValue, knownSystemType, knownSystemJsonKeys, systemKeywordSettingList } = this.state;
    const { projectShortName, owner } = currentProject;

    let keywordList = [];
    R.forEach((item) => {
      const keywordSetting = R.find((_item) => _item.value === item, systemKeywordSettingList || []);
      if (keywordSetting) keywordList = [...keywordList, keywordSetting];
    }, systemKeywordSettingValue);

    const jsonSettingMap = R.groupBy((item) => item.systemTag, knownSystemJsonKeys || []);

    this.setState({ keywordLoading: true });
    const request = [];
    R.forEach((item) => {
      const jsonMap = {};
      R.forEach((_item) => {
        jsonMap[_item.value] = _item.title;
      }, jsonSettingMap[item.value] || []);

      request.push(
        fetchPost(getEndpoint('SystemErrorLogKeywordSetting'), {
          ...credentials,
          projectName: projectShortName,
          type: knownSystemType,
          systemTag: item.value,
          customerName: owner,
          ...(knownSystemType === 'fieldName' && R.keys(jsonMap).length > 0
            ? { jsonSetting: JSON.stringify(jsonMap) }
            : {}),
        }),
      );
    }, keywordList);
    Promise.all(request)
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          createLoadAction(ActionTypes.LOAD_PROJECT_KEYWORDS_SETTING, { projectName }, this.dataLoader, false, () => {
            this.setState({
              systemKeywordSettingValue: [],
              knownSystemType: 'content',
              knownSystemJsonKeys: [],
              keywordLoading: false,
            });
          });
        } else {
          message.error(msg);
          this.setState({ keywordLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ keywordLoading: false });
        message.error(err.message || String(err));
      });
  }

  @autobind
  handleSavePatternNameLabels() {
    const { patternNameLabelsMappingList, patternNameLabelsMappingValue, knownSystemType, knownSystemJsonKey } =
      this.state;
    const findDataList = [];
    R.forEach((item) => {
      const findData = R.find((_item) => _item.value === item, patternNameLabelsMappingList);
      if (findData) findDataList.push(findData);
    }, patternNameLabelsMappingValue);

    const patternNameLabels = [];
    R.forEach((item) => {
      const { data, value } = item;
      R.forEachObjIndexed((_value, _key) => {
        patternNameLabels.push({
          countThreshold: 0,
          isNew: true,
          keyword: knownSystemType === 'fieldName' ? `${knownSystemJsonKey}=${_key}` : _key,
          type: knownSystemType,
          patternName: _value,
          systemTag: value,
        });
      }, data || {});
    }, findDataList);
    this.localPatternNameLabels = R.addIndex(R.map)(
      (item, idx) => ({ ...item, order: idx + 1 }),
      [...patternNameLabels, ...this.localPatternNameLabels],
    );
    this.setState({ patternNameLabelsMappingValue: [], knownSystemType: 'content', knownSystemJsonKey: null });
    this.handleSaveClickPatternNameLabels();
  }

  @autobind
  handleSaveSystemIncidentLabels() {
    const { incidentPatterMappingList, incidentPatterMappingValue, knownSystemType, knownSystemJsonKey } = this.state;
    const findDataList = [];
    R.forEach((item) => {
      const findData = R.find((_item) => _item.value === item, incidentPatterMappingList);
      if (findData) findDataList.push(findData);
    }, incidentPatterMappingValue);

    const systemIncidentLabels = [];
    R.forEach((item) => {
      const { data, value } = item;
      R.forEachObjIndexed((_value, _key) => {
        systemIncidentLabels.push({
          countThreshold: 0,
          isNew: true,
          keyword: knownSystemType === 'fieldName' ? `${knownSystemJsonKey}=${_key}` : _key,
          type: knownSystemType,
          // patternName: _value,
          patternNameLabels: [{ patternName: _value || _key, type: knownSystemType, order: 1 }],
          systemTag: value,
        });
      }, data || {});
    }, findDataList);
    this.localKeywordsIncident = R.addIndex(R.map)(
      (item, idx) => ({ ...item, order: idx + 1 }),
      [...systemIncidentLabels, ...this.localKeywordsIncident],
    );
    this.setState({ incidentPatterMappingValue: [], knownSystemType: 'content', knownSystemJsonKey: null });
    this.handleSaveClickIncident();
  }

  @autobind
  allCheckCellRender(fieldName, list, tableRef) {
    return () => {
      return (
        <input
          className="fui input"
          type="checkbox"
          checked={this.state[fieldName] || false}
          onChange={(e) => {
            const newVal = e.target.checked;
            this.setState({ [fieldName]: newVal }, () => {
              R.forEach((item) => {
                item.checked = newVal;
              }, list);
              if (tableRef) {
                tableRef.forceUpdateGrid();
                this.forceUpdate();
              }
            });
          }}
        />
      );
    };
  }

  @autobind
  checkCellRender(fieldName, { dataKey, rowData, cellData }, list, tableRef) {
    return (
      <input
        className="fui input"
        type="checkbox"
        checked={cellData || false}
        onChange={(e) => {
          const newVal = e.target.checked;
          rowData[dataKey] = newVal;
          const newAllChecked = !R.find((item) => !item.checked, list);
          this.setState({ [fieldName]: newAllChecked });
          if (tableRef) {
            tableRef.forceUpdateGrid();
            this.forceUpdate();
          }
        }}
      />
    );
  }

  @autobind
  handleCheckedRemoveClick(list, removeType) {
    const { createLoadAction, projectName } = this.props;
    if (removeType === 'categorylist') {
      const keywords = R.map((item) => {
        if (item.checked) {
          return { ...item, keyword: '' };
        }
        return item;
      }, list);
      createLoadAction(
        ActionTypes.SAVE_PROJECT_KEYWORDS_SETTING,
        { projectName, type: removeType, keywords },
        this.submitLoader,
      );
    } else {
      const keywordsList = R.filter((item) => item.checked, list);
      const keywords = R.map((item) => this.retureFieldNameKeyWord(item), keywordsList);
      createLoadAction(
        ActionTypes.DELETE_PROJECT_KEYWORDS_SETTING,
        {
          projectName,
          type: removeType,
          keywords,
        },
        this.submitLoader,
      );
    }
    this.setState({
      whitelistCheckedAll: false,
      trainingWhitelistCheckedAll: false,
      instanceNameLabelsCheckedAll: false,
      trainingBlacklistCheckedAll: false,
      extractionBlacklistCheckedAll: false,
      featurelistCheckedAll: false,
      anomalyFeatureCheckedAll: false,
      categorylistCheckedAll: false,
      incidentlistCheckedAll: false,
      patternNameLabelsCheckedAll: false,
      triagelistCheckedAll: false,
      dataFilterCheckedAll: false,
    });
  }

  @autobind
  systemCellRender({ cellData }) {
    return (
      <Popover title={null} content={cellData} placement="right">
        <span className="hidden-line-with-ellipsis inline-block clickable">{cellData}</span>
      </Popover>
    );
  }

  @autobind
  configRegexCellRender(type) {
    return ({ rowData, rowIndex }) => {
      const { intl } = this.props;
      return (
        <Button
          size="small"
          disabled={this.fieldIsNullConfig(rowData)}
          onClick={() =>
            this.setState({
              showPatternNameRegex: true,
              activeEvent: R.clone({ data: rowData, index: rowIndex, type }),
            })
          }
        >
          {intl.formatMessage(appFieldsMessages.config)}
        </Button>
      );
    };
  }

  @autobind
  sortTable({ sortBy, sortDirection }, sortByType, sortDirectionType) {
    this.setState({ [sortByType]: sortBy, [sortDirectionType]: sortDirection });
  }

  @autobind
  headerSortRenderer({ dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div style={{ height: 24 }}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  renderKeyword(isLoading, hasError) {
    const { intl, entry } = this.props;
    const { systemKeywordSettingList, systemKeywordSettingValue, knownSystemType, knownSystemJsonKeys } = this.state;
    const { keywordSortBy, keywordSortDirection, fieldNameOptions, keywordLoading } = this.state;

    let filterKeywordsList = this.localKeywordsList;
    if (this.searchSystem) {
      filterKeywordsList = R.filter(
        (item) => (item.systemTag || '').toLowerCase().includes(this.searchSystem.toLowerCase()),
        filterKeywordsList || [],
      );
    }

    return (
      <div className="full-height flex-col">
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={this.handleAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          {entry !== 'batch' && (
            <>
              <div style={{ margin: '0 8px 0 16px' }}>{intl.formatMessage(settingsMessages.knownSystemList)}: </div>
              <Select
                allowClear
                showSearch
                size="small"
                mode="multiple"
                optionFilterProp="label"
                value={systemKeywordSettingValue}
                onChange={(systemKeywordSettingValue) => this.setState({ systemKeywordSettingValue })}
                options={systemKeywordSettingList}
                style={{ minWidth: 200, maxWidth: 500, margin: '0 8px 0 0' }}
              />
              <Popconfirm
                placement="bottom"
                icon={null}
                title={
                  <div className="overflow-y-auto" style={{ maxHeight: 400, paddingRight: 16 }}>
                    <div className="flex-row flex-center-align">
                      <span style={{ flexShrink: 0, minWidth: 70, marginRight: 8, fontSize: 16, fontWeight: 'bold' }}>
                        {intl.formatMessage(appFieldsMessages.type)}:
                      </span>
                      <Select
                        className="full-width"
                        size="small"
                        style={{ width: 200 }}
                        value={knownSystemType}
                        onChange={(knownSystemType) => {
                          let newState = { knownSystemType };
                          if (knownSystemType === 'fieldName') {
                            const keywordList = [];
                            R.forEach((item) => {
                              const keywordSetting = R.find(
                                (_item) => _item.value === item,
                                systemKeywordSettingList || [],
                              );
                              if (keywordSetting && keywordSetting?.data) {
                                R.forEachObjIndexed((info, base) => {
                                  keywordList.push({ systemTag: keywordSetting.value, title: base, info, value: '' });
                                }, keywordSetting?.data || {});
                              }
                            }, systemKeywordSettingValue);
                            newState = { ...newState, knownSystemJsonKeys: keywordList };
                          } else {
                            newState = { ...newState, knownSystemJsonKeys: [] };
                          }
                          this.setState(newState);
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {R.map(
                          (item) => (
                            <Select.Option key={item.value} value={item.value}>
                              {item.label}
                            </Select.Option>
                          ),
                          this.typeList,
                        )}
                      </Select>
                    </div>
                    {knownSystemType === 'fieldName' && (
                      <>
                        {R.addIndex(R.map)((item, idx) => {
                          const prvData = knownSystemJsonKeys[idx - 1];
                          const showTag = item.systemTag !== prvData?.systemTag;
                          return (
                            <>
                              {showTag && (
                                <div
                                  style={{
                                    marginTop: 12,
                                    paddingTop: 8,
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    borderTop: '2px dashed var(--virtualized-table-border-color)',
                                  }}
                                >
                                  {item.systemTag}
                                </div>
                              )}
                              <div className="flex-row flex-center-align" style={{ marginTop: 4, marginLeft: 8 }}>
                                <div
                                  className="flex-row flex-center-align flex-wrap break-word"
                                  style={{ flexShrink: 0, minWidth: 70, maxWidth: 130, marginRight: 8, fontSize: 14 }}
                                >
                                  <Popover
                                    title={null}
                                    content={
                                      <div style={{ maxWidth: 320, maxHeight: 240, overflowY: 'auto' }}>
                                        {item.info}
                                      </div>
                                    }
                                    placement="left"
                                  >
                                    {item.title}
                                    <QuestionCircleOutlined style={{ margin: '0 4px' }} className="clickable" />:
                                  </Popover>
                                </div>
                                <AutoComplete
                                  size="small"
                                  allowClear
                                  options={fieldNameOptions}
                                  value={item.value}
                                  placeholder={intl.formatMessage(logMessages.regexPlaceHolderForWhiteListJsonKey)}
                                  filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf((item.value || '').toUpperCase()) !== -1
                                  }
                                  onChange={(value) => {
                                    const { knownSystemJsonKeys } = this.state;
                                    const newKeywordList = update(knownSystemJsonKeys, {
                                      [idx]: {
                                        $set: {
                                          ...knownSystemJsonKeys[idx],
                                          ...{ value },
                                        },
                                      },
                                    });
                                    this.setState({ knownSystemJsonKeys: newKeywordList });
                                  }}
                                  style={{ width: 200 }}
                                  className={`${item.value ? '' : 'jsonKeyNoneError'}`}
                                />
                              </div>
                            </>
                          );
                        }, knownSystemJsonKeys || [])}
                      </>
                    )}
                  </div>
                }
                onConfirm={this.handleSaveSystemKeyword}
                onCancel={() => this.setState({ knownSystemType: 'content', knownSystemJsonKeys: [] })}
                okText={intl.formatMessage(appButtonsMessages.update)}
                cancelText={intl.formatMessage(appButtonsMessages.cancel)}
                okButtonProps={{
                  disabled:
                    knownSystemType === 'fieldName' && R.filter((item) => !item.value, knownSystemJsonKeys).length > 0,
                }}
                onOpenChange={(open) => !open && this.setState({ knownSystemType: 'content', knownSystemJsonKeys: [] })}
              >
                <Button size="small" type="primary" disabled={systemKeywordSettingValue.length === 0}>
                  {intl.formatMessage(appButtonsMessages.update)}
                </Button>
              </Popconfirm>
              <div style={{ margin: '0 8px 0 16px' }}>{intl.formatMessage(appFieldsMessages.system)}: </div>
              <Input
                size="small"
                allowClear
                style={{ maxWidth: 150 }}
                value={this.searchSystem}
                onChange={(e) => {
                  this.searchSystem = e.target.value;
                  this.forceUpdate();
                }}
              />
            </>
          )}
        </div>
        <Container className={`flex-grow field${isLoading || keywordLoading ? ' disabled' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <SortableTable
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCache}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCache.rowHeight}
                rowCount={filterKeywordsList.length}
                rowGetter={({ index }) => filterKeywordsList[index]}
                ref={(c) => {
                  this.table = c && c.getWrappedInstance();
                }}
                sort={(sortProps) => this.sortTable(sortProps, 'keywordSortBy', 'keywordSortDirection')}
                sortBy={keywordSortBy}
                sortDirection={keywordSortDirection}
                key={filterKeywordsList.length}
                lockAxis="y"
                useDragHandle
                onSortEnd={this.onWhitelistLabelsSortEnd}
                rowRenderer={(params) => <SortableTableRowRenderer {...params} />}
                rowStyle={{ zIndex: 1001 }}
              >
                <Column
                  width={40}
                  disableSort
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.allCheckCellRender('whitelistCheckedAll', filterKeywordsList, this.table)}
                  cellRenderer={(params) =>
                    this.checkCellRender('whitelistCheckedAll', params, filterKeywordsList, this.table)
                  }
                />
                <Column
                  width={65}
                  label={intl.formatMessage(appFieldsMessages.order)}
                  disableSort
                  dataKey="order"
                  className="white-pre"
                  headerRenderer={this.handleWhitelistLabelsOrderRender}
                />
                <Column
                  width={120}
                  disableSort
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={this.renderTypeSelectList('table')}
                />
                <Column
                  width={430}
                  disableSort
                  label={intl.formatMessage(appFieldsMessages.detectionRegex)}
                  dataKey="keyword"
                  flexGrow={1}
                  flexShrink={0}
                  // cellRenderer={this.renderKeywordsInput('cellMeasureCache', 'table')}
                  cellRenderer={this.renderKeywordsInputList('cellMeasureCache', 'table')}
                  headerRenderer={this.handleRegexRender}
                />
                <Column
                  width={80}
                  disableSort
                  label={intl.formatMessage(appFieldsMessages.patternNameRegex)}
                  dataKey="configRegex"
                  className="text-center"
                  flexShrink={0}
                  headerStyle={{ whiteSpace: 'pre-wrap' }}
                  headerClassName="text-center"
                  cellRenderer={this.configRegexCellRender('localKeywordsList')}
                  headerRenderer={({ label }) => label}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(appFieldsMessages.system)}
                  dataKey="systemTag"
                  disableSort
                  headerRenderer={this.headerSortRenderer}
                  cellRenderer={this.systemCellRender}
                />
                <Column
                  width={54}
                  disableSort
                  label={intl.formatMessage(eventMessages.critical)}
                  dataKey="isCritical"
                  className="text-center"
                  headerClassName="text-center"
                  cellRenderer={this.checkboxCellRender('table')}
                />
                <Column
                  width={70}
                  disableSort
                  label={intl.formatMessage(eventMessages.hotEventOnly)}
                  dataKey="isHotEventOnly"
                  className="text-center"
                  headerClassName="text-center"
                  flexShrink={0}
                  headerStyle={{ whiteSpace: 'pre-wrap' }}
                  cellRenderer={this.checkboxCellRender('table')}
                  headerRenderer={({ label }) => label}
                />
                <Column
                  width={this.entry !== 'batch' ? 290 : 140}
                  disableSort
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer('table', 'whitelist', 'localKeywordsList', 'whitelist')}
                  dataKey="userName"
                />
              </SortableTable>
            )}
          </AutoSizer>
        </Container>
        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              disabled={!R.find((item) => item.checked, filterKeywordsList)}
              onClick={() => this.handleCheckedRemoveClick(filterKeywordsList, 'whitelist')}
              style={{ marginRight: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
            <Button size="small" type="primary" loading={isLoading} disabled={hasError} onClick={this.handleSaveClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  retrunTableRef(type) {
    let tableRef;
    if (type === 'trainingBlacklist') {
      tableRef = this.tableTrainingBlacklist;
    } else if (type === 'trainingWhitelist') {
      tableRef = this.tableTrainingWhitelist;
    } else if (type === 'instanceNameLabels') {
      tableRef = this.tableInstanceNameLabels;
    } else if (type === 'anomalyFeature') {
      tableRef = this.tableAnomalyFeature;
    } else {
      tableRef = this.tableExtractionBlacklist;
    }
    return tableRef;
  }

  @autobind
  renderKeywordBlacklist(isLoading, hasErrorBlack, type) {
    const { intl } = this.props;
    let filterExtractionBlacklist = this.typeLocalKeyWordMap[type];
    if (type === 'extractionBlacklist' && this.searchValue) {
      filterExtractionBlacklist = R.filter(
        (item) =>
          R.toLower(item.type === 'content' ? item.keyword : `${item.jsonKey}=${item.keyword}`).includes(
            R.toLower(this.searchValue),
          ),
        filterExtractionBlacklist || [],
      );
    }
    return (
      <div className="full-height flex-col">
        <div style={{ marginBottom: 8, display: type === 'extractionBlacklist' ? 'flex' : '' }}>
          <Button size="small" type="primary" onClick={this.handleBlacklistAddClick(type)}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          {type === 'extractionBlacklist' && (
            <div className="flex-row flex-center-align" style={{ marginLeft: 20 }}>
              <div style={{ marginRight: 8, fontWeight: 'bold' }}>{intl.formatMessage(appFieldsMessages.regex)}:</div>
              <Input
                size="small"
                allowClear
                value={this.searchValue}
                onChange={(e) => {
                  if (e.target.value) {
                    const list = R.filter(
                      (item) =>
                        R.toLower(item.type === 'content' ? item.keyword : `${item.jsonKey}=${item.keyword}`).includes(
                          R.toLower(e.target.value),
                        ),
                      this.typeLocalKeyWordMap[type] || [],
                    );
                    this.setState({ [`${type}CheckedAll`]: !R.find((item) => !item.checked, list) });
                  } else {
                    this.setState({
                      [`${type}CheckedAll`]: !R.find((item) => !item.checked, this.typeLocalKeyWordMap[type] || []),
                    });
                  }
                  this.searchValue = e.target.value;
                  this.forceUpdate();
                }}
                style={{ maxWidth: 150 }}
              />
            </div>
          )}
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.typeCacheMap[[type]]}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.typeCacheMap[type].rowHeight}
                rowCount={filterExtractionBlacklist.length}
                rowGetter={({ index }) => filterExtractionBlacklist[index]}
                ref={(c) => {
                  if (type === 'trainingBlacklist') {
                    this.tableTrainingBlacklist = c;
                  } else if (type === 'trainingWhitelist') {
                    this.tableTrainingWhitelist = c;
                  } else if (type === 'instanceNameLabels') {
                    this.tableInstanceNameLabels = c;
                  } else if (type === 'anomalyFeature') {
                    this.tableAnomalyFeature = c;
                  } else {
                    this.tableExtractionBlacklist = c;
                  }
                }}
              >
                <Column
                  width={40}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.allCheckCellRender(
                    `${type}CheckedAll`,
                    filterExtractionBlacklist,
                    this.retrunTableRef(type),
                  )}
                  cellRenderer={(params) =>
                    this.checkCellRender(
                      `${type}CheckedAll`,
                      params,
                      filterExtractionBlacklist,
                      this.retrunTableRef(type),
                    )
                  }
                />
                <Column
                  width={140}
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={this.renderTypeSelect(this.typeTableMap[type])}
                />
                {type === 'anomalyFeature' && (
                  <Column
                    width={150}
                    label="Feature name"
                    dataKey="name"
                    cellRenderer={this.renderFeatureName(this.typeTableMap[type])}
                  />
                )}
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.regex)}
                  dataKey="keyword"
                  flexGrow={1}
                  cellRenderer={this.renderKeywordsInputBlacklist(type)}
                  headerRenderer={this.handleRegexRender}
                />
                <Column
                  width={100}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRendererBlacklist(type)}
                  dataKey="userName"
                />
              </Table>
            )}
          </AutoSizer>
        </Container>

        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              disabled={!R.find((item) => item.checked, filterExtractionBlacklist)}
              onClick={() => this.handleCheckedRemoveClick(filterExtractionBlacklist, type)}
              style={{ marginRight: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
            <Button
              type="primary"
              size="small"
              loading={isLoading}
              disabled={hasErrorBlack}
              onClick={this.handleSaveClickBlacklist(type)}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  renderFeatureName(node) {
    return ({ dataKey, rowData, cellData }) => {
      return (
        <Input
          size="small"
          placeholder=""
          value={cellData}
          onChange={this.handleKeywordsChanged(rowData, dataKey, node)}
        />
      );
    };
  }

  @autobind
  renderKeywordFeature(isLoading, hasErrorFeature) {
    const { intl } = this.props;
    return (
      <div className="full-height flex-col">
        <div style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={this.handleFeatureAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`} style={{ overflow: 'overlay hidden' }}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width <= 930 ? 930 : width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCacheFeature}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCacheFeature.rowHeight}
                rowCount={this.localKeywordsFeature.length}
                rowGetter={({ index }) => this.localKeywordsFeature[index]}
                ref={(c) => {
                  this.tableFeature = c;
                }}
              >
                <Column
                  width={40}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.allCheckCellRender(
                    'featurelistCheckedAll',
                    this.localKeywordsFeature,
                    this.tableFeature,
                  )}
                  cellRenderer={(params) =>
                    this.checkCellRender('featurelistCheckedAll', params, this.localKeywordsFeature, this.tableFeature)
                  }
                />
                <Column
                  width={140}
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={this.renderTypeSelect('tableFeature')}
                />
                <Column
                  width={150}
                  label="Feature name"
                  dataKey="name"
                  cellRenderer={this.renderFeatureName('tableFeature')}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(appFieldsMessages.regex)}
                  dataKey="keyword"
                  flexGrow={1}
                  cellRenderer={this.renderKeywordsInput('cellMeasureCacheFeature', 'tableFeature')}
                  headerRenderer={this.handleRegexRender}
                />
                <Column
                  width={120}
                  label={intl.formatMessage(settingsMessages.groupingPriority)}
                  dataKey="priority"
                  className="text-center"
                  headerClassName="text-center"
                  cellRenderer={this.renderGroupingPrioritySelect}
                />
                <Column
                  width={100}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer(
                    'tableFeature',
                    'featurelist',
                    'localKeywordsFeature',
                    'featurelist',
                  )}
                  dataKey="userName"
                />
              </Table>
            )}
          </AutoSizer>
        </Container>
        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              disabled={!R.find((item) => item.checked, this.localKeywordsFeature)}
              onClick={() => this.handleCheckedRemoveClick(this.localKeywordsFeature, 'featurelist')}
              style={{ marginRight: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
            <Button
              size="small"
              type="primary"
              loading={isLoading}
              disabled={hasErrorFeature}
              onClick={this.handleSaveClickFeature}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  renderKeywordKpi(isLoading, hasErrorKPI) {
    const { intl } = this.props;
    return (
      <div className="full-height flex-col">
        <div style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={this.handleKPIAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCacheKPI}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCacheKPI.rowHeight}
                rowCount={this.localKeywordsKPI.length}
                rowGetter={({ index }) => this.localKeywordsKPI[index]}
                ref={(c) => {
                  this.tableKPI = c;
                }}
              >
                <Column
                  width={120}
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={this.renderTypeSelect('tableKPI')}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.regex)}
                  dataKey="keyword"
                  flexGrow={1}
                  cellRenderer={this.renderKeywordsInput('cellMeasureCacheKPI', 'tableKPI')}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(settingsMessages.metricProject)}
                  dataKey="metricProject"
                  cellRenderer={this.inputCellRender('tableKPI')}
                />
                <Column
                  width={100}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer('tableKPI', 'kpilist', 'localKeywordsKPI', 'kpilist')}
                  dataKey="userName"
                />
              </Table>
            )}
          </AutoSizer>
        </Container>
        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              loading={isLoading}
              disabled={hasErrorKPI}
              onClick={this.handleSaveClickKPI}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  renderKeywordCategory(isLoading, hasErrorCategory) {
    const { intl } = this.props;
    return (
      <div className="full-height flex-col">
        <div style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={this.handleCategoryAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCacheCategory}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCacheCategory.rowHeight}
                rowCount={this.localKeywordsCategory.length}
                rowGetter={({ index }) => this.localKeywordsCategory[index]}
                ref={(c) => {
                  this.tableCategory = c;
                }}
              >
                <Column
                  width={40}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.allCheckCellRender(
                    'categorylistCheckedAll',
                    this.localKeywordsCategory,
                    this.tableCategory,
                  )}
                  cellRenderer={(params) =>
                    this.checkCellRender(
                      'categorylistCheckedAll',
                      params,
                      this.localKeywordsCategory,
                      this.tableCategory,
                    )
                  }
                />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.category)}
                  dataKey="category"
                  className="white-pre"
                  cellRenderer={this.inputCellRender('tableCategory')}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.keywords)}
                  dataKey="keyword"
                  flexGrow={1}
                  cellRenderer={this.renderKeywordsInput('cellMeasureCacheCategory', 'tableCategory')}
                />
                <Column
                  width={100}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer(
                    'tableCategory',
                    'categorylist',
                    'localKeywordsCategory',
                    'categorylist',
                  )}
                  dataKey="userName"
                />
              </Table>
            )}
          </AutoSizer>
        </Container>
        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              disabled={!R.find((item) => item.checked, this.localKeywordsCategory)}
              onClick={() => this.handleCheckedRemoveClick(this.localKeywordsCategory, 'categorylist')}
              style={{ marginRight: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
            <Button
              size="small"
              type="primary"
              loading={isLoading}
              disabled={hasErrorCategory}
              onClick={this.handleSaveClickCategory}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  handleThresholdChange({ value, rowData, dataKey, node }) {
    const newVal = value || (dataKey === 'countThreshold' ? 0 : '');
    // Save the data and force update.
    rowData[dataKey] = newVal;
    this[node].forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  patternNameInput(cache, node) {
    return ({ dataKey, parent, rowIndex, cellData, rowData }) => {
      const { patternNameKey } = rowData;
      return (
        <CellMeasurer cache={this[cache]} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
          <div className="flex-row flex-center-align">
            <AutoComplete
              allowClear
              size="small"
              value={cellData}
              options={this.incidentListData}
              style={{ width: 200 }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              onChange={(incident) => this.handleThresholdChange({ value: incident, rowData, dataKey, node })}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
              }
              className={`${cellData || patternNameKey ? '' : 'jsonKeyNoneError'}`}
            />
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  patternNameKeyInput(cache, node) {
    return ({ dataKey, parent, rowIndex, cellData, rowData }) => {
      const { type, patternName } = rowData;
      const isJSON = type === 'fieldName';
      return (
        <CellMeasurer cache={this[cache]} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
          <div className="flex-row flex-center-align">
            <AutoComplete
              allowClear
              size="small"
              value={cellData}
              options={isJSON ? this.state.fieldNameOptions : []}
              style={{ width: 200 }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ maxWidth: 650 }}
              onChange={(incident) => this.handleThresholdChange({ value: incident, rowData, dataKey, node })}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
              }
              className={`${cellData || patternName ? '' : 'jsonKeyNoneError'}`}
            />
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  renderInputNumber(cache, node) {
    return ({ dataKey, parent, rowIndex, cellData, rowData }) => {
      return (
        <CellMeasurer cache={this[cache]} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
          <div className="flex-row flex-center-align">
            <InputNumber
              size="small"
              min={0}
              placeholder="Please input integer"
              value={cellData || 0}
              onChange={(value) => this.handleThresholdChange({ value, rowData, dataKey, node })}
            />
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleOrderRender(props) {
    const { intl } = this.props;
    const { label } = props;
    return (
      <span className="clickable flex-row flex-center-align" style={{ marginBottom: 4 }}>
        <span style={{ marginRight: 4 }}>{label}</span>
        <Popover title={null} content={intl.formatMessage(settingsMessages.incidentPatternOrder)} placement="right">
          <QuestionCircleOutlined />
        </Popover>
      </span>
    );
  }

  @autobind
  onSortEnd({ oldIndex, newIndex }) {
    if (oldIndex === newIndex) {
      return;
    }
    this.localKeywordsIncident = R.move(oldIndex, newIndex, R.clone(this.localKeywordsIncident));
    this.localKeywordsIncident = R.addIndex(R.map)(
      (item, idx) => ({ ...item, order: idx + 1 }),
      this.localKeywordsIncident,
    );
    this.handleSaveClickIncident();
    this.cellMeasureCacheIncident.clearAll();
    this.tableIncident.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  handlePatternNameLabelsOrderRender(props) {
    const { intl } = this.props;
    const { label } = props;
    return (
      <span className="clickable flex-row flex-center-align" style={{ marginBottom: 4 }}>
        <span style={{ marginRight: 4 }}>{label}</span>
        <Popover title={null} content={intl.formatMessage(settingsMessages.patternNameLabelsOrder)} placement="right">
          <QuestionCircleOutlined />
        </Popover>
      </span>
    );
  }

  @autobind
  handleWhitelistLabelsOrderRender(props) {
    const { intl } = this.props;
    const { label } = props;
    return (
      <span className="clickable flex-row flex-center-align" style={{ marginBottom: 4 }}>
        <span style={{ marginRight: 4 }}>{label}</span>
        <Popover title={null} content={intl.formatMessage(settingsMessages.whitelistLabelsOrder)} placement="right">
          <QuestionCircleOutlined />
        </Popover>
      </span>
    );
  }

  @autobind
  onWhitelistLabelsSortEnd({ oldIndex, newIndex }) {
    const { entry } = this.props;
    if (oldIndex === newIndex) {
      return;
    }
    this.localKeywordsList = R.move(oldIndex, newIndex, R.clone(this.localKeywordsList));
    this.localKeywordsList = R.addIndex(R.map)((item, idx) => ({ ...item, order: idx + 1 }), this.localKeywordsList);
    if (entry !== 'batch') {
      this.handleSaveClick();
    }
    this.cellMeasureCache.clearAll();
    this.table.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  onPatternNameLabelsSortEnd({ oldIndex, newIndex }) {
    if (oldIndex === newIndex) {
      return;
    }
    this.localPatternNameLabels = R.move(oldIndex, newIndex, R.clone(this.localPatternNameLabels));
    this.localPatternNameLabels = R.addIndex(R.map)(
      (item, idx) => ({ ...item, order: idx + 1 }),
      this.localPatternNameLabels,
    );
    this.handleSaveClickPatternNameLabels();
    this.cellMeasureCachePatternNameLabels.clearAll();
    this.tablePatternNameLabels.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  renderKeywordPatternNameLabels(isLoading, hasErrorPatternNameLabels) {
    const { intl } = this.props;
    const { patternNameLabelsMappingValue, patternNameLabelsMappingList, knownSystemType, knownSystemJsonKey } =
      this.state;
    const { fieldNameOptions } = this.state;
    return (
      <div className="full-height flex-col">
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={this.handlePatternNameLabelsAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          <div style={{ margin: '0 8px 0 16px' }}>{intl.formatMessage(settingsMessages.knownSystemList)}: </div>
          <Select
            allowClear
            showSearch
            size="small"
            mode="multiple"
            optionFilterProp="label"
            value={patternNameLabelsMappingValue}
            onChange={(patternNameLabelsMappingValue) => this.setState({ patternNameLabelsMappingValue })}
            options={patternNameLabelsMappingList}
            style={{ minWidth: 200, margin: '0 8px 0 0' }}
          />
          <Popconfirm
            placement="right"
            icon={null}
            title={
              <div>
                <div className="flex-row flex-center-align">
                  <span style={{ flexShrink: 0, width: 60 }}>{intl.formatMessage(appFieldsMessages.type)}:</span>
                  <Select
                    className="full-width"
                    size="small"
                    style={{ width: 200 }}
                    value={knownSystemType}
                    onChange={(knownSystemType) => this.setState({ knownSystemType, knownSystemJsonKey: null })}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {R.map(
                      (item) => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ),
                      this.typeList,
                    )}
                  </Select>
                </div>
                {knownSystemType === 'fieldName' && (
                  <div className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                    <span style={{ flexShrink: 0, width: 60 }}>{intl.formatMessage(settingsMessages.jsonKey)}:</span>
                    <AutoComplete
                      size="small"
                      allowClear
                      options={fieldNameOptions}
                      value={knownSystemJsonKey}
                      placeholder={intl.formatMessage(logMessages.regexPlaceHolderForWhiteListJsonKey)}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf((knownSystemJsonKey || '').toUpperCase()) !== -1
                      }
                      onChange={(knownSystemJsonKey) => this.setState({ knownSystemJsonKey })}
                      style={{ width: 200 }}
                      className={`${knownSystemJsonKey ? '' : 'jsonKeyNoneError'}`}
                    />
                  </div>
                )}
              </div>
            }
            onConfirm={this.handleSavePatternNameLabels}
            onCancel={() => this.setState({ knownSystemType: 'content', knownSystemJsonKey: null })}
            okText={intl.formatMessage(appButtonsMessages.update)}
            cancelText={intl.formatMessage(appButtonsMessages.cancel)}
            okButtonProps={{ disabled: knownSystemType === 'fieldName' && !knownSystemJsonKey }}
          >
            <Button size="small" type="primary" disabled={patternNameLabelsMappingValue.length === 0}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Popconfirm>
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <SortableTable
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCachePatternNameLabels}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCachePatternNameLabels.rowHeight}
                rowCount={this.localPatternNameLabels.length}
                rowGetter={({ index }) => this.localPatternNameLabels[index]}
                ref={(table) => {
                  this.tablePatternNameLabels = table && table.getWrappedInstance();
                }}
                key={this.localPatternNameLabels.length}
                lockAxis="y"
                useDragHandle
                onSortEnd={this.onPatternNameLabelsSortEnd}
                rowRenderer={(params) => <SortableTableRowRenderer {...params} />}
              >
                <Column
                  width={40}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.allCheckCellRender(
                    'patternNameLabelsCheckedAll',
                    this.localPatternNameLabels,
                    this.tablePatternNameLabels,
                  )}
                  cellRenderer={(params) =>
                    this.checkCellRender(
                      'patternNameLabelsCheckedAll',
                      params,
                      this.localPatternNameLabels,
                      this.tablePatternNameLabels,
                    )
                  }
                />
                <Column
                  width={65}
                  label={intl.formatMessage(appFieldsMessages.order)}
                  dataKey="order"
                  className="white-pre"
                  headerRenderer={this.handlePatternNameLabelsOrderRender}
                />
                <Column
                  width={140}
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={this.renderTypeSelectList('tablePatternNameLabels')}
                />
                <Column
                  width={430}
                  label={intl.formatMessage(appFieldsMessages.regex)}
                  dataKey="keyword"
                  flexGrow={1}
                  flexShrink={0}
                  cellRenderer={this.renderKeywordsInputList(
                    'cellMeasureCachePatternNameLabels',
                    'tablePatternNameLabels',
                  )}
                  headerRenderer={this.handleRegexRender}
                />
                <Column
                  width={120}
                  label={intl.formatMessage(appFieldsMessages.system)}
                  dataKey="systemTag"
                  // headerRenderer={this.headerSortRenderer}
                  cellRenderer={this.systemCellRender}
                />
                <Column
                  width={150}
                  label={intl.formatMessage(logMessages.patternName)}
                  dataKey="patternName"
                  cellRenderer={this.patternNameInput('cellMeasureCachePatternNameLabels', 'tablePatternNameLabels')}
                />
                <Column
                  width={150}
                  label={intl.formatMessage(logMessages.patternNameKey)}
                  dataKey="patternNameKey"
                  cellRenderer={this.patternNameKeyInput('cellMeasureCachePatternNameLabels', 'tablePatternNameLabels')}
                />
                {false && (
                  <>
                    <Column
                      width={70}
                      label={intl.formatMessage(DashboardMessages.threshold)}
                      dataKey="countThreshold"
                      cellRenderer={this.renderInputNumber(
                        'cellMeasureCachePatternNameLabels',
                        'tablePatternNameLabels',
                      )}
                    />
                    <Column
                      width={180}
                      label={intl.formatMessage(DashboardMessages.instances)}
                      dataKey="instanceSet"
                      cellRenderer={this.renderInstanceSelect(
                        'cellMeasureCachePatternNameLabels',
                        'tablePatternNameLabels',
                      )}
                    />
                  </>
                )}
                <Column
                  width={115}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer(
                    'tablePatternNameLabels',
                    'patternNameLabels',
                    'localPatternNameLabels',
                    'patternName',
                  )}
                  dataKey="userName"
                />
              </SortableTable>
            )}
          </AutoSizer>
        </Container>
        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              disabled={!R.find((item) => item.checked, this.localPatternNameLabels)}
              onClick={() => this.handleCheckedRemoveClick(this.localPatternNameLabels, 'patternName')}
              style={{ marginRight: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
            <Button
              size="small"
              type="primary"
              loading={isLoading}
              disabled={hasErrorPatternNameLabels}
              onClick={this.handleSaveClickPatternNameLabels}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  renderKeywordIncident(isLoading, hasErrorIncident) {
    const { intl } = this.props;
    const { incidentPatterMappingValue, incidentPatterMappingList, knownSystemType, knownSystemJsonKey } = this.state;
    const { fieldNameOptions } = this.state;

    let filterKeywordsIncident = this.localKeywordsIncident;
    if (this.searchSystem) {
      filterKeywordsIncident = R.filter(
        (item) => (item.systemTag || '').toLowerCase().includes(this.searchSystem.toLowerCase()),
        filterKeywordsIncident || [],
      );
    }

    return (
      <div className="full-height flex-col">
        <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={this.handleIncidentAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
          <div style={{ margin: '0 8px 0 16px' }}>{intl.formatMessage(settingsMessages.knownSystemList)}: </div>
          <Select
            allowClear
            showSearch
            size="small"
            mode="multiple"
            optionFilterProp="label"
            value={incidentPatterMappingValue}
            onChange={(incidentPatterMappingValue) => this.setState({ incidentPatterMappingValue })}
            options={incidentPatterMappingList}
            style={{ minWidth: 200, maxWidth: 500, margin: '0 8px 0 0' }}
          />
          <Popconfirm
            placement="right"
            icon={null}
            title={
              <div>
                <div className="flex-row flex-center-align">
                  <span style={{ flexShrink: 0, width: 60 }}>{intl.formatMessage(appFieldsMessages.type)}:</span>
                  <Select
                    className="full-width"
                    size="small"
                    style={{ width: 200 }}
                    value={knownSystemType}
                    onChange={(knownSystemType) => this.setState({ knownSystemType, knownSystemJsonKey: null })}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {R.map(
                      (item) => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ),
                      this.typeList,
                    )}
                  </Select>
                </div>
                {knownSystemType === 'fieldName' && (
                  <div className="flex-row flex-center-align" style={{ marginTop: 4 }}>
                    <span style={{ flexShrink: 0, width: 60 }}>{intl.formatMessage(settingsMessages.jsonKey)}:</span>
                    <AutoComplete
                      size="small"
                      allowClear
                      options={fieldNameOptions}
                      value={knownSystemJsonKey}
                      placeholder={intl.formatMessage(logMessages.regexPlaceHolderForWhiteListJsonKey)}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf((knownSystemJsonKey || '').toUpperCase()) !== -1
                      }
                      onChange={(knownSystemJsonKey) => this.setState({ knownSystemJsonKey })}
                      style={{ width: 200 }}
                      className={`${knownSystemJsonKey ? '' : 'jsonKeyNoneError'}`}
                    />
                  </div>
                )}
              </div>
            }
            onConfirm={this.handleSaveSystemIncidentLabels}
            onCancel={() => this.setState({ knownSystemType: 'content', knownSystemJsonKey: null })}
            okText={intl.formatMessage(appButtonsMessages.update)}
            cancelText={intl.formatMessage(appButtonsMessages.cancel)}
            okButtonProps={{ disabled: knownSystemType === 'fieldName' && !knownSystemJsonKey }}
          >
            <Button size="small" type="primary" disabled={incidentPatterMappingValue.length === 0}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Popconfirm>
          <div style={{ margin: '0 8px 0 16px' }}>{intl.formatMessage(appFieldsMessages.system)}: </div>
          <Input
            size="small"
            allowClear
            style={{ maxWidth: 150 }}
            value={this.searchSystem}
            onChange={(e) => {
              this.searchSystem = e.target.value;
              this.forceUpdate();
            }}
          />
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <SortableTable
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCacheIncident}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCacheIncident.rowHeight}
                rowCount={filterKeywordsIncident.length}
                rowGetter={({ index }) => filterKeywordsIncident[index]}
                ref={(table) => {
                  this.tableIncident = table && table.getWrappedInstance();
                }}
                key={filterKeywordsIncident.length}
                lockAxis="y"
                useDragHandle
                onSortEnd={this.onSortEnd}
                rowRenderer={(params) => <SortableTableRowRenderer {...params} />}
              >
                <Column
                  width={40}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.allCheckCellRender(
                    'incidentlistCheckedAll',
                    filterKeywordsIncident,
                    this.tableIncident,
                  )}
                  cellRenderer={(params) =>
                    this.checkCellRender('incidentlistCheckedAll', params, filterKeywordsIncident, this.tableIncident)
                  }
                />
                <Column
                  width={65}
                  label={intl.formatMessage(appFieldsMessages.order)}
                  dataKey="order"
                  className="white-pre"
                  headerRenderer={this.handleOrderRender}
                />
                <Column
                  width={120}
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={this.renderTypeSelectList('tableIncident')}
                />
                <Column
                  width={430}
                  label={intl.formatMessage(appFieldsMessages.incidentRegex)}
                  dataKey="keyword"
                  flexGrow={1}
                  flexShrink={0}
                  cellRenderer={this.renderKeywordsInputList('cellMeasureCacheIncident', 'tableIncident')}
                  headerRenderer={this.handleRegexRender}
                />
                <Column
                  width={80}
                  disableSort
                  label={intl.formatMessage(appFieldsMessages.patternNameRegex)}
                  dataKey="configRegex"
                  className="text-center"
                  flexShrink={0}
                  headerStyle={{ whiteSpace: 'pre-wrap' }}
                  headerClassName="text-center"
                  cellRenderer={this.configRegexCellRender('localKeywordsIncident')}
                  headerRenderer={({ label }) => label}
                />
                <Column
                  width={100}
                  label={intl.formatMessage(appFieldsMessages.system)}
                  dataKey="systemTag"
                  // headerRenderer={this.headerSortRenderer}
                  cellRenderer={this.systemCellRender}
                />
                <Column
                  width={70}
                  label={intl.formatMessage(DashboardMessages.threshold)}
                  dataKey="countThreshold"
                  cellRenderer={this.renderInputNumber('cellMeasureCacheIncident', 'tableIncident')}
                />
                <Column
                  width={130}
                  label={intl.formatMessage(DashboardMessages.instances)}
                  dataKey="instanceSet"
                  cellRenderer={this.renderInstanceSelect('cellMeasureCacheIncident', 'tableIncident')}
                />
                <Column
                  width={115}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer(
                    'tableIncident',
                    'incidentlist',
                    'localKeywordsIncident',
                    'incidentlist',
                  )}
                  dataKey="userName"
                />
              </SortableTable>
            )}
          </AutoSizer>
        </Container>
        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              disabled={!R.find((item) => item.checked, filterKeywordsIncident)}
              onClick={() => this.handleCheckedRemoveClick(filterKeywordsIncident, 'incidentlist')}
              style={{ marginRight: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
            <Button
              size="small"
              type="primary"
              loading={isLoading}
              disabled={hasErrorIncident}
              onClick={this.handleSaveClickIncident}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  renderKeywordTriageReport(isLoading, hasErrorTriageReport) {
    const { intl } = this.props;
    return (
      <div className="full-height flex-col">
        <div style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={this.handleTriageReportAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCacheTriageReport}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCacheTriageReport.rowHeight}
                rowCount={this.localKeywordsTriageReport.length}
                rowGetter={({ index }) => this.localKeywordsTriageReport[index]}
                ref={(table) => {
                  this.tableTriageReport = table;
                }}
              >
                <Column
                  width={40}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.allCheckCellRender(
                    'triagelistCheckedAll',
                    this.localKeywordsTriageReport,
                    this.tableTriageReport,
                  )}
                  cellRenderer={(params) =>
                    this.checkCellRender(
                      'triagelistCheckedAll',
                      params,
                      this.localKeywordsTriageReport,
                      this.tableTriageReport,
                    )
                  }
                />
                <Column
                  width={140}
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={this.renderTypeSelect('tableTriageReport')}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.regex)}
                  dataKey="keyword"
                  flexGrow={1}
                  cellRenderer={this.renderKeywordsInput('cellMeasureCacheTriageReport', 'tableTriageReport')}
                  headerRenderer={this.handleRegexRender}
                />
                <Column
                  width={100}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer(
                    'tableTriageReport',
                    'triagelist',
                    'localKeywordsTriageReport',
                    'triagelist',
                  )}
                  dataKey="userName"
                />
              </Table>
            )}
          </AutoSizer>
        </Container>
        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              disabled={!R.find((item) => item.checked, this.localKeywordsTriageReport)}
              onClick={() => this.handleCheckedRemoveClick(this.localKeywordsTriageReport, 'triagelist')}
              style={{ marginRight: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
            <Button
              type="primary"
              size="small"
              loading={isLoading}
              disabled={hasErrorTriageReport}
              onClick={this.handleSaveClickTriageReport}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  handleDataFilterAddClick(event) {
    event.preventDefault();
    this.localKeywordsDataFilter.unshift({
      keyword: '',
    });
    this.tableDataFilter.forceUpdateGrid();
    this.forceUpdate();
  }

  @autobind
  renderDataFilterReport(isLoading, hasErrorDataFilter) {
    const { intl } = this.props;
    return (
      <div className="full-height flex-col">
        <div style={{ marginBottom: 8 }}>
          <Button size="small" type="primary" onClick={this.handleDataFilterAddClick}>
            {intl.formatMessage(appButtonsMessages.add)}
          </Button>
        </div>
        <Container className={`flex-grow field${isLoading ? ' disabled' : ''}`}>
          <AutoSizer>
            {({ width, height }) => (
              <Table
                className="with-border"
                width={width}
                height={height}
                deferredMeasurementCache={this.cellMeasureCacheDataFilter}
                headerHeight={40}
                rowClassName={({ index }) => (index >= 0 && index % 2 === 1 ? 'odd-row' : '')}
                rowHeight={this.cellMeasureCacheDataFilter.rowHeight}
                rowCount={this.localKeywordsDataFilter.length}
                rowGetter={({ index }) => this.localKeywordsDataFilter[index]}
                ref={(table) => {
                  this.tableDataFilter = table;
                }}
              >
                <Column
                  width={40}
                  dataKey="checked"
                  className="text-center"
                  headerClassName="text-center"
                  headerRenderer={this.allCheckCellRender(
                    'dataFilterCheckedAll',
                    this.localKeywordsDataFilter,
                    this.tableDataFilter,
                  )}
                  cellRenderer={(params) =>
                    this.checkCellRender(
                      'dataFilterCheckedAll',
                      params,
                      this.localKeywordsDataFilter,
                      this.tableDataFilter,
                    )
                  }
                />
                <Column
                  width={140}
                  label={intl.formatMessage(appFieldsMessages.type)}
                  dataKey="type"
                  className="white-pre"
                  cellRenderer={this.renderTypeSelect('tableDataFilter')}
                />
                <Column
                  width={200}
                  label={intl.formatMessage(appFieldsMessages.regex)}
                  dataKey="keyword"
                  flexGrow={1}
                  cellRenderer={this.renderKeywordsInput('cellMeasureCacheDataFilter', 'tableDataFilter')}
                  headerRenderer={this.handleRegexRender}
                />
                <Column
                  width={100}
                  label=""
                  className="text-right"
                  cellRenderer={this.removeRenderer(
                    'tableDataFilter',
                    'dataFilterLabels',
                    'localKeywordsDataFilter',
                    'dataFilter',
                  )}
                  dataKey="userName"
                />
              </Table>
            )}
          </AutoSizer>
        </Container>
        {this.entry !== 'batch' && (
          <Container className="field flex-row" style={{ marginTop: 8 }}>
            <div className="flex-grow" />
            <Button
              size="small"
              type="primary"
              disabled={!R.find((item) => item.checked, this.localKeywordsDataFilter)}
              onClick={() => this.handleCheckedRemoveClick(this.localKeywordsDataFilter, 'dataFilter')}
              style={{ marginRight: 8 }}
            >
              {intl.formatMessage(appButtonsMessages.remove)}
            </Button>
            <Button
              type="primary"
              size="small"
              loading={isLoading}
              disabled={hasErrorDataFilter}
              onClick={this.handleSaveClickDataFilter}
            >
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </Container>
        )}
      </div>
    );
  }

  @autobind
  fieldIsNull(list) {
    const hasData = R.find((n) => {
      if (!n.type) return true;
      if (n.type === 'fieldName') {
        return R.isEmpty(n.jsonKey) || R.isNil(n.keyword);
      } else {
        return R.isEmpty(n.keyword);
      }
    }, list);
    return !!hasData;
  }

  @autobind
  fieldIsNullJsonKey(list) {
    const hasData = R.find((n) => {
      if (!n.type) return true;
      if (n.type === 'fieldName') {
        // return R.isEmpty(n.jsonKey) || R.isNil(n.keyword);
        return !n.jsonKey;
      } else {
        return R.isEmpty(n.keyword);
      }
    }, list);
    return !!hasData;
  }

  @autobind
  fieldIsNullConfig(n) {
    if (!n.type) return true;
    if (n.type === 'fieldName') {
      const [jsonKey, keyword] = R.split('=', n.keyword);
      return R.isEmpty(jsonKey) || R.isNil(keyword);
    } else {
      return R.isEmpty(n.keyword);
    }
  }

  @autobind
  fieldIsNullItem(n) {
    if (!n.type) return true;
    return R.isEmpty(n.keyword);
  }

  @autobind
  fieldIsNullList(list) {
    const hasData = R.find((n) => {
      if (!n.type) return true;
      if (n.type === 'fieldName') {
        const keywordList = this.getKeywordList(n);
        return R.find((item) => R.isEmpty(item.jsonKey) || R.isNil(item.keyword), keywordList);
      } else {
        const keywordList = this.getKeywordList(n);
        return R.find((item) => R.isEmpty(item.keyword), keywordList);
      }
    }, list);
    return !!hasData;
  }

  @autobind
  clearTableCache() {
    this.cellMeasureCache.clearAll();
    this.cellMeasureCacheTrainingWhitelist.clearAll();
    this.cellMeasureCacheInstanceNameLabels.clearAll();
    this.cellMeasureCacheTrainingBlacklist.clearAll();
    this.cellMeasureCacheExtractionBlacklist.clearAll();
    this.cellMeasureCacheFeature.clearAll();
    this.cellMeasureCacheCategory.clearAll();
    this.cellMeasureCacheIncident.clearAll();
    this.cellMeasureCachePatternNameLabels.clearAll();
    this.cellMeasureCacheTriageReport.clearAll();
    this.cellMeasureCacheDataFilter.clearAll();
    if (this.table) this.table.forceUpdateGrid();
    if (this.tableTrainingBlacklist) this.tableTrainingBlacklist.forceUpdateGrid();
    if (this.tableTrainingWhitelist) this.tableTrainingWhitelist.forceUpdateGrid();
    if (this.tableInstanceNameLabels) this.tableInstanceNameLabels.forceUpdateGrid();
    if (this.tableAnomalyFeature) this.tableAnomalyFeature.forceUpdateGrid();
    if (this.tableExtractionBlacklist) this.tableExtractionBlacklist.forceUpdateGrid();
    if (this.tableFeature) this.tableFeature.forceUpdateGrid();
    if (this.tableKPI) this.tableKPI.forceUpdateGrid();
    if (this.tableCategory) this.tableCategory.forceUpdateGrid();
    if (this.tableIncident) this.tableIncident.forceUpdateGrid();
    if (this.tablePatternNameLabels) this.tablePatternNameLabels.forceUpdateGrid();
    if (this.tableTriageReport) this.tableTriageReport.forceUpdateGrid();
    if (this.tableDataFilter) this.tableDataFilter.forceUpdateGrid();
    this.forceUpdate();
  }

  render() {
    const { intl, loadStatus, entry, pickComponents, tabPosition } = this.props;
    const { keywordsList } = this.props;
    const { activeKey, activeEvent, showPatternNameRegex, fieldNameOptions } = this.state;
    const {
      whitelist,
      trainingBlacklist,
      trainingWhitelist,
      instanceNameLabels,
      extractionBlacklist,
      featurelist,
      categorylist,
      incidentlist,
      patternNameLabels,
      triagelist,
      anomalyFeature,
      dataFilterLabels,
    } = keywordsList || {};
    const diff = R.differenceWith(this.cmp, this.localKeywordsList, whitelist || []);
    const diffTrainingBlack = R.differenceWith(
      this.cmpTraining,
      this.localKeywordsTrainingBlacklist,
      trainingBlacklist || [],
    );
    const diffTrainingWhite = R.differenceWith(
      this.cmpTraining,
      this.localKeywordsTrainingWhitelist,
      trainingWhitelist || [],
    );
    const diffInstanceNameLabels = R.differenceWith(
      this.cmpInstanceNameLabels,
      this.localKeywordsInstanceNameLabels,
      instanceNameLabels || [],
    );
    const diffExtractionBlack = R.differenceWith(
      this.cmpExtraction,
      this.localKeywordsExtractionBlacklist,
      extractionBlacklist || [],
    );
    const diffAnomalyFeature = R.differenceWith(this.cmp, this.localKeywordsAnomalyFeature, anomalyFeature || []);
    const diffFeature = R.differenceWith(this.cmpFeature, this.localKeywordsFeature, featurelist || []);
    const diffCategory = R.differenceWith(this.cmpCategory, this.localKeywordsCategory, categorylist || []);
    const diffIncident = R.differenceWith(this.cmpIncident, this.localKeywordsIncident, incidentlist || []);
    const diffPatternNameLabels = R.differenceWith(
      this.cmpPatternNameLabel,
      this.localPatternNameLabels,
      patternNameLabels || [],
    );
    const diffTriageReport = R.differenceWith(this.cmpTriageReport, this.localKeywordsTriageReport, triagelist || []);
    const diffDataFilter = R.differenceWith(this.cmpDataFilter, this.localKeywordsDataFilter, dataFilterLabels || []);

    const diffHasNull = this.fieldIsNullList(diff);
    const diffTrainingWhiteHasNull = this.fieldIsNullJsonKey(diffTrainingWhite);
    const diffInstanceNameLabelsHasNull = this.fieldIsNullJsonKey(diffInstanceNameLabels);
    const diffTrainingBlackHasNull = this.fieldIsNullJsonKey(diffTrainingBlack);
    const diffExtractionBlackHasNull = this.fieldIsNullJsonKey(diffExtractionBlack);
    const diffFeatureHasNull = this.fieldIsNullJsonKey(diffFeature);
    const diffAnomalyFeatureHasNull = this.fieldIsNullJsonKey(diffAnomalyFeature);
    const diffIncidentHasNull = this.fieldIsNullList(diffIncident);
    const diffPatternNameLabelsHasNull = this.fieldIsNullList(diffPatternNameLabels);
    const diffTriageReportHasNull = this.fieldIsNullJsonKey(diffTriageReport);
    const diffDataFilterHasNull = this.fieldIsNullJsonKey(diffDataFilter);

    const hasError = diff.length === 0 || diffHasNull;
    const hasErrorTrainingBlack = diffTrainingBlack.length === 0 || diffTrainingBlackHasNull;
    const hasErrorTrainingWhite = diffTrainingWhite.length === 0 || diffTrainingWhiteHasNull;
    const hasErrorInstanceNameLabels = diffInstanceNameLabels.length === 0 || diffInstanceNameLabelsHasNull;
    const hasErrorExtractionBlack = diffExtractionBlack.length === 0 || diffExtractionBlackHasNull;
    const hasErrorAnomalyFeature = diffAnomalyFeature.length === 0 || diffAnomalyFeatureHasNull;
    const hasErrorFeature =
      diffFeature.length === 0 ||
      R.filter((item) => item.priority === 0, this.localKeywordsFeature).length > 1 ||
      diffFeatureHasNull;
    const hasErrorCategory =
      diffCategory.length === 0 ||
      !R.reduce(
        R.and,
        true,
        R.map((data) => R.test(/^[^\n\r]+(,\s*[^\n\r]+)*$/, data.keyword), diffCategory),
      );
    const hasErrorIncident = diffIncident.length === 0 || diffIncidentHasNull;
    const hasErrorTriageReport = diffTriageReport.length === 0 || diffTriageReportHasNull;
    const hasErrorDataFilter = diffDataFilter.length === 0 || diffDataFilterHasNull;
    const hasErrorPatternNameLabels =
      diffPatternNameLabels.length === 0 ||
      diffPatternNameLabelsHasNull ||
      R.filter((item) => !item.patternName && !item.patternNameKey, this.localPatternNameLabels || []).length > 0;
    const { isLoading } = getLoadStatus(get(loadStatus, this.submitLoader), intl);
    const componentMap = {
      whitelist: (
        <>
          <h3 className="project-setting-title" id="project-setting-anchor-general">
            {intl.formatMessage(settingsMessages.whitelist)}
            <Popover title={null} content={intl.formatMessage(settingsMessages.whitelistDesc)} placement="right">
              <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
            </Popover>
          </h3>
          <div style={GroupBox}>{this.renderKeyword(isLoading, hasError)}</div>
        </>
      ),
      trainingWhitelist: (
        <>
          <h3 className="project-setting-title" id="project-setting-anchor-general">
            {intl.formatMessage(settingsMessages.trainingWhitelist)}
            <Popover
              title={null}
              content={intl.formatMessage(settingsMessages.trainingWhitelistDesc)}
              placement="right"
            >
              <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
            </Popover>
          </h3>
          <div style={GroupBox}>
            {this.renderKeywordBlacklist(isLoading, hasErrorTrainingWhite, 'trainingWhitelist')}
          </div>
        </>
      ),
      instanceNameLabels: (
        <>
          <h3 className="project-setting-title" id="project-setting-anchor-general">
            {intl.formatMessage(settingsMessages.instanceNameLabels)}
            <Popover
              title={null}
              content={intl.formatMessage(settingsMessages.instanceNameLabelsDesc)}
              placement="right"
            >
              <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
            </Popover>
          </h3>
          <div style={GroupBox}>
            {this.renderKeywordBlacklist(isLoading, hasErrorInstanceNameLabels, 'instanceNameLabels')}
          </div>
        </>
      ),
      incident: (
        <>
          <h3 className="project-setting-title" id="project-setting-anchor-general">
            {intl.formatMessage(settingsMessages.incident)}
            <Popover title={null} content={intl.formatMessage(settingsMessages.incidentDesc)} placement="right">
              <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
            </Popover>
          </h3>
          <div style={GroupBox}>{this.renderKeywordIncident(isLoading, hasErrorIncident)}</div>
        </>
      ),
      patternName: (
        <>
          <h3 className="project-setting-title" id="project-setting-anchor-general">
            {intl.formatMessage(settingsMessages.normalPatternName)}
            <Popover
              title={null}
              content={intl.formatMessage(settingsMessages.patternNameLabelsDesc)}
              placement="right"
            >
              <QuestionCircleOutlined style={{ fontSize: 12, marginLeft: 4 }} />
            </Popover>
          </h3>
          <div style={GroupBox}>{this.renderKeywordPatternNameLabels(isLoading, hasErrorPatternNameLabels)}</div>
        </>
      ),
    };

    return (
      <>
        {pickComponents && R.map((cname) => <div key={cname}>{componentMap[cname]}</div>, pickComponents)}
        {!pickComponents && (
          <Container fullHeight className="overflow-y-auto overflow-x-hidden">
            <form className="full-height flex-col" style={{ fontSize: 12 }}>
              <Tabs
                className="full-height ant-tabs-content-full-height tabs-content-padding-top"
                tabBarStyle={{ width: tabPosition === 'top' ? undefined : 250 }}
                tabPosition={tabPosition || 'left'}
                activeKey={activeKey}
                onChange={(activeKey) => {
                  this.searchValue = '';
                  this.searchSystem = '';
                  this.setState(
                    {
                      activeKey,
                      extractionBlacklistCheckedAll:
                        (this.typeLocalKeyWordMap?.extractionBlacklist || []).length > 0 &&
                        !R.find((item) => !item.checked, this.typeLocalKeyWordMap.extractionBlacklist || []),
                      knownSystemType: 'content',
                      knownSystemJsonKey: null,
                    },
                    () => {
                      this.clearTableCache();
                    },
                  );
                }}
              >
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={intl.formatMessage(settingsMessages.whitelistDesc)}
                      placement="right"
                    >
                      {intl.formatMessage(settingsMessages.whitelist)}
                    </Popover>
                  }
                  key="whitelist"
                >
                  {this.renderKeyword(isLoading, hasError)}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={intl.formatMessage(settingsMessages.trainingWhitelistDesc)}
                      placement="right"
                    >
                      {intl.formatMessage(settingsMessages.trainingWhitelist)}
                    </Popover>
                  }
                  key="trainingWhitelist"
                >
                  {this.renderKeywordBlacklist(isLoading, hasErrorTrainingWhite, 'trainingWhitelist')}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={intl.formatMessage(settingsMessages.trainingBlacklistDesc)}
                      placement="right"
                    >
                      {intl.formatMessage(settingsMessages.trainingBlacklist)}
                    </Popover>
                  }
                  key="trainingBlacklist"
                >
                  {this.renderKeywordBlacklist(isLoading, hasErrorTrainingBlack, 'trainingBlacklist')}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={intl.formatMessage(settingsMessages.detectionBlacklistDesc)}
                      placement="right"
                    >
                      {intl.formatMessage(settingsMessages.detectionBlacklist)}
                    </Popover>
                  }
                  key="extractionBlacklist"
                >
                  {this.renderKeywordBlacklist(isLoading, hasErrorExtractionBlack, 'extractionBlacklist')}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Popover title={null} content={intl.formatMessage(settingsMessages.featureDesc)} placement="right">
                      {intl.formatMessage(settingsMessages.feature)}
                    </Popover>
                  }
                  key="feature"
                >
                  {this.renderKeywordFeature(isLoading, hasErrorFeature)}
                </Tabs.TabPane>
                {entry !== 'batch' && (
                  <Tabs.TabPane
                    tab={
                      <Popover
                        title={null}
                        content={intl.formatMessage(settingsMessages.anomalyFeature)}
                        placement="right"
                      >
                        {intl.formatMessage(settingsMessages.anomalyFeature)}
                      </Popover>
                    }
                    key="anomalyFeature"
                  >
                    {this.renderKeywordBlacklist(isLoading, hasErrorAnomalyFeature, 'anomalyFeature')}
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane
                  tab={
                    <Popover title={null} content={intl.formatMessage(settingsMessages.categoryDesc)} placement="right">
                      {intl.formatMessage(settingsMessages.category)}
                    </Popover>
                  }
                  key="category"
                >
                  {this.renderKeywordCategory(isLoading, hasErrorCategory)}
                </Tabs.TabPane>
                {entry !== 'batch' && (
                  <Tabs.TabPane
                    tab={
                      <Popover
                        title={null}
                        content={intl.formatMessage(settingsMessages.incidentDesc)}
                        placement="right"
                      >
                        <span className="tour-setting-keywords-incident">
                          {intl.formatMessage(settingsMessages.incident)}
                        </span>
                      </Popover>
                    }
                    key="incident"
                  >
                    {this.renderKeywordIncident(isLoading, hasErrorIncident)}
                  </Tabs.TabPane>
                )}
                {entry !== 'batch' && (
                  <Tabs.TabPane
                    tab={
                      <Popover
                        title={null}
                        content={intl.formatMessage(settingsMessages.patternNameLabelsDesc)}
                        placement="right"
                      >
                        <span className="tour-setting-keywords-incident">
                          {intl.formatMessage(settingsMessages.normalPatternName)}
                        </span>
                      </Popover>
                    }
                    key="patternName"
                  >
                    {this.renderKeywordPatternNameLabels(isLoading, hasErrorPatternNameLabels)}
                  </Tabs.TabPane>
                )}
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={intl.formatMessage(settingsMessages.instanceNameLabelsDesc)}
                      placement="right"
                    >
                      {intl.formatMessage(settingsMessages.instanceNameLabels)}
                    </Popover>
                  }
                  key="instanceNameLabels"
                >
                  {this.renderKeywordBlacklist(isLoading, hasErrorInstanceNameLabels, 'instanceNameLabels')}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={intl.formatMessage(settingsMessages.sensitiveDataFilterDesc)}
                      placement="right"
                    >
                      {intl.formatMessage(settingsMessages.sensitiveDataFilter)}
                    </Popover>
                  }
                  key="DataFilter"
                >
                  {this.renderDataFilterReport(isLoading, hasErrorDataFilter)}
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <Popover
                      title={null}
                      content={intl.formatMessage(settingsMessages.triageReportDesc)}
                      placement="right"
                    >
                      {intl.formatMessage(settingsMessages.triageReport)}
                    </Popover>
                  }
                  key="triageReport"
                >
                  {this.renderKeywordTriageReport(isLoading, hasErrorTriageReport)}
                </Tabs.TabPane>
              </Tabs>
            </form>
          </Container>
        )}
        {showPatternNameRegex && (
          <PatternNameRegexModal
            intl={intl}
            activeEvent={activeEvent}
            fieldNameOptions={fieldNameOptions}
            incidentListData={this.incidentListData}
            regexPlaceHolderForIncident={this.regexPlaceHolderForIncident}
            onClose={(data) => {
              if (data) {
                let list = this[data.type];
                if (R.includes(data?.type, ['localKeywordsList', 'localKeywordsIncident']) && this.searchSystem) {
                  list = R.filter(
                    (item) => (item.systemTag || '').toLowerCase().includes(this.searchSystem.toLowerCase()),
                    list || [],
                  );
                }
                R.addIndex(R.forEach)((item, idx) => {
                  if (idx === data.index) item.patternNameLabels = data.event.patternNameLabels;
                }, list || []);
              }
              this.setState({ showPatternNameRegex: false, activeEvent: null }, () => {
                if (entry === 'batch') return;
                if (data?.type === 'localKeywordsList') {
                  this.handleSaveClick();
                } else if (data?.type === 'localKeywordsIncident') {
                  this.handleSaveClickIncident();
                }
              });
            }}
          />
        )}
      </>
    );
  }
}

const KeywordsSetting = injectIntl(KeywordsSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus } = state.app;
    const { credentials } = state.auth;

    return { location, loadStatus, credentials };
  },
  { replace, push, createLoadAction },
)(KeywordsSetting);
