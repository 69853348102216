import React, { Component } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import Papa from 'papaparse';
import moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { replace, push } from 'react-router-redux';
import { isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { AutoComplete, Button, InputNumber, message, Select, Tabs, Upload } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
  UploadOutlined,
} from '@ant-design/icons';

import WorkerBuilder from './worker-build';
import hashWorker from './hash-worker';
import fetchPost from '../../../src/common/apis/fetchPost';
import fetchPostForm from '../../../src/common/apis/fetchPostForm';
import getEndpoint from '../../../src/common/apis/getEndpoint';
import { hideAppLoader, showAppLoader, updataProject, updateLastActionInfo } from '../../../src/common/app/actions';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Column, Modal, Popover, Table } from '../../../src/lib/fui/react';
import { buildLocation, Defaults, parseLocation } from '../../../src/common/utils';

import FileDataModal from './component/FileDataModal';
import FileRerunMetricData from './component/FileRerunMetricData';
import FileListDisplay from './component/FileListDisplay';
import OtherFileListDisplay from './component/OtherFileListDisplay';

import { appButtonsMessages, appFieldsMessages } from '../../../src/common/app/messages';
import { eventMessages } from '../../../src/common/metric/messages';
import { settingsMessages } from '../../../src/common/settings/messages';
import { DashboardMessages } from '../../../src/common/dashboard/messages';

type Props = {
  hideAppLoader: Function,
  showAppLoader: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  intl: Object,
  location: Object,
  credentials: Object,
  updateLastActionInfo: Function,
  style: Object,
  projects: Array<Object>,
  userInfo: Object,
  updataProject: Function,
  userList: Array<Object>,
  refresh: Number,
  handleRefresh: Function,
  replace: Function,
  push: Function,
  downloadSampleFile: Function,
};

class BigFileUploadCore extends Component {
  props: Props;

  constructor(props) {
    super(props);

    const { location, userInfo, userList, intl } = props;
    const { customerName, projectName } = parseLocation(location);
    const newProjects = this.getProject(props);
    const findProject = R.find((project) => project.projectShortName === projectName, newProjects);

    const projectList = R.map(
      (project) => ({
        label: project.projectDisplayName,
        value: project.projectShortName,
        systemId: project.systemId,
        customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName,
      }),
      newProjects,
    );

    this.state = {
      fileList: [],
      fileDataModal: false,
      activeRow: null,
      projectNameValue: findProject?.projectShortName || null,
      refreshFileRerunData: 0,
      useNameValue: customerName || '',
      fileRerunLoading: false,
      samplingInterVal: 5,
      samplingUnitVal: 1,
      projectSourceVal: true,

      sortBy: null,
      sortDirection: null,
    };

    this.beforeUploadErrorFlag = 0;
    this.removeFileFlag = false; // 删除开关
    this.waitIndex = -1; // 当前需要处理的index
    this.maxFileLen = 0; // 上传的length
    this.CHUNK_NUM_SIZE = 2; // 切片大小限制
    this.CHUNK_SIZE = this.CHUNK_NUM_SIZE * 1024 * 1024; // 2M
    this.MAX_REQUEST_NUM = 6; // 最大上传并发数
    this.MAX_UPLOAD_SIZE = 2048 * 1024 * 1024; // 2G
    this.userListOptions = R.map(
      (userName) => ({ value: userName, label: userName }),
      R.sort((a, b) => a.localeCompare(b), R.uniq(R.map((item) => item.userName, userList || []))),
    );
    this.samplingUnit = [
      { label: 'Minutes', value: 1 },
      { label: 'Hours', value: 60 },
      { label: 'Days', value: 1440 },
    ];
    this.projectSourceUnit = [
      { label: intl.formatMessage(eventMessages.selectFromExistingProjects), value: false },
      { label: intl.formatMessage(eventMessages.createANewProject), value: true },
    ];
    this.projectList = this.sortData(projectList);
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });
    this.dataTableNode = null;
  }

  componentDidMount() {
    this.props.hideAppLoader();
  }

  componentDidUpdate(prevProps, prevState) {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, userInfo, projects } = nextProps;
    const { customerName } = parseLocation(location);
    if (projects !== this.props.projects) {
      const newProjects = this.getProject(nextProps);
      const projectList = R.map(
        (project) => ({
          label: project.projectDisplayName,
          value: project.projectShortName,
          systemId: project.systemId,
          customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName,
        }),
        newProjects,
      );
      this.projectList = this.sortData(projectList);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { sortBy: prevSortBy, sortDirection: prevSortDirection } = this.state;

    if (nextState.sortBy !== prevSortBy || nextState.sortDirection !== prevSortDirection) {
      const { sortBy, sortDirection } = nextState;
      if (sortBy) {
        this.projectList = this.sortData(this.projectList, sortBy, sortDirection);
        if (this.dataTableNode) this.dataTableNode.forceUpdate();
        this.forceUpdate();
      }
    }
  }

  @autobind
  sortData(list, sortBy, sortDirection) {
    let sortList = list;

    let sortFunctions = [R.ascend(R.prop('label'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }

    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  }

  @autobind
  onChangeFilterOwner(useNameValue) {
    const { showAppLoader, location } = this.props;
    if (showAppLoader) {
      showAppLoader();
    }
    this.setState({ useNameValue }, () => {
      const params = parseLocation(location);
      const { pathname, search } = buildLocation(location.pathname, {}, { ...params, customerName: useNameValue });
      window.location.href = pathname + search;
    });
  }

  @autobind
  getProject(props) {
    const { location, projects, userInfo } = props;
    const params = parseLocation(location);
    const { customerName } = params;
    let newProjects = R.filter((project) => project.isMetric, projects || []);
    if (userInfo.isAdmin || userInfo.isLocalAdmin) {
      newProjects = R.filter((project) => project.owner === customerName, newProjects);
    } else {
      newProjects = R.filter((project) => project.owner === userInfo.userName, newProjects);
    }
    return newProjects;
  }

  // 获取文件后缀名
  @autobind
  getFileSuffix(fileName, flag = true) {
    const arr = fileName.split('.');
    if (arr.length > 0) {
      if (!flag) return R.join('', R.take(arr.length - 1, arr));
      return R.last(arr);
    }
    return '';
  }

  // 切片文件
  @autobind
  splitFile(file, size = this.CHUNK_SIZE) {
    const fileChunkList = [];
    for (let i = 0; i < file.size; i += size) {
      fileChunkList.push({ chunk: file.slice(i, i + size) });
    }
    return fileChunkList;
  }

  // 计算文件hash
  @autobind
  calculateHash(chunkList) {
    return new Promise((resolve) => {
      const woker = new WorkerBuilder(hashWorker);
      woker.postMessage({ chunkList });
      woker.onmessage = (e) => {
        // 删除开发触发停止操作
        if (this.removeFileFlag) {
          // 关闭hash处理
          woker.terminate();
          // 继续触发下一个文件上传
          this.handleUploadAvatar();
        }
        const { hash } = e.data;
        // 当hash计算完成时，执行resolve
        if (hash) resolve(hash);
      };
    });
  }

  @autobind
  arrayToCsv(data, args = {}) {
    const columnDelimiter = args.columnDelimiter || ',';
    const lineDelimiter = args.lineDelimiter || '\n';

    return data.reduce((csv, row) => {
      const rowContent = Array.isArray(R.values(row))
        ? R.values(row).reduce((rowTemp, col) => {
            let ret = rowTemp ? rowTemp + columnDelimiter : rowTemp;
            if (col || col === 0) {
              const formatedCol = col.toString().replace(new RegExp(lineDelimiter, 'g'), ' ');
              ret += /,/.test(formatedCol) ? `"${formatedCol}"` : formatedCol;
            }
            return ret;
          }, '')
        : R.values(row);
      return (csv ? csv + lineDelimiter : '') + rowContent;
    }, '');
  }

  @autobind
  getCSVData(file) {
    let chunkList = [];
    let fileDataList = [];
    let header = '';
    let headerList = [];
    let hasError = false;
    return new Promise((resolve) => {
      Papa.parse(file, {
        header: true,
        worker: true,
        dynamicTyping: true, // 数字和布尔数据将被转换为它们的类型，而不是剩余的字符串。
        chunkSize: this.CHUNK_SIZE,
        chunk: (results, parser) => {
          const { data, meta } = results;
          headerList = meta.fields;
          header = String(meta.fields || '');
          chunkList = [...chunkList, { chunk: new Blob([this.arrayToCsv(data)]) }];
          fileDataList = [...fileDataList, ...data];
          if (this.removeFileFlag) {
            parser.abort();
            hasError = true;
            throw new Error('Manual error reporting');
          }
        },
        complete: (results, file) => {
          const hash = CryptoJS.SHA256(JSON.stringify(fileDataList)).toString();
          if (!hasError) {
            resolve({ dataChunkList: chunkList, header, fileDataList, headerList, hash });
          }
        },
        error: (err) => {
          hasError = undefined;
          this.nextUploadFile('error', `${file.name} ${String(err)}`);
        },
      });
    });
  }

  // 修改文件状态
  @autobind
  updataFileState(fileList, restObj, waitIndex) {
    waitIndex = R.isNil(waitIndex) ? this.waitIndex : waitIndex;
    fileList = update(fileList, {
      [waitIndex]: {
        $set: {
          ...fileList[waitIndex],
          ...restObj,
        },
      },
    });
    return fileList;
  }

  // 显示文件内容
  @autobind
  async viewFileContent(rowData) {
    this.setState({ fileDataModal: true, activeRow: rowData });
  }

  // 重置状态
  @autobind
  resetFileState(uid) {
    const { intl } = this.props;
    const { projectNameValue } = this.state;
    let { fileList } = this.state;
    if (!projectNameValue) {
      message.error(intl.formatMessage(eventMessages.projectCannotBeEmpty));
      return;
    }
    const findIndex = R.findIndex((item) => item.uid === uid, fileList || []);
    fileList = this.updataFileState(fileList, { state: 'wait' }, findIndex);
    // 继续触发下一个文件上传
    this.setState({ fileList }, () => this.handleUploadAvatar());
  }

  // 继续触发下一个文件上传
  @autobind
  nextUploadFile(type, errMessage) {
    let { fileList } = this.state;
    let message = {};
    if (errMessage) message = { errMessage };
    fileList = this.updataFileState(fileList, { state: type, percentage: 0, uploadedSize: 0, ...message });
    this.setState({ fileList }, () => this.handleUploadAvatar());
  }

  // 删除file
  @autobind
  removeRile(uid) {
    let { fileList } = this.state;
    const removeFileIdx = R.findIndex((item) => item.uid === uid, fileList);
    const processFileIdx = R.findIndex((item) => item.state === 'analysis' || item.state === 'progress', fileList);

    // 只有等待、成功、失败 和 删除的idx比正在上传的idx大就直接删除
    fileList = R.filter((item) => item.uid !== uid, fileList);
    if (removeFileIdx === processFileIdx) {
      // 删除当前正在上传的文件
      this.removeFileFlag = true;
    } else if (removeFileIdx < processFileIdx) {
      // 删除的idx比正在上传的idx小
      this.waitIndex -= 1;
    }
    this.maxFileLen = fileList.length;
    if (fileList.length === 0) this.waitIndex = -1;

    this.setState({ fileList });
  }

  @autobind
  renderContent(title, list = []) {
    const { intl } = this.props;
    return (
      <>
        <h5 style={{ marginTop: 8 }}>{intl.formatMessage(title)}: </h5>
        <div className="flex-row flex-wrap" style={{ marginLeft: 8, wordBreak: 'break-word' }}>
          {R.addIndex(R.map)((item, index) => {
            const itemObject = isObject(item);
            return (
              <>
                {itemObject ? (
                  <span key={index} style={{ marginRight: 8 }}>
                    {item.flag && <span style={{ color: 'red' }}>*</span>}
                    {`${item.name}${index + 1 !== list.length ? ',' : ''}`}
                  </span>
                ) : (
                  <span key={index} style={{ marginRight: 8 }}>
                    {`${item}${index + 1 !== list.length ? ',' : ''}`}
                  </span>
                )}
              </>
            );
          }, list)}
        </div>
      </>
    );
  }

  // 过滤切片
  @autobind
  filterChunk(params) {
    const { intl } = this.props;
    const { uploadedChunkList, chunkList, hash, projectDataStatus, allowUpload } = params;
    const { existedInstances, existedMetrics, headerList } = params;
    // 过滤掉已上传的块
    const chunksData = R.addIndex(R.map)(
      ({ chunk }, index) => ({ chunk, hash, chunkNumber: index, progress: 0 }),
      chunkList,
    );
    const cmp = (x, y) => x.chunkNumber === y;
    const uploadedChunkIndexList = R.differenceWith(cmp, chunksData, uploadedChunkList);

    // 分片已经上传完整
    if (uploadedChunkIndexList.length === 0 && !allowUpload) {
      this.nextUploadFile('error', intl.formatMessage(eventMessages.theFileAlreadyExists));
      message.warning(intl.formatMessage(eventMessages.fileAlreadyExistsError));
      return;
    }

    // 删除开发触发停止操作
    if (this.removeFileFlag) {
      // 继续触发下一个文件上传
      this.handleUploadAvatar();
      return;
    }

    if ((uploadedChunkIndexList.length === 0 && allowUpload) || projectDataStatus) {
      this.judgeChunkRepeatData(projectDataStatus, hash, existedInstances, existedMetrics, headerList);
    } else {
      this.uploadChunks(uploadedChunkIndexList, hash, existedInstances, existedMetrics, headerList);
    }
  }

  // 处理projectName给后端 + 秒传：验证文件是否存在服务器
  @autobind
  async handleProjectSetting({ hash, csvHeader, file, chunkList, fileDataList }) {
    const { projectNameValue, samplingInterVal, samplingUnitVal, sortBy, sortDirection } = this.state;
    const { updateLastActionInfo, credentials, userInfo, location, replace } = this.props;
    const params = parseLocation(location);
    const { customerName } = params;

    const findProject = R.find((project) => project.value === projectNameValue, this.projectList);

    if (!findProject) {
      const projectList = [
        ...this.projectList,
        {
          label: projectNameValue,
          value: projectNameValue,
          customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName,
          isNewProject: true,
        },
      ];
      this.projectList = this.sortData(projectList, sortBy, sortDirection);
    }

    updateLastActionInfo();
    return fetchPost(getEndpoint('uploadmetrichandshake'), {
      ...credentials,
      hash,
      fileName: this.getFileSuffix(file.name, false),
      suffix: this.getFileSuffix(file.name),
      totalChunks: chunkList.length,
      csvHeader,
      projectName: projectNameValue,
      isNewProject: !findProject,
      customerName: findProject
        ? findProject.customerName
        : userInfo.isAdmin || userInfo.isLocalAdmin
        ? customerName
        : userInfo.userName,
      firstRow: String(R.values(fileDataList[0] || {})),
      ...(!findProject ? { samplingInterval: samplingInterVal * samplingUnitVal } : {}),
    })
      .then((res) => {
        const { success, message, uploadedChunkList, allowUpload } = res;
        const { projectMetricList, projectInstanceList } = res;
        let { projectDataStatus } = res;
        if (success || success === undefined) {
          let existedInstances = projectInstanceList || [];
          let existedMetrics = projectMetricList || [];
          existedInstances = R.sort((a, b) => R.toString(a).localeCompare(R.toString(b)), R.uniq(existedInstances));
          existedMetrics = R.sort((a, b) => R.toString(a).localeCompare(R.toString(b)), R.uniq(existedMetrics));
          projectDataStatus = projectDataStatus ? [{ projectDataStatus }] : null;
          if (!findProject) replace(buildLocation(location.pathname, {}, { ...params, projectName: projectNameValue }));
          return {
            uploadedChunkList: uploadedChunkList || [],
            projectDataStatus,
            allowUpload,
            existedInstances,
            existedMetrics,
          };
        } else {
          return { proejctIsError: message };
        }
      })
      .catch((err) => {
        return { proejctIsError: err };
      });
  }

  // 上传分片
  @autobind
  async uploadChunks(chunksData, hash, existedInstances, existedMetrics, headerList) {
    const { userInfo, location, intl } = this.props;
    const { customerName } = parseLocation(location);
    // 创建数据流列表
    const formDataList = R.map(({ chunk, hash, chunkNumber }) => {
      const formData = new FormData();
      formData.append('data', chunk);
      formData.append('hash', hash);
      formData.append('chunkNumber', chunkNumber);
      formData.append('projectName', this.state.projectNameValue);
      formData.append('customerName', userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName);
      return { formData };
    }, chunksData);
    const formDataListLength = formDataList.length;

    let { fileList } = this.state;
    fileList = this.updataFileState(fileList, { state: 'progress' });
    this.setState({ fileList }, () => {
      const dataList = []; // 请求返回的所有数据
      let currentTaskNum = 0; // 请求位
      let progress = 0; // 进度
      let hasError = false; // 是否有错误

      // 请求池
      updateLastActionInfo();
      const requestPool = () => {
        if (currentTaskNum < this.MAX_REQUEST_NUM && formDataList.length && !hasError) {
          const curTask = fetchPostForm(getEndpoint('uploadmetricfile'), formDataList.shift().formData);
          if (curTask && curTask.then) {
            currentTaskNum += 1;
            curTask
              .then((v) => {
                if (v.success === false) {
                  throw new Error(v.message);
                }
                dataList.push(Promise.resolve(v));
                if (formDataListLength === dataList.length) {
                  Promise.all(dataList).then((res) => {
                    // 延迟发送合并请求，方便观察服务器合并文件的步骤
                    setTimeout(() => {
                      this.judgeChunkRepeatData(res, hash, existedInstances, existedMetrics, headerList);
                    }, 1000);
                  });
                } else {
                  currentTaskNum -= 1;
                  // 删除开发触发停止操作
                  if (this.removeFileFlag) {
                    hasError = true;
                    progress = 0;
                    if (currentTaskNum === 0) {
                      // 继续触发下一个文件上传
                      this.handleUploadAvatar();
                      return;
                    }
                  }
                  if (hasError) {
                    if (currentTaskNum === 0) {
                      this.nextUploadFile('error', v.message || intl.formatMessage(eventMessages.cancelUpload));
                    }
                    return;
                  }

                  progress += 100 / formDataListLength;
                  let { fileList } = this.state;
                  // 处理进度条数据和已上传文件大小
                  fileList = this.updataFileState(fileList, {
                    percentage: progress,
                    uploadedSize: fileList[this.waitIndex]?.uploadedSize + this.CHUNK_NUM_SIZE,
                  });
                  this.setState({ fileList }, () => requestPool());
                }
              })
              .catch((e) => {
                currentTaskNum -= 1;
                console.error(e);
                hasError = true;
                if (currentTaskNum === 0) this.nextUploadFile('error', e.message || String(e));
              });
          }
          requestPool();
        }
      };
      requestPool();
    });
  }

  // 判断chunk重复数据
  @autobind
  judgeChunkRepeatData(chunkDataList, hash, existedInstances = [], existedMetrics = [], headerList) {
    const { intl } = this.props;
    let hasDuplicateData = [];
    R.forEach((item) => {
      R.forEachObjIndexed((val, key) => {
        if (val) hasDuplicateData.push(moment.utc(Number(key)).format(Defaults.DateFormat));
      }, item.projectDataStatus || {});
    }, chunkDataList || []);

    let instanceHeader = [];
    let merticHeader = [];
    R.addIndex(R.forEach)((item, idx) => {
      if (idx !== 0) {
        const instanceName = (item.match(/\[(\S*)]/) || [])[1];
        const merticName = (item.match(/(\S*)\[/) || [])[1];
        if (instanceName) instanceHeader.push(instanceName);
        if (merticName) merticHeader.push(merticName);
      }
    }, headerList || []);
    instanceHeader = R.sort((a, b) => R.toString(a).localeCompare(R.toString(b)), R.uniq(instanceHeader));
    merticHeader = R.sort((a, b) => R.toString(a).localeCompare(R.toString(b)), R.uniq(merticHeader));
    instanceHeader = R.map((item) => ({ name: item, flag: R.includes(item, existedInstances) }), instanceHeader);
    merticHeader = R.map((item) => ({ name: item, flag: R.includes(item, existedMetrics) }), merticHeader);

    // 删除开发触发停止操作
    if (this.removeFileFlag) {
      // 继续触发下一个文件上传
      this.handleUploadAvatar();
      return;
    }

    hasDuplicateData = R.sort((a, b) => R.toString(a).localeCompare(R.toString(b)), R.uniq(hasDuplicateData));
    const hasDataFlag = hasDuplicateData.length > 0 || instanceHeader.length > 0 || merticHeader.length > 0;
    if (hasDataFlag) {
      Modal.confirm({
        title: intl.formatMessage(eventMessages.areYouSureToUploadTheFile),
        icon: <ExclamationCircleFilled />,
        content: (
          <div style={{ maxHeight: 400, overflowY: 'auto' }}>
            {(instanceHeader.length > 0 || merticHeader.length > 0) && (
              <div className="flex-row flex-center-align bold">
                <span style={{ color: 'red' }}>*</span>
                <span style={{ padding: '0 4px' }}>:</span>
                {intl.formatMessage(eventMessages.alreadyExistInTheSelectedProject)}
              </div>
            )}
            {instanceHeader.length > 0 && this.renderContent(DashboardMessages.instances, instanceHeader)}
            {merticHeader.length > 0 && this.renderContent(eventMessages.metricAnomaly, merticHeader)}
            {hasDuplicateData.length > 0 && this.renderContent(eventMessages.dateWithDuplicateData, hasDuplicateData)}
          </div>
        ),
        className: 'file-modal',
        okText: `${intl.formatMessage(appButtonsMessages.upload)}`,
        cancelText: `${intl.formatMessage(appButtonsMessages.cancel)}`,
        onOk: () => this.confirmAnalysisFile(hash),
        onCancel: () => this.nextUploadFile('error', intl.formatMessage(eventMessages.cancelUpload)),
      });
    } else {
      this.confirmAnalysisFile(hash);
    }
  }

  @autobind
  confirmAnalysisFile(hash) {
    const { credentials, userInfo, location } = this.props;
    const { customerName } = parseLocation(location);
    const { projectNameValue } = this.state;
    fetchPost(getEndpoint('confirmMetricFileAnalysis'), {
      ...credentials,
      projectName: projectNameValue,
      customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName,
      hash,
      confirm: true,
    })
      .then((res) => {
        const { success, message: msg } = res;
        if (success || success === undefined) {
          // 删除开发触发停止操作
          if (this.removeFileFlag) {
            // 继续触发下一个文件上传
            this.handleUploadAvatar();
            return;
          }
          const { refreshFileRerunData } = this.state;
          let { fileList } = this.state;
          fileList = this.updataFileState(fileList, {
            state: 'success',
            percentage: 0,
            uploadedSize: fileList[this.waitIndex]?.fileTotalSize,
          });
          // 继续触发下一个文件上传
          this.setState({ fileList, refreshFileRerunData: refreshFileRerunData + 1 }, () => this.handleUploadAvatar());
        } else {
          message.error(msg);
          this.nextUploadFile('error', msg);
        }
      })
      .catch((e) => {
        console.error(e);
        this.nextUploadFile('error', e.message || String(e));
      });
  }

  // 单个文件上传处理
  @autobind
  async handleUpdate(fileInfo) {
    const { intl } = this.props;
    const { fileData: file } = fileInfo;
    if (!file) return;

    const { dataChunkList, header, fileDataList, headerList, hash } = await this.getCSVData(file);
    const csvHeader = header || '';
    const chunkList = dataChunkList || [];

    // 计算文件hash
    // const hash = await this.calculateHash(chunkList);

    // 查看project状态 + 验证文件是否存在服务器
    const { uploadedChunkList, projectDataStatus, allowUpload, existedInstances, existedMetrics, proejctIsError } =
      await this.handleProjectSetting({
        hash,
        csvHeader,
        file,
        chunkList,
        fileDataList,
      });
    const params = {
      uploadedChunkList,
      chunkList,
      hash,
      projectDataStatus,
      file,
      allowUpload,
      existedInstances,
      existedMetrics,
      headerList,
    };

    let { fileList } = this.state;
    fileList = this.updataFileState(fileList, { hash });
    this.setState({ fileList }, () => {
      // 删除开发触发停止操作
      if (this.removeFileFlag) {
        // 继续触发下一个文件上传
        this.handleUploadAvatar();
        return;
      }

      // 验证错误
      if (proejctIsError) {
        this.nextUploadFile('error', proejctIsError.message || String(proejctIsError));
        message.error(
          proejctIsError.message || String(proejctIsError) || intl.formatMessage(eventMessages.fileUploadError),
        );
        return;
      }

      this.getUploadFileSize({ ...params });
    });
  }

  // 处理以已上传过的切片，获取已上传文件大小
  @autobind
  getUploadFileSize(params) {
    const { uploadedChunkList } = params;
    const uploadFileSize = uploadedChunkList.length * this.CHUNK_NUM_SIZE;
    if (uploadFileSize > 0) {
      // 有已上传的切片
      let { fileList } = this.state;
      let fileTotalSize = fileList[this.waitIndex]?.fileTotalSize;
      // 当前文件大小 - 已上传文件大小
      fileTotalSize = uploadFileSize >= fileTotalSize ? fileTotalSize : fileTotalSize - uploadFileSize;
      fileList = this.updataFileState(fileList, { fileTotalSize });
      this.setState({ fileList }, () => this.filterChunk({ ...params }));
    } else {
      this.filterChunk({ ...params });
    }
  }

  // antd 自定义Upload事件
  @autobind
  async handleUploadAvatar(option) {
    // 只要触发upload，默认删除开关 false
    this.removeFileFlag = false;
    // 获取有file内容的上传列表
    const { projectNameValue } = this.state;
    let { fileList } = this.state;
    if (!projectNameValue) return;
    if (option && (option?.file?.name || '').indexOf('.csv') < 0) return;
    if (option && option?.file?.size > this.MAX_UPLOAD_SIZE) return;
    fileList = R.filter((item) => item.fileData, fileList);
    this.setState({ fileList }, () => {
      let { fileList } = this.state;
      // 因为上传是一次一次触发的，设置一个开关进行触发 (只有this.maxFileLen 等于 上传的列表数量才能触发)
      if (option) this.maxFileLen += 1;
      if (this.maxFileLen === fileList.length) {
        // 有没有进行中或者解析中（有就不触发upload，进入等待）
        const progressItem = R.find((item) => item.state === 'progress' || item.state === 'analysis', fileList || []);
        if (progressItem) return;
        // 有没有等待的 （没有等待说明没有需要上传的）
        const waitIndex = R.findIndex((item) => item.state === 'wait', fileList || []);
        if (waitIndex === -1) {
          const isNewProject = R.find((project) => project.isNewProject, this.projectList);
          if (isNewProject) {
            this.props.updataProject();
          }
          return;
        }

        // 进入需要上传程序
        this.waitIndex = waitIndex;
        fileList = this.updataFileState(fileList, { state: 'analysis' });
        this.setState({ fileList }, () => this.handleUpdate(fileList[this.waitIndex]));
      }
    });
  }

  @autobind
  selectUserRender(disabled, existing = false) {
    const { userInfo, intl } = this.props;
    const { useNameValue } = this.state;
    return (
      <>
        {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
          <div className={existing ? 'flex-col' : 'flex-row flex-center-align'} style={{ marginBottom: 8 }}>
            <span style={{ width: existing ? 100 : 60, ...(existing ? { marginBottom: 4 } : {}) }}>
              <span style={{ color: 'var(--primary-color)', marginRight: 4 }}>*</span>
              {intl.formatMessage(appFieldsMessages.user)}:
            </span>
            <Select
              allowClear={false}
              showArrow={false}
              showSearch
              size="small"
              style={{ flex: 1 }}
              placeholder="Please select user"
              optionFilterProp="children"
              value={useNameValue}
              onChange={this.onChangeFilterOwner}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              dropdownMatchSelectWidth={false}
              disabled={disabled}
              className={`${useNameValue ? '' : 'jsonKeyNoneError'}`}
            >
              {this.userListOptions.map((item) => (
                <Select.Option key={item.value} title={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
      </>
    );
  }

  @autobind
  projectCellRenderer({ dataKey, parent, rowIndex, cellData, columnIndex }) {
    return (
      <CellMeasurer
        cache={this.cellMeasureCache}
        columnIndex={columnIndex}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <Popover title={null} content={cellData} placement="top" mouseEnterDelay={0.3}>
          <div className="hidden-line-with-ellipsis">{cellData}</div>
        </Popover>
      </CellMeasurer>
    );
  }

  @autobind
  sort({ sortBy, sortDirection }) {
    this.setState({ sortBy, sortDirection });
  }

  @autobind
  headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
    const sortIcon = () => {
      if (sortBy !== dataKey) {
        return null;
      }
      if (sortDirection === 'ASC') {
        return <CaretUpOutlined />;
      }
      return <CaretDownOutlined />;
    };
    return (
      <div className={`${dataKey === 'rawData' ? 'full-width flex-row flex-center-align' : ''}`}>
        {label}
        {!disableSort && sortIcon()}
      </div>
    );
  }

  @autobind
  projectListRender() {
    const { intl, location, replace } = this.props;
    const { sortBy, sortDirection, projectNameValue } = this.state;
    const query = parseLocation(location);
    return (
      <div className="flex-grow">
        <AutoSizer>
          {({ width, height }) => (
            <Table
              className="with-border"
              width={width}
              height={height}
              deferredMeasurementCache={this.cellMeasureCache}
              headerHeight={40}
              rowHeight={this.cellMeasureCache.rowHeight}
              rowCount={this.projectList.length}
              rowGetter={({ index }) => this.projectList[index]}
              headerClassName="flex-row flex-center-align"
              sort={this.sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              ref={(c) => {
                this.dataTableNode = c;
              }}
              onRowClick={({ rowData }) => {
                this.maxFileLen = 0;
                this.setState({ projectNameValue: rowData?.value, fileList: [] }, () => {
                  replace(buildLocation(location.pathname, {}, { ...query, projectName: rowData?.value }));
                });
              }}
              rowClassName={({ index }) => {
                let className = 'clickable';
                className += index >= 0 && index % 2 === 1 ? ' odd-row' : '';
                if (index >= 0) {
                  if (this.projectList[index].value === projectNameValue) {
                    className += ' active';
                  }
                }
                return className;
              }}
            >
              <Column
                width={100}
                flexGrow={1}
                label="Anomaly detection project name"
                dataKey="label"
                cellRenderer={this.projectCellRenderer}
                headerRenderer={this.headerRenderer}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }

  render() {
    const { intl, credentials, userInfo, style, refresh, handleRefresh, replace, location, push } = this.props;
    const { downloadSampleFile } = this.props;
    const { fileList, fileDataModal, activeRow, projectNameValue, refreshFileRerunData } = this.state;
    const { fileRerunLoading, samplingInterVal, samplingUnitVal, projectSourceVal } = this.state;
    const query = parseLocation(location);
    const disabled =
      R.findIndex(
        (item) => item.state === 'wait' || item.state === 'progress' || item.state === 'analysis',
        fileList || [],
      ) !== -1 || fileRerunLoading;
    const hasFailFiles = R.find((file) => file.state === 'error', fileList || []);
    const hasOtherFiles = R.filter((file) => !R.includes(file.state, ['error', 'success']), fileList || []);
    const isNewProject = !R.find((project) => project.value === projectNameValue, this.projectList);

    return (
      <>
        <div className="flex-row full-height">
          <div className="flex-col" style={{ width: style.width }}>
            <Tabs
              type="card"
              className="full-height ant-tabs-content-full-height flex-col"
              activeKey={String(projectSourceVal)}
              onChange={(activeKey) => {
                const { projectName } = query;
                const findProject = R.find((project) => project.value === projectName, this.projectList);
                this.maxFileLen = 0;
                this.setState({
                  fileList: [],
                  projectSourceVal: activeKey === 'true',
                  projectNameValue: findProject?.value || null,
                  samplingInterVal: 5,
                  samplingUnitVal: 1,
                });
              }}
            >
              <Tabs.TabPane
                disabled={disabled}
                tab={intl.formatMessage(appFieldsMessages.viewExistingData)}
                key="false"
                style={{ paddingTop: 8 }}
              >
                <div className="full-width full-height flex-col">
                  {this.selectUserRender(disabled)}
                  {this.projectListRender()}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                disabled={disabled}
                tab={intl.formatMessage(appFieldsMessages.uploadNewData)}
                key="true"
                style={{ paddingTop: 8 }}
              >
                <div className="full-width full-height flex-col">
                  <Button
                    icon={<DownloadOutlined />}
                    size="small"
                    type="primary"
                    style={{ marginBottom: 8, width: 200, alignSelf: 'end' }}
                    onClick={downloadSampleFile}
                  >
                    Download sample data
                  </Button>
                  {this.selectUserRender(disabled, true)}
                  <div className="flex-col">
                    <span style={{ flexShrink: 0, marginBottom: 4 }}>
                      <span style={{ color: 'var(--primary-color)', marginRight: 4 }}>*</span>
                      Anomaly detection project name:
                    </span>
                    <AutoComplete
                      size="small"
                      allowClear={false}
                      disabled={disabled}
                      style={{ flex: 1 }}
                      value={projectNameValue}
                      options={this.sortData(this.projectList)}
                      placeholder="Can type in project name or select from existing"
                      dropdownMatchSelectWidth={false}
                      onChange={(projectNameValue) => {
                        this.maxFileLen = 0;
                        this.setState({ projectNameValue, fileList: [] }, () => {
                          const isNewProject = !R.find(
                            (project) => project.value === projectNameValue,
                            this.projectList,
                          );
                          if (!isNewProject) {
                            replace(buildLocation(location.pathname, {}, { ...query, projectName: projectNameValue }));
                          }
                        });
                      }}
                      className={`overflow-hidden ${projectNameValue ? '' : 'jsonKeyNoneError'}`}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
                      }
                    />
                  </div>
                  {isNewProject && (
                    <div className="flex-col flex-center-justify">
                      <span style={{ padding: '8px 0' }}>
                        <span style={{ color: 'var(--primary-color)', marginRight: 4 }}>*</span>
                        Data sampling interval:
                      </span>
                      <div className="flex-row">
                        <InputNumber
                          min={1}
                          value={samplingInterVal}
                          className="flex-grow"
                          disabled={disabled}
                          onChange={(samplingInterVal) => this.setState({ samplingInterVal })}
                        />
                        <Select
                          value={samplingUnitVal}
                          options={this.samplingUnit}
                          style={{ width: 90, marginLeft: 8 }}
                          disabled={disabled}
                          onChange={(samplingUnitVal) => this.setState({ samplingUnitVal })}
                        />
                      </div>
                    </div>
                  )}
                  <div style={{ ...style, flexShrink: 0, marginTop: 8 }}>
                    <Upload.Dragger
                      fileList={fileList}
                      multiple
                      showUploadList={false}
                      beforeUpload={(file, filelLists) => {
                        this.beforeUploadErrorFlag += 1;
                        const uploadFlag = this.beforeUploadErrorFlag === filelLists.length;
                        if (!projectNameValue) {
                          if (uploadFlag) {
                            this.beforeUploadErrorFlag = 0;
                            message.error(intl.formatMessage(eventMessages.projectCannotBeEmpty));
                          }
                          return;
                        }

                        const newFileList = [];
                        R.forEach((item) => {
                          if (item.name.indexOf('.csv') < 0) {
                            if (uploadFlag)
                              message.error(intl.formatMessage(eventMessages.fileFormatError, { fileName: item.name }));
                          } else if (item.size > this.MAX_UPLOAD_SIZE) {
                            if (uploadFlag)
                              message.error(
                                intl.formatMessage(eventMessages.fileUploadMaxError, { fileName: item.name }),
                              );
                          } else {
                            const fileTotalSize = item.size / 1024 / 1024;
                            newFileList.push({
                              fileData: item,
                              state: 'wait',
                              percentage: 0,
                              fileTotalSize,
                              uploadedSize: 0,
                              name: item.name,
                            });
                          }
                        }, filelLists);
                        if (uploadFlag) this.beforeUploadErrorFlag = 0;

                        this.setState({ fileList: [...fileList, ...newFileList] });
                      }}
                      customRequest={this.handleUploadAvatar}
                    >
                      <div
                        className="ant-upload-drag-icon"
                        style={{ fontSize: 30, lineHeight: '30px', marginBottom: 10, color: 'var(--text-color)' }}
                      >
                        <UploadOutlined />
                      </div>
                      <div className="ant-upload-text" style={{ fontSize: 12, color: 'var(--text-color)' }}>
                        {intl.formatMessage(eventMessages.uploadToolTipContent)}
                      </div>
                    </Upload.Dragger>
                  </div>
                  <div style={{ fontSize: 12, color: 'red' }}>
                    *{intl.formatMessage(eventMessages.uploadMaxSizeTitle)}
                  </div>
                  {hasOtherFiles.length > 0 && (
                    <OtherFileListDisplay
                      intl={intl}
                      fileList={fileList}
                      viewFileContent={this.viewFileContent}
                      removeRile={this.removeRile}
                    />
                  )}
                  {hasFailFiles && (
                    <FileListDisplay
                      intl={intl}
                      fileList={fileList}
                      resetFileState={this.resetFileState}
                      viewFileContent={this.viewFileContent}
                      removeRile={this.removeRile}
                    />
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
          <div className="flex-grow" style={{ marginLeft: 12 }}>
            <FileRerunMetricData
              intl={intl}
              push={push}
              refresh={refresh}
              userInfo={userInfo}
              fileList={fileList}
              projectName={projectNameValue}
              credentials={credentials}
              projectList={this.projectList}
              viewFileContent={this.viewFileContent}
              refreshFileRerunData={refreshFileRerunData}
              setFileRerunLoading={(fileRerunLoading) => this.setState({ fileRerunLoading })}
              handleRefresh={handleRefresh}
            />
          </div>
        </div>
        {fileDataModal && (
          <FileDataModal
            intl={intl}
            activeRow={activeRow}
            getCSVData={this.getCSVData}
            onClose={(reload) => this.setState({ fileDataModal: false, activeRow: null })}
          />
        )}
      </>
    );
  }
}

const BigFileUpload = injectIntl(BigFileUploadCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { projects, userList } = state.app;

    const { credentials, userInfo } = state.auth;
    return { credentials, location, projects, userInfo, userList };
  },
  { hideAppLoader, showAppLoader, updateLastActionInfo, updataProject, replace, push },
)(BigFileUpload);
