import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import moment from 'moment';
import RcDatePicker from 'react-datepicker';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Alert, Col, Radio, Row, Select, Spin, message } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { buildUrl, Defaults, utcObjToLocalDate, localDateToUtcObj, parseLocation } from '../../../common/utils';
import { Input, Modal, Popover } from '../../../lib/fui/react';
import { TimeInput } from '../../share/TimeInput';
import { loadProjectInfo, updateLastActionInfo, createSetAction } from '../../../common/app/actions';

import { appFieldsMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  credentials: Object,
  projectList: Array<Object>,
  // eslint-disable-next-line
  projectInstanceComponentMap: Object,
  // eslint-disable-next-line
  loadProjectInfo: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  createSetAction: Function,
  // eslint-disable-next-line
  projectName: String,
  // eslint-disable-next-line
  instanceName: String,
  startTimestamp: Number,
  endTimestamp: Number,
  showKeywordSearch: Boolean,
  // eslint-disable-next-line
  noMetricProject: Boolean,
  onClose: Function,
  globaInstaceMappingProject: Object,
  loading: Boolean,
};

class LineChartTimeSelectModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { startTimestamp, endTimestamp, loading } = props;

    this.state = {
      isLoading: false || loading,

      projectName: undefined,
      instanceName: undefined,
      startTimestamp: startTimestamp || moment.utc().valueOf(),
      endTimestamp: endTimestamp || moment.utc().valueOf(),
      keywordFilter: '',

      globaInstaceMappingProject: {},
      instanceDisplayNameMap: {},
    };
    this.projectListOptions = [];
    this.instanceListOptions = [];
    this.componetName = '';
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      await this.getSystemBasicInfo(this.props);
      this.setState({ isLoading: false || this.props.loading });
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false || this.props.loading });
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.instanceName !== this.props.instanceName) {
      try {
        this.setState({ isLoading: true });
        await this.getSystemBasicInfo(this.props);
        this.setState({ isLoading: false });
      } catch (err) {
        console.log(err);
        this.setState({ isLoading: false || this.props.loading });
      }
    }
    if (nextProps.loading !== this.props.loading) {
      this.setState({ isLoading: this.state.isLoading || nextProps.loading });
    }
  }

  @autobind
  async getSystemBasicInfo(props) {
    const { instanceName, noMetricProject, projectList, globaInstaceMappingProject } = props;
    // eslint-disable-next-line prefer-destructuring
    let containerName = '';
    let splitInstanceName = instanceName || '';
    if (R.includes('_', instanceName || '')) {
      const [ct, inc] = R.split('_', instanceName || '');
      containerName = ct;
      splitInstanceName = inc;
    }
    const instanceList = R.keys(globaInstaceMappingProject || {});
    const findInstance = R.filter(
      (ins) => splitInstanceName === ins || R.includes(ins, splitInstanceName) || R.includes(splitInstanceName, ins),
      instanceList || [],
    );
    const mappingProjectInfos = [];
    R.forEach((ins) => {
      const mappingProjects = globaInstaceMappingProject[ins] || [];
      mappingProjectInfos.push(
        ...R.filter(
          (p) => p.instanceName === ins && (noMetricProject ? p.dataType !== 'Metric' : true),
          mappingProjects,
        ),
      );
    }, findInstance || []);
    const findMappingProject = mappingProjectInfos;
    let newProjectName;
    let newInstanceName;
    let instanceDisplayNameMap = {};
    if (findMappingProject.length > 0) {
      const projects = [];
      R.forEach((p) => {
        const findP = R.find(
          (item) => `${p.projectShortName}@${p.owner}` === `${item.projectName}@${item.userName}`,
          findMappingProject,
        );
        if (findP)
          projects.push({
            ...p,
            instanceName: `${containerName ? `${containerName}_` : ''}${findP.instanceName}`,
            componentName: findP.componentName,
          });
      }, projectList || []);

      instanceDisplayNameMap = await this.getInstanceDisplayNameData(props, projects);

      newProjectName = projects[0]?.projectName;
      newInstanceName = projects[0]?.instanceName;

      this.projectListOptions = R.map(
        (p) => ({
          value: p?.projectName,
          label: `${p?.projectDisplayName}${p?.owner ? `@${p?.owner}` : ''}${
            p?.systemName ? ` (${p?.systemName})` : ''
          }`,
          projectShortName: p.projectShortName,
          owner: p.owner,
          instanceName: p.instanceName,
          componentName: p.componentName,
        }),
        projects,
      );

      this.instanceListOptions = [{ value: newInstanceName, label: newInstanceName }];
      this.componetName = projects[0]?.component;
    }

    this.setState({
      projectName: newProjectName,
      instanceName: newInstanceName,
      globaInstaceMappingProject,
      instanceDisplayNameMap,
    });
  }

  @autobind
  getInstanceDisplayNameData(props, projects = []) {
    const { credentials } = props;
    if (projects.length === 0) {
      return {};
    }
    return fetchGet(getEndpoint('instance-display-name'), {
      ...credentials,
      instanceDisplayNameRequestList: JSON.stringify(
        R.map((p) => ({ projectName: p?.projectShortName, customerName: p?.owner }), projects || []),
      ),
    })
      .then((d1) => {
        const instanceDisplayNameMap = {};
        R.forEach((item) => {
          const [pInfo, iList] = item || [];
          const { projectName, customerName } = pInfo || {};
          R.forEach((instanceInfo) => {
            const { instanceSet, instanceDisplayName } = instanceInfo || {};
            R.forEach((instance) => {
              instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
              instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
            }, instanceSet || []);
          }, iList || []);
        }, d1 || []);
        return instanceDisplayNameMap;
      })
      .catch((err) => {
        message.error(err.message || String(err));
        return {};
      });
  }

  @autobind
  handleConfirm() {
    const { projectList, onClose } = this.props;
    if (onClose) {
      const { projectName, instanceName, startTimestamp, endTimestamp, ...rest } = this.state;
      const currentProject = R.find((project) => project.projectName === projectName, projectList);
      if (currentProject && currentProject.isMetric) {
        const startTimeObj = moment.utc(startTimestamp);
        const endTimeObj = moment.utc(endTimestamp);
        const query = {
          projectName,
          instanceGroup: 'All',
          startTimestamp: startTimeObj.startOf('day').valueOf(),
          endTimestamp: endTimeObj.endOf('day').valueOf(),
          justInstanceList: instanceName,
        };
        window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
        onClose();
      } else {
        onClose({ projectName, instanceName, startTimestamp, endTimestamp, ...rest });
      }
    }
  }

  render() {
    const { intl, projectList } = this.props;
    const { onClose, showKeywordSearch } = this.props;
    const { isLoading, projectName, instanceName, startTimestamp, endTimestamp, instanceDisplayNameMap } = this.state;

    const currentProject = R.find((project) => project.projectName === projectName, projectList || []);
    const isMetric = currentProject && currentProject.isMetric;
    const startTimeObj = moment.utc(startTimestamp);
    const endTimeObj = moment.utc(endTimestamp);
    const keywordFilterLink = VLink.state(this, 'keywordFilter').check(
      (value) => !value || (Boolean(value) && R.test(/^"[^\n\r]+"$/, value)),
      'keyword must be in the right format',
    );

    const hasError =
      !projectName ||
      !instanceName ||
      !startTimestamp ||
      !endTimestamp ||
      startTimestamp > endTimestamp ||
      (showKeywordSearch && keywordFilterLink.error);

    const showError = !isLoading && !projectName && this.projectListOptions.length === 0;
    return (
      <Modal
        width={650}
        title={intl.formatMessage(logMessages.timeRange)}
        onCancel={() => onClose()}
        onOk={this.handleConfirm}
        okButtonProps={{ disabled: hasError }}
        visible
        maskClosable={false}
        zIndex={1002}
      >
        <Spin spinning={isLoading}>
          {showError && (
            <Alert message="The selected instance is not found!" type="error" style={{ marginBottom: 8 }} />
          )}

          <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
            <span className="label bold" style={{ minWidth: 120 }}>
              {intl.formatMessage(appFieldsMessages.project)}:
            </span>
            <Radio.Group
              onChange={(e) => {
                const projectName = e.target.value;
                const findProject = R.find((p) => p.value === projectName, this.projectListOptions || []) || {};
                const newinstance = findProject.instanceName || instanceName;
                this.instanceListOptions = [{ value: newinstance, label: newinstance }];
                this.componetName = findProject.componetName || this.componetName;
                this.setState({ projectName, instanceName: newinstance || instanceName });
              }}
              value={projectName}
              className="flex-grow flex-min-width"
            >
              <Row style={{ maxHeight: 200, overflowY: 'auto' }}>
                {R.map((item) => {
                  return (
                    <Col
                      span={24}
                      key={item.value}
                      className="flex-row flex-center-align"
                      style={{ marginTop: 4, height: 25 }}
                    >
                      <Radio key={item.value} value={item.value} />
                      <span className="flex-grow flex-row flex-center-align overflow-hidden full-height">
                        <Popover content={item.label} mouseEnterDelay={0.3} placement="topLeft" trigger="hover">
                          <span
                            className="hidden-line-with-ellipsis flex-grow full-height font-12"
                            style={{ lineHeight: '25px' }}
                          >
                            {item.label}
                          </span>
                        </Popover>
                      </span>
                    </Col>
                  );
                }, this.projectListOptions || [])}
              </Row>
            </Radio.Group>
          </div>
          <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
            <span className="label bold" style={{ minWidth: 120 }}>
              {intl.formatMessage(appFieldsMessages.instance)}:
            </span>
            <div className="flex-grow flex-min-width">
              <Select
                className="full-width"
                showSearch
                value={instanceName}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                  (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                }
              >
                {R.map((item) => {
                  const findP = R.find((p) => p.value === projectName, this.projectListOptions || []);
                  const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item.value, {
                    pn: findP?.projectShortName,
                    owner: findP?.owner,
                  });
                  return (
                    <Select.Option key={item.value} value={item.value} label={instanceStr}>
                      {`${instanceStr}${
                        item.value !== this.componetName && this.componetName ? ` (${this.componetName})` : ''
                      }`}
                    </Select.Option>
                  );
                }, this.instanceListOptions || [])}
              </Select>
            </div>
          </div>

          <div className="flex-row flex-center-align rc-date-picker" style={{ marginBottom: 16 }}>
            <span className="label bold" style={{ width: 120 }}>
              Start Time:
            </span>
            <RcDatePicker
              showTimeInput
              timeIntervals={15}
              showPopperArrow={false}
              timeFormat={Defaults.DatePickerTime}
              dateFormat={Defaults.DateTimeFormat1}
              selected={utcObjToLocalDate(startTimeObj)}
              customTimeInput={<TimeInput />}
              maxDate={utcObjToLocalDate(moment.utc().add(1, 'days').endOf('day'))}
              onChange={(startTime) => {
                this.setState({ startTimestamp: localDateToUtcObj(startTime).valueOf() });
              }}
            />
            <span className="label bold" style={{ width: 80, marginLeft: 20 }}>
              End Time:
            </span>
            <RcDatePicker
              showTimeInput
              timeIntervals={15}
              showPopperArrow={false}
              timeFormat={Defaults.DatePickerTime}
              dateFormat={Defaults.DateTimeFormat1}
              selected={utcObjToLocalDate(endTimeObj)}
              customTimeInput={<TimeInput />}
              maxDate={utcObjToLocalDate(moment.utc().add(1, 'days').endOf('day'))}
              onChange={(endTime) => {
                this.setState({ endTimestamp: localDateToUtcObj(endTime).valueOf() });
              }}
            />
          </div>

          {showKeywordSearch && !isMetric && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 16 }}>
              <span className="label bold" style={{ width: 120 }}>
                Keyword Filter:
              </span>
              <div className="flex-grow flex-min-width">
                <Input valueLink={keywordFilterLink} placeholder={`"keyword"`} fullWidth style={{ height: 24 }} />
              </div>
            </div>
          )}
        </Spin>
      </Modal>
    );
  }
}

const LineChartTimeSelectModal = injectIntl(LineChartTimeSelectModalCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { loadStatus, projects: projectList, projectInstanceComponentMap } = state.app;
    return {
      location,
      loadStatus,
      credentials,
      projectList,
      projectInstanceComponentMap,
    };
  },
  { push, replace, loadProjectInfo, updateLastActionInfo, createSetAction },
)(LineChartTimeSelectModal);
