import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
// import { get } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Spin, Form, Alert, Select, AutoComplete, Input, DatePicker, message, Tooltip } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Regex } from '../../../common/utils';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { appFieldsMessages, appMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import DashboardMessages from '../../../common/dashboard/messages/DashboardMessages';

type Props = {
  projectNameSet: Array<Object>,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
};

class ProjectInsertIncidentModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    const { projectNameSet } = props;
    const logProjects = R.filter((project) => project.dataType !== 'Metric', projectNameSet || []);

    this.state = {
      isLoading: false,
      isSubmiting: false,
      errMessage: null,
      instancesOptions: [],

      projectName: logProjects.length > 0 ? logProjects[0].projectNameReal : undefined,
      instanceName: undefined,
      timeObj: moment.utc(),

      // service now
      shortDescription: '',
      description: '',
      fieldPriority: '1',
      fieldTicketNumber: '',
      fieldRequestedBy: '',
      fieldAssignedTo: '',
    };
    this.logProjects = logProjects;
  }

  componentDidMount() {
    this.reloadProjectInfo();
  }

  @autobind
  handleProjectChange(projectName) {
    this.setState({ projectName, instanceName: undefined }, () => {
      this.reloadProjectInfo();
    });
  }

  @autobind
  reloadProjectInfo() {
    const { intl, credentials } = this.props;
    const { projectName } = this.state;

    if (projectName) {
      this.setState({ isLoading: true });
      const requests = [
        fetchGet(getEndpoint('ingestlogincident'), {
          ...credentials,
          projectName,
        }),
      ];
      Promise.all(requests)
        .then((results) => {
          const { instances } = results[0] || {};
          const instancesOptions = R.map((item) => ({ value: item, label: item }), instances || []);
          this.setState({ isLoading: false, errMessage: undefined, instancesOptions });
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          this.setState({ isLoading: false, errMessage: err.message || String(err), instancesOptions: [] });
        });
    }
  }

  @autobind
  handleSubmit() {
    this.setState({ isSubmiting: true });
    const { intl, credentials, onClose } = this.props;
    const { projectName, instanceName, timeObj } = this.state;
    const { shortDescription, description, fieldPriority, fieldTicketNumber, fieldRequestedBy, fieldAssignedTo } =
      this.state;

    const data = {
      'short description': shortDescription,
      description,
      priority: Number(fieldPriority),
      'ticket number': fieldTicketNumber,
      'requested by': fieldRequestedBy,
      'assigned to': fieldAssignedTo,
    };

    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('ingestlogincident'), {
      ...credentials,
      projectName,
      instanceName,
      timestamp: timeObj.valueOf(),
      data: JSON.stringify(data),
    })
      .then((d) => {
        const { success, message: msg } = d;
        if (success) {
          message.success(`${intl.formatMessage(appMessages.apiSuccess)}. ${msg}`);
          this.setState({ isSubmiting: false, errMessage: undefined });
          onClose();
        } else {
          this.setState({ isSubmiting: false, errMessage: msg });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({ isSubmiting: false, errMessage: err.message || String(err) });
      });
  }

  render() {
    const { intl, onClose } = this.props;
    const { isLoading, isSubmiting, errMessage, instancesOptions } = this.state;
    const { projectName, instanceName, timeObj } = this.state;
    const { shortDescription, description, fieldPriority, fieldTicketNumber, fieldRequestedBy, fieldAssignedTo } =
      this.state;

    const hasError =
      !projectName ||
      !instanceName ||
      !Regex.instanceName.test(instanceName) ||
      !timeObj ||
      !shortDescription ||
      !description ||
      !fieldPriority;
    return (
      <Modal
        title="Ingest incident data"
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
        width={750}
        bodyStyle={{ height: 450, overflowY: 'auto' }}
      >
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            {errMessage && (
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Alert type="error" showIcon message={null} description={errMessage} />
              </Form.Item>
            )}
            {this.logProjects.length === 0 && (
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Alert
                  type="info"
                  showIcon
                  message={null}
                  description={
                    'No project exists for receiving incident data. ' +
                    'Please create an alert or log project for ingesting incident data.'
                  }
                />
              </Form.Item>
            )}

            <Form.Item
              label={intl.formatMessage(eventMessages.projectName)}
              validateStatus={!projectName ? 'error' : 'success'}
              help={!projectName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                showSearch
                filterOption
                optionFilterProp="label"
                value={projectName}
                onChange={this.handleProjectChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.projectNameReal} label={item.projectDisplayName}>
                      {item.projectDisplayName}
                    </Select.Option>
                  ),
                  this.logProjects || [],
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(eventMessages.instanceName)}
              validateStatus={!instanceName || !Regex.instanceName.test(instanceName) ? 'error' : 'success'}
              help={
                !instanceName
                  ? intl.formatMessage(appFieldsMessages.inputRequired)
                  : !Regex.instanceName.test(instanceName)
                  ? intl.formatMessage(appFieldsMessages.instanceNameCheck)
                  : undefined
              }
              required
            >
              <AutoComplete
                allowClear
                filterOption={(inputValue, option) =>
                  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                options={instancesOptions}
                value={instanceName}
                onChange={(instanceName) => this.setState({ instanceName })}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(appFieldsMessages.time)}
              validateStatus={!timeObj ? 'error' : 'success'}
              help={!timeObj ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <div className="flex-col">
                <div className="flex-row flex-center-align">
                  <DatePicker
                    style={{ width: 200, marginRight: 16 }}
                    allowClear={false}
                    showTime
                    value={timeObj}
                    onChange={(timeObj) => this.setState({ timeObj: moment.utc(timeObj.valueOf()) })}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                  />
                  <Tooltip
                    title={
                      'Please specify the time using UTC time zone. ' +
                      'InsightFinder will transform the time into your time zone specified in your user profile.'
                    }
                    mouseEnterDelay={0.3}
                  >
                    <span className="bold">UTC</span>
                  </Tooltip>
                </div>
              </div>
            </Form.Item>

            <Form.Item
              label={intl.formatMessage(eventMessages.shortDescription)}
              validateStatus={!shortDescription ? 'error' : 'success'}
              help={!shortDescription ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input value={shortDescription} onChange={(e) => this.setState({ shortDescription: e.target.value })} />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(eventMessages.description)}
              validateStatus={!description ? 'error' : 'success'}
              help={!description ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Input.TextArea
                rows={4}
                value={description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(DashboardMessages.priority)}
              validateStatus={!fieldPriority ? 'error' : 'success'}
              help={!fieldPriority ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
              required
            >
              <Select
                allowClear={false}
                options={R.map((item) => ({ value: String(item), label: String(item) }), R.range(1, 6))}
                value={fieldPriority}
                onChange={(fieldPriority) => this.setState({ fieldPriority })}
              />
            </Form.Item>
            <Form.Item label={intl.formatMessage(DashboardMessages.ticketNumber)}>
              <Input
                placeholder="INCxxxxxxx"
                value={fieldTicketNumber}
                onChange={(e) => this.setState({ fieldTicketNumber: e.target.value })}
              />
            </Form.Item>
            <Form.Item label={intl.formatMessage(DashboardMessages.requestedBy)}>
              <Input value={fieldRequestedBy} onChange={(e) => this.setState({ fieldRequestedBy: e.target.value })} />
            </Form.Item>
            <Form.Item label={intl.formatMessage(DashboardMessages.assignedTo)}>
              <Input value={fieldAssignedTo} onChange={(e) => this.setState({ fieldAssignedTo: e.target.value })} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const ProjectInsertIncidentModal = injectIntl(ProjectInsertIncidentModalCore);
export default connect(
  (state) => {
    const { credentials } = state.auth;
    const { projects } = state.app;
    return {
      credentials,
      projects,
    };
  },
  { push, replace, updateLastActionInfo },
)(ProjectInsertIncidentModal);
