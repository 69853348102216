/*  @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */
/* eslint-disable no-console */

import * as R from 'ramda';
import type { Credentials } from '../types';
import fetchGet from './fetchGet';

const getRabbitMQStatusList = (credentials: Credentials, params) => {
  return fetchGet(`${window.BASE_URL || ''}/adminGetRabbitMQStats`, {
    ...credentials,
    ...params,
  }).then((d) => {
    const { success, message, data } = d;
    return {
      rawData: d,
      data: {
        rabbitMQStatusInfo: success ? null : message,
        rabbitMQStatusList: success ? R.sort((a, b) => b.messageNumber - a.messageNumber, message || []) : [],
      },
    };
  });
};
export default getRabbitMQStatusList;
