import moment from 'moment';
import fetchPost from './fetchPost';
import getEndpoint from './getEndpoint';

const refreshTokenFnTime = (props) => {
  const { userName, token } = props || {};
  const credentials = { userName, token };
  let time = null;

  const refreshTokenFnTime = window.localStorage.getItem('refreshTokenFnTime');
  const tokenExpiredTime = window.localStorage.getItem('tokenExpiredTime');
  if (!refreshTokenFnTime && tokenExpiredTime) {
    time = setTimeout(() => {
      fetchPost(getEndpoint('refreshusertoken'), {
        ...credentials,
      })
        .then((data) => {
          const { token, tokenExpiredTime } = data || {};
          // Set tokenExpiredTime in localStorage
          window.localStorage.setItem('tokenExpiredTime', tokenExpiredTime);
          console.debug(`Token Expired Time: ${moment.utc(tokenExpiredTime).format('HH:mm:ss')}`);

          // Set X-CSRF-Token in localStorage
          window.localStorage.setItem('X-CSRF-Token', token);
          window.localStorage.removeItem('refreshTokenFnTime');
        })
        .catch((e) => {
          console.error('[IF] refresh token error', e);
          window.localStorage.removeItem('refreshTokenFnTime');
        });
    }, 5 * 60 * 1000);
    window.localStorage.setItem('refreshTokenFnTime', time);
  }
};
export default refreshTokenFnTime;
