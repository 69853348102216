/* @flow */

/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */

import * as R from 'ramda';
import { get, isEmpty } from 'lodash';

import { Observable } from 'rxjs/Observable';
import type { Deps } from '../../types';
import { getLoaderEpicAction, pickNotNil } from '../../utils';
import { apiEpicErrorHandle } from '../../errors';
import { getCausalIncident } from '../../apis';
import { updateLastActionInfo } from '../../app/actions';
import { setCausalIncidentData } from '../actions';

const loadCausalIncidentEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_CAUSAL_INCIDENT').concatMap((action) => {
    const { force, loader } = action.payload;
    const params = pickNotNil(action.payload.params || {});
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const { instanceName } = params;
    const { incidentData, instanceIncidentData } = state.causal;
    const hasInstance = Boolean(instanceName);
    const prevParams = get(hasInstance ? instanceIncidentData : incidentData, 'params', {});

    const reload = force || isEmpty(params) || !R.equals(prevParams, params);
    let resetAction$ = Observable.of(setCausalIncidentData(params));
    let apiAction$ = Observable.empty();

    if (reload) {
      resetAction$ = Observable.of(setCausalIncidentData(params));
      apiAction$ = Observable.from(getCausalIncident(credentials, params))
        .concatMap((data) => {
          return Observable.of(setCausalIncidentData(params, data));
        })
        .catch((err) => {
          return apiEpicErrorHandle(err);
        });
    }

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), resetAction$, apiAction$, hideLoader);
  });

export default loadCausalIncidentEpic;
