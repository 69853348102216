import React, { useEffect, useState, memo, useRef } from 'react';
import * as R from 'ramda';
import dagre from 'dagre';
import { isEmpty } from 'lodash';
import { Button, Popover } from 'antd';
import moment from 'moment';

import {
  BlockOutlined,
  CloudServerOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  EllipsisOutlined,
  FileTextOutlined,
  FileOutlined,
  SubnodeOutlined,
  DoubleRightOutlined,
} from '@ant-design/icons';
import { Handle, Position, ReactFlow, useEdgesState, useNodesState, Panel } from '../../../lib/reactflow/core';
import { Controls } from '../../../lib/reactflow/controls';
import '../../../lib/reactflow/style.css';
import { buildUrl, parseLocation } from '../../../common/utils';
import BaseUrls from '../../app/BaseUrls';

const colorsRGB = [
  // green
  '#3af570',
  '#2cdd55',
  '#1dc639',
  '#0fae1e',
  // yellow
  '#d0e552',
  '#efe747',
  '#eec82c',
  // red
  '#FFA58B',
  '#FFA58B',
  '#FF826F',
  '#FF4C28',
];

const nodeWidth = 140;
const nodeHeight = 40;
const nodeListHeight = 400;
const ranksep = 200;

const IconStyle = { fontSize: 24 };
const LabelStyle = {
  fontSize: 12,
  textAlign: 'center',
  width: nodeWidth,
};
const HasDataColor = `linear-gradient(to right, ${colorsRGB[0]} 0%, ${colorsRGB[4]} 40%, ${colorsRGB[10]} 100%)`;
const NoDataColor = 'white';
const MissingDataColor = `linear-gradient(to right, var(--react-flow-missing-data-bg) 0%, var(--react-flow-missing-data-bg) 50%, ${colorsRGB[0]} 50%, ${colorsRGB[4]} 70%, ${colorsRGB[10]} 100%)`;

// dataStatus: 0 is no data, 1 is missing data, 2 is complete data
const NodeType = {
  0: 'serviceNow',
  1: 'container',
  2: 'instance',
  3: 'component',
};

const MaxThreshold = 1.5 * 1000000;

const pageSize = 10;

function jumpToInvestigation(data) {
  const query = parseLocation(window.location);
  const params = {
    ...query,
    forceRefreshTime: undefined,
  };
  if (data.type === 'component') {
    params.jumpComponentName = data.label;
  } else if (data.type === 'instance') {
    params.jumpInstanceName = data.label;
  }
  window.open(buildUrl(BaseUrls.GlobalSystemRootCause, {}, params), '_blank');
}

function titleCase(str) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}

const CustomNodeFactory =
  (NodeIcon) =>
  ({ data, type, id, ...rest }: Object) => {
    return (
      <Popover
        content={
          type === 'service' ? null : (
            <div className="flex-col">
              <div className="flex-row flex-center-align">
                <span className="inline-block light-label bold" style={{ marginRight: 4, width: 110 }}>
                  Anomaly score:
                </span>
                <span>
                  {data?.scoreRaw}
                  <Button
                    size="small"
                    style={{ color: 'var(--link-color)', marginLeft: 4 }}
                    onClick={(e) => jumpToInvestigation({ ...data, type })}
                  >
                    Detail
                  </Button>
                </span>
              </div>
              <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
                <span style={{ marginRight: 4, width: 110 }} className="inline-block light-label bold">
                  {titleCase(type)} name:
                </span>
                <span
                  className="inline-block"
                  style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', maxWidth: 200 }}
                >
                  {data.label}
                </span>
              </div>
            </div>
          )
        }
      >
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden' }} />
        <div className="flex-col nodrag">
          <NodeIcon style={{ ...IconStyle, color: type === 'service' ? 'var(--text-color)' : 'inherit' }} />
          <span
            style={{ ...LabelStyle, color: type === 'service' ? 'var(--text-color)' : 'inherit' }}
            className="inline-block hidden-line-with-ellipsis max-width"
          >
            {data.label}
          </span>
          {data?.hasChildren && (
            <>
              <DoubleRightOutlined
                style={{
                  ...IconStyle,
                  cursor: 'pointer',
                  color: type === 'service' ? 'var(--text-color)' : 'inherit',
                  transform: `rotate(${data.expand ? -90 : 90}deg)`,
                  fontSize: 18,
                }}
                className="nodrag"
              />
            </>
          )}
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ visibility: 'hidden', color: type === 'service' ? 'var(--text-color)' : null }}
        />
      </Popover>
    );
  };

const ListNode = (NodeIcon) =>
  memo(({ data, type, id }: Object) => {
    return (
      <>
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden' }} />
        <div className="flex-col nodrag">
          <NodeIcon style={{ ...IconStyle }} />
          <span style={{ ...LabelStyle, width: '100%' }}>{data.label}</span>
        </div>
        <div
          className="flex-col nodrag nowheel serviceMap-container-bar"
          style={{
            width: 240,
            maxHeight: nodeListHeight,
            border: '1px solid var(--black)',
            background: 'var(--react-flow-bg)',
            overflow: 'hidden auto',
            borderRadius: 4,
            cursor: 'default',
          }}
        >
          {R.map((cItem) => {
            return (
              <div
                key={cItem.name}
                className="font-12 flex-row flex-center-align"
                style={{
                  borderBottom: '1px solid var(--virtualized-table-row-border)',
                  wordBreak: 'break-word',
                  padding: '0 6px',
                }}
              >
                <span
                  style={{
                    width: 10,
                    height: 10,
                    background: cItem.background,
                    borderRadius: 100,
                    marginRight: 4,
                    display: 'inline-block',
                    border: `0.5px solid ${cItem?.status === 0 ? 'var(--black)' : 'var(--border-color-base)'}`,
                  }}
                />
                <div className="flex-col">
                  <span className="flex-grow">{cItem.name}</span>
                  <span>(Anomaly score: {cItem.scoreRaw})</span>
                </div>
              </div>
            );
          }, data?.list)}
        </div>
        <Handle type="source" position={Position.Bottom} style={{ visibility: 'hidden' }} />
      </>
    );
  });

const LoadMoreNode = (NodeIcon) =>
  memo(({ data, type, id }) => {
    return (
      <>
        <Handle type="target" position={Position.Top} style={{ visibility: 'hidden' }} />
        <div className="flex-col flex-center-align nodrag" style={{ height: nodeHeight + 20 }}>
          <span style={{ ...LabelStyle, width: '100%', fontSize: 24, color: 'var(--text-color)' }}>{data.label}</span>
          {data?.hasRest && (
            <EllipsisOutlined
              style={{ ...IconStyle, cursor: 'pointer', color: 'var(--text-color)' }}
              className="nodrag"
            />
          )}
        </div>
        <Handle type="source" position={Position.Bottom} style={{ visibility: 'hidden' }} />
      </>
    );
  });

const ServiceNode = CustomNodeFactory(ClusterOutlined);
const ComponentNode = CustomNodeFactory(BlockOutlined);
const InstanceNode = CustomNodeFactory(CloudServerOutlined);
const ContainerNode = ListNode(DatabaseOutlined);
const ServiceNow = CustomNodeFactory(FileTextOutlined);
const DefaultNode = CustomNodeFactory(FileOutlined);
const PaginationNode = LoadMoreNode(SubnodeOutlined);

const nodeTypes = {
  service: ServiceNode,
  component: ComponentNode,
  instance: InstanceNode,
  container: ContainerNode,
  serviceNow: ServiceNow,
  defaultNode: DefaultNode,
  paginationNode: PaginationNode,
};

function convertToK(str) {
  if (!str || R.isNil(str)) {
    return { num: 0, rawNum: 0 };
  }
  let num = parseFloat(str);
  if (str.includes('K')) {
    num *= 1000;
  } else if (str.includes('M')) {
    num *= 1000000;
  }
  return { num: num >= MaxThreshold ? MaxThreshold : num, rawNum: num };
}

const mapTree = (org, key) => {
  const haveChildren = Array.isArray(org.children) && org.children.length > 0;
  const { num, rawNum } = convertToK(org.anomalyScoreStr);
  const score = num ? (Math.floor(Math.log(num) / 1.4) <= 0 ? 0 : Math.floor(Math.log(num) / 1.4)) : 0;
  return {
    ...org,
    key: key + org.name,
    name: org.name,
    score,
    scoreRaw: rawNum,
    children: haveChildren ? org.children.map((i, k) => mapTree(i, `${key}-${k}`)) : [],
  };
};

// dataStatus: 0 no data, 1 missing data, 2 complete data
function getNodeStatusColor(status, score) {
  let state = 0;
  switch (status.length) {
    case 1:
      state = status[0];
      break;
    case 2:
      state = 1;
      break;
    case 3:
      state = 1;
      break;
    default:
      break;
  }

  const gradientColor = colorsRGB[score];
  const color = 'var(--react-flow-label-color)';
  let background;

  switch (state) {
    case 0:
      // no data
      background = NoDataColor;
      break;
    case 1:
      // missing data
      background = `linear-gradient(to right, var(--react-flow-missing-data-bg) 0%, var(--react-flow-missing-data-bg) 50%,  ${gradientColor} 50%,${gradientColor} 100%)`;
      break;
    case 2:
      // complete data
      background = gradientColor;
      break;

    default:
      break;
  }
  return { color, status: state, background };
}

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction, ranksep, nodesep: 80 });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

function addPaginationNode(parentKey, allData, pageDirection) {
  const key = `${parentKey}-${pageDirection}`;
  const node = {
    id: key,
    type: 'paginationNode',
    data: {
      label: pageDirection === 'prev' ? `prev ${pageSize}` : `next ${pageSize}`,
      hasRest: true,
      allData,
      parentKey,
      expand: false,
      pageDirection,
    },
    style: { borderRadius: 6 },
    expand: false,
    isPagination: true,
  };
  const edge = {
    id: `${parentKey}-${key}`,
    source: parentKey,
    target: key,
    animated: true,
    style: { stroke: 'var(--black)' },
  };

  return { node, edge };
}

const pagingStates = {};

function getNodesAndEdges({ data, rootName }) {
  const total = (data || []).length;
  const pageIndex = pagingStates[rootName] || 0;

  const pageData = R.take(pageSize, R.drop(pageIndex * pageSize, data));
  const nodes = [
    {
      id: rootName,
      type: 'service',
      data: { label: rootName, hasChildren: !isEmpty(data), children: data, expand: true },
      expand: true,
    },
  ];
  const edges = [];
  // dataStatus: 0 no data, 1 missing data, 2 complete data

  R.forEach((item) => {
    const { key, name, children, type } = item;
    const { score, scoreRaw } = item;
    let { dataStatus } = item;
    const hasChildren = !isEmpty(children);
    dataStatus = R.uniq(R.values(dataStatus || {}));
    const { background, color } = getNodeStatusColor(dataStatus, score, type);

    nodes.push({
      id: key,
      type: NodeType[type] || 'defaultNode',
      data: {
        label: name,
        hasChildren,
        children,
        scoreRaw,
        expand: false,
      },
      style: {
        color,
        background,
        borderRadius: 6,
        border: '1px solid var(--border-color-base)',
      },
      expand: false,
    });
    edges.push({
      id: `${rootName}-${key}`,
      source: rootName,
      target: key,
    });
  }, pageData);

  if (pageIndex > 0) {
    const { node: PN, edge: PE } = addPaginationNode(rootName, data, 'prev');
    nodes.push(PN);
    edges.push(PE);
  }

  if (total > (pageIndex + 1) * pageSize) {
    const { node: PN, edge: PE } = addPaginationNode(rootName, data, 'next');
    nodes.push(PN);
    edges.push(PE);
  }

  return { nodes, edges };
}

// type: 1 is container, 2 is instance, 3 is component.
// dataStatus: 0 no data, 1 missing data, 2 complete data

function addNodes({ data, parentKey, parentInstance }) {
  const total = (data || []).length;
  const pageIndex = pagingStates[parentKey] || 0;

  const pageData = R.take(pageSize, R.drop(pageIndex * pageSize, data));

  const nodes = [];
  const edges = [];
  const isListNode = data.length > 0 && data[0].type === 1;
  if (isListNode) {
    const listKey = `${parentKey}-container`;
    let list = R.map((item) => {
      const { score, scoreRaw } = item;
      let { dataStatus } = item;
      dataStatus = R.uniq(R.values(dataStatus || [0]));
      const { background, status, color } = getNodeStatusColor(dataStatus, score);
      return { name: item.name, background, scoreRaw, status, color };
    }, data);
    list = R.sortWith([R.descend(R.prop('scoreRaw')), R.descend(R.prop('status'))], list);
    nodes.push({
      id: listKey,
      type: NodeType[1],
      data: {
        label: 'Container',
        isListNode,
        list,
        expand: false,
      },
      expand: false,
    });
    edges.push({
      id: `${parentKey}-${listKey}`,
      source: parentKey,
      target: listKey,
    });

    return { nodes, edges };
  }

  R.forEach((item) => {
    const { key, name, children, type } = item;

    const hasChildren = !isEmpty(children);

    const { score, scoreRaw } = item;
    let { dataStatus } = item;
    dataStatus = R.uniq(R.values(dataStatus || [0]));
    const { background, color } = getNodeStatusColor(dataStatus, score);
    nodes.push({
      id: key,
      type: NodeType[type] || 'defaultNode',
      data: {
        label: name,
        hasChildren,
        children,
        scoreRaw,
        expand: false,
      },
      style: { color, background, borderRadius: 6, border: '1px solid var(--border-color-base)' },
      expand: false,
    });
    edges.push({
      id: `${parentKey}-${key}`,
      source: parentKey,
      target: key,
    });
  }, pageData);

  if (pageIndex > 0) {
    const { node: PN, edge: PE } = addPaginationNode(parentKey, data, 'prev');
    nodes.push(PN);
    edges.push(PE);
  }

  if (total > (pageIndex + 1) * pageSize) {
    const { node: PN, edge: PE } = addPaginationNode(parentKey, data, 'next');
    nodes.push(PN);
    edges.push(PE);
  }
  return { nodes, edges };
}

function getNodeRelateKeys(data, keys = []) {
  R.forEach((item) => {
    keys.push(item.key);
    if (item.type === 2) {
      keys.push(`${item.key}-container`);
    }
    keys.push(`${item.key}-prev`);
    keys.push(`${item.key}-next`);
    if (item.children && !isEmpty(item.children)) {
      getNodeRelateKeys(item.children, keys);
    }
  }, data);

  return keys;
}

const ServiceMapVisualizer = ({ width, height, data = [], rootName = 'No system' }) => {
  const [refresh, setRefresh] = useState(moment.utc().valueOf());
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const flowRef = useRef(null);
  useEffect(() => {
    const nodeTree = data.map((org, key) => mapTree(org, key));
    const { nodes, edges } = getNodesAndEdges({ data: nodeTree, rootName });
    const { nodes: n, edges: e } = getLayoutedElements(nodes, edges);
    setNodes(n);
    setEdges(e);
    setRefresh(moment.utc().valueOf());
  }, [data]);

  useEffect(() => {
    if (flowRef.current) {
      setTimeout(() => {
        flowRef.current.fitView();
      }, 200);
    }
  }, [refresh]);

  const onExpandNode = (open, node) => {
    const {
      id: parentKey,
      data: { children, label: parentInstance },
      type: parentType,
    } = node;
    const needRemoveNodeKeys = [
      ...getNodeRelateKeys(children),
      `${parentKey}-container`,
      `${parentKey}-prev`,
      `${parentKey}-next`,
    ];
    let newNodes = R.filter((item) => !needRemoveNodeKeys.includes(item.id), nodes);
    let newEdges = R.filter((item) => !needRemoveNodeKeys.includes(item.target), edges);

    const currentNodeIndex = R.findIndex((item) => item.id === parentKey, newNodes);
    newNodes[currentNodeIndex].data.expand = open;
    newNodes = [...newNodes, { ...newNodes[currentNodeIndex], expand: open }];
    if (open) {
      const { nodes: n, edges: e } = addNodes({
        data: children,
        parentKey,
        parentType,
        parentInstance,
      });
      newNodes = newNodes.concat(n);
      newEdges = newEdges.concat(e);
    }
    const { nodes: ln, edges: le } = getLayoutedElements(newNodes, newEdges);
    newNodes = ln;
    newEdges = le;
    setNodes(newNodes);
    setEdges(newEdges);
  };

  const onSpreadNode = (node) => {
    const {
      data: { allData, parentKey, pageDirection },
    } = node;
    const total = (allData || []).length;

    let pageIndex = pagingStates[parentKey] || 0;
    pageIndex = pageDirection === 'next' ? pageIndex + 1 : pageIndex - 1;
    pageIndex = pageIndex < 0 ? 0 : pageIndex;

    const pageData = R.take(pageSize, R.drop(pageIndex * pageSize, allData));
    const parent = R.find((n) => n.id === parentKey, nodes);

    const needRemoveNodeKeys = [
      ...getNodeRelateKeys(parent?.data?.children || []),
      `${parentKey}-container`,
      `${parentKey}-prev`,
      `${parentKey}-next`,
    ];
    let newNodes = R.filter((item) => !needRemoveNodeKeys.includes(item.id), nodes);
    let newEdges = R.filter((item) => !needRemoveNodeKeys.includes(item.target), edges);

    R.forEach((item) => {
      const { key, name, children, type } = item;
      const { score, scoreRaw } = item;
      let { dataStatus } = item;
      const hasChildren = !isEmpty(children);
      dataStatus = R.uniq(R.values(dataStatus || [0]));
      const { background, color } = getNodeStatusColor(dataStatus, score);
      newNodes.push({
        id: key,
        type: NodeType[type] || 'defaultNode',
        data: {
          label: name,
          hasChildren,
          children,
          scoreRaw,
          expand: false,
        },
        style: { color, background, borderRadius: 6, border: '1px solid var(--border-color-base)' },
        expand: false,
      });
      newEdges.push({
        id: `${parentKey}-${key}`,
        source: parentKey,
        target: key,
      });
    }, pageData);

    if (pageIndex > 0) {
      const { node: PN, edge: PE } = addPaginationNode(parentKey, allData, 'prev');
      newNodes = [...newNodes, PN];
      newEdges = [...newEdges, PE];
    }

    if (total > (pageIndex + 1) * pageSize) {
      const { node: PN, edge: PE } = addPaginationNode(parentKey, allData, 'next');
      newNodes = [...newNodes, PN];
      newEdges = [...newEdges, PE];
    }

    const { nodes: n, edges: e } = getLayoutedElements(newNodes, newEdges);

    setNodes(n);
    setEdges(e);
    pagingStates[parentKey] = pageIndex;
  };

  const onNodeClick = (event, node) => {
    const {
      data: { hasChildren, hasRest },
      expand,
      isPagination,
      type,
    } = node;
    if (!hasChildren && !hasRest) return;
    if (isPagination) {
      onSpreadNode(node);
    } else {
      onExpandNode(!expand, node);
      if (type === 'service') {
        setRefresh(moment.utc().valueOf());
      }
    }
  };

  const onInit = (reactFlowInstance) => {
    flowRef.current = reactFlowInstance;
  };
  return (
    <div style={{ width, height, border: '1px solid var(--border-color-base)', background: 'var(--react-flow-bg)' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        proOptions={{ hideAttribution: true }}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        minZoom={0.2}
        onNodeClick={onNodeClick}
        onInit={onInit}
        fitView
        selectionKeyCode={false}
      >
        <Panel position="top-left">
          <div className="flex-row flex-center-align" style={{ columnGap: 14 }}>
            <div className="flex-row flex-center-align">
              <div style={{ width: 34, height: 14, background: MissingDataColor, borderRadius: 16, marginRight: 5 }} />
              Missing data & Anomaly score
            </div>
            <div className="flex-row flex-center-align">
              <div style={{ width: 34, height: 14, background: HasDataColor, borderRadius: 16, marginRight: 5 }} />
              Anomaly score
            </div>
            <div className="flex-row flex-center-align">
              <div
                style={{
                  width: 34,
                  height: 16,
                  background: NoDataColor,
                  borderRadius: 16,
                  marginRight: 5,
                  border: '0.5px solid var(--black)',
                }}
              />
              No data
            </div>
          </div>
        </Panel>
        <Controls showInteractive={false} position="top-right" />
      </ReactFlow>
    </div>
  );
};

export default ServiceMapVisualizer;
