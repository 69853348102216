import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import Helmet from 'react-helmet';
import { get, indexOf } from 'lodash';
import {
  DashboardOutlined,
  SettingOutlined,
  UserOutlined,
  ClusterOutlined,
  LineChartOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Menu, Button, Descriptions, Popover, Avatar, Tooltip, DatePicker } from 'antd';

import getEndpoint from '../apis/getEndpoint';
import { BaseUrls } from '../../web/app/Constants';
import { buildUrl, parseLocation, Defaults, CellRenderers } from '.';
import {
  GlobalHealthIcon,
  IncidentPredictIcon,
  RootCauseIcon,
  AlertCauseIcon,
  KnowledgeBaseIcon,
  CapacityPlanningIcon,
  QeuryIcon,
  AnalysisIcon,
  ActionIconNoFill,
  AnomalyDetectionIcon,
} from '../../lib/fui/icons';
import { Modal, List, CellMeasurer, CellMeasurerCache } from '../../lib/fui/react';

import { appMenusMessages, appFieldsMessages } from '../app/messages';
import { eventActionMessages } from '../metric/messages';

import { CollapsibleLogContent } from '../../web/share';
import { getHandTitleText, getMenuName } from './getHandTitleText';

const { SubMenu } = Menu;

type PropsSiderMenuRender = {
  intl: Object,
  currentTheme: Object,
  push: Function,
  location: Object,
  userInfo: Object,
  systemsMap: Object,
  projectSettingsParams: Object,
  openedSubMenus: Array<string>,
  setOpenedSubMenus: Function,
  collapsed: Boolean,
};

type PropsSoftwareUpdateContextModal = {
  intl: Object,
  incident: Object,
  onCancel: Function,
};

type PropsTimePairs = {
  intl: Object,
  width: Number,
  height: Number,
  timePairs: Array<Object>,
};

// global renderers
const GlobalRenderers = {
  SiderMenuRender: ({
    intl,
    currentTheme,
    push,
    location,
    userInfo,
    systemsMap,
    projectSettingsParams,
    openedSubMenus,
    setOpenedSubMenus,
    collapsed,
  }: PropsSiderMenuRender) => {
    const displayedMenus = userInfo.displayedMenus || Defaults.DisplayedMenus;
    const { isAdmin, isReadUser, allowFileUpload, isLocalAdmin } = userInfo;

    // handle the params
    const params = parseLocation(location);
    const { environmentId, systemId, hasLog, hasAlert, projectName } = params;
    let { customerName } = params;

    // build selected menu key
    let selectedKey = location.pathname;
    if (location.pathname.indexOf(BaseUrls.Log) === 0) {
      if (hasLog === 'true') selectedKey = `${BaseUrls.Log}_hasLog`;
      if (hasAlert === 'true') selectedKey = `${BaseUrls.Log}_hasAlert`;
    } else if (location.pathname.indexOf(BaseUrls.Causal) === 0) {
      selectedKey = BaseUrls.Causal;
    }

    const handleMenuSelect = ({ item, key, keyPath, domEvent }) => {
      if (item.props.url) {
        push(item.props.url);
      } else {
        let { startTime, endTime } = params;
        if (startTime && startTime.length !== 10) startTime = null;
        if (endTime && endTime.length !== 10) endTime = null;
        const prevCustomerName = sessionStorage.getItem('dashboardCustomerName');
        if (prevCustomerName !== null && customerName) {
          customerName = prevCustomerName;
        }

        push(buildUrl(key, {}, { customerName, startTime, endTime, environmentId, systemId }));
      }
    };

    const handleSubmenuClick = ({ key }) => {
      const idx = indexOf(openedSubMenus, key);
      if (collapsed) {
        setOpenedSubMenus([key]);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (idx >= 0) {
          setOpenedSubMenus([]);
        } else {
          setOpenedSubMenus([key]);
        }
      }
    };

    const returnMenuChildren = () => {
      return (
        <>
          {displayedMenus.includes(4) && (
            <Menu.Item
              key={BaseUrls.GlobalSystemRootCause}
              className="menu-RCA"
              title={<span>{getMenuName(BaseUrls.GlobalSystemRootCause, intl)}</span>}
            >
              <RootCauseIcon />
              <span>{getMenuName(BaseUrls.GlobalSystemRootCause, intl)}</span>
            </Menu.Item>
          )}
          {displayedMenus.includes(3) && (
            <Menu.Item
              key={BaseUrls.GlobalSystemPrediction}
              className="menu-IP"
              title={<span>{getMenuName(BaseUrls.GlobalSystemPrediction, intl)}</span>}
            >
              <IncidentPredictIcon />
              <span>{getMenuName(BaseUrls.GlobalSystemPrediction, intl)}</span>
            </Menu.Item>
          )}
          <Menu.Item
            key={BaseUrls.GlobalServiceMap}
            title={<span>{getMenuName(BaseUrls.GlobalServiceMap, intl)}</span>}
          >
            <ClusterOutlined />
            <span>{getMenuName(BaseUrls.GlobalServiceMap, intl)}</span>
          </Menu.Item>
          {!isReadUser && allowFileUpload && (
            <Menu.Item
              key={BaseUrls.FileAnalysis}
              title={<span>{getMenuName(BaseUrls.FileAnalysis, intl)}</span>}
              // url={buildUrl(BaseUrls.FileAnalysis, {}, { customerName })}
            >
              <AnomalyDetectionIcon />
              <span>{getMenuName(BaseUrls.FileAnalysis, intl)}</span>
            </Menu.Item>
          )}
          {displayedMenus.includes(1) && (
            <Menu.Item
              key={BaseUrls.GlobalHealth}
              className="menu-GHV"
              title={<span>{getMenuName(BaseUrls.GlobalHealth, intl)}</span>}
            >
              <GlobalHealthIcon />
              <span>{getMenuName(BaseUrls.GlobalHealth, intl)}</span>
            </Menu.Item>
          )}
          {displayedMenus.includes(2) && (
            <Menu.Item
              key={BaseUrls.GlobalDashboard}
              className="menu-AI"
              title={<span>{getMenuName(BaseUrls.GlobalSystemInsights, intl)}</span>}
            >
              <DashboardOutlined />
              <span>{getMenuName(BaseUrls.GlobalSystemInsights, intl)}</span>
            </Menu.Item>
          )}
          {false && displayedMenus.includes(5) && (
            <Menu.Item
              key={BaseUrls.GlobalSystemAlertCause}
              className="menu-AA"
              title={<span>{getMenuName(BaseUrls.GlobalSystemAlertCause, intl)}</span>}
            >
              <AlertCauseIcon />
              <span>{getMenuName(BaseUrls.GlobalSystemAlertCause, intl)}</span>
            </Menu.Item>
          )}
          {false && displayedMenus.includes(7) && (
            <Menu.Item
              key={BaseUrls.GlobalCapacityPlanning}
              className="menu-CP"
              title={<span>{getMenuName(BaseUrls.GlobalCapacityPlanning, intl)}</span>}
            >
              <CapacityPlanningIcon />
              <span>{getMenuName(BaseUrls.GlobalCapacityPlanning, intl)}</span>
            </Menu.Item>
          )}
          {false && displayedMenus.includes(7) && (
            <Menu.Item
              key={BaseUrls.Query}
              className="menu-query"
              title={<span>{getMenuName(BaseUrls.Query, intl)}</span>}
            >
              <QeuryIcon />
              <span>{getMenuName(BaseUrls.Query, intl)}</span>
            </Menu.Item>
          )}
          <Menu.Item key={BaseUrls.GlobalAction} title={<span>{getMenuName(BaseUrls.GlobalAction, intl)}</span>}>
            <ActionIconNoFill />
            <span>{getMenuName(BaseUrls.GlobalAction, intl)}</span>
          </Menu.Item>
          <Menu.Item key={BaseUrls.MetricLineCharts} title={<span>Metric line chart</span>}>
            <LineChartOutlined />
            <span>Metric line chart</span>
          </Menu.Item>

          {displayedMenus.includes(8) && (
            <SubMenu
              key="analysis"
              className="menu-analysis"
              onTitleClick={handleSubmenuClick}
              title={
                <Tooltip
                  title={collapsed ? <span>{intl.formatMessage(appMenusMessages.analysis)}</span> : null}
                  placement="right"
                  overlayStyle={{ zIndex: 1049 }}
                  align={{ offset: [20, 0] }}
                >
                  <span>
                    <AnalysisIcon />
                    {collapsed ? <></> : <span>{intl.formatMessage(appMenusMessages.analysis)}</span>}
                  </span>
                </Tooltip>
              }
            >
              <Menu.Item
                className="menu-analysis-metrics"
                key={BaseUrls.MetricEvents}
                url={buildUrl(BaseUrls.MetricEvents, {}, { customerName })}
              >
                {getMenuName(BaseUrls.MetricEvents, intl)}
              </Menu.Item>
              <Menu.Item
                key={`${BaseUrls.Log}_hasLog`}
                url={buildUrl(BaseUrls.LogCalendar, {}, { hasLog: true, customerName })}
              >
                {getMenuName(`${BaseUrls.Log}_hasLog`, intl)}
              </Menu.Item>
              <Menu.Item
                key={`${BaseUrls.Log}_hasAlert`}
                url={buildUrl(BaseUrls.LogCalendar, {}, { hasAlert: true, customerName })}
              >
                {getMenuName(`${BaseUrls.Log}_hasAlert`, intl)}
              </Menu.Item>
              <Menu.Item key={BaseUrls.Causal} url={buildUrl(BaseUrls.CausalAnalysis, {}, { customerName })}>
                {getMenuName(BaseUrls.Causal, intl)}
              </Menu.Item>
              {displayedMenus.includes(6) && (
                <Menu.Item
                  key={BaseUrls.GlobalSystemKnowledgeBase}
                  url={buildUrl(BaseUrls.GlobalSystemKnowledgeBase, {}, { customerName })}
                >
                  {getMenuName(BaseUrls.GlobalSystemKnowledgeBase, intl)}
                </Menu.Item>
              )}
            </SubMenu>
          )}

          <SubMenu
            key={BaseUrls.Settings}
            className="menu-setting"
            onTitleClick={handleSubmenuClick}
            title={
              <Tooltip
                title={collapsed ? <span>{intl.formatMessage(appMenusMessages.settings)}</span> : null}
                placement="right"
                overlayStyle={{ zIndex: 1049 }}
                align={{ offset: [20, 0] }}
              >
                <span>
                  <SettingOutlined />
                  {collapsed ? <></> : <span>{intl.formatMessage(appMenusMessages.settings)}</span>}
                </span>
              </Tooltip>
            }
          >
            <Menu.Item key={BaseUrls.SystemSetting}>{getMenuName(BaseUrls.SystemSetting, intl)}</Menu.Item>
            {!isReadUser && (
              <Menu.Item key={BaseUrls.SettingsIntegrations}>
                {getMenuName(BaseUrls.SettingsIntegrations, intl)}
              </Menu.Item>
            )}
            {!isReadUser && <Menu.Item key={BaseUrls.SystemBatch}>{getMenuName(BaseUrls.SystemBatch, intl)}</Menu.Item>}
            {(isAdmin || isLocalAdmin) && (
              <Menu.Item key={BaseUrls.InsightfinderSetting}>
                {getMenuName(BaseUrls.InsightfinderSetting, intl)}
              </Menu.Item>
            )}
            {!isReadUser && (
              <Menu.Item key={BaseUrls.ExternalServiceSetting}>
                {getMenuName(BaseUrls.ExternalServiceSetting, intl)}
              </Menu.Item>
            )}
            {(isAdmin || isLocalAdmin) && (
              <Menu.Item key={BaseUrls.IdentityProvidersSetting}>
                {getMenuName(BaseUrls.IdentityProvidersSetting, intl)}
              </Menu.Item>
            )}
            {isAdmin && (
              <Menu.Item key={BaseUrls.StatusPageSetting}>{getMenuName(BaseUrls.StatusPageSetting, intl)}</Menu.Item>
            )}
          </SubMenu>
        </>
      );
    };

    return (
      <>
        <Helmet
          title={getHandTitleText(
            selectedKey,
            intl,
            projectName,
            systemId,
            systemsMap,
            projectSettingsParams,
            location,
          )}
        />
        {collapsed && (
          <Menu
            theme="dark"
            selectedKeys={[selectedKey]}
            onSelect={handleMenuSelect}
            mode="inline"
            style={{ height: '100%', borderRight: 0, fontSize: 13 }}
            triggerSubMenuAction="hover"
            className="close-fold"
          >
            {returnMenuChildren()}
          </Menu>
        )}
        {!collapsed && (
          <Menu
            theme="dark"
            selectedKeys={[selectedKey]}
            onSelect={handleMenuSelect}
            openKeys={openedSubMenus || []}
            mode="inline"
            style={{ height: '100%', borderRight: 0, fontSize: 13 }}
          >
            {returnMenuChildren()}
          </Menu>
        )}
      </>
    );
  },
  SoftwareUpdateContextModal({ intl, incident, onCancel }: PropsSoftwareUpdateContextModal) {
    const { startTimestamp: deploymentTime, rawData: deploymentMessage } = incident || {};
    return (
      <Modal
        width={650}
        title={intl.formatMessage(appFieldsMessages.softwareUpdateContext)}
        onCancel={onCancel}
        onOk={onCancel}
        visible
        maskClosable={false}
      >
        <Descriptions bordered size="small" title={null}>
          {deploymentTime && (
            <Descriptions.Item label={intl.formatMessage(appFieldsMessages.lastSoftwareUpdateTime)} span={3}>
              {moment.utc(deploymentTime).format(Defaults.DateTimeFormat)}
            </Descriptions.Item>
          )}
          {deploymentMessage && (
            <Descriptions.Item label={intl.formatMessage(appFieldsMessages.softwareUpdateInformation)} span={3}>
              <div style={{ wordBreak: 'break-all' }}>
                <div>
                  {`${deploymentMessage.substring(0, 300)}...`}
                  <Popover
                    placement="right"
                    content={
                      <div
                        className="log-event-group flex-col"
                        style={{
                          width: 450,
                          maxHeight: 300,
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          fontSize: 12,
                          padding: '0 0',
                        }}
                      >
                        <CollapsibleLogContent
                          ownerObject={incident}
                          message={deploymentMessage}
                          width={450}
                          hideExpanded
                        />
                      </div>
                    }
                    trigger="click"
                  >
                    <Button
                      type="link"
                      style={{
                        padding: 0,
                        height: 'auto',
                        fontSize: 12,
                      }}
                    >
                      {intl.formatMessage(appFieldsMessages.expand)}
                    </Button>
                  </Popover>
                </div>
              </div>
            </Descriptions.Item>
          )}
        </Descriptions>
      </Modal>
    );
  },
  TimePairs({ intl, width, height, timePairs }: PropsTimePairs) {
    const cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 24 });

    const renderListItem = ({ key, index: rowIndex, style, parent }) => {
      const item = timePairs[rowIndex];
      if (!item) return null;

      return (
        <CellMeasurer key={key} cache={cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div style={{ ...style }}>
            <span className="light-label bold" style={{ marginRight: 4 }}>
              {intl.formatMessage(appFieldsMessages.startTime)}:
            </span>
            <span>{CellRenderers.time({ cellData: String(item.s).length > 10 ? item.s : item.s * 1000 })}</span>
            <span className="light-label bold" style={{ marginRight: 4, marginLeft: 8 }}>
              {intl.formatMessage(appFieldsMessages.endTime)}:
            </span>
            <span>{CellRenderers.time({ cellData: String(item.e).length > 10 ? item.e : item.e * 1000 })}</span>
          </div>
        </CellMeasurer>
      );
    };

    return (
      <List
        width={width}
        height={height}
        rowCount={(timePairs || []).length}
        overscanRowCount={4}
        deferredMeasurementCache={cellMeasureCache}
        rowHeight={cellMeasureCache.rowHeight}
        rowRenderer={renderListItem}
      />
    );
  },
  RenderReporter({ intl, timezoneOffset = 0, reporterRecordSet = [] }: Object) {
    return (
      <>
        {R.addIndex(R.map)((item, idx) => {
          const { timestamp, reporterName, action } = item;
          const customerTimestamp = moment.utc(timestamp).valueOf() + (timezoneOffset || 0) * 60000;
          return (
            <Tooltip
              key={idx}
              title={
                <div>
                  <div>{moment.utc(customerTimestamp).format(Defaults.DateTimeFormat)}</div>
                  <div>
                    {intl.formatMessage(eventActionMessages.reporter)}: {reporterName}
                  </div>
                  {action && (
                    <div>
                      {intl.formatMessage(eventActionMessages.action)}:{' '}
                      <span style={{ textTransform: 'lowercase' }}>{action}</span>
                    </div>
                  )}
                </div>
              }
              mouseEnterDelay={0.3}
            >
              <span>
                <GlobalRenderers.RenderReporterAvatar userName={reporterName} />
              </span>
            </Tooltip>
          );
        }, reporterRecordSet || [])}
      </>
    );
  },
  RenderReporterAvatar({ userName }: Object) {
    return (
      <Avatar
        style={{
          width: 18,
          height: 18,
          marginRight: 4,
          marginBottom: 2,
          verticalAlign: 'middle',
          flexShrink: 0,
        }}
        size="small"
        className="reporter-avatar"
        icon={<UserOutlined />}
        src={buildUrl(getEndpoint('avatar', 2), {}, { userName })}
      />
    );
  },
  DatePickerInput: (props: Object) => {
    const datePickerRef = useRef();
    const valueRef = useRef();

    useEffect(() => {
      const rangePickerDomNode = datePickerRef.current;
      const [dateInput] = rangePickerDomNode.querySelectorAll('.ant-picker-input input');
      if (dateInput) {
        dateInput.addEventListener('keyup', (event: any) => {
          if (moment.utc(dateInput.value, props.format).isValid()) {
            valueRef.current = moment.utc(dateInput.value, props.format);
          }
        });
      }
    }, [datePickerRef]);

    const onBlur = () => {
      props.onChange(valueRef.current);
    };

    return (
      <div ref={datePickerRef}>
        <DatePicker
          {...props}
          onChange={(momentDate) => {
            if (momentDate && momentDate.isValid()) {
              valueRef.current = momentDate;
            }
            onBlur();
          }}
          onSelect={(momentDate) => {
            if (momentDate && momentDate.isValid()) {
              valueRef.current = momentDate;
            }
            onBlur();
          }}
          onBlur={onBlur}
        />
      </div>
    );
  },
};

export { GlobalRenderers };
