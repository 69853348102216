import React from 'react';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { notification } from 'antd';

import type { State, AlertMessage } from '../../../common/types';

type Props = {
  intl: Object,
  alertMessages: Array<AlertMessage>,
};

class AppAlertCore extends React.Component {
  props: Props;

  componentDidMount() {}

  componentDidUpdate() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.alertMessages !== this.props.alertMessages) {
      const { intl } = nextProps;
      R.forEach((alert) => {
        const { key, type, message, params, options } = alert;
        notification[type]({
          key,
          message: options.title || 'Notification',
          description: intl.formatMessage(message, params),
          duration: options.duration !== undefined ? options.duration : type === 'error' ? 0 : 3,
          ...options,
        });
      }, nextProps.alertMessages || []);
    }
  }

  render() {
    return null;
  }
}

const AppAlert = injectIntl(AppAlertCore);

export default connect(
  (state: State) => ({
    alertMessages: state.app.alerts,
  }),
  {},
)(AppAlert);
