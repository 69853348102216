/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 */

import React from 'react';
// import * as R from 'ramda';
import moment from 'moment';
import { replace, push } from 'react-router-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { Button, Card } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { Container } from '../../../lib/fui/react';
import { parseLocation, buildUrl } from '../../../common/utils';
import { hideAppLoader } from '../../../common/app/actions';

import { appButtonsMessages, appMenusMessages } from '../../../common/app/messages';
import { settingsMenusMessages } from '../../../common/settings/messages';

import SlackImg from '../../../../images/services/Slack.png';
import JiraImg from '../../../../images/services/Jira.png';
import ServiceNowImg from '../../../../images/services/ServiceNow.png';
import SentryImg from '../../../../images/services/Sentry.png';
import PagerDutyImg from '../../../../images/services/PagerDuty.png';
import TeamsImg from '../../../../images/identity-providers/microsoft-teams-icon.svg';
import DataDogImg from '../../../../images/services/datadog-icon.svg';
import DynatraceSvg from '../../../../images/services/dynatrace-icon.svg';
import BigPandaImg from '../../../../images/services/BigPanda.png';

import ServiceSlackModal from './ServiceSlackModal';
import ServiceJiraModal from './ServiceJiraModal';
import ServiceServiceNowModal from './ServiceServiceNowModal';
import ServiceSentryModal from './ServiceSentryModal';
import ServicePagerDutyModal from './ServicePagerDutyModal';
import ServiceMSTeamsModal from './ServiceMSTeamsModal';
import ServiceDataDogModal from './ServiceDataDogModal';
import ServiceDynatraceModal from './ServiceDynatraceModal';
import ServiceBigPandaModal from './ServiceBigPandaModal';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  replace: Function,
  hideAppLoader: Function,
  push: Function,
  isDark: Boolean,
};

class ExternalServiceSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const params = parseLocation(location);
    const { defaultActiveModal } = params;

    this.state = {
      refresh: null,

      showSlackModal: defaultActiveModal === 'slack',
      showJiraModal: defaultActiveModal === 'jira',
      showServiceNowModal: defaultActiveModal === 'servicenow',
      showSentryModal: defaultActiveModal === 'sentry',
      showPagerDutyModal: defaultActiveModal === 'pagerduty',
      showMSTeamsModal: defaultActiveModal === 'teams',
      showDataDogModal: defaultActiveModal === 'datadog',
      showDynatraceModal: defaultActiveModal === 'dynatrace',
      showBigPandaModal: defaultActiveModal === 'bigPanda',
    };
  }

  componentDidMount() {
    const { hideAppLoader } = this.props;
    hideAppLoader();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleRefresh() {
    this.setState({ refresh: moment.utc().valueOf() });
  }

  @autobind
  handleNavigate() {
    const { push, location } = this.props;
    const query = parseLocation(location);
    push(buildUrl(BaseUrls.ExternalZoomAuthSetting), {}, query);
  }

  render() {
    const { intl, location, isDark } = this.props;
    const query = parseLocation(location);

    return (
      <Container fullHeight withGutter className="flex-col">
        <Container className="flex-row flex-center-align" breadcrumb>
          <div className="section">
            <span className="label">{intl.formatMessage(appMenusMessages.settings)}</span>
            <span className="divider">/</span>
            <NavLink to={buildUrl(BaseUrls.ExternalServiceSetting, {}, query)}>
              <span className="label">{intl.formatMessage(settingsMenusMessages.externalService)}</span>
            </NavLink>
          </div>
          <div className="flex-grow" />
          <div>
            <Button size="small" onClick={this.handleRefresh}>
              {intl.formatMessage(appButtonsMessages.refresh)}
            </Button>
          </div>
        </Container>
        <Container
          className="flex-grow flex-min-height overflow-y-auto content-bg corner-10"
          style={{
            margin: '0 16px 8px 16px',
            borderRadius: 4,
            padding: '8px 16px',
          }}
        >
          <Container className="flex-row flex-wrap overflow-y-auto">
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="SlackImg" src={SlackImg} style={{ height: 180, padding: 8 }} />}
              onClick={() => this.setState({ showSlackModal: true })}
            >
              <Card.Meta style={{ wordBreak: 'break-all' }} title="Slack" description="https://www.slack.com" />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="JiraImg" src={JiraImg} style={{ height: 180, padding: 8 }} />}
              onClick={() => this.setState({ showJiraModal: true })}
            >
              <Card.Meta
                style={{ wordBreak: 'break-all' }}
                title="Jira"
                description="https://www.atlassian.com/software/jira"
              />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="ServiceNowImg" src={ServiceNowImg} style={{ height: 180, padding: 8 }} />}
              onClick={() => this.setState({ showServiceNowModal: true })}
            >
              <Card.Meta
                style={{ wordBreak: 'break-all' }}
                title="ServiceNow"
                description="https://www.servicenow.com"
              />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="SentryImg" src={SentryImg} style={{ height: 180, padding: 8 }} />}
              onClick={() => this.setState({ showSentryModal: true })}
            >
              <Card.Meta style={{ wordBreak: 'break-all' }} title="Sentry" description="https://sentry.io" />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="PagerDutyImg" src={PagerDutyImg} style={{ height: 180, padding: 8 }} />}
              onClick={() => this.setState({ showPagerDutyModal: true })}
            >
              <Card.Meta style={{ wordBreak: 'break-all' }} title="PagerDuty" description="https://www.pagerduty.com" />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={<img alt="TeamsImg" src={TeamsImg} style={{ height: 180, padding: 8 }} />}
              onClick={() => this.setState({ showMSTeamsModal: true })}
            >
              <Card.Meta
                style={{ wordBreak: 'break-all' }}
                title="Microsoft Teams"
                description="https://www.microsoft.com/en-us/microsoft-teams/group-chat-software"
              />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={
                <img
                  alt="DataDogImg"
                  src={DataDogImg}
                  style={{ height: 180, padding: 8, filter: isDark ? 'invert(1)' : 'none' }}
                />
              }
              onClick={() => this.setState({ showDataDogModal: true })}
            >
              <Card.Meta style={{ wordBreak: 'break-all' }} title="DataDog" description="https://www.datadoghq.com" />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={
                <img
                  alt="DynatraceImg"
                  src={DynatraceSvg}
                  style={{ height: 180, padding: 8, filter: isDark ? 'invert(1)' : 'none' }}
                />
              }
              onClick={() => this.setState({ showDynatraceModal: true })}
            >
              <Card.Meta style={{ wordBreak: 'break-all' }} title="Dynatrace" description="https://www.dynatrace.com" />
            </Card>
            <Card
              hoverable
              style={{ width: 180, margin: 8 }}
              cover={
                <div className="flex-row flex-center-align" style={{ height: 180, padding: 8 }}>
                  <img
                    className="full-width"
                    alt="BigPandaImg"
                    src={BigPandaImg}
                    style={{ height: 41, filter: isDark ? 'invert(1)' : 'none' }}
                  />
                </div>
              }
              onClick={() => this.setState({ showBigPandaModal: true })}
            >
              <Card.Meta style={{ wordBreak: 'break-all' }} title="BigPanda" description="https://get.bigpanda.io" />
            </Card>
          </Container>
        </Container>

        {this.state.showSlackModal && (
          <ServiceSlackModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showSlackModal: false });
            }}
          />
        )}
        {this.state.showJiraModal && (
          <ServiceJiraModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showJiraModal: false });
            }}
          />
        )}
        {this.state.showServiceNowModal && (
          <ServiceServiceNowModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showServiceNowModal: false });
            }}
          />
        )}
        {this.state.showSentryModal && (
          <ServiceSentryModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showSentryModal: false });
            }}
          />
        )}
        {this.state.showPagerDutyModal && (
          <ServicePagerDutyModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showPagerDutyModal: false });
            }}
          />
        )}
        {this.state.showMSTeamsModal && (
          <ServiceMSTeamsModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showMSTeamsModal: false });
            }}
          />
        )}
        {this.state.showDataDogModal && (
          <ServiceDataDogModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showDataDogModal: false });
            }}
          />
        )}
        {this.state.showDynatraceModal && (
          <ServiceDynatraceModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showDynatraceModal: false });
            }}
          />
        )}
        {this.state.showBigPandaModal && (
          <ServiceBigPandaModal
            refresh={this.state.refresh}
            onClose={() => {
              this.setState({ showBigPandaModal: false });
            }}
          />
        )}
      </Container>
    );
  }
}

const ExternalServiceSetting = injectIntl(ExternalServiceSettingCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { currentTheme } = state.app;
    const isDark = currentTheme === 'dark';
    return { location, isDark };
  },
  { replace, hideAppLoader, push },
)(ExternalServiceSetting);
