/* @flow */
import React, { Element } from 'react';
import cx from 'classnames';

type Props = {
  children: Element<any>,
  className: string,
  raised: boolean,
  isLink: boolean,
  isBox: boolean,
};

const Box = ({ className, isLink = false, raised = false, isBox = true, children, ...rest }: Props) => {
  const classes = cx(
    'fui',
    {
      'is-link': isLink,
      raised,
      box: isBox,
    },
    className,
  );
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

export default Box;
