/* eslint-disable react/prop-types */
import React from 'react';
import { get, isEqual } from 'lodash';
import * as R from 'ramda';
import ReactDOMServer from 'react-dom/server';
import { autobind } from 'core-decorators';

import { DataFrame } from 'dataframe-js';
import moment from 'moment';
import 'moment/locale/zh-cn';
import Dygraph from '../../../src/lib/dygraph';

type Props = {
  data: Array,
  highlight: Object,
  anomalyList: Array,
  handleDrawCallback: Function,
  style: Object,
  onCreateAnnotation: Function,
  width: Number,
  height: Number,
  onDateWindowChange: Function,
  drawCallback: Function,
  dateWindow: Array,
};
class DetectionChart extends React.PureComponent {
  poprs: Props;

  constructor(props) {
    super(props);

    this.options = {
      connectSeparatedPoints: false,
      includeZero: true,
      labelsUTC: true,
      axisLabelFontSize: 12,
      fillAlpha: 0.3,
      gridLineColor: 'rgba(0, 0, 0, 0.18)',
      strokeWidth: 1.5,
      highlightSeriesOpts: { strokeWidth: 2 },
      highlightSeriesBackgroundAlpha: 0.3,
      colors: ['#6abbf7'],
      // labels
      legend: 'follow',
      drawCallback: this.handleDrawCallback,

      legendFormatter: (x) => this.legendFormatter(x),

      plotter: [Dygraph.Plotters.fillPlotter, Dygraph.Plotters.errorPlotter, (x) => this.handlePlotter(x)],
    };
    this.state = {};

    this.chartNode = null;
    this.currentDateWindow = [];
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.dateWindow !== this.props.dateWindow && this.dygraph) {
      if (!R.isEmpty(nextProps.dateWindow) && nextProps.dateWindow) {
        const options = { ...this.options, dateWindow: nextProps.dateWindow };
        this.dygraph.updateOptions(options);
      }
    }
  }

  @autobind
  parseData(props) {
    const { data, labels, dateWindow } = props;

    const dataSet = R.map((item) => {
      return { ...item, timestamp: moment.utc(item.timestamp).toDate() };
    }, data);
    const options = {
      ...this.options,
      labels,
      labelsDiv: this.legendNode,
      ...(!R.isEmpty(dateWindow) && dateWindow ? { dateWindow } : {}),
    };
    const df = new DataFrame(dataSet);
    this.dygraph = new Dygraph(this.chartNode, df.toArray(), options);
    this.options = options;
    this.setState({ refresh: moment.utc().valueOf() });
  }

  legendFormatter(data) {
    const { series, x } = data;
    const { data: dataSet } = this.props;
    if (!x) return '';
    let labels = [];

    R.forEach((item) => {
      if (item.timestamp === x) {
        R.forEach((serie) => {
          labels.push(
            <div
              key={serie.label + serie.y}
              style={{ border: `1px solid ${serie.isHighlighted ? 'var(--border-color-base)' : 'transparent'}` }}
            >
              <span>{serie.label}</span>:<span>{item[serie.label]}</span>
            </div>,
          );
        }, series);
      }
    }, dataSet);
    return ReactDOMServer.renderToStaticMarkup(
      <>
        <div className="time">{moment.utc(x).format('YYYY-MM-DD HH:mm')}</div>
        <div className="labels"> {labels}</div>
      </>,
    );
  }

  handlePlotter(event) {
    const { points, setName } = event;
    const { highlight } = this.props;

    Dygraph.Plotters.linePlotter(event);
    const { timeRange } = get(highlight, setName, []);

    R.forEach((item) => {
      const { startTime: startTimestamp, endTime: endTimestamp, color } = item;
      let hpoints = R.filter((p) => {
        const xvalSec = p.xval ? p.xval : null;

        return xvalSec && xvalSec >= startTimestamp && xvalSec <= endTimestamp;
      }, points);

      if (hpoints.length === 0) {
        const sp = R.findLast((p) => p.xval && p.xval <= startTimestamp, points);
        const ep = R.find((p) => p.xval && p.xval >= endTimestamp, points);
        hpoints = [sp, ep];
      }

      hpoints = R.filter((p) => Boolean(p), hpoints);
      if (hpoints.length > 0) {
        const hightlightEvent = {
          ...event,
          points: hpoints,
          strokeWidth: 3,
          color,
        };
        Dygraph.Plotters.linePlotter(hightlightEvent);
      }
    }, timeRange || []);
  }

  @autobind
  handleDrawCallback(g, initial) {
    const { onDateWindowChange, drawCallback } = this.props;
    if (onDateWindowChange && !initial) {
      const dw = g.xAxisRange();
      if (!isEqual(dw, this.currentDateWindow)) {
        onDateWindowChange(g.xAxisRange());
        this.currentDateWindow = dw;
      }
    }

    if (drawCallback) {
      drawCallback(g, initial);
    }
  }

  render() {
    const { style, width, height, anomalyList, onCreateAnnotation } = this.props;
    const tmpItems = [];
    R.forEach(
      (item) => {
        const { timeStamp, ...rest } = item;
        const x = this.dygraph.toDomXCoord(timeStamp);
        const annoElem = onCreateAnnotation({ x, timeStamp, ...rest });
        if (annoElem) {
          tmpItems.push(annoElem);
        }
      },
      this.dygraph ? anomalyList || [] : [],
    );
    return (
      <div
        style={{
          ...style,
          width,
          height: height - 20,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          position: 'relative',
        }}
      >
        <div>{tmpItems}</div>
        <div
          className="legend-bar"
          style={{ position: 'absolute' }}
          ref={(c) => {
            this.legendNode = c;
          }}
        />
        <div
          style={{
            width,
            height: height - 40,
          }}
          ref={(r) => (this.chartNode = r)}
        />
      </div>
    );
  }
}
export default DetectionChart;
