/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import { isBoolean, isString, isArray } from 'lodash';
import { setLoadStatus, showAppLoader, hideAppLoader, showAppAlert } from '../app/actions';
import type { Message } from '../types';

/**
 * Get actions for loading status, the return object contains show, hide, and error load status actions
 * The default will use the app loader or page loader.
 * If loader is null or false, will not get the loader, which is used to disable show the loading spinning.
 */
const getLoadStatusActions = (loader: any = true) => {
  let showLoading = null;
  let hideLoading = null;
  let errorLoading = () => null;

  if (isString(loader) || isArray(loader)) {
    // If loader is string or array of string, which contains the name of the loader, create
    // a load status for the loader.
    const loaders = R.filter((l) => Boolean((l || '').trim()), isArray(loader) ? loader : [loader]);
    if (loaders.length > 0) {
      showLoading = setLoadStatus(R.reduce((acc, l) => ({ ...acc, [l]: 'loading' }), {}, loaders));
      hideLoading = setLoadStatus(R.reduce((acc, l) => ({ ...acc, [l]: 'loaded' }), {}, loaders));
      errorLoading = (message: Message, params: ?Object) =>
        setLoadStatus(R.reduce((acc, l) => ({ ...acc, [l]: { error: true, message, params } }), {}, loaders));
    }
  } else if (isBoolean(loader) && loader) {
    // If loader is true, get the app loader.
    showLoading = showAppLoader();
    hideLoading = hideAppLoader();
    errorLoading = (message: Message, params: ?Object) => showAppAlert('error', message, params);
  }

  return { showLoading, hideLoading, errorLoading };
};

export default getLoadStatusActions;
