import React from 'react';
import { injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import type { State, ErrorMessage } from '../../../common/types';

import CenterPage from './CenterPage';

type Props = {
  appLastError: ?ErrorMessage,
  intl: Object,
  push: Function,
  currentTheme: String,
};

const AppErrorCore = ({ appLastError, intl, push, currentTheme }: Props) => {
  const { message } = appLastError || {};
  if (message) {
    return (
      <CenterPage intl={intl} push={push} className="error-page" currentTheme={currentTheme}>
        <div>{intl.formatMessage(message)}</div>
      </CenterPage>
    );
  }

  return null;
};

const AppError = injectIntl(AppErrorCore);

export default connect(
  (state: State) => ({
    currentTheme: state.app.currentTheme,
    appLastError: state.app.lastError,
  }),
  { push },
)(AppError);
