import React, { useEffect, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Input, Select, Spin, message } from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { ActionTypes, createSetAction, updateLastActionInfo } from '../../../../common/app/actions';

import ZoneSecreteJWT from './ZoneSecreteJWT';

import { appButtonsMessages, appMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';

function ZoneSecreteJWTRenderCore(props: Object) {
  const { activeSystemTab, systemId, intl, systemsMap, credentials, createSetAction, updateLastActionInfo } =
    props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    levelType: 'zoneLevel',
    loading: false,
    zonesecretModal: false,
  });
  const { levelType, zonesecretModal, loading } = state;
  const isZoneLevel = levelType === 'zoneLevel';

  const systemInfo = systemsMap[systemId] || {};
  const { systemSetting } = systemInfo;
  const { jwtType } = JSON.parse(systemSetting || '{}');

  useEffect(() => {
    setState({ levelType: jwtType === 1 ? 'systemLevel' : 'zoneLevel' });
  }, [systemId, jwtType]);

  const changeZonesecretModal = (zonesecretModal) => {
    setState({ zonesecretModal });
  };

  const changeLevelType = (levelType) => {
    setState({ loading: true });

    const jwtType = levelType === 'zoneLevel' ? 0 : 1;
    const systemSetting = JSON.stringify({ systemLevelJWTSecret: undefined, jwtType });
    const newSystemsMap = {
      ...systemsMap,
      [systemId]: { ...(systemsMap[systemId] || {}), systemSetting },
    };

    const request = [
      fetchPost(
        getEndpoint('systemframework', 2),
        {
          ...credentials,
          operation: 'systemFrameworkSetting',
          systemKey: JSON.stringify({
            userName: systemInfo?.owner,
            systemName: systemId,
            environmentName: systemInfo?.environmentName,
          }),
          systemFrameworkSetting: systemSetting,
        },
        {},
        false,
      ),
    ];

    updateLastActionInfo();
    Promise.all(request)
      .then((res) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        createSetAction(ActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { systemsMap: newSystemsMap });
        setState({ loading: false, levelType });
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ loading: false });
      });
  };

  return (
    <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-height">
      <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
        <div className="flex-row flex-center-align" style={{ marginRight: 16 }}>
          <div style={{ marginRight: 8 }}>{intl.formatMessage(settingsMessages.level)}: </div>
          <Select
            size="small"
            value={levelType}
            style={{ width: 200 }}
            onChange={changeLevelType}
            options={[
              { value: 'zoneLevel', label: intl.formatMessage(settingsMessages.zoneLevel) },
              { value: 'systemLevel', label: intl.formatMessage(settingsMessages.systemLevel) },
            ]}
          />
        </div>
        {isZoneLevel && (
          <div className="flex-row flex-center-align">
            <Button size="small" type="primary" onClick={() => changeZonesecretModal(true)}>
              {intl.formatMessage(appButtonsMessages.add)}
            </Button>
          </div>
        )}
      </div>
      <div className="flex-grow">
        {isZoneLevel && (
          <ZoneSecreteJWT
            activeSystemTab={activeSystemTab}
            systemId={systemId}
            zonesecretModal={zonesecretModal}
            changeZonesecretModal={changeZonesecretModal}
          />
        )}
        {!isZoneLevel && <SystemLevelZone activeSystemTab={activeSystemTab} systemId={systemId} {...props} />}
      </div>
    </Spin>
  );
}

const ZoneSecreteJWTRender = injectIntl(ZoneSecreteJWTRenderCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, systemsMap, projects, currentTheme } = state.app;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isLocalAdmin } = state.auth.userInfo;
    const isDark = currentTheme === 'dark';
    return {
      location,
      loadStatus,
      systemsMap,
      credentials,
      userInfo,
      projects,
      isDark,
      isAdmin,
      isLocalAdmin,
    };
  },
  { updateLastActionInfo, createSetAction },
)(ZoneSecreteJWTRender);

const SystemLevelZone = (props: Object) => {
  const { systemId, intl, credentials, updateLastActionInfo, systemsMap, createSetAction } = props || {};
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    zoneKey: '',
    loading: false,
  });
  const { zoneKey, loading } = state;
  const systemInfo = systemsMap[systemId] || {};
  const { systemSetting } = systemInfo;
  const { systemLevelJWTSecret } = JSON.parse(systemSetting || '{}');

  useEffect(() => {
    setState({ zoneKey: systemLevelJWTSecret });
  }, [systemLevelJWTSecret]);

  const handleUpdate = () => {
    setState({ loading: true });
    const systemSetting = JSON.stringify({ systemLevelJWTSecret: zoneKey, jwtType: 1 });
    const newSystemsMap = {
      ...systemsMap,
      [systemId]: { ...(systemsMap[systemId] || {}), systemSetting },
    };
    const request = [
      fetchPost(
        getEndpoint('systemframework', 2),
        {
          ...credentials,
          operation: 'systemFrameworkSetting',
          systemKey: JSON.stringify({
            userName: systemInfo?.owner,
            systemName: systemId,
            environmentName: systemInfo?.environmentName,
          }),
          systemFrameworkSetting: systemSetting,
        },
        {},
        false,
      ),
    ];

    updateLastActionInfo();
    Promise.all(request)
      .then((res) => {
        message.success(intl.formatMessage(appMessages.apiSuccess));
        createSetAction(ActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { systemsMap: newSystemsMap });
        setState({ loading: false });
      })
      .catch((err) => {
        message.error(err.message || String(err));
        setState({ loading: false });
      });
  };

  return (
    <div style={{ marginTop: 16 }}>
      <div className="flex-row flex-center-align" style={{ marginBottom: 24 }}>
        <div style={{ marginRight: 8 }}>{intl.formatMessage(settingsMessages.zoneKey)}:</div>
        <Input
          value={zoneKey}
          size="small"
          allowClear
          onChange={(e) => setState({ zoneKey: e.target.value })}
          style={{ width: 200 }}
        />
      </div>
      <div className="flex-row flex-center-align">
        <Button size="small" type="primary" loading={loading} onClick={handleUpdate}>
          {intl.formatMessage(appButtonsMessages.update)}
        </Button>
      </div>
    </div>
  );
};
