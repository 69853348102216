/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isArray, isObject, isPlainObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Alert, Spin, Pagination, Button, Menu, Input, Empty, Select, Divider, Tooltip, Popover } from 'antd';
import {
  SettingOutlined,
  DownloadOutlined,
  LeftCircleFilled,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { Container, Dropdown } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';
import { Defaults, parseLocation, getLoadStatus, CellRenderers, buildLocation } from '../../../common/utils';

import { appButtonsMessages, appFieldsMessages, appMenusMessages } from '../../../common/app/messages';
import { logMessages } from '../../../common/log/messages';
import { eventMessages } from '../../../common/metric/messages';

import InsightQueryBoxModal from '../../dashboard/components/InsightQueryBoxModal';
import TakeEventTriageModal from '../../../../components/incidents/TakeEventTriageModal';
import EventEmailAlertsModal from '../../metric/components/EventEmailAlertsModal';

import LogEntriesDetail from './LogEntriesDetail';
import LogAnalysisPatternList from './LogAnalysisPatternList';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { ActionTypes } from '../../../common/log/actions';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,

  intl: Object,
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  isReadUser: Boolean,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  logImportantEntryTotal: Number,
  // eslint-disable-next-line
  instanceInfoList: Array<Object>,

  // eslint-disable-next-line react/no-unused-prop-types
  credentials: Object,
  handleExportImportantClick: Function,
  reloadInstanceInfo: Function,
  instanceDisplayNameMap: Object,
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

class LogEntriesCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { intl, location } = props;
    const params = parseLocation(location);
    const { instanceName, activePatternId, anomalyType: filterAnomaly, isJump } = params;
    this.dataLoader = 'log_cluster_important_content';

    this.filterTypeOptions = [
      { label: 'Instance', value: 'instanceName' },
      { label: 'Component', value: 'appName' },
    ];

    this.localInstanceInfoList = [];
    this.localFilterInstanceInfoList = [];

    this.typeMessageMap = {
      incidentEventCount: intl.formatMessage(logMessages.incident),
      newPatternCount: intl.formatMessage(logMessages.newpattern),
      hotEventCount: intl.formatMessage(logMessages.hot),
      coldEventCount: intl.formatMessage(logMessages.cold),
      rareEventCount: intl.formatMessage(logMessages.rare),
      whitelistEventCount: intl.formatMessage(logMessages.keywordAlerts),
    };

    this.typeColorMap = {
      incidentEventCount: 'var(--primary-color)',
      newPatternCount: 'var(--primary-color)',
      hotEventCount: 'orange',
      coldEventCount: '#2185D0',
      rareEventCount: '#F2711C',
      whitelistEventCount: 'orange',
      entryCount: '#678dff',
    };

    this.anomalyKeys = [
      'incidentEventCount',
      'newPatternCount',
      'rareEventCount',
      'hotEventCount',
      'coldEventCount',
      'whitelistEventCount',
    ];
    this.logOption = [];

    this.logMessageMap = {
      hot: 'Hot',
      newPattern: 'New',
      cold: 'Cold',
      whiteList: intl.formatMessage(logMessages.keywordAlerts),
      rare: 'Rare',
      incident: 'Incident',
      alert: 'Alert',
      change: 'Change events',
    };

    this.activeAnomalyTypeMap = {
      rare: 'rare',
      incident: 'incident',
    };

    this.hostAndPodIdList = [];

    this.state = {
      activeInstance: instanceName,
      activePatternId: filterAnomaly === 'rare' ? 'all' : activePatternId || 'all',
      activeIncident: null,

      page: 1,
      pageSize: 100,
      pageTotal: 0,
      abnormalInstancesTotal: 0,
      normalInstancesTotal: 0,
      instanceContainerOptions: [],

      filterType: 'instanceName',
      filterValue: isJump ? instanceName : null,
      filterPage: 1,
      filterPageSize: 20,
      filterPageTotal: 0,

      patternList: [],

      clustersSampleMsgMap: {},
      clusterActivePatternChange: null,

      isLoadingFrequency: false,

      resetActivePattern: !activePatternId,
      anomalyType: this.activeAnomalyTypeMap[filterAnomaly] || 'all',
      showInstance: true,
      globalLoading: false,
      isLoadingInstanceInfo: false,
    };
  }

  componentDidMount() {
    this.parseInstanceList(this.props);
    this.reloadPattern(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { location: locationNew } = nextProps;
    if (this.props.instanceInfoList !== nextProps.instanceInfoList) {
      this.parseInstanceList(nextProps);
    }

    if (location !== locationNew) {
      const { instanceName, instanceTime } = parseLocation(location);
      const { instanceName: instanceNameNew, instanceTime: instanceTimeNew } = parseLocation(locationNew);
      if (instanceName !== instanceNameNew || instanceTime !== instanceTimeNew) {
        this.reloadPattern(nextProps);
      }
    }
  }

  @autobind
  reloadAnomalyTypeSize(props, { activePatternId }) {
    const { credentials, location } = props;
    const { projectName, instanceName, startTime, endTime } = parseLocation(location);
    return new Promise((resolve) => {
      fetchGet(getEndpoint('fetchimportantlog'), {
        ...credentials,
        projectName,
        instanceName,
        startTime: moment.utc(startTime).startOf('day').valueOf(),
        endTime: moment.utc(endTime).endOf('day').valueOf(),
        patternId: activePatternId === 'all' ? undefined : activePatternId,
        isFetchAll: activePatternId === 'all',
        pageNo: 1,
        pageSize: 1,
      })
        .then((res) => {
          resolve(get(res, 'anomalyStats', {}));
        })
        .catch((e) => {
          resolve({});
        });
    });
  }

  @autobind
  reloadInstanceInfo(props) {
    const { location, createLoadAction, projects } = props;
    const { page, pageSize } = this.state;
    const params = parseLocation(location);
    const { projectName, startTime, endTime } = params;
    const project = R.find((project) => project.projectName === projectName, projects);
    const pageNo = page - 1 < 0 ? 0 : page - 1;
    if (project && project.hasAllInfo) {
      this.setState({ isLoadingInstanceInfo: true });
      createLoadAction(
        ActionTypes.LOAD_LOG_INSTANCEINFO_LIST,
        { projectName, day: endTime, startTime, endTime, pageNo, pageSize },
        false,
        false,
        this.callbackHandleInstanceInfos,
      );
    }
  }

  @autobind
  callbackHandleInstanceInfos() {
    this.setState({ isLoadingInstanceInfo: false });
  }

  @autobind
  async parseInstanceList(props) {
    const query = parseLocation(props.location);
    const { instanceName, isJump } = query;
    const { filterValue } = this.state;
    const instanceInfoList = props.instanceInfoList || [];

    const { sortInstanceInfoList, normalInstancesTotal, abnormalInstancesTotal } =
      this.totalInstanceSort(instanceInfoList);

    this.localInstanceInfoList = sortInstanceInfoList;

    let jumpHideInstance = {};
    let instanceList = sortInstanceInfoList;
    if (isJump && filterValue) {
      instanceList = this.filterData(filterValue, instanceList);
      jumpHideInstance = { showInstance: false };
    }

    const instanceContainerOptions = this.classifyComponent(instanceList);

    this.setState({
      instanceContainerOptions,
      abnormalInstancesTotal,
      normalInstancesTotal,
      pageTotal: props?.instanceInfoTotal || 0,
      activeInstance: instanceName,
      ...jumpHideInstance,
    });
  }

  @autobind
  classifyComponent(instanceInfoList) {
    const componentInstanceMap = {};
    let instanceContainerOptions = [];
    R.forEach((item) => {
      if (!R.has(item.appName, componentInstanceMap)) {
        componentInstanceMap[item.appName] = [];
      }
      componentInstanceMap[item.appName].push(item);
    }, instanceInfoList);
    const componentList = R.uniq(R.keys(componentInstanceMap));

    // build instanceContainerOptions
    R.forEachObjIndexed((componentId) => {
      let instanceContainers = componentInstanceMap[componentId] || [];
      instanceContainers = R.sortWith(
        [
          R.descend((item) => {
            return item.total + item.anomalyMap.entryCount;
          }),
        ],
        instanceContainers,
      );
      instanceContainerOptions.push({
        type: 'component',
        id: componentId,
        instanceContainers,
        hasAnomaly: R.reduce(
          R.or,
          false,
          R.map((item) => item.hasAnomaly, instanceContainers),
        ),
        total: R.sum(R.map((item) => item.total + item.anomalyMap.entryCount, instanceContainers)),
        incidentEventCount: R.sum(R.map((item) => item.anomalyMap.incidentEventCount || 0, instanceContainers)),
      });
    }, componentList);
    instanceContainerOptions = R.sortWith(
      [R.descend(R.prop('incidentEventCount')), R.descend(R.prop('total'))],
      instanceContainerOptions,
    );

    const { projects, location } = this.props;
    const { projectName } = parseLocation(location);
    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects,
    );
    const { isContainer } = project || {};
    if (isContainer) {
      instanceContainerOptions = R.map((item) => {
        const { instanceContainers } = item;
        const groupByInstance = R.groupBy((item) => item.instanceName.split('_')[1], instanceContainers);
        return { ...item, instanceContainers: groupByInstance };
      }, instanceContainerOptions);
    }
    return instanceContainerOptions;
  }

  @autobind
  totalInstanceSort(instanceInfoList) {
    let normalInstancesTotal = 0;
    let abnormalInstancesTotal = 0;
    R.forEach((item) => {
      const {
        entryCount,
        coldEventCount,
        hotEventCount,
        incidentEventCount,
        newPatternCount,
        rareEventCount,
        whitelistEventCount,
      } = item?.anomalyMap || {};
      item.total = 0;

      if (
        entryCount &&
        !coldEventCount &&
        !hotEventCount &&
        !incidentEventCount &&
        !newPatternCount &&
        !rareEventCount &&
        !whitelistEventCount
      ) {
        normalInstancesTotal += 1;
      } else if (
        coldEventCount ||
        hotEventCount ||
        incidentEventCount ||
        newPatternCount ||
        rareEventCount ||
        whitelistEventCount
      ) {
        abnormalInstancesTotal += 1;
      }

      item.total =
        item.total +
        (coldEventCount || 0) +
        (hotEventCount || 0) +
        (incidentEventCount || 0) +
        (newPatternCount || 0) +
        (rareEventCount || 0) +
        (whitelistEventCount || 0);
    }, instanceInfoList);
    const sortInstanceInfoList = R.sortWith([R.descend(R.prop('total'))], instanceInfoList);
    return { sortInstanceInfoList, normalInstancesTotal, abnormalInstancesTotal };
  }

  @autobind
  handlePagination(page, pageSize) {
    this.setState(
      {
        page,
        pageSize,
      },
      () => {
        this.reloadInstanceInfo(this.props);
      },
    );
  }

  @autobind
  handleFilterPagination(filterPage, filterPageSize) {
    const instanceInfoList = R.slice(
      (filterPage - 1) * filterPageSize,
      filterPage * filterPageSize,
      this.localFilterInstanceInfoList,
    );
    const instanceContainerOptions = this.classifyComponent(instanceInfoList);
    this.setState({
      filterPage,
      filterPageSize,
      instanceContainerOptions,
    });
  }

  @autobind
  handleFilterChange(value) {
    const { replace, location } = this.props;
    const query = parseLocation(location);
    const { page, pageSize, filterPage, filterPageSize } = this.state;
    let filterPageTotal = 0;
    let instanceList = this.localInstanceInfoList;
    if (value) {
      instanceList = this.filterData(value, instanceList);
      this.localFilterInstanceInfoList = R.clone(instanceList);
      filterPageTotal = instanceList.length;
      instanceList = R.slice((filterPage - 1) * filterPageSize, filterPage * filterPageSize, instanceList);
      instanceList = this.classifyComponent(instanceList);
    } else {
      instanceList = this.classifyComponent(this.localInstanceInfoList);
    }
    this.setState(
      {
        filterValue: value,
        filterPage: 1,
        filterPageSize: 20,
        filterPageTotal,
        instanceContainerOptions: instanceList,
      },
      () => {
        replace(buildLocation(location.pathname, {}, { ...query, isJump: undefined }));
      },
    );
  }

  @autobind
  handleFilterTypeChange(filterType) {
    this.setState({
      filterType,
      filterValue: null,
      instanceContainerOptions: this.classifyComponent(this.localInstanceInfoList),
    });
  }

  @autobind
  filterData(value, instanceList) {
    const { instanceDisplayNameMap, location, projects } = this.props;
    const { filterType } = this.state;
    const { projectName } = parseLocation(location);
    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects || [],
    );
    if (filterType === 'instanceName') {
      instanceList = R.filter((item) => {
        const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, item.instanceName, {
          pn: project?.projectShortName,
          owner: project?.owner,
        });
        return item.instanceName.indexOf(value) >= 0 || (instanceStr || '').indexOf(value) >= 0;
      }, instanceList);
    }
    if (filterType === 'appName') {
      instanceList = R.filter((item) => item.appName.toLowerCase().indexOf(value.toLowerCase()) >= 0, instanceList);
    }
    return instanceList;
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  numberFormat(number = 0) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  @autobind
  renderInstanceGroup(instanceContainerOptions) {
    const { intl, projects, location, instanceDisplayNameMap } = this.props;
    const { projectName } = parseLocation(location);
    const { activeInstance } = this.state;
    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects,
    );
    const { isContainer } = project || {};
    const anomalyKeys = ['rareEventCount', 'hotEventCount', 'coldEventCount', 'whitelistEventCount'];
    return R.map((item) => {
      const { instanceContainers, id } = item;
      return (
        <div
          key={uuidv4() + id}
          style={{
            border: '1px solid var(--border-color-base)',
            margin: '0 8px 8px 8px',
          }}
          className="corner-8-8-0-0"
        >
          <div
            className="flex-col full-width"
            style={{
              height: 60,
            }}
          >
            <Tooltip title={`${id} component`} zIndex={9999}>
              <div
                className="flex-row flex-grow flex-center-align"
                style={{ padding: '0 10px', fontSize: 14, fontWeight: 'bold' }}
              >
                <div className="hidden-line-with-ellipsis" style={{ marginRight: 6 }}>
                  {id}
                </div>
                component
              </div>
            </Tooltip>
            <div
              className="flex-row flex-center-align full-width light-label"
              style={{
                height: 20,
                background: 'var(--virtualized-table-child-header-bg)',
                padding: 10,
              }}
            >
              <div style={{ width: 70 }}>{intl.formatMessage(appMenusMessages.patterns)}</div>
              <div style={{ width: 150, flex: 1 }}>{intl.formatMessage(appMenusMessages.events)}</div>
              <div style={{ width: 70 }}>{intl.formatMessage(logMessages.totalLogs)}</div>
            </div>
          </div>
          {isContainer &&
            isPlainObject(instanceContainers) &&
            R.addIndex(R.map)((key, cid) => {
              const containers = instanceContainers[key] || [];
              const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, key, {
                pn: project?.projectShortName,
                owner: project?.owner,
              });
              return (
                <div key={key + uuidv4()}>
                  <div className="hidden-line-with-ellipsis" style={{ padding: '6px 10px', fontSize: 14 }}>
                    {instanceStr || key}
                  </div>
                  {R.addIndex(R.map)((instance, idx) => {
                    const isLast = idx === instanceContainers.length - 1;
                    const { anomalyMap } = instance;
                    const { incidentEventCount, entryCount = 0, newPatternCount } = anomalyMap;
                    const totalLogsCount = this.numberFormat(entryCount);
                    const [containerName, instanceName] = instance.instanceName.split('_');

                    const { instanceDisplayName } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
                      pn: project?.projectShortName,
                      owner: project?.owner,
                    });
                    const width = instanceDisplayName ? 140 : 100;
                    return (
                      <div key={uuidv4() + instanceName + idx + cid}>
                        <Popover
                          content={
                            <div
                              className="flex-col"
                              style={{ rowGap: 10, maxHeight: 300, paddingRight: 16, overflow: 'hidden auto' }}
                            >
                              <div className="flex-row">
                                <div style={{ width, marginRight: 10 }}>
                                  {intl.formatMessage(DashboardMessages.container)} name:
                                </div>
                                {containerName && <div>{containerName}</div>}
                              </div>
                              <div className="flex-row flex-center-align">
                                <div style={{ width, marginRight: 10 }}>
                                  {intl.formatMessage(appFieldsMessages.instanceName)}:
                                </div>
                                <div>{instanceName}</div>
                              </div>
                              {instanceDisplayName && (
                                <div className="flex-row flex-center-align">
                                  <div style={{ width, marginRight: 10 }}>
                                    {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                                  </div>
                                  <div>{instanceDisplayName}</div>
                                </div>
                              )}
                            </div>
                          }
                          zIndex={999}
                        >
                          <div
                            style={{
                              position: 'relative',
                              padding: '10px 10px 10px 20px',
                              borderBottom: `1px solid ${isLast ? 'transparent' : 'var(--border-color-split)'}`,
                              cursor: 'pointer',
                            }}
                            className={`hoverable ${activeInstance === instance.instanceName ? 'list-active' : ''}`}
                            onClick={(e) => this.handleInstanceClick(instance)}
                          >
                            <div className="hidden-line-with-ellipsis" style={{ fontWeight: 300, fontSize: 14 }}>
                              {containerName}
                            </div>
                            <div className="flex-row full-width" style={{ marginTop: 4 }}>
                              <div style={{ width: 70 }} className="flex-row flex-center-align">
                                {Boolean(incidentEventCount) && (
                                  <div style={{ marginRight: 4 }}>
                                    {this.renerTypeIcon('incidentEventCount')}
                                    <span style={{ color: this.typeColorMap.incidentEventCount }}>
                                      {this.numberFormat(incidentEventCount)}
                                    </span>
                                  </div>
                                )}
                                {Boolean(newPatternCount) && (
                                  <div>
                                    {this.renerTypeIcon('newPatternCount')}
                                    <span style={{ color: this.typeColorMap.newPatternCount }}>
                                      {this.numberFormat(newPatternCount)}
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div className="flex-row flex-grow flex-center-align" style={{ width: 150 }}>
                                {R.addIndex(R.map)((category, cIdx) => {
                                  return (
                                    <>
                                      {Boolean(anomalyMap[category]) && (
                                        <div
                                          key={instanceName + cIdx + idx + uuidv4()}
                                          style={{
                                            marginRight: 3,
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {this.renerTypeIcon(category)}
                                          <span style={{ color: this.typeColorMap[category] }}>
                                            {this.numberFormat(anomalyMap[category])}
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  );
                                }, anomalyKeys)}
                              </div>
                              <div style={{ width: 70 }}>
                                <span style={{ color: this.typeColorMap.entryCount }}>{totalLogsCount}</span>
                              </div>
                            </div>
                          </div>
                        </Popover>
                      </div>
                    );
                  }, containers)}
                </div>
              );
            }, R.keys(instanceContainers))}
          {!isContainer &&
            isArray(instanceContainers) &&
            R.addIndex(R.map)((instance, idx) => {
              const isLast = idx === instanceContainers.length - 1;
              const { anomalyMap } = instance;
              const { instanceName } = instance;
              const { incidentEventCount, entryCount = 0, newPatternCount } = anomalyMap;
              const totalLogsCount = this.numberFormat(entryCount);
              const { instanceDisplayName, instanceStr } = getInstanceDisplayName(
                instanceDisplayNameMap,
                instanceName,
                {
                  pn: project?.projectShortName,
                  owner: project?.owner,
                },
              );
              const width = instanceDisplayName ? 140 : 100;

              return (
                <div
                  key={uuidv4() + instanceName + idx}
                  style={{
                    position: 'relative',
                    padding: 10,
                    borderBottom: `1px solid ${isLast ? 'transparent' : 'var(--border-color-split)'}`,
                    cursor: 'pointer',
                  }}
                  className={`hoverable ${activeInstance === instance.instanceName ? 'list-active' : ''}`}
                  onClick={(e) => this.handleInstanceClick(instance)}
                >
                  <Popover
                    content={
                      <div
                        className="flex-col"
                        style={{ rowGap: 10, maxHeight: 300, paddingRight: 16, overflow: 'hidden auto' }}
                      >
                        <div className="flex-row flex-center-align">
                          <div style={{ width, marginRight: 10 }}>
                            {intl.formatMessage(appFieldsMessages.instanceName)}:
                          </div>
                          <div>{instanceName}</div>
                        </div>
                        {instanceDisplayName && (
                          <div className="flex-row flex-center-align">
                            <div style={{ width, marginRight: 10 }}>
                              {intl.formatMessage(appFieldsMessages.instanceDisplayName)}:
                            </div>
                            <div>{instanceStr}</div>
                          </div>
                        )}
                      </div>
                    }
                    zIndex={999}
                  >
                    <div className="flex-col flex-align-center">
                      <div className="hidden-line-with-ellipsis">{instanceStr}</div>
                    </div>
                  </Popover>
                  <div className="flex-row full-width" style={{ marginTop: 4 }}>
                    <div style={{ width: 70 }} className="flex-row flex-center-align">
                      {Boolean(incidentEventCount) && (
                        <div style={{ marginRight: 4 }}>
                          {this.renerTypeIcon('incidentEventCount')}
                          <span style={{ color: this.typeColorMap.incidentEventCount }}>
                            {this.numberFormat(incidentEventCount)}
                          </span>
                        </div>
                      )}
                      {Boolean(newPatternCount) && (
                        <div>
                          {this.renerTypeIcon('newPatternCount')}
                          <span style={{ color: this.typeColorMap.newPatternCount }}>
                            {this.numberFormat(newPatternCount)}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="flex-row flex-grow flex-center-align" style={{ width: 150 }}>
                      {R.addIndex(R.map)((category, cIdx) => {
                        return (
                          <>
                            {Boolean(anomalyMap[category]) && (
                              <div
                                key={instanceName + cIdx + idx + uuidv4()}
                                style={{
                                  marginRight: 3,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {this.renerTypeIcon(category)}
                                <span style={{ color: this.typeColorMap[category] }}>
                                  {this.numberFormat(anomalyMap[category])}
                                </span>
                              </div>
                            )}
                          </>
                        );
                      }, anomalyKeys)}
                    </div>
                    <div style={{ width: 70 }}>
                      <span style={{ color: this.typeColorMap.entryCount }}>{totalLogsCount}</span>
                    </div>
                  </div>
                </div>
              );
            }, instanceContainers)}
        </div>
      );
    }, instanceContainerOptions);
  }

  @autobind
  handleInstanceClick(rowData) {
    const { instanceName } = rowData;
    const { replace, location } = this.props;
    const query = parseLocation(location);
    const changeInstanceName = instanceName !== query.instanceName;
    this.setState(
      {
        activeInstance: instanceName,
        resetActivePattern: true,
        showInstance: false,
      },
      () => {
        replace(
          buildLocation(
            location.pathname,
            {},
            { ...query, instanceName, ...(changeInstanceName ? { activePatternId: 'all' } : {}) },
          ),
        );
      },
    );
  }

  // Select Pattern component
  @autobind
  async reloadPattern(props) {
    const { intl, location, credentials, projects } = props;
    const params = parseLocation(location);
    const { projectName, startTime, endTime, instanceName } = params;
    let { activePatternId, resetActivePattern } = this.state;

    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects,
    );
    if (projectName && project?.hasAllInfo && startTime && endTime && instanceName) {
      this.setState({ isLoadingPattern: true });

      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      const startTimestampList = R.map(
        (i) => startTimestamp + i * 86400000,
        R.range(0, (endTimestamp + 1 - startTimestamp) / 86400000),
      );

      await this.getInstanceidentityData({ props, projectName, instanceName, project, startTime, endTime });

      const requests = [];
      R.forEach((dayTimeMillis) => {
        requests.push(
          fetchGet(getEndpoint('logstreaminglistpatterns'), {
            ...credentials,
            projectName,
            instanceName,
            dayTimeMillis,
          }).then((data) => {
            return { ...(data || {}), dayTimeMillis };
          }),
        );
      }, startTimestampList);
      props.updateLastActionInfo();
      Promise.all(requests)
        .then(async (results) => {
          // merge patterns from multiple days
          const clustersSampleMsgMap = {};
          const eventPatternMap = {};
          R.forEach((data) => {
            const { patternArray, sampleMsgMap, dayTimeMillis } = data || {};
            R.forEach((event) => {
              // build new event
              const { nid, newPatternFlag, incidentFlag, whitelistFlag, coldFlag, hotFlag } = event;
              const hasAnomalyFlag = newPatternFlag || incidentFlag || whitelistFlag || coldFlag || hotFlag;
              const newEvent = { ...event, patternId: nid, hasAnomalyFlag };

              if (!R.has(nid, eventPatternMap)) {
                eventPatternMap[nid] = newEvent;
              } else {
                eventPatternMap[nid] = {
                  ...eventPatternMap[nid],
                  count: eventPatternMap[nid].count + newEvent.count,
                  newPatternFlag: eventPatternMap[nid].newPatternFlag || newEvent.newPatternFlag,
                  incidentFlag: eventPatternMap[nid].incidentFlag || newEvent.incidentFlag,
                  whitelistFlag: eventPatternMap[nid].whitelistFlag || newEvent.whitelistFlag,
                  hotFlag: eventPatternMap[nid].hotFlag || newEvent.hotFlag,
                  coldFlag: eventPatternMap[nid].coldFlag || newEvent.coldFlag,
                  hasAnomalyFlag: eventPatternMap[nid].hasAnomalyFlag || newEvent.hasAnomalyFlag,
                };
              }
            }, patternArray || []);

            // get all pattern sample msg
            R.forEachObjIndexed((s, patternId) => {
              const clusterKey = `${patternId}[${0}]`;
              if (!R.has(clusterKey, clustersSampleMsgMap)) {
                clustersSampleMsgMap[clusterKey] = {};
              }
              clustersSampleMsgMap[clusterKey][dayTimeMillis] = s;
            }, sampleMsgMap || {});
          }, results);
          let patternList = R.values(eventPatternMap);
          patternList = this.sortPattern(patternList);
          // rest active pattern id

          const allPatternIds = [...R.map((event) => String(event.patternId), patternList), 'all'];
          activePatternId = resetActivePattern
            ? 'all'
            : allPatternIds.includes(activePatternId)
            ? activePatternId
            : 'all';
          // add all patterns info
          const allPatternsEvent = {
            isAllPattern: true,
            patternId: 'all',
            nid: 'all',
            count: R.reduce(
              R.add,
              0,
              R.map((item) => item.count, patternList),
            ),
          };

          patternList = R.map(
            (item) => {
              return { ...item, patternStr: this.renderPattern(item) };
            },
            [allPatternsEvent, ...patternList],
          );

          const anomalyStats = await this.reloadAnomalyTypeSize(props, { activePatternId });
          let allAnomalyCount = 0;
          let logOption = R.map((key) => {
            allAnomalyCount += anomalyStats[key];
            return { label: `${this.logMessageMap[key]}(${anomalyStats[key]})`, value: key };
          }, R.keys(anomalyStats));
          logOption = [
            { label: `All anomalous logs(${allAnomalyCount})`, value: 'all', count: allAnomalyCount },
            ...logOption,
            { label: 'All log entries', value: null },
          ];
          this.logOption = logOption;

          this.setState({
            isLoadingPattern: false,
            activePatternId: String(activePatternId),
            patternList,
            clustersSampleMsgMap,
            clusterActivePatternChange: moment.utc().valueOf(),
            resetActivePattern: true,
            anomalyType: allAnomalyCount > 0 ? 'all' : null,
          });
        })
        .catch((err) => {
          this.setState({
            isLoadingPattern: false,
            patternList: [],
            activePatternId: '',
            clustersSampleMsgMap: {},
            resetActivePattern: true,
          });
        });
    }
  }

  @autobind
  getInstanceidentityData({ props, projectName, instanceName, project, startTime, endTime }) {
    const { credentials } = props;
    const { owner } = project;
    return fetchGet(getEndpoint('instanceidentity'), {
      ...credentials,
      projectName,
      customerName: owner,
      startTime: moment.utc(startTime).startOf('day').valueOf(),
      endTime: moment.utc(endTime).endOf('day').valueOf(),
      instanceList: JSON.stringify([instanceName]),
    })
      .then((data) => {
        const { success, message: msg, k8CoverageMap } = data || {};
        if (success || success === undefined) {
          const k8Coverage = JSON.parse(k8CoverageMap || '{}');
          const { coverageMap } = k8Coverage;
          this.hostAndPodIdList = coverageMap[instanceName] || [];
        } else {
          console.error(msg);
        }
      })
      .catch((err) => {
        console.error(err.message || String(err));
      });
  }

  @autobind
  sortPattern(patternList, sortBy, sortDirection) {
    let sortList = patternList || [];

    // sort by
    let sortFunctions = [
      R.descend(R.prop('isAllPattern')),
      R.descend(R.prop('hasAnomalyFlag')),
      R.descend(R.prop('count')),
    ];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(patternList);
    return sortList;
  }

  @autobind
  renderStatus(rowData) {
    const { intl } = this.props;
    const { hasAnomalyFlag, newPatternFlag, incidentFlag, whitelistFlag, coldFlag, hotFlag } = rowData;

    const typeList = [];
    if (newPatternFlag) typeList.push('newpattern');
    if (incidentFlag) typeList.push('incident');
    if (whitelistFlag) typeList.push('whitelist');
    if (coldFlag) typeList.push('cold');
    if (hotFlag) typeList.push('hot');
    return (
      <>
        {hasAnomalyFlag &&
          R.map((type) => {
            return CellRenderers.logShortTypeRenderer({ intl, type });
          }, typeList)}
      </>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { isAllPattern, patternName, patternId } = rowData;
    if (isAllPattern) {
      return 'All patterns';
    }
    const { patternNameStr } = Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true });

    return patternNameStr;
  }

  @autobind
  async handlePatternChange(activePatternId) {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    const anomalyStats = await this.reloadAnomalyTypeSize(this.props, { activePatternId });
    let allAnomalyCount = 0;
    let logOption = R.map((key) => {
      allAnomalyCount += anomalyStats[key];
      return { label: `${this.logMessageMap[key]}(${anomalyStats[key]})`, value: key };
    }, R.keys(anomalyStats));
    logOption = [
      { label: `All anomalous logs(${allAnomalyCount})`, value: 'all', count: allAnomalyCount },
      ...logOption,
      { label: 'All log entries', value: null },
    ];
    this.logOption = logOption;
    this.setState(
      {
        activePatternId,
        clusterActivePatternChange: moment.utc().valueOf(),
        anomalyType: allAnomalyCount > 0 ? 'all' : null,
      },
      () => {
        replace(buildLocation(location.pathname, {}, { ...query, activePatternId }));
      },
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;

    const handleMenuClick = ({ key, domEvent }) => {
      domEvent.stopPropagation();

      const { location } = this.props;
      const params = parseLocation(location);
      const { startTime, endTime, instanceName } = params;
      const { patternId } = rowData;

      switch (key) {
        case 'jumpKeywordSearch':
          this.handleLogQueryClick(rowData, {
            template: 'b81364228d574502b7f6c3e454b9a21b',
            instanceName,
            startTimeObj: moment.utc(startTime, Defaults.DateFormat).subtract(7, 'days').startOf('day'),
            endTimeObj: moment.utc(endTime, Defaults.DateFormat).endOf('day'),
          });
          break;
        case 'jumpPatternTrend':
          this.handleLogQueryClick(rowData, {
            template: '953de6a33d8a4b96ac9c100bf69ba3fc',
            instanceName,
            startTimeObj: moment.utc(startTime, Defaults.DateFormat).subtract(7, 'days').startOf('day'),
            endTimeObj: moment.utc(endTime, Defaults.DateFormat).endOf('day'),
            pattern: String(patternId),
          });
          break;
        case 'setPatternName':
          this.handleActionClick(rowData, 'setPatternName');
          break;
        case 'emailAlerts':
          this.handleEmailAlertsClick(rowData);
          break;
        default:
          break;
      }
    };
    return (
      <Dropdown icon={<SettingOutlined />} itemClick={handleMenuClick} onClick={(event) => event.stopPropagation()}>
        <>
          <Menu.Item key="jumpKeywordSearch">{intl.formatMessage(eventMessages.jumpKeywordSearch)}</Menu.Item>
          <Menu.Item key="jumpPatternTrend">{intl.formatMessage(eventMessages.jumpPatternTrend)}</Menu.Item>
          <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item>
          <Menu.Item key="emailAlerts">{intl.formatMessage(eventMessages.emailAlerts)}</Menu.Item>
          {/* <Menu.Item key="takeAction">{intl.formatMessage(eventMessages.takeAction)}</Menu.Item> */}
        </>
      </Dropdown>
    );
  }

  @autobind
  renderFilterContent(props) {
    const { intl, instanceDisplayNameMap, location, projects } = props;
    const { anomalyType, activeInstance } = this.state;
    const { projectName } = parseLocation(location);
    const project = R.find(
      (project) => project.projectName === projectName || project.projectShortName === projectName,
      projects || [],
    );

    const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, activeInstance, {
      pn: project?.projectShortName,
      owner: project?.owner,
    });

    return (
      <div>
        <div style={{ padding: '8px 8px 0 8px' }} className="flex-row flex-wrap flex-space-between flex-center-align">
          <div className="flex-row flex-center-align">
            {activeInstance && (
              <>
                <span className="bold">{intl.formatMessage(appFieldsMessages.instanceName)}:</span>
                <div style={{ marginRight: 10, marginLeft: 4 }}>{instanceStr}</div>
              </>
            )}
            <span className="bold">{intl.formatMessage(appFieldsMessages.type)}:</span>
            <Select
              size="small"
              style={{ width: 120, marginLeft: 4 }}
              allowClear
              onChange={(anomalyType) => {
                const allAnomalyCount = R.find((item) => item.value === 'all', this.logOption || [])?.count;
                this.setState({
                  anomalyType: anomalyType === undefined ? (allAnomalyCount > 0 ? 'all' : null) : anomalyType,
                });
              }}
              value={anomalyType}
              options={this.logOption}
              dropdownMatchSelectWidth={false}
            />
          </div>

          <Button size="small" icon={<DownloadOutlined />} onClick={this.props.handleExportImportantClick}>
            {intl.formatMessage(appButtonsMessages.export)}
          </Button>
        </div>
        <Divider style={{ margin: '8px 0 8px 0' }} />
      </div>
    );
  }

  @autobind
  renerTypeIcon(key) {
    return (
      <>
        {CellRenderers.logShortTypeRenderer({
          intl: this.props.intl,
          type: this.typeMessageMap[key],
          usePopover: false,
        })}
      </>
    );
  }

  @autobind
  handleLogQueryClick(incident, queryParams) {
    this.setState({ activeIncident: incident, queryParams, showInsightQueryBox: true });
  }

  @autobind
  handleActionClick(incident, actionName) {
    const { location } = this.props;
    const params = parseLocation(location);
    const { instanceName } = params;
    this.setState({ activeIncident: { ...incident, instanceName }, showTakeLogActionModal: true, actionName });
  }

  @autobind
  handlePatternNameChanged(newPatternName, patternId) {
    this.setState({ showTakeLogActionModal: false, resetActivePattern: false }, () => {
      this.reloadPattern(this.props);
    });
  }

  @autobind
  handleEmailAlertsClick(incident) {
    this.setState({ activeIncident: incident, showEmailAlertsModal: true });
  }

  @autobind
  renderPatternList(props, state) {
    const { location } = props;
    const { activePatternId, patternList, clusterActivePatternChange, filterValue } = state;
    const { startTime, endTime, instanceName } = parseLocation(location);
    return (
      <div
        className="flex-col flex-grow full-height corner-8"
        style={{
          border: '1px solid var(--border-color-base)',
          maxWidth: 380,
        }}
      >
        <div
          className="flex-row flex-center-align hoverable"
          style={{ justifyContent: 'start', cursor: 'pointer', padding: 10 }}
          onClick={() => {
            let newFilterValue = filterValue;
            if (filterValue) {
              const instanceList = this.filterData(filterValue, this.localInstanceInfoList);
              if ((instanceList || []).length === 0) {
                newFilterValue = null;
              }
            }
            this.setState({ showInstance: true, filterValue: newFilterValue }, () => {
              if (!newFilterValue) {
                this.handleFilterChange(null);
              }
            });
          }}
        >
          <LeftCircleFilled style={{ fontSize: 24, marginRight: 10 }} />
          <span className="font-16 bold">Back to instance list</span>
        </div>
        <LogAnalysisPatternList
          handleActionClick={this.handleActionClick}
          handleEmailAlertsClick={this.handleEmailAlertsClick}
          handleLogQueryClick={this.handleLogQueryClick}
          activePatternId={activePatternId}
          startTime={startTime}
          endTime={endTime}
          instanceName={instanceName}
          handlePatternChange={this.handlePatternChange}
          eventList={patternList}
          clusterActivePatternChange={clusterActivePatternChange}
          setLoading={(needLoading) => this.setState({ globalLoading: needLoading })}
        />
      </div>
    );
  }

  render() {
    const { intl, loadStatus, location, projects, instanceInfoTotal, instanceDisplayNameMap } = this.props;

    const {
      globalLoading,
      isLoadingFrequency,
      isLoadingPattern,

      // Select pattern
      patternList,

      page,
      pageSize,
      pageTotal,
      abnormalInstancesTotal,
      normalInstancesTotal,
      instanceContainerOptions,

      filterType,
      filterValue,
      filterPage,
      filterPageSize,
      filterPageTotal,

      activePatternId,

      clustersSampleMsgMap,
      clusterActivePatternChange,

      activeIncident,
      anomalyType,
      showInstance,
      isLoadingInstanceInfo,
    } = this.state;

    const params = parseLocation(location);
    const { projectName, instanceName } = params;
    let project;
    if (activeIncident) {
      project = R.find((project) => projectName === project.projectName, projects);
    }
    const clusterInfo = R.find((item) => String(item.patternId) === String(activePatternId), patternList);
    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const loading = isLoading || isLoadingPattern || isLoadingFrequency || globalLoading || isLoadingInstanceInfo;
    return (
      <Container className="full-width full-height flex-row flex-min-width">
        <Spin spinning={loading} wrapperClassName="full-width full-height spin-full-height">
          {errorMessage && (
            <div className="full-width full-height" style={{ padding: 16 }}>
              <Alert type="error" message={String(errorMessage)} showIcon />
            </div>
          )}

          {!errorMessage && (
            <div className="full-width full-height flex-row">
              <div
                className={`flex-col full-height `}
                style={{
                  width: 380,
                  minWidth: 380,
                  marginRight: 10,
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{ width: 760, transition: 'all 0.2s' }}
                  className={`${showInstance ? '' : 'toggleList'} flex-row flex-grow full-height content-bg`}
                >
                  <div
                    className="flex-col flex-grow full-height corner-8"
                    style={{
                      border: '1px solid var(--border-color-base)',
                      maxWidth: 380,
                    }}
                  >
                    <div
                      className="flex-col"
                      style={{
                        padding: 10,
                        borderBottom: '1px solid var(--border-color-base)',
                      }}
                    >
                      <div className="flex-row flex-center-align">
                        <Select
                          size="small"
                          style={{ width: 110, marginRight: 10 }}
                          options={this.filterTypeOptions}
                          defaultActiveFirstOption
                          dropdownMatchSelectWidth={false}
                          onChange={this.handleFilterTypeChange}
                          value={filterType}
                        />
                        <Input.Search
                          allowClear
                          style={{ flex: 1 }}
                          size="small"
                          placeholder={filterType === 'appName' ? 'Component search' : 'Instance search'}
                          value={filterValue}
                          onSearch={(value) => this.handleFilterChange(value)}
                          onChange={(e) => this.setState({ filterValue: e.target.value })}
                        />
                      </div>
                      <div className="flex-row flex-center-align" style={{ marginTop: 10 }}>
                        <span style={{ marginRight: 4 }}>
                          {intl.formatMessage(appFieldsMessages.abnomalInstances)}:
                        </span>
                        <span style={{ color: 'var(--red)' }}>{abnormalInstancesTotal}</span>
                        <span style={{ marginRight: 4, marginLeft: 10 }}>
                          {intl.formatMessage(appFieldsMessages.normalInstances)}:
                        </span>
                        <span>{normalInstancesTotal}</span>
                      </div>
                    </div>
                    <div style={{ padding: '0 10px', marginTop: 10 }} className="flex-row">
                      <span>Key:</span>
                      <div
                        className="flex-row flex-wrap flex-center-align"
                        style={{ width: 320, marginLeft: 10, rowGap: 4 }}
                      >
                        {R.addIndex(R.map)((key, idx) => {
                          const name = this.typeMessageMap[key];
                          return (
                            <div key={key + idx} style={{ flex: '0 0 33%', whiteSpace: 'nowrap' }}>
                              {this.renerTypeIcon(key)}
                              <span style={{ color: 'var(--text-color)', fontSize: 12 }}>{name}</span>
                            </div>
                          );
                        }, this.anomalyKeys)}
                      </div>
                    </div>
                    <div className="flex-col flex-grow overflow-y-auto overflow-x-hidden" style={{ margin: '10px 0' }}>
                      {/* instance group */}
                      {R.isEmpty(instanceContainerOptions) || !instanceContainerOptions ? (
                        <div className="flex-col flex-grow flex-center-align flex-center-justify">
                          <Empty />
                        </div>
                      ) : (
                        this.renderInstanceGroup(instanceContainerOptions)
                      )}
                      {/* instance group end */}
                    </div>

                    <div className="flex-row flex-center-justify" style={{ marginBottom: 10 }}>
                      {!filterValue && (
                        <Pagination
                          showSizeChanger
                          size="small"
                          current={page}
                          pageSize={pageSize}
                          total={pageTotal}
                          pageSizeOptions={['10', '20', '30', '50', '80', '100']}
                          onChange={this.handlePagination}
                        />
                      )}
                      {filterValue && (
                        <Pagination
                          showSizeChanger
                          size="small"
                          current={filterPage}
                          pageSize={filterPageSize}
                          total={filterPageTotal}
                          pageSizeOptions={['10', '20', '30', '50', '80', '100']}
                          onChange={this.handleFilterPagination}
                        />
                      )}
                    </div>
                  </div>
                  {this.renderPatternList(this.props, this.state)}
                </div>
              </div>

              {/* Detail */}
              <LogEntriesDetail
                renderFilterContent={() => this.renderFilterContent(this.props)}
                clusterActivePatternChange={clusterActivePatternChange}
                clusterInfo={clusterInfo}
                clustersSampleMsgMap={clustersSampleMsgMap}
                anomalyType={anomalyType}
                onChangeAnomalyType={() => {
                  const allAnomalyCount = R.find((item) => item.value === 'all', this.logOption || [])?.count;
                  this.setState({ anomalyType: allAnomalyCount > 0 ? 'all' : null });
                }}
                activePatternId={activePatternId}
                setLoading={(needLoading) => this.setState({ globalLoading: needLoading })}
                hostAndPodIdList={this.hostAndPodIdList}
                instanceDisplayNameMap={instanceDisplayNameMap}
              />
              {/* Detail end */}
            </div>
          )}
        </Spin>

        {this.state.showInsightQueryBox && (
          <InsightQueryBoxModal
            projects={[
              {
                projectNameReal: projectName,
                projectDisplayName: (project || {}).projectDisplayName || projectName,
              },
            ]}
            queryParams={this.state.queryParams}
            onConfirm={this.onConfirmInsightQueryProjectSelect}
            onClose={() => this.setState({ showInsightQueryBox: false })}
          />
        )}
        {this.state.showTakeLogActionModal && (
          <TakeEventTriageModal
            actionDetailsName={this.state.actionName}
            incident={this.state.activeIncident}
            project={project}
            projectName={projectName}
            eventType={false}
            onClose={() => this.setState({ showTakeLogActionModal: false })}
            onNameChanged={this.handlePatternNameChanged}
          />
        )}
        {this.state.showEmailAlertsModal && (
          <EventEmailAlertsModal
            incident={this.state.activeIncident}
            projectName={projectName}
            instanceName={instanceName}
            onClose={() => this.setState({ showEmailAlertsModal: false })}
          />
        )}
      </Container>
    );
  }
}

const LogEntries = injectIntl(LogEntriesCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { isReadUser } = state.auth.userInfo;
    const { loadStatus, projects, currentTheme } = state.app;
    const { logImportantEntryTotal } = state.log;
    const { instanceInfoList, instanceInfoTotal } = state.log;

    return {
      location,
      loadStatus,
      isReadUser,
      projects: R.filter((project) => !project.isMetric, projects),
      logImportantEntryTotal,
      instanceInfoList,
      instanceInfoTotal,
      currentTheme,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(LogEntries);
