import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { message, Descriptions, Spin } from 'antd';

import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { createLoadAction, updateLastActionInfo } from '../../../common/app/actions';
import { Popover } from '../../../lib/fui/react';

import { appMessages } from '../../../common/app/messages';

import LikelyRootCauses from './LikelyRootCauses';
import { Defaults, EventRenderers, LogRenderers, parseLocation } from '../../../common/utils';
import LikelyRootCausesTableRCA from './LikelyRootCausesTableRCA';

type Props = {
  // eslint-disable-next-line
  incident: Object,
  environmentId: String,
  systemId: String,
  needRC: Boolean,
  needPT: Boolean,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  updateLastActionInfo: Function,
  credentials: Object,
  rcaFlag: Boolean,
  currentTheme: String,
  isJWT: Boolean,
  jwtToken: String,
  zoneName: String,
};

class LikelyRootCausesRCACore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.reloadData();
  }

  componentWillUnmount() {
    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  reloadData() {
    const { intl, incident, needRC, needPT, credentials, isJWT, jwtToken, zoneName } = this.props;
    const { systemId } = this.props;
    const {
      category,
      projectOwner,
      anomalyLogInstance,
      instanceList,
      startTimestamp,
      endTimestamp,
      patternId,
      type,
      rootCauseTableKey,
      rootCauseInfoKey,
    } = incident;

    this.setState({ isLoading: true });

    // parse params to get root cause
    let { projectName } = incident;
    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
    const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
    const event = {
      nid: patternId,
      eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
    };

    const request = [];
    if (needRC && !isJWT) {
      request.push(
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'rootCauseEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      );
    }
    if (needPT && !isJWT) {
      request.push(
        fetchPost(getEndpoint('logCausalInfoServlet', 1), {
          ...credentials,
          projectName,
          instanceName: anomalyLogInstance || instanceList[0],
          startTime,
          endTime,
          operation: 'predictedEvents',
          rootCauseTableKey: JSON.stringify(rootCauseTableKey),
          event: JSON.stringify(event),
        }),
      );
    }

    if (needRC && isJWT) {
      request.push(
        fetchPost(getEndpoint('logCausalInfoJWTServlet', 1), {
          queryString: JSON.stringify(rootCauseInfoKey),
          customerName: credentials?.userName,
          systemName: systemId,
          jwt: jwtToken,
          zoneName,
        }),
      );
    }

    if (!isJWT) this.props.updateLastActionInfo();
    Promise.all(request)
      .then((results) => {
        const rootCausesData = needRC ? results[0] : undefined;
        const predictedData = needPT ? results[needRC ? 1 : 0] : undefined;

        this.setState({
          isLoading: false,

          rootCausesData,
          predictedData,
        });
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        this.setState({
          isLoading: false,

          rootCausesData: undefined,
          predictedData: undefined,
        });
      });
  }

  @autobind
  renderIncidentDetails(rowData) {
    const { intl, credentials, currentTheme, needPT, isJWT } = this.props;
    const { category, rawData, projectOwner, summarySettingsMap = {}, isTrace, startTimestamp } = rowData;
    let { projectName } = rowData;

    projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
    const summarySettings = summarySettingsMap[projectName] || [];

    const rootCauseDetailsArr = get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []);
    let rawDataJson;
    try {
      rawDataJson = JSON.parse(rawData);
    } catch (error) {
      // console.debug(error)
    }
    return (
      <div style={{ marginBottom: 8 }} className="ant-descriptions-table">
        <Descriptions bordered size="small" column={8}>
          <Descriptions.Item label={needPT ? 'Event Time' : 'Incident Time'} span={2} labelStyle={{ width: 140 }}>
            <div>{moment.utc(startTimestamp).format(Defaults.DateTimeFormat)}</div>
          </Descriptions.Item>
          <Descriptions.Item label={needPT ? 'Event summary' : 'Incident summary'} span={6} labelStyle={{ width: 140 }}>
            <Popover
              placement="bottom"
              content={
                <div
                  className="overflow-y-auto overflow-x-auto"
                  style={{
                    maxWidth: 480,
                    maxHeight: 350,
                  }}
                >
                  {category === 'metric' && (
                    <div>
                      {R.addIndex(R.map)(
                        (event, index) => EventRenderers.RenderMetricAnomalySummary({ intl, event, index }),
                        rootCauseDetailsArr,
                      )}
                    </div>
                  )}
                  {category === 'log' && (
                    <>
                      {isTrace ? (
                        rawDataJson && this.renderTrace(rowData, rawDataJson)
                      ) : (
                        <div style={{ wordWrap: 'break-word' }}>
                          {!rawDataJson && (
                            <div style={{ whiteSpace: 'break-spaces' }}>
                              {R.join(
                                '\n',
                                R.filter((x) => Boolean(x), (rawData || '').split('\n')),
                              )}
                            </div>
                          )}
                          {rawDataJson && (
                            <div className="flex-grow flex-min-height flex-min-width flex-row flex-center-align">
                              <LogRenderers.RenderLogContent
                                intl={intl}
                                rawData={rawData}
                                rawDataJson={rawDataJson}
                                owner={rowData}
                                summarySettings={summarySettings}
                                enableExpansion={false}
                                currentTheme={currentTheme}
                                clearStyle
                                noExpand
                                notViewJsonString
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              }
            >
              <div className="max-width flex-row" style={{ height: '100%', width: 'fit-content' }}>
                <div className="hidden-line-with-ellipsis-multiline clickable">
                  {category === 'metric' ? (
                    <div className="flex-center-align full-height hidden-line-with-ellipsis-multiline">
                      {R.addIndex(R.map)(
                        (event, index) => EventRenderers.RenderMetricAnomalySummary({ intl, event, index }),
                        rootCauseDetailsArr,
                      )}
                    </div>
                  ) : (
                    <>
                      {!rawDataJson && <div className="hidden-line-with-ellipsis-multiline">{rawData}</div>}
                      {rawDataJson && (
                        <LogRenderers.RenderLogContent
                          intl={intl}
                          rawData={rawData}
                          rawDataJson={rawDataJson}
                          owner={rowData}
                          summarySettings={summarySettings}
                          enableExpansion={false}
                          currentTheme={currentTheme}
                          clearStyle
                          multilineEllipsis
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Popover>
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }

  render() {
    const { incident, environmentId, systemId, rcaFlag, needRC, needPT, isJWT } = this.props;
    const { isLoading, rootCausesData, predictedData } = this.state;
    return (
      <div className="flex-col" style={{ height: '100%' }}>
        {!rcaFlag && this.renderIncidentDetails(incident)}
        <div className="overflow-hidden" style={{ flex: 1, width: '100%' }}>
          {!rcaFlag && (
            <LikelyRootCauses
              environmentId={environmentId}
              systemId={systemId}
              incident={incident}
              rootCausesData={rootCausesData}
              predictedData={predictedData}
              onReload={this.reloadData}
            />
          )}
          {rcaFlag && (
            <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
              <LikelyRootCausesTableRCA
                needRC={needRC}
                needPT={needPT}
                environmentId={environmentId}
                systemId={systemId}
                incident={incident}
                rootCausesData={rootCausesData}
                predictedData={predictedData}
                onReload={this.reloadData}
                isJWT={isJWT}
              />
            </Spin>
          )}
        </div>
      </div>
    );
  }
}

const LikelyRootCausesRCA = injectIntl(LikelyRootCausesRCACore);
export default connect(
  (state) => {
    const { location } = state.router;

    const { dark, jwtToken, customerName } = parseLocation(location);
    let credentials = {};
    if (jwtToken) {
      if (dark) {
        state.app.currentTheme = 'dark';
      } else {
        state.app.currentTheme = 'light';
      }
      credentials = { userName: customerName };
    } else {
      credentials = state.auth?.credentials;
    }
    const { currentTheme } = state.app;

    return { credentials, currentTheme };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(LikelyRootCausesRCA);
