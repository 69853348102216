/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postPatternFlag = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, nid, ignoreFlag, ...rest } = params;
  return fetchPost(
    getEndpoint('events'),
    {
      ...credentials,
      ...rest,
      projectName,
      instanceName,
      operation: ignoreFlag ? 'setIgnoreFlag' : 'cancelIgnoreFlag',
      nid,
    },
    {},
    false,
  ).then((d) => {
    return d;
  });
};

export default postPatternFlag;
