import React, { useEffect, useReducer } from 'react';
import { Button, Form, Input, message, Select, Spin } from 'antd';
import * as R from 'ramda';
import { Modal } from '../../../lib/fui/react';
import SelectMultiple from '../../../../components/custome/SelectMultiple';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { parseJSON } from '../../../common/utils';

const SelectApplyInfoModal = (props: Object) => {
  const {
    userList,
    isAdmin,
    isLocalAdmin,
    onClose,
    onApply,
    userInfo,
    systemList: localSystemList,
    fromSystemOptions,
    systemId,
  } = props;
  const { credentials } = props;

  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    systemListOptions: [],
    systemIds: [],
    userNameValue: isAdmin || isLocalAdmin ? null : userInfo.userName,
    fromSystemId: systemId,
    loading: false,
    systemLoading: false,
    dashboardName: '',
    isLoadingSystem: false,
    systemsMap: null,
  });
  const {
    systemIds,
    userNameValue,
    dashboardName,
    isLoadingSystem,
    systemListOptions,
    systemsMap,
    systemLoading,
    fromSystemId,
  } = state;

  const parseSystemData = async (flag) => {
    const { isAdmin, isLocalAdmin, userName } = userInfo;
    let systemList = [];
    const systemsMap = {};
    setState({ systemLoading: true });
    if (!isAdmin && !isLocalAdmin) {
      systemList = R.sort((systemA, systemB) => systemA.name.localeCompare(systemB.name), localSystemList);
      systemList = R.filter(
        (item) => (item) => item.ownerUserName === userName || item.shareUserSet.includes(userName),
        systemList,
      );
    } else {
      await fetchGet(getEndpoint('systemframework', 2), {
        ...credentials,
        customerName: userNameValue,
        needDetail: false,
      })
        .then((results) => {
          if (!flag) {
            const { success, message: msg } = results;
            if (success || success === undefined) {
              let { ownSystemArr, shareSystemArr } = results || {};
              ownSystemArr = R.addIndex(R.map)((item, index) => {
                const system = parseJSON(item) || {};
                return { ...system, isShared: false };
              }, ownSystemArr || []);
              shareSystemArr = R.addIndex(R.map)((item, index) => {
                const system = parseJSON(item) || {};
                return { ...system, isShared: true };
              }, shareSystemArr || []);

              R.forEach(
                (system) => {
                  const { systemDisplayName, systemKey } = system;
                  const { systemName: systemId, userName } = systemKey || {};
                  systemList.push({
                    ...system,
                    ownerUserName: userName,
                    name: systemDisplayName || systemId,
                    id: systemId,
                  });
                  if (!R.has(systemId, systemsMap)) {
                    systemsMap[systemId] = {
                      ...system,
                      owner: userName,
                      systemName: systemDisplayName,
                      id: systemId,
                      systemId,
                      ownerUserName: userName,
                    };
                  }
                },
                [...ownSystemArr, ...shareSystemArr],
              );
              systemList = R.sort((systemA, systemB) => systemA.name.localeCompare(systemB.name), systemList);
              systemList = R.filter(
                (item) => (item) => item.ownerUserName === userNameValue || item.shareUserSet.includes(userNameValue),
                systemList,
              );
            } else {
              message.error(msg);
            }
          }
        })
        .catch((err) => {
          message.error(err.message || String(err));
        });
    }

    systemList = R.map(
      (item) => ({ label: `${item.systemDisplayName} (${item.ownerUserName})` || item.name, value: item.id }),
      systemList,
    );

    setState({ systemListOptions: systemList, systemsMap, systemIds: [], systemLoading: false });
  };

  const handleApply = () => {
    R.forEach((systemId) => {
      onApply(systemId, userNameValue, dashboardName, systemsMap, fromSystemId);
    }, systemIds);
  };

  useEffect(() => {
    let flag = false;
    if (userNameValue) {
      parseSystemData(flag);
    }
    return () => {
      flag = true;
    };
  }, [userNameValue]);

  const hasError = !systemIds.length || !userNameValue || !dashboardName;

  return (
    <Modal title="Select the systems to apply the dashboard to" visible footer={null} onCancel={() => onClose()}>
      <Spin spinning={systemLoading} wrapperClassName="full-width full-height spin-full-width">
        <Form layout="vertical" className="full-width">
          <Form.Item label="From System" required>
            <Select
              showSearch
              value={fromSystemId}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(fromSystemId) => {
                setState({ fromSystemId })
              }}
              dropdownMatchSelectWidth={false}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ),
                fromSystemOptions || [],
              )}
            </Select>
          </Form.Item>

          <Form.Item label="Dashboard name" required>
            <Input value={dashboardName} onChange={(e) => setState({ dashboardName: e.target.value })} />
          </Form.Item>

          {(isAdmin || isLocalAdmin) && (
            <Form.Item label="User" required>
              <Select
                showSearch
                value={userNameValue}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(userNameValue) => setState({ userNameValue })}
                dropdownMatchSelectWidth={false}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            </Form.Item>
          )}

          {userNameValue && (
            <Form.Item label="Target System" required>
              <SelectMultiple
                loading={isLoadingSystem}
                options={systemListOptions}
                value={systemIds}
                onChange={(systemIds) => setState({ systemIds })}
              />
            </Form.Item>
          )}
        </Form>
      </Spin>
      <div className="flex-row flex-end-justify">
        <Button size="small" disabled={hasError} type="primary" onClick={handleApply} loading={isLoadingSystem}>
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default SelectApplyInfoModal;
