import { get } from 'lodash';
import { BaseUrls } from '../../web/app/Constants';
import { appFieldsMessages, appMenusMessages, appMessages } from '../app/messages';
import { settingsMenusMessages } from '../settings/messages';
import { DashboardMessages } from '../dashboard/messages';

export const getMenuName = (url, intl, projectSettingsParams, location) => {
  let menuTitle = '';
  switch (url) {
    case BaseUrls.GlobalHealth:
      menuTitle = intl.formatMessage(appMenusMessages.globalHealthView);
      break;
    case BaseUrls.GlobalSystemInsights:
      menuTitle = intl.formatMessage(appMenusMessages.globalSystemInsights);
      break;
    case BaseUrls.GlobalSystemRootCause:
      menuTitle = intl.formatMessage(appMenusMessages.globalSystemRootCause);
      break;
    case BaseUrls.GlobalSystemPrediction:
      menuTitle = intl.formatMessage(appMenusMessages.globalSystemPrediction);
      break;
    case BaseUrls.GlobalSystemAlertCause:
      menuTitle = intl.formatMessage(appMenusMessages.globalSystemAlertCause);
      break;
    case BaseUrls.GlobalSystemKnowledgeBase:
      menuTitle = intl.formatMessage(appMenusMessages.globalSystemKnowledgeBase);
      break;
    case BaseUrls.GlobalCapacityPlanning:
      menuTitle = intl.formatMessage(appMenusMessages.globalCapacityView);
      break;
    case BaseUrls.Query:
      menuTitle = intl.formatMessage(appMenusMessages.insightQuery);
      break;
    case BaseUrls.MetricEvents:
      menuTitle = intl.formatMessage(appMenusMessages.metricAnalysis);
      break;
    case `${BaseUrls.Log}_hasLog`:
      menuTitle = intl.formatMessage(appMenusMessages.logAnalysis);
      break;
    case `${BaseUrls.Log}_hasAlert`:
      menuTitle = intl.formatMessage(appMenusMessages.alertAnalysis);
      break;
    case BaseUrls.Causal:
      menuTitle = intl.formatMessage(appMenusMessages.causalAnalysis);
      break;
    case BaseUrls.EventsHistory:
      menuTitle = intl.formatMessage(appMenusMessages.eventHistory);
      break;
    case BaseUrls.JobAnalysis:
      menuTitle = intl.formatMessage(appMenusMessages.jobAnalysis);
      break;
    case BaseUrls.FileAnalysis:
      menuTitle = intl.formatMessage(appMenusMessages.fileAnalysis);
      break;
    case BaseUrls.SystemSetting:
      menuTitle = intl.formatMessage(settingsMenusMessages.systemSetting);
      break;
    case BaseUrls.SystemBatch:
      menuTitle = intl.formatMessage(settingsMenusMessages.systemBatch);
      break;
    case BaseUrls.InsightfinderSetting:
      menuTitle = intl.formatMessage(settingsMenusMessages.ifSetting);
      break;
    case BaseUrls.ExternalServiceSetting:
      menuTitle = intl.formatMessage(settingsMenusMessages.externalService);
      break;
    case BaseUrls.IdentityProvidersSetting:
      menuTitle = intl.formatMessage(settingsMenusMessages.identityProvider);
      break;
    case BaseUrls.StatusPageSetting:
      menuTitle = intl.formatMessage(settingsMenusMessages.statusPage);
      break;
    case BaseUrls.GlobalHealthSystem:
      menuTitle = intl.formatMessage(appMenusMessages.globalHealthView);
      break;
    case BaseUrls.GlobalSystemPredictionMatched:
      menuTitle = intl.formatMessage(appMenusMessages.globalSystemCorrectlyPredicted);
      break;
    case BaseUrls.GlobalSystemIgnoredIncident:
      menuTitle = intl.formatMessage(appMenusMessages.globalSystemIgnoredIncident);
      break;
    case BaseUrls.CapacityPlanningCalendar:
      menuTitle = intl.formatMessage(DashboardMessages.capacityPlanning);
      break;
    case BaseUrls.MetricLineCharts:
      menuTitle = intl.formatMessage(appMenusMessages.metricAnalysis);
      break;
    case BaseUrls.EventsDetails:
      menuTitle = intl.formatMessage(appMenusMessages.metricAnalysis);
      break;
    case BaseUrls.SettingsIntegrations:
      menuTitle = intl.formatMessage(appFieldsMessages.integrations);
      break;
    case BaseUrls.SettingsProjectWizard:
      menuTitle = intl.formatMessage(settingsMenusMessages.systemSetting);
      break;
    case BaseUrls.SettingsCopyProject:
      menuTitle = intl.formatMessage(settingsMenusMessages.systemSetting);
      break;
    case BaseUrls.FilesMonitoring:
      menuTitle = intl.formatMessage(appMenusMessages.fileAnalysis);
      break;
    case BaseUrls.FilesDetectionMonitoring:
      menuTitle = intl.formatMessage(appMenusMessages.fileAnalysis);
      break;
    case BaseUrls.Help:
      menuTitle = intl.formatMessage(appMenusMessages.help);
      break;
    case BaseUrls.AccountInfo:
      menuTitle = intl.formatMessage(appMessages.userAccountInformation);
      break;
    case `${BaseUrls.SystemSetting}/${projectSettingsParams?.projectName}`:
      menuTitle = intl.formatMessage(settingsMenusMessages.systemSetting);
      break;
    case location?.pathname.includes('/settings/integrations/') && location?.pathname:
      menuTitle = intl.formatMessage(settingsMenusMessages.systemSetting);
      break;
    case BaseUrls.GlobalServiceMap:
      menuTitle = intl.formatMessage(settingsMenusMessages.serviceMapMsg);
      break;
    case BaseUrls.GlobalAction:
      menuTitle = intl.formatMessage(settingsMenusMessages.actionMsg);
      break;
    default:
      menuTitle = '';
  }
  return menuTitle;
};

export const getHandTitleText = (
  selectedKey,
  intl,
  projectName,
  systemId,
  systemsMap,
  projectSettingsParams,
  location,
) => {
  const menuName = getMenuName(selectedKey, intl, projectSettingsParams, location);
  const systemName = get(systemsMap, [systemId, 'systemName'], systemId);
  return `${menuName ? `${menuName} - ` : ''}${systemName ? `${systemName} - ` : ''}${
    projectName ? `${projectName} - ` : ''
  }InsightFinder`;
};
