import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get } from 'lodash';
import { Spin, message } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, parseJSON } from '../../../common/utils';

import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { appMessages } from '../../../common/app/messages';

type PropsLeadToIncident = {
  intl: Object,
  credentials: Object,
  incident: Object,
  leadIncidentPatternNameStr: String,
  setIncidentPatternNameStr: Function,
  updateLastActionInfo: Function,
};

const RenderLeadToIncident = ({
  intl,
  credentials,
  incident,
  leadIncidentPatternNameStr,
  setIncidentPatternNameStr,
  updateLastActionInfo,
}: PropsLeadToIncident) => {
  const [isLoading, setLoading] = useState(false);
  const [patternNameStr, setPatternNameStr] = useState(leadIncidentPatternNameStr);

  useEffect(() => {
    if (!patternNameStr) {
      setLoading(true);

      let leadToIncidentItem = R.find(
        (item) => get(item, ['rootCauseResultInfo', 'leadToIncident'], false),
        incident.timelines || [],
      );
      leadToIncidentItem = leadToIncidentItem || incident;
      const {
        category,
        projectOwner,
        anomalyLogInstance,
        instanceList,
        startTimestamp,
        endTimestamp,
        patternId,
        type,
        rootCauseTableKey,
        rootCausesDetailsList,
      } = leadToIncidentItem;
      let { projectName } = leadToIncidentItem;
      projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
      const startTime = moment.utc(startTimestamp).startOf('day').valueOf();
      const endTime = moment.utc(endTimestamp).endOf('day').valueOf();
      const rootCause = R.omit(['timePairList'], rootCausesDetailsList[0] || {});
      const event = {
        timestamp: startTimestamp,
        nid: patternId,
        eventType: type === 'Incident' ? 'Incident' : category === 'metric' ? 'Metric' : type,
        rootCause: type === 'Incident' && category === 'metric' ? rootCause : null,
      };

      updateLastActionInfo();
      fetchPost(getEndpoint('logCausalInfoServlet', 1), {
        ...credentials,
        projectName,
        instanceName: anomalyLogInstance || instanceList[0],
        startTime,
        endTime,
        operation: 'predictedEvents',
        rootCauseTableKey: JSON.stringify(rootCauseTableKey),
        event: JSON.stringify(event),
      })
        .then(async (data) => {
          const logPredictedEvents = get(data, ['logPredictedEvents'], []);
          const metricPredictedEvents = get(data, ['metricPredictedEvents'], []);
          let anomalyList = [];
          R.forEach(
            (item) => {
              const predictionSourceInfoList = get(item, ['rootCauseChain', 'predictionSourceInfoList'], []);
              anomalyList = [...anomalyList, ...predictionSourceInfoList];
            },
            [...logPredictedEvents, ...metricPredictedEvents],
          );

          // find incident
          const incidentItem = R.find((item) => item.type === 'Incident', anomalyList);
          if (incidentItem) {
            const { nid } = parseJSON(incidentItem.sourceDetail) || {};

            // get patternName
            const incidentProjectName =
              incidentItem.sourceProjectOwner !== credentials.userName
                ? `${incidentItem.sourceProjectName}@${incidentItem.sourceProjectOwner}`
                : incidentItem.sourceProjectName;
            const patternName = await fetchGet(getEndpoint('getpatternname', 1), {
              ...credentials,
              customerName: incidentItem.sourceProjectOwner,
              projectName: incidentProjectName,
              instanceName: incidentItem.sourceInstanceName,
              patternId: nid,
              startTime: incidentItem.eventTimestamp,
            })
              .then((result) => get(result, 'patternName'))
              .catch((err) => undefined);
            const { patternNameStr } = Defaults.PatternIdNameStr(
              { patternName, patternId: nid },
              { hasFullName: true, hasPrefix: true },
            );

            setPatternNameStr(patternNameStr);
            setIncidentPatternNameStr(patternNameStr);
          }
          setLoading(false);
        })
        .catch((err) => {
          message.error(intl.formatMessage(appMessages.apiFaild));
          console.log(err);
          setLoading(false);
        });
    }
  }, []);

  return (
    <div className="flex-col" style={{ minWidth: 150 }}>
      <div>{intl.formatMessage(DashboardMessages.leadToIncident)}</div>
      <div className="flex-row">
        <div style={{ marginRight: 8, whiteSpace: 'nowrap' }}>{intl.formatMessage(eventMessages.incidentPattern)}:</div>
        <Spin size="small" spinning={isLoading}>
          {patternNameStr}
        </Spin>
      </div>
    </div>
  );
};

export default RenderLeadToIncident;
