import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { message, Spin, Select, Alert, Tag } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import fetchDelete from '../../../common/apis/fetchDelete';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Modal } from '../../../lib/fui/react';
import { appMessages } from '../../../common/app/messages';
import { causalMessages } from '../../../common/causal/messages';

type Props = {
  intl: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  errorMessage: ?Object,
  currentLoadingComponents: Object,
  projects: Array<Object>,

  causalKey: String,
  causalGroup: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  customerName: String,
  onClose: Function,
  handleShareCausalGroup: Function,
};

class ShareCausalGroupModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoader = 'causal_share_user_submit';
    this.sumbitting = false;

    this.state = {
      isLoading: false,
      userList: [],

      user: null,
    };
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLoadingComponents, errorMessage } = nextProps;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      if (!errorMessage) {
        this.props.onClose(true);
      }
    }
  }

  @autobind
  reloadData(props) {
    const { intl, credentials, userInfo, updateLastActionInfo } = props;
    this.setState({ isLoading: true });
    updateLastActionInfo();
    fetchGet(getEndpoint('customer', 2), {
      ...credentials,
    })
      .then((data) => {
        let userList = data || [];
        userList = R.filter(
          (item) =>
            item.userName !== userInfo.userName && (userInfo.isAdmin || item.companyName === userInfo.companyName),
          userList,
        );
        userList = R.sortWith([R.ascend(R.prop('userName'))], userList);

        this.setState({ isLoading: false, userList });
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false, userList: [] });
      });
  }

  @autobind
  hadnleRemoveShareUser(user) {
    const { intl, credentials, updateLastActionInfo, onClose, causalGroup } = this.props;
    this.setState({ isLoading: true });
    const { causalKey, owner } = causalGroup;
    updateLastActionInfo();
    fetchDelete(
      getEndpoint('sharecausalgroup'),
      {
        ...credentials,
        causalKey,
        owner,
        removeShareUserName: user,
      },
      {},
      false,
    )
      .then(() => {
        this.setState({ isLoading: false });
        onClose(true);
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isLoading: false });
      });
  }

  @autobind
  handleSumbit() {
    const { handleShareCausalGroup, causalGroup } = this.props;
    const { user } = this.state;
    const { causalKey, causalName, customerName, interval, projectList } = causalGroup;
    const shareCausalData = {
      [causalKey]: { causalName, interval, projectList, userName: customerName },
    };

    this.sumbitting = true;
    handleShareCausalGroup(
      { shareUserName: user, shareCausalData: JSON.stringify(shareCausalData) },
      { [this.submitLoader]: true },
    );
  }

  render() {
    const { intl, onClose, currentLoadingComponents, errorMessage, causalGroup } = this.props;
    const { isLoading, userList, user } = this.state;
    const { sharedUserSet } = causalGroup;

    const hasError = !user;
    const isSubmitting = get(currentLoadingComponents, this.submitLoader, false);
    return (
      <Modal
        title={intl.formatMessage(causalMessages.shareCausalGroup)}
        width={650}
        visible
        onCancel={() => onClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isSubmitting }}
      >
        <Spin spinning={isLoading} wrapperClassName="spin-full-height full-width full-height">
          <div className="flex-row flex-center-align" style={{ marginBottom: 24 }}>
            <div className="bold" style={{ width: 100 }}>
              {intl.formatMessage(causalMessages.sharedUsers)}:
            </div>
            <div className="flex-grow" style={{ wordBreak: 'break-word' }}>
              {sharedUserSet &&
                sharedUserSet.length > 0 &&
                R.map((user) => {
                  return (
                    <Tag
                      key={user}
                      closable
                      visible
                      onClose={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        this.hadnleRemoveShareUser(user);
                      }}
                    >
                      {user}
                    </Tag>
                  );
                }, sharedUserSet)}
            </div>
          </div>

          <div className="flex-row flex-center-aign" style={{ marginBottom: 24 }}>
            <div className="bold" style={{ width: 100 }}>
              {intl.formatMessage(causalMessages.shareUser)}:
            </div>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={user}
              onChange={(user) => this.setState({ user })}
              style={{ width: 200 }}
            >
              {R.map((user) => {
                return <Select.Option key={user.userName}>{user.userName}</Select.Option>;
              }, userList)}
            </Select>
          </div>

          {Boolean(errorMessage) && (
            <Alert message={intl.formatMessage(errorMessage.message, errorMessage.params)} type="error" showIcon />
          )}
        </Spin>
      </Modal>
    );
  }
}

export default ShareCausalGroupModal;
