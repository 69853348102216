/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 **/

import React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import Helmet from 'react-helmet';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { parseLocation, getLoadStatus, ifIn } from '../../../common/utils';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/log/actions';
import { Container } from '../../../lib/fui/react';
import LogBarCharts from '../../log/components/LogBarCharts';

type Props = {
  // Common props
  intl: Object,
  location: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  projects: Array<Object>,
  instanceInfoList: Array<Object>,
  instancesDetailInfos: Array<Object>,
};

class LogRareEventReportCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.pageSize = 50;
    this.dataLoader = 'export_log_rareevent';
  }

  componentDidMount() {
    this.reloadData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.instanceInfoList !== nextProps.instanceInfoList) {
      this.reloadData(nextProps);
    }
  }

  @autobind
  reloadData(props) {
    const { location, createLoadAction, instanceInfoList } = props;
    const params = parseLocation(location);
    const { projectName, day } = params;
    const instanceList = R.map((instanceInfo) => instanceInfo.id, instanceInfoList || []);

    if (instanceList.length === 0) {
      createLoadAction(ActionTypes.LOAD_LOG_INSTANCEINFO_LIST, { projectName, day }, null);
    } else if (instanceList.length > 0) {
      createLoadAction(ActionTypes.LOAD_LOG_INSTANCES_STATS, { projectName, instanceList, day }, this.dataLoader);
    }
  }

  @autobind
  parseData(props) {}

  render() {
    const { intl, loadStatus, instancesDetailInfos } = this.props;
    const { isLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const { location } = this.props;
    const params = parseLocation(location);
    const { hideevent: hideEvent, projectName, day } = params;
    const width = 700;
    const stackTraceNames = ['stacktrace', 'stack-trace'];

    return (
      <Container className={`report ${isLoading ? 'loading' : ''}`} style={{ width: '100%', minHeight: 200 }}>
        <Helmet title={`report-${projectName}-${day}`} htmlAttributes={{ class: 'report' }} />
        <Container style={{ fontSize: 12, width, margin: '0 auto', padding: '16px 0' }}>
          {R.addIndex(R.map)((n, index) => {
            return (
              <div key={index} className="section">
                <div style={{ fontSize: 14, fontWeight: 'bold', marginTop: 8, color: 'blue' }}>{`Instance: ${
                  n.instanceName
                }`}</div>
                <LogBarCharts height={160} width={width} detailInfos={n} />
                {!hideEvent && (
                  <div>
                    {R.addIndex(R.map)((r, indexEvent) => {
                      let message = r.rawData || '';
                      try {
                        let json = JSON.parse(message) || {};
                        if (R.keys(json).length > 1) {
                          // Display json message
                          if (json.messageContent) {
                            const mjson = JSON.parse(json.messageContent);
                            if (mjson) {
                              json = { ...json, ...mjson };
                            }
                          }

                          // Auto display the keys of the json
                          const names = R.sort(
                            (a, b) => a.localeCompare(b),
                            R.filter(
                              (data) =>
                                data.toLowerCase() !== 'msg' &&
                                data.toLowerCase() !== 'messagecontent' &&
                                !ifIn(data.toLowerCase(), stackTraceNames),
                              R.keys(json),
                            ),
                          );

                          const stname = R.find((data) => ifIn(data.toLowerCase(), stackTraceNames), R.keys(json));
                          const stacktrace = get(json, stname);

                          return (
                            <div key={indexEvent} style={{ marginTop: 8 }}>
                              <div style={{ fontWeight: 'bold', fontSize: 13 }}>{`Rare Event (${moment
                                .utc(r.timestamp)
                                .toString()})`}</div>
                              {R.addIndex(R.map)((n, idx) => {
                                return (
                                  <div key={idx}>
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                        width: 200,
                                        display: 'inline-block',
                                        textTransform: 'capitalize',
                                      }}
                                    >{`${n}:`}</span>
                                    <span>{json[n] || (json[n] === null ? 'null' : '')}</span>
                                  </div>
                                );
                              }, names)}
                              <div style={{ fontWeight: 'bold' }}>Message:</div>
                              <div style={{ wordBreak: 'break-all' }}>{json.msg || ''}</div>
                              {stacktrace && <div style={{ fontWeight: 'bold' }}>StackTrace:</div>}
                              {stacktrace && <div style={{ wordBreak: 'break-all' }}>{stacktrace}</div>}
                            </div>
                          );
                        } else {
                          // If message only contains a field, we assume it's msg field, show it as the raw data.
                          message = json.msg || json[R.keys(json)[0]] || '';
                        }
                      } catch (e) {}

                      return (
                        <div key={indexEvent} style={{ marginTop: 8 }}>
                          <div style={{ fontWeight: 'bold', fontSize: 13 }}>{`Rare Event (${moment
                            .utc(r.timestamp)
                            .toString()})`}</div>
                          <div style={{ wordBreak: 'break-all' }}>{message}</div>
                        </div>
                      );
                    }, R.sort((a, b) => a.timestamp - b.timestamp, n.rareEntries || []))}
                  </div>
                )}
              </div>
            );
          }, instancesDetailInfos)}
        </Container>
      </Container>
    );
  }
}

const LogRareEventReport = injectIntl(LogRareEventReportCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { projects, loadStatus } = state.app;
    const instanceInfoList = state.log.instanceInfoList || [];
    const instancesDetailInfos = state.log.instancesDetailInfos || [];
    return { location, loadStatus, projects, instanceInfoList, instancesDetailInfos };
  },
  { push, replace, createLoadAction },
)(LogRareEventReport);
