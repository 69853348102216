import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { hideAppLoader } from '../../common/app/actions';
import { BaseUrls } from '../app/Constants';

import { HelpMessages } from '../../common/help/messages';

type Props = {
  intl: Object,
  hideAppLoader: Function,
};

class HelpCore extends React.Component {
  props: Props;

  componentDidMount() {
    this.props.hideAppLoader();
  }

  render() {
    const { intl } = this.props;
    return (
      <div className="ui container" style={{ padding: 16 }}>
        <div className="text-center" style={{ padding: '40px 50px 20px' }}>
          <h2>{intl.formatMessage(HelpMessages.welcome)}!</h2>
          <p>{intl.formatMessage(HelpMessages.welcomeInfo)}</p>
        </div>
        <div className="ui grid three columns text-center" style={{ margin: 0 }}>
          <div className="column">
            <div className="flex-col flex-center-align">
              <i className="info circle icon" />
              <h3>{intl.formatMessage(HelpMessages.helpStart)}</h3>
              <div className="flex-col full-width" style={{ textAlign: 'left' }}>
                <Link to={BaseUrls.SystemSetting} className="item">
                  {intl.formatMessage(HelpMessages.helpStart1)}
                </Link>
                <Link to={BaseUrls.GlobalHealth} className="item">
                  {intl.formatMessage(HelpMessages.helpStart2)}
                </Link>
                <Link to={BaseUrls.SystemSetting} className="item">
                  {intl.formatMessage(HelpMessages.helpStart3)}
                </Link>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="flex-col flex-center-align">
              <i className="help circle icon" />
              <h3>{intl.formatMessage(HelpMessages.helpAgent)}</h3>
              <div className="flex-col full-width" style={{ textAlign: 'left' }}>
                <Link to={BaseUrls.AccountInfo} className="item">
                  {intl.formatMessage(HelpMessages.helpAgent1)}
                </Link>
                <a
                  href="https://github.com/insightfinder/InsightAgent/wiki"
                  target="_blank"
                  className="item"
                  rel="noreferrer"
                >
                  {intl.formatMessage(HelpMessages.helpAgent2)}
                </a>
                <a
                  href="https://github.com/insightfinder/InsightAgent/wiki"
                  target="_blank"
                  className="item"
                  rel="noreferrer"
                >
                  {intl.formatMessage(HelpMessages.helpAgent3)}
                </a>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="flex-col flex-center-align">
              <i className="dashboard icon" />
              <h3>{intl.formatMessage(HelpMessages.helpDashboard)}</h3>
              <div className="flex-col full-width" style={{ textAlign: 'left' }}>
                <Link to={BaseUrls.GlobalHealth} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpDashboard1)}
                </Link>
                <Link to={BaseUrls.GlobalSystemPrediction} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpDashboard2)}
                </Link>
                <Link to={BaseUrls.GlobalSystemRootCause} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpDashboard3)}
                </Link>
                <Link to={BaseUrls.GlobalSystemRootCause} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpDashboard4)}
                </Link>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="flex-col flex-center-align">
              <i className="file text icon" />
              <h3>{intl.formatMessage(HelpMessages.helpLog)}</h3>
              <div className="flex-col full-width" style={{ textAlign: 'left' }}>
                <Link to={BaseUrls.LogCalendar} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpLog1)}
                </Link>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="flex-col flex-center-align">
              <i className="settings icon" />
              <h3>{intl.formatMessage(HelpMessages.helpSettings)}</h3>
              <div className="flex-col full-width" style={{ textAlign: 'left' }}>
                <Link to={BaseUrls.SystemSetting} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpSettings1)}
                </Link>
                <Link to={BaseUrls.SystemSetting} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpSettings2)}
                </Link>
                <Link to={BaseUrls.SystemSetting} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpSettings3)}
                </Link>
                <Link to={BaseUrls.ExternalServiceSetting} className="item">
                  &bull; {intl.formatMessage(HelpMessages.helpSettings4)}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Help = injectIntl(HelpCore);
export default connect(
  (state) => {
    const { location } = state.router;
    return { location };
  },
  { hideAppLoader },
)(Help);
