/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { RightOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { TreeSelect, Button, Alert, Select as AntSelect, DatePicker, Checkbox, Input as AntInput, Card } from 'antd';

import { Input, Select } from '../../../../lib/fui/react';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
};

class DataDogBatchSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;

    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      appKey: '',
      apiKey: '',
      dataType: 'Metric',
      fields: [],
      instanceKey: '',
      samplingInterval: 5,
      samplingUnit: 60,
      metrics: [],
      selectedMetrics: [],
      treeMetricList: [],
      kpiMetrics: [],
      startTime: null,
      endTime: null,
      processPauseFlag: '',
      componentName: [],
      tagsExp: [],

      additionalFilterList: [],
      componentOptions: [],

      treeData: [],
      allComponentTags: [],
      allTags: [],
      logInstanceFilter: [{ tag: '', value: '' }],
      useHostName: 'Host',
    };
    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.dataTypeOption = [
      { label: 'Metric', value: 'Metric' },
      { label: 'Event', value: 'Alert' },
      { label: 'Log', value: 'Log' },
    ];
    this.samplingUnit = [
      { label: 'second', value: 1 },
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
    this.dateFormat = 'YYYY-MM-DD';
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { credentials } = this.props;
    const { appKey, apiKey, dataType } = this.state;
    const isMetric = dataType === 'Metric';
    let metrics = [];
    const projectCreationType = isMetric ? 'DataDogMetric' : 'DataDogLog';
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      appkey: appKey,
      apikey: apiKey,
      projectCreationType,
      dataType,
    })
      .then((data) => {
        const { success, results, fields, tags, message: msg } = data;
        if (success) {
          metrics = R.uniq(results.metrics || []);
          metrics = metrics.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          });
          this.handleTagsData(data?.tags || []);
          if (isMetric) {
            this.handleTreeData(metrics);
            this.setState({ metrics });
          } else {
            this.setState({ fields, componentOptions: tags });
          }
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
            },
            () => {
              this.props.saveProjectInfo(projectCreationType, { dataType }, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: msg || String(msg),
            },
            () => {
              this.props.saveProjectInfo(projectCreationType, { dataType }, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: err.message || String(err),
          },
          () => {
            this.props.saveProjectInfo(projectCreationType, { dataType }, this.state);
          },
        );
      });
  }

  @autobind
  handleConfirmClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      appKey,
      apiKey,
      dataType,
      selectedFields,
      instanceKey,
      samplingInterval,
      samplingUnit,
      selectedMetrics,
      kpiMetrics,
      startTime,
      endTime,
      processPauseFlag,
      componentName,
      tagsExp,
      additionalFilterList,
      useHostName,
    } = this.state;
    let { logInstanceFilter } = this.state;
    const isMetric = dataType === 'Metric';

    const additionalFilter = [];
    R.forEach((item) => {
      if (item) {
        const addtionList = R.map((n) => n.trim(), item.split('AND'));
        additionalFilter.push(addtionList);
      }
    }, additionalFilterList);

    const newTagsExp = {};
    R.forEach((item) => {
      const tag = item.split('=')[0];
      const value = item.split('=')[1];
      if (!R.has(tag, newTagsExp)) {
        newTagsExp[tag] = [value];
      } else {
        newTagsExp[tag].push(value);
      }
    }, tagsExp);

    const newLogTagsExp = {};
    R.forEach((item) => {
      const { tag, value } = item;
      if (tag && value) {
        if (!R.has(tag, newLogTagsExp)) {
          newLogTagsExp[tag] = value.split(',');
        } else {
          newLogTagsExp[tag] = [...newLogTagsExp[tag], ...value.split(',')];
        }
      }
    }, logInstanceFilter);

    if (logInstanceFilter.length > 1) {
      logInstanceFilter = R.filter((item) => item.tag && item.value, logInstanceFilter);
      if (logInstanceFilter.length < 1) {
        logInstanceFilter = [{ tag: '', value: '' }];
      }
    }

    this.setState({ logInstanceFilter }, () => {
      this.props.createProject(
        'Datadog',
        {
          apikey: apiKey,
          appkey: appKey,
          dataType,
          fields: JSON.stringify(selectedFields),
          instanceKey,
          operation: 'register',
          samplingInterval: Number(samplingInterval) * samplingUnit,
          metrics: JSON.stringify(selectedMetrics),
          kpiMetrics: JSON.stringify(kpiMetrics),
          startTime: startTime ? startTime.valueOf() : undefined,
          endTime: endTime ? endTime.valueOf() : undefined,
          processPauseFlag,
          ...(isMetric
            ? { componentName: componentName.join(',') }
            : { componentFields: JSON.stringify(componentName) }),
          additionalFilter: JSON.stringify(additionalFilter),
          preTag: JSON.stringify(isMetric ? newTagsExp : newLogTagsExp),
          useHostName: useHostName !== 'Host',
        },
        this.state,
      );
    });
  }

  @autobind
  handleChangeMetric(metricNames) {
    const treeMetricList = [];
    let selectedMetrics = [];
    R.forEach((metricName) => {
      R.forEach((metric) => {
        if (metric.indexOf(metricName) === 0) {
          selectedMetrics.push(metric);
        }
      }, this.state.metrics);
      treeMetricList.push(metricName);
    }, metricNames);
    selectedMetrics = R.uniq(selectedMetrics);
    this.setState({ selectedMetrics, treeMetricList });
  }

  @autobind
  handleChangeInstance(instanceNames) {
    this.setState({ tagsExp: instanceNames });
  }

  @autobind
  handleKPIMetricChange(metrics) {
    this.setState({ kpiMetrics: metrics });
  }

  @autobind
  handleComponentChange(componentName) {
    this.setState({ componentName });
  }

  @autobind
  handleFieldChange(fields) {
    this.setState({ selectedFields: fields });
  }

  @autobind
  handleTreeData(metrics) {
    const treeData = [];
    R.forEach((metric) => {
      const metricArr = metric.split('.');
      const nameLen = metricArr.length;
      let nodes = treeData || [];
      let metricName = '';
      for (let i = 0; i < nameLen; i += 1) {
        const prefix = metricArr[i];
        const nodesNum = nodes.length;
        metricName = metricName === '' ? prefix : `${metricName}.${prefix}`;
        if (nodesNum === 0 || nodes[nodesNum - 1].label !== prefix) {
          nodes.push({
            title: metricName,
            value: metricName,
            key: metricName,
            label: prefix,
            children: [],
          });
        }
        nodes = nodes[nodes.length - 1].children;
      }
    }, metrics);
    this.setState({ treeData });
  }

  @autobind
  handleTagsData(tags) {
    const allComponentTags = [];
    R.forEach(
      (name) => {
        allComponentTags.push(name);
      },
      R.sort((a, b) => a.localeCompare(b), R.keys(tags)),
    );
    const allTags = this.setAllTags(tags);
    this.setState({ allComponentTags, allTags });
  }

  @autobind
  setAllTags(allTags) {
    let newAllTags = [];
    R.forEach((item) => {
      const tags = { title: item, value: item, key: item, label: item, children: [] };
      for (let i = 0; i < allTags[item].length; i += 1) {
        const tag = allTags[item][i];
        tags.children.push({
          title: `${item}=${tag}`,
          value: `${item}=${tag}`,
          key: `${item}=${tag}`,
          label: `${item}=${tag}`,
          children: [],
        });
      }
      tags.children = R.sortWith([R.ascend(R.prop('title'))], tags.children);
      newAllTags.push(tags);
    }, R.keys(allTags) || {});
    newAllTags = R.sortWith([R.ascend(R.prop('title'))], newAllTags);
    return newAllTags;
  }

  @autobind
  handleDateRangeChange(timeRange) {
    const [startTime, endTime] = timeRange;
    this.setState({ startTime, endTime });
  }

  @autobind
  handleAddFilter() {
    const { additionalFilterList } = this.state;
    this.setState({
      additionalFilterList: [...additionalFilterList, ''],
    });
  }

  @autobind
  handleDeleteFilter(index) {
    const { additionalFilterList } = this.state;
    this.setState({
      additionalFilterList: R.remove(index, 1, additionalFilterList),
    });
  }

  @autobind
  handleCollectionAndTagChange(value, index) {
    const { additionalFilterList } = this.state;
    const tagsExp = additionalFilterList[index];
    additionalFilterList[index] = tagsExp ? `${tagsExp} AND ${value}=unknown` : `${value}=unknown`;
    this.setState({
      additionalFilterList,
    });
    this.forceUpdate();
  }

  @autobind
  handleCollectionInputChange(e, index) {
    const { additionalFilterList } = this.state;
    additionalFilterList[index] = e.target.value;
    this.setState({
      additionalFilterList,
    });
    this.forceUpdate();
  }

  @autobind
  handleAddInstanceFilterClick() {
    let { logInstanceFilter } = this.state;
    logInstanceFilter = [...logInstanceFilter, { tag: '', value: '' }];
    this.setState({ logInstanceFilter });
  }

  @autobind
  handleDataTypeChange(dataType) {
    console.log(dataType);
    this.setState(
      {
        dataType,
        verified: false,
      },
      () => {
        this.props.saveProjectInfo('Datadog', { dataType }, this.state);
      },
    );
  }

  render() {
    const { intl, hasError, isLoading } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      treeMetricList,
      selectedMetrics,
      startTime,
      endTime,
      processPauseFlag,
      dataType,
      fields,
      tagsExp,
      selectedFields,

      additionalFilterList,
      treeData,
      allComponentTags,
      kpiMetrics,
      componentName,
      allTags,
      logInstanceFilter,
      useHostName,
    } = this.state;
    const isMetric = dataType === 'Metric';

    const appKeyLink = VLink.state(this, 'appKey').check((word) => word, 'The app key is required');
    const apiKeyLink = VLink.state(this, 'apiKey').check((word) => word, 'The api key is required');
    const dataTypeLink = VLink.state(this, 'dataType')
      .check((word) => word, 'The data type is required')
      .onChange(this.handleDataTypeChange);

    const samplingIntervalLink = VLink.state(this, 'samplingInterval').check((word) => {
      return Number(word) > 0 && isInteger(Number(word));
    }, 'The sampling interal is required and it has to be an integer');
    const samplingUnitLink = VLink.state(this, 'samplingUnit').check((word) => word, 'The sampling unit is required');
    const instanceKeyLink = VLink.state(this, 'instanceKey').check((word) => word, 'The instance key is required');

    const hasVerifyError = appKeyLink.error || apiKeyLink.error || dataTypeLink.error;
    const hasErrorMetric =
      hasVerifyError ||
      samplingIntervalLink.error ||
      samplingUnitLink.error ||
      treeMetricList.length === 0 ||
      selectedMetrics.length === 0 ||
      tagsExp.length === 0 ||
      !useHostName ||
      hasError;
    const hasFilterLogInstance = R.find((item) => !item.tag || !item.value, logInstanceFilter);
    const hasErrorAlert =
      hasVerifyError ||
      instanceKeyLink.error ||
      !selectedFields ||
      selectedFields.length === 0 ||
      hasFilterLogInstance ||
      hasError;

    const tProps = {
      treeData,
      value: treeMetricList,
      onChange: this.handleChangeMetric,
      treeCheckable: true,
      showCheckedStrategy: TreeSelect.SHOW_CHILD,
      placeholder: 'Please select metrics',
      style: {
        width: '100%',
      },
    };

    const allTagsProps = {
      treeData: allTags,
      value: tagsExp,
      onChange: this.handleChangeInstance,
      treeCheckable: true,
      showCheckedStrategy: TreeSelect.SHOW_CHILD,
      placeholder: 'Please select Instance',
      style: {
        width: '100%',
      },
    };

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.PublicDataDogIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card className="ui form">
          <div className="field required">
            <label>API Key</label>
            <Input type="password" valueLink={apiKeyLink} />
          </div>
          <div className="field required">
            <label>Application Key</label>
            <Input type="password" valueLink={appKeyLink} />
          </div>
          <div className="field required">
            <label>Data Type</label>
            <Select valueLink={dataTypeLink} style={{ padding: '2px 0' }} options={this.dataTypeOption} />
          </div>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>

        <Card className={`${verified ? 'block' : 'display-none'}`}>
          {isMetric && (
            <div>
              <div className="field" style={{ margin: 15 }}>
                <label>
                  Metrics <span style={{ content: '*', color: '#DB2828' }}>*</span>
                </label>
                <div className="flex-row">
                  {selectedMetrics.length > 500 && (
                    <div className="ui info message" style={{ display: 'block' }}>
                      <p>Selected metrics should be less than 500</p>
                    </div>
                  )}
                </div>
                <div className="flex-row">
                  <TreeSelect {...tProps} />
                </div>
              </div>
              <div className="field" style={{ margin: 15 }}>
                <label>KPI Metrics</label>
                <div className="flex-row">
                  <AntSelect
                    mode="multiple"
                    value={kpiMetrics}
                    onChange={this.handleKPIMetricChange}
                    style={{ width: '100%' }}
                  >
                    {R.map((metric) => {
                      return <AntSelect.Option key={metric}>{metric}</AntSelect.Option>;
                    }, selectedMetrics)}
                  </AntSelect>
                </div>
              </div>
              <div className="field" style={{ margin: 15 }}>
                <label>
                  Sampling Interval <span style={{ content: '*', color: '#DB2828' }}>*</span>
                </label>
                <div className="flex-row">
                  <Input type="number" valueLink={samplingIntervalLink} style={{ width: 200 }} />
                  <Select
                    valueLink={samplingUnitLink}
                    style={{ width: 130, padding: '2px 0' }}
                    options={this.samplingUnit}
                  />
                </div>
              </div>

              <div className="field" style={{ margin: 15 }}>
                <label>Component name</label>
                <div className="flex-row">
                  <AntSelect
                    showSearch
                    value={componentName}
                    onChange={this.handleComponentChange}
                    style={{ width: '100%' }}
                    mode="multiple"
                  >
                    {R.map(
                      (t) => (
                        <AntSelect.Option key={t} value={t}>
                          {t.value}
                        </AntSelect.Option>
                      ),
                      allComponentTags,
                    )}
                  </AntSelect>
                </div>
              </div>
              <div className="field" style={{ margin: 15 }}>
                <label>
                  Instance filters <span style={{ content: '*', color: '#DB2828' }}>*</span>
                </label>
                <div className="flex-row">
                  <TreeSelect {...allTagsProps} />
                </div>
              </div>
              <div className="field" style={{ margin: 15 }}>
                <label>
                  Instance Name <span style={{ content: '*', color: '#DB2828' }}>*</span>
                </label>
                <div className="flex-row" style={{ padding: '4px 0', alignItems: 'center' }}>
                  <AntSelect
                    showSearch
                    value={useHostName}
                    onChange={(useHostName) => this.setState({ useHostName })}
                    style={{ width: 110 }}
                    dropdownMatchSelectWidth={false}
                  >
                    <AntSelect.Option key="Host" value="Host">
                      Host
                    </AntSelect.Option>
                    {/* eslint-disable-next-line react/jsx-boolean-value */}
                    <AntSelect.Option key="Host name" value="Host name">
                      Host name
                    </AntSelect.Option>
                  </AntSelect>
                </div>
              </div>
            </div>
          )}

          {!isMetric && (
            <div className="ui form">
              <div className="field required" style={{ margin: 15 }}>
                <label>Fields to analyze</label>
                <div className="flex-row">
                  <AntSelect
                    mode="multiple"
                    value={selectedFields}
                    onChange={this.handleFieldChange}
                    style={{ width: '100%' }}
                  >
                    {R.map((field) => {
                      return <AntSelect.Option key={field}>{field}</AntSelect.Option>;
                    }, fields)}
                  </AntSelect>
                </div>
              </div>
              <div className="field required" style={{ margin: 15 }}>
                <label>Instance Key</label>
                <div className="flex-row">
                  <Input
                    type="text"
                    valueLink={instanceKeyLink}
                    style={{ width: '100%' }}
                    placeholder="{key} / {key}={regex}"
                  />
                </div>
              </div>
              <div className="field" style={{ margin: 10 }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <label>
                    Instance filter <span style={{ content: '*', color: '#DB2828' }}>*</span>
                  </label>
                  <Button
                    type="primary"
                    size="small"
                    style={{ marginLeft: 15 }}
                    onClick={this.handleAddInstanceFilterClick}
                  >
                    {intl.formatMessage(appButtonsMessages.add)}
                  </Button>
                </div>
                <div>
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <div
                        className="flex-row"
                        style={{ alignItems: 'center', marginTop: index !== 0 ? 10 : 0 }}
                        key={index}
                      >
                        <AntInput
                          value={item.tag}
                          onChange={(e) => {
                            const { logInstanceFilter } = this.state;
                            this.setState({
                              logInstanceFilter: update(logInstanceFilter, {
                                [index]: { $set: { ...item, tag: e.target.value } },
                              }),
                            });
                          }}
                          style={{ width: 200 }}
                        />
                        <div style={{ margin: '0 15px' }}>=</div>
                        <AntInput
                          value={item.value}
                          placeholder="item,item"
                          onChange={(e) => {
                            const { logInstanceFilter } = this.state;
                            this.setState({
                              logInstanceFilter: update(logInstanceFilter, {
                                [index]: { $set: { ...item, value: e.target.value } },
                              }),
                            });
                          }}
                        />
                        {index === 0 && <div style={{ marginLeft: 15, width: 52, flexShrink: 0 }} />}
                        {index > 0 && (
                          <Button
                            type="primary"
                            size="small"
                            style={{ marginLeft: 15 }}
                            onClick={() => {
                              let { logInstanceFilter } = this.state;
                              logInstanceFilter = R.remove(index, 1, logInstanceFilter);
                              this.setState({ logInstanceFilter });
                            }}
                          >
                            {intl.formatMessage(appButtonsMessages.delete)}
                          </Button>
                        )}
                      </div>
                    );
                  }, logInstanceFilter)}
                </div>
              </div>
              <div className="field" style={{ margin: 15 }}>
                <label>Component Name</label>
                <div className="flex-row">
                  <AntSelect
                    value={componentName}
                    showSearch
                    onChange={this.handleComponentChange}
                    style={{ width: '100%' }}
                    mode="multiple"
                  >
                    {R.map(
                      (t) => (
                        <AntSelect.Option key={t} value={t}>
                          {t.replace('tags=', '')}
                        </AntSelect.Option>
                      ),
                      this.state.componentOptions,
                    )}
                  </AntSelect>
                </div>
              </div>
              <div className="field" style={{ margin: 15 }}>
                <label>Historical Date Range</label>
                <div className="flex-row flex-grow flex-center-align">
                  <DatePicker.RangePicker
                    allowClear
                    style={{ width: '50%', margin: '0 8px 0 0' }}
                    value={[startTime, endTime]}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                    onChange={this.handleDateRangeChange}
                  />
                </div>
              </div>
              {!isMetric && (
                <div className="field required" style={{ margin: 15 }}>
                  <label style={{ marginRight: 16 }}>Enable initial processing pause</label>
                  <Checkbox
                    checked={processPauseFlag}
                    onChange={(e) => {
                      this.setState({ processPauseFlag: e.target.checked });
                    }}
                  />
                </div>
              )}

              {!isMetric && (
                <div className="field" style={{ margin: 15 }}>
                  <label>
                    Data collection filter
                    <Button size="small" icon={<PlusCircleOutlined />} onClick={this.handleAddFilter}>
                      Add filters
                    </Button>
                  </label>
                  <div className="flex-col flex-grow">
                    {R.addIndex(R.map)((item, index) => {
                      return (
                        <div
                          key={`${index}filterRow`}
                          className="flex-row flex-center-justify flex-center-align"
                          style={{
                            border: '2px dashed var(--border-color-base)',
                            borderRadius: 16,
                            padding: 12,
                            margin: '6px 0',
                            position: 'relative',
                          }}
                        >
                          {index !== 0 && <span style={{ marginRight: 10 }}>OR</span>}
                          <AntInput value={item} onChange={(e) => this.handleCollectionInputChange(e, index)} />
                          <AntSelect
                            value="AND"
                            showSearch
                            dropdownMatchSelectWidth={280}
                            disabled={item.indexOf('unknown') >= 0}
                            onChange={(value) => this.handleCollectionAndTagChange(value, index)}
                            style={{ width: 86, padding: '0 8px' }}
                          >
                            {R.map(
                              (t) => (
                                <AntSelect.Option key={t} value={t}>
                                  {t.value}
                                </AntSelect.Option>
                              ),
                              fields,
                            )}
                          </AntSelect>
                          <div
                            style={{
                              position: 'absolute',
                              top: -15,
                              right: -15,
                              height: 30,
                              width: 30,
                              cursor: 'pointer',
                            }}
                            className="flex-row flex-center-align flex-center-justify content-bg"
                            onClick={(e) => this.handleDeleteFilter(index)}
                          >
                            <MinusCircleOutlined style={{ fontSize: 18 }} />
                          </div>
                        </div>
                      );
                    }, additionalFilterList)}
                  </div>
                </div>
              )}
            </div>
          )}
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={isMetric ? hasErrorMetric : hasErrorAlert}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const DataDogBatchSetting = injectIntl(DataDogBatchSettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(DataDogBatchSetting);
