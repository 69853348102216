/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import { Container } from '../../../lib/fui/react';
import { State } from '../../../common/types';
import { createLoadAction } from '../../../common/app/actions';
import { ActionTypes } from '../../../common/settings/actions';

import { eventMessages } from '../../../common/metric/messages';

type Props = {
  intl: Object,
  createLoadAction: Function,
  componentVersions: Object,
};

class SystemGeneral extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.createLoadAction(ActionTypes.LOAD_SYSTEM_GENERAL_INFO);
  }

  render() {
    const { intl, componentVersions } = this.props;
    const { engine, ui, licenseInfo } = componentVersions || {};
    const isEffective = get(licenseInfo, ['isEffective'], false);
    const hasError = false;
    return (
      <Container fullHeight className="overflow-y-auto">
        <form className={`ui ${hasError ? 'error' : ''} form`} style={{ fontSize: 12, width: 800 }}>
          <h3>{intl.formatMessage(eventMessages.componentVersion)}</h3>
          <div className="select field" style={{ width: '100%', fontSize: 14, marginBottom: 8 }}>
            <span style={{ display: 'inline-block', width: 180, fontWeight: 'bold' }}>
              {intl.formatMessage(eventMessages.coreEngine)}
            </span>
            <span>{engine}</span>
          </div>
          <div className="select field" style={{ width: '100%', fontSize: 14, marginBottom: 8 }}>
            <span style={{ display: 'inline-block', width: 180, fontWeight: 'bold' }}>
              {intl.formatMessage(eventMessages.webUI)}
            </span>
            <span>{ui}</span>
          </div>
          <h3>{intl.formatMessage(eventMessages.license)}</h3>
          <div className="select field" style={{ width: '100%', fontSize: 14, marginBottom: 8 }}>
            <span style={{ display: 'inline-block', width: 180, fontWeight: 'bold' }}>
              {intl.formatMessage(eventMessages.effective)}
            </span>
            {isEffective && <span style={{ color: '#42d12d' }}>Yes</span>}
            {!isEffective && <span style={{ color: 'red' }}>No</span>}
          </div>
          <div className="select field" style={{ width: '100%', fontSize: 14, marginBottom: 8 }}>
            <span style={{ display: 'inline-block', width: 180, fontWeight: 'bold' }}>
              {intl.formatMessage(eventMessages.license)}
            </span>
            <span>{get(licenseInfo, ['licensee'], '')}</span>
          </div>
          <div className="select field" style={{ width: '100%', fontSize: 14, marginBottom: 8 }}>
            <span style={{ display: 'inline-block', width: 180, fontWeight: 'bold' }}>
              {intl.formatMessage(eventMessages.nodeLimit)}
            </span>
            <span>{get(licenseInfo, ['nodeLimit'], '')}</span>
          </div>
          <div className="select field" style={{ width: '100%', fontSize: 14, marginBottom: 8 }}>
            <span style={{ display: 'inline-block', width: 180, fontWeight: 'bold' }}>
              {intl.formatMessage(eventMessages.expirationDate)}
            </span>
            <span>{get(licenseInfo, ['expirationDate'], '')}</span>
          </div>
          <div className="select field" style={{ width: '100%', fontSize: 14, marginBottom: 8 }}>
            <span style={{ display: 'inline-block', width: 180, fontWeight: 'bold' }}>
              {intl.formatMessage(eventMessages.supportContact)}
            </span>
            <span>{get(licenseInfo, ['supportContact'], '')}</span>
          </div>
        </form>
      </Container>
    );
  }
}

export default connect(
  (state: State) => {
    const { componentVersions } = state.settings;
    return { componentVersions };
  },
  { createLoadAction },
)(SystemGeneral);
