import React, { useState, useEffect, useMemo } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { Spin, message, Button } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults, buildUrl, parseLocation } from '../../../common/utils';
import { getCacheData, setCacheData } from '../utils';
import fetchPost from '../../../common/apis/fetchPost';
import BaseUrls from '../../app/BaseUrls';
import { eventMessages } from '../../../common/metric/messages';
import { DashboardMessages } from '../../../common/dashboard/messages';
import { Popover } from '../../../lib/fui/react';

const handleIsNil = (value) => {
  return R.isNil(value) ? 'N/A' : value;
};
const getViewData = (view) => (view || view === 0 ? `${view}` : 'N/A');
const millsToMins = (ms) => (ms ? (Number(ms) / (1000 * 60)).toFixed(1) : ms);
const getPercentage = (value) => {
  if (value === -1) {
    return value;
  } else {
    return `${(value * 100).toFixed(1)} %`;
  }
};

const IncidentPredictionAccuracy = (props: Object) => {
  const {
    intl,
    summaryObj,
    systemId,
    credentials,
    startTime,
    endTime,
    systemIdsWithShare,
    customerName,
    environmentId,
    location,
  } = props;
  const query = parseLocation(location);
  const [isLoading, setLoading] = useState(false);
  const [incident, setIncident] = useState({
    truePositiveCount: 0,
    falsePositiveCount: 0,
    truePositiveRate: 0,
    accuracy: 0,
  });

  const parseData = (data) => {
    const system = R.find((item) => item.systemKey === systemId, data || []);
    if (!system) {
      return;
    }

    const { truePositiveCount, falsePositiveCount, truePositiveRate, accuracy } = system;
    setIncident({
      truePositiveCount: handleIsNil(truePositiveCount),
      falsePositiveCount: handleIsNil(falsePositiveCount),
      truePositiveRate: getPercentage(truePositiveRate),
      accuracy: getPercentage(accuracy),
    });
  };

  const fetchStatistics = () => {
    const startDate = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endDate = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();

    const filterSystem = R.filter((item) => item.id === systemId, systemIdsWithShare || []);

    const params = {
      ...credentials,
      systemIdsWithShare: JSON.stringify(filterSystem),
      startTime: startDate,
      endTime: endDate,
      cacheKey: 'IncidentPredictionAccuracy',
    };
    const apiData = getCacheData(params);
    if (apiData) {
      parseData(apiData);
      return;
    }

    setLoading(true);
    fetchPost(getEndpoint('dashboard-summary'), R.clone(params))
      .then((data) => {
        setLoading(false);
        parseData(data);
        setCacheData(params, data);
      })
      .catch((e) => {
        setLoading(false);
        if (e?.code >= 500) {
          console.error(e.message || String(e));
        } else {
          message.error(e.message || String(e));
        }
      });
  };

  const handleMatchedIncidentClick = () => {
    if (query?.saveFlag === 'true') {
      message.warning(intl.formatMessage(eventMessages.notSaveDashboardMessage));
      return;
    }

    const params = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,
    };
    window.open(buildUrl(BaseUrls.GlobalSystemPredictionMatched, {}, params), '_blank');
  };

  const noIncidentRender = (content) => {
    return (
      <Popover content={content} mouseEnterDelay={0.3} placement="top">
        <span className="clickable">N/A</span>
      </Popover>
    );
  };

  const viewData = useMemo(() => {
    return R.find((item) => item.systemKey === systemId, summaryObj || []) || {};
  }, [summaryObj]);

  useEffect(() => {
    fetchStatistics();
  }, [systemId]);

  const { truePositiveCount, falsePositiveCount, truePositiveRate, accuracy } = incident;

  return (
    <Spin spinning={isLoading} wrapperClassName="spin-base overflow-x-hidden overflow-y-auto full-width full-height">
      <div className="full-height flex-col">
        <div className="font-14 bold flex-row flex-space-between flex-center-align">
          <span>{intl.formatMessage(DashboardMessages.predictionStatistics)}</span>
          <Button
            className="dashButton"
            size="small"
            icon={<UnorderedListOutlined />}
            style={{ marginLeft: 10 }}
            onClick={() => handleMatchedIncidentClick()}
          >
            {intl.formatMessage(eventMessages.details)}
          </Button>
        </div>
        <div className="flex-row flex-center-align full-width flex-center-justify flex-grow" style={{ marginTop: 8 }}>
          <div className="flex-col" style={{ width: 70 + 20 }}>
            <div className="flex-col" style={{ height: 44 }}>
              <Popover
                content={intl.formatMessage(DashboardMessages.truePositives)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
                  TP
                </div>
              </Popover>
              <div className="hidden-line-with-ellipsis" style={{ fontSize: 18 }}>
                {truePositiveCount}
              </div>
            </div>
            <div className="flex-col" style={{ marginTop: 8, height: 44 }}>
              <Popover
                content={intl.formatMessage(DashboardMessages.predictionAccuracy)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
                  {intl.formatMessage(DashboardMessages.accuracy)}
                </div>
              </Popover>
              <div className="hidden-line-with-ellipsis" style={{ fontSize: 18 }}>
                {accuracy === -1 ? noIncidentRender('No incident predicted') : accuracy}
              </div>
            </div>
          </div>
          <div className="flex-col" style={{ width: 70 + 20 }}>
            <div className="flex-col" style={{ height: 44 }}>
              <Popover
                content={intl.formatMessage(DashboardMessages.falsePositives)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
                  FP
                </div>
              </Popover>
              <div className="hidden-line-with-ellipsis" style={{ fontSize: 18 }}>
                {falsePositiveCount}
              </div>
            </div>
            <div className="flex-col" style={{ marginTop: 8, height: 44 }}>
              <Popover
                content={intl.formatMessage(DashboardMessages.truePositiveRate)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
                  Coverage
                </div>
              </Popover>
              <div className="hidden-line-with-ellipsis" style={{ fontSize: 18 }}>
                {truePositiveRate === -1 ? noIncidentRender('No incident detected') : truePositiveRate}
              </div>
            </div>
          </div>
          <div className="flex-col" style={{ width: 80 + 20 }}>
            <div className="flex-col" style={{ height: 44 }}>
              <Popover
                content={intl.formatMessage(DashboardMessages.predictionLeadTime)}
                mouseEnterDelay={0.3}
                placement="top"
              >
                <div className="clickable" style={{ color: 'var(--text-color)', width: 'fit-content' }}>
                  {intl.formatMessage(eventMessages.leadTime)}
                </div>
              </Popover>
              <div className="hidden-line-with-ellipsis" style={{ fontSize: 18 }}>
                {getViewData(millsToMins(viewData.avgLeadTime))}
                {getViewData(millsToMins(viewData.avgLeadTime)) !== 'N/A' && (
                  <span style={{ fontSize: 14, marginLeft: 4 }}>Min</span>
                )}
              </div>
            </div>
            <div className="flex-col" style={{ marginTop: 8, height: 44 }} />
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default IncidentPredictionAccuracy;
