import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { get, round } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { sortableHandle } from 'react-sortable-hoc';
import { Empty, Spin } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';

import { BaseUrls } from '../../app/Constants';
import { Defaults, buildUrl } from '../../../common/utils';
import { MoveIcon } from '../../../lib/fui/icons';

import { logMessages } from '../../../common/log/messages';

const DragHandle = sortableHandle(() => (
  <span className="hover-display-item" style={{ cursor: 'move', position: 'absolute', right: 8, top: 8 }}>
    <MoveIcon style={{ fontSize: 16 }} />
  </span>
));

const InsightsPredictionAccuracy = (props) => {
  // eslint-disable-next-line
  const { intl, name } = props;
  const {
    // eslint-disable-next-line
    push,
    // eslint-disable-next-line
    updateLastActionInfo,
    // eslint-disable-next-line
    credentials,
    // eslint-disable-next-line
    userInfo,

    // eslint-disable-next-line
    refresh,
    // eslint-disable-next-line
    refreshTime,
    // eslint-disable-next-line
    environmentId,
    // eslint-disable-next-line
    systemId,
    // eslint-disable-next-line
    customerName,
    // eslint-disable-next-line
    startTime,
    // eslint-disable-next-line
    globalInfo,
    // eslint-disable-next-line
    updateState,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [accuracyInfo, setAccuracy] = useState({
    predictionAccuracy: 0,
    recall: 0,
    f1score: 0,

    predictionAccuracyTrend: 0,
    recallTrend: 0,
    f1scoreTrend: 0,
  });

  useEffect(() => {
    let cancel = false;

    // UNSAFE_componentWillReceiveProps
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (
      ((userInfo.isAdmin && customerName) || !userInfo.isAdmin) &&
      environmentId &&
      systemId &&
      systemInfo &&
      startTime
    ) {
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      const startTimestampYesterday = moment
        .utc(startTime, Defaults.DateFormat)
        .subtract(1, 'days')
        .startOf('days')
        .valueOf();
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();

      setLoading(true);
      updateState({ isLoadingOverall: true });
      updateLastActionInfo();
      Promise.all([
        fetchGet(getEndpoint('insightsAccuracyStatsServlet', 1), {
          ...credentials,
          environmentName: environmentId,
          systemName: systemId,
          customerName: systemInfo.ownerUserName,
          dayTimeMillis: startTimestampYesterday,
        }),
        fetchGet(getEndpoint('insightsAccuracyStatsServlet', 1), {
          ...credentials,
          environmentName: environmentId,
          systemName: systemId,
          customerName: systemInfo.ownerUserName,
          dayTimeMillis: startTimestamp,
        }),
      ])
        .then((results) => {
          if (cancel) return;

          const predictionAccuracyYesterday = round(get(results[0], ['rollingAverage', 'predictionAccuracy'], 0), 4);
          const recallYesterday = round(get(results[0], ['rollingAverage', 'recall'], 0), 4);
          const f1scoreYesterday = round(get(results[0], ['rollingAverage', 'f1score'], 0), 4);

          const predictionAccuracy = round(get(results[1], ['rollingAverage', 'predictionAccuracy'], 0), 4);
          const recall = round(get(results[1], ['rollingAverage', 'recall'], 0), 4);
          const f1score = round(get(results[1], ['rollingAverage', 'f1score'], 0), 4);

          const predictionAccuracyTrend = predictionAccuracy - predictionAccuracyYesterday;
          const recallTrend = recall - recallYesterday;
          const f1scoreTrend = f1score - f1scoreYesterday;

          // down saving info
          const { totalDowntimeSaving, totalMatchOverallConsolidated, avgLeadTimeOverall } = get(
            results[1],
            ['overall'],
            {},
          );

          setAccuracy({
            predictionAccuracy,
            recall,
            f1score,

            predictionAccuracyTrend,
            recallTrend,
            f1scoreTrend,
          });
          updateState({
            isLoadingOverall: false,
            overallInfo: {
              totalDowntimeSaving,
              totalMatchOverallConsolidated,
              avgLeadTimeOverall,
            },
          });
          setHasData(true);
          setLoading(false);
        })
        .catch((err) => {
          if (cancel) return;
          console.error(err);
          setAccuracy({
            predictionAccuracy: 0,
            recall: 0,
            f1score: 0,

            predictionAccuracyTrend: 0,
            recallTrend: 0,
            f1scoreTrend: 0,
          });
          updateState({
            isLoadingOverall: false,
            overallInfo: {},
          });
          setHasData(false);
          setLoading(false);
        });
    }

    return () => {
      cancel = true;
    };
  }, [refresh, refreshTime, environmentId, systemId, customerName]);

  return (
    <div
      className="full-height full-width flex-col health-card hover-display"
      style={{ backgroundColor: 'white', borderRadius: '2em', padding: '8px 8px', position: 'relative' }}
    >
      <div
        className="flex-row flex-center-justify bold link"
        style={{ textAlign: 'center', margin: '0 14px 8px 14px', height: 34 }}
        onClick={() => {
          push(buildUrl(BaseUrls.GlobalSystemPrediction, {}, { startTime, customerName, environmentId, systemId }));
        }}
      >
        {name}
      </div>
      <DragHandle />

      <Spin spinning={isLoading} wrapperClassName="spin-full-height flex-grow">
        {!hasData && (
          <div className="full-height flex-row flex-center-align flex-center-justify">
            <Empty imageStyle={{ height: 60 }} />
          </div>
        )}
        {hasData && (
          <div className="full-height flex-col flex-center-align flex-center-justify">
            <div className="flex-col">
              <div className="flex-row font-16">
                <div className="light-label bold" style={{ width: 100, marginBottom: 8 }}>
                  {intl.formatMessage(logMessages.precision)}
                </div>
                <div style={{ width: 60 }}>{numeral(accuracyInfo.predictionAccuracy).format('0.0%')}</div>
                <div style={{ color: accuracyInfo.predictionAccuracyTrend >= 0 ? '#32C880' : '#FF5143' }}>
                  {numeral(accuracyInfo.predictionAccuracyTrend).format('+0.0%')}
                </div>
              </div>
              <div className="flex-row font-16">
                <div className="light-label bold" style={{ width: 100, marginBottom: 8 }}>
                  {intl.formatMessage(logMessages.recall)}
                </div>
                <div style={{ width: 60 }}>{numeral(accuracyInfo.recall).format('0.0%')}</div>
                <div style={{ color: accuracyInfo.recallTrend >= 0 ? '#32C880' : '#FF5143' }}>
                  {numeral(accuracyInfo.recallTrend).format('+0.0%')}
                </div>
              </div>
              {false && (
                <div className="flex-row font-16">
                  <div className="light-label bold" style={{ width: 100, marginBottom: 8 }}>
                    F1 Score
                  </div>
                  <div style={{ width: 60 }}>{numeral(accuracyInfo.f1score).format('0.0%')}</div>
                  <div style={{ color: accuracyInfo.f1scoreTrend >= 0 ? '#32C880' : '#FF5143' }}>
                    {numeral(accuracyInfo.f1scoreTrend).format('+0.0%')}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default InsightsPredictionAccuracy;
