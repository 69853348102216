/* eslint-disable react/prop-types */
/**
 * SystemInsights dashboard.
 * here are two important concepts: widget、widgetLayout
 * get system priority: params.systemId > dashboardWidget.key > defaultSystemId
 * system distinguish two types: default system 、 othersystem
 * and the system has three types: kubernetes、cloud cost、cp setting
 */
import React, { useContext, useEffect, useState, createContext, useReducer, useRef, useMemo } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Button, Tooltip, message, Spin } from 'antd';
import * as R from 'ramda';
import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { get, isObject } from 'lodash';
import moment from 'moment/moment';
import { injectIntl } from 'react-intl';
import { push, replace, goBack } from 'react-router-redux';
import { connect } from 'react-redux';

import SystemInsightWidgets from './widgetlist';
import SystemHeader from './widgets/SystemHeader';
import { Container } from '../../lib/fui/react';
import { getWidgetInfos } from './widgetinfos';
import fetchGet from '../../common/apis/fetchGet';
import getEndpoint from '../../common/apis/getEndpoint';
import fetchPost from '../../common/apis/fetchPost';
import { buildLocation, Defaults, parseJSON, parseLocation, sleep, downloadPdfV2 } from '../../common/utils';
import { hideAppLoader, setShowDetailsFrame } from '../../common/app/actions';
import WidgetSettingsModal from './widgets/WidgetSettingsModal';
import { appMessages } from '../../common/app/messages';

import './style/index.less';

const ReactGridLayout = WidthProvider(RGL);
const DashCreateContext = createContext('dash');
const localWidgetInfos = getWidgetInfos();

const SystemInsightsCore = (props) => {
  const dashContext = useContext(DashCreateContext);
  const {
    userInfo,
    credentials,
    location,
    intl,
    favorites,
    hideAppLoader,
    systemsMap,
    globalInfo,
    allProjects,
    replace,
  } = dashContext;
  const params = {
    userInfo,
    credentials,
    location,
    intl,
    favorites,
    hideAppLoader,
    systemsMap,
    globalInfo,
    allProjects,
    replace,
  };
  return <SystemInsights {...{ ...props, ...params, dashContext }} />;
};

const SystemInsights = (props) => {
  const {
    userInfo,
    credentials,
    location,
    intl,
    favorites,
    hideAppLoader,
    systemsMap,
    globalInfo,
    allProjects,
    replace,
    dashContext,
  } = props;
  const currentLayoutRef = useRef([]);
  const [reloadTime, setReloadTime] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(moment.utc().valueOf());
  const query = parseLocation(location);
  const { startTime, endTime, environmentId, systemId, customerName, isCreate } = query;
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    showSettings: false,
    widgetSettings: {},
    settingsWidgetId: null,
    settingsLayoutWidgetKey: null,
    currentWidgetId: '',
    isDesignMode: false,
    summaryObj: [],
    showWidgetControl: true,
    currentLayout: [],
    editable: false,
    instanceDisplayNameMap: {},
    systemInstanceDisplayNameMap: {},
    systemSet: [],
    systemStatisticsMap: {},
    exportPDFing: false,
  });
  const {
    showSettings,
    widgetSettings,
    settingsWidgetId,
    settingsLayoutWidgetKey,
    isDesignMode,
    summaryObj,
    showWidgetControl,
    currentLayout,
    editable,
    currentWidgetId,
    instanceDisplayNameMap,
    systemInstanceDisplayNameMap,
    exportPDFing,
  } = state;
  const [curCustomerSystemList, setCurCustomerSystemList] = useState([]);
  // #region ============== base info ============================
  const defaultSystemId = systemId;
  const newSystemsMap = R.clone(systemsMap);
  let newGlobalInfo = [];
  let dashboardSystemFramework = sessionStorage.getItem('dashboardSystemFramework');

  if (dashboardSystemFramework) {
    dashboardSystemFramework = JSON.parse(dashboardSystemFramework) || {};
  }

  newGlobalInfo = R.map((item) => {
    const newSystemList = [...item.systemList];
    const newSystemIds = [...item.systemIds];
    if (dashboardSystemFramework?.id && !item.systemIds.includes(dashboardSystemFramework.id)) {
      newSystemList.push(dashboardSystemFramework);
      newSystemIds.push(dashboardSystemFramework.id);
      newSystemsMap[dashboardSystemFramework.id] = dashboardSystemFramework;
    }
    if (curCustomerSystemList.length) {
      R.forEach((curCustomerSystem) => {
        if (!item.systemIds.includes(curCustomerSystem.id)) {
          newSystemList.push(curCustomerSystem);
          newSystemIds.push(curCustomerSystem.id);
          newSystemsMap[curCustomerSystem.id] = curCustomerSystem;
        }
      }, curCustomerSystemList);
    }
    return {
      ...item,
      systemIds: newSystemIds,
      systemList: newSystemList,
    };
  }, globalInfo);

  const environment = R.find((e) => e.id === environmentId, newGlobalInfo || []);
  const systemList = get(environment, 'systemList', []); // get the system list of the current user has access to
  const getSystemInfo = (systemId) => {
    let curSystemInfo = R.find((system) => system.id === systemId, systemList);
    if (curSystemInfo) {
      curSystemInfo.customerName = curSystemInfo.customerName || curSystemInfo.ownerUserName || customerName;
      curSystemInfo.hasPermission = true;
    } else {
      curSystemInfo = R.find((system) => system.systemName === systemId, state.systemSet || []);
      if (curSystemInfo) {
        curSystemInfo.customerName = curSystemInfo.userName || customerName;
        curSystemInfo.systemId = curSystemInfo.systemName;
      }
    }
    return curSystemInfo || {};
  };
  const systemIdsWithShare = R.map(
    (item) => ({ id: item.id, customerName: item.ownerUserName, ownerUserName: item.ownerUserName }),
    systemList || [],
  );

  let defaultSystemInfo = getSystemInfo(systemId);
  defaultSystemInfo = {
    ...defaultSystemInfo,
    customerName: defaultSystemInfo?.customerName || defaultSystemInfo?.ownerUserName || customerName,
  };
  const { ownerUserName } = defaultSystemInfo;

  const { userName, isAdmin, isLocalAdmin } = userInfo || {};
  const startTimeObjValueOf = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
  const endTimeObjValueOf = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
  const Cols = 23; // How many columns the screen is divided into
  // #endregion ============== base info ============================

  // #region ================= calculated data ======================
  const systemHasKuberneteProject = (system) => {
    return !!R.find(
      (item) => R.includes('Kubernetes', item?.cloudType || ''),
      newSystemsMap[system.systemName]?.projectDetailsList || [],
    );
  };

  const systemHasCPSetting = (system) => {
    return !!state.systemStatisticsMap[system.systemName]?.hasCPSetting;
  };

  const systemHasCost = (system) => {
    return !!state.systemStatisticsMap[system.systemName]?.hasCost;
  };

  const systemHasCloudCost = (system) => {
    return !!state.systemStatisticsMap[system.systemName]?.isCloudCost;
  };

  const kuberneteProjectSystems = R.filter(systemHasKuberneteProject, state.systemSet);
  const hasCPSettingSystems = R.filter(systemHasCPSetting, state.systemSet);
  const hasCostSystems = R.filter(systemHasCost, state.systemSet);
  const hasCloudCostSystems = R.filter(systemHasCloudCost, state.systemSet);
  const isKubernetes = !!kuberneteProjectSystems.length;
  // #endregion =============== calculated data ==================

  // 所有的组件配置信息
  const widgetInfos = useMemo(() => {
    if (state.systemSet.length > 0) {
      const WidgetInfos = getWidgetInfos({
        hasCost: hasCostSystems.length,
        hasCPSetting: hasCPSettingSystems.length,
        isKubernetes: kuberneteProjectSystems.length,
        isCloudCost: hasCloudCostSystems.length,
      });
      return WidgetInfos;
    }
    return localWidgetInfos;
  }, [state.systemStatisticsMap, state.systemSet]);

  // #region ==============calculate instance properties =============
  // 同一个类型下的 并且currentLayout 不存在的 system
  const getSystemOptions = (widgetId) => {
    const widgetInfo = widgetInfos[widgetId];
    const { systemSet } = state;
    let filtedSystems = systemSet;
    if (widgetInfo) {
      const hasLayoutWidget = R.filter((l) => l.i === widgetId, currentLayoutRef.current || []);
      const hasSelectSystemNames = R.map((l) => l.params?.systemId || defaultSystemId, hasLayoutWidget);
      if (widgetInfo.isKubernetes) {
        filtedSystems = R.filter(systemHasKuberneteProject, systemSet);
      } else if (widgetInfo.isCloudCost) {
        filtedSystems = R.filter(systemHasCloudCost, systemSet);
      } else if (widgetInfo.hasCPSetting) {
        filtedSystems = R.filter(systemHasCPSetting, systemSet);
      } else {
        filtedSystems = state.systemSet;
      }
      filtedSystems = R.filter((system) => !R.includes(system.systemName, hasSelectSystemNames), filtedSystems);
    }
    const systemOptions = R.map((system) => {
      const curSystemInfo = getSystemInfo(system.systemName);
      return {
        value: system.systemName,
        label: curSystemInfo?.name || system.name,
        hasPermission: system?.hasPermission || curSystemInfo.hasPermission,
      };
    }, filtedSystems);
    return R.filter((e) => e.label, systemOptions);
  };

  const getKeyByWidgetLayout = (widgetLayout) => {
    return (
      widgetLayout.i + (widgetLayout.key ? widgetLayout.key : '') + (widgetLayout.addTime ? widgetLayout.addTime : '')
    );
  };

  const getWidgetLayoutByKey = (layoutWidgetKey) => {
    const widgetLayout = R.find((l) => {
      const key = getKeyByWidgetLayout(l);
      return key === layoutWidgetKey;
    }, currentLayout);
    return widgetLayout;
  };

  const getSystemOptionBySystemId = (inputSystemId) => {
    if (inputSystemId) {
      const curSystemInfo = getSystemInfo(inputSystemId);
      return { value: inputSystemId, label: curSystemInfo?.name };
    }
  };

  const getSystemIdByWidgetLayout = (widgetLayout) => {
    return widgetLayout.params?.systemId || widgetLayout.key || defaultSystemId;
  };

  const getCanSlectSystemOptions = (settingsWidgetId, settingsLayoutWidgetKey) => {
    const seettingLayoutWidgetSystemId = getSystemIdByWidgetLayout(getWidgetLayoutByKey(settingsLayoutWidgetKey));
    return R.uniqBy(
      (o) => o.value,
      R.filter(
        (s) => s.label,
        [...getSystemOptions(settingsWidgetId), getSystemOptionBySystemId(seettingLayoutWidgetSystemId)],
      ),
    );
  };

  // Get the label/tooltip and other locations of the pie char
  const getPopUpPosition = (widgetInfo, pos = [], size) => {
    const w = R.find((w) => getKeyByWidgetLayout(w) === widgetInfo?.key, currentLayoutRef.current || []) || {};
    const { x: positionX = 0, y: positionY = 0 } = w;
    const boxWidth = size?.contentSize[0] || 0; // tooltips width
    const boxHeight = size?.contentSize[1] || 0; // tooltips height
    const isRight = positionX > 10;
    const isBottom = positionY > 9;

    let pointX = (pos[0] || 0) + 10;
    let pointY = (pos[1] || 0) + 10;
    if (isRight) pointX -= boxWidth + 10;
    if (isBottom) pointY -= boxHeight + 10;
    return [pointX, pointY];
  };
  // #endregion ==============calculate instance properties =============
  const widgetProps = {
    ...props,
    ...dashContext,
    systemIdsWithShare,
    ...query,
    isCreate: isCreate === 'true',
    summaryObj,
    refresh,
    systemList,
    editable,
    globalInfo: newGlobalInfo,
    systemsMap: newSystemsMap,
    allProjects,
    isKubernetes,
    instanceDisplayNameMap,
    getPopUpPosition,
  };

  // #region ============== hooks =============
  // when location change do somthing
  useEffect(() => {
    const query = parseLocation(location);
    const { saveFlag } = query;
    if (R.keys(state.systemStatisticsMap).length > 0 && saveFlag === 'true') {
      // change edit mode will don‘t reload
      setIsMounted(true);
      return;
    } else {
      hideAppLoader();
      if (newGlobalInfo.length === 0 && systemList.length === 0) return;
      setIsMounted(true);
      loadData();
    }
    // eslint-disable-next-line consistent-return
    return () => {
      setIsMounted(false);
    };
  }, [location]);

  // 可以选择的组件信息 小于可选system 数量时禁用
  const candidateWidgets = useMemo(() => {
    let newCandidateWidgets = R.map((w) => {
      const layoutCurTypeWidgets = R.filter((l) => l.i === w.id, currentLayout);
      let leftCount = 0;
      if (w.isKubernetes) {
        leftCount = kuberneteProjectSystems.length - layoutCurTypeWidgets.length;
      } else if (w.isCloudCost) {
        leftCount = Math.max(hasCostSystems.length, hasCloudCostSystems.length) - layoutCurTypeWidgets.length;
      } else if (w.hasCPSetting) {
        leftCount = hasCPSettingSystems.length - layoutCurTypeWidgets.length;
      } else {
        leftCount = state.systemSet.length - layoutCurTypeWidgets.length;
      }
      w.leftCount = Math.max(leftCount, 0);
      return w;
    }, R.values(widgetInfos));
    newCandidateWidgets = R.filter((w) => w.leftCount > 0 || w.disable, newCandidateWidgets);
    return newCandidateWidgets;
  }, [state.systemStatisticsMap, widgetInfos, currentLayout]);
  // #endregion ============== hooks =============

  // #region ============= asynchronous request api ========

  const loadSystemStatistics = async (systemSet) => {
    try {
      const promiseArr = [];
      R.forEach((systemInfo) => {
        promiseArr.push(
          fetchGet(getEndpoint('dashboard-statistics'), {
            ...credentials,
            customerName: systemInfo?.userName || customerName,
            systemName: systemInfo.systemName,
            startTime: startTimeObjValueOf,
            endTime: endTimeObjValueOf,
          }).catch((e) => {
            return {};
          }),
        );
      }, systemSet);
      const systemStatisticSet = await Promise.all(promiseArr);
      const systemStatisticsMap = {};
      R.addIndex(R.forEach)((systemInfo, index) => {
        const currentViewDataStatistics = systemStatisticSet[index] || {};
        const { avgCost, allCost } = currentViewDataStatistics;
        const { projectDetailsList } = systemsMap[systemInfo.systemName] || {};
        const { hasCPSetting = false, hasCost = false } =
          R.find((system) => system.id === systemInfo.systemName, systemList) || {};

        const hasLogProject = Boolean(R.find((findOnly) => findOnly.dataType !== 'Metric', projectDetailsList || []));
        systemStatisticsMap[systemInfo.systemName] = {
          currentViewDataStatistics,
          isCloudCost: (avgCost || avgCost === 0) && allCost,
          hasCloudCost: (avgCost || avgCost === 0) && allCost === false,
          hasLogProject,
          hasCPSetting,
          hasCost,
        };
      }, systemSet);
      setState({
        systemStatisticsMap,
      });
    } catch (e) {
      console.error(e.message || String(e));
    }
  };

  const loadInstanceDisplayName = async (systemSet) => {
    const promiseArr = [];
    R.forEach((systemInfo) => {
      promiseArr.push(
        fetchGet(getEndpoint('info/metadataAggregate', 2), {
          ...credentials,
          startTime: startTimeObjValueOf,
          endTime: endTimeObjValueOf,
          customerName: systemInfo?.userName || customerName,
          systemName: systemInfo.systemName,
          anomalyInstanceOnly: false,
        }),
      );
    }, systemSet);
    const instanceDisplayNameResultSet = await Promise.all(promiseArr);
    const systemInstanceDisplayNameMap = {};
    R.addIndex(R.forEach)((systemInfo, index) => {
      const data = instanceDisplayNameResultSet[index] || {};
      const instanceDisplayNameMap = {};
      const { success } = data || {};
      if (success || success === undefined) {
        const { aggregatedResult } = data || {};
        const { globalViewInstanceInfoResult } = aggregatedResult || {};
        const instancesByDay = globalViewInstanceInfoResult?.g || {};
        const projectInfo = globalViewInstanceInfoResult?.p?.projectInfoList || [];

        R.forEachObjIndexed((instance, key) => {
          const { ip, idn } = instance || {};
          instanceDisplayNameMap[key] = idn;
          R.forEach((item) => {
            const { projectName, userName } = projectInfo[item.i] || {};
            instanceDisplayNameMap[`${projectName}-${userName}-${key}`] = idn;
          }, ip?.i || []);
        }, instancesByDay);
      }
      systemInstanceDisplayNameMap[systemInfo.systemName] = instanceDisplayNameMap;
      setState({
        systemInstanceDisplayNameMap,
      });
    }, systemSet);
  };

  const fetchSystemSummary = async () => {
    try {
      return await fetchPost(getEndpoint('dashboard-summary'), {
        ...credentials,
        systemIdsWithShare: JSON.stringify(systemIdsWithShare),
        startTime: startTimeObjValueOf,
        endTime: endTimeObjValueOf,
      });
    } catch (e) {
      console.error(e.message || String(e));
      return {};
    }
  };

  const fetchLayoutData = async () => {
    console.log('[IF] fetchLayoutData', new Date());
    const result = await fetchGet(getEndpoint('dashboardorder'), {
      targetUser: customerName || userName,
      customerName: defaultSystemInfo?.customerName || customerName,
      systemName: defaultSystemId,
      dashboardName: query?.dashBoardName || '',
    });
    const { order, editable = false } = result || {};
    // const { order, editable = false } = dashboardOrderMockRes;
    if (order) {
      let orderObj = {};
      try {
        orderObj = JSON.parse(order);
        const { dashboardOrderSet, otherSystemSet } = orderObj.dashboardWidgetOrder
          ? orderObj.dashboardWidgetOrder || {}
          : orderObj || {};

        const systemDisplayMapping = orderObj.systemDisplayNameMap || {};

        const newLayout = [];
        R.forEach((x) => {
          const { l, dashboardWidget } = x;
          // Check the widget is valid
          if (widgetInfos[l] && dashboardWidget && isObject(dashboardWidget)) {
            const widgetLayoutParams = dashboardWidget.params ? JSON.parse(dashboardWidget.params) : null;
            dashboardWidget.params = widgetLayoutParams;
            dashboardWidget.minW = widgetInfos[l].minW || widgetInfos[l].width;
            dashboardWidget.minH = widgetInfos[l].minH || widgetInfos[l].height;
            const widgetLayoutKey = dashboardWidget.key || dashboardWidget.params?.systemId || defaultSystemId; // dashboardWidget.key  is uniq under same label(l)
            newLayout.push({ i: l, ...(dashboardWidget || {}), key: widgetLayoutKey });
          }
        }, dashboardOrderSet || []);
        console.log('[IF] fetchLayoutData got layout', new Date());
        const newOtherSystemSet = otherSystemSet || [
          {
            systemName: defaultSystemId,
            userName: defaultSystemInfo?.customerName,
            environmentName: defaultSystemInfo?.environmentName,
          },
        ];
        R.forEach((system) => {
          system.name = systemDisplayMapping[system.systemName]?.displayName;
          system.hasPermission = systemDisplayMapping[system.systemName]?.hasPermission;
        }, newOtherSystemSet);
        return {
          newLayout,
          editable,
          otherSystemSet: newOtherSystemSet,
        };
      } catch (e) {
        console.error(e);
      }
    }
    return { newLayout: [], editable: false };
  };

  const loadSystemList = async () => {
    const result = await fetchGet(getEndpoint('systemframework', 2), {
      ...credentials,
      customerName,
      needDetail: false,
    });
    const { shareSystemArr = [], ownSystemArr = [] } = result || {};
    const newSystemList = [...shareSystemArr, ...ownSystemArr];
    R.addIndex(R.map)((system, index) => {
      const systemInfo = parseJSON(system) || {};

      if (systemInfo?.projectDetailsList) {
        systemInfo.projectDetailsList = parseJSON(systemInfo.projectDetailsList);
      }
      newSystemList[index] = {
        ...systemInfo,
        name: systemInfo.systemDisplayName,
        customerName: systemInfo?.systemKey?.userName || customerName,
        ownerUserName: systemInfo?.systemKey?.userName || customerName,
        id: systemInfo?.systemKey?.systemName,
        environmentName: environmentId,
        environmentId,
      };
    }, newSystemList);
    setCurCustomerSystemList(newSystemList);
  };

  const loadData = async () => {
    try {
      console.log('[IF] fetch stats summary and layout', new Date());
      setIsLoading(true);
      // eslint-disable-next-line no-sparse-arrays
      const results = await Promise.all([
        isAdmin || isLocalAdmin ? loadSystemList() : Promise.resolve(),
        fetchSystemSummary(),
        fetchLayoutData(),
        Promise.resolve(),
        ,
      ]);
      console.log('[IF] fetch stats summary and layout done', new Date());

      const { newLayout, editable: newEditable, otherSystemSet } = results[2] || {};

      await loadSystemStatistics(otherSystemSet || []);
      await loadInstanceDisplayName(otherSystemSet || []);
      const newState = {
        summaryObj: results[1] || [],
        isDesignMode: isCreate === 'true',
        showWidgetControl: isCreate === 'true',
        currentLayout: newLayout,
        editable: newEditable,
        systemSet: otherSystemSet || [],
      };
      currentLayoutRef.current = newLayout;

      setState(newState);
      console.log('[IF] set state done', new Date());
      setIsLoading(false);
      console.log('[IF] set loading false', new Date());
    } catch (err) {
      message.error(err.message || String(err));
      console.log(err);
      setIsLoading(false);
    }
  };
  // #endregion ============= asynchronous request api ========

  // #region  ============= layout update method ===========
  const onDrop = (layout, item, trigger) => {
    const currentId = currentWidgetId || item?.id;
    const isClickTrigger = trigger === 'click';
    if (currentId) {
      const widget = widgetInfos[currentId];
      const systemOptions = getSystemOptions(currentId);
      const widgetLayoutSystemId = systemOptions[0]?.value;
      if (widget) {
        // reset the widget settings
        const { width: w, height: h, minW, minH } = widget;
        const addTime = moment.utc().valueOf();
        const newItem = {
          i: currentId,
          w: item?.w || w,
          h: item?.h || h,
          minW: item?.minW || minW,
          minH: item?.minH || minH,
          x: item?.x || 0,
          key: widgetLayoutSystemId,
          addTime,
          y: isClickTrigger ? (currentLayout.length * 2) % Cols : item?.y || 0,
          params: {
            systemId: widgetLayoutSystemId,
          },
        };
        const newLayout = [...R.clone(currentLayout)];
        R.forEach((l) => {
          R.forEach((cl) => {
            if (getKeyByWidgetLayout(cl) === getKeyByWidgetLayout(l)) {
              cl.x = l.x;
              cl.y = l.y;
              cl.w = l.w;
              cl.h = l.h;
              cl.minW = l.minW;
              cl.minH = l.minH;
            }
          }, newLayout);
        }, layout);
        newLayout.push(newItem);
        currentLayoutRef.current = newLayout;
        // remove the added widget from the list
        setState({
          currentWidgetId: null,
          showWidgetControl: true,
          currentLayout: newLayout,
        });
      } else {
        console.warn(`[IF] Cannot find widget with id: ${currentId}`);
      }
    }
  };

  const updateLayout = (layout) => {
    // Cannot update the layouts state, just update the value directly
    R.forEach((l) => {
      R.forEach((cl) => {
        if (getKeyByWidgetLayout(cl) === getKeyByWidgetLayout(l)) {
          cl.x = l.x;
          cl.y = l.y;
          cl.w = l.w;
          cl.h = l.h;
          cl.minW = l.minW;
          cl.minH = l.minH;
        }
      }, currentLayout);
    }, layout);
    currentLayoutRef.current = currentLayout;
  };

  const onDropDragOver = () => {
    if (isMounted && currentWidgetId) {
      const widget = widgetInfos[currentWidgetId];
      if (widget) {
        return {
          w: widget.width,
          h: widget.height,
          minW: widget.minW,
          minH: widget.minH,
        };
      }
    }

    return false;
  };

  const onDelete = (widgetId, layoutWidgetKey) => {
    const widget = widgetInfos[widgetId];
    if (!widget) {
      console.warn(`[IF] Cannot find widget with id: ${widgetId}`);
      return;
    }

    const newLayout = R.filter((l) => {
      return getKeyByWidgetLayout(l) !== layoutWidgetKey;
    }, currentLayout || []);
    currentLayoutRef.current = newLayout;
    setState({
      currentLayout: newLayout,
    });
  };
  // #endregion  ============= layout update method ===========

  // #region ========= widget setting method========
  const onConfig = (widgetId, layoutWidgetKey) => {
    const widget = widgetInfos[widgetId];
    if (!widget) {
      console.warn(`[IF] Cannot find widget with id: ${widgetId}`);
      return;
    }
    setState({
      showSettings: true,
      widgetSettings: widget.settings,
      settingsWidgetId: widgetId,
      settingsLayoutWidgetKey: layoutWidgetKey,
    });
  };

  const handleConfigApply = (params) => {
    const widgetLayout = getWidgetLayoutByKey(settingsLayoutWidgetKey);
    if (widgetLayout) {
      widgetLayout.params = params;
    }
    if (settingsWidgetId === 'HoneycombMap') {
      setReloadTime(new Date().valueOf());
    }
    setState({ showSettings: false, settingsWidgetId: null, settingsLayoutWidgetKey: null });
  };
  // #endregion ========= widget setting method========

  // widget render
  const generateWidgets = () => {
    const { systemSet, systemStatisticsMap } = state;
    return R.map((l) => {
      const currentWidgetId = l.i;
      const widget = widgetInfos[currentWidgetId];
      if (widget) {
        const { settings } = widget;
        const needsProjectList = settings?.projectNameList;
        // const height = widget?.height || 0;
        const widgetSystemId = getSystemIdByWidgetLayout(l);
        const key = getKeyByWidgetLayout(l);
        const widgetDisplaySys = systemSet.find((e) => e.systemName === widgetSystemId) || {};
        const displaySystemInfo = getSystemInfo(widgetSystemId) || {
          name: widgetDisplaySys.name,
          systemId: widgetDisplaySys.systemName,
          customerName: widgetDisplaySys.userName,
        };
        const dispalySystemStatistics = systemStatisticsMap[widgetSystemId] || {};
        return (
          <div key={key} data-grid={l} className="content-bg corner-10" style={{ padding: '28px 24px 12px' }}>
            <div style={{ position: 'absolute', left: 24, top: 4, fontSize: 12 }}>
              <span>system: {displaySystemInfo.name}</span>
            </div>
            {isDesignMode && (needsProjectList || systemSet.length > 1) && (
              <div
                style={{ position: 'absolute', right: 36, top: 4, fontSize: 16, cursor: 'pointer' }}
                onClick={() => onConfig(currentWidgetId, key)}
              >
                <SettingOutlined style={{ color: 'var(--black)' }} />
              </div>
            )}
            {isDesignMode && (
              <div className="react-remove-handle" style={{ right: 4, top: 4 }}>
                <span className="react-remove-handle" onClick={() => onDelete(currentWidgetId, key)}>
                  <CloseOutlined style={{ color: 'var(--black)' }} />
                </span>
              </div>
            )}
            {widget.component({
              ...widgetProps,
              ...dispalySystemStatistics,
              instanceDisplayNameMap: systemInstanceDisplayNameMap[widgetSystemId],
              widgetInfo: { ...widget, key, params: l.params || [] },
              reloadTime,
              systemId: widgetSystemId,
              systemInfo: displaySystemInfo,
            })}
          </div>
        );
      }
      return null;
    }, currentLayout || []);
  };

  // #region ========= edit save copy method========
  const saveLayout = async (useTargetInfo = false, targetSystemParams = {}, dashName) => {
    let orderModelSet = R.map((l) => {
      const currentWidgetId = l.i;
      const widget = widgetInfos[currentWidgetId];
      if (widget) {
        return {
          l: currentWidgetId,
          dashboardWidget: {
            w: l.w,
            h: l.h,
            minW: l.minW,
            minH: l.minH,
            x: l.x,
            y: l.y,
            key: l.params?.systemId || l.key || defaultSystemId,
            params: l?.params ? JSON.stringify(l?.params) : null,
          },
        };
      }
      return null;
    }, currentLayout || []);

    orderModelSet = R.filter((x) => !!x, orderModelSet);
    if (useTargetInfo) {
      orderModelSet = R.filter(
        (orderModel) => orderModel.dashboardWidget.key === targetSystemParams.fromSystemId,
        orderModelSet,
      );
      // correct the systemId and key of the widget
      R.forEach((orderModel) => {
        const { dashboardWidget } = orderModel;
        if (dashboardWidget?.params && !!targetSystemParams.systemName) {
          const paramsObj = JSON.parse(dashboardWidget.params);
          paramsObj.systemId = targetSystemParams.systemName;
          dashboardWidget.params = JSON.stringify(paramsObj);
        } else {
          dashboardWidget.params = JSON.stringify({ systemId: targetSystemParams.systemName });
        }
        dashboardWidget.key = targetSystemParams.systemName;
      }, orderModelSet);
    }

    // repeat check
    const uniqOrderModelSet = R.uniqBy(
      (orderModal) =>
        getKeyByWidgetLayout({
          i: orderModal.l,
          ...orderModal.dashboardWidget,
        }),
      orderModelSet,
    );
    if (uniqOrderModelSet.length !== orderModelSet.length) {
      message.error(intl.formatMessage(appMessages.dashBoardWidgetSame));
      return;
    }
    const order = JSON.stringify(orderModelSet);

    try {
      let params = {};
      if (useTargetInfo) {
        params = {
          ...credentials,
          ...targetSystemParams,
          orderModelSet: order,
          dashboardName: dashName || '',
        };
      } else {
        params = {
          ...credentials,
          targetUser: customerName || userName,
          customerName: ownerUserName,
          systemName: systemId,
          orderModelSet: order,
          dashboardName: dashName || '',
        };
      }
      const data = await fetchPost(getEndpoint('dashboardorder'), params);
      const { success, message: msg } = data;
      if (success || success === undefined) {
      } else {
        console.error(msg);
      }
    } catch (e) {
      if (e?.code >= 500) {
        console.error(e.message || String(e));
      } else {
        message.error(e.message || String(e));
      }
    }
  };

  const onChangeMode = async (needsSave, dashName) => {
    if (isDesignMode) {
      if (needsSave) {
        setIsLoading(true);
        await saveLayout(false, {}, dashName);
      }
      setState({ showWidgetControl: true });
    }

    replace(buildLocation(location.pathname, {}, { ...query, isCreate: false, saveFlag: !isDesignMode }));
    setState({ isDesignMode: !isDesignMode });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      if (needsSave) {
        setIsLoading(false);
      }
    });
  };

  const onApplyOtherLayout = async (targetSystemId, targetCustomerName, dashName, systemsMap, fromSystemId) => {
    const { ownerUserName, owner } =
      R.find((system) => (system.id || system.systemId) === targetSystemId, R.values(systemsMap)) || {};
    await saveLayout(
      true,
      {
        targetUser: targetCustomerName,
        customerName: ownerUserName || owner,
        systemName: targetSystemId,
        fromSystemId: fromSystemId || defaultSystemId,
      },
      dashName,
    );
  };
  // #endregion ========= edit save copy method========

  const exportPDF = async () => {
    try {
      setState({ exportPDFing: true });
      const { location } = props;
      const { startTime, endTime, dashBoardName } = parseLocation(location);
      const pdfFilename = `${dashBoardName}-${startTime}-${endTime} dashboard.pdf`;
      const linechart = document.getElementById('export-container');
      const containerHeight = linechart.scrollHeight;
      const pdfContents = document.createElement('div');
      pdfContents.setAttribute('id', 'divToPrint');
      pdfContents.setAttribute('class', 'flex-grow flex-col insight-dash');
      pdfContents.setAttribute(
        'style',
        `min-width: 210mm; 
        width: 100%; 
        min-height: 100%; 
        height: ${containerHeight}; 
        margin-left: auto; 
        margin-right: auto; 
        background-color: var(--layout-body-background);
      `,
      );
      const hideEl = document.createElement('div');
      hideEl.setAttribute(
        'style',
        `width: 100%;  
          min-height: 100%;  
          height: ${containerHeight}; 
          position: absolute; 
          top: -10000px; 
          left: -10000px;`,
      );
      hideEl.appendChild(pdfContents);
      document.body.appendChild(hideEl);
      await pdfContents.appendChild(linechart);
      await sleep(600);
      await downloadPdfV2(pdfFilename, [pdfContents], () => {
        document.getElementById('export-container-parent').appendChild(linechart);
        document.body.removeChild(hideEl);
        setState({ exportPDFing: false });
      });
    } catch (e) {
      console.log(e);
      setState({ exportPDFing: false });
    }
  };

  return (
    <Container
      fullHeight
      withGutter
      className={`${isLoading || !!exportPDFing ? 'loading ' : ''} flex-row insight-dash`}
      id="export-container-parent"
    >
      <div className="flex-grow full-height flex-col overflow-y-auto overflow-x-auto" id="export-container">
        <SystemHeader
          {...widgetProps}
          {...(state.systemStatisticsMap[defaultSystemId] || {})} // default systemInfo
          systemStatisticsMap={state.systemStatisticsMap}
          systemId={defaultSystemId}
          systemInfo={defaultSystemInfo}
          isDesignMode={isDesignMode}
          onChangeMode={onChangeMode}
          onShowWidget={(show) => setState({ showWidgetControl: true })}
          favorites={favorites}
          onApplyOtherLayout={onApplyOtherLayout}
          fromSystemOptions={getSystemOptions()}
          onExportPDF={exportPDF}
        />
        <div className="flex-col full-width full-height">
          <Container className="grid-layout flex-grow flex-col" style={{ minWidth: 1165 }}>
            <ReactGridLayout
              className="layout"
              cols={Cols} // how much columns the screen is divided into
              margin={[8, 8]}
              containerPadding={[16, 8]}
              layout={currentLayout}
              measureBeforeMount
              useCSSTransforms={!!isDesignMode}
              resizeHandles={['se']}
              rowHeight={32}
              isDraggable={isDesignMode}
              isDroppable={isDesignMode}
              isResizable={isDesignMode}
              isBounded={isDesignMode}
              onDragStart={() => setState({ showWidgetControl: true })}
              onDropDragOver={onDropDragOver}
              onDrop={onDrop}
              onDragStop={(layout) => updateLayout(layout)}
              onResizeStop={(layout) => {
                setRefresh(moment.utc().valueOf());
                updateLayout(layout);
              }}
            >
              {generateWidgets()}
            </ReactGridLayout>
          </Container>
        </div>
      </div>
      {isDesignMode && showWidgetControl && (
        <div
          style={{
            width: 265,
            height: '100%',
            padding: '8px 10px',
            background: 'var(--content-background)',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 10,
            borderLeft: '1px solid var(--border-color-base)',
            boxShadow: 'var(--card-shadow)',
          }}
          className="overflow-y-auto"
        >
          <div
            style={{
              padding: '0 8px',
            }}
          >
            <div className="flex-row flex-space-between">
              <div>
                {/* <Button size="small" style={{ marginRight: 8 }} onClick={() => onChangeMode(false)}>
                  {intl.formatMessage(appButtonsMessages.cancel)}
                </Button> */}
              </div>
              <Tooltip title="Hide widget window" placement="bottomRight">
                <Button
                  shape="circle"
                  size="small"
                  onClick={() => setState({ showWidgetControl: false })}
                  icon={<CloseOutlined style={{ fontSize: 16 }} color="var(--icon-color)" />}
                />
              </Tooltip>
            </div>
          </div>
          <SystemInsightWidgets
            widgets={candidateWidgets}
            onTakeItem={(key) => setState({ currentWidgetId: key })}
            onDrop={onDrop}
          />
        </div>
      )}
      {showSettings && (
        <WidgetSettingsModal
          systemId={getSystemIdByWidgetLayout(getWidgetLayoutByKey(settingsLayoutWidgetKey))}
          getSystemInfo={getSystemInfo}
          allProjects={allProjects}
          settings={widgetSettings}
          systemOptions={getCanSlectSystemOptions(settingsWidgetId, settingsLayoutWidgetKey)}
          widget={{
            ...widgetInfos[settingsWidgetId],
            params: getWidgetLayoutByKey(settingsLayoutWidgetKey)?.params || {},
          }}
          onClose={() => {
            setState({ showSettings: false });
          }}
          onApply={handleConfigApply}
          isKubernetes={isKubernetes}
        />
      )}
    </Container>
  );
};

class DashContextCore extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <DashCreateContext.Provider value={this.props}>
        <SystemInsightsCore />
      </DashCreateContext.Provider>
    );
  }
}

export { DashCreateContext };
const DashContext = injectIntl(DashContextCore);
export default connect(
  (state: Object) => {
    const { location } = state.router;
    const { credentials, userInfo } = state.auth;
    const { isAdmin, isReadUser, userName } = state.auth.userInfo;
    const { loadStatus, globalInfo, allSystemConfigs, allProjects, currentTheme, systemsMap, favorites, projects } =
      state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { globalHealthSummary, globalHealthByInstance, globalSystemIncidentTimelines } = state.dashboard;
    return {
      userInfo,
      loadStatus,
      credentials,
      globalInfo,
      isAdmin,
      isReadUser,
      userName,
      location,
      userList,
      globalHealthSummary,
      globalHealthByInstance,
      globalSystemIncidentTimelines,
      allSystemConfigs,
      allProjects,
      currentTheme,
      systemsMap,
      favorites,
      projects,
    };
  },
  { push, replace, hideAppLoader, goBack, setShowDetailsFrame },
)(DashContext);
