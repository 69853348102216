/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import { get } from 'lodash';
import Papa from 'papaparse';
import * as R from 'ramda';

import { getMetricLineCharts } from '../../apis';
import { ActionTypes } from '../actions';
import { ActionTypes as AppActionTypes, createSetAction, updateLastActionInfo } from '../../app/actions';
import { DataFrame } from '../../../lib/fui/react';
import { apiErrorHandle } from '../../errors';
import { getLoadStatusActions, ifIn } from '../../utils';

const loadMetricLineChartsLogic = createLogic({
  type: [ActionTypes.LOAD_METRIC_LINECHARTS],
  cancelType: AppActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader } = action.payload;
    const { credentials } = state.auth;
    const { instanceName, startTimestamp, endTimestamp } = params;
    const { metricList, instanceList } = params;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());

    // Get the metric and instance list in the diff of the metric and instance list
    const lineChartsMetricInstanceMap = get(state.metric, 'lineChartsMetricInstanceMap', {});
    let dataFrameMetricList = [];
    let dataFrameInstanceList = [];
    R.forEachObjIndexed((val) => {
      dataFrameMetricList.push(val.metric);
      dataFrameInstanceList.push(val.instance);
    }, lineChartsMetricInstanceMap);
    dataFrameMetricList = R.uniq(dataFrameMetricList);
    dataFrameInstanceList = R.uniq(dataFrameInstanceList);

    // Get the different metric & instance list from them in data frame.
    const newMetricList = R.difference(metricList, dataFrameMetricList);
    const newInstanceList = R.difference(instanceList, dataFrameInstanceList);

    const { lineChartsGroupHighlights } = state.metric;
    const selectMetricData = (dfMap, metricDataList, metricInstanceMap, metricUnitMapping, mlist, ilist) => {
      R.forEach(
        (m) => {
          const sname = ['timestamp'];
          const instanceList = [];
          R.forEachObjIndexed((mi) => {
            if (mi.metric === m && ifIn(mi.instance, ilist)) {
              sname.push(mi.colname);
              instanceList.push(mi.instance);
            }
          }, metricInstanceMap);

          const df = dfMap[m];
          if (df) {
            const sdata = df.select(...sname).toArray();
            metricDataList.push({
              id: m,
              div_id: m,
              title: m,
              unit: (metricUnitMapping[m] || {}).unit || '',
              metrics: m,
              instanceList,
              sdata,
              sname,
              highlights: lineChartsGroupHighlights[m] || [],
            });
          }
        },
        R.sort(
          (a, b) => a.localeCompare(b),
          R.filter((n) => Boolean(n), mlist),
        ),
      );
    };

    if (
      newMetricList.length === 0 &&
      newInstanceList.length === 0 &&
      metricList.length !== 0 &&
      instanceList.length !== 0
    ) {
      // If not new metric or instance, just filter the line chart data set
      const {
        lineChartsMetricAllDataList,
        lineChartsMetricDataFrameMap: dfMap,
        lineChartsMetricUnitMapping,
      } = state.metric;
      if (lineChartsMetricAllDataList && dfMap) {
        let metricDataList = [];
        let instanceChanged = false;
        R.forEach((m) => {
          if (ifIn(m.metrics, metricList)) {
            const { instanceList: metricInstanceList } = m;
            if (instanceList.length === metricInstanceList) {
              metricDataList.push(m);
            } else {
              instanceChanged = true;
            }
          }
        }, lineChartsMetricAllDataList);

        if (instanceChanged) {
          metricDataList = [];
          const highlightMetricList = R.filter((m) => ifIn(m, metricList), R.keys(lineChartsGroupHighlights || {}));
          const diffMetricList = R.difference(metricList, highlightMetricList);

          selectMetricData(
            dfMap,
            metricDataList,
            lineChartsMetricInstanceMap,
            lineChartsMetricUnitMapping || {},
            highlightMetricList,
            instanceList,
          );
          selectMetricData(
            dfMap,
            metricDataList,
            lineChartsMetricInstanceMap,
            lineChartsMetricUnitMapping || {},
            diffMetricList,
            instanceList,
          );
        }

        dispatch(
          createSetAction(ActionTypes.SET_METRIC_LINECHARTS, params, {
            lineChartsMetricDataList: metricDataList,
          }),
        );
      }
      dispatch(hideLoading);
      done();
      return;
    }

    getMetricLineCharts(credentials, { ...params, instanceList, metricList })
      .then((d) => {
        const { lineChartsInstanceList, lineChartsMetricList } = state.metric;
        const instanceSet = get(d, 'instanceSet', []);
        const metricSet = get(d, 'metricSet', []);

        const metricUnitMapping = {};
        R.forEach((m) => (metricUnitMapping[m.metric] = m), get(d, 'metricUnitMapping', []));

        const splitCsvData = get(d, 'splitCsvData', {});
        // Get the metric & instance in the dataset
        const lineChartsMetricInstanceMap = {};
        const lineChartsMetricDataFrameMap = {};
        let dfMetricList = [];
        let dfInstanceList = [];

        R.forEachObjIndexed((csvData, mname) => {
          const pa = Papa.parse(csvData, { header: true, skipEmptyLines: true, dynamicTyping: true });
          const dsResult = pa.data || [];
          // Add empty data for start/end timestamp, and ignore data out of the time range.
          const dataset = [];
          if (dsResult.length === 0 || dsResult[0].timestamp > startTimestamp) {
            dataset.push({ timestamp: new Date(startTimestamp) });
          }

          R.forEach((d) => {
            if (d.timestamp >= startTimestamp) {
              d.timestamp = new Date(d.timestamp);
              dataset.push(d);
            }
          }, dsResult);

          if (dataset[dataset.length - 1].timestamp.valueOf() < endTimestamp) {
            dataset.push({ timestamp: new Date(endTimestamp) });
          }

          const df = new DataFrame(dataset, pa.meta.fields);
          lineChartsMetricDataFrameMap[mname] = df;
          const colnames = df.listColumns();
          R.forEach(
            (n) => {
              // The metric name is like: LoadAvg5[ip-172-31-7-224][][]:7007
              const idx = n.indexOf(']');
              if (idx > 0) {
                const name = n.substr(0, idx + 1);
                const nidx = name.indexOf('[');
                if (nidx > 0) {
                  const metric = name.substr(0, nidx);
                  const instance = name.substr(nidx + 1, name.length - nidx - 2);
                  dfMetricList.push(metric);
                  dfInstanceList.push(instance);
                  lineChartsMetricInstanceMap[name] = { colname: n, metric, instance };
                }
              }
            },
            R.filter((n) => Boolean(n), colnames),
          );
        }, splitCsvData);

        dfMetricList = R.uniq(dfMetricList);
        dfInstanceList = R.uniq(dfInstanceList);
        const highlightMetricList = R.filter((m) => ifIn(m, dfMetricList), R.keys(lineChartsGroupHighlights || {}));

        // First process the metric with highlights
        const metricDataList = [];
        selectMetricData(
          lineChartsMetricDataFrameMap,
          metricDataList,
          lineChartsMetricInstanceMap,
          metricUnitMapping,
          highlightMetricList,
          dfInstanceList,
        );
        dfMetricList = R.difference(dfMetricList, highlightMetricList);
        selectMetricData(
          lineChartsMetricDataFrameMap,
          metricDataList,
          lineChartsMetricInstanceMap,
          metricUnitMapping,
          dfMetricList,
          dfInstanceList,
        );

        // Group the dataset by metric
        dispatch(
          createSetAction(ActionTypes.SET_METRIC_LINECHARTS, params, {
            lineChartsRawData: splitCsvData,
            lineChartsDataFrame: null,
            lineChartsMetricDataFrameMap,
            lineChartsMetricInstanceMap,
            lineChartsMetricAllDataList: metricDataList,
            lineChartsMetricDataList: metricDataList,
            lineChartsInstanceList: instanceSet && instanceSet.length > 0 ? instanceSet : lineChartsInstanceList,
            lineChartsMetricList: metricSet && metricSet.length > 0 ? metricSet : lineChartsMetricList,
            lineChartsMetricUnitMapping: metricUnitMapping,
          }),
        );
      })
      .catch((err) => {
        dispatch(apiErrorHandle(err));
      })
      .then(() => {
        dispatch(hideLoading);
        done();
      });
  },
});

export default loadMetricLineChartsLogic;
