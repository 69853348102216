import React from 'react';
import * as R from 'ramda';
import { get, isFunction } from 'lodash';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push, replace } from 'react-router-redux';
import { Checkbox, Divider, DatePicker, Input, message, Select } from 'antd';
import momenttz from 'moment-timezone';
import RcDatePicker from 'react-datepicker';

import { LoadingOutlined } from '@ant-design/icons';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { BaseUrls } from '../../app/Constants';
import { Container, Modal } from '../../../lib/fui/react';
import {
  buildUrl,
  Defaults,
  FilterQueryList,
  getLoadStatus,
  ifIn,
  localDateToUtcObj,
  Params,
  parseLocation,
  utcObjToLocalDate,
} from '../../../common/utils';
import { createLoadAction, loadProjectInfo, updateLastActionInfo } from '../../../common/app/actions';

import { ActionTypes } from '../../../common/query/actions';
import { appFieldsMessages, appMenusMessages, appMessages } from '../../../common/app/messages';
import { DEFAULT_EXPAND_DURATION, getDurationMs, getExpandDurations } from '../../../common/utils/DurationUtils';
import { TimeInput } from '../../share/TimeInput';
import fetchGet from '../../../common/apis/fetchGet';
import getInstanceDisplayName from '../../../common/utils/getInstanceDisplayName';

type Props = {
  queryParams: Object,
  projects: Array<Object>,
  onConfirm: Function,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  location: Object,
  loadStatus: Object,
  loaderStatus: Object,
  // eslint-disable-next-line
  projectList: Array<Object>,
  // eslint-disable-next-line
  isAdmin: Boolean,
  createLoadAction: Function,
  loadProjectInfo: Function,
  timezoneOffset: Number,

  templateList: Array<Object>,
  // eslint-disable-next-line
  templateMap: Object,
  // eslint-disable-next-line
  templateCategoryList: Array<Object>,

  updateLastActionInfo: Function,
  credentials: Object,
  userInfo: Object,
};

class InsightQueryBoxModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'global_system_query_loader';
    this.projectLoader = 'global_system_query_project_loader';

    const projects = props.projects || [];
    const queryParams = props.queryParams || {};
    const projectName = queryParams.projectName || (projects.length > 0 ? projects[0].projectNameReal : undefined);
    const endTimeObj = this.getLocalEndTime(
      props,
      { projectName },
      queryParams.endTimeObj || queryParams.endTimestampObj,
    );

    this.state = {
      templateId: queryParams.template || 'search_all_log_entries',
      projectName,
      instanceName: [],
      startTimeObj: this.getLocalStartOfDay(props?.projectList || [], projectName, endTimeObj.clone()),
      endTimeObj,
      keyword: '',
      numOfCluster: '',
      duration: queryParams.duration || DEFAULT_EXPAND_DURATION,
      pattern: queryParams.pattern || '',
      endTimeChanged: false,

      searchValue: '',
      loading: false,
    };
    this.queryOptions = [];
    this.templateMap = {};
    this.instanceListOptions = [];
    this.durationOptions = getExpandDurations(props.intl);
  }

  componentDidMount() {
    const { intl, projectList, loadProjectInfo } = this.props;
    const { projectName, startTimeObj, endTimeObj } = this.state;

    moment.locale(intl.locale === 'zh' ? 'zh-cn' : intl.locale);

    // reload template list
    this.props.createLoadAction(ActionTypes.LOAD_QUERY_TEMPLATE_LIST, {}, this.dataLoader);
    // reload project meta data
    const currentProject = R.find((project) => project.projectName === projectName, projectList);
    if (currentProject && !currentProject.hasAllInstanceInfo) {
      loadProjectInfo(
        {
          projectName,
          includeInstance: true,
          startTimestamp: startTimeObj.clone().startOf('day').valueOf(),
          endTimestamp: endTimeObj.clone().endOf('day').valueOf(),
        },
        { [this.projectLoader]: true },
      );
    } else {
      this.parseData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.templateList !== this.props.templateList || nextProps.projectList !== this.props.projectList) {
      this.parseData(nextProps);
    }
  }

  @autobind
  getLocalStartOfDay(projectList, projectName, startTimeObj) {
    const currentProject = R.find((project) => project.projectName === projectName, projectList || []);

    let timezoneOffset = 0;
    if (currentProject?.timezone) {
      const zone = momenttz.tz(currentProject?.timezone);
      timezoneOffset = zone.utcOffset();
    }

    return moment.utc(startTimeObj.clone().startOf('day').valueOf() + (timezoneOffset || 0) * 60000);
  }

  @autobind
  getLocalEndTime(props, state, endTimeObj) {
    const { projectList } = props;
    const { projectName, endTimeChanged } = state;
    const currentProject = R.find((project) => project.projectName === projectName, projectList);

    let timezoneOffset = 0;
    if (currentProject?.timezone) {
      const zone = momenttz.tz(currentProject?.timezone);
      timezoneOffset = zone.utcOffset();
    }

    const now = moment.utc(moment.utc().valueOf() + (timezoneOffset || 0) * 60000);
    if (endTimeObj) {
      // add back the endtime check.
      if (endTimeObj.clone().startOf('day').diff(now, 'days') === 0) {
        return now;
      } else {
        return endTimeObj;
      }
    } else {
      return now;
    }
  }

  @autobind
  async parseData(props) {
    const { templateList, templateMap, projectList } = props;
    const { projectName } = this.state;

    this.queryOptions = R.map(
      (item) => ({ value: item.id, label: item.desc }),
      R.filter((item) => FilterQueryList.includes(item.id), templateList),
    );
    this.templateMap = templateMap || {};

    const currentProject = R.find((project) => project.projectName === projectName, projectList);
    const instanceList = currentProject ? currentProject.instanceList : [];

    await this.reloadInstanceData(instanceList);

    this.forceUpdate();
  }

  @autobind
  async reloadInstanceData(localinstanceList) {
    const { intl, credentials, queryParams, projectList } = this.props;
    const { startTimeObj, endTimeObj, duration, templateId, instanceName } = this.state;

    const { projectName } = this.state;
    const currentProject = R.find((project) => project.projectName === projectName, projectList);
    let instanceGroupList = [];
    const instanceDisplayNameMap = {};
    if (startTimeObj || endTimeObj || duration) {
      this.setState({ loading: true });
      this.props.updateLastActionInfo();
      const projectendTime = this.getLocalEndTime(
        this.props,
        { projectName },
        queryParams.endTimeObj || queryParams.endTimestampObj,
      ).valueOf();

      let startTime = startTimeObj.startOf('day').valueOf();
      let endTime = endTimeObj.valueOf();

      if (endTime > projectendTime) {
        endTime = projectendTime;
      }

      if (templateId === 'search_all_log_entries' || templateId === 'live_tail_log_entries') {
        startTime = endTime - getDurationMs(duration);
      }

      if (startTime > endTime) {
        startTime = endTime;
      }

      const request = [
        fetchGet(getEndpoint('fetchloginstancewithdata'), {
          ...credentials,
          projectName,
          startTime,
          endTime,
        }),
        fetchGet(getEndpoint('instance-display-name'), {
          ...credentials,
          instanceDisplayNameRequestList: JSON.stringify([
            { projectName: currentProject?.projectShortName, customerName: currentProject?.owner },
          ]),
        }),
      ];

      await Promise.all(request)
        .then((res) => {
          const [d, d1] = res || [];
          const { success } = d || {};
          if (success || success === undefined) {
            R.forEachObjIndexed((c, i) => {
              instanceGroupList.push({
                instanceName: i,
                appName: c || '',
              });
            }, d || {});
            instanceGroupList = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('instanceName')))], instanceGroupList);
          }

          R.forEach((item) => {
            const [pInfo, iList] = item || [];
            const { projectName, customerName } = pInfo || {};
            R.forEach((instanceInfo) => {
              const { instanceSet, instanceDisplayName } = instanceInfo || {};
              R.forEach((instance) => {
                instanceDisplayNameMap[`${instance}`] = instanceDisplayName;
                instanceDisplayNameMap[`${projectName}-${customerName}-${instance}`] = instanceDisplayName;
              }, instanceSet || []);
            }, iList || []);
          }, d1 || []);
        })
        .catch((err) => {
          console.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        });
    }

    const newInstanceName = [];
    R.forEach((item) => {
      const findI = R.find((i) => item === i.instanceName, instanceGroupList || []);
      if (findI) newInstanceName.push(item);
    }, instanceName || []);
    this.instanceListOptions = R.map((item) => {
      const { instanceName, appName } = item;
      const { instanceStr } = getInstanceDisplayName(instanceDisplayNameMap, instanceName, {
        pn: currentProject?.projectShortName,
        owner: currentProject?.owner,
      });
      return { value: instanceName, label: `${instanceStr}${appName ? ` (${appName})` : ''}` };
    }, instanceGroupList || []);
    this.setState({ loading: false, instanceName: newInstanceName });
  }

  @autobind
  handleQueryChange(templateId) {
    const { queryParams } = this.props;
    const { projectName } = this.state;
    const startTimeObj =
      queryParams.startTimeObj ||
      queryParams.startTimestampObj ||
      this.getLocalStartOfDay(this.props.projectList, projectName, moment.utc());
    const endTimeObj = this.getLocalEndTime(
      this.props,
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.state,
      queryParams.endTimeObj || queryParams.endTimestampObj,
    );
    this.setState({ templateId, startTimeObj, endTimeObj, instanceName: [] }, () => {
      this.reloadInstanceData();
    });
  }

  @autobind
  handleProjectChange(projectName) {
    const endTimeObj = this.getLocalEndTime(this.props, { projectName }, this.state.endTimeObj);
    this.setState({ projectName, instanceName: undefined, endTimeObj }, () => {
      const { projectList, loadProjectInfo } = this.props;
      const { startTimeObj, endTimeObj } = this.state;
      const currentProject = R.find((project) => project.projectName === projectName, projectList);
      if (currentProject && !currentProject.hasAllInstanceInfo) {
        loadProjectInfo(
          {
            projectName,
            includeInstance: true,
            startTimestamp: startTimeObj.clone().startOf('day').valueOf(),
            endTimestamp: endTimeObj.clone().endOf('day').valueOf(),
          },
          { [this.projectLoader]: true },
        );
      } else {
        this.parseData(this.props);
      }
    });
  }

  @autobind
  onChangeDuration(value) {
    this.setState({ duration: value }, () => {
      this.reloadInstanceData();
    });
  }

  @autobind
  handleConfirmClick() {
    const { onConfirm } = this.props;
    if (isFunction(onConfirm)) {
      const { location } = this.props;
      const params = parseLocation(location);
      const { customerName } = params;
      const { startTimeObj, endTimeObj, templateId, duration, instanceName } = this.state;
      let startTime = startTimeObj.valueOf();
      let endTime = endTimeObj.valueOf();

      if (templateId === 'search_all_log_entries' || templateId === 'live_tail_log_entries') {
        startTime = endTime - getDurationMs(duration) * 2;
        endTime -= getDurationMs(duration);
      }
      onConfirm({
        ...this.state,
        startTime,
        endTime,
        customerName,
        startTimestampObj: moment.utc(Number(startTime)),
        endTimeObj: moment.utc(Number(endTime)),
        startTimeObj: moment.utc(Number(startTime)),
        endTimestampObj: moment.utc(Number(endTime)),
        instanceName: JSON.stringify(instanceName),
      });
    } else {
      this.handleInsightQueryConfirm();
    }
  }

  @autobind
  handleInsightQueryConfirm() {
    const { onClose, location } = this.props;
    const params = parseLocation(location);
    const { customerName } = params;
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
    } = this.state;
    let startTime = startTimeObj.valueOf();
    const endTime = endTimeObj.valueOf();

    if (templateId === 'search_all_log_entries' || templateId === 'live_tail_log_entries') {
      startTime = endTime - getDurationMs(duration);
    }

    const query = {
      t: templateId,
      projectName,
      instanceName,
      startTime,
      endTime,
      keyword,
      duration,
      customerName,
      numOfCluster: parseInt(numOfCluster, 10) ? parseInt(numOfCluster, 10) : undefined,
      startTimestamp: startTime,
      endTimestamp: endTime,
      pattern,
    };
    window.open(buildUrl(BaseUrls.Query, {}, query), '_blank');
    onClose();
  }

  @autobind
  getInstanceListOptions(option) {
    return R.map((item) => item.value, option);
  }

  @autobind
  getFilterInstanceListOptions(searchValue, options) {
    return R.filter((item) => item.label.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1, options);
  }

  @autobind
  isCheckedAll() {
    const { searchValue, instanceName } = this.state;
    const filterValue = this.getInstanceListOptions(
      this.getFilterInstanceListOptions(searchValue, this.instanceListOptions),
    );
    const diff = R.difference(filterValue, instanceName);
    return diff.length === 0;
  }

  render() {
    const { intl, loadStatus, loaderStatus, onClose, projects, projectList, userInfo } = this.props;
    const {
      templateId,
      projectName,
      instanceName,
      startTimeObj,
      endTimeObj,
      keyword,
      numOfCluster,
      pattern,
      duration,
      searchValue,
      loading,
    } = this.state;

    const template = get(this.templateMap, templateId);
    const validTemplate = Boolean(template);
    const requiredParams = get(template || {}, 'requiredParams', []);
    const optionalParams = get(template || {}, 'optionalParams', []);

    const needsProjectName =
      ifIn(Params.ProjectName, requiredParams) ||
      ifIn(Params.MetricProjectName, requiredParams) ||
      ifIn(Params.LogProjectName, requiredParams);
    const needsInstanceName = ifIn(Params.InstanceName, requiredParams);
    const needsStartTime = ifIn(Params.StartTime, requiredParams);
    const needsStartTimestamp = ifIn(Params.StartTimestamp, requiredParams);
    const needsEndTime = ifIn(Params.EndTime, requiredParams);
    const needsEndTimestamp = ifIn(Params.EndTimestamp, requiredParams);
    const needsKeyword = ifIn(Params.Keyword, requiredParams);
    const needsNumOfCluster = ifIn(Params.NumOfCluster, requiredParams);
    const needsPattern = ifIn(Params.Pattern, requiredParams);
    const needsDuration = ifIn(Params.Duration, requiredParams);

    const showProjectName =
      needsProjectName ||
      ifIn(Params.ProjectName, optionalParams) ||
      ifIn(Params.MetricProjectName, optionalParams) ||
      ifIn(Params.LogProjectName, optionalParams);
    const showInstanceName = needsInstanceName || ifIn(Params.InstanceName, optionalParams);
    const showStartTime =
      needsStartTime ||
      needsStartTimestamp ||
      ifIn(Params.StartTime, optionalParams) ||
      ifIn(Params.StartTimestamp, optionalParams);
    const showEndTime =
      needsEndTime ||
      needsEndTimestamp ||
      ifIn(Params.EndTime, optionalParams) ||
      ifIn(Params.EndTimestamp, optionalParams);
    const showKeyword = needsKeyword || ifIn(Params.Keyword, optionalParams);
    const showNumOfCluster = needsNumOfCluster || ifIn(Params.NumOfCluster, optionalParams);
    const showPattern = needsPattern || ifIn(Params.Pattern, optionalParams);
    const showDuration = needsDuration || ifIn(Params.Duration, optionalParams);

    const hasError =
      !validTemplate ||
      !template ||
      (needsDuration && !duration) ||
      (needsProjectName && !projectName) ||
      (needsInstanceName && !instanceName) ||
      (needsStartTime && !startTimeObj) ||
      (needsEndTime && !endTimeObj) ||
      (!needsDuration && endTimeObj && endTimeObj.valueOf() < startTimeObj.valueOf()) ||
      (needsKeyword && !keyword) ||
      (keyword && !R.test(/^"[^\n\r]+"(\s*(AND|OR)\s*"[^\n\r]+")*$/, keyword)) ||
      (needsNumOfCluster && !numOfCluster) ||
      (needsPattern && !pattern) ||
      R.isEmpty(instanceName);

    const { isLoading } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const isProjectLoading = get(loaderStatus, this.projectLoader, false);

    const needAll = ['953de6a33d8a4b96ac9c100bf69ba3fc', 'b81364228d574502b7f6c3e454b9a21b'];
    const showCheckAll = needAll.includes(templateId);

    return (
      <Modal
        open
        width={650}
        title={intl.formatMessage(appMenusMessages.logQuery)}
        onOk={this.handleConfirmClick}
        onCancel={onClose}
        okButtonProps={{ disabled: hasError }}
        maskClosable={false}
      >
        <Container
          className={`flex-col content ${isLoading || isProjectLoading ? ' loading' : ''}`}
          style={{ minHeight: 120, fontSize: 12 }}
        >
          <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
            <span className="label required before" style={{ minWidth: 120, fontWeight: 'bold' }}>
              {intl.formatMessage(appFieldsMessages.query)}:
            </span>
            <Select
              className="full-width"
              showSearch
              value={(this.queryOptions || []).length > 0 ? templateId : null}
              onChange={this.handleQueryChange}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {R.map(
                (item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ),
                this.queryOptions,
              )}
            </Select>
          </div>

          {showProjectName && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <span
                className={`label ${needsProjectName ? ' required before' : ''}`}
                style={{ minWidth: 120, fontWeight: 'bold' }}
              >
                {intl.formatMessage(appFieldsMessages.project)}:
              </span>
              <Select
                className="full-width"
                showSearch
                value={projectName}
                onChange={this.handleProjectChange}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {R.map((item) => {
                  const projectInfo =
                    R.find((_item) => _item.projectName === item.projectDisplayName, projectList || []) || {};
                  const projectDisplayName = projectInfo?.projectDisplayName
                    ? `${projectInfo.projectDisplayName}${
                        userInfo.isAdmin || userInfo.isLocalAdmin || projectInfo.owner !== userInfo.userName
                          ? `@${projectInfo.owner}`
                          : ''
                      }`
                    : item.projectDisplayName;
                  return (
                    <Select.Option key={item.projectNameReal} value={item.projectNameReal}>
                      {projectDisplayName}
                    </Select.Option>
                  );
                }, projects || [])}
              </Select>
            </div>
          )}

          {showStartTime && !showDuration && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <span
                className={`label ${needsStartTime ? ' required before' : ''}`}
                style={{ minWidth: 120, fontWeight: 'bold', ...(!needsStartTime ? { paddingLeft: 12 } : {}) }}
              >
                {intl.formatMessage(needsStartTimestamp ? appFieldsMessages.startTime : appFieldsMessages.startDate)}:
              </span>
              <RcDatePicker
                showTimeSelect={needsStartTimestamp}
                showTimeInput={needsStartTimestamp}
                timeIntervals={15}
                showPopperArrow={false}
                timeFormat={Defaults.DatePickerTime}
                dateFormat={needsStartTimestamp ? Defaults.DateTimeFormat1 : Defaults.DateFormat1}
                selected={utcObjToLocalDate(startTimeObj)}
                customTimeInput={<TimeInput />}
                onChange={(startTime) => {
                  this.setState({ startTimeObj: localDateToUtcObj(startTime) });
                }}
                onCalendarClose={() => {
                  this.reloadInstanceData();
                }}
              />
            </div>
          )}
          {showEndTime && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <span
                className={`label ${needsEndTime ? ' required before' : ''}`}
                style={{ minWidth: 120, fontWeight: 'bold', ...(!needsEndTime ? { paddingLeft: 12 } : {}) }}
              >
                {intl.formatMessage(needsEndTimestamp ? appFieldsMessages.endTime : appFieldsMessages.endDate)}:
              </span>
              <RcDatePicker
                showTimeSelect={needsEndTimestamp}
                showTimeInput={needsEndTimestamp}
                timeIntervals={15}
                showPopperArrow={false}
                timeFormat={Defaults.DatePickerTime}
                dateFormat={needsEndTimestamp ? Defaults.DateTimeFormat1 : Defaults.DateFormat1}
                selected={utcObjToLocalDate(endTimeObj)}
                customTimeInput={<TimeInput />}
                onChange={(endTime) => {
                  this.setState({ endTimeObj: localDateToUtcObj(endTime), endTimeChanged: true });
                }}
                onCalendarClose={() => {
                  this.reloadInstanceData();
                }}
              />
            </div>
          )}
          {showDuration && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <span className="label required before" style={{ minWidth: 120, fontWeight: 'bold' }}>
                {intl.formatMessage(appFieldsMessages.duration)}:
              </span>
              <Select
                allowClear
                showSearch
                style={{ width: 140 }}
                value={duration}
                optionFilterProp="children"
                onChange={this.onChangeDuration}
                filterOption={(input, option) =>
                  this.durationOptions.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.text}
                    </Select.Option>
                  ),
                  this.durationOptions || [],
                )}
              </Select>
            </div>
          )}

          {showProjectName && showInstanceName && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <span className="label required before" style={{ minWidth: 120, fontWeight: 'bold' }}>
                {intl.formatMessage(appFieldsMessages.instance)}
                {loading && (
                  <LoadingOutlined style={{ color: 'red', fontSize: 20, fontWeight: 'bold', marginLeft: 4 }} />
                )}
                :
              </span>
              <Select
                allowClear
                style={{ width: 482 }}
                showSearch
                mode="multiple"
                value={instanceName}
                autoClearSearchValue={false}
                onChange={(instanceNameValue) => {
                  if (instanceNameValue.length > 2 && !needAll.includes(templateId)) {
                    instanceNameValue.shift();
                  }
                  this.setState({ instanceName: instanceNameValue });
                }}
                onDropdownVisibleChange={(open) => {
                  if (!open) {
                    this.setState({ searchValue: '' });
                  }
                }}
                optionFilterProp="value"
                filterOption={(input, option) => {
                  return (
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                  );
                }}
                maxTagCount={1}
                dropdownRender={(menu) => (
                  <div>
                    {showCheckAll && (
                      <>
                        <div
                          className="flex-row"
                          style={{ padding: '5px 12px' }}
                          onMouseDown={(event) => event.preventDefault()}
                        >
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={this.isCheckedAll()}
                            onChange={(e) => {
                              const { checked } = e.target;
                              const filterValue = this.getInstanceListOptions(
                                this.getFilterInstanceListOptions(searchValue, this.instanceListOptions),
                              );
                              const diff = R.difference(instanceName, filterValue);
                              this.setState({ instanceName: checked ? [...diff, ...filterValue] : diff });
                            }}
                          />
                          <span>{intl.formatMessage(appFieldsMessages.selectAll)}</span>
                        </div>
                        <Divider style={{ margin: '4px 0' }} />
                      </>
                    )}
                    {menu}
                  </div>
                )}
                onSearch={(searchValue) => this.setState({ searchValue })}
                disabled={loading}
              >
                {R.map((item) => {
                  return (
                    <Select.Option className="hide-icon" key={item.value} value={item.value} label={item.label}>
                      <Checkbox checked={(instanceName || []).includes(item.value)} style={{ marginRight: 8 }} />
                      {item.label}
                    </Select.Option>
                  );
                }, this.instanceListOptions || [])}
              </Select>
            </div>
          )}

          {showKeyword && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <span
                className={`label ${needsKeyword ? ' required before' : ''}`}
                style={{ minWidth: 120, fontWeight: 'bold', ...(!needsKeyword ? { paddingLeft: 12 } : {}) }}
              >
                {intl.formatMessage(appFieldsMessages.keywords)}:
              </span>
              <Input
                className="full-width"
                placeholder={`"keyword1" AND "keyword2" OR "keyword3"`}
                value={keyword}
                onChange={(e) => this.setState({ keyword: e.target.value })}
              />
            </div>
          )}
          {showNumOfCluster && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <span
                className={`label ${needsNumOfCluster ? ' required before' : ''}`}
                style={{ minWidth: 120, fontWeight: 'bold', ...(!needsNumOfCluster ? { paddingLeft: 12 } : {}) }}
              >
                {intl.formatMessage(appFieldsMessages.topKPatterns)}:
              </span>
              <Input
                className="full-width"
                value={numOfCluster}
                onChange={(e) => this.setState({ numOfCluster: e.target.value })}
              />
            </div>
          )}
          {showPattern && (
            <div className="flex-row flex-center-align" style={{ marginBottom: 8 }}>
              <span
                className={`label ${needsPattern ? ' required before' : ''}`}
                style={{ minWidth: 120, fontWeight: 'bold', ...(!needsPattern ? { paddingLeft: 12 } : {}) }}
              >
                {intl.formatMessage(appFieldsMessages.pattern)}:
              </span>
              <Input
                className="full-width"
                value={pattern}
                onChange={(e) => this.setState({ pattern: e.target.value })}
              />
            </div>
          )}
        </Container>
      </Modal>
    );
  }
}

const InsightQueryBoxModal = injectIntl(InsightQueryBoxModalCore);
export default connect(
  (state) => {
    const { isAdmin } = state.auth.userInfo;
    const { location } = state.router;
    const { loadStatus, loaderStatus, projects: projectList, timezoneOffset } = state.app;
    const { templateList, templateMap, templateCategoryList } = state.query;
    const { credentials, userInfo } = state.auth;
    return {
      location,
      loadStatus,
      loaderStatus,
      projectList,
      isAdmin,
      templateList,
      templateMap,
      templateCategoryList,
      timezoneOffset,
      credentials,
      userInfo,
    };
  },
  { push, replace, createLoadAction, loadProjectInfo, updateLastActionInfo },
)(InsightQueryBoxModal);
