/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

export const ActionTypes = {
  CANCEL_QUERY: 'CANCEL_QUERY',
  LOAD_QUERY_RESULT: 'LOAD_QUERY_RESULT',
  SET_QUERY_RESULT: 'SET_QUERY_RESULT',
  SET_APP_NAME_MAP: 'SET_APP_NAME_MAP',
  LOAD_QUERY_TEMPLATE_LIST: 'LOAD_QUERY_TEMPLATE_LIST',
  SET_QUERY_TEMPLATE_LIST: 'SET_QUERY_TEMPLATE_LIST',
};
