/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 * */
import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import numeral from 'numeral';
import { get, isNumber, isFinite } from 'lodash';
import { Tag } from 'antd';

import { Defaults } from '.';
import calcColorOfAnomalyScore from './calcColorOfAnomalyScore';
import { Up, Down, UpToTop } from '../../lib/fui/icons';
import { Popover } from '../../lib/fui/react';
import { LogContent } from '../../web/share';

import { appFieldsMessages } from '../app/messages';
import { DashboardMessages } from '../dashboard/messages';
import { logMessages, logDescMessages } from '../log/messages';

const anomalyTypeRendererItem = (my, idx) => {
  const label = my ? my[0].toUpperCase() : 'M';

  const color = 'gray';
  const legend = (
    <span
      key={idx}
      className="anomaly-event-legend"
      style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
    >
      {my || label}
    </span>
  );

  const legendWithTooltip = (
    <Popover key={idx} mouseEnterDelay={0.3} placement="top" title={null} content={<div>{my || label}</div>}>
      <div className="hidden-line-with-ellipsis inline-block">{legend}</div>
    </Popover>
  );
  return { label, legend: legendWithTooltip };
};

const anomalySeverityRendererItem = (e, idx, blueColor, topestRank) => {
  const { pct, rootCauseMetric, direction, rootCauseSource } = e;
  let color = direction === 'lower' ? blueColor : calcColorOfAnomalyScore(pct);

  const metric = (rootCauseMetric || '').toLowerCase();
  let label = metric ? metric[0].toUpperCase() : 'M';
  let legend;

  if (metric.indexOf('network') === 0) {
    label = 'NET';
  } else if (metric.indexOf('disk') === 0) {
    label = 'DISK';
  } else if (metric.indexOf('cpu') === 0) {
    label = 'CPU';
  } else if (metric.indexOf('mem') === 0) {
    label = 'MEM';
  } else if (metric.indexOf('new') === 0) {
    label = 'NEW';
  }

  if (rootCauseSource === 'missing data') {
    color = calcColorOfAnomalyScore(topestRank);
    label = 'DOWN';
    legend = (
      <span
        key={idx}
        className="anomaly-event-legend"
        style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
      >
        {label}
      </span>
    );
  } else if (rootCauseSource === 'threshold') {
    color = calcColorOfAnomalyScore(topestRank);
    legend = (
      <span
        key={idx}
        className="anomaly-event-legend"
        style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
      >
        {label}
        <UpToTop color="white" />
      </span>
    );
  }

  if (!legend) {
    if (direction === 'higher') {
      legend = (
        <span
          key={idx}
          className="anomaly-event-legend"
          style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
        >
          {label}
          <Up color="white" />
        </span>
      );
    } else {
      legend = (
        <span
          key={idx}
          className="anomaly-event-legend"
          style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
        >
          {label}
          <Down color="white" />
        </span>
      );
    }
  }

  const legendWithTooltip = (
    <Popover
      key={idx}
      mouseEnterDelay={0.3}
      placement="top"
      title={null}
      content={<div>{rootCauseMetric || label}</div>}
    >
      <div className="hidden-line-with-ellipsis inline-block">{legend}</div>
    </Popover>
  );
  return { label, legend: legendWithTooltip };
};

const anomalyInstanceRendererItem = (e, idx, blueColor, topestRank) => {
  const { pct, name, direction, rootCauseSource } = e;
  let color = direction === 'lower' ? blueColor : calcColorOfAnomalyScore(pct);

  const sidx = name.indexOf('(');
  const label = sidx > 0 ? name.substr(0, sidx) : name;
  const { labels } = e;
  let legend;

  if (rootCauseSource === 'missing data') {
    color = calcColorOfAnomalyScore(topestRank);
    // label = 'DOWN';
    legend = (
      <span
        key={idx}
        className="anomaly-event-legend"
        style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
      >
        {label}
      </span>
    );
  } else if (rootCauseSource === 'threshold') {
    color = calcColorOfAnomalyScore(topestRank);
    legend = (
      <span
        key={idx}
        className="anomaly-event-legend"
        style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
      >
        {label}
        <UpToTop color="white" />
      </span>
    );
  }

  if (!legend) {
    if (direction === 'higher') {
      legend = (
        <span
          key={idx}
          className="anomaly-event-legend"
          style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
        >
          {label}
          <Up color="white" />
        </span>
      );
    } else {
      legend = (
        <span
          key={idx}
          className="anomaly-event-legend"
          style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
        >
          {label}
          <Down color="white" />
        </span>
      );
    }
  }

  const legendWithTooltip = (
    <Popover
      key={idx}
      mouseEnterDelay={0.3}
      placement="top"
      title={null}
      content={
        <div className="flex-col">
          {R.addIndex(R.map)(
            (l, idx) => (
              <span key={idx} className="flex-grow">
                {l}
              </span>
            ),
            labels,
          )}
        </div>
      }
    >
      <div className="hidden-line-with-ellipsis inline-block">{legend}</div>
    </Popover>
  );
  return { label, legend: legendWithTooltip };
};

// Table cell renderers
const CellRenderers = {
  years: ({ cellData }) => (cellData ? moment.utc(Number(cellData)).format(Defaults.DateTimeFormat) : ''),
  month: ({ cellData }) => (cellData ? moment.utc(Number(cellData)).format(Defaults.MonthFormat) : ''),
  date: ({ cellData }) => (cellData ? moment.utc(Number(cellData)).format(Defaults.DateFormat) : ''),
  time: ({ cellData }) => (cellData ? moment.utc(Number(cellData)).format(Defaults.ShortDateTimeFormat) : ''),
  humanizeDuration: ({
    period,
    intl,
    currentLocale,
    shortDisplay,
    showZero,
    showSeconds,
    showSecondsWithZeroMin,
    showMilliseconds,
    showZeroToOne,
  }) => {
    const parts = [];
    const duration = moment.duration(period);

    // return nothing when the duration is falsy or not correctly parsed (P0D)
    if (!duration || duration.toISOString() === 'P0D') {
      return showZero
        ? `${showZeroToOne ? '1' : '0'} ${shortDisplay ? 's' : intl.formatMessage(appFieldsMessages.second)}`
        : '-';
    } else if (duration < 1000 && showMilliseconds) {
      return `${numeral(period / 1000).format('0.[000]')} ${
        shortDisplay ? 's' : intl.formatMessage(appFieldsMessages.second)
      }`;
    }

    // return default format if no intl
    if (!intl) return duration.locale(currentLocale || 'en').humanize();

    if (duration.years() >= 1) {
      const years = Math.floor(duration.years());
      parts.push(
        `${years}${shortDisplay ? '' : ' '}${
          shortDisplay
            ? 'y'
            : years > 1
            ? intl.formatMessage(appFieldsMessages.years)
            : intl.formatMessage(appFieldsMessages.year)
        }`,
      );
    }
    if (duration.months() >= 1) {
      const months = Math.floor(duration.months());
      parts.push(
        `${months}${shortDisplay ? '' : ' '}${
          shortDisplay
            ? 'm'
            : months > 1
            ? intl.formatMessage(appFieldsMessages.months)
            : intl.formatMessage(appFieldsMessages.month)
        }`,
      );
    }
    if (duration.days() >= 1) {
      const days = Math.floor(duration.days());
      parts.push(
        `${days}${shortDisplay ? '' : ' '}${
          shortDisplay
            ? 'd'
            : days > 1
            ? intl.formatMessage(appFieldsMessages.days)
            : intl.formatMessage(appFieldsMessages.day)
        }`,
      );
    }
    if (duration.hours() >= 1) {
      const hours = Math.floor(duration.hours());
      parts.push(
        `${hours}${shortDisplay ? '' : ' '}${
          shortDisplay
            ? 'h'
            : hours > 1
            ? intl.formatMessage(appFieldsMessages.hours)
            : intl.formatMessage(appFieldsMessages.hour)
        }`,
      );
    }
    if (duration.minutes() >= 1) {
      const minutes = Math.floor(duration.minutes());
      parts.push(
        `${minutes}${shortDisplay ? '' : ' '}${
          shortDisplay
            ? 'm'
            : minutes > 1
            ? intl.formatMessage(appFieldsMessages.minutes)
            : intl.formatMessage(appFieldsMessages.minute)
        }`,
      );
    }
    if (showSeconds && duration.seconds() >= 1) {
      if (showSecondsWithZeroMin && parts.length === 0) {
        const seconds = Math.floor(duration.seconds());
        parts.push(
          `${seconds}${shortDisplay ? '' : ' '}${
            shortDisplay
              ? 's'
              : seconds > 1
              ? intl.formatMessage(appFieldsMessages.seconds)
              : intl.formatMessage(appFieldsMessages.second)
          }`,
        );
      } else if (!showSecondsWithZeroMin) {
        const seconds = Math.floor(duration.seconds());
        parts.push(
          `${seconds}${shortDisplay ? '' : ' '}${
            shortDisplay
              ? 's'
              : seconds > 1
              ? intl.formatMessage(appFieldsMessages.seconds)
              : intl.formatMessage(appFieldsMessages.second)
          }`,
        );
      }
    }

    return parts.join(', ');
  },
  shortTime: ({ cellData }) => (cellData ? moment.utc(Number(cellData)).format(Defaults.ShortTimeFormat) : ''),
  timePair: ({ intl, cellData, rowData, isCorrelation }: Object) => {
    const trs = cellData || [];
    if (trs.length === 0) {
      const { timestamp } = rowData;
      if (timestamp) {
        return <div>{CellRenderers.time({ cellData: timestamp })}</div>;
      }
      return <div />;
    }
    const startTs = trs[0][0];
    const endTs = trs[trs.length - 1][1];
    const showEnd = startTs !== endTs;

    return (
      <Popover
        mouseEnterDelay={0.3}
        placement="right"
        title={null}
        content={
          <div className="flex-col overflow-y-auto" style={{ maxHeight: 240 }}>
            {R.addIndex(R.map)((itemList, idx) => {
              return (
                <div key={idx}>
                  {!isCorrelation && (
                    <span className="light-label bold" style={{ marginRight: 4 }}>
                      {intl.formatMessage(appFieldsMessages.startTime)}:
                    </span>
                  )}
                  <span>{CellRenderers.time({ cellData: itemList[0] })}</span>
                  {isCorrelation && <span style={{ margin: '0 4px' }}>~</span>}
                  {!isCorrelation && (
                    <span className="light-label bold" style={{ marginRight: 4, marginLeft: 8 }}>
                      {intl.formatMessage(appFieldsMessages.endTime)}:
                    </span>
                  )}
                  <span>{CellRenderers.time({ cellData: itemList[1] })}</span>
                </div>
              );
            }, trs)}
          </div>
        }
      >
        <div className="flex-col" style={{ margin: '4px 0' }}>
          <div className="flex-grow">
            {showEnd && !isCorrelation && (
              <span className="light-label bold inline-block" style={{ width: 40 }}>
                {intl.formatMessage(appFieldsMessages.start)}:
              </span>
            )}
            {moment.utc(Number(startTs)).format(Defaults.ShortTimeOnlyFormat)}
          </div>
          {isCorrelation && <div>~</div>}
          {(showEnd || isCorrelation) && (
            <div className="flex-grow">
              {showEnd && !isCorrelation && (
                <span className="light-label bold inline-block" style={{ width: 40 }}>
                  {intl.formatMessage(appFieldsMessages.end)}:
                </span>
              )}
              {moment.utc(Number(endTs)).format(Defaults.ShortTimeOnlyFormat)}
            </div>
          )}
        </div>
      </Popover>
    );
  },
  timeCountMap: ({ cellData, rowData }: Object) => {
    const timestampMap = cellData || {};
    const timestamps = R.sort((a, b) => a - b, R.keys(timestampMap));
    const { startTime, endTime } = rowData;
    return (
      <Popover
        mouseEnterDelay={0.3}
        placement="right"
        title={null}
        content={
          <div className="flex-col" style={{ fontSize: 12, width: 200, maxHeight: 250, overflowY: 'auto' }}>
            {R.addIndex(R.map)((timestamp, idx) => {
              return (
                <div key={idx}>{`Time: ${CellRenderers.time({ cellData: timestamp })} Count:${
                  timestampMap[timestamp]
                }`}</div>
              );
            }, timestamps)}
          </div>
        }
      >
        <div className="flex-col">
          <div className="flex-grow">{CellRenderers.time({ cellData: startTime })}</div>
          <div className="flex-grow">{CellRenderers.time({ cellData: endTime })}</div>
        </div>
      </Popover>
    );
  },
  probability: ({ cellData }) => {
    if (cellData === undefined) return 'NA';
    const n = cellData;

    if (isFinite(n)) {
      return `${(n * 100).toFixed(1)}%`;
    }
    return 'NA';
  },
  withTooltip: ({ cellData }: any) => {
    return (
      <Popover mouseEnterDelay={0.3} placement="top" title={null} content={<div>{cellData}</div>}>
        <div className="hidden-line-with-ellipsis inline-block">{cellData}</div>
      </Popover>
    );
  },
  anomalyEventDurationRenderer: ({ rowData }) => {
    const { startTimestamp, endTimestamp } = rowData;
    let { duration } = rowData;
    if (!duration && startTimestamp && endTimestamp) {
      duration = (endTimestamp - startTimestamp) / 1000 / 60;
    }
    if (!duration) {
      return 'N/A';
    }
    return moment.duration(duration, 'minutes').humanize();
  },
  anomalyScoreRenderer: ({ rowData }) => {
    const { anomalyRatio } = rowData;
    if (!isNumber(anomalyRatio)) {
      return anomalyRatio;
    }
    return anomalyRatio.toFixed(1);
  },

  alertLevelShortRenderer: ({ intl, severity, style = {} }: Object) => {
    if (!severity) return null;

    let color = 'gray';
    const label = logMessages[severity] ? intl.formatMessage(logMessages[severity]) : severity;
    switch (severity) {
      case 'critical':
        color = Defaults.Colorbrewer[1];
        break;
      case 'major':
        color = 'orange';
        break;
      default:
        break;
    }

    return (
      <Popover key={severity} placement="top" mouseEnterDelay={0.3} content={label}>
        <Tag color={color} style={{ margin: 0 }}>
          {label}
        </Tag>
      </Popover>
    );
  },
  logShortTypeRenderer: ({ intl, type, style = {}, usePopover = true }: Object) => {
    if (!type) return null;

    const typeWord = R.toLower(type);
    let shortLabel = R.toUpper(type[0]);

    let color = 'orange';
    let label = logMessages[typeWord] ? intl.formatMessage(logMessages[typeWord]) : typeWord;
    switch (typeWord) {
      case 'rare':
        color = '#f2711c';
        break;
      case 'normal':
        color = 'gray';
        break;
      case 'cold':
        color = Defaults.Colorbrewer[9];
        break;
      case 'hot':
        color = 'var(--red)';
        shortLabel = 'H';
        break;
      case 'critical':
        color = Defaults.Colorbrewer[1];
        break;
      case 'incident':
        color = 'red';
        break;
      case 'deployment':
        shortLabel = 'C';
        break;
      case 'ublanomaly':
        label = intl.formatMessage(logMessages.metricanomaly);
        break;
      case 'metric':
        label = intl.formatMessage(logMessages.metricanomaly);
        break;
      case 'whitelist':
        label = intl.formatMessage(logMessages.keywordAlerts);
        shortLabel = 'K';
        break;
      default:
        break;
    }

    return (
      <>
        {usePopover ? (
          <Popover key={type} placement="top" mouseEnterDelay={0.3} content={label}>
            <span className="short-tag" style={{ color, borderColor: color, ...style }}>
              {shortLabel}
            </span>
          </Popover>
        ) : (
          <span className="short-tag" style={{ color, borderColor: color, ...style }}>
            {shortLabel}
          </span>
        )}
      </>
    );
  },
  logTypeRenderer: ({
    intl,
    rowData,
    isPrediction,
    withPredictionPrefix,
    isFixedIncident,
    isHorizontal,
    showAbbr,
  }: Object) => {
    const { type: eventType, typeList: logTypeList = [] } = rowData;
    const tlist = R.uniq(R.map((x) => x.toLowerCase(), [...R.split('&', eventType || ''), ...logTypeList]));
    const typeList = R.map((word) => {
      let typeWord = R.toLower(word);
      let typeInfo = null;
      if (R.indexOf('(', typeWord) > 0) {
        [typeWord, typeInfo] = typeWord.split('(');
        typeInfo = typeInfo.split(')')[0];
      }
      return { typeWord, typeInfo };
    }, tlist);
    const typeShort = (ty) => {
      const t = ty.toLowerCase();
      if (t === 'whitelist') return 'K';
      else if (t === 'newpattern') return 'N';
      else if (t === 'new') return 'N';
      else if (t === 'normal') return 'N';
      else if (t === 'incident') return 'I';
      else if (t === 'critical') return 'C';
      else if (t === 'logcoldevent') return 'C';
      else if (t === 'logHotEvent') return 'H';
      else if (t === 'lograreevent') return 'R';
      else return ty;
    };

    const legends = [];
    R.addIndex(R.forEach)((type, index) => {
      let color = 'orange';
      let label = type.typeWord;
      if (type.typeWord === 'rare') {
        color = '#f2711c';
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages[type.typeWord]);
      } else if (type.typeWord === 'normal') {
        color = 'gray';
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages[type.typeWord]);
      } else if (type.typeWord === 'hot') {
        color = 'orange';
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages[type.typeWord]);
      } else if (type.typeWord === 'cold') {
        color = Defaults.Colorbrewer[9];
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages[type.typeWord]);
      } else if (type.typeWord === 'critical') {
        color = Defaults.Colorbrewer[1];
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages[type.typeWord]);
        label = type.typeInfo ? `${label}(${type.typeInfo})` : label;
      } else if (type.typeWord === 'whitelist') {
        color = 'orange';
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages.keywordAlerts);
        label = type.typeInfo ? `${label}(${type.typeInfo})` : label;
      } else if (type.typeWord === 'newpattern') {
        color = 'orange';
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages[type.typeWord]);
      } else if (type.typeWord === 'incident') {
        color = 'red';
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages[type.typeWord]);

        label = `${
          withPredictionPrefix
            ? isPrediction
              ? `${intl.formatMessage(DashboardMessages.predicted)} `
              : `${intl.formatMessage(DashboardMessages.detected)} `
            : ''
        }${label}`;
        if (isFixedIncident) {
          label = `${intl.formatMessage(DashboardMessages.fixed)} ${label}`;
        }
      } else if (type.typeWord === 'deployment') {
        color = 'orange';
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages[type.typeWord]);
      } else if (type.typeWord === 'ublanomaly') {
        color = 'orange';
        label = showAbbr ? typeShort(type.typeWord) : intl.formatMessage(logMessages.metricanomaly);
      }

      if (logDescMessages[type.typeWord]) {
        legends.push(
          <Popover
            key={index}
            title={null}
            content={intl.formatMessage(logDescMessages[type.typeWord])}
            mouseEnterDelay={0.3}
          >
            <Tag
              color={color}
              className="max-width hidden-line-with-ellipsis"
              style={{ margin: isHorizontal ? '0 4px 0 0' : '4px 0' }}
            >
              {label}
            </Tag>
          </Popover>,
        );
      } else {
        legends.push(
          <Tag
            key={index}
            color={color}
            className="max-width hidden-line-with-ellipsis"
            style={{ margin: isHorizontal ? '0 4px 0 0' : '4px 0' }}
          >
            {label}
          </Tag>,
        );
      }
    }, typeList);
    return legends;
  },
  metricTypeRenderer: ({ rowData }: Object) => {
    const { metricType } = rowData;

    const elems = [];
    R.addIndex(R.forEach)((e, idx) => {
      const { legend } = anomalyTypeRendererItem(e, idx);
      elems.push(legend);
    }, metricType || []);

    return (
      <div className="white-pre" style={{ margin: '4px 0' }}>
        {elems}
      </div>
    );
  },
  anomalySeverityRenderer: ({ rowData }: Object) => {
    const topestRank = 100;
    const blueColor = '#2185d0';
    const allRootCauseDetailList = R.map(
      (r) => ({ ...r, name: r.appName ? `${r.appName}(${r.instanceId})` : r.instanceId, pct: parseFloat(r.pct) }),
      get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []),
    );

    const rcMap = {};
    R.forEach((e) => {
      const { pct, rootCauseMetric, direction, rootCauseSource } = e;

      if (true) {
        // if (rootCauseSource !== 'missing data' && rootCauseSource !== 'threshold') {
        const key = `${rootCauseMetric}_${direction}`;
        if (rcMap[key]) {
          rcMap[key].pct = R.max(rcMap[key].pct, pct);
        } else {
          rcMap[key] = { pct, rootCauseMetric, direction, rootCauseSource };
        }
      }
    }, allRootCauseDetailList);

    const elems = [];

    R.addIndex(R.forEach)((e, idx) => {
      const { legend } = anomalySeverityRendererItem(e, idx, blueColor, topestRank);
      elems.push(legend);
    }, R.values(rcMap));

    return (
      <div className="white-pre" style={{ margin: '4px 0' }}>
        {elems}
      </div>
    );
  },
  anomalyLogInstanceRenderer: (rowData, instanceInfo) => {
    const { appName, instanceName } = instanceInfo;
    let shortAppName = appName;
    if (R.indexOf('(', shortAppName) >= 0) {
      shortAppName = shortAppName.split('(')[0];
    }
    shortAppName = shortAppName.length > 18 ? `${R.take(18, shortAppName)}..` : shortAppName;

    let type = get(rowData, 'type', '');
    type = R.toLower(type);
    let color = 'gray';
    if (type.indexOf('rare') >= 0) {
      color = '#f2711c';
    } else if (type.indexOf('hot') >= 0) {
      color = 'orange';
    } else if (type.indexOf('cold') >= 0) {
      color = Defaults.Colorbrewer[9];
    } else if (type.indexOf('critical') >= 0) {
      color = Defaults.Colorbrewer[1];
    } else if (type.indexOf('whitelist') >= 0) {
      color = 'orange';
    } else if (type.indexOf('newpattern') >= 0) {
      color = 'orange';
    } else if (type.indexOf('incident') >= 0) {
      color = 'red';
    } else if (type.indexOf('deployment') >= 0) {
      color = 'orange';
    }

    return (
      <div className="white-pre" style={{ margin: '4px 0' }}>
        <Popover mouseEnterDelay={0.3} placement="top" title={null} content={<div>{instanceName}</div>}>
          <div
            className="hidden-line-with-ellipsis inline-block anomaly-event-legend"
            style={{ border: `1px solid ${color}`, background: color, color: 'white' }}
          >
            {shortAppName}
          </div>
        </Popover>
      </div>
    );
  },
  anomalyInstanceRenderer: ({ rowData }: Object) => {
    const topestRank = 100;
    const blueColor = '#2185d0';
    const allRootCauseDetailList = R.map(
      (r) => ({
        ...r,
        name: r.appName && r.appName !== r.instanceId ? `${r.appName}(${r.instanceId})` : r.instanceId,
        pct: parseFloat(r.pct),
      }),
      get(rowData, ['rootCauseJson', 'rootCauseDetailsArr'], []),
    );
    const rcMap = {};
    R.forEach((e) => {
      const { name, pct, rootCauseMetric, direction, rootCauseSource } = e;
      if (true) {
        // if (rootCauseSource !== 'missing data' && rootCauseSource !== 'threshold') {
        const sidx = name.indexOf('(');
        const label = sidx > 0 ? name.split('(')[1].split(')')[0] : name;
        const appName = R.split('(', name)[0];
        const key = `${appName}_${direction}`;
        if (rcMap[key]) {
          rcMap[key].pct = R.max(rcMap[key].pct, pct);
          rcMap[key].labels.push(label);
        } else {
          rcMap[key] = { name, pct, rootCauseMetric, direction, rootCauseSource, labels: [label] };
        }
      }
    }, R.take(3, allRootCauseDetailList));

    const elems = [];
    R.addIndex(R.forEach)((e, idx) => {
      const { legend } = anomalyInstanceRendererItem(e, idx, blueColor, topestRank);
      elems.push(legend);
    }, R.values(rcMap));

    return (
      <div className="flex-col" style={{ margin: '4px 0' }}>
        {elems}
        {allRootCauseDetailList.length > 3 ? <div>...</div> : null}
      </div>
    );
  },
  logContent: LogContent,
};

export { CellRenderers, anomalySeverityRendererItem };
