/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import VLink from 'valuelink';
import { isInteger } from 'lodash';
import momenttz from 'moment-timezone';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { RightOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Switch,
  Form,
  Input as AntInput,
  TreeSelect,
  Checkbox,
  Divider,
  Select as AntSelect,
} from 'antd';

import { appButtonsMessages, appMessages } from '../../../../common/app/messages';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';

const SPLITKEY = '&SPLITKEY&';

type Props = {
  intl: Object,
  credentials: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  componentState: Object,
};

class NagiosSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;

    const state = {
      dataType: 'nagiosXIAlert',

      verified: false,
      isVerifying: false,
      hostURL: '',
      apiKey: '',
      hosts: [],
      hostOptions: [],
      instanceTimezone: 'Etc/GMT',
    };
    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);
    this.state = {
      ...state,

      ...(componentState || {}),
    };

    this.dataTypeOptions = [{ label: 'Alert', value: 'nagiosXIAlert' }];

    this.filterHostOptions = {};
    this.allHosts = [];

    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
  }

  componentDidMount() {
    this.props.saveProjectInfo('Nagios', { dataType: this.state.dataType }, this.state);
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      isVerifying: true,
    });
    const { intl, credentials } = this.props;
    const { dataType, hostURL, apiKey } = this.state;
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType: dataType,
      hostURL,
      apiKey,
    })
      .then((d) => {
        const { success, message: errMsg } = d || {};
        if (success === undefined || success) {
          this.filterHostOptions = d;
          this.parseHostOptions(d);

          this.setState(
            {
              hosts: [],
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
            },
            () => {
              this.props.saveProjectInfo('Nagios', { dataType }, this.state);
            },
          );
        } else {
          this.filterHostOptions = {};
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
              hostOptions: [],
              hosts: [],
            },
            () => {
              this.props.saveProjectInfo('Nagios', { dataType }, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.filterHostOptions = {};
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: `${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`,
            hosts: [],
            hostOptions: [],
          },
          () => {
            this.props.saveProjectInfo('Nagios', { dataType }, this.state);
          },
        );
      });
  }

  @autobind
  parseHostOptions(data, searchValue) {
    data = R.clone(data);
    const { hostGroups = {} } = data || {};
    let { hosts = [] } = data || {};

    let uniqHost = [];

    R.forEachObjIndexed((values, key) => {
      uniqHost = [...uniqHost, ...values];
    }, hostGroups);
    hosts = R.difference(hosts, uniqHost);

    const hostOptions = [];
    if (hosts.length > 0) {
      hostGroups.unknow = hosts;
    }

    R.addIndex(R.forEachObjIndexed)((value, key, record, index) => {
      const children = [];
      R.forEach((item) => {
        if (searchValue && item.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
          children.push({ key: `${key}${SPLITKEY}${item}`, value: `${key}${SPLITKEY}${item}`, label: item });
        } else if (!searchValue) {
          children.push({ key: `${key}${SPLITKEY}${item}`, value: `${key}${SPLITKEY}${item}`, label: item });
        }
      }, value || []);

      hostOptions.push({ key, value: key, label: key, children });
    }, hostGroups || {});

    let allHosts = [];
    R.forEachObjIndexed((value, key) => {
      allHosts = [...allHosts, ...R.map((item) => item.value, value.children)];
    }, hostOptions);
    this.allHosts = allHosts;
    this.setState({
      hostOptions,
    });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { dataType, hosts, hostURL, apiKey, instanceTimezone } = this.state;
    const hostObj = {};
    R.forEach((item) => {
      try {
        const [service, host] = item.split(SPLITKEY);
        hostObj[host] = service === 'unknow' ? '' : service;
      } catch (error) {
        console.log(error);
      }
    }, hosts);
    this.props.createProject(
      dataType === 'nagiosXIAlert' ? 'Nagios' : 'InsightAgent',
      {
        dataType,
        hosts: JSON.stringify(hostObj),
        hostURL,
        apiKey,
        projectCreationType: dataType,
        instanceTimezone,
      },
      this.state,
    );
  }

  @autobind
  handleDataTypeChange(dataType) {
    this.setState({ dataType }, () => {
      this.props.saveProjectInfo('nagios', { dataType }, this.state);
    });
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { intl, isLoading } = this.props;
    const { dataType, verified, isVerifying, hostURL, apiKey, hostOptions, hosts, instanceTimezone } = this.state;

    const isAlert = dataType === 'nagiosXIAlert';

    const hasVerifyError = isAlert ? dataType.error || !hostURL || !apiKey : false;
    const hasAlertError = hasVerifyError || this.props.hasError || !hosts.length;

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <Card>
          <Form layout="vertical">
            <Form.Item required label="Data Type">
              <AntSelect
                style={{ width: '100%' }}
                showSearch
                options={this.dataTypeOptions}
                value={dataType}
                onChange={this.handleDataTypeChange}
              />
            </Form.Item>
            {isAlert && (
              <>
                <Form.Item required label="Service URL">
                  <AntInput.Password value={hostURL} onChange={(e) => this.setState({ hostURL: e.target.value })} />
                </Form.Item>

                <Form.Item required label="Api key">
                  <AntInput.Password value={apiKey} onChange={(e) => this.setState({ apiKey: e.target.value })} />
                </Form.Item>
              </>
            )}
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>

        <Card className={`${verified && isAlert ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            <Form.Item required label="Hosts">
              <TreeSelect
                className="no-count-num"
                treeDefaultExpandAll
                autoClearSearchValue={false}
                treeData={hostOptions}
                showSearch
                multiple
                treeCheckable
                value={hosts}
                onChange={(hosts) => this.setState({ hosts })}
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                style={{ width: '100%' }}
                onSearch={(val) => {
                  this.parseHostOptions(this.filterHostOptions, val);
                }}
                dropdownRender={(menu) => {
                  return (
                    <div>
                      <div
                        className="flex-row"
                        style={{ padding: '5px 12px' }}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={hosts.length === this.allHosts.length}
                          onChange={(e) => {
                            const { checked } = e.target;
                            this.setState({
                              hosts: checked ? this.allHosts : [],
                            });
                          }}
                        />
                        Select all
                      </div>
                      <Divider style={{ margin: '4px 0' }} />
                      {menu}
                    </div>
                  );
                }}
              />
            </Form.Item>

            <Form.Item label="Instance timezone">
              <AntSelect
                options={this.timezoneOptions}
                showSearch
                value={instanceTimezone}
                onChange={(instanceTimezone) => this.setState({ instanceTimezone })}
              />
            </Form.Item>
          </Form>
        </Card>

        <div style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}>
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={hasAlertError}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const DynatraceSetting = injectIntl(NagiosSettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(DynatraceSetting);
