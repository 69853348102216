/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { get, isArray, isObject } from 'lodash';
import { injectIntl } from 'react-intl';
import { push, replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { HomeOutlined, ForwardFilled, BackwardFilled, LeftOutlined } from '@ant-design/icons';
import { Button, Select, DatePicker, Tooltip, Breadcrumb, message, Spin, Empty, Tabs } from 'antd';

import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { parseLocation, Defaults, buildUrl, getLoadStatus, GlobalParse, timeScopeControl } from '../../../common/utils';
import {
  showAppAlert,
  createLoadAction,
  createSetAction,
  updateLastActionInfo,
  ActionTypes as AppActionTypes,
} from '../../../common/app/actions';
import { State } from '../../../common/types';
import { ActionTypes } from '../../../common/dashboard/actions';
import { BaseUrls } from '../../app/Constants';
import { AutoSizer, Container } from '../../../lib/fui/react';

import { appFieldsMessages, appButtonsMessages, appMessages, appMenusMessages } from '../../../common/app/messages';

import SystemHealthRow from './SystemHealthRow';
import GlobalCronSelectModal from './GlobalCronSelectModal';
import RecentLogsSelectorModal from './RecentLogsSelectorModal';

import GlobalZoomTimelins from './GlobalZoomTimelins';

import GlobalPanelLineChart from './GlobalPanelLineChart';
import GlobalPanelLogQuery from './GlobalPanelLogQuery';
import GlobalPanelHistoricalMaxScores from './GlobalPanelHistoricalMaxScores';

// new
import PredictedIncidentList from './PredictedIncidentList';
import GlobalDetectedIncident from './GlobalDetectedIncident';
import GlobalAlert from './GlobalAlert';
import GlobalSystemChartView from './GlobalSystemChartView';

import GlobalDetectedAnomalies from './GlobalDetectedAnomalies';
import { UHV_PANEL_HEIGHT } from './GlobalConstants';
import fetchPost from '../../../common/apis/fetchPost';
import { eventMessages } from '../../../common/metric/messages';
import LikelyRootCausesRCA from './LikelyRootCausesRCA';

const SortableItem = (props: Object) => {
  const { Component, anchorId } = props;
  return <div id={anchorId}>{React.createElement(Component, { ...props })}</div>;
};

const SortableList = (props: Object) => {
  const { items, width, height } = props;
  return (
    <div style={{ width, height }}>
      {(items || []).map((item, index) => {
        const idx = index;
        const { component, data } = item;
        const allProps = { ...props, ...data, sortIndex: index, width };
        return <SortableItem key={`dragitem${idx}`} index={index} Component={component} {...allProps} />;
      })}
    </div>
  );
};

const activeKeyMap = {
  prediction: 'Incidents',
  incident: 'Incidents',
  changes: 'Changes',
  anomolie: 'Anomalies',
  alerts: 'Anomalies',
  traces: 'Traces',
};

const activeTypeMap = {
  prediction: 'predictedIncident',
  incident: 'detectedIncident',
  changes: 'change',
  anomolie: 'detectedAnomalies',
  alert: 'alert',
};

type Props = {
  // eslint-disable-next-line
  refresh: Number,
  handleRefresh: Function,
  handleCustomerNameChange: Function,
  // eslint-disable-next-line
  handleEnvironmentChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  location: Object,
  loadStatus: Object,
  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  showAppAlert: Function,
  credentials: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  // eslint-disable-next-line
  isReadUser: Boolean,
  userName: String,
  userList: Array<Object>,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  createSetAction: Function,
  updateLastActionInfo: Function,

  globalInfo: Array<Object>,
  globalHealthSummary: Array<Object>,
  globalHealthByInstance: Array<Object>,
  globalSystemIncidentTimelines: Array<Object>,
  globalPredictedIncidentList: Array<Object>,
  globalSystemDetectIncidentTimelines: Array<Object>,
  globalSystemAlertTimelines: Array<Object>,
  globalSystemTraceTimelines: Array<Object>,
  globalSystemAnomalyTimelines: Array<Object>,
  defaultTimezone: String,
  systemsMap: Object,
  userInfo: Object,
  projects: Array<Object>,
};

class GlobalSystemHealthViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.dataLoader = 'dashboard_global_system_health_view';

    const { location } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;
    this.healthRow = React.createRef();
    this.MINHTABLEEIGHT = 500;
    this.PROJECTCHARTHEIGHT = 200;
    this.cacheProjectList = [];

    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      endTimeOpen: false,
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      view: 'list',
      currentSystem: null,
      showLatestLogsSelector: false,
      showTimeSelectModal: false,
      selectStartTimestamp: null,
      selectEndTimestamp: null,

      isLoadingZoomIn: false,
      zoomStartTime: null,
      zoomEndTime: null,

      selectSystemHealthInfo: null,
      systemLoading: {},
      systemAnomalyInstance: {},
      reloadSystem: null,
      systemRankMap: {},
      systemAlertTimelinesMap: {},
      systemIncidentTimelinesMap: {},
      systemPredictionControl: {},
      systemDetectedIncidentsControl: {},

      isForceReload: false,
      isAutoReload: false,

      globalHealthSummary: props.globalHealthSummary,

      activeKey: 'Incidents',

      summarySettingsMap: {},
      logsummaryLoading: false,

      updateModelLoading: false,

      dataTotalCount: [],

      isLoadingDetectedIncident: false, // detected incidents
      isLoadingPanelAnomalies: false, // detected anomalies
      isLoadingPanelAlert: false, // alert / changes
      showCollapse: true,
      showProjectChart: true,

      updateDetectedIncident: true,
      updateAlert: true,
      updateChange: true,
      updateTrace: true,
      updateDetectedAnomalies: true,

      dragMap: { Incidents: [], Changes: [], Anomalies: [], Traces: [] },

      activeProjectName: null,
      activeProjectType: null,
      activeAnomalies: false,

      projectDisplayMap: {},
      activeLineIconItem: null,
      activeLineIconKey: null,

      showLikelyRootCausesRCA: false,
      activeEvent: null,
      needRC: false,

      rcaFlag: true,
    };
  }

  async componentDidMount() {
    const projectDisplayMap = await this.getProjectDisplayList();
    this.setState({ projectDisplayMap }, () => {
      this.reloadData(this.props, { isForceReload: true });
      this.demandLoad(this.state.activeKey, this.props, { isAutoReload: false });
      this.getReloadDataTotalCount();
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location, globalHealthByInstance, globalPredictedIncidentList } = this.props;
    const { zoomStartTime, zoomEndTime, activeKey } = this.state;
    if (nextProps.location !== location) {
      const nextQuery = parseLocation(nextProps.location);
      const query = parseLocation(location);
      if (
        nextProps.refresh !== this.props.refresh ||
        nextQuery.refreshTime !== query.refreshTime ||
        nextQuery.environmentId !== query.environmentId ||
        nextQuery.customerName !== query.customerName ||
        nextQuery.systemId !== query.systemId
      ) {
        const isForceReload = nextProps.refresh !== this.props.refresh;
        const isAutoReload = nextQuery.refreshTime !== query.refreshTime;
        // not reload data when zoom in
        if (!isAutoReload || (isAutoReload && !zoomStartTime && !zoomEndTime)) {
          this.cacheProjectList = [];
          this.setState(
            {
              updateDetectedIncident: true,
              updateAlert: true,
              updateChange: true,
              updateTrace: true,
              updateDetectedAnomalies: true,
              dragMap: { Incidents: [], Changes: [], Anomalies: [], Traces: [] },
              activeAnomalies: false,
            },
            () => {
              this.reloadData(nextProps, { isForceReload, isAutoReload });
              this.demandLoad(activeKey, nextProps, { isForceReload, isAutoReload });
            },
          );
        }
        this.getReloadDataTotalCount();
      }
    } else if (nextProps.globalHealthByInstance !== globalHealthByInstance) {
      this.parserSystemInstanceHealth(nextProps);
    }
    if (globalPredictedIncidentList !== nextProps.globalPredictedIncidentList) {
      this.parsePredictedIncidentData(nextProps.globalPredictedIncidentList, nextProps);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { globalHealthSummary, location, globalPredictedIncidentList, globalInfo } = this.props;
    const { reloadSystem, systemAnomalyInstance } = this.state;
    const { systemId, environmentId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (reloadSystem !== prevState.reloadSystem || globalHealthSummary !== prevProps.globalHealthSummary) {
      // reload incident timelins if needed
      this.reorderDisplay({ ...this.props, isForceReload: true });
    }
    if (prevState.systemAnomalyInstance !== systemAnomalyInstance) {
      this.filterAllInstance({ globalPredictedIncidentList, systemInfo, systemId });
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  getProjectDisplayList() {
    const { projects } = this.props;
    const projectDisplayMap = {};
    R.forEach((p) => {
      if (!R.has(p.projectShortName, projectDisplayMap)) projectDisplayMap[p.projectShortName] = p.projectDisplayName;
    }, projects || []);
    return projectDisplayMap;
  }

  @autobind
  async demandLoad(activeKey, props, params) {
    props = props || this.props;
    const {
      globalSystemAlertTimelines,
      globalSystemDetectIncidentTimelines,
      globalSystemAnomalyTimelines,
      globalSystemDeployTimelines,
    } = props;
    const { updateDetectedIncident, updateAlert, updateChange, updateTrace, updateDetectedAnomalies, activeAnomalies } =
      this.state;
    const { activeProjectName, activeProjectType, projectDisplayMap } = this.state;
    const isEmpty = (params) => R.isEmpty(params) || R.isNil(params);
    const findAnomaliesProject = R.find(
      (item) => `${item.projectName}-${item.type}` === `${activeProjectName}-${activeProjectType}`,
      this.cacheProjectList,
    );

    switch (activeKey) {
      case 'Incidents':
        if (isEmpty(globalSystemDetectIncidentTimelines) || updateDetectedIncident) {
          this.reloadDetectedIncidentData(props, { ...params, projectDisplayMap });
        }
        if (isEmpty(globalSystemDeployTimelines) || updateChange) {
          this.reloadDeployData(props, { ...params, projectDisplayMap });
        }
        break;
      case 'Changes':
        if (isEmpty(globalSystemDeployTimelines) || updateChange) {
          this.reloadDeployData(props, { ...params, projectDisplayMap });
        }
        break;
      case 'Anomalies':
        if (!activeAnomalies) {
          if (!findAnomaliesProject) {
            await this.reloadAlertData(props, { ...params, filterProjectList: activeProjectName, projectDisplayMap });
            await this.reloadDetectedAnomalyData(props, {
              ...params,
              filterProjectList: activeProjectName,
              projectDisplayMap,
            });
          }
        } else {
          if (isEmpty(globalSystemAlertTimelines) || updateAlert) {
            await this.reloadAlertData(props, { ...params, projectDisplayMap });
          }
          if (isEmpty(globalSystemAnomalyTimelines) || updateDetectedAnomalies) {
            await this.reloadDetectedAnomalyData(props, { ...params, projectDisplayMap });
          }
        }

        break;
      default:
        break;
    }
  }

  @autobind
  filterAllInstance({ globalPredictedIncidentList, systemInfo, systemId }) {
    this.setState({ dragMap: { Incidents: [], Changes: [], Anomalies: [], Traces: [] } }, () => {
      this.parsePredictedIncidentData(globalPredictedIncidentList, { ...this.props, systemId }).then((res) => {
        this.parseDetectedIncidentData({ ...this.props, systemInfo, systemId }).then((res2) => {
          this.parseAlertData({ ...this.props, systemInfo, systemId }).then((res3) => {
            this.parseAnomaliesData({ ...this.props, systemInfo, systemId }).then((res4) => {
              this.parseAnomaliesData({ ...this.props, systemInfo, systemId, isAlertAnomalies: true });
            });
          });
        });
      });
    });
  }

  @autobind
  reloadData(props, params) {
    const { createLoadAction, location, isAdmin } = props;
    const query = parseLocation(location);
    const { environmentId, startTime, endTime, customerName, systemId } = query;
    const { isForceReload, isAutoReload } = params;
    const { systemLoading } = this.state;
    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId) {
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const newSystemLoading = R.clone(systemLoading);
      if (!isAutoReload) newSystemLoading[systemId] = true;
      this.setState({ isForceReload, isAutoReload, systemLoading: newSystemLoading }, () => {
        createLoadAction(
          ActionTypes.LOAD_GLOBAL_HEALTH,
          {
            isAutoReload,
            environmentId,
            customerName,
            systemIds: [systemId],
            startTimestamp,
            endTimestamp,
          },
          false,
          false,
          isAutoReload ? null : this.callbackSetHealthScoreState(),
        );
      });
    }
  }

  @autobind
  callbackSetHealthScoreState() {
    return () => {
      const { location, globalInfo } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo && !systemInfo.hasAllInstanceInfo) {
        this.loadSystemData();
      }
    };
  }

  @autobind
  callbackAlertState() {
    return () => {};
  }

  @autobind
  loadSystemData() {
    const { location, createLoadAction } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime, environmentId, systemId } = query;

    createLoadAction(
      AppActionTypes.LOAD_INFO_SYSTEM,
      {
        startTime: moment.utc(startTime, Defaults.DateFormat).startOf('day').format(Defaults.DateFormat),
        endTime: moment.utc(endTime, Defaults.DateFormat).endOf('day').format(Defaults.DateFormat),
        environmentId,
        systemName: systemId,
        // customerName,
        anomalyInstanceOnly: false,
      },
      false,
      true,
      this.callbackHandleSystemUpdate,
    );
  }

  @autobind
  reloadDetectedIncidentData(props, params) {
    this.setState({ isLoadingPanelIncident: true, isLoadingDetectedIncident: true });

    const { createLoadAction, location, isAdmin } = props;
    const query = parseLocation(location);
    const { environmentId, startTime, endTime, customerName, systemId } = query;
    const { isAutoReload, projectDisplayMap } = params;

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId) {
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      createLoadAction(
        ActionTypes.LOAD_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES,
        {
          isAutoReload,
          level: 'system',
          environmentId,
          systemIds: [systemId],
          startTime: startTimestamp,
          endTime: endTimestamp,
          projectDisplayNameMap: projectDisplayMap,
        },
        false,
        false,
        this.callbackSetDetectedIncidentTimelineState(),
      );
    }
  }

  @autobind
  callbackSetDetectedIncidentTimelineState() {
    return () => {
      const { location, globalInfo } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo) {
        this.parseDetectedIncidentData({ ...this.props, systemInfo });
      }
    };
  }

  @autobind
  async parseDetectedIncidentData(props) {
    const { globalSystemDetectIncidentTimelines, systemInfo, credentials, systemId } = props;
    const { sortBy, sortDirection, systemAnomalyInstance, summarySettingsMap, dragMap } = this.state;

    // parse anomaly timelines
    const systemIncidentTimelines = (
      R.find((item) => item.id === systemInfo.id, globalSystemDetectIncidentTimelines) || {}
    ).anomalyTimelines;

    let events = R.filter(
      (event) => R.toLower(event.type) === 'incident' && event.timeLineType !== 'future',
      systemIncidentTimelines || [],
    );

    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];

      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const instanceName = pair.instanceId || pair.instanceName;
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.instanceName} (${componentName})`
            : item.instanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);

      let typeList = [];
      if (item.category === 'metric' && item.type !== 'Incident') {
        typeList = ['metricanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', item.type).split('&'));
      }

      // get status
      const { rootCauseResultInfo } = item;
      const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');
      const hasTrailing = get(rootCauseResultInfo, 'hasTrailingEvent');
      const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');

      return {
        ...item,

        rootCauseJson: { rootCauseDetailsArr },
        appName,
        instanceAppNames,
        componentNameString,
        componentList,
        typeList,
        hasRootCause,
        hasTrailing,
        causedByChange,
        eventsCount: R.sum(R.values(item.timestampMap || {})) || 1,
        lastSeenTime: item.endTimestamp,
      };
    }, events);

    let eventList = this.filterDetectedData(events);
    // not merge
    eventList = R.map((item) => {
      return { ...item, timelines: [item] };
    }, eventList);
    // merge
    eventList = this.mergeDetectedIncidentEvents(props, eventList);
    // add rank number
    eventList = R.sortWith(
      [
        R.descend(R.prop('isNewAlert')),
        R.descend(R.prop('causedByChange')),
        R.descend(R.prop('hasRootCause')),
        R.descend(R.prop('eventsCount')),
        R.ascend(R.prop('patternId')),
      ],
      eventList,
    );
    const rankMap = {};
    const labelKeyMap = {};
    let rank = 1;
    eventList = R.addIndex(R.map)((event, index) => {
      if (!R.has(event.labelKey, labelKeyMap)) {
        labelKeyMap[event.labelKey] = rank;
        rank += 1;
      }
      R.forEach((item) => {
        rankMap[item.id] = labelKeyMap[event.labelKey];
      }, event.timelines);
      return { ...event, rank: labelKeyMap[event.labelKey] };
    }, eventList);

    // default display alerts
    const incidents = [];
    eventList = R.addIndex(R.map)((event, index) => {
      const { timelines } = event;
      R.forEach((item) => {
        incidents.push(item);
      }, timelines || []);
      return { ...event, isDisplayInChart: true };
    }, eventList);
    this.handleUpdateSystemRankMap(systemInfo.id, { rankMap, incidents });

    // sort
    eventList = this.sortDetectedIncidentData(eventList, sortBy, sortDirection);

    let allTimeline = [];
    R.forEach((item) => {
      const { timelines } = item;
      if (timelines) {
        R.forEach((timeline) => allTimeline.push(timeline), timelines);
      }
    }, eventList);

    // filter
    const selectAnomalyInstance = systemAnomalyInstance[systemId];
    if (systemId && selectAnomalyInstance) {
      allTimeline = R.filter((anomaly) => {
        return (
          anomaly.instanceList.includes(selectAnomalyInstance) ||
          anomaly.instanceName.indexOf(selectAnomalyInstance) >= 0
        );
      }, allTimeline);
    }
    const groupByProject = R.groupBy((item) => item.projectName)(allTimeline);
    const allProject = R.keys(groupByProject);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allProject.length; i++) {
      let { projectName, projectOwner, category } = groupByProject[allProject[i]][0];
      projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
      if (!R.has(projectName, summarySettingsMap)) {
        // eslint-disable-next-line no-await-in-loop
        summarySettingsMap[allProject[i]] = await this.getLogsummarysettings({ projectName, projectOwner, category });
      }
    }

    const dragComponentList = [];
    if (!R.isEmpty(allTimeline)) {
      R.addIndex(R.forEachObjIndexed)((value, key, obj, index) => {
        const projectDisplayName = get(value, [0, 'projectDisplayName'], '');
        const projectName = get(value, [0, 'projectName'], '');
        const id = get(value, [0, 'id'], `anchor${index}`);
        dragComponentList.push({
          data: {
            anchorId: id,
            events: value,
            summarySettingsMap,
            title: projectDisplayName,
            typeString: 'Detected Incidents',
          },
          component: GlobalDetectedIncident,
          type: 'detectedIncident',
          sortId: 1,
          projectName,
        });
      }, groupByProject);
    }

    dragMap.Incidents = R.filter((item) => item.type !== 'detectedIncident', dragMap.Incidents);
    dragMap.Incidents = R.concat(dragMap.Incidents, dragComponentList);

    this.setState({
      isLoadingDetectedIncident: false,
      isLoadingPanelIncident: false,
      updateDetectedIncident: false,
      dragMap,
    });
    this.forceUpdate();
    return Promise.resolve(true);
  }

  @autobind
  filterDetectedData(eventList) {
    const { ignoreFilter } = this.state;
    let filterList = eventList || [];

    if (ignoreFilter) {
      filterList = R.filter((event) => !event.isIgnored, filterList);
    }
    return filterList;
  }

  @autobind
  mergeDetectedIncidentEvents(props, eventList) {
    let newEvents = [];

    const eventsMap = {};
    R.forEach((event) => {
      const { projectName, instanceNameString, patternId, isImportant, isIgnored, isNewAlert, rootCauseResultInfo } =
        event;
      const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');
      const hasTrailing = get(rootCauseResultInfo, 'hasTrailingEvent');
      const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');

      if (!isIgnored) {
        const key = `${projectName}-${instanceNameString}-${patternId}`;
        const newEvent = { ...event, key, labelKey: key, hasRootCause, hasTrailing, causedByChange };
        if (!R.has(key, eventsMap)) {
          eventsMap[key] = {
            ...newEvent,

            timelines: [newEvent],
          };
        } else {
          eventsMap[key] = {
            ...eventsMap[key],
            isImportant: eventsMap[key].isImportant || isImportant,
            isIgnored: eventsMap[key].isIgnored || isIgnored,
            isNewAlert: eventsMap[key].isNewAlert || isNewAlert,
            hasRootCause: eventsMap[key].hasRootCause || hasRootCause,
            hasTrailing: eventsMap[key].hasTrailing || hasTrailing,
            causedByChange: eventsMap[key].causedByChange || causedByChange,

            timelines: [...eventsMap[key].timelines, newEvent],
          };
        }
      }
    }, eventList || []);
    newEvents = R.values(eventsMap);

    // get overall info
    newEvents = R.map((event) => {
      let lastSeenTime = 0;
      R.forEach((item) => {
        const { endTimestamp } = item;
        lastSeenTime = R.max(lastSeenTime, endTimestamp);
      }, event.timelines || []);

      // get totle count from api: insightsIncidentPatternCount
      // const eventsCount = get(this.patternCountMap, event.key);
      let eventsCount = 0;
      R.forEach((item) => {
        eventsCount += R.sum(R.values(item.timestampMap || {})) || 1;
      }, event.timelines);

      return { ...event, eventsCount, lastSeenTime };
    }, newEvents);

    return newEvents;
  }

  @autobind
  sortDetectedIncidentData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [
      R.descend(R.prop('isNewAlert')),
      R.descend(R.prop('causedByChange')),
      R.descend(R.prop('hasRootCause')),
      R.descend(R.prop('eventsCount')),
      R.ascend(R.prop('patternId')),
    ];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  callbackHandleSystemUpdate() {
    // reset reloadSystem
    this.setState({ reloadSystem: moment.utc().valueOf() });
  }

  @autobind
  async parsePredictedIncidentData(predictedIncidentData, props) {
    const { systemAnomalyInstance, summarySettingsMap, dragMap } = this.state;
    const { credentials, systemId } = props;
    this.setState({ isLoadingPanelIncident: true });

    const dragComponentList = [];

    if (!R.isEmpty(predictedIncidentData)) {
      // filter instance
      const selectAnomalyInstance = systemAnomalyInstance[systemId];
      if (systemId && selectAnomalyInstance) {
        predictedIncidentData = R.filter((anomaly) => {
          return (
            anomaly.instanceList.includes(selectAnomalyInstance) ||
            anomaly.instanceName.indexOf(selectAnomalyInstance) >= 0
          );
        }, predictedIncidentData);
      }
      const groupByProject = R.groupBy((item) => item.projectName)(predictedIncidentData);
      const allProject = R.keys(groupByProject);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < allProject.length; i++) {
        const { projectOwner, category } = groupByProject[allProject[i]][0];
        let { projectName } = groupByProject[allProject[i]][0];
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
        if (!R.has(projectName, summarySettingsMap)) {
          // eslint-disable-next-line no-await-in-loop
          summarySettingsMap[allProject[i]] = await this.getLogsummarysettings({ projectName, projectOwner, category });
        }
      }

      R.addIndex(R.forEachObjIndexed)((value, key, obj, index) => {
        const projectDisplayName = get(value, [0, 'projectDisplayName'], '');
        const projectName = get(value, [0, 'projectName'], '');
        const id = get(value, [0, 'id'], `anchor${index}`);
        dragComponentList.unshift({
          data: {
            anchorId: id,
            events: value,
            summarySettingsMap,
            title: projectDisplayName,
            typeString: 'Predicted Incidents',
          },
          component: PredictedIncidentList,
          type: 'predictedIncident',
          sortId: 0,
          projectName,
        });
      }, groupByProject);
    }

    dragMap.Incidents = R.filter((item) => item.type !== 'predictedIncident', dragMap.Incidents);
    dragMap.Incidents = R.concat(dragComponentList, dragMap.Incidents);

    this.setState({
      isForceReload: true,
      summarySettingsMap,
      dragMap,
      isLoadingPanelIncident: false,
    });
    this.forceUpdate();
    return Promise.resolve(true);
  }

  @autobind
  reorderDisplay(props) {
    const { location, globalInfo, globalHealthSummary, globalSystemIncidentTimelines, isForceReload } = props;
    const { isAutoReload, systemLoading } = this.state;

    // get system meta data info
    const { environmentId, systemId } = parseLocation(location);
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo && systemInfo.hasAllInstanceInfo) {
      // load incident timelinse if need
      const needLoadIncidentIds =
        isForceReload || isAutoReload
          ? [systemId]
          : R.difference(
              [systemId],
              R.map((item) => item.id, globalSystemIncidentTimelines),
            );
      const newSystemLoading = R.clone(systemLoading);
      if (!isAutoReload) {
        if (needLoadIncidentIds.length > 0) {
          R.forEach((systemId) => {
            newSystemLoading[systemId] = true;
          }, needLoadIncidentIds);
        } else {
          newSystemLoading[systemId] = false;
        }
      }
      this.setState(
        {
          isForceReload: false,
          globalHealthSummary,
          systemLoading: newSystemLoading,
        },
        () => {
          if (needLoadIncidentIds.length > 0) {
            this.loadSystemIncidentTimelines(props, needLoadIncidentIds, isAutoReload);
          } else {
            this.setState({ isAutoReload: false });
          }
        },
      );
    }
  }

  @autobind
  loadSystemIncidentTimelines(props, systemIds, isAutoReload) {
    const { createLoadAction, location, isAdmin } = props;
    const { environmentId, startTime, endTime, customerName } = parseLocation(location);
    const { projectDisplayMap } = this.state;

    if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime) {
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
      createLoadAction(
        ActionTypes.LOAD_GLOBAL_SYSTEM_INCIDENT_TIMELINES,
        {
          isAutoReload,
          level: 'system',
          // customerName,
          environmentId,
          systemIds,
          startTime: startTimestamp,
          endTime: endTimestamp,
          projectDisplayNameMap: projectDisplayMap,
        },
        false,
        false,
        this.callbackSetIncidentTimelineState(systemIds, isAutoReload),
      );
    }
  }

  @autobind
  callbackSetIncidentTimelineState(systemIds, isAutoReload) {
    return () => {
      if (isAutoReload) {
        this.setState({ isAutoReload: false });
      } else {
        const { systemLoading } = this.state;
        const newSystemLoading = R.clone(systemLoading);
        R.forEach((systemId) => {
          newSystemLoading[systemId] = false;
        }, systemIds);
        this.setState({
          systemLoading: newSystemLoading,
        });
      }
    };
  }

  @autobind
  updateState(state) {
    this.setState({ ...(state || {}) });
  }

  @autobind
  handleUpdateSystemRankMap(systemId, { rankMap, alerts, incidents }) {
    const { systemRankMap, systemAlertTimelinesMap, systemIncidentTimelinesMap } = this.state;
    const oldRankMap = systemRankMap[systemId] || {};
    const state = {
      systemRankMap: {
        ...systemRankMap,
        [systemId]: {
          ...oldRankMap,
          ...(rankMap || {}),
        },
      },
    };
    if (alerts !== undefined) {
      state.systemAlertTimelinesMap = {
        ...systemAlertTimelinesMap,
        [systemId]: alerts,
      };
    }
    if (incidents !== undefined) {
      state.systemIncidentTimelinesMap = {
        ...systemIncidentTimelinesMap,
        [systemId]: incidents,
      };
    }
    const alertTimelinesMap = state.systemAlertTimelinesMap ? state.systemAlertTimelinesMap : systemAlertTimelinesMap;
    const incidentTimelinesMap = state.systemIncidentTimelinesMap
      ? state.systemIncidentTimelinesMap
      : systemIncidentTimelinesMap;
    this.getAllProjectName(alertTimelinesMap, incidentTimelinesMap);
    this.setState(state);
  }

  @autobind
  handleUpdateSystemTimelines(systemId, params) {
    const { hideAlertsLabelKey, addAlerts, allAlerts } = params || {};
    const { hideIncidentsLabelKey, addIncidents, allIncidents } = params || {};
    const { systemAlertTimelinesMap, systemIncidentTimelinesMap } = this.state;

    const newState = {};
    if (allAlerts !== undefined) {
      // replace all
      newState.systemAlertTimelinesMap = { ...systemAlertTimelinesMap, [systemId]: allAlerts };
    } else if (hideAlertsLabelKey || addAlerts) {
      // hide
      let alerts = systemAlertTimelinesMap[systemId] || [];
      if (hideAlertsLabelKey) {
        alerts = R.filter((item) => item.labelKey !== hideAlertsLabelKey, alerts);
      }
      // add
      if (addAlerts) {
        alerts = [...alerts, ...(addAlerts || [])];
      }

      newState.systemAlertTimelinesMap = { ...systemAlertTimelinesMap, [systemId]: alerts };
    }

    if (allIncidents !== undefined) {
      // replace all
      newState.systemIncidentTimelinesMap = { ...systemIncidentTimelinesMap, [systemId]: allIncidents };
    } else if (hideIncidentsLabelKey || addIncidents) {
      // hide
      let incidents = systemIncidentTimelinesMap[systemId] || [];
      if (hideIncidentsLabelKey) {
        incidents = R.filter((item) => item.labelKey !== hideIncidentsLabelKey, incidents);
      }
      // add
      if (addIncidents) {
        incidents = [...incidents, ...(addIncidents || [])];
      }

      newState.systemIncidentTimelinesMap = { ...systemIncidentTimelinesMap, [systemId]: incidents };
    }

    const alertTimelinesMap = newState.systemAlertTimelinesMap
      ? newState.systemAlertTimelinesMap
      : systemAlertTimelinesMap;
    const incidentTimelinesMap = newState.systemIncidentTimelinesMap
      ? newState.systemIncidentTimelinesMap
      : systemIncidentTimelinesMap;
    this.getAllProjectName(alertTimelinesMap, incidentTimelinesMap);
    this.setState(newState);
  }

  @autobind
  onReloadSystemIncidentTimelines(systemIds) {
    const { systemLoading } = this.state;
    const newSystemLoading = R.clone(systemLoading);
    R.forEach((systemId) => {
      newSystemLoading[systemId] = true;
    }, systemIds);
    this.setState({ systemLoading: newSystemLoading }, () => {
      this.loadSystemIncidentTimelines(this.props, systemIds);
    });
  }

  @autobind
  parserSystemInstanceHealth(props) {
    const { selectSystemHealthInfo, systemLoading } = this.state;
    const systemId = get(selectSystemHealthInfo, 'id');
    this.setState({
      systemLoading: { ...systemLoading, [systemId]: false },
    });
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        startTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        endTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'add'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    let disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;

    disableRefresh = false;

    this.setState(
      {
        endTimeObj,
        timeChange,
        disableRefresh,
        tooltipVisibleReload,
        startTimeObj: timeScopeControl(startTimeObj, endTimeObj, timeObj, 'subtract'),
      },
      () => {
        if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
      },
    );
  }

  @autobind
  handleStartOpenChange(open) {
    if (!open) {
      this.setState({ endTimeOpen: true });
    }
  }

  @autobind
  handleEndOpenChange(open) {
    this.setState({ endTimeOpen: open });
  }

  @autobind
  handleUpdateGlobalViewClick() {
    const { location } = this.props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;
    if (startTime && endTime) {
      const selectStartTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const selectEndTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      this.setState({ showTimeSelectModal: true, selectStartTimestamp, selectEndTimestamp });
    }
  }

  @autobind
  onCloseTimeSelect(props) {
    const { intl, location, credentials, updateLastActionInfo } = this.props;
    const { startTimestamp, endTimestamp, ...rest } = props || {};
    this.setState({ updateModelLoading: true });
    if (startTimestamp && endTimestamp) {
      updateLastActionInfo();
      GlobalParse.updateGlobalView({
        intl,
        location,
        state: {
          selectStartTimestamp: startTimestamp,
          selectEndTimestamp: endTimestamp,
          ...rest,
        },
        credentials,
        callback: ({ loading, model }) => {
          if (loading) {
            this.setState({ updateModelLoading: false });
          }
          if (model) {
            this.setState({ showTimeSelectModal: false });
          }
        },
      });
    } else {
      this.setState({ showTimeSelectModal: false, updateModelLoading: false });
    }
  }

  @autobind
  handleLatestLogsClick(systemInfo) {
    return (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.setState({ showLatestLogsSelector: true, currentSystem: systemInfo });
    };
  }

  @autobind
  async onChangeSystemDatazoomTime({ zoomStartTime, zoomEndTime }) {
    if (zoomStartTime && zoomEndTime) {
      this.setState({ activeAnomalies: true, updateAlert: true, updateDetectedAnomalies: true }, async () => {
        await this.demandLoad('Anomalies', null, { isForceReload: true, isAutoReload: true });
        const { onZoomIn } = this.healthRow;
        onZoomIn(zoomStartTime, zoomEndTime);
      });
    }
    this.setState({ zoomStartTime, zoomEndTime });
  }

  @autobind
  onChangeSystemDropdown({ systemInfo }) {
    return (open) => {};
  }

  @autobind
  onChangeSystemAnomalyInstance(systemHealth, value) {
    const { systemLoading, systemAnomalyInstance } = this.state;
    const { location, createLoadAction, globalInfo } = this.props;
    const params = parseLocation(location);
    const { startTime, endTime, environmentId } = params;
    const systemId = systemHealth.id;
    const newState = {
      selectSystemHealthInfo: value ? systemHealth : null,
      systemAnomalyInstance: { ...systemAnomalyInstance, [systemId]: value },
    };

    const needReloadInstanceHealth = Boolean(value);

    // set loading info
    if (needReloadInstanceHealth) {
      newState.systemLoading = { ...systemLoading, [systemId]: true };
    }

    this.setState(newState, () => {
      if (needReloadInstanceHealth) {
        const environment = R.find((e) => e.id === environmentId, globalInfo || []);
        const systemList = get(environment, 'systemList', []);
        const systemInfo = R.find((system) => system.id === systemId, systemList);
        const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
        const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
        createLoadAction(
          ActionTypes.LOAD_GLOBAL_SYSTEM_INSTANCE_HEALTH,
          {
            level: 'system',
            environmentId,
            customerName: systemInfo.ownerUserName,
            systemIds: [systemId],
            startTime: startTimestamp,
            endTime: endTimestamp,
            instanceName: value,
            systemHealth,
          },
          false,
        );
      }
    });
  }

  @autobind
  onChangeSystemPredictions({ systemInfo }) {
    return (event) => {
      const { systemPredictionControl } = this.state;
      const systemId = systemInfo.id;
      const showHistoricalPredictions = event;

      this.setState({ systemPredictionControl: { ...systemPredictionControl, [systemId]: showHistoricalPredictions } });
    };
  }

  @autobind
  onChangeSystemDetectedIncidents({ systemInfo }) {
    return (event) => {
      const { systemDetectedIncidentsControl } = this.state;
      const systemId = systemInfo.id;
      const showDetectedIncidents = event;

      this.setState({
        systemDetectedIncidentsControl: { ...systemDetectedIncidentsControl, [systemId]: showDetectedIncidents },
      });
    };
  }

  @autobind
  // eslint-disable-next-line consistent-return
  getLogsummarysettings(project) {
    const { credentials } = this.props;
    const { category, projectName } = project;
    if (!R.includes(category, ['metric'])) {
      return new Promise((resolve, reject) => {
        fetchGet(getEndpoint('logsummarysettings'), {
          ...credentials,
          projectName,
        })
          .then((summarySettings) => {
            resolve(summarySettings);
          })
          .catch((err) => {
            resolve([]);
          });
      });
    }
  }

  @autobind
  async getAllProjectName(systemAlertTimelinesMap, systemIncidentTimelinesMap) {
    const { location, globalSystemIncidentTimelines, credentials } = this.props;
    const { summarySettingsMap } = this.state;
    const query = parseLocation(location);
    const { systemId } = query;

    this.setState({ logsummaryLoading: true });

    const predictionIncidentTimelines =
      (R.find((item) => item.id === systemId, globalSystemIncidentTimelines) || {}).anomalyTimelines || [];
    const alertTimelines = get(systemAlertTimelinesMap, [systemId], []);
    const incidentTimelines = get(systemIncidentTimelinesMap, [systemId], []);

    let allProjectNameList = [];
    R.forEach(
      (item) => {
        const { projectOwner, category } = item;
        let { projectName } = item;
        projectName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;
        allProjectNameList = [...allProjectNameList, { projectName, category }];
      },
      [...predictionIncidentTimelines, ...alertTimelines, ...incidentTimelines],
    );
    allProjectNameList = R.filter((item) => Boolean(item), R.uniq(allProjectNameList));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < allProjectNameList.length; i++) {
      if (!R.has(allProjectNameList[i].projectName, summarySettingsMap)) {
        // eslint-disable-next-line no-await-in-loop
        summarySettingsMap[allProjectNameList[i].projectName] = await this.getLogsummarysettings(allProjectNameList[i]);
      }
    }
    this.setState({ summarySettingsMap, logsummaryLoading: false });
  }

  @autobind
  getReloadDataTotalCount() {
    const { intl, credentials, location, globalInfo } = this.props;
    const params = parseLocation(location);
    const { startTime, endTime, environmentId, systemId } = params;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (!systemInfo) return;

    const startTimeObj = moment.utc(startTime, Defaults.DateFormat);
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat);
    const diffTimeObj = endTimeObj.diff(startTimeObj, 'days') + 1;
    const startTimestampPrev = startTimeObj.subtract(diffTimeObj, 'days').startOf('days').valueOf();
    const endTimestampPrev = endTimeObj.subtract(diffTimeObj, 'days').endOf('days').valueOf();
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();
    Promise.all([
      fetchGet(getEndpoint('system-daily-info'), {
        ...credentials,
        customerName: systemInfo.ownerUserName,
        systemName: systemInfo.id,
        startTime: startTimestampPrev,
        endTime: endTimestampPrev,
      }),
      fetchGet(getEndpoint('system-daily-info'), {
        ...credentials,
        customerName: systemInfo.ownerUserName,
        systemName: systemInfo.id,
        startTime: startTimestamp,
        endTime: endTimestamp,
      }),
    ])
      .then((dataTotalCount) => {
        this.setState({ dataTotalCount });
      })
      .catch((err) => {
        // message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
      });
  }

  @autobind
  handleLineChartClick({ item, key, keyPath, domEvent, customerName, startTime, endTime, environmentId, systemInfo }) {
    const {
      props: { data },
    } = item;

    const { projectNameReal } = data;
    const systemId = get(systemInfo, ['id']);
    const instanceGroup = GlobalParse.getInstanceGroupByEnv(environmentId);
    let modelType = 'Holistic';
    const startTimeObj = moment.utc(startTime, Defaults.DateFormat).startOf('day');
    const endTimeObj = moment.utc(endTime, Defaults.DateFormat).endOf('day');
    if (instanceGroup !== 'All') modelType = 'splitByEnv';
    const query = {
      startTime,
      endTime,
      customerName,
      environmentId,
      systemId,
      projectName: projectNameReal,
      instanceGroup,
      modelType,
      startTimestamp: startTimeObj.valueOf(),
      endTimestamp: endTimeObj.valueOf(),
      predictedFlag: false,
    };
    window.open(buildUrl(BaseUrls.MetricLineCharts, {}, query), '_blank');
  }

  @autobind
  async reloadDetectedAnomalyData(props, params) {
    this.setState({ isLoadingPanelAnomalies: true });

    const { createLoadAction, location, isAdmin } = props || this.props;
    const query = parseLocation(location);
    const { environmentId, startTime, endTime, customerName, systemId } = query;
    const { isAutoReload, filterProjectList, projectDisplayMap } = params;
    return new Promise((resolve, reject) => {
      if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId) {
        const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
        const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
        createLoadAction(
          ActionTypes.LOAD_GLOBAL_SYSTEM_ANOMALY_TIMELINES,
          {
            isAutoReload,
            level: 'system',
            environmentId,
            systemIds: [systemId],
            startTime: startTimestamp,
            endTime: endTimestamp,
            loadPredicted: true,
            ...(filterProjectList ? { filterProjectList: JSON.stringify([filterProjectList]) } : {}),
            projectDisplayNameMap: projectDisplayMap,
          },
          false,
          false,
          this.callbackSetAnomalyTimelineState(resolve, reject),
        );
      }
    });
  }

  @autobind
  callbackSetAnomalyTimelineState(resolve, reject) {
    return () => {
      const { location, globalInfo } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo) {
        this.parseAnomaliesData({ ...this.props, systemInfo });
        resolve(true);
        return;
      }
      reject(false);
    };
  }

  @autobind
  async parseAnomaliesData(props) {
    const { location, userInfo, projects } = props;
    const query = parseLocation(location);
    const { systemId } = query;

    const { globalSystemAnomalyTimelines, systemInfo, credentials, globalSystemAlertTimelines, isAlertAnomalies } =
      props;
    const data = isAlertAnomalies ? globalSystemAlertTimelines : globalSystemAnomalyTimelines;

    const { sortBy, sortDirection, systemAnomalyInstance, summarySettingsMap, dragMap, activeAnomalies } = this.state;
    const { activeProjectName } = this.state;

    // parse anomaly timelines
    const systemAnomalyTimelines = (R.find((item) => item.id === systemInfo.id, data) || {}).anomalyTimelines;
    let events = systemAnomalyTimelines || [];

    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];
      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const instanceName = pair.instanceId || pair.instanceName;
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.realInstanceName} (${componentName})`
            : item.realInstanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);
      const onlyInstanceString = get(item, ['containerInfo', 'instanceName'], item.realInstanceString);

      let typeList = [];
      if (item.category === 'metric' && item.type !== 'Incident') {
        typeList = ['metricanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', item.type).split('&'));
      }

      // build log json info
      let rawDataJson;
      try {
        rawDataJson = JSON.parse(item.rawData);
        if (!isObject(rawDataJson)) rawDataJson = undefined;
      } catch (error) {
        // console.debug(error);
      }

      // get flag isTrace from event's project, build trace info
      const { projectOwner } = item;
      let { projectName } = item;
      projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
      const project = R.find((project) => projectName === project.projectName, projects || []);
      const { isTrace } = project || {};
      const traceId = get(rawDataJson, 'trace_id');
      let traceInfo;
      if (isTrace) {
        const traceId = get(rawDataJson, 'trace_id');
        const parentId = get(rawDataJson, 'parent_span_id');
        const spanId = get(rawDataJson, 'span_id');
        const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentNameString;
        const name = get(rawDataJson, 'method_name');
        const startTime = get(rawDataJson, 'start_time');
        // use microseconds for timestamp and duration
        const duration = Number(get(rawDataJson, 'duration', 0)) * 1000;
        const timestamp = moment.utc(startTime).valueOf() * 1000;
        traceInfo = {
          traceId,
          parentId,
          id: spanId,
          name,
          timestamp,
          duration,
          serviceName,
          color: '#ffccc7',
          tags: {},
        };
      }

      // get status
      const leadToIncident = get(item.rootCauseResultInfo, 'leadToIncident');
      const hasTrailing = get(item.rootCauseResultInfo, 'hasTrailingEvent');
      const causedByChange = get(item.rootCauseResultInfo, 'causedByChangeEvent');
      const hasRootCause = get(item.rootCauseResultInfo, 'hasPrecedingEvent');

      // build id, and mergeKey
      const mergeKey =
        item.category === 'metric'
          ? `${item.projectName}-${item.instanceNameString}-${item.patternId}-${
              item.isIncident ? 'incident' : 'notIncident'
            }-${item.metricNameString}`
          : `${item.projectName}-${item.instanceNameString}-${item.patternId}-${
              item.isIncident ? 'incident' : 'notIncident'
            }-${
              typeList[0].toLowerCase() === 'newpattern' || typeList[0] === 'whitelist' ? 'new_whitelist' : typeList[0]
            }`;

      return {
        ...item,
        mergeKey,
        rawDataJson,
        rootCauseJson: { rootCauseDetailsArr },
        appName,
        componentNameString,
        onlyInstanceString,
        instanceAppNames,
        componentList,
        typeList,
        leadToIncident,
        hasTrailing,
        causedByChange,
        hasRootCause,
        patternAndTrace: isTrace ? traceId : item.patternId,
        isTrace,
        traceId,
        traceInfo,
      };
    }, events);

    let eventList = this.filterData(events);
    // filter
    const selectAnomalyInstance = systemAnomalyInstance[systemId];
    if (systemId && selectAnomalyInstance) {
      eventList = R.filter((anomaly) => {
        return (
          anomaly.instanceList.includes(selectAnomalyInstance) ||
          anomaly.instanceName.indexOf(selectAnomalyInstance) >= 0
        );
      }, eventList);
    }
    eventList = R.filter((item) => item.type !== 'Deployment', eventList);
    // merge
    eventList = this.mergeAnomalyEvents(props, eventList);
    // add rank number
    eventList = R.sortWith([R.descend(R.prop('isImportant')), R.descend(R.prop('totalScore'))], eventList);
    eventList = R.addIndex(R.map)((event, index) => {
      return { ...event, rank: index + 1 };
    }, eventList);
    // sort
    eventList = this.sortData(eventList, sortBy, sortDirection);

    const projectMaps = {};
    const byProjectName = R.groupBy((item) => item.projectName);
    const groupEventMap = byProjectName(eventList);
    const dragComponentList = [];
    R.addIndex(R.forEach)((item, index) => {
      const anomalyList = get(item, 1, []);
      const { projectDisplayName, projectName, projectOwner, category } = anomalyList[0] || {};
      const projectFullName = projectOwner !== credentials.userName ? `${projectName}@${projectOwner}` : projectName;

      projectMaps[projectFullName] = category;
      dragComponentList.push({
        type: isAlertAnomalies ? 'alert' : 'detectedAnomalies',
        data: {
          events: anomalyList,
          projectName,
          title: projectDisplayName,
          summarySettingsMap,
          anchorId: `${isAlertAnomalies ? 'alert' : 'detected'}anomalies${index}`,
          isMetric: category === 'metric',
          typeString: isAlertAnomalies ? 'Alerts' : 'Anomalies',
        },
        component: GlobalDetectedAnomalies,
        sortId: 3,
        projectName,
      });
    }, R.toPairs(groupEventMap));

    R.forEachObjIndexed(async (category, projectName) => {
      if (!R.has(projectName, summarySettingsMap)) {
        // eslint-disable-next-line no-await-in-loop
        summarySettingsMap[projectName] = await this.getLogsummarysettings({
          projectName,
          category,
        });
      }
    }, projectMaps);

    const filterStr = isAlertAnomalies ? 'alert' : 'detectedAnomalies';
    dragMap.Anomalies = R.filter(
      (item) => `${item.projectName}-${item.type}` !== `${activeProjectName}-${filterStr}`,
      dragMap.Anomalies,
    );
    this.cacheProjectList = R.filter(
      (item) => `${item.projectName}-${item.type}` !== `${activeProjectName}-${filterStr}`,
      this.cacheProjectList || [],
    );

    dragMap.Anomalies = R.concat(dragMap.Anomalies, dragComponentList);

    if (!activeAnomalies && !selectAnomalyInstance) {
      R.forEach((item) => {
        const findItem = R.find(
          (_item) => `${_item.projectName}-${_item.type}` === `${item.projectName}-${item.type}`,
          this.cacheProjectList || [],
        );
        if (!findItem) this.cacheProjectList.push(item);
      }, dragMap.Anomalies || []);
      // dragMap.Anomalies = this.cacheProjectList;
    }

    const state = {
      summarySettingsMap,
      isLoadingPanelAnomalies: false,
      updateAlert: false,
      isLoadingPanelAlert: false,
      isForceReload: true,
      dragMap,
    };
    if (!isAlertAnomalies) {
      state.updateDetectedAnomalies = false;
      state.isLoadingPanelAnomalies = false;
    }
    this.setState(state);
    this.forceUpdate();
    return Promise.resolve(true);
  }

  @autobind
  filterData(eventList) {
    const { ignoreFilter } = this.state;
    let filterList = eventList || [];

    if (ignoreFilter) {
      filterList = R.filter((event) => !event.isIgnored, filterList);
    }
    return filterList;
  }

  @autobind
  mergeAnomalyEvents(props, eventList) {
    let newEvents = [];

    const eventsMap = {};
    R.forEach((event) => {
      const { isImportant, isIgnored, causedByChange, componentName } = event;
      const key = `${componentName}-${event.patternId}`;
      if (!R.has(key, eventsMap)) {
        eventsMap[key] = {
          ...event,
          isImportant,
          isIgnored,
          causedByChange,

          timelines: [event],
        };
      } else {
        eventsMap[key] = {
          ...eventsMap[key],
          isImportant: eventsMap[key].isImportant || isImportant,
          isIgnored: eventsMap[key].isIgnored || isIgnored,
          causedByChange: eventsMap[key].causedByChange || causedByChange,

          timelines: [...eventsMap[key].timelines, event],
        };
      }
    }, eventList || []);
    newEvents = R.values(eventsMap);

    // get overall info
    newEvents = R.map((event) => {
      const eventsCount = event.timelines.length;

      let totalScore = 0;
      let lastSeenTime = 0;
      let projectDisplayNames = [];
      let allComponentList = [];
      R.forEach((item) => {
        const { avgAnomalyScore, endTimestamp, projectDisplayName, componentList } = item;
        totalScore += avgAnomalyScore;
        lastSeenTime = R.max(lastSeenTime, endTimestamp);
        projectDisplayNames.push(projectDisplayName);
        allComponentList = [...allComponentList, ...componentList];
      }, event.timelines || []);
      projectDisplayNames = R.uniq(projectDisplayNames);
      allComponentList = R.uniq(allComponentList);

      return { ...event, eventsCount, totalScore, lastSeenTime, projectDisplayNames, allComponentList };
    }, newEvents);

    return newEvents;
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.descend(R.prop('isImportant')), R.descend(R.prop('totalScore'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  reloadDeployData(props, params) {
    this.setState({ isLoadingPanelDeploy: true });
    const { createLoadAction, location, isAdmin } = props;
    const query = parseLocation(location);
    const { environmentId, startTime, endTime, customerName, systemId } = query;
    const { isAutoReload, projectDisplayMap } = params;

    return new Promise((resolve, reject) => {
      if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId) {
        const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
        const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
        createLoadAction(
          ActionTypes.LOAD_GLOBAL_SYSTEM_DEPLOY_TIMELINES,
          {
            isAutoReload,
            level: 'system',
            environmentId,
            systemIds: [systemId],
            startTime: startTimestamp,
            endTime: endTimestamp,
            projectDisplayNameMap: projectDisplayMap,
          },
          false,
          false,
          this.callbackSetDeployTimelineState(resolve, reject),
        );
      }
    });
  }

  @autobind
  callbackSetDeployTimelineState(resolve, reject) {
    return async () => {
      const { location, globalInfo } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo) {
        await this.parseAlertData({ ...this.props, systemInfo, isDeployment: true });
        resolve(true);
        return;
      }
      reject(false);
    };
  }

  @autobind
  reloadAlertData(props, params) {
    this.setState({ isLoadingPanelAlert: true });
    const { createLoadAction, location, isAdmin } = props;
    const query = parseLocation(location);
    const { environmentId, startTime, endTime, customerName, systemId } = query;
    const { isAutoReload, filterProjectList, projectDisplayMap } = params;

    return new Promise((resolve, reject) => {
      if (((isAdmin && customerName) || !isAdmin) && environmentId && startTime && endTime && systemId) {
        const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
        const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
        createLoadAction(
          ActionTypes.LOAD_GLOBAL_SYSTEM_ALERT_TIMELINES,
          {
            isAutoReload,
            level: 'system',
            environmentId,
            systemIds: [systemId],
            startTime: startTimestamp,
            endTime: endTimestamp,
            ...(filterProjectList ? { filterProjectList: JSON.stringify([filterProjectList]) } : {}),
            projectDisplayNameMap: projectDisplayMap,
          },
          false,
          false,
          this.callbackSetAlertTimelineState(resolve, reject),
        );
      }
    });
  }

  @autobind
  callbackSetAlertTimelineState(resolve, reject) {
    return async () => {
      const { location, globalInfo } = this.props;
      const { environmentId, systemId } = parseLocation(location);
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo) {
        await this.parseAnomaliesData({ ...this.props, systemInfo, isAlertAnomalies: true });
        resolve(true);
        return;
      }
      reject(false);
    };
  }

  @autobind
  async parseAlertData(props) {
    const { globalSystemAlertTimelines, globalSystemDeployTimelines, isDeployment } = props;
    const { dragMap, activeKey } = this.state;
    const timelineData = isDeployment ? globalSystemDeployTimelines : globalSystemAlertTimelines;
    const allTimeline = await this.parseTimelineData(timelineData, props, false);

    const groupByProject = R.groupBy((item) => item.projectName)(allTimeline);
    const dragComponentList = [];
    if (!R.isEmpty(allTimeline)) {
      R.addIndex(R.forEachObjIndexed)((value, key, obj, index) => {
        const projectDisplayName = get(value, [0, 'projectDisplayName'], '');
        const projectName = get(value, [0, 'projectName'], '');
        const type = get(value, [0, 'type']);
        const id = get(value, [0, 'id'], `anchor${index}`);
        const typeString = type === 'Deployment' ? 'Changes' : 'Alerts';
        if (type === 'Deployment') {
          dragComponentList.unshift({
            data: {
              anchorId: id,
              events: value,
              title: projectDisplayName,
              typeString,
            },
            component: GlobalAlert,
            type: 'change',
            sortId: 2,
            projectName,
          });
        }
      }, groupByProject);
    }

    dragMap[activeKey] = R.filter((item) => item.type !== 'change', dragMap[activeKey] || []);

    R.forEach((item) => {
      const { type } = item;
      if (type === 'change') {
        dragMap.Changes.push(item);
      }
    }, dragComponentList);

    this.setState({
      isLoadingPanelAlert: false,
      isForceReload: true,
      updateAlert: false,
      updateChange: false,
      dragMap,
      isLoadingPanelDeploy: false,
    });
    this.forceUpdate();
  }

  @autobind
  async parseTimelineData(timelineData, props) {
    const { userInfo, projects, systemInfo, systemId } = props;
    const { sortBy, sortDirection, systemAnomalyInstance } = this.state;
    // parse anomaly timelines
    const systemAlertTimelines = (R.find((item) => item.id === systemInfo.id, timelineData) || {}).anomalyTimelines;

    let events = systemAlertTimelines || [];

    // add component name
    const instanceComponentMap = get(systemInfo, 'instanceComponentMap', {});
    events = R.map((item) => {
      // get event instances and types
      let instanceAppNames = [];
      let componentList = [];

      let rootCauseDetailsArr = get(item, ['rootCauseJson', 'rootCauseDetailsArr'], []);
      if (item.category === 'metric') {
        rootCauseDetailsArr = R.map((pair) => {
          const instanceName = pair.instanceId || pair.instanceName;
          const componentName = get(instanceComponentMap, instanceName, instanceName);
          componentList.push(componentName);
          const appName =
            componentName && componentName.indexOf(instanceName) === -1
              ? `${instanceName} (${componentName})`
              : instanceName;
          instanceAppNames.push(appName);

          return { ...pair, appName };
        }, rootCauseDetailsArr);
      } else if (item.category === 'log') {
        const { realInstanceName } = item;
        const componentName = get(instanceComponentMap, realInstanceName, item.componentName);
        componentList.push(componentName);
        const appName =
          componentName && componentName !== item.instanceName && componentName !== realInstanceName
            ? `${item.instanceName} (${componentName})`
            : item.instanceName;
        instanceAppNames.push(appName);
      }
      componentList = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(componentList));
      instanceAppNames = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), R.uniq(instanceAppNames));
      const appName = R.join(', ', instanceAppNames);
      const componentNameString = R.join(', ', componentList);

      let typeList = [];
      if (item.category === 'metric' && item.type !== 'Incident') {
        typeList = ['metricanomaly'];
      } else {
        typeList = R.map((type) => R.toLower(type), R.replace(/\(\w*\)/g, '', item.type).split('&'));
      }

      // build log json info
      let rawDataJson;
      try {
        rawDataJson = JSON.parse(item.rawData);
        if (!isObject(rawDataJson)) rawDataJson = undefined;
      } catch (error) {
        // console.debug(error);
      }

      // get falg isTrace from event's project, build trace info
      const { projectOwner } = item;
      let { projectName } = item;
      projectName = userInfo.userName !== projectOwner ? `${projectName}@${projectOwner}` : projectName;
      const project = R.find((project) => projectName === project.projectName, projects || []);
      const { isTrace } = project || {};
      const traceId = get(rawDataJson, 'trace_id');
      let traceInfo;
      if (isTrace) {
        const traceId = get(rawDataJson, 'trace_id');
        const parentId = get(rawDataJson, 'parent_span_id');
        const spanId = get(rawDataJson, 'span_id');
        const serviceName = get(rawDataJson, ['attributes', 'http.server_name']) || componentNameString;
        const name = get(rawDataJson, 'method_name');
        const startTime = get(rawDataJson, 'start_time');
        // use microseconds for timestamp and duration
        const duration = Number(get(rawDataJson, 'duration', 0)) * 1000;
        const timestamp = moment.utc(startTime).valueOf() * 1000;
        traceInfo = {
          traceId,
          parentId,
          id: spanId,
          name,
          timestamp,
          duration,
          serviceName,
          color: '#ffccc7',
          tags: {},
        };
      }

      return {
        ...item,
        rawDataJson,
        rootCauseJson: { rootCauseDetailsArr },
        appName,
        componentNameString,
        instanceAppNames,
        componentList,
        typeList,
        isRare: typeList.includes('rare'),
        isHot: typeList.includes('hot'),
        patternAndTrace: isTrace ? traceId : item.patternId,
        isTrace,
        traceId,
        traceInfo,
      };
    }, events);

    let eventList = events;
    // merge
    eventList = this.mergeAlertAnomalyEvents(props, eventList);
    // add rank number
    eventList = R.sortWith(
      [
        R.descend(R.prop('leadToIncident')),
        R.descend(R.prop('hasTrailing')),
        R.descend(R.prop('isNewAlert')),
        R.descend(R.prop('isRare')),
        R.descend(R.prop('isHot')),
        R.descend(R.prop('eventsCount')),
        R.ascend(R.prop('patternId')),
      ],
      eventList,
    );
    const rankMap = {};
    const labelKeyMap = {};
    let rank = 1;
    eventList = R.addIndex(R.map)((event, index) => {
      if (!R.has(event.labelKey, labelKeyMap)) {
        labelKeyMap[event.labelKey] = rank;
        rank += 1;
      }
      R.forEach((item) => {
        rankMap[item.id] = labelKeyMap[event.labelKey];
      }, event.timelines);
      return { ...event, rank: labelKeyMap[event.labelKey] };
    }, eventList);

    // default display alerts
    const alerts = [];
    eventList = R.addIndex(R.map)((event, index) => {
      const { isDeployment, timelines } = event;
      if (isDeployment || index < this.defaultDisplayAlertSize) {
        R.forEach((item) => {
          alerts.push(item);
        }, timelines || []);
        return { ...event, isDisplayInChart: true };
      }
      return { ...event, isDisplayInChart: false };
    }, eventList);
    this.handleUpdateSystemRankMap(systemInfo.id, { rankMap, alerts });

    // sort
    eventList = this.sortAlertData(eventList, sortBy, sortDirection);

    let allTimeline = [];
    R.forEach((item) => {
      const { timelines } = item;
      if (timelines) {
        R.forEach((timeline) => allTimeline.push(timeline), timelines);
      }
    }, eventList);

    // filter
    const selectAnomalyInstance = systemAnomalyInstance[systemId];
    if (systemId && selectAnomalyInstance) {
      allTimeline = R.filter((anomaly) => {
        return (
          anomaly.instanceList.includes(selectAnomalyInstance) ||
          anomaly.instanceName.indexOf(selectAnomalyInstance) >= 0
        );
      }, allTimeline);
    }

    return Promise.resolve(allTimeline);
  }

  @autobind
  mergeAlertAnomalyEvents(props, eventList) {
    let newEvents = [];

    const eventsMap = {};
    R.forEach((event) => {
      const { projectName, componentNameString, patternId, isImportant, isIgnored, isNewAlert, rootCauseResultInfo } =
        event;
      const { isTrace, traceId } = event;
      const hasRootCause = get(rootCauseResultInfo, 'hasPrecedingEvent');
      const hasTrailing = get(rootCauseResultInfo, 'hasTrailingEvent');
      const leadToIncident = get(rootCauseResultInfo, 'leadToIncident');
      const causedByChange = get(rootCauseResultInfo, 'causedByChangeEvent');

      const key = `${projectName}-${componentNameString}-${isTrace ? traceId : patternId}`;
      const newEvent = { ...event, key, labelKey: key, hasRootCause, hasTrailing, leadToIncident, causedByChange };
      if (!R.has(key, eventsMap)) {
        eventsMap[key] = {
          ...newEvent,

          timelines: [newEvent],
        };
      } else {
        eventsMap[key] = {
          ...eventsMap[key],
          isImportant: eventsMap[key].isImportant || isImportant,
          isIgnored: eventsMap[key].isIgnored || isIgnored,
          hasRootCause: eventsMap[key].hasRootCause || hasRootCause,
          hasTrailing: eventsMap[key].hasTrailing || hasTrailing,
          leadToIncident: eventsMap[key].leadToIncident || leadToIncident,
          causedByChange: eventsMap[key].causedByChange || causedByChange,
          isNewAlert: eventsMap[key].isNewAlert || isNewAlert,

          timelines: [...eventsMap[key].timelines, newEvent],
        };
      }
    }, eventList || []);
    newEvents = R.values(eventsMap);

    // get overall info
    newEvents = R.map((event) => {
      let lastSeenTime = 0;
      R.forEach((item) => {
        const { endTimestamp } = item;
        lastSeenTime = R.max(lastSeenTime, endTimestamp);
      }, event.timelines || []);

      // get totle count from api: insightsIncidentPatternCount
      const eventsCount = get(this.patternCountMap, event.key);

      return { ...event, eventsCount: eventsCount || event.timelines.length, lastSeenTime };
    }, newEvents);

    return newEvents;
  }

  @autobind
  sortAlertData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [
      R.descend(R.prop('leadToIncident')),
      R.descend(R.prop('hasTrailing')),
      R.descend(R.prop('isNewAlert')),
      R.descend(R.prop('isRare')),
      R.descend(R.prop('isHot')),
      R.descend(R.prop('eventsCount')),
      R.ascend(R.prop('patternId')),
    ];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  healthRowRef(cmp) {
    this.healthRow = cmp;
  }

  @autobind
  onRefreshData(isAlert) {
    const { activeProjectName, activeProjectType, projectDisplayMap } = this.state;
    if (isAlert) {
      this.cacheProjectList = R.filter(
        (item) => `${item.projectName}-${item.type}` !== `${activeProjectName}-${activeProjectType}`,
        this.cacheProjectList || [],
      );
      this.setState({ updateChange: true, updateAlert: true }, () => {
        this.demandLoad('Changes', this.props, { isForceReload: false, isAutoReload: false });
        this.demandLoad('Anomalies', this.props, { isForceReload: false, isAutoReload: false });
      });
      return;
    }
    this.reloadData(this.props, { isForceReload: true });
    this.reloadDetectedIncidentData(this.props, { isAutoReload: false, projectDisplayMap });
  }

  @autobind
  changeProjectActive(projectActive, flag, saveActiveItem, saveActiveKey) {
    const { isForceReload, isAutoReload, zoomStartTime, zoomEndTime } = this.state;
    if (zoomStartTime && zoomEndTime) {
      return;
    }
    let activeKey = (projectActive || '').split('-')[0];
    const project = (projectActive || '').split(`${activeKey}-`)[1];
    const activeProjectType = activeTypeMap[activeKey];
    activeKey = activeKeyMap[activeKey];
    if (flag) {
      const projectChartsBox = document.getElementById('systemUHVProjectChartContent');
      if (projectChartsBox) projectChartsBox.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
    this.setState(
      {
        activeKey,
        activeProjectName: project,
        activeProjectType,
        activeLineIconItem: saveActiveItem ? projectActive : null,
        activeLineIconKey: saveActiveKey || null,
      },
      () => {
        this.demandLoad(activeKey, this.props, { isForceReload, isAutoReload });
      },
    );
  }

  render() {
    const { location, push } = this.props;
    const query = parseLocation(location);
    const {
      isAdmin,
      userList,
      globalInfo,
      intl,
      loadStatus,
      userName,
      globalSystemIncidentTimelines,
      globalHealthByInstance,
      isLocalAdmin,
      defaultTimezone,
      systemsMap,
      credentials,
      userInfo,
      projects,
    } = this.props;
    const {
      startTimeObj,
      endTimeObj,
      endTimeOpen,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
      view,
      showTimeSelectModal,
      selectStartTimestamp,
      selectEndTimestamp,

      isLoadingZoomIn,
      zoomStartTime,
      zoomEndTime,

      reloadSystem,
      globalHealthSummary,
      isAutoReload,

      dragMap,
      isLoadingPanelAnomalies,
      isLoadingDetectedIncident,
      isLoadingPanelAlert,
      isLoadingPanelIncident,
      isLoadingPanelDeploy,
      showCollapse,
      showProjectChart,
      projectDisplayMap,
    } = this.state;
    const { currentSystem, showLatestLogsSelector } = this.state;
    // render chart states
    const {
      systemLoading,
      systemAnomalyInstance,
      systemRankMap,
      systemAlertTimelinesMap,
      systemIncidentTimelinesMap,
      systemPredictionControl,
      systemDetectedIncidentsControl,
      activeKey,
      summarySettingsMap,
      logsummaryLoading,
      updateModelLoading,
      dataTotalCount,
      activeProjectName,
      activeProjectType,
      activeLineIconItem,
      activeLineIconKey,
      showLikelyRootCausesRCA,
      activeEvent,
      needRC,
    } = this.state;

    // get system list
    const { startTime, endTime, customerName, environmentId, systemId, refresh, refreshTime, forceRefreshTime } = query;
    let globalInfoFilter = globalInfo || [];
    let environment = R.find((e) => e.id === environmentId, globalInfoFilter);
    const systemList = get(environment, 'systemList', []);
    if (globalHealthSummary && globalHealthSummary.length === 0) {
      globalInfoFilter = R.filter((e) => e.id !== environmentId, globalInfoFilter);
      environment = null;
    }

    // get system health chart data
    const systemHealth = R.find((item) => item.systemId === systemId, globalHealthSummary);
    const systemInfo = R.find((system) => system.id === systemId, systemList || []);
    const showHistoricalPredictions = get(systemPredictionControl, [systemId], true);
    const showDetectedIncidents = get(systemDetectedIncidentsControl, [systemId], true);
    const isLoadingSystemChart = get(systemLoading, [systemId], false);
    const selectAnomalyInstance = get(systemAnomalyInstance, [systemId]);
    const predictionIncidentTimelines = (R.find((item) => item.id === systemId, globalSystemIncidentTimelines) || {})
      .anomalyTimelines;
    const systemHealthByInstance = R.find((item) => item.id === systemId, globalHealthByInstance) || {};
    const rankMap = get(systemRankMap, [systemId]);
    const alertTimelines = get(systemAlertTimelinesMap, [systemId], []);
    const incidentTimelines = get(systemIncidentTimelinesMap, [systemId], []);

    const { isLoading, errorMessage, errorInfo } = getLoadStatus(get(loadStatus, this.dataLoader), intl);
    const globalLoading =
      isLoadingSystemChart ||
      isLoadingPanelAnomalies ||
      isLoadingDetectedIncident ||
      isLoadingPanelAlert ||
      isLoadingPanelIncident ||
      isLoadingPanelDeploy;
    let dragItems = dragMap[activeKey];
    if (activeProjectName && activeProjectType) {
      dragItems = R.find(
        (item) => item.type === activeProjectType && item.projectName === activeProjectName,
        dragItems,
      );
      dragItems = dragItems ? [dragItems] : [];
    }
    const hasData = !R.isEmpty(dragItems);
    const startTimeStr = moment(startTime).format(Defaults.DateFormat);
    const endTimeStr = moment(endTime).format(Defaults.DateFormat);

    let timezone = defaultTimezone;
    if (systemInfo?.owner !== userName) {
      const zone = get(systemsMap, [systemInfo?.id, 'timezone']);
      timezone = zone || defaultTimezone;
    }

    let projectNameRCA;
    if (activeEvent) {
      const { projectOwner } = activeEvent;
      projectNameRCA =
        userInfo.userName !== projectOwner ? `${activeEvent.projectName}@${projectOwner}` : activeEvent.projectName;
    }

    return (
      <Container fullHeight withGutter className="flex-col flex-min-height">
        <Container breadcrumb className={showLikelyRootCausesRCA ? 'display-none' : 'flex-row'}>
          <div className="flex-grow flex-row flex-center-align">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, { ...query, systemId: undefined }))}>
                  {intl.formatMessage(appMenusMessages.globalHealthView)}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Select
                  showSearch
                  size="small"
                  value={systemId}
                  style={{ width: 120 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleSystemIdChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.name}${item.ownerUserName ? ` (${item.ownerUserName})` : ''}`}
                      </Select.Option>
                    ),
                    systemList || [],
                  )}
                </Select>
              </Breadcrumb.Item>
            </Breadcrumb>

            <Button size="small" style={{ marginLeft: 8 }} onClick={this.handleUpdateGlobalViewClick}>
              {intl.formatMessage(appButtonsMessages.update)}
            </Button>
          </div>
          <div className="flex-row flex-center-align">
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.startDate)}</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={startTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleStartTimeChange}
              onOpenChange={this.handleStartOpenChange}
            />
            <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.endDate)}</span>
            <DatePicker
              size="small"
              allowClear={false}
              showToday
              value={endTimeObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={this.handleEndTimeChange}
              open={endTimeOpen}
              onOpenChange={this.handleEndOpenChange}
            />

            {(isAdmin || isLocalAdmin) && (
              <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
            )}
            {(isAdmin || isLocalAdmin) && (
              <Select
                showSearch
                size="small"
                value={customerName}
                style={{ width: 100 }}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={this.props.handleCustomerNameChange}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ maxWidth: 650 }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.userName} value={item.userName}>
                      {item.userName}
                    </Select.Option>
                  ),
                  userList || [],
                )}
              </Select>
            )}

            <div
              style={{ marginLeft: 8 }}
              onMouseEnter={() => {
                if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                  this.setState({ tooltipVisibleReloadMouseOver: true });
              }}
              onMouseLeave={() => {
                if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
              }}
            >
              <Tooltip
                mouseEnterDelay={0.3}
                placement="bottomRight"
                open={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                title={
                  disableRefresh
                    ? 'Range of days <= 31'
                    : timeChange
                    ? intl.formatMessage(appMessages.clickToReload)
                    : null
                }
              >
                <Button
                  size="small"
                  disabled={disableRefresh}
                  onClick={() => this.props.handleRefresh({ startTimeObj, endTimeObj })}
                >
                  {intl.formatMessage(appButtonsMessages.refresh)}
                </Button>
              </Tooltip>
            </div>
          </div>
        </Container>

        <Container
          className={`${isLoading ? 'loading ' : ''}global-view flex-grow ${
            showLikelyRootCausesRCA ? 'display-none' : 'flex-row'
          } flex-min-height`}
        >
          {errorMessage && (
            <Container style={{ margin: 8 }}>
              <div className={`ui mini ${errorInfo} message`}>{errorMessage}</div>
            </Container>
          )}

          {!errorMessage && systemId && (systemHealthByInstance || systemHealth) && (
            <div
              className="flex-grow flex-col flex-min-width flex-min-height"
              style={{ position: 'relative', overflow: 'overlay' }}
            >
              <div className="flex-row">
                <div
                  style={{
                    transition: 'height 300ms ease 0s, width 300ms ease 0s',
                    position: 'relative',
                    height: showCollapse ? UHV_PANEL_HEIGHT + 40 : 0,
                  }}
                />
                <div
                  style={{
                    height: showCollapse ? UHV_PANEL_HEIGHT + 40 : 0,
                    position: 'absolute',
                    top: 0,
                    left: 8,
                    right: 8,
                    zIndex: 99,
                    overflow: 'hidden',
                    transition: 'height 300ms ease 0s, width 300ms ease 0s',
                  }}
                >
                  <SystemHealthRow
                    healthRow={this.healthRowRef}
                    userList={userList}
                    userName={userName}
                    startTime={startTime}
                    endTime={endTime}
                    customerName={customerName}
                    environmentId={environmentId}
                    view={view}
                    systemIncidentTimelines={predictionIncidentTimelines}
                    systemHealth={selectAnomalyInstance ? systemHealthByInstance : systemHealth}
                    systemInfo={systemInfo}
                    showHistoricalPredictions={showHistoricalPredictions}
                    showDetectedIncidents={showDetectedIncidents}
                    isLoading={isLoadingSystemChart}
                    selectAnomalyInstance={selectAnomalyInstance}
                    rankMap={rankMap}
                    alertTimelines={alertTimelines}
                    incidentTimelines={incidentTimelines}
                    isLoadingZoomIn={isLoadingZoomIn}
                    summarySettingsMap={summarySettingsMap}
                    logsummaryLoading={logsummaryLoading}
                    onChangeDatazoomTime={this.onChangeSystemDatazoomTime}
                    onChangeSystemDropdown={this.onChangeSystemDropdown}
                    onChangeSystemAnomalyInstance={this.onChangeSystemAnomalyInstance}
                    onChangeSystemPredictions={this.onChangeSystemPredictions}
                    onChangeSystemDetectedIncidents={this.onChangeSystemDetectedIncidents}
                    onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                    updateState={this.updateState}
                    demandLoad={this.demandLoad}
                    changeProjectActive={this.changeProjectActive}
                  />
                </div>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ showCollapse: !showCollapse });
                }}
                style={{
                  cursor: 'pointer',
                  margin: '0 16px 8px',
                  height: 30,
                  flexShrink: 0,
                  position: 'relative',
                }}
                className={`flex-row flex-center-justify flex-center-align content-bg ${
                  showCollapse ? 'corner-0-0-10-10' : 'corner-10'
                }`}
              >
                {showCollapse && (
                  <div className="bold light-label" style={{ position: 'absolute', left: 16 }}>
                    {intl.formatMessage(appFieldsMessages.timezoneCaptical)}: {timezone}
                  </div>
                )}
                {showCollapse && (
                  <BackwardFilled
                    className="important-arrow"
                    style={{ transform: 'rotate(90deg)', color: 'var(--text-color-secondary)', fontSize: 16 }}
                  />
                )}
                {!showCollapse && (
                  <ForwardFilled
                    className="important-arrow"
                    style={{ transform: 'rotate(90deg)', color: 'var(--text-color-secondary)', fontSize: 16 }}
                  />
                )}
              </div>
              <div className="flex-row" id="systemUHVProjectChartContent">
                <div
                  style={{
                    transition: 'height 300ms ease 0s, top 300ms ease 0s, width 300ms ease 0s',
                    position: 'relative',
                    height: showProjectChart ? this.PROJECTCHARTHEIGHT : 0,
                  }}
                />
                <div
                  style={{
                    height: showProjectChart ? this.PROJECTCHARTHEIGHT : 0,
                    position: 'absolute',
                    top: showCollapse ? UHV_PANEL_HEIGHT + 38 + 40 : 38,
                    left: 8,
                    right: 8,
                    zIndex: 99,
                    overflow: 'hidden',
                    transition: 'height 300ms ease 0s, top 300ms ease 0s, width 300ms ease 0s',
                  }}
                >
                  <GlobalSystemChartView
                    intl={intl}
                    credentials={credentials}
                    systemInfo={systemInfo}
                    zoomStartTime={zoomStartTime}
                    zoomEndTime={zoomEndTime}
                    activeLineIconItem={activeLineIconItem}
                    changeProjectActive={this.changeProjectActive}
                    projects={projects}
                  />
                </div>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ showProjectChart: !showProjectChart });
                }}
                style={{
                  cursor: 'pointer',
                  margin: '0 16px',
                  height: 40,
                  flexShrink: 0,
                  position: 'relative',
                }}
                className={`flex-row flex-center-justify flex-center-align content-bg ${
                  showProjectChart ? 'corner-0-0-10-10' : 'corner-10'
                }`}
              >
                {showProjectChart && (
                  <BackwardFilled
                    className="important-arrow"
                    style={{ transform: 'rotate(90deg)', color: 'var(--text-color-secondary)', fontSize: 16 }}
                  />
                )}
                {!showProjectChart && (
                  <ForwardFilled
                    className="important-arrow"
                    style={{ transform: 'rotate(90deg)', color: 'var(--text-color-secondary)', fontSize: 16 }}
                  />
                )}
                <div
                  className="flex-row flex-end-justify"
                  style={{ position: 'absolute', right: 16, width: 420 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <GlobalPanelLineChart
                    startTime={startTime}
                    endTime={endTime}
                    customerName={customerName}
                    environmentId={environmentId}
                    refresh={refresh}
                    projectDisplayMap={projectDisplayMap}
                    systemInfo={systemInfo}
                  />
                  <GlobalPanelLogQuery
                    startTime={startTime}
                    endTime={endTime}
                    customerName={customerName}
                    environmentId={environmentId}
                    refresh={refresh}
                    projectDisplayMap={projectDisplayMap}
                    systemInfo={systemInfo}
                    style={{ marginLeft: 12 }}
                  />
                  <GlobalPanelHistoricalMaxScores
                    activeKey={activeKey}
                    startTime={startTime}
                    endTime={endTime}
                    customerName={customerName}
                    environmentId={environmentId}
                    refresh={refresh}
                    systemHealth={systemHealth}
                    systemInfo={systemInfo}
                    isLoading={isLoadingSystemChart}
                    isAutoReload={isAutoReload}
                    style={{ marginLeft: 12 }}
                  />
                </div>
              </div>

              {zoomStartTime && zoomEndTime && (
                <div
                  className="flex-grow flex-col flex-min-height"
                  style={{ margin: '8px 16px', minHeight: this.MINHTABLEEIGHT }}
                >
                  <GlobalZoomTimelins
                    startTime={startTime}
                    endTime={endTime}
                    customerName={customerName}
                    environmentId={environmentId}
                    refreshTime={refreshTime}
                    refresh={refresh}
                    zoomStartTime={zoomStartTime}
                    zoomEndTime={zoomEndTime}
                    selectAnomalyInstance={selectAnomalyInstance}
                    rankMap={rankMap}
                    systemHealth={systemHealth}
                    systemInfo={systemInfo}
                    summarySettingsMap={summarySettingsMap}
                    logsummaryLoading={logsummaryLoading}
                    isLoadingPanelAnomalies={this.state.isLoadingPanelAnomalies}
                    isLoadingPanelAlert={this.state.isLoadingPanelDeploy}
                    isLoadingPanelIncident={this.state.isLoadingPanelIncident}
                    updateState={this.updateState}
                    projectDisplayMap={projectDisplayMap}
                  />
                </div>
              )}
              <div
                className={`flex-grow flex-col flex-min-height  ${
                  zoomStartTime && zoomEndTime ? ' display-none' : ''
                } content-bg corner-10`}
                style={{ margin: '8px 16px', minHeight: this.MINHTABLEEIGHT }}
              >
                <div className="flex-grow" style={{ position: 'relative', padding: 8 }}>
                  <AutoSizer>
                    {({ width, height }) => {
                      return hasData ? (
                        <Spin
                          spinning={globalLoading}
                          style={{ width, height: 40, position: 'absolute', top: '50%' }}
                          wrapperClassName="flex-grow"
                        >
                          <SortableList
                            intl={intl}
                            items={dragItems}
                            width={width}
                            height={height - 40}
                            startTime={startTime}
                            endTime={endTime}
                            customerName={customerName}
                            environmentId={environmentId}
                            refreshTime={refreshTime}
                            refresh={refresh}
                            zoomStartTime={zoomStartTime}
                            zoomEndTime={zoomEndTime}
                            selectAnomalyInstance={selectAnomalyInstance}
                            systemHealth={systemHealth}
                            systemInfo={systemInfo}
                            isAutoReload={isAutoReload}
                            reloadSystem={reloadSystem}
                            forceRefreshTime={forceRefreshTime}
                            onReloadSystemIncidentTimelines={this.onReloadSystemIncidentTimelines}
                            updateState={this.updateState}
                            isLoadingSystemChart={isLoadingSystemChart}
                            isLoadingPanelAnomalies={isLoadingPanelAnomalies}
                            isLoadingDetectedIncident={isLoadingDetectedIncident}
                            rankMap={rankMap}
                            activeKey={activeKey}
                            isLoading={isLoadingSystemChart}
                            handleUpdateSystemRankMap={this.handleUpdateSystemRankMap}
                            handleUpdateSystemTimelines={this.handleUpdateSystemTimelines}
                            dataTotalCount={dataTotalCount}
                            getReloadDataTotalCount={this.getReloadDataTotalCount}
                            onRefreshData={this.onRefreshData}
                            activeLineIconKey={activeLineIconKey}
                            changeActiveLineIconKey={(activeLineIconKey) => this.setState({ activeLineIconKey })}
                            changeLikelyRootCausesRCA={(data) =>
                              this.setState({ showLikelyRootCausesRCA: true, ...data })
                            }
                            globalLoading={globalLoading}
                          />
                        </Spin>
                      ) : (
                        <div
                          style={{ width: width - 16, height }}
                          className="flex-col flex-center-justify flex-center-align content-bg"
                        >
                          <Spin spinning={globalLoading}>
                            <Empty
                              description={
                                globalLoading ? (
                                  <span />
                                ) : (
                                  <span style={{ color: 'var(--green)' }}>
                                    {activeKey === 'Incidents' && intl.formatMessage(eventMessages.incidentEmpty)}
                                    {activeKey === 'Changes' &&
                                      intl.formatMessage(eventMessages.changesEmpty, {
                                        startTime: startTimeStr,
                                        endTime: endTimeStr,
                                      })}
                                    {activeKey === 'Anomalies' && intl.formatMessage(eventMessages.anomaliesEmpty)}
                                  </span>
                                )
                              }
                            />
                          </Spin>
                        </div>
                      );
                    }}
                  </AutoSizer>
                </div>
              </div>
            </div>
          )}
        </Container>

        {showLikelyRootCausesRCA && (
          <div className="flex-col full-height">
            <Container breadcrumb className="flex-row" style={{ marginBottom: 0 }}>
              <div className="flex-row flex-center-align" style={{ width: 'fit-content' }}>
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() =>
                    this.setState({ showLikelyRootCausesRCA: false, activeEvent: null, needRC: false, rcaFlag: true })
                  }
                >
                  {intl.formatMessage(appMenusMessages.globalHealthView)}
                </Button>
                <div style={{ marginLeft: 4 }}>{`/ ${intl.formatMessage(eventMessages.rootCauseDetails)}`}</div>
              </div>
            </Container>
            <div
              className="flex-grow flex-min-height flex-col content-bg flex-min-width corner-10"
              style={{ margin: '0 16px', padding: 8 }}
            >
              <LikelyRootCausesRCA
                incident={activeEvent}
                environmentId={environmentId}
                systemId={systemInfo.id}
                projectName={projectNameRCA}
                functionRC="root cause"
                needRC={needRC}
                rcaFlag={this.state.rcaFlag}
              />
            </div>
          </div>
        )}

        {showTimeSelectModal && (
          <GlobalCronSelectModal
            startTimestamp={selectStartTimestamp}
            endTimestamp={selectEndTimestamp}
            updateModelLoading={updateModelLoading}
            onClose={this.onCloseTimeSelect}
          />
        )}
        {showLatestLogsSelector && (
          <RecentLogsSelectorModal
            system={currentSystem}
            startTime={startTime}
            endTime={endTime}
            environmentId={environmentId}
            onClose={() => this.setState({ showLatestLogsSelector: false })}
          />
        )}
      </Container>
    );
  }
}

const GlobalSystemHealthView = injectIntl(GlobalSystemHealthViewCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { isAdmin, isReadUser, userName, isLocalAdmin } = state.auth.userInfo;
    const { loadStatus, globalInfo, defaultTimezone, systemsMap, projects } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const {
      globalHealthSummary,
      globalHealthByInstance,
      globalSystemIncidentTimelines,
      globalSystemAnomalyTimelines,
      globalPredictedIncidentList,
      globalSystemDetectIncidentTimelines,
      globalSystemAlertTimelines,
      globalSystemDeployTimelines,
      globalSystemTraceTimelines,
    } = state.dashboard;
    return {
      loadStatus,
      credentials,
      globalInfo,
      isAdmin,
      isLocalAdmin,
      isReadUser,
      userName,
      location,
      userList,
      userInfo: state.auth.userInfo,
      globalHealthSummary,
      globalHealthByInstance,
      globalSystemIncidentTimelines,
      globalSystemAnomalyTimelines,
      globalPredictedIncidentList,
      globalSystemDetectIncidentTimelines,
      globalSystemAlertTimelines,
      globalSystemDeployTimelines,
      globalSystemTraceTimelines,
      defaultTimezone,
      systemsMap,
      projects,
    };
  },
  { push, replace, showAppAlert, createLoadAction, createSetAction, updateLastActionInfo },
)(GlobalSystemHealthView);
