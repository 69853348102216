/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import momenttz from 'moment-timezone';
import VLink from 'valuelink';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import {
  Form,
  Button,
  Alert,
  Input as AntdInput,
  Select as AntSelect,
  DatePicker,
  Popover,
  Checkbox,
  Card,
} from 'antd';

import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { Input, Select } from '../../../../lib/fui/react';
import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  credentials: Object,
  componentState: Object,
};

class ServiceNowSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    this.state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      host: '',
      serviceNowUser: '',
      serviceNowPassword: '',
      clientId: '',
      clientSecret: '',
      tableName: 'incident',
      proxy: '',
      fields: [],
      defaultFields: [],
      timestampField: null,
      timestampFormat: '',
      instanceField: null,
      instanceFieldRegex: '',
      additonalFields: [],
      instanceTimezone: 'UTC',
      historicalDates: [null, null],
      projectModelFlag: false,
      processPauseFlag: false,
      sysparmQuery: '',

      ...(componentState || {}),
    };
    this.timezoneOptions = R.map((t) => ({ label: t, value: t }), momenttz.tz.names());
    this.additonalFieldsOptions = [];
  }

  componentDidMount() {
    this.props.saveProjectInfo('ServiceNow', {}, this.state);
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { credentials } = this.props;
    const { host, serviceNowUser, serviceNowPassword, clientId, clientSecret, tableName, proxy } = this.state;
    const projectCreationType = 'ServiceNow';
    fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      projectCreationType,
      host,
      serviceNowUser,
      serviceNowPassword,
      clientId,
      clientSecret,
      tableName,
      proxy,
    })
      .then((data) => {
        const { success, message } = data;
        if (success === false) {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: message || String(message),
            },
            () => {
              this.props.saveProjectInfo('ServiceNow', {}, this.state);
            },
          );
          return;
        }

        let fields = get(data, 'fields', []);
        const defaultFields = get(data, 'defaultFields', []);

        fields = R.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()), fields);
        this.additonalFieldsOptions = R.map((item) => {
          return { value: item, label: item, disabled: defaultFields.includes(item) };
        }, fields);
        this.setState(
          {
            isVerifying: false,
            verified: get(data, 'success'),
            verifiedMessage: undefined,
            fields,
            defaultFields,
            additonalFields: defaultFields,
          },
          () => {
            this.props.saveProjectInfo('ServiceNow', {}, this.state);
          },
        );
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: err.message || String(err),
          },
          () => {
            this.props.saveProjectInfo('ServiceNow', {}, this.state);
          },
        );
      });
  }

  @autobind
  handleConfirmClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const {
      host,
      serviceNowUser,
      serviceNowPassword,
      clientId,
      clientSecret,
      tableName,
      proxy,
      timestampField,
      timestampFormat,
      instanceField,
      instanceFieldRegex,
      additonalFields,
      instanceTimezone,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
      sysparmQuery,
    } = this.state;
    const { defaultFields } = this.state;
    const newFields = R.difference(additonalFields, defaultFields);
    this.props.createProject(
      'ServiceNow',
      {
        host,
        serviceNowUser,
        serviceNowPassword,
        clientId,
        clientSecret,
        tableName,
        proxy,
        operation: 'register',
        timestampField,
        timestampFormat,
        instanceField,
        instanceFieldRegex,
        additonalFields: JSON.stringify(newFields || []),
        instanceTimezone,
        startTime: historicalDates[0] ? historicalDates[0].startOf('day').valueOf() : undefined,
        endTime: historicalDates[1] ? historicalDates[1].endOf('day').valueOf() : undefined,
        projectModelFlag,
        processPauseFlag,
        sysparmQuery,
      },
      this.state,
    );
  }

  render() {
    const { intl, isLoading, hasError } = this.props;

    const {
      isVerifying,
      verified,
      verifiedMessage,
      fields,
      timestampField,
      timestampFormat,
      instanceField,
      instanceFieldRegex,
      additonalFields,
      instanceTimezone,
      historicalDates,
      projectModelFlag,
      processPauseFlag,
      sysparmQuery,
      tableName,
    } = this.state;
    const hostLink = VLink.state(this, 'host').check((x) => x, 'Host is required');
    const serviceNowUserLink = VLink.state(this, 'serviceNowUser').check((x) => x, 'ServiceNow User is required');
    const serviceNowPasswordLink = VLink.state(this, 'serviceNowPassword').check(
      (x) => x,
      'ServiceNow Password is required',
    );
    const clientIdLink = VLink.state(this, 'clientId');
    const clientSecretLink = VLink.state(this, 'clientSecret');
    const proxyAddressLink = VLink.state(this, 'proxy');
    const hasVerifyError =
      hostLink.error ||
      serviceNowUserLink.error ||
      serviceNowPasswordLink.error ||
      clientIdLink.error ||
      clientSecretLink.error ||
      proxyAddressLink.error ||
      !tableName;
    const hasErrorRegister =
      hostLink.error ||
      serviceNowUserLink.error ||
      serviceNowPasswordLink.error ||
      clientIdLink.error ||
      clientSecretLink.error ||
      proxyAddressLink.error ||
      !tableName ||
      !timestampField ||
      !instanceField ||
      !instanceTimezone ||
      hasError;

    const hasErrorRegisterWorkflow =
      hostLink.error ||
      serviceNowUserLink.error ||
      serviceNowPasswordLink.error ||
      clientIdLink.error ||
      clientSecretLink.error ||
      proxyAddressLink.error ||
      !tableName ||
      !sysparmQuery ||
      hasError;

    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.ServiceNowIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card>
          <Form layout="vertical">
            <Form.Item label="Host URL" required>
              <Input className="full-width" valueLink={hostLink} />
            </Form.Item>
            <Form.Item label="ServiceNow User" required>
              <Input className="full-width" valueLink={serviceNowUserLink} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="ServiceNow Password" required>
              <Input
                className="full-width"
                valueLink={serviceNowPasswordLink}
                type="password"
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item label="Client Id">
              <Input className="full-width" type="password" valueLink={clientIdLink} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Client Secret">
              <Input className="full-width" type="password" valueLink={clientSecretLink} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Table Name" required>
              <AntSelect
                className="full-width"
                value={tableName}
                style={{ width: '100%' }}
                options={[
                  { label: 'incident', value: 'incident' },
                  { label: 'change_request', value: 'change_request' },
                  { label: 'workflow', value: 'workflow' },
                  // { label: 'cmdb_ci', value: 'cmdb_ci' },
                ]}
                onChange={(tableName) => this.setState({ tableName })}
              />
            </Form.Item>
            <Form.Item label="Proxy Address">
              <Input className="full-width" valueLink={proxyAddressLink} style={{ width: '100%' }} />
            </Form.Item>
          </Form>
          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>

        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <Form layout="vertical">
            {tableName !== 'workflow' && (
              <Form.Item label="InsightFinder Analysis Timestamp in ServiceNow" required>
                <AntSelect
                  value={timestampField}
                  onChange={(timestampField) => {
                    this.setState({ timestampField });
                  }}
                  style={{ width: '100%' }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {R.map((field) => {
                    return <AntSelect.Option key={field}>{field}</AntSelect.Option>;
                  }, fields)}
                </AntSelect>
              </Form.Item>
            )}
            {tableName !== 'workflow' && (
              <Form.Item label="Special timestamp format">
                <AntdInput
                  placeholder="yyyy-MM-dd HH:mm:ss"
                  value={timestampFormat}
                  onChange={(e) => {
                    this.setState({ timestampFormat: e.target.value });
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )}
            {tableName !== 'workflow' && (
              <Form.Item
                label={
                  <Popover
                    title={null}
                    content={
                      <div style={{ width: 320 }}>
                        This instance in InsightFinder represents the ticket grouping granularity for pattern learning.
                      </div>
                    }
                    placement="right"
                  >
                    <span>
                      InsightFinder Analysis Instance in ServiceNow
                      <QuestionCircleOutlined size="small" style={{ marginLeft: 4 }} />
                    </span>
                  </Popover>
                }
                required
              >
                <AntSelect
                  value={instanceField}
                  onChange={(instanceField) => {
                    this.setState({ instanceField });
                  }}
                  style={{ width: '100%' }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {R.map((field) => {
                    return <AntSelect.Option key={field}>{field}</AntSelect.Option>;
                  }, fields)}
                </AntSelect>
              </Form.Item>
            )}
            {tableName !== 'workflow' && (
              <Form.Item label="Regular expression for instance name extraction">
                <AntdInput
                  value={instanceFieldRegex}
                  onChange={(e) => {
                    this.setState({ instanceFieldRegex: e.target.value });
                  }}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            )}
            {tableName !== 'workflow' && (
              <Form.Item label="InsightFinder Analysis Additonal Fields in ServiceNow">
                <Checkbox.Group
                  options={this.additonalFieldsOptions}
                  value={additonalFields}
                  onChange={(additonalFields) => {
                    this.setState({ additonalFields });
                  }}
                />
              </Form.Item>
            )}
            {tableName !== 'workflow' && (
              <Form.Item label="Timezone in ServiceNow" required>
                <AntSelect
                  showSearch
                  filterOption
                  options={this.timezoneOptions}
                  value={instanceTimezone}
                  onChange={(instanceTimezone) => {
                    this.setState({ instanceTimezone });
                  }}
                />
              </Form.Item>
            )}

            <Form.Item label="Filter query" required={tableName === 'workflow'}>
              <AntdInput
                placeholder="company=xxx OR company=xxx"
                value={sysparmQuery}
                onChange={(e) => this.setState({ sysparmQuery: e.target.value })}
              />
            </Form.Item>

            {tableName !== 'workflow' && (
              <Form.Item label="Historical Date Range">
                <DatePicker.RangePicker
                  allowClear
                  style={{ width: '50%', margin: '0 8px 0 0' }}
                  value={historicalDates}
                  disabledDate={(current) => {
                    return current && current > moment.utc().add(1, 'days').endOf('day');
                  }}
                  onChange={(historicalDates) => this.setState({ historicalDates })}
                />
                <div className="flex-row" style={{ marginTop: 16 }}>
                  <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                    Enable holistic model
                  </div>
                  <Checkbox
                    checked={projectModelFlag}
                    onChange={(e) => {
                      this.setState({ projectModelFlag: e.target.checked });
                    }}
                  />
                </div>
                <div className="flex-row" style={{ marginTop: 16 }}>
                  <div className="bold" style={{ marginRight: 16, color: 'var(--text-color)' }}>
                    Enable initial processing pause
                  </div>
                  <Checkbox
                    checked={processPauseFlag}
                    onChange={(e) => {
                      this.setState({ processPauseFlag: e.target.checked });
                    }}
                  />
                </div>
              </Form.Item>
            )}
          </Form>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={tableName === 'workflow' ? hasErrorRegisterWorkflow : hasErrorRegister}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const ServiceNowSetting = injectIntl(ServiceNowSettingCore);
export default connect((state) => {
  const { credentials } = state.auth;
  return { credentials };
}, {})(ServiceNowSetting);
