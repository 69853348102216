/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { isNumber } from 'lodash';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

export const TimeInput = ({ date, value, onChange }) => {
  const hour = value?.split(':')[0] || 0;
  const minute = value?.split(':')[1] || 0;
  const formatter = (val) => {
    return val < 10 ? `0${val}` : val;
  };
  const parser = (value) => value;

  return (
    <div>
      <InputNumber
        min={0}
        max={23}
        style={{ width: 60, fontSize: 13 }}
        value={hour}
        onChange={(v) => {
          const val = isNumber(v) ? (v > 23 ? 23 : v) : 0;
          onChange(`${val}:${minute}`);
        }}
        controls={{ upIcon: <PlusOutlined />, downIcon: <MinusOutlined /> }}
        formatter={formatter}
        parser={parser}
      />
      <span style={{ fontSize: 14, padding: '0 2px' }}>:</span>
      <InputNumber
        min={0}
        max={59}
        style={{ width: 60, fontSize: 13 }}
        value={minute}
        onChange={(v) => {
          const val = isNumber(v) ? (v > 59 ? 59 : v) : 0;
          onChange(`${hour}:${val}`);
        }}
        controls={{ upIcon: <PlusOutlined />, downIcon: <MinusOutlined /> }}
        formatter={formatter}
        parser={parser}
      />
    </div>
  );
};
