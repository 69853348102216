import React from 'react';
// import * as R from 'ramda';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import moment from 'moment';
import { SaveOutlined } from '@ant-design/icons';
import { Button, DatePicker, InputNumber } from 'antd';

import { Modal } from '../../../../artui/react';
import { appFieldsMessages, appButtonsMessages } from '../../../common/app/messages';
import { Defaults } from '../../../common/app';
import { causalMessages } from '../../../common/causal/messages';

type Props = {
  intl: Object,
  causalGraphVersion: String,
  errorMessage: ?Object,
  currentLoadingComponents: Object,
  projects: Array<Object>,

  causalKey: String,
  causalGroup: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  projects: Array<Object>,
  customerName: String,
  onClose: Function,
  createCausalIncident: Function,
};

class CreateIncidentModal extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.submitLoader = 'causal_create_incident_submit';
    this.sumbitting = false;

    // Check the project interval to decide the number of day.
    this.maxNumberOfDays = get(props.causalGroup, ['interval'], Defaults.SmallNumberOfDays);

    this.state = {
      datesObj: [moment.utc().subtract(this.maxNumberOfDays, 'days').startOf('day'), moment.utc().endOf('day')],
      cutIntervalInDayStr: this.maxNumberOfDays + 1,
      maxDayValue: this.maxNumberOfDays + 1,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentLoadingComponents, errorMessage } = nextProps;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      if (!errorMessage) {
        this.props.onClose(true);
      }
    }
  }

  @autobind
  handleOnClose() {
    this.props.onClose(false);
  }

  @autobind
  handleSumbit() {
    const { causalGraphVersion, createCausalIncident, causalKey, customerName } = this.props;
    const { datesObj, cutIntervalInDayStr } = this.state;
    const [startTimeObj, endTimeObj] = datesObj;
    this.sumbitting = true;
    createCausalIncident(
      { causalGraphVersion, causalKey, startTimeObj, endTimeObj, customerName, cutIntervalInDayStr },
      { [this.submitLoader]: true },
    );
  }

  render() {
    const { intl, currentLoadingComponents, errorMessage } = this.props;
    const { datesObj, cutIntervalInDayStr, maxDayValue } = this.state;

    const hasError = false;
    const isLoading = get(currentLoadingComponents, this.submitLoader, false);

    return (
      <Modal size="tiny" closable={false} onClose={this.handleOnClose}>
        <div className="content content-bg" style={{ paddingBottom: 0 }}>
          <div className="ui info mini message">
            <i className="icon info" />
            <span>{intl.formatMessage(causalMessages.createTimeRangeInfo)}</span>
          </div>
          <div className="flex-row flex-center-align">
            <label className="bold" style={{ marginRight: 24 }}>
              {intl.formatMessage(appFieldsMessages.date)}:
            </label>
            <DatePicker.RangePicker
              allowClear={false}
              // style={{ width: 220 }}
              value={datesObj}
              disabledDate={(current) => {
                return current && current > moment.utc().add(1, 'days').endOf('day');
              }}
              onChange={(datesObj) => {
                const maxDayValue = datesObj[1].diff(datesObj[0], 'days') + 1;
                this.setState({ datesObj, maxDayValue, cutIntervalInDayStr: maxDayValue });
              }}
            />
          </div>
          <div className="flex-row flex-center-align" style={{ marginTop: 8 }}>
            <div style={{ marginRight: 8 }}>{`${intl.formatMessage(causalMessages.causalTaskDuration)}:`}</div>
            <InputNumber
              min={1}
              max={maxDayValue}
              value={cutIntervalInDayStr}
              style={{ width: 140 }}
              addonAfter="Days"
              onChange={(cutIntervalInDayStr) => this.setState({ cutIntervalInDayStr })}
            />
          </div>
          <br />
        </div>

        {Boolean(errorMessage) && (
          <div className="error content">
            <div className="ui error mini message">
              <i className="icon warning sign" />
              <span>{intl.formatMessage(errorMessage.message, errorMessage.params)}</span>
            </div>
          </div>
        )}
        <div className="actions">
          <Button size="small" onClick={this.handleOnClose}>
            {intl.formatMessage(appButtonsMessages.cancel)}
          </Button>
          <Button
            type="primary"
            size="small"
            icon={<SaveOutlined />}
            style={{ marginLeft: 8 }}
            disabled={hasError}
            loading={isLoading}
            onClick={this.handleSumbit}
          >
            {intl.formatMessage(appButtonsMessages.submit)}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default CreateIncidentModal;
