/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import { createLogic } from 'redux-logic';
import { get } from 'lodash';
import * as R from 'ramda';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions, ifIn } from '../../utils';
import { getLogHotColdEntryList } from '../../apis';
import { appMessages } from '../../app/messages';

const loadLogHotColdEntryListLogic = createLogic({
  type: [ActionTypes.LOAD_LOG_HOTCOLD_ENTRY_LIST],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { nid, startTimestamp, endTimestamp, ...rest } = params;

    // Convert nid from string to int

    const { detailInfos } = state.log;
    const frequencyMap = get(detailInfos, 'frequencyMap', {});
    let hotNids = [];
    let coldNids = [];
    // Get the hot & cold nids in the time range
    R.forEachObjIndexed((val, nid) => {
      R.forEachObjIndexed((v, ts) => {
        if (ts >= startTimestamp && ts <= endTimestamp) {
          if (v.isHot) hotNids.push(nid);
          if (v.isCold) coldNids.push(nid);
        }
      }, val);
    }, frequencyMap);

    hotNids = R.uniq(hotNids);
    coldNids = R.uniq(coldNids);
    const patternIds = R.type(nid) === 'Array' ? nid : nid ? [nid] : R.concat(hotNids, coldNids);

    dispatch(showLoading);
    dispatch(updateLastActionInfo());
    if (patternIds.length === 0) {
      dispatch(createSetAction(ActionTypes.SET_LOG_HOTCOLD_ENTRY_LIST, params, { logEntryList: [] }));

      // callback function
      if (R.type(callback) === 'Function') {
        callback();
      }
      dispatch(hideLoading);
      done();
    } else {
      getLogHotColdEntryList(credentials, {
        patternIds,
        startTimestamp,
        endTimestamp,
        ...rest,
      })
        .then((d) => {
          const { logEntryList, logEntryListTotal, originSize, compressedSize, decreaseRatio } = d;
          dispatch(
            createSetAction(ActionTypes.SET_LOG_HOTCOLD_ENTRY_LIST, params, {
              logEntryList,
              logEntryListTotal,
              originSize,
              compressedSize,
              decreaseRatio,
            }),
          );
        })
        .catch((err) => {
          console.error('[IF_API] Failed get cluster info list', err);
          dispatch(
            createSetAction(ActionTypes.SET_LOG_HOTCOLD_ENTRY_LIST, params, { logEntryList: [], logEntryListTotal: 0 }),
          );
          dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
        })
        .then(() => {
          // callback function
          if (R.type(callback) === 'Function') {
            callback();
          }
          dispatch(hideLoading);
          done();
        });
    }
  },
});

export default loadLogHotColdEntryListLogic;
