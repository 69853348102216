/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import * as R from 'ramda';
import moment from 'moment';
import { isArray } from 'lodash';
import { createLogic } from 'redux-logic';

import { ActionTypes, createSetAction, updateLastActionInfo } from '../actions';
import { getLoadStatusActions, GlobalParse } from '../../utils';
import { getProjectInfo } from '../../apis';
import { appMessages } from '../messages';

const loadProjectInfoLogic = createLogic({
  type: [ActionTypes.LOAD_PROJECT_INFO],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { allProjects, userList } = state.app;
    const { credentials, userInfo } = state.auth;
    const { projectName, includeInstance, withStats } = params;
    let { startTimestamp, endTimestamp } = params;
    const nameList = isArray(projectName) ? projectName : [projectName];

    dispatch(showLoading);
    const projectList = [];
    const logProjectList = [];
    R.forEach(
      (item) => {
        const proj = R.find((project) => project.projectName === item, allProjects);
        if (proj) {
          if (!proj.isMetric) {
            logProjectList.push(item);
          }

          projectList.push({
            projectName: proj.projectShortName,
            customerName: proj.owner,
            projectType: withStats ? proj.projectTypeRaw : undefined,
          });
        }
      },
      R.filter((item) => Boolean(item), nameList),
    );

    if (projectList.length === 0) {
      if (R.type(callback) === 'Function') {
        callback();
      }
      dispatch(hideLoading);
      done();
    } else {
      dispatch(updateLastActionInfo());

      if (includeInstance && (!startTimestamp || !endTimestamp)) {
        const projectOwner = projectList[0].customerName;
        // use user/customer time zone to set isCurrentDay
        let { timezoneOffset } = state.app;
        if ((userInfo.isAdmin || userInfo.isLocalAdmin) && projectOwner) {
          const customerUserInfo = R.find((user) => user.userName === projectOwner, userList || []);
          timezoneOffset = customerUserInfo ? customerUserInfo.userZoneOffset : timezoneOffset;
        }
        const nowTimestamp = moment.utc().valueOf() + (timezoneOffset || 0) * 60000;
        startTimestamp = moment.utc(nowTimestamp).startOf('day').valueOf();
        endTimestamp = moment.utc(nowTimestamp).endOf('day').valueOf();
      }

      let success = false;
      try {
        let data = await getProjectInfo(credentials, {
          projectList,
          includeInstance,
          startTimestamp,
          endTimestamp,
          withStats,
        });

        // Only load log instance list for one log project
        if (logProjectList.length === 1) {
          const projectName = logProjectList[0];
          data = GlobalParse.updateProjectInstanceInfo({ data, projectName });
        }

        success = true;
        dispatch(createSetAction(ActionTypes.SET_PROJECT_INFO, params, data));
      } catch (err) {
        console.log('[IF] Failed to load project info', projectList, err);
        dispatch(
          errorLoading(appMessages.errorsAPIMessage, {
            message: err.message,
            error: err,
          }),
        );
      }

      // callback function
      if (R.type(callback) === 'Function') {
        callback(success);
      }

      dispatch(hideLoading);
      done();
    }
  },
});

export default loadProjectInfoLogic;
