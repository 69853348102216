/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import moment from 'moment';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';
import { Defaults } from '../app';

const newCausalIncident = (credentials: Credentials, params: Object) => {
  const { causalKey, customerName, startTimeObj, endTimeObj } = params;

  // Convert the time to utc time.
  const startTime = startTimeObj.format(Defaults.DateFormat);
  const endTime = endTimeObj.format(Defaults.DateFormat);

  const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('day').valueOf();
  const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('day').valueOf();

  return fetchPost(getEndpoint('eventsrelationprocess'), {
    ...credentials,
    causalKey,
    customerName,
    startTimestamp,
    endTimestamp,
  }).then((d) => {
    return d;
  });
};

export default newCausalIncident;
