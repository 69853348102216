/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import * as R from 'ramda';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const postDeployAgent = (credentials: Credentials, params: Object) => {
  return fetchPost(getEndpoint('settings/deployagent'), {
    ...credentials,
    ...params,
  }).then((d) => {
    return d;
  });
};

export default postDeployAgent;
