/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { push } from 'react-router-redux';
import { get, isArray } from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import { SearchOutlined } from '@ant-design/icons';
import { Drawer, Button } from 'antd';

import { State } from '../../common/types';
import { loadProjectInfo, createLoadAction } from '../../common/app/actions';
import { ActionTypes } from '../../common/query/actions';
import { AutoSizer, Container } from '../../lib/fui/react';
import {
  getLoadStatus,
  buildUrl,
  parseLocation,
  parseQueryString,
  pickNotNil,
  pickNotEmpty,
  ifIn,
  Params,
} from '../../common/utils';
import { BaseUrls } from '../app/Constants';
import { queryMessages, queryFieldMessages } from '../../common/query/messages';
import './query.scss';

import QueryBox from './components/QueryBox';
import QueryCategory from './components/QueryCategory';
import createResultViewer from './createResultViewer';

type Props = {
  intl: Object,
  location: Object,
  push: Function,

  loadStatus: Object,
  loaderStatus: Object,

  projects: Array<Object>,
  templateList: Array<Object>,
  templateMap: Object,
  templateCategoryList: Array<Object>,
  queryResultList: ?Array<Object>,
  appNameMapping: Object,

  loadProjectInfo: Function,
  createLoadAction: Function,
  currentTheme: String,
  timezoneOffset: number,
};

class InsightQueryCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.resultLoader = 'query_result_loader';
    this.paramsLoader = 'query_params_loader';

    // default query options
    this.defaultLimit = 20;

    props.createLoadAction(ActionTypes.LOAD_QUERY_TEMPLATE_LIST);
    this.state = {
      reload: false,
      queryBoxVisible: false,
    };
    this.noNeedProjectInfo = ['8f8f61ab21c44eb3aafa6805685eb8ff', '9076db738dc24cf38e4f9db53ba1deb3'];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Load the query result in two cases:
    // 1. Page refresh when url contains query parameters
    // 2. Url changed by select query or query parameters.
    if (nextProps.templateList !== this.props.templateList || nextProps.location !== this.props.location) {
      const params = parseLocation(this.props.location);
      const nextParams = parseLocation(nextProps.location);
      const isTimeChange =
        params.startTime !== nextParams.startTime ||
        params.endTime !== nextParams.endTime ||
        params.startTimestamp !== nextParams.startTimestamp ||
        params.endTimestamp !== nextParams.endTimestamp ||
        params.time !== nextParams.time ||
        params.interval !== nextParams.interval;
      this.reloadData(nextProps, { isTimeChange });
    }
  }

  @autobind
  handleTemplateSelected(template) {
    const { push } = this.props;

    const t = get(template, 'id', undefined);
    push(buildUrl(BaseUrls.Query, {}, { t }));

    this.showQueryBoxDrawer();
  }

  @autobind
  handleQueryProjectChange({ projectName, startTime, endTime, startTimestamp, endTimestamp }) {
    const { location, projects, loadProjectInfo } = this.props;
    const params = parseQueryString(location.search);
    const { t: templateId } = params;
    const needProjectInfo = this.noNeedProjectInfo.indexOf(templateId) === -1;
    if (projectName && needProjectInfo) {
      const project = R.find((p) => p.projectName === projectName, projects) || {};
      if (!project.hasAllInstanceInfo || ((startTime || startTimestamp) && (endTime || endTimestamp))) {
        let sTime = startTime || startTimestamp;
        const eTime = endTime || endTimestamp;
        if (sTime > eTime) {
          sTime = eTime;
        }
        loadProjectInfo(
          {
            projectName,
            includeInstance: true,
            startTimestamp: sTime,
            endTimestamp: eTime,
          },
          { [this.paramsLoader]: true },
          () => this.callbackHandle(projectName),
        );
      }
    }
  }

  @autobind
  callbackHandle(projectName) {
    this.setState({ projectName });
  }

  @autobind
  handleQueryClear() {
    const { push } = this.props;

    push(buildUrl(BaseUrls.Query, {}, {}));
  }

  @autobind
  handleQuerySubmit(templateParams) {
    const { push } = this.props;
    const {
      projectList,
      templateId,
      instanceGroup,
      startTimeObj,
      endTimeObj,
      startTimestampObj,
      endTimestampObj,
      timeObj,
      forecastInterval,
      ...rest
    } = templateParams || {};

    const startTime = startTimeObj ? startTimeObj.valueOf() : undefined;
    let endTime = endTimeObj ? endTimeObj.valueOf() : undefined;
    const startTimestamp = startTimestampObj ? startTimestampObj.valueOf() : undefined;
    let endTimestamp = endTimestampObj ? endTimestampObj.valueOf() : undefined;
    const time = timeObj ? timeObj.valueOf() : undefined;
    if (forecastInterval) {
      endTime = startTime + forecastInterval;
      endTimestamp = startTimestamp + forecastInterval;
    }
    // remove allInstanceListOptions from query params
    const others = R.omit(['allInstanceListOptions'], rest);
    push(
      buildUrl(
        BaseUrls.Query,
        {},
        {
          ...others,
          t: templateId,
          projectList: projectList && projectList.length > 0 ? R.join(',', projectList) : null,
          instanceGroup: instanceGroup || 'All',
          startTime,
          endTime,
          startTimestamp,
          endTimestamp,
          forecastInterval,
          time,
          start: 0,
        },
      ),
    );
    this.onCloseDrawer();
  }

  @autobind
  handleQueryCancel(templateParams) {
    const { createLoadAction } = this.props;
    createLoadAction(ActionTypes.CANCEL_QUERY, {}, this.resultLoader);
  }

  @autobind
  reloadData(nextProps, { isTimeChange }) {
    const { location, createLoadAction, templateMap, projects } = nextProps;
    let nextParams = parseQueryString(location.search);
    const { t, projectName, start, limit, pageNumber, startTime, forecastInterval } = nextParams;
    let { projectList, endTime } = nextParams;
    if (!endTime) {
      endTime = moment.utc(Number(startTime)).clone().endOf('day').valueOf();
      nextParams = { ...nextParams, endTime };
    }
    if (projectList) {
      projectList = JSON.stringify(R.split(',', projectList));
      nextParams = { ...nextParams, projectList };
    }

    const project = R.find((p) => p.projectName === projectName, projects) || {};
    const { dataType } = project;
    const extraParams = {};
    const template = get(templateMap, t);
    let reloaded = false;
    if (template) {
      let requiredParams = template.requiredParams || [];
      const optionalParams = template.optionalParams || [];
      const fixedParams = template.fixedParams || '';
      const pageParams = { pageNumber };
      if (start && limit) {
        pageParams.start = Number(start);
        pageParams.limit = Number(limit);
      }

      // Handle special projectName for metric and log
      if (ifIn(Params.MetricProjectName, requiredParams)) {
        requiredParams = [Params.ProjectName, ...R.reject((s) => s === Params.MetricProjectName, requiredParams)];
        extraParams[Params.MetricProjectName] = projectName;
      }
      if (ifIn(Params.LogProjectName, requiredParams)) {
        requiredParams = [Params.ProjectName, ...R.reject((s) => s === Params.LogProjectName, requiredParams)];
        extraParams[Params.LogProjectName] = projectName;
      }

      let requiredQueryParams = pickNotEmpty(pickNotNil(R.pick(requiredParams, nextParams)));
      if (!requiredQueryParams.endTime && forecastInterval) {
        requiredQueryParams = { ...requiredQueryParams, endTime };
        requiredParams.push('endTime');
      }
      const optionalQueryParams = pickNotEmpty(pickNotNil(R.pick(optionalParams, nextParams)));

      const needProjectInfo = this.noNeedProjectInfo.indexOf(t) === -1;
      if (requiredParams.length === R.keys(requiredQueryParams).length) {
        reloaded = true;
        this.setState({ reload: false }, () => {
          createLoadAction(
            ActionTypes.LOAD_QUERY_RESULT,
            {
              templateId: t === 'live_tail_log_entries' ? 'search_all_log_entries' : t,
              ...requiredQueryParams,
              ...optionalQueryParams,
              ...extraParams,
              fixedParams,
              ...pageParams,
              projectType: dataType,
              isTimeChange,
              needProjectInfo,
            },
            this.resultLoader,
          );
        });
      }
    }
    if (!reloaded) {
      this.setState({ reload: true });
      // If template changed, reset the query result
      const params = parseQueryString(this.props.location.search);
      if (t !== params.t) {
        createLoadAction(ActionTypes.SET_QUERY_RESULT, {}, []);
      }
    }
  }

  @autobind
  showQueryBoxDrawer() {
    this.setState({
      queryBoxVisible: true,
    });
  }

  @autobind
  onCloseDrawer() {
    this.setState({
      queryBoxVisible: false,
    });
  }

  render() {
    const { intl, projects, loadStatus, loaderStatus, currentTheme, timezoneOffset } = this.props;
    const { templateList, templateMap, templateCategoryList, queryResultList, appNameMapping } = this.props;
    const { location } = this.props;
    const { reload, queryBoxVisible, projectName: pn } = this.state;
    const params = parseQueryString(location.search);
    let { t: templateId, projectName, instanceGroup, startTime, endTime, startTimestamp, endTimestamp } = params;
    let { projectList } = params;
    const {
      time,
      interval,
      duration,
      trainingWindow,
      forecastInterval,
      modelType,
      keyword,
      excludingKeyword,
      instanceName,
      logEventType,
      start,
      limit,
      similarityLevel,
      numOfCluster,
      incidentId,
      pattern,
    } = params;

    projectList = projectList ? R.split(',', projectList) : undefined;
    const template = templateId ? R.find((item) => item.id === templateId, templateList) : null;
    templateId = templateId || 'search_all_log_entries';

    const templateParams = {
      templateId,
      projectName,
      projectList,
      instanceGroup,
      instanceName,
      logEventType,
      modelType,
      keyword,
      excludingKeyword,
      duration,
      interval: interval ? parseInt(interval, 10) : null,
      trainingWindow: trainingWindow ? parseInt(trainingWindow, 10) : null,
      forecastInterval: forecastInterval ? parseInt(forecastInterval, 10) : null,
      startTimeObj: startTime ? moment.utc(Number(startTime)) : null,
      endTimeObj: endTime ? moment.utc(Number(endTime)) : null,
      startTimestampObj: startTimestamp ? moment.utc(Number(startTimestamp)) : null,
      endTimestampObj: endTimestamp ? moment.utc(Number(endTimestamp)) : null,
      timeObj: time ? moment.utc(parseInt(time, 10)) : null,
      start: start ? Number(start) : 0,
      limit: limit ? Number(limit) : this.defaultLimit,
      similarityLevel,
      numOfCluster,
      incidentId,
      pattern,
      timezoneOffset,
    };
    const { isLoading: isResultLoading, errorMessage } = getLoadStatus(get(loadStatus, this.resultLoader), intl);
    const isParamsLoading = get(loaderStatus, this.paramsLoader, false);
    const showQueryResult = Boolean(templateId);
    const project = R.find((p) => p.projectName === projectName, projects);

    return (
      <Container fullHeight className="flex-col query content-bg">
        <Drawer
          placement="top"
          closable={false}
          onClose={this.onCloseDrawer}
          visible={queryBoxVisible}
          // getContainer={false}
          style={{ position: 'absolute' }}
        >
          <Container className="query">
            <QueryBox
              key={templateId}
              intl={intl}
              templateParams={templateParams}
              projects={projects}
              templateList={templateList}
              templateMap={templateMap}
              onProjectChange={this.handleQueryProjectChange}
              onQueryClear={this.handleQueryClear}
              onQuerySubmit={this.handleQuerySubmit}
              isParamsLoading={isParamsLoading}
              isResultLoading={isResultLoading}
              onQueryCancel={this.handleQueryCancel}
              pn={pn}
            />
          </Container>
        </Drawer>
        <Container className="result-toolbar">
          <div className="flex-row flex-center-align flex-space-between">
            <Button key="1" icon={<SearchOutlined />} size="small" onClick={this.showQueryBoxDrawer}>
              {intl.formatMessage(queryFieldMessages.openQueryBox)}
            </Button>
            <div
              style={{
                fontSize: 14,
                fontWeight: 'bold',
              }}
            >
              {template &&
                (R.has(template.id, queryMessages)
                  ? intl.formatMessage(queryMessages[template.id], { displayName: template.desc })
                  : template.desc)}
            </div>
          </div>
        </Container>
        {showQueryResult && (
          <Container className={`result flex-grow flex-col flex-min-height ${isResultLoading ? ' loading' : ''}`}>
            {queryResultList && queryResultList.length > 0 && (
              <AutoSizer>
                {({ width, height }) => {
                  const resobj = queryResultList[0];
                  return createResultViewer(
                    resobj,
                    {
                      width,
                      height,

                      intl,
                      project,
                      onQuerySubmit: this.handleQuerySubmit,
                      onQueryCancel: this.handleQueryCancel,
                      appNameMapping,
                      currentTheme,
                    },
                    templateParams,
                  );
                }}
              </AutoSizer>
            )}
          </Container>
        )}
      </Container>
    );
  }
}

const InsightQuery = injectIntl(InsightQueryCore);
export default connect(
  (state: State) => {
    const { loaderStatus, loadStatus, currentTheme, timezoneOffset } = state.app;
    let { projects } = state.app;
    projects = R.filter((project) => project.status !== 'Deleting', projects);
    const { templateList, templateMap, templateCategoryList } = state.query;
    const { appNameMapping } = state.query;
    const { queryResultList } = state.query.queryResult || {};

    return {
      projects,
      loaderStatus,
      timezoneOffset,
      loadStatus,
      templateList,
      templateMap,
      templateCategoryList,
      queryResultList,
      appNameMapping,
      currentTheme,
    };
  },
  { push, createLoadAction, loadProjectInfo },
)(InsightQuery);
