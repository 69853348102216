/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { getLoaderEpicAction } from '../../utils';
import { apiEpicErrorHandle } from '../../errors';
import { getMetricEventLogList } from '../../apis';
import { updateLastActionInfo } from '../../app/actions';
import { setMetricEventLogList } from '../actions';

const loadMetricEventLogListEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('LOAD_METRIC_EVENT_LOGLIST').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(getMetricEventLogList(credentials, params))
      .concatMap((d) => {
        return Observable.of(setMetricEventLogList(params, d.data));
      })
      .catch((err) => {
        return apiEpicErrorHandle(err);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$, hideLoader);
  });

export default loadMetricEventLogListEpic;
