/*  @flow */
import * as R from 'ramda';
import { get } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import parseEventList from './parsers/parseEventList';

const loadBugDetails = (credentials: Credentials, params: Object) => {
  return fetchGet(getEndpoint('publishedDetection'), {
    ...credentials,
    ...params,
  }).then((d) => {
    const { data } = d || {};
    const { eventArr, ...rest } = data || {};
    return {
      bugDetails: {
        ...rest,
        eventArr: parseEventList({ All: eventArr }, { instanceGroup: 'All' }),
      },
    };
  });
};

export default loadBugDetails;
