/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPostJson from './fetchPostJson';

const postLogPatternName = (credentials: Credentials, projectName: String, params: Object) => {
  const { incidentId, patternId, patternName, instanceName, isPatternSequence, sequencePatterns } = params;
  return fetchPostJson(
    getEndpoint('logstreamingsetpattern'),
    {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: incidentId,
      isPatternSequence: Boolean(isPatternSequence),
    },
    isPatternSequence ? { [sequencePatterns]: patternName } : { [patternId.toString()]: patternName },
  ).then((d) => {
    return d;
  });
};

export default postLogPatternName;
