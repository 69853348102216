/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { isNumber, isString, isArray } from 'lodash';
import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getMetricCapacityScore = (credentials: Credentials, params: Object) => {
  const { projectName, env, endTimestamp } = params;
  const envVal = env === 'All' ? `PROD(${env})` : env.indexOf(':') >= 0 ? R.split(':', env)[1] : env;
  const requests = [];

  if (isArray(projectName)) {
    R.forEach((item) => {
      requests.push(
        fetchGet(getEndpoint('fetchrealtimecapacityscore'), {
          ...credentials,
          projectName: item,
          evn: envVal,
          endTimestamp,
        }).then((data) => {
          return { ...(data || {}), projectNameStr: item };
        }),
      );
    }, projectName);
  } else {
    requests.push(
      fetchGet(getEndpoint('fetchrealtimecapacityscore'), {
        ...credentials,
        projectName,
        evn: envVal,
        endTimestamp,
      }).then((data) => {
        return { ...(data || {}), projectNameStr: projectName };
      }),
    );
  }

  return Promise.all(requests).then((results) => {
    const anomalyInfo = [];
    R.forEach((data) => {
      const { aggregatedWeightedScore, aggregatedPredictedWeightedScore, projectNameStr } = data || {};
      anomalyInfo.push({
        aggregatedWeightedScore: isNumber(aggregatedWeightedScore) ? aggregatedWeightedScore * 100 : 0,
        aggregatedPredictedWeightedScore: isNumber(aggregatedPredictedWeightedScore)
          ? aggregatedPredictedWeightedScore * 100
          : 0,
        projectName: projectNameStr,
      });
    }, results);
    return {
      anomalyInfo: isString(projectName) ? anomalyInfo[0] : anomalyInfo,
    };
  });
};
export default getMetricCapacityScore;
