/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import VLink from 'valuelink';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { isInteger } from 'lodash';
import { autobind } from 'core-decorators';
import { RightOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {
  TreeSelect,
  Button,
  Alert,
  Select as AntSelect,
  DatePicker,
  Checkbox,
  Input as AntInput,
  Card,
  Divider,
} from 'antd';

import { Input, Select } from '../../../../lib/fui/react';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { projectWizardMessages } from '../../../../common/settings/messages';
import { appButtonsMessages } from '../../../../common/app/messages';

type Props = {
  intl: Object,
  saveProjectInfo: Function,
  configureDefaultVal: Object,
  credentials: Object,
  componentState: Object,
  push: Function,
  location: Object,
  projects: Array<Object>,
  systemsMap: Object,
  credentials: Object,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  isReadUser: Boolean,
  createProject: Function,
  userList: Array<Object>,
  userInfo: Object,
};

class DataDogSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { componentState } = props;
    const state = {
      isVerifying: false,
      verified: false,
      verifiedMessage: '',

      appKey: '',
      apiKey: '',
      dataType: 'Metric',
      fields: [],
      instanceKey: '',
      samplingInterval: 5,
      samplingUnit: 60,
      metrics: [],
      selectedMetrics: [],
      treeMetricList: [],
      kpiMetrics: [],
      startTime: null,
      endTime: null,
      processPauseFlag: '',
      componentName: [],
      tagsExp: '',
      additionalQuery: '',
      selectedFields: [],

      additionalFilterList: [],
      componentOptions: [],

      treeData: [],
      allComponentTags: [],
      allTags: [],
      useHostName: 'Host',
    };
    // use the default val from configureDefaultVal
    const configureDefaultVal = props.configureDefaultVal || {};
    R.forEachObjIndexed((val, key) => {
      if (R.has(key, state)) {
        state[key] = val;
      }
    }, configureDefaultVal);

    this.state = {
      ...state,

      ...(componentState || {}),
    };
    this.dataTypeOption = [
      { label: 'Metric', value: 'Metric' },
      { label: 'Event', value: 'Alert' },
      { label: 'Log', value: 'Log' },
    ];
    this.samplingUnit = [
      { label: 'second', value: 1 },
      { label: 'minute', value: 60 },
      { label: 'hour', value: 3600 },
      { label: 'day', value: 86400 },
    ];
    this.dateFormat = 'YYYY-MM-DD';
    this.metrics = [];
    this.filterMetrics = [];

    this.filterComponentOptions = [];
    this.filterFields = [];
    this.filterAllComponentTags = [];
  }

  @autobind
  handleVerifyClick(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ isVerifying: true });
    const { credentials } = this.props;
    const { appKey, apiKey, dataType } = this.state;
    const isMetric = dataType === 'Metric';
    let metrics = [];
    return fetchPost(getEndpoint('project-key-verify'), {
      ...credentials,
      appkey: appKey,
      apikey: apiKey,
      projectCreationType: isMetric ? 'DataDogMetric' : 'DataDogLog',
      dataType,
    })
      .then((data) => {
        const { success, results, fields, tags, message: errMsg } = data;
        if (success) {
          this.handleTagsData(data?.tags || []);
          if (isMetric) {
            metrics = R.uniq(results.metrics || []);
            metrics = metrics.sort((a, b) => {
              return a.toLowerCase().localeCompare(b.toLowerCase());
            });
            this.metrics = metrics;
            this.handleTreeData(metrics);
            this.setState({ metrics });
          } else {
            this.filterComponentOptions = tags;
            this.filterFields = fields;
            this.setState({ fields, componentOptions: tags });
          }
          this.setState(
            {
              isVerifying: false,
              verified: true,
              verifiedMessage: undefined,
              componentName: [],
            },
            () => {
              this.props.saveProjectInfo('Datadog', { dataType }, this.state);
            },
          );
        } else {
          this.setState(
            {
              isVerifying: false,
              verified: false,
              verifiedMessage: errMsg,
              componentName: [],
            },
            () => {
              this.props.saveProjectInfo('Datadog', { dataType }, this.state);
            },
          );
        }
      })
      .catch((err) => {
        this.setState(
          {
            isVerifying: false,
            verified: false,
            verifiedMessage: err.message || String(err),
            componentName: [],
          },
          () => {
            this.props.saveProjectInfo('Datadog', { dataType }, this.state);
          },
        );
      });
  }

  @autobind
  async handleConfirmClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const {
      appKey,
      apiKey,
      dataType,
      selectedFields,
      instanceKey,
      samplingInterval,
      samplingUnit,
      selectedMetrics,
      kpiMetrics,
      startTime,
      endTime,
      processPauseFlag,
      componentName,
      tagsExp,
      additionalQuery,
      additionalFilterList,
      useHostName,
    } = this.state;
    const isMetric = dataType === 'Metric';

    const additionalFilter = [];
    R.forEach((item) => {
      if (item) {
        const addtionList = R.map((n) => n.trim(), item.split('AND'));
        additionalFilter.push(addtionList);
      }
    }, additionalFilterList);

    this.props.createProject(
      'Datadog',
      {
        apiKey,
        appKey,
        dataType,
        fields: JSON.stringify(selectedFields),
        instanceKey,
        operation: 'register',
        samplingInterval: Number(samplingInterval) * samplingUnit,
        metrics: JSON.stringify(selectedMetrics),
        kpiMetrics: JSON.stringify(kpiMetrics),
        startTime: startTime ? startTime.valueOf() : undefined,
        endTime: endTime ? endTime.valueOf() : undefined,
        processPauseFlag,
        ...(isMetric ? { componentName: componentName.join(',') } : { componentFields: JSON.stringify(componentName) }),
        tagsExp: tagsExp ? tagsExp.replaceAll('=', ':') : undefined,
        additionalQuery: additionalQuery || undefined,
        additionalFilter: JSON.stringify(additionalFilter),
        useHostName: useHostName !== 'Host',
      },
      this.state,
    );
  }

  @autobind
  handleChangeMetric(metricNames) {
    const treeMetricList = [];
    let selectedMetrics = [];
    R.forEach((metricName) => {
      R.forEach((metric) => {
        if (metric.indexOf(metricName) === 0) {
          selectedMetrics.push(metric);
        }
      }, this.state.metrics);
      treeMetricList.push(metricName);
    }, metricNames);
    selectedMetrics = R.uniq(selectedMetrics);
    this.setState({ selectedMetrics, treeMetricList });
  }

  @autobind
  handleKPIMetricChange(metrics) {
    this.setState({ kpiMetrics: metrics });
  }

  @autobind
  handleComponentChange(componentName) {
    this.setState({ componentName });
  }

  @autobind
  handleAndTagChange(tag) {
    const { tagsExp } = this.state;
    if (tag) {
      this.setState({ tagsExp: tagsExp ? `${tagsExp} AND ${tag}` : tag });
    }
  }

  @autobind
  handleOrTagChange(tag) {
    const { tagsExp } = this.state;
    if (tag) {
      this.setState({ tagsExp: tagsExp ? `${tagsExp} OR ${tag}` : tag });
    }
  }

  @autobind
  handleFieldChange(fields) {
    this.setState({ selectedFields: fields });
  }

  @autobind
  handleTreeData(metrics) {
    const treeData = [];
    R.forEach((metric) => {
      const metricArr = metric.split('.');
      const nameLen = metricArr.length;
      let nodes = treeData;
      let metricName = '';
      for (let i = 0; i < nameLen; i += 1) {
        const prefix = metricArr[i];
        const nodesNum = nodes.length;
        metricName = metricName === '' ? prefix : `${metricName}.${prefix}`;
        if (nodesNum === 0 || nodes[nodesNum - 1].label !== prefix) {
          nodes.push({
            title: metricName,
            value: metricName,
            key: metricName,
            label: prefix,
            children: [],
          });
        }
        nodes = nodes[nodes.length - 1].children;
      }
    }, metrics);
    this.filterMetrics = metrics;
    this.setState({ treeData });
  }

  @autobind
  handleTagsData(tags) {
    const allTags = [];
    const allComponentTags = [];

    R.forEach(
      (name) => {
        const children = tags[name];
        allTags.push(name);
        allComponentTags.push(name);
        R.forEach(
          (tag) => {
            allTags.push(`${name}=${tag}`);
          },
          R.sort((a, b) => a.localeCompare(b), children),
        );
      },
      R.sort((a, b) => a.localeCompare(b), R.keys(tags)),
    );
    this.filterAllComponentTags = allComponentTags;
    this.setState({ allComponentTags, allTags });
  }

  @autobind
  handleDateRangeChange(timeRange) {
    const [startTime, endTime] = timeRange;
    this.setState({ startTime, endTime });
  }

  @autobind
  handleAddFilter() {
    const { additionalFilterList } = this.state;
    this.setState({
      additionalFilterList: [...additionalFilterList, ''],
    });
  }

  @autobind
  handleDeleteFilter(index) {
    const { additionalFilterList } = this.state;
    this.setState({
      additionalFilterList: R.remove(index, 1, additionalFilterList),
    });
  }

  @autobind
  handleCollectionAndTagChange(value, index) {
    const { additionalFilterList } = this.state;
    const tagsExp = additionalFilterList[index];
    additionalFilterList[index] = tagsExp ? `${tagsExp} AND ${value}=unknown` : `${value}=unknown`;
    this.setState({
      additionalFilterList,
    });
    this.forceUpdate();
  }

  @autobind
  handleCollectionInputChange(e, index) {
    const { additionalFilterList } = this.state;
    additionalFilterList[index] = e.target.value;
    this.setState({
      additionalFilterList,
    });
    this.forceUpdate();
  }

  @autobind
  handleDataTypeChange({ value }) {
    this.setState(
      {
        dataType: value,
        verified: false,
      },
      () => {
        this.props.saveProjectInfo('Datadog', { dataType: value }, this.state);
      },
    );
  }

  render() {
    const { intl, hasError, isLoading } = this.props;
    const {
      isVerifying,
      verified,
      verifiedMessage,
      treeMetricList,
      selectedMetrics,
      startTime,
      endTime,
      processPauseFlag,
      dataType,
      fields,
      tagsExp,
      selectedFields,

      additionalFilterList,
      treeData,
      allComponentTags,
      allTags,
      kpiMetrics,
      componentName,
      useHostName,
    } = this.state;
    const isMetric = dataType === 'Metric';

    const appKeyLink = VLink.state(this, 'appKey').check((word) => word, 'The app key is required');
    const apiKeyLink = VLink.state(this, 'apiKey').check((word) => word, 'The api key is required');
    const dataTypeLink = VLink.state(this, 'dataType').check((word) => word, 'The data type is required');

    const samplingIntervalLink = VLink.state(this, 'samplingInterval').check((word) => {
      return Number(word) > 0 && isInteger(Number(word));
    }, 'The sampling interal is required and it has to be an integer');
    const samplingUnitLink = VLink.state(this, 'samplingUnit').check((word) => word, 'The sampling unit is required');
    const instanceKeyLink = VLink.state(this, 'instanceKey').check((word) => word, 'The instance key is required');
    const instanceQueryLink = VLink.state(this, 'additionalQuery');

    const hasVerifyError = appKeyLink.error || apiKeyLink.error || dataTypeLink.error;

    const tProps = {
      treeData,
      showSearch: true,
      multiple: true,
      treeDefaultExpandAll: true,
      autoClearSearchValue: false,
      value: treeMetricList,
      onChange: this.handleChangeMetric,
      treeCheckable: true,
      showCheckedStrategy: TreeSelect.SHOW_CHILD,
      placeholder: 'Please select metrics',
      style: {
        width: '100%',
      },
      onSearch: (val) => {
        const newMetrics = val
          ? R.filter((item) => item.toLowerCase().indexOf(val.toLowerCase()) >= 0, this.metrics)
          : this.metrics;
        this.handleTreeData(newMetrics);
      },
      dropdownRender: (menu) => {
        return (
          <div>
            <div className="flex-row" style={{ padding: '5px 12px' }} onMouseDown={(event) => event.preventDefault()}>
              <Checkbox
                style={{ marginRight: 8 }}
                checked={treeMetricList.length === this.filterMetrics.length}
                onChange={(e) => {
                  const { checked } = e.target;
                  this.setState({
                    treeMetricList: checked ? this.filterMetrics.map((item) => item) : [],
                  });
                }}
              />
              Select all
            </div>
            <Divider style={{ margin: '4px 0' }} />
            {menu}
          </div>
        );
      },
    };

    const hasErrorMetric =
      samplingIntervalLink.error ||
      samplingUnitLink.error ||
      treeMetricList.length === 0 ||
      selectedMetrics.length === 0 ||
      !useHostName ||
      hasError ||
      !verified;
    const hasErrorAlert =
      hasVerifyError ||
      instanceKeyLink.error ||
      !selectedFields ||
      selectedFields.length === 0 ||
      hasError ||
      !verified;
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <div
          className="text"
          style={{ paddingBottom: '1em' }}
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(projectWizardMessages.PublicDataDogIntro),
          }}
        />

        {verifiedMessage && (
          <div style={{ marginBottom: '1em' }}>
            <Alert message={verifiedMessage} type="error" />
          </div>
        )}

        <Card className="ui form">
          <div className="ui form">
            <div className="field required">
              <label>API Key</label>
              <Input type="password" valueLink={apiKeyLink} />
            </div>
            <div className="field required">
              <label>Application Key</label>
              <Input type="password" valueLink={appKeyLink} />
            </div>
            <div className="field required">
              <label>Data Type</label>
              <Select
                valueLink={dataTypeLink}
                style={{ padding: '2px 0' }}
                options={this.dataTypeOption}
                onChange={this.handleDataTypeChange}
              />
            </div>
          </div>

          <div className="inline field text-right">
            <Button
              type="primary"
              style={{ width: 120, marginTop: 16 }}
              loading={isVerifying}
              disabled={hasVerifyError}
              onClick={this.handleVerifyClick}
            >
              Verify
            </Button>
          </div>
        </Card>

        <Card className={`${verified ? 'block' : 'display-none'}`}>
          <div className="flex-grow">
            {isMetric && (
              <div>
                <div className="field" style={{ margin: '15px 0' }}>
                  <label>
                    Metrics <span style={{ content: '*', color: '#DB2828' }}>*</span>
                  </label>
                  <div className="flex-row">
                    {selectedMetrics.length > 500 && (
                      <div className="ui info message" style={{ display: 'block' }}>
                        <p>Selected metrics should be less than 500</p>
                      </div>
                    )}
                  </div>
                  <div className="flex-row">
                    <TreeSelect {...tProps} className="no-count-num" />
                  </div>
                </div>
                <div className="field" style={{ margin: '15px 0' }}>
                  <label>KPI Metrics</label>
                  <div className="flex-row">
                    <AntSelect
                      value={kpiMetrics}
                      mode="multiple"
                      onChange={this.handleKPIMetricChange}
                      style={{ width: '100%' }}
                    >
                      {R.map((metric) => {
                        return <AntSelect.Option key={metric}>{metric}</AntSelect.Option>;
                      }, selectedMetrics)}
                    </AntSelect>
                  </div>
                </div>
                <div className="field" style={{ margin: '15px 0' }}>
                  <label>
                    Sampling Interval <span style={{ content: '*', color: '#DB2828' }}>*</span>
                  </label>
                  <div className="flex-row">
                    <Input type="number" valueLink={samplingIntervalLink} style={{ width: 200 }} />
                    <Select
                      valueLink={samplingUnitLink}
                      style={{ width: 130, padding: '2px 0' }}
                      options={this.samplingUnit}
                    />
                  </div>
                </div>
              </div>
            )}
            {isMetric && (
              <>
                <div className="field" style={{ margin: '15px 0' }}>
                  <label>Component name</label>
                  <div className="flex-row">
                    <AntSelect
                      value={componentName}
                      showSearch
                      onChange={this.handleComponentChange}
                      style={{ width: '100%' }}
                      mode="multiple"
                      autoClearSearchValue={false}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          this.filterAllComponentTags = allComponentTags;
                        }
                      }}
                      dropdownRender={(menu) => {
                        return (
                          <div>
                            <div
                              className="flex-row"
                              style={{ padding: '5px 12px' }}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={componentName.length === this.filterAllComponentTags.length}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  this.setState({
                                    componentName: checked ? this.filterAllComponentTags.map((item) => item) : [],
                                  });
                                }}
                              />
                              Select all
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            {menu}
                          </div>
                        );
                      }}
                      onSearch={(searchValue) => {
                        const newComponent = searchValue
                          ? R.filter(
                              (item) => item.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
                              allComponentTags,
                            )
                          : allComponentTags;
                        this.filterAllComponentTags = newComponent;
                      }}
                    >
                      {R.map(
                        (t) => (
                          <AntSelect.Option key={t} value={t} className="hide-icon">
                            <Checkbox
                              checked={componentName.indexOf(t) !== -1}
                              onChange={(e) => null}
                              style={{ marginRight: 8 }}
                            />
                            {t}
                          </AntSelect.Option>
                        ),
                        allComponentTags,
                      )}
                    </AntSelect>
                  </div>
                </div>
                <div className="field" style={{ margin: '15px 0' }}>
                  <div className="flex-row" style={{ padding: '4px 0', alignItems: 'center' }}>
                    <label>Instance filters</label>
                    <AntSelect
                      value="AND"
                      showSearch
                      dropdownMatchSelectWidth={280}
                      onChange={this.handleAndTagChange}
                      style={{ width: 86, padding: '0 8px' }}
                    >
                      {R.map(
                        (t) => (
                          <AntSelect.Option key={t} value={t}>
                            {t.value}
                          </AntSelect.Option>
                        ),
                        allTags,
                      )}
                    </AntSelect>
                    <AntSelect
                      showSearch
                      value="OR"
                      dropdownMatchSelectWidth={280}
                      onChange={this.handleOrTagChange}
                      style={{ width: 60 }}
                    >
                      {R.map(
                        (t) => (
                          <AntSelect.Option key={t} value={t}>
                            {t.value}
                          </AntSelect.Option>
                        ),
                        allTags,
                      )}
                    </AntSelect>
                  </div>
                  <div className="flex-row">
                    <AntInput
                      placeholder="(gcp AND zone=us)"
                      value={tagsExp}
                      onChange={(e) => this.setState({ tagsExp: e.target.value })}
                    />
                  </div>
                </div>
                <div className="field" style={{ margin: '15px 0' }}>
                  <label>
                    Instance Name <span style={{ content: '*', color: '#DB2828' }}>*</span>
                  </label>
                  <div className="flex-row" style={{ padding: '4px 0', alignItems: 'center' }}>
                    <AntSelect
                      showSearch
                      value={useHostName}
                      onChange={(useHostName) => this.setState({ useHostName })}
                      style={{ width: 110 }}
                      dropdownMatchSelectWidth={false}
                    >
                      <AntSelect.Option key="Host" value="Host">
                        Host
                      </AntSelect.Option>
                      {/* eslint-disable-next-line react/jsx-boolean-value */}
                      <AntSelect.Option key="Host name" value="Host name">
                        Host name
                      </AntSelect.Option>
                    </AntSelect>
                  </div>
                </div>
              </>
            )}

            {!isMetric && (
              <div className="ui form">
                <div className="field required" style={{ margin: '15px 0' }}>
                  <label>Fields to analyze</label>
                  <div className="flex-row">
                    <AntSelect
                      value={selectedFields}
                      autoClearSearchValue={false}
                      mode="multiple"
                      onChange={this.handleFieldChange}
                      style={{ width: '100%' }}
                      onDropdownVisibleChange={(open) => {
                        if (!open) {
                          this.setState({
                            fields,
                          });
                          this.filterFields = fields;
                        }
                      }}
                      dropdownRender={(menu) => {
                        return (
                          <div>
                            <div
                              className="flex-row"
                              style={{ padding: '5px 12px' }}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selectedFields.length === this.filterFields.length}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  this.setState({
                                    selectedFields: checked ? this.filterFields.map((item) => item) : [],
                                  });
                                }}
                              />
                              Select all
                            </div>
                            <Divider style={{ margin: '4px 0' }} />
                            {menu}
                          </div>
                        );
                      }}
                      onSearch={(searchValue) => {
                        const newFields = searchValue
                          ? R.filter((item) => item.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1, fields)
                          : fields;
                        this.filterFields = newFields;
                      }}
                    >
                      {R.map((field) => {
                        return (
                          <AntSelect.Option key={field} className="hide-icon">
                            <Checkbox
                              checked={selectedFields.indexOf(field) !== -1}
                              onChange={(e) => null}
                              style={{ marginRight: 8 }}
                            />
                            {field}
                          </AntSelect.Option>
                        );
                      }, fields)}
                    </AntSelect>
                  </div>
                </div>
                <div className="field required" style={{ margin: '15px 0' }}>
                  <label>Instance Key</label>
                  <div className="flex-row">
                    <Input
                      type="text"
                      valueLink={instanceKeyLink}
                      style={{ width: '100%' }}
                      placeholder="{key} / {key}={regex}"
                    />
                  </div>
                </div>
                <div className="field" style={{ margin: '15px 0' }}>
                  <label>Instance filter query</label>
                  <div className="flex-row">
                    <Input type="text" valueLink={instanceQueryLink} style={{ width: '100%' }} />
                  </div>
                </div>
              </div>
            )}

            {!isMetric && (
              <div className="field" style={{ margin: '15px 0' }}>
                <label>Component Name</label>
                <div className="flex-row">
                  <AntSelect
                    value={componentName}
                    showSearch
                    onChange={this.handleComponentChange}
                    style={{ width: '100%' }}
                    mode="multiple"
                    autoClearSearchValue={false}
                    onDropdownVisibleChange={(open) => {
                      if (!open) {
                        this.filterComponentOptions = this.state.componentOptions;
                      }
                    }}
                    dropdownRender={(menu) => {
                      return (
                        <div>
                          <div
                            className="flex-row"
                            style={{ padding: '5px 12px' }}
                            onMouseDown={(event) => event.preventDefault()}
                          >
                            <Checkbox
                              style={{ marginRight: 8 }}
                              checked={componentName.length === this.filterComponentOptions.length}
                              onChange={(e) => {
                                const { checked } = e.target;
                                this.setState({
                                  componentName: checked ? this.filterComponentOptions.map((item) => item) : [],
                                });
                              }}
                            />
                            Select all
                          </div>
                          <Divider style={{ margin: '4px 0' }} />
                          {menu}
                        </div>
                      );
                    }}
                    onSearch={(searchValue) => {
                      const newComponentOptions = searchValue
                        ? R.filter(
                            (item) => item.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
                            this.state.componentOptions,
                          )
                        : this.state.componentOptions;
                      this.filterComponentOptions = newComponentOptions;
                    }}
                  >
                    {R.map(
                      (t) => (
                        <AntSelect.Option key={t} value={t} className="hide-icon">
                          <Checkbox
                            checked={componentName.indexOf(t) !== -1}
                            onChange={(e) => null}
                            style={{ marginRight: 8 }}
                          />
                          {t.replace('tags=', '')}
                        </AntSelect.Option>
                      ),
                      this.state.componentOptions,
                    )}
                  </AntSelect>
                </div>
              </div>
            )}

            <div className="field" style={{ margin: '15px 0' }}>
              <label>Historical Date Range</label>
              <div className="flex-row flex-grow flex-center-align">
                <DatePicker.RangePicker
                  allowClear
                  style={{ width: '50%', margin: '0 8px 0 0' }}
                  value={[startTime, endTime]}
                  disabledDate={(current) => {
                    return current && current > moment.utc().add(1, 'days').endOf('day');
                  }}
                  onChange={this.handleDateRangeChange}
                />
              </div>
            </div>
            {!isMetric && (
              <div className="field required" style={{ margin: '15px 0' }}>
                <label style={{ marginRight: 16 }}>Enable initial processing pause</label>
                <Checkbox
                  checked={processPauseFlag}
                  onChange={(e) => {
                    this.setState({ processPauseFlag: e.target.checked });
                  }}
                />
              </div>
            )}

            {!isMetric && (
              <div className="field" style={{ margin: '15px 0' }}>
                <label>
                  Data collection filter
                  <Button size="small" icon={<PlusCircleOutlined />} onClick={this.handleAddFilter}>
                    Add filters
                  </Button>
                </label>
                <div className="flex-col flex-grow">
                  {R.addIndex(R.map)((item, index) => {
                    return (
                      <div
                        key={`${index}filterRow`}
                        className="flex-row flex-center-justify flex-center-align"
                        style={{
                          border: '2px dashed var(--border-color-base)',
                          borderRadius: 16,
                          padding: 12,
                          margin: '6px 0',
                          position: 'relative',
                        }}
                      >
                        {index !== 0 && <span style={{ marginRight: 10 }}>OR</span>}
                        <AntInput value={item} onChange={(e) => this.handleCollectionInputChange(e, index)} />
                        <AntSelect
                          value="AND"
                          showSearch
                          dropdownMatchSelectWidth={280}
                          disabled={item.indexOf('unknown') >= 0}
                          onChange={(value) => this.handleCollectionAndTagChange(value, index)}
                          style={{ width: 86, padding: '0 8px' }}
                        >
                          {R.map(
                            (t) => (
                              <AntSelect.Option key={t} value={t}>
                                {t.value}
                              </AntSelect.Option>
                            ),
                            fields,
                          )}
                        </AntSelect>
                        <div
                          style={{
                            position: 'absolute',
                            top: -15,
                            right: -15,
                            height: 30,
                            width: 30,
                            cursor: 'pointer',
                          }}
                          className="flex-row flex-center-align flex-center-justify content-bg"
                          onClick={(e) => this.handleDeleteFilter(index)}
                        >
                          <MinusCircleOutlined style={{ fontSize: 18 }} />
                        </div>
                      </div>
                    );
                  }, additionalFilterList)}
                </div>
              </div>
            )}
          </div>
        </Card>

        <div
          className={`${verified ? 'block' : 'display-none'}`}
          style={{ position: 'fixed', bottom: 32, right: 64, zIndex: 99 }}
        >
          <Button
            type="primary"
            style={{ width: 120 }}
            disabled={isMetric ? hasErrorMetric : hasErrorAlert}
            onClick={this.handleConfirmClick}
            loading={isLoading}
          >
            {intl.formatMessage(appButtonsMessages.finished)}
          </Button>
        </div>
      </div>
    );
  }
}

const DataDogSetting = injectIntl(DataDogSettingCore);
export default connect(
  (state) => {
    const { credentials } = state.auth;
    const { projects } = state.app;

    return { credentials, projects };
  },
  { push },
)(DataDogSetting);
