/* @flow */
import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { push, replace } from 'react-router-redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { HomeOutlined } from '@ant-design/icons';
import { Select, Tabs, Button, notification, Breadcrumb, Tooltip, DatePicker } from 'antd';

import { BaseUrls } from '../../app/Constants';
import { Defaults, parseLocation, buildUrl, buildLocation } from '../../../common/utils';
import { Container } from '../../../lib/fui/react';
import { createLoadAction, updateLastActionInfo, ActionTypes as AppActionTypes } from '../../../common/app/actions';

import { appFieldsMessages, appButtonsMessages, appMessages, appMenusMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';

import KnowledgeBaseRuleList from './KnowledgeBaseRuleListNew';
import KnowledgeBaseRuleManualList from './KnowledgeBaseRuleManualListNew';
import GlobalKnowledgeBase from './GlobalKnowledgeBase';

type Props = {
  refresh: Number,
  handleRefresh: Function,
  handleCustomerNameChange: Function,
  // eslint-disable-next-line
  handleEnvironmentChange: Function,
  handleSystemIdChange: Function,

  intl: Object,
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  currentLocale: String,
  // eslint-disable-next-line
  userList: Array<Object>,
  // eslint-disable-next-line
  systemsMap: Object,
  // eslint-disable-next-line
  projects: Array<Object>,
  // eslint-disable-next-line
  projectDisplayMap: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  globalInfo: Object,

  // eslint-disable-next-line
  push: Function,
  // eslint-disable-next-line
  replace: Function,
  // eslint-disable-next-line
  createLoadAction: Function,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
};

class GlobalSystemKnowledgeBaseViewCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { location } = props;
    const query = parseLocation(location);
    const { startTime, endTime } = query;

    this.state = {
      startTimeObj: moment.utc(startTime, Defaults.DateFormat),
      endTimeObj: moment.utc(endTime, Defaults.DateFormat),
      timeChange: false,
      disableRefresh: false,
      tooltipVisibleReload: false,
      tooltipVisibleReloadMouseOver: false,

      isLoadingSystem: false,

      activeKey: 'autoRules',
    };
  }

  componentDidMount() {
    const { location, globalInfo } = this.props;
    const query = parseLocation(location);
    const { systemId, environmentId } = query;
    const environment = R.find((e) => e.id === environmentId, globalInfo || []);
    const systemList = get(environment, 'systemList', []);
    const systemInfo = R.find((system) => system.id === systemId, systemList);
    if (systemInfo && !systemInfo.hasAllInstanceInfo) {
      this.loadSystemData(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextQuery = parseLocation(nextProps.location);
    const query = parseLocation(this.props.location);
    if (
      nextQuery.environmentId !== query.environmentId ||
      nextQuery.customerName !== query.customerName ||
      nextQuery.systemId !== query.systemId
    ) {
      const { globalInfo } = nextProps;
      const { systemId, environmentId } = nextQuery;
      const environment = R.find((e) => e.id === environmentId, globalInfo || []);
      const systemList = get(environment, 'systemList', []);
      const systemInfo = R.find((system) => system.id === systemId, systemList);
      if (systemInfo && !systemInfo.hasAllInstanceInfo) {
        this.loadSystemData(nextProps);
      }
    }
  }

  componentWillUnmount() {
    notification.destroy();

    // if conponent unmount, remove setState function, because some fetch action from timer
    this.setState = (state, callback) => {};
  }

  @autobind
  loadSystemData(props) {
    const { location, isAdmin, userInfo, createLoadAction } = props;
    const query = parseLocation(location);
    const { startTime, endTime, customerName, systemId, environmentId } = query;
    if (((isAdmin && customerName) || !isAdmin) && startTime && endTime && systemId && environmentId) {
      this.setState({ isLoadingSystem: true });
      createLoadAction(
        AppActionTypes.LOAD_INFO_SYSTEM,
        {
          startTime,
          endTime,
          customerName: userInfo.isAdmin || userInfo.isLocalAdmin ? customerName : userInfo.userName,
          systemName: systemId,
          environmentId,
          anomalyInstanceOnly: false,
        },
        false,
        true,
        this.callbackHandleUpdateReloadSystemTime,
      );
    }
  }

  @autobind
  callbackHandleUpdateReloadSystemTime() {
    const { location, replace } = this.props;
    const query = parseLocation(location);
    replace(buildLocation(location.pathname, {}, { ...query, reloadSystem: moment.utc().valueOf() }));
    this.setState({ isLoadingSystem: false });
  }

  @autobind
  handleStartTimeChange(timeObj) {
    const startTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { endTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    this.setState({ startTimeObj, timeChange, disableRefresh, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  @autobind
  handleEndTimeChange(timeObj) {
    const endTimeObj = moment.utc(timeObj.valueOf());

    const { location } = this.props;
    const { startTime, endTime } = parseLocation(location);
    const { startTimeObj } = this.state;
    // get ini info
    const timeChange =
      startTime !== startTimeObj.format(Defaults.DateFormat) || endTime !== endTimeObj.format(Defaults.DateFormat);
    const disableRefresh =
      endTimeObj >= startTimeObj.clone().add(31, 'days') || endTimeObj.startOf('day') < startTimeObj.startOf('day');
    const tooltipVisibleReload = timeChange || disableRefresh;
    this.setState({ endTimeObj, timeChange, disableRefresh, tooltipVisibleReload }, () => {
      if (tooltipVisibleReload) setTimeout(() => this.setState({ tooltipVisibleReload: false }), 2000);
    });
  }

  render() {
    const { refresh, intl, location, userList, userInfo, globalInfo, push } = this.props;
    const { customerName, environmentId, systemId } = parseLocation(location);
    const {
      startTimeObj,
      endTimeObj,
      timeChange,
      disableRefresh,
      tooltipVisibleReload,
      tooltipVisibleReloadMouseOver,
    } = this.state;
    const { isLoadingSystem, activeKey } = this.state;
    const environment = R.find((e) => e.id === environmentId, globalInfo);
    let systemList = environment ? environment.systemList : [];
    if (userInfo.isAdmin) {
      systemList = [{ id: 'globalKnowledgeBase', name: 'Global Knowledge Base' }, ...systemList];
    }
    const { instanceDisplayNameMap } = R.find((system) => system.id === systemId, systemList) || {};

    return (
      <Container className="flex-grow flex-col flex-min-height flex-min-width">
        <Container breadcrumb className="flex-row">
          <div className="flex-grow flex-row flex-center-align">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalHealth, {}, {}))}>
                  <HomeOutlined />
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a onClick={() => push(buildUrl(BaseUrls.GlobalSystemKnowledgeBaseNew, {}, {}))}>
                  {intl.formatMessage(appMenusMessages.globalSystemKnowledgeBase)}
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Select
                  showSearch
                  size="small"
                  value={systemId}
                  style={{ width: 200 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleSystemIdChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {`${item.name}${item.ownerUserName ? ` (${item.ownerUserName})` : ''}`}
                      </Select.Option>
                    ),
                    systemList || [],
                  )}
                </Select>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="flex-row">
            <Container className="flex-row" style={{ alignItems: 'center' }}>
              {false && (
                <>
                  <span style={{ fontWeight: 700, padding: '0 1em' }}>
                    {intl.formatMessage(appFieldsMessages.startDate)}
                  </span>
                  <DatePicker
                    size="small"
                    allowClear={false}
                    showToday={false}
                    value={startTimeObj}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                    onChange={this.handleStartTimeChange}
                  />
                  <span style={{ fontWeight: 700, padding: '0 1em' }}>
                    {intl.formatMessage(appFieldsMessages.endDate)}
                  </span>
                  <DatePicker
                    size="small"
                    allowClear={false}
                    showToday={false}
                    value={endTimeObj}
                    disabledDate={(current) => {
                      return current && current > moment.utc().add(1, 'days').endOf('day');
                    }}
                    onChange={this.handleEndTimeChange}
                  />
                </>
              )}

              {/* <span style={{ fontWeight: 700, padding: '0 1em' }}>
                {intl.formatMessage(appFieldsMessages.environment)}
              </span>
              <Select
                size="small"
                value={environmentId}
                style={{ width: 140 }}
                onChange={this.props.handleEnvironmentChange}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item.name} value={item.name} title={item.name}>
                      {item.id}
                    </Select.Option>
                  ),
                  globalInfo || [],
                )}
              </Select> */}
              {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
                <span style={{ fontWeight: 700, padding: '0 1em' }}>{intl.formatMessage(appFieldsMessages.user)}</span>
              )}
              {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
                <Select
                  showSearch
                  size="small"
                  value={customerName}
                  style={{ width: 100 }}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={this.props.handleCustomerNameChange}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ maxWidth: 650 }}
                >
                  {R.map(
                    (item) => (
                      <Select.Option key={item.userName} value={item.userName}>
                        {item.userName}
                      </Select.Option>
                    ),
                    userList || [],
                  )}
                </Select>
              )}

              <div
                style={{ marginLeft: 8 }}
                onMouseEnter={() => {
                  if ((disableRefresh || timeChange) && !tooltipVisibleReloadMouseOver)
                    this.setState({ tooltipVisibleReloadMouseOver: true });
                }}
                onMouseLeave={() => {
                  if (tooltipVisibleReloadMouseOver) this.setState({ tooltipVisibleReloadMouseOver: false });
                }}
              >
                <Tooltip
                  mouseEnterDelay={0.3}
                  placement="bottomRight"
                  visible={tooltipVisibleReload || tooltipVisibleReloadMouseOver}
                  title={
                    disableRefresh
                      ? 'Range of days <= 31'
                      : timeChange
                      ? intl.formatMessage(appMessages.clickToReload)
                      : null
                  }
                >
                  <Button
                    size="small"
                    disabled={disableRefresh}
                    onClick={() => this.props.handleRefresh({ startTimeObj, endTimeObj })}
                  >
                    {intl.formatMessage(appButtonsMessages.refresh)}
                  </Button>
                </Tooltip>
              </div>
            </Container>
          </div>
        </Container>
        {systemId === 'globalKnowledgeBase' && userInfo.isAdmin && (
          <Container
            className="global-view flex-grow flex-col flex-min-height"
            style={{ margin: '0 16px 8px 16px', borderRadius: 4 }}
          >
            <GlobalKnowledgeBase refresh={refresh} />
          </Container>
        )}

        {systemId !== 'globalKnowledgeBase' && (
          <Container
            className="global-view flex-grow flex-col flex-min-height"
            style={{ margin: '0 16px 8px 16px', borderRadius: 4 }}
          >
            <Tabs
              type="card"
              className="full-width full-height ant-tabs-content-full-height"
              activeKey={activeKey}
              onChange={(activeKey) => this.setState({ activeKey })}
            >
              <Tabs.TabPane tab={intl.formatMessage(eventMessages.automaticallyCreatedRules)} key="autoRules">
                <KnowledgeBaseRuleList
                  activeKey={activeKey}
                  tabName="autoRules"
                  refresh={refresh}
                  isLoadingSystem={isLoadingSystem}
                  instanceDisplayNameMap={instanceDisplayNameMap}
                />
              </Tabs.TabPane>
              {(userInfo.isAdmin || userInfo.isLocalAdmin) && (
                <Tabs.TabPane tab={intl.formatMessage(eventMessages.manuallyCreatedRules)} key="manualRules">
                  <KnowledgeBaseRuleManualList
                    activeKey={activeKey}
                    tabName="manualRules"
                    refresh={refresh}
                    isLoadingSystem={isLoadingSystem}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>
          </Container>
        )}
      </Container>
    );
  }
}

const GlobalSystemKnowledgeBaseView = injectIntl(GlobalSystemKnowledgeBaseViewCore);
export default connect(
  (state) => {
    const { location } = state.router;
    const { loadStatus, currentLocale, systemsMap, projects, projectDisplayMap, globalInfo } = state.app;
    let { userList } = state.app;
    userList = R.filter((user) => user.role !== 'Admin', userList || []);
    const { credentials, userInfo } = state.auth;
    const { globalSystemMatchedPredictions } = state.dashboard;
    return {
      location,
      loadStatus,
      currentLocale,
      userList,
      systemsMap,
      projects,
      projectDisplayMap,
      credentials,
      userInfo,

      globalInfo,
      globalSystemMatchedPredictions,
    };
  },
  { push, replace, createLoadAction, updateLastActionInfo },
)(GlobalSystemKnowledgeBaseView);
