import React from 'react';
import {
  BarChartOutlined,
  PieChartOutlined,
  RiseOutlined,
  SlidersOutlined,
  DollarOutlined,
  ProfileOutlined,
  GlobalOutlined,
} from '@ant-design/icons';

import { HoneycombMapIcon } from '../../lib/fui/icons';

import WorldMap from './widgets/WorldMap';
import InsightsHeader from './widgets/InsightsHeader';
import PredictionDetail from './widgets/PredictionDetail';
import InstancesAnomalous from './widgets/InstancesAnomalous';
import ComponentsAnomalous from './widgets/ComponentsAnomalous';
import Overandunder from './widgets/Overandunder';
import PatternsAnomalies from './widgets/PatternsAnomalies';
import PatternsIncidents from './widgets/PatternsIncidents';
import TopCausesChart from './widgets/TopCausesChart';
import TotalMetricCount from './widgets/TotalMetricCount';
import TotalInstanceCount from './widgets/TotalInstanceCount';
import TotalLogCount from './widgets/TotalLogCount';
import SystemIncidents from './widgets/SystemIncidents';
import TrendsTopCostProjects from './widgets/TrendsTopCostProjects';
import TrendsTopCostAccounts from './widgets/TrendsTopCostAccounts';
import TrendsTopCostServices from './widgets/TrendsTopCostServices';
import TopCostProjects from './widgets/TopCostProjects';
import TopCostAccounts from './widgets/TopCostAccounts';
import TopCostService from './widgets/TopCostService';
import HoneycombMap from './widgets/HoneycombMap';
import IncidentPredictionAccuracy from './widgets/IncidentPredictionAccuracy';
import RedutionRatio from './widgets/AlertReductionRatio';
import AverageKubernetesMemoryUsage from './widgets/AverageKubernetesMemoryUsage';
import AverageInstanceCount from './widgets/AverageInstanceCount';
import AverageContainerCount from './widgets/AverageContainerCount';
import AverageK8sHostCount from './widgets/AverageK8sHostCount';
import AveragePodCount from './widgets/AveragePodCount';
import AutoFixedIncidents from './widgets/AutoFixedIncidents';
import NotificationCoverage from './widgets/NotificationCoverage';
import LogComoressionRatio from './widgets/LogComoressionRatio';

export const getWidgetInfos = ({ isCloudCost, hasCPSetting, hasCost, isKubernetes } = {}) => {
  isCloudCost = isCloudCost || hasCost;
  return {
    insights: {
      id: 'insights',
      type: 'header',
      title: 'Incident statistics',
      desc: 'Stats trends of your system',
      component: (props) => {
        return <InsightsHeader {...props} />;
      },
      icon: <RiseOutlined />,
      width: 7,
      height: 3,
      minW: 7,
      minH: 3,
    },
    // predictionDetail: {
    //   id: 'predictionDetail',
    //   title: 'Prediction',
    //   desc: 'Correct predictions of your system',
    //   component: (props) => {
    //     return <PredictionDetail {...props} />;
    //   },
    //   icon: <ProfileOutlined />,
    //   width: 6,
    //   height: 3,
    // },
    costStatistics: {
      id: 'costStatistics',
      isCloudCost: true,
      disable: !isCloudCost,
      title: 'Cost statistics',
      desc: 'Cost statistics',
      component: (props) => {
        return <InsightsHeader {...props} isCloudCost />;
      },
      icon: <RiseOutlined style={{ color: !isCloudCost ? 'var(--disabled-color)' : 'var(--icon-color)' }} />,
      width: 3,
      height: 3,
      minW: 3,
      minH: 3,
    },
    TopCostProjects: {
      id: 'TopCostProjects',
      isCloudCost: true,
      disable: !isCloudCost,
      title: 'Top cost projects',
      desc: 'Top cost projects',
      component: (props) => {
        return <TopCostProjects {...props} />;
      },
      icon: <DollarOutlined style={{ color: !isCloudCost ? 'var(--disabled-color)' : 'var(--icon-color)' }} />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    TopCostAccounts: {
      id: 'TopCostAccounts',
      isCloudCost: true,
      disable: !isCloudCost,
      title: 'Top cost accounts',
      desc: 'Top cost accounts',
      component: (props) => {
        return <TopCostAccounts {...props} />;
      },
      icon: <DollarOutlined style={{ color: !isCloudCost ? 'var(--disabled-color)' : 'var(--icon-color)' }} />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    TopCostService: {
      id: 'TopCostService',
      isCloudCost: true,
      disable: !isCloudCost,
      title: 'Top cost service',
      desc: 'Top cost service',
      component: (props) => {
        return <TopCostService {...props} />;
      },
      icon: <DollarOutlined style={{ color: !isCloudCost ? 'var(--disabled-color)' : 'var(--icon-color)' }} />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    topAnomalousComponents: {
      id: 'topAnomalousComponents',
      title: 'Top anomalous (Components)',
      desc: 'Top anomalous components in your system',
      icon: <BarChartOutlined rotate={90} />,
      component: (props) => {
        return <ComponentsAnomalous {...props} />;
      },
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
      settings: {
        projectNameList: true,
      },
    },
    topAnomalousInstances: {
      id: 'topAnomalousInstances',
      title: 'Top anomalous (Instances)',
      desc: 'Top anomalous instances in your system',
      component: (props) => {
        return <InstancesAnomalous {...props} />;
      },
      icon: <BarChartOutlined rotate={90} />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
      settings: {
        projectNameList: true,
      },
    },
    overAndUnderUtilized: {
      id: 'overAndUnderUtilized',
      title: 'Over and under utilized hosts',
      desc: 'Over and under utilized hosts in your system',
      hasCPSetting: true,
      disable: !hasCPSetting,
      component: (props) => {
        return <Overandunder {...props} />;
      },
      icon: <SlidersOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    TrendsTopCostProjects: {
      id: 'TrendsTopCostProjects',
      isCloudCost: true,
      disable: !isCloudCost,
      title: 'Trend top cost projects',
      desc: 'Trend of top cost projects',
      component: (props) => {
        return <TrendsTopCostProjects {...props} />;
      },
      icon: <DollarOutlined style={{ color: !isCloudCost ? 'var(--disabled-color)' : 'var(--icon-color)' }} />,
      hasCloudCost: true,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    TrendsTopCostAccounts: {
      id: 'TrendsTopCostAccounts',
      isCloudCost: true,
      disable: !isCloudCost,
      title: 'Trend top cost Accounts',
      desc: 'Trend of top cost Accounts',
      component: (props) => {
        return <TrendsTopCostAccounts {...props} />;
      },
      icon: <DollarOutlined style={{ color: !isCloudCost ? 'var(--disabled-color)' : 'var(--icon-color)' }} />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    TrendsTopCostServices: {
      id: 'TrendsTopCostServices',
      isCloudCost: true,
      disable: !isCloudCost,
      title: 'Trend top cost services',
      desc: 'Trend of top cost services',
      component: (props) => {
        return <TrendsTopCostServices {...props} />;
      },
      icon: <DollarOutlined style={{ color: !isCloudCost ? 'var(--disabled-color)' : 'var(--icon-color)' }} />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    topAnomalousPatterns: {
      id: 'topAnomalousPatterns',
      title: 'Top anomalous (Patterns)',
      desc: 'Top anomalous patterns in your system',
      component: (props = {}) => {
        return <PatternsAnomalies {...props} />;
      },
      icon: <PieChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    topIncidentsPatterns: {
      id: 'topIncidentsPatterns',
      title: 'Top incidents (Patterns)',
      desc: 'Top incidents patterns in your system',
      component: (props) => {
        return <PatternsIncidents {...props} />;
      },
      icon: <PieChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    topRootCauses: {
      id: 'topRootCauses',
      title: 'Top Root Cause Categories',
      desc: 'Top root cause categories in your system',
      component: (props) => {
        return <TopCausesChart {...props} />;
      },
      icon: <PieChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    SystemTotalMetricCount: {
      id: 'SystemTotalMetricCount',
      title: 'Total metric count',
      desc: 'Trand of total metric count in your system',
      component: (props) => {
        return <TotalMetricCount {...props} />;
      },
      icon: <BarChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    SystemTotalLogCount: {
      id: 'SystemTotalLogCount',
      title: 'Total log count',
      desc: 'Trend of total log count in your system',
      component: (props) => {
        return <TotalLogCount {...props} />;
      },
      icon: <BarChartOutlined />,
      hasLogProject: true,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    SystemTotalInstanceCount: {
      id: 'SystemTotalInstanceCount',
      title: 'System instance',
      desc: 'Trend of total instance count in your system',
      component: (props) => {
        return <TotalInstanceCount {...props} />;
      },
      icon: <BarChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    SystemTotalIncidentCount: {
      id: 'SystemTotalIncidentCount',
      title: 'System incidents',
      desc: 'Trend of total incident count in your system',
      component: (props) => {
        return <SystemIncidents {...props} />;
      },
      icon: <BarChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    WorldMap: {
      id: 'WorldMap',
      title: 'World map',
      desc: 'Display you location',
      component: (props) => {
        return <WorldMap {...props} />;
      },
      icon: <GlobalOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    HoneycombMap: {
      id: 'HoneycombMap',
      title: 'Honeycomb map',
      desc: 'Display you anomalous heat map',
      component: (props) => {
        return <HoneycombMap {...props} />;
      },
      icon: <HoneycombMapIcon />,
      width: 9,
      height: 7,
      minW: 9,
      minH: 7,
      settings: {
        projectNameList: true,
        zoneList: true,
      },
    },
    IncidentPredictionAccuracy: {
      id: 'IncidentPredictionAccuracy',
      title: 'Prediction statistics',
      desc: 'Display you prediction statistics',
      component: (props) => {
        return <IncidentPredictionAccuracy {...props} />;
      },
      icon: <RiseOutlined />,
      width: 5,
      height: 5,
      minW: 5,
      minH: 5,
    },
    autoFixedIncidents: {
      id: 'autoFixedIncidents',
      title: 'Auto-fixed incidents',
      desc: 'Auto-fixed incidents',
      component: (props) => {
        return <AutoFixedIncidents {...props} />;
      },
      icon: <RiseOutlined />,
      width: 4,
      height: 3,
      minW: 4,
      minH: 3,
    },
    AlertReductionRatio: {
      id: 'AlertReductionRatio',
      title: 'Alert reduction ratio',
      desc: 'Display you alert reduction ratio',
      component: (props) => {
        return <RedutionRatio {...props} />;
      },
      icon: <RiseOutlined />,
      width: 4,
      height: 3,
      minW: 4,
      minH: 3,
    },
    logCompressionRatio: {
      id: 'logCompressionRatio',
      title: 'Log compression ratio',
      desc: 'Log compression ratio',
      component: (props) => {
        return <LogComoressionRatio {...props} />;
      },
      icon: <RiseOutlined />,
      width: 9,
      height: 5,
      minW: 9,
      minH: 5,
    },
    notificationCoverage: {
      id: 'notificationCoverage',
      title: 'Alert notification coverage',
      desc: 'Display you alert notification coverage',
      component: (props) => {
        return <NotificationCoverage {...props} />;
      },
      icon: <RiseOutlined />,
      width: 4,
      height: 3,
      minW: 4,
      minH: 3,
    },
    K8sMemoryUsage: {
      id: 'K8sMemoryUsage',
      isKubernetes: true,
      disable: !isKubernetes,
      title: 'Avg Host Memory Usage',
      desc: 'Avg Host Memory Usage',
      component: (props) => {
        return <AverageKubernetesMemoryUsage {...props} />;
      },
      icon: <BarChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
    },
    ...(!isKubernetes
      ? {
          AverageInstanceCount: {
            id: 'AverageInstanceCount',
            title: 'Instances',
            desc: 'Instances',
            component: (props) => {
              return <AverageInstanceCount {...props} />;
            },
            icon: <BarChartOutlined />,
            width: 6,
            height: 6,
            minW: 6,
            minH: 6,
            settings: {
              projectNameList: true,
            },
          },
        }
      : {}),
    AverageK8sHostCount: {
      id: 'AverageK8sHostCount',
      isKubernetes: true,
      disable: !isKubernetes,
      title: 'Hosts',
      desc: 'Hosts',
      component: (props) => {
        return <AverageK8sHostCount {...props} />;
      },
      icon: <BarChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
      settings: {
        projectNameList: true,
      },
    },
    AveragePodCount: {
      id: 'AveragePodCount',
      isKubernetes: true,
      disable: !isKubernetes,
      title: 'Pods',
      desc: 'Pods',
      component: (props) => {
        return <AveragePodCount {...props} />;
      },
      icon: <BarChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
      settings: {
        projectNameList: true,
      },
    },
    AverageContainerCount: {
      id: 'AverageContainerCount',
      isKubernetes,
      title: 'Containers',
      desc: 'Containers',
      component: (props) => {
        return <AverageContainerCount {...props} />;
      },
      icon: <BarChartOutlined />,
      width: 6,
      height: 6,
      minW: 6,
      minH: 6,
      settings: {
        projectNameList: true,
      },
    },
  };
};
