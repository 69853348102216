import React from 'react';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { Button, Tabs } from 'antd';

import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { Container } from '../../src/lib/fui/react';
import { hideAppLoader } from '../../src/common/app/actions';

import { appButtonsMessages } from '../../src/common/app/messages';

import { BigFileUpload } from '.';
import { downloadFile } from '../../src/common/utils';
import { sampleFileData } from './fileBigFileUpload/utils/sampleFileData';

type Props = {
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,

  // eslint-disable-next-line
  hideAppLoader: Function,
};

class FileAnalysisCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'fileUpload',
      refresh: moment.utc().valueOf(),
    };
  }

  componentDidMount() {
    this.props.hideAppLoader();
  }

  @autobind
  handleRefresh() {
    this.setState({ refresh: moment.utc().valueOf() });
  }

  @autobind
  downloadSampleFile() {
    downloadFile(sampleFileData, 'Sample file.csv');
  }

  render() {
    const { intl } = this.props;
    const { activeTab, refresh } = this.state;
    return (
      <Container fullHeight withGutter className="global-view flex-col flex-min-height flex-min-width">
        {false && (
          <Container breadcrumb className="flex-row">
            <div className="flex-grow" />
            <div className="flex-row">
              <Button size="small" onClick={() => this.handleRefresh()} style={{ marginLeft: 8 }}>
                {intl.formatMessage(appButtonsMessages.refresh)}
              </Button>
            </div>
          </Container>
        )}
        <Container className="block flex-grow flex-col flex-min-height" style={{ padding: 8 }}>
          <Tabs
            type="card"
            className="full-height ant-tabs-content-full-height flex-col"
            activeKey={activeTab}
            onChange={(activeTab) => this.setState({ activeTab })}
          >
            <Tabs.TabPane tab="CSV File Upload" key="fileUpload" style={{ padding: 16 }}>
              <BigFileUpload
                style={{ width: 280, height: 120 }}
                refresh={refresh}
                handleRefresh={this.handleRefresh}
                downloadSampleFile={this.downloadSampleFile}
              />
            </Tabs.TabPane>
          </Tabs>
        </Container>
      </Container>
    );
  }
}

const FileAnalysis = injectIntl(FileAnalysisCore);
export default connect(
  (state) => {
    const { location } = state.router;
    return { location };
  },
  { hideAppLoader },
)(FileAnalysis);
