import React, { useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import * as R from 'ramda';
import ReactDOMServer from 'react-dom/server';
import { get, isNumber, parseInt, round } from 'lodash';

import { Spin } from 'antd';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { Defaults } from '../../../common/utils';

import { EChart } from '../../share';
import DashCellTitle from './DashCellTitle';

import { DashboardMessages } from '../../../common/dashboard/messages';
import { eventMessages } from '../../../common/metric/messages';
import { getCacheData, setCacheData } from '../utils';

const getViewData = (view) => (view || view === 0 ? view : 0);
const floatFixed = (num) => (num ? Number(Number.parseFloat(num).toFixed(3)) : num);
const hexToRgba = (hex, opacity) => {
  if (!hex) return 'red';
  return `rgba(${parseInt(`0x${hex.slice(1, 3)}`)},${parseInt(`0x${hex.slice(3, 5)}`)},${parseInt(
    `0x${hex.slice(5, 7)}`,
  )},${opacity})`;
};

export default function TrendsChart({
  title,
  subTitle,
  isCloudCost = false,
  systemScoreList,
  hasCloudCost = false,
  credentials,
  location,
  globalInfo,
  userInfo,
  intl,
  currentTheme,
  environmentId,
  startTime,
  endTime,
  systemId,
  customerName,
  systemInfo,
  cacheKey,
  projects,
  showMaxNum,
  widgetInfo,
  getPopUpPosition,
}: Object) {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    healthOption: {},
  });
  const { healthOption } = state;

  const buildChartOption = (dataList, baseColor = '#6abbf7', highColor = '#043e7d') => {
    const firstIndex = R.findIndex((item) => Boolean(item.value), dataList);
    const lastIndex = R.findLastIndex((item) => Boolean(item.value), dataList);
    return {
      backgroundColor: 'transparent',
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        confine: true,
        appendToBody: true,
        position: (pos, params, dom, rect, size) => {
          return getPopUpPosition(widgetInfo, pos, size);
        },
        formatter: (params, ticket, callback) => {
          const { name, seriesName, color, data } = params[0];
          const { realValue } = data;
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              {name}
              <br />
              <span
                style={{
                  display: 'inline-block',
                  marginRight: 5,
                  borderRadius: 10,
                  width: 9,
                  height: 9,
                  backgroundColor: color,
                }}
              />
              {`${title === 'Health' ? seriesName : intl.formatMessage(eventMessages.totalLabel)}: ${realValue || 0}`}
            </div>,
          );
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      grid:
        dataList.length > 0
          ? { top: 10, left: 50, right: 30, bottom: 20 }
          : { top: 30, left: 10, right: 10, bottom: 10 },
      xAxis: {
        type: 'category',
        data: R.map((item) => item.name, dataList),
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        axisLabel: {
          fontSize: 10,
          width: '42',
          overflow: 'break',
        },
        splitLine: { show: false },
        splitArea: { show: false },
      },
      series: [
        {
          name: intl.formatMessage(DashboardMessages.healthScore),
          type: 'bar',
          barMaxWidth: '25px',
          data: R.map((item) => {
            return {
              value: R.max(0, item.value),
              realValue: item.value,
              flag: item.flag,
            };
          }, dataList),
          emphasis: {
            itemStyle: {
              color: '#097fd7',
              borderWidth: 0,
            },
          },
          itemStyle: {
            color: (item) => {
              const { data } = item;
              const { flag } = data;
              const highLightColor = flag ? baseColor : highColor;
              return highLightColor;
            },
            borderWidth: 0,
            borderRadius: [2, 2, 0, 0],
          },
        },
      ],
    };
  };

  const buildCostOption = (topCosts, startTimestamp = 0, endTimestamp = 0) => {
    let colors = [];
    if (currentTheme === 'dark') {
      colors = [
        '#3878A1',
        '#59C1BD',
        '#713471',
        '#547ec9',
        '#D37387',
        '#83d4de',
        '#1D7324',
        '#e095e0',
        '#68B78C',
        '#ae9be3',

        '#ee8e76',
        '#7e93b7',
        '#6fc876',
        '#a8d6b7',
        '#d9e446',
        '#ca87ca',
        '#4e6da5',
        '#C35989',
        '#918ADF',
        '#D78742',

        '#B9541A',
        '#E9A133',
        '#3878A1',
        '#A3416E',
        '#F1AEA4',
        '#8BCDBC',
        '#399561',
        '#A4D8A8',
        '#764EA2',
        '#B2ADED',

        '#BC5B22',
        '#EEB46C',
        '#3E769E',
        '#ABC4A2',
        '#3B925D',
        '#B2CB9A',
        '#6565C5',
        '#B4B4F2',
        '#98366D',
        '#DD86AF',

        '#FFC940',
        '#FFEEC5',
        '#96ec9d',
        '#E8F8B6',
        '#D1E1FF',
        '#dab45a',
        '#6B9FA1',
        '#FFE39F',
        '#ddd8cc',
        '#E1BAE1',

        // '#20B2AA'
      ];
    } else {
      colors = [
        '#043e7d',
        '#59C1BD',
        '#E8AA42',
        '#768bff',
        '#98ad72',
        '#2192FF',
        '#38E54D',
        '#acce44',
        '#51718a',
        '#A460ED',

        '#88b1da',
        '#A77979',
        '#1CD6CE',
        '#E8A0BF',
        '#997ec4',
        '#a6c254',
        '#00E0FF',
        '#82A6CB',
        '#9e8c32',
        '#71caaa',

        '#99A799',
        '#4B6587',
        '#89b9d4',
        '#eeb2c6',
        '#ac72cb',
        '#E8AA42',
        '#7D8F69',
        '#0E5E6F',
        '#624F82',
        '#0008C1',

        '#FF8787',
        '#DC5F00',
        '#7978FF',
        '#d6c403',
        '#749F82',
        '#93b3fb',
        '#aad183',
        '#AF0171',
        '#d9b1c7',
        '#E14D2A',

        '#e8ca81',
        '#7cbaa3',
        '#dbb25e',
        '#6ec3ea',
        '#b0a79b',
        '#E80F88',
        '#0096FF',
        '#B270A2',
        '#5A8F7B',
        '#DC3535',

        // '#FBA1A1',
      ];
    }

    const data = topCosts || [];
    let xAxisData = [];
    const series = [];
    const legendDate = [];

    let maxBarCount = 0;
    const markPoints = [];
    const maxProjectCost = R.sortWith([R.descend(R.prop('cost'))], data)[0] || undefined;
    if (maxProjectCost) {
      const sortCostData = R.sortWith([R.descend(R.prop('cost'))], maxProjectCost?.dailyCosts || []);
      const compareds = R.take(3, sortCostData);
      const otherCompareds = R.slice(1, Infinity, compareds);
      const comparedsMaxCost = compareds[0].cost || 0;
      const comparedsMaxTime = compareds[0].dailyTimestamp || 0;

      R.forEach((item) => {
        const selfCost = item.cost || 0;
        const otherDoubleCost = selfCost * 2;
        if (comparedsMaxCost > otherDoubleCost && !maxBarCount) maxBarCount = otherDoubleCost;
      }, otherCompareds);
      R.forEach((item) => {
        const selfCost = item.cost || 0;
        const otherDoubleCost = selfCost * 2;
        if (otherDoubleCost > comparedsMaxCost && maxBarCount) {
          const costIndex = R.findIndex(
            (c) => item.cost === c.cost && item.dailyTimestamp === c.dailyTimestamp,
            maxProjectCost?.dailyCosts || [],
          );
          if (costIndex !== -1)
            markPoints.push({
              index: costIndex,
              num: selfCost,
              value: `${isCloudCost || hasCloudCost ? '$ ' : ''}${item.cost}`,
            });
        }
      }, [] || otherCompareds);

      // 把最大数值的加进去
      if (maxBarCount) {
        const maxNumIndex = R.findIndex(
          (item) => item.cost === comparedsMaxCost && item.dailyTimestamp === comparedsMaxTime,
          maxProjectCost?.dailyCosts || [],
        );
        markPoints.push({
          index: maxNumIndex,
          num: maxBarCount,
          value: `${isCloudCost || hasCloudCost ? '$ ' : ''}${comparedsMaxCost}`,
        });
      }
    }

    R.addIndex(R.forEach)((item, index) => {
      const { dailyCosts = [], componentName, instanceName, projectName } = item;

      R.forEach((itemsTamp) => {
        xAxisData.push(itemsTamp.dailyTimestamp);
      }, dailyCosts);

      const colorName = index <= colors.length - 1 ? colors[index] : '#FBA1A1';
      const itemName = title === 'Accounts' ? componentName : title === 'Services' ? instanceName : projectName;

      legendDate.push({
        name: itemName,
        itemStyle: {
          color: colorName,
        },
      });

      series.push({
        type: 'bar',
        data: R.map((costItem) => {
          const value = getViewData(floatFixed(costItem.cost));
          return {
            ...costItem,
            value,
            time: moment.utc(Number(costItem.dailyTimestamp)).format(Defaults.ShortDayFormat),
            flag: costItem.dailyTimestamp < startTimestamp || costItem.dailyTimestamp > endTimestamp,
          };
        }, dailyCosts),
        emphasis: {
          itemStyle: {
            color: '#097fd7',
            borderWidth: 0,
          },
        },
        barMaxWidth: 20,
        zlevel: 20,
        stack: 'total',
        name: itemName,
        itemStyle: {
          color: (itemColor) => {
            return hexToRgba(colorName, itemColor?.data?.flag ? 0.9 : 1);
          },
        },
        markPoint: showMaxNum
          ? {
              data: R.map(
                (mp) => ({
                  coord: [mp.index, mp.num],
                  value: mp.value,
                }),
                markPoints,
              ),
              label: {
                color: currentTheme === 'dark' ? '#b4b4c4' : 'black',
              },
              itemStyle: {
                color: 'transparent',
              },
              symbolOffset: [0, 13],
            }
          : {},
      });
    }, data);

    xAxisData = R.uniq(xAxisData);
    xAxisData = R.sort((a, b) => a - b, xAxisData);
    xAxisData = R.map((item) => moment.utc(Number(item)).format(Defaults.ShortDayFormat), xAxisData);

    const healthOption = {
      backgroundColor: 'transparent',
      legend: {
        show: false,
        type: 'scroll',
        top: 0,
        data: legendDate,
      },
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        enterable: true,
        appendToBody: true,
        position: (pos, params, dom, rect, size) => {
          return getPopUpPosition(widgetInfo, pos, size);
        },
        formatter: (params, ticket, callback) => {
          let newParams = R.filter((item) => item.data, params);
          newParams = R.sortWith([R.descend(R.prop('value'))], newParams);
          if (newParams[0]?.name) {
            return ReactDOMServer.renderToStaticMarkup(
              <div style={{ maxHeight: 230, overflowY: 'auto', padding: 16 }}>
                <div className="flex-row flex-center-align">
                  <div style={{ fontSize: 14, fontWeight: 'bold', marginRight: 12 }}>{newParams[0]?.name}</div>
                  {hasCloudCost && (
                    <div style={{ fontSize: 14, fontWeight: 'bold' }}>
                      Total:{' '}
                      <span>{`$ ${Number.parseFloat(R.sum(R.map((_item) => _item.value, newParams || []))).toFixed(
                        2,
                      )}`}</span>
                    </div>
                  )}
                </div>
                {R.addIndex(R.map)((item, index) => {
                  const { color, data, seriesName } = item;
                  const findProject = R.find((item) => item.projectShortName === seriesName, projects || []);
                  return (
                    <div key={index}>
                      <span className="ecahrt-tooltip-dot" style={{ backgroundColor: color }} />
                      <span style={{ margin: '0 10px 0 5px' }}>{findProject?.projectDisplayName || seriesName}:</span>
                      <span style={{ fontSize: 15, fontWeight: 'bold', color }}>{`${
                        isCloudCost || hasCloudCost ? '$ ' : ''
                      }${data.value}`}</span>
                    </div>
                  );
                }, newParams)}
              </div>,
            );
          }
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      grid:
        data.length > 0 ? { top: 20, left: 50, right: 30, bottom: 20 } : { top: 30, left: 10, right: 10, bottom: 10 },
      xAxis: {
        type: 'category',
        data: xAxisData,
        splitLine: { show: false },
        splitArea: { show: false },
      },
      yAxis: {
        type: 'value',
        axisLine: { show: true },
        axisLabel: {
          fontSize: 10,
          width: '42',
          overflow: 'break',
          formatter: (value, index) => {
            return `$${value}`;
          },
        },
        splitLine: { show: false },
        splitArea: { show: false },
        ...(showMaxNum && maxBarCount ? { max: Math.ceil(maxBarCount) } : {}),
      },
      series,
    };
    setState({ healthOption });
    setIsLoading(false);
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
    const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
    const newStartTimestamp = moment.utc(startTimestamp).subtract(7, 'days').valueOf();
    const newEndTimestamp = moment.utc(endTimestamp).add(7, 'days').valueOf();
    const healthScoreMap = {};
    R.forEach((item) => {
      healthScoreMap[item.dailyTimestamp] =
        item[title === 'Incidents' ? 'totalIncidents' : title === 'Alerts' ? 'totalAlerts' : 'healthScore'];
    }, systemScoreList || []);

    let dataList = [];
    R.forEach((ts) => {
      const timestamp = ts * 86400000;
      const value = get(healthScoreMap, String(timestamp));
      dataList.push({
        timestamp,
        value: isNumber(value) ? round(value) : null,
        flag: timestamp < startTimestamp || timestamp > endTimestamp,
      });
    }, R.range(newStartTimestamp / 86400000, newEndTimestamp / 86400000 + 1));
    dataList = R.sortWith([R.ascend(R.prop('timestamp'))], dataList);
    dataList = R.map((item) => {
      const name = moment.utc(item.timestamp).format(Defaults.ShortDayFormat);
      return { ...item, name };
    }, dataList);
    dataList = R.filter((item) => item.timestamp < moment.utc().endOf('day').valueOf(), dataList);
    dataList = R.find((item) => item.value, dataList || []) ? dataList : [];

    const option = buildChartOption(dataList);
    setState({ healthOption: option });
  }, [systemScoreList, currentTheme]);

  // get top cost
  useEffect(() => {
    if (!isCloudCost) return;
    let cancel = false;
    if (systemInfo) {
      setIsLoading(true);
      const newTimesTamp = moment.utc(moment.utc(), Defaults.DateFormat).endOf('days').valueOf();
      const startTimestamp = moment.utc(startTime, Defaults.DateFormat).startOf('days').valueOf();
      const endTimestamp = moment.utc(endTime, Defaults.DateFormat).endOf('days').valueOf();
      const newStartTimestamp = moment.utc(startTimestamp).subtract(7, 'days').valueOf();
      let newEndTimestamp = moment.utc(endTimestamp).add(7, 'days').valueOf();
      if (newTimesTamp < newEndTimestamp) {
        newEndTimestamp = newTimesTamp;
      }
      const params = {
        ...credentials,
        customerName: systemInfo.customerName,
        systemName: systemId,
        startTime: newStartTimestamp,
        endTime: newEndTimestamp,
        granularity: title === 'Accounts' ? 'component' : title === 'Services' ? 'instance' : 'project',
      };
      const apiData = getCacheData({ ...params, cacheKey, url: 'topCosts' });
      if (apiData) {
        buildCostOption(apiData, startTimestamp, endTimestamp);
        return;
      }

      fetchGet(getEndpoint('top-costs'), R.clone(params))
        .then((data) => {
          if (cancel) return;
          const { success, message: msg, topCosts } = data;
          if (success || success === undefined) {
            buildCostOption(topCosts, startTimestamp, endTimestamp);
            setCacheData({ ...params, cacheKey, url: 'topCosts' }, topCosts);
          } else {
            buildCostOption([]);
            setCacheData({ ...params, cacheKey, url: 'topCosts' }, []);
            console.error(msg);
          }
        })
        .catch((err) => {
          if (cancel) return;
          buildCostOption([]);
          console.error(err);
        });
    } else {
      buildCostOption([]);
    }
    return () => {
      cancel = true;
    };
  }, [systemId, currentTheme]);

  const hasHealthOption = ((healthOption?.series || [])[0]?.data || []).length < 1;
  return (
    <Spin
      spinning={isLoading}
      wrapperClassName="spin-base dash-grid-cell content-bg corner-10 overflow-x-hidden overflow-y-auto"
    >
      <DashCellTitle title={title} subTitle={subTitle} />
      <div style={{ position: 'relative', height: 'calc(100% - 48px)' }}>
        <EChart width="100%" height="100%" option={healthOption} onEvents={{}} theme={currentTheme} />
        {hasHealthOption && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-55%, -30%)',
              color: 'var(--text-color-secondary)',
              width: 160,
            }}
          >
            No data for this time period
          </div>
        )}
      </div>
    </Spin>
  );
}
