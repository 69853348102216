import { createLogic } from 'redux-logic';

import { ActionTypes, setResetPasswordByTempStatus } from '../actions';
import { getLoadStatusActions } from '../../utils';
import { resetPasswordByTemp } from '../../apis';

const resetNewPasswordByTempLogic = createLogic({
  type: [ActionTypes.RESET_NEW_PASSWORD_BY_TEMP],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const { params, loader } = action.payload;
    const { showLoading, hideLoading } = getLoadStatusActions(loader);

    dispatch(showLoading);

    resetPasswordByTemp(params)
      .then((d) => {
        if (d.success) {
          dispatch(setResetPasswordByTempStatus(true));
        } else {
          dispatch(setResetPasswordByTempStatus(false, d.message || 'The reset code is incorrect or expired.'));
        }
        dispatch(hideLoading);
        done();
      })
      .catch(async (err) => {
        console.error('[IF_API] Failed reset new password, ignored', err);

        // get message from stream body
        const result = err.responseJson ? err.responseJson : err.response.json();
        const { message } = result || {};

        dispatch(setResetPasswordByTempStatus(false, message || err.response.message));
        dispatch(hideLoading);
        done();
      });
  },
});

export default resetNewPasswordByTempLogic;
