/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

/* eslint-disable no-console */
import { Observable } from 'rxjs/Observable';

import type { Deps } from '../../types';
import { deleteProjectHoliday } from '../../apis';
import { apiEpicErrorHandle } from '../../errors';
import { updateLastActionInfo } from '../../app/actions';
import { loadProjectHoliday } from '../actions';
import { getLoaderEpicAction } from '../../utils';

const removeProjectHolidayEpic = (action$: any, { getState }: Deps) =>
  action$.ofType('REMOVE_PROJECT_HOLIDAY').concatMap((action) => {
    const { params, loader } = action.payload;
    const state = getState();
    const { credentials } = state.auth;
    const { showLoader, hideLoader } = getLoaderEpicAction(loader);

    const apiAction$ = Observable.from(deleteProjectHoliday(credentials, params))
      .concatMap(() => {
        return Observable.concat(Observable.of(loadProjectHoliday(params, loader)), hideLoader);
      })
      .catch((err) => {
        return Observable.concat(apiEpicErrorHandle(err), hideLoader);
      });

    return Observable.concat(showLoader, Observable.of(updateLastActionInfo()), apiAction$);
  });

export default removeProjectHolidayEpic;
