/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 * */

import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { CaretDownOutlined, CaretUpOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, Input } from 'antd';

import { State } from '../../../common/types';
import { Defaults, CellRenderers } from '../../../common/utils';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer, Dropdown, Popover } from '../../../lib/fui/react';

import { appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import WordCloud from './WorkCloud';
import { logMessages } from '../../../common/log/messages';

function isEdge() {
  const ua = navigator.userAgent.toLowerCase();
  let tem;
  let M = ua.match(/(edg(?=\/))\/?\s*(\d+)/i) || [];
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(Edge|edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.includes('edg') && M[1] < 114;
}

type Props = {
  intl: Object,
  handleActionClick: Function,
  handleEmailAlertsClick: Function,
  handleLogQueryClick: Function,
  activePatternId: String,
  startTime: String,
  endTime: String,
  instanceName: String,
  handlePatternChange: Function,
  eventList: Array,
  clusterActivePatternChange: Number,
  setLoading: Function,
};

class LogAnalysisPatternListCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { activePatternId, eventList } = props;

    this.dataLoader = 'log_cluster_content';
    this.listHeaderHeight = 30;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 40,
    });

    this.state = {
      eventList,
      patternSearch: undefined,
      allPatternsEvent: {},
      activePatternId,
    };
    this.localEventList = [];
    this.cloneLocalEventList = [];
    this.isEdge = isEdge();
  }

  componentDidMount() {
    this.parseData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.activePatternId !== nextProps.activePatternId ||
      this.props.clusterActivePatternChange !== nextProps.clusterActivePatternChange
    ) {
      this.parseData(nextProps);
    }
  }

  @autobind
  parseData(props) {
    const { activePatternId, eventList } = props;
    const { sortBy, sortDirection } = this.state;

    // add all patterns info
    const allPatternsEvent = {
      isAllPattern: true,
      patternId: 'all',
      nid: 'all',
      count: R.reduce(
        R.add,
        0,
        R.map((item) => item.count, eventList),
      ),
    };

    // filter and sort
    let localEventList = this.filterData(eventList, this.state);
    localEventList = this.sortData(localEventList, sortBy, sortDirection);
    this.localEventList = localEventList;
    this.cloneLocalEventList = localEventList;
    if (this.cellMeasureCache) this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
    this.setState(
      {
        eventList,
        activePatternId,
        allPatternsEvent,
      },
      () => {
        const findIdx = R.findIndex(
          (item) => String(item.patternId) === this.state.activePatternId,
          this.localEventList,
        );
        if (this.listNode) {
          this.listNode.scrollToRow(findIdx);
          this.listNode.forceUpdateGrid();
        }
      },
    );
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [
      R.descend(R.prop('isAllPattern')),
      R.descend(R.prop('hasAnomalyFlag')),
      R.descend(R.prop('count')),
    ];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(eventList);
    return sortList;
  }

  @autobind
  filterData(eventList, state) {
    const { patternIdFilter } = state;

    let filterList = eventList || [];

    if (patternIdFilter) {
      filterList = R.filter((log) => {
        return String(log.patternId) === R.toLower(patternIdFilter);
      }, filterList);
    }

    return filterList;
  }

  @autobind
  onChangeFilterFunc(field, value) {
    this.setState({ [field]: value }, () => {
      const { eventList, sortBy, sortDirection } = this.state;
      let localEventList = this.filterData(eventList, this.state);
      localEventList = this.sortData(localEventList, sortBy, sortDirection);

      this.localEventList = localEventList;
      this.cellMeasureCache.clearAll();
      if (this.listNode) this.listNode.forceUpdateGrid();
      this.forceUpdate();
    });
  }

  @autobind
  renderListItem(events) {
    return ({ key, index: rowIndex, style, parent }) => {
      const rowData = events[rowIndex];
      if (!rowData) return null;

      const { isAllPattern, count, patternId } = rowData;
      const active = String(patternId) === String(this.state.activePatternId);
      return (
        <CellMeasurer
          key={key + patternId}
          cache={this.cellMeasureCache}
          parent={parent}
          columnIndex={0}
          rowIndex={rowIndex}
        >
          <div
            className={`event-list-row clickable ${active ? 'active' : ''} ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
            style={{ ...style, minHeight: 40 }}
            onClick={() => this.handleEventClick(rowData)}
          >
            <div className="row-column" style={{ width: 60 }}>
              {this.renderStatus(rowData)}
            </div>
            <div className="row-column" style={{ width: 60, flex: 1 }}>
              {this.renderPattern(rowData)}
            </div>
            <div className="row-column" style={{ width: 80 }}>
              {count}
            </div>
            <div className="row-column" style={{ width: 40 }}>
              {!isAllPattern && this.renderControl(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  @autobind
  handleEventClick(rowData) {
    const activePatternId = String(rowData.patternId);
    if (this.props.handlePatternChange && activePatternId !== String(this.state.activePatternId)) {
      this.props.handlePatternChange(activePatternId);
      this.setState({ activePatternId });
      this.props.setLoading(true);
    }
  }

  @autobind
  renderStatus(rowData) {
    const { intl } = this.props;
    const { hasAnomalyFlag, newPatternFlag, incidentFlag, whitelistFlag, coldFlag, hotFlag } = rowData;

    const typeList = [];
    if (newPatternFlag) typeList.push('newpattern');
    if (incidentFlag) typeList.push('incident');
    if (whitelistFlag) typeList.push('whitelist');
    if (coldFlag) typeList.push('cold');
    if (hotFlag) typeList.push('hot');
    return (
      <div className="flex-row flex-wrap full-width">
        {hasAnomalyFlag &&
          R.map((type) => {
            return CellRenderers.logShortTypeRenderer({ intl, type });
          }, typeList)}
      </div>
    );
  }

  @autobind
  renderPattern(rowData) {
    const { intl } = this.props;
    const { isAllPattern, patternName, patternId, topK } = rowData;

    if (isAllPattern) {
      return 'All patterns';
    }
    const words = R.map(
      (word) => {
        word = R.trim(word);
        const regex = /(?<=\()(.+?)(?=\))/g;
        const value = word.match(regex)[0];
        const text = word.replace(regex, '').replace(/\(\)/g, '');
        return { text, value };
      },
      topK ? topK.split(',') : [],
    );
    const { patternNameStr } = Defaults.PatternIdNameStr({ patternName, patternId }, { hasFullName: true });
    return (
      <Popover
        content={
          <div className="flex-col">
            <div className="flex-row flex-center-align">
              <div style={{ width: 100 }}>{intl.formatMessage(logMessages.patternName)}:</div>
              <div className="flex-grow hidden-line-with-ellipsis">{patternNameStr}</div>
            </div>
            <div className="flex-row" style={{ marginTop: 10 }}>
              {this.isEdge && (
                <>
                  <div style={{ width: 100 }}>{intl.formatMessage(logMessages.topKeywords)}:</div>
                  <div className="flex-grow">
                    {R.map(
                      (item) => (
                        <div key={item.value}>{`${item.text} (${item.value})`}</div>
                      ),
                      R.slice(0, 3, words),
                    )}
                  </div>
                </>
              )}

              {!this.isEdge && (
                <div className="flex-grow">
                  <WordCloud words={words} size={[400, 200]} />
                </div>
              )}
            </div>
          </div>
        }
        mouseEnterDelay={0.3}
        placement="right"
      >
        <div className="hidden-line-with-ellipsis inline-block max-width">{patternNameStr}</div>
      </Popover>
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;

    const handleMenuClick = ({ key, domEvent }) => {
      domEvent.stopPropagation();

      const { startTime, endTime, instanceName } = this.props;
      const { patternId } = rowData;

      switch (key) {
        case 'jumpKeywordSearch':
          this.props.handleLogQueryClick(rowData, {
            template: 'b81364228d574502b7f6c3e454b9a21b',
            instanceName,
            startTimeObj: moment.utc(startTime, Defaults.DateFormat).subtract(7, 'days').startOf('day'),
            endTimeObj: moment.utc(endTime, Defaults.DateFormat).endOf('day'),
          });
          break;
        case 'jumpPatternTrend':
          this.props.handleLogQueryClick(rowData, {
            template: '953de6a33d8a4b96ac9c100bf69ba3fc',
            instanceName,
            startTimeObj: moment.utc(startTime, Defaults.DateFormat).subtract(7, 'days').startOf('day'),
            endTimeObj: moment.utc(endTime, Defaults.DateFormat).endOf('day'),
            pattern: String(patternId),
          });
          break;
        case 'setPatternName':
          this.props.handleActionClick(rowData, 'setPatternName');
          break;
        case 'emailAlerts':
          this.props.handleEmailAlertsClick(rowData);
          break;
        default:
          break;
      }
    };
    return (
      <Dropdown icon={<SettingOutlined />} itemClick={handleMenuClick} onClick={(event) => event.stopPropagation()}>
        <>
          <Menu.Item key="jumpKeywordSearch">{intl.formatMessage(eventMessages.jumpKeywordSearch)}</Menu.Item>
          <Menu.Item key="jumpPatternTrend">{intl.formatMessage(eventMessages.jumpPatternTrend)}</Menu.Item>
          <Menu.Item key="setPatternName">{intl.formatMessage(eventMessages.setPatternName)}</Menu.Item>
          <Menu.Item key="emailAlerts">{intl.formatMessage(eventMessages.emailAlerts)}</Menu.Item>
        </>
      </Dropdown>
    );
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          this.localEventList = this.sortData(this.localEventList, name, sortDir);

          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handleActionPatternSearch() {
    this.localEventList = this.cloneLocalEventList;
    const { patternSearch, allPatternsEvent } = this.state;
    const { localEventList, cloneLocalEventList } = this;
    const filterLocalEventList = patternSearch
      ? R.filter((item) => {
          return (
            R.includes(patternSearch, R.toString(item.patternId)) ||
            R.includes(patternSearch.toLowerCase(), item.patternName ? item.patternName.toLowerCase() : '')
          );
        }, localEventList)
      : cloneLocalEventList;
    this.localEventList = [allPatternsEvent, ...filterLocalEventList];
    if (this.listNode) this.listNode.scrollToRow(0);
    this.forceUpdate();
  }

  render() {
    const { intl } = this.props;
    const { sortBy, sortDirection } = this.state;
    const { patternSearch } = this.state;

    return (
      <div className="flex-col full-width full-height">
        <div className="flex-row corner-8-8-0-0" style={{ padding: 10, background: '#464F69' }}>
          <Input.Search
            size="small"
            className="flex-grow"
            placeholder="Search by pattern ID or pattern name"
            allowClear
            value={patternSearch}
            onChange={(e) => {
              this.setState({ patternSearch: e.target.value }, () => {
                if (!this.state.patternSearch) {
                  this.localEventList = this.cloneLocalEventList;
                  this.listNode.scrollToRow(0);
                  this.forceUpdate();
                }
              });
            }}
            onSearch={(value) => this.handleActionPatternSearch(value)}
          />
        </div>
        <div className="flex-grow flex-col overflow-y-auto overflow-x-hidden">
          <AutoSizer>
            {({ width, height }) => (
              <div className="event-list">
                <div
                  className="event-list-header"
                  style={{
                    height: this.listHeaderHeight,
                    width,
                    borderRadius: 0,
                    paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                  }}
                >
                  <div className="header-column" style={{ width: 60 }} />
                  <div className="header-column" style={{ width: 60, flex: 1 }} onClick={this.headerClick('patternId')}>
                    <span>{intl.formatMessage(appFieldsMessages.pattern)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'patternId')}
                  </div>
                  <div className="header-column" style={{ width: 80 }} onClick={this.headerClick('count')}>
                    <span>{intl.formatMessage(appFieldsMessages.count)}</span>
                    {this.sortIcon(sortBy, sortDirection, 'count')}
                  </div>
                  <div className="header-column" style={{ width: 40 }} />
                </div>
                <List
                  className="event-list-grid"
                  ref={(listNode) => {
                    this.listNode = listNode;
                  }}
                  width={width}
                  height={height - this.listHeaderHeight - 22}
                  rowCount={this.localEventList.length}
                  overscanRowCount={4}
                  deferredMeasurementCache={this.cellMeasureCache}
                  rowHeight={this.cellMeasureCache.rowHeight}
                  rowRenderer={this.renderListItem(this.localEventList)}
                  onScrollbarPresenceChange={({ horizontal, vertical }) => {
                    if (vertical) {
                      this.listNodeHeaderScrollbar = true;
                    } else {
                      this.listNodeHeaderScrollbar = false;
                    }
                    if (this.listNode) this.listNode.forceUpdateGrid();
                    this.forceUpdate();
                  }}
                />
              </div>
            )}
          </AutoSizer>
        </div>
      </div>
    );
  }
}

const LogAnalysisPatternList = injectIntl(LogAnalysisPatternListCore);
export default connect((state: State) => ({}), {})(LogAnalysisPatternList);
