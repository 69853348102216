/*  @flow */
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const changePassword = (params: Object) => {
  const { userName, password, newPassword } = params;
  return fetchPost(getEndpoint('resetnewpassword'), {
    userName,
    password,
    newPassword,
  }).then((resp) => {
    return resp;
  });
};

export default changePassword;
