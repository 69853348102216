import React, { useState, useEffect } from 'react';

import { Button, Dropdown as DropdownCore, Menu, Space } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

// eslint-disable-next-line react/prop-types
const Dropdown = ({
  name,
  itemClick,
  children,
  icon,
  disabled,
  itemStyle,
  onOpenChange,
  arrow = true,
  butStyle,
  ...rest
}: Object) => {
  const [visibleFlag, setVisibleFlag] = useState(false);
  useEffect(() => {
    if (onOpenChange) {
      onOpenChange(visibleFlag);
    }
  }, [visibleFlag]);
  return (
    <DropdownCore
      {...rest}
      overlay={
        <Menu
          onClick={(key) => {
            setVisibleFlag(!visibleFlag);
            // eslint-disable-next-line no-unused-expressions
            itemClick && itemClick(key);
          }}
        >
          {children}
        </Menu>
      }
      disabled={disabled}
      trigger={['click']}
      onOpenChange={(open) => setVisibleFlag(open)}
    >
      <Space style={{ cursor: 'pointer', ...itemStyle, ...butStyle }}>
        <Button size="small" style={{ padding: '0 8px 0 0', ...butStyle }}>
          <span style={{ fontWeight: 'bold', paddingLeft: name ? 8 : '' }}>{name}</span>
          {arrow &&
            (icon ||
              (visibleFlag ? (
                <UpOutlined style={{ fontWeight: 'bold' }} />
              ) : (
                <DownOutlined style={{ fontWeight: 'bold' }} />
              )))}
        </Button>
      </Space>
    </DropdownCore>
  );
};

export default Dropdown;
