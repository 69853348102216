import React, { Fragment } from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import yaml from 'js-yaml';
import { autobind } from 'core-decorators';
import { AutoComplete, Button, Card, Checkbox, Form, Image, Input, message, Select, Spin } from 'antd';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
import { parseJSON } from '../../../../common/utils';
import { CodeMirror, Modal, Popover } from '../../../../lib/fui/react';
import { PreviewIcon } from '../../../../lib/fui/icons';

import { projectWizardMessages, settingsMessages } from '../../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { eventMessages } from '../../../../common/metric/messages';

import kubernetesDetailsWhite from '../../../../../images/kubernetes_details_white.png';

type Props = {
  intl: Object,
  currentTheme: String,
  isAdmin: Boolean,
  isLocalAdmin: Boolean,
  userList: Array<Object>,
  systemsMap: Object,
  credentials: Object,
  userInfo: Object,
  createProject: Function,
};

const dataTypeCommon = { endpoint: undefined, authentication: false, username: undefined, password: undefined };
const defaultMode = 'install';
const modeMap = {
  existing: 1,
  install: 2,
};
const dataTypeHideMap = {
  prometheus: 'embeddedprometheus',
  loki: 'embeddedloki',
};

class KubernetesSettingNew extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { systemsMap, credentials, intl } = props;

    this.state = {
      loading: false,
      systemOwner: '',
      systemName: '',
      namespaces: [],
      dataTypes: [],
      dataTypeSettings: [
        {
          type: 'Metric',
          name: 'prometheus',
          title: intl.formatMessage(settingsMessages.prometheus),
          hiddle: true,
          endpointUrl: 'http://prometheus-server.monitor.svc.cluster.local',
          ...dataTypeCommon,
        },
        {
          type: 'Log',
          name: 'loki',
          title: intl.formatMessage(settingsMessages.loki),
          hiddle: true,
          endpointUrl: 'http://loki-gateway.monitor.svc.cluster.local',
          ...dataTypeCommon,
        },
      ],
      installationMode: [
        {
          type: 'Metric',
          value: '',
          option: [
            {
              value: 'existing',
              label: intl.formatMessage(settingsMessages.installationModeExisting, { type: 'prometheus' }),
            },
            {
              value: 'install',
              label: intl.formatMessage(settingsMessages.installationModeInstall, { type: 'prometheus' }),
            },
          ],
        },
        {
          type: 'Log',
          value: '',
          option: [
            {
              value: 'existing',
              label: intl.formatMessage(settingsMessages.installationModeExisting, { type: 'loki' }),
            },
            { value: 'install', label: intl.formatMessage(settingsMessages.installationModeInstall, { type: 'loki' }) },
          ],
        },
      ],

      showPreviewCodeModal: false,
      previewCode: null,

      saveFlag: false,
      btnLoading: false,
    };

    let systemOptions = R.values(systemsMap);
    systemOptions = R.filter((item) => item.owner === credentials.userName, systemOptions);
    systemOptions = R.uniqWith(R.eqBy(R.prop('systemId')), systemOptions);
    systemOptions = R.sortWith(
      [R.ascend(R.compose(R.toLower, R.prop('value')))],
      R.map(
        (item) => ({
          value: item.systemName,
          sid: item.systemId,
          owner: item.owner,
          environmentName: item.environmentName,
        }),
        systemOptions,
      ),
    );
    this.systemOptions = systemOptions;

    this.dataTypesOptions = ['Metric', 'Log'];
  }

  @autobind
  handleSystemOwnerChange(owner) {
    const { credentials } = this.props;
    this.systemOptions = [];
    this.setState({ loading: true, saveFlag: false }, async () => {
      const result = await fetchGet(getEndpoint('systemframework', 2), {
        ...credentials,
        customerName: owner,
      });
      const systems = R.map((s) => parseJSON(s) || {}, result?.ownSystemArr || []);
      let systemOptions = R.map(
        (item) => ({
          value: item.systemDisplayName,
          sid: item?.systemKey?.systemName,
          owner: item?.systemKey?.userName || owner,
          environmentName: item?.systemKey?.environmentName || 'All',
        }),
        systems,
      );
      systemOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], systemOptions);
      this.systemOptions = systemOptions;
      this.setState({ systemOwner: owner, systemName: undefined, loading: false });
    });
  }

  @autobind
  handleChangeDataTypes(dataTypes, oldDataTypes) {
    const { dataTypeSettings, installationMode } = this.state;
    const newValue = R.difference(dataTypes, oldDataTypes);

    const newDataTypeSettings = R.map((item) => {
      if (R.includes(item.type, newValue)) {
        return { ...item, hiddle: false, ...dataTypeCommon };
      } else if (!R.includes(item.type, dataTypes)) {
        return { ...item, hiddle: true, ...dataTypeCommon };
      } else {
        return item;
      }
    }, dataTypeSettings);

    const newInstallationMode = R.map((item) => {
      if (R.includes(item.type, newValue)) {
        return { ...item, value: defaultMode };
      } else if (!R.includes(item.type, dataTypes)) {
        return { ...item, value: '' };
      } else {
        return item;
      }
    }, installationMode || []);

    this.setState({
      dataTypes,
      dataTypeSettings: newDataTypeSettings,
      installationMode: newInstallationMode,
      saveFlag: false,
    });
  }

  @autobind
  handleChangeInstallationMode(value, type) {
    const { dataTypeSettings, installationMode } = this.state;

    const newDataTypeSettings = R.map((item) => {
      if (item.type === type && value === 'install') {
        return { ...item, ...dataTypeCommon };
      } else {
        return item;
      }
    }, dataTypeSettings);

    const newInstallationMode = R.map((item) => {
      if (type === item.type) {
        return { ...item, value };
      } else {
        return item;
      }
    }, installationMode || []);
    this.setState({ dataTypeSettings: newDataTypeSettings, installationMode: newInstallationMode, saveFlag: false });
  }

  @autobind
  handleCopyClick(content) {
    if (!navigator.clipboard) {
      try {
        const input = document.createElement('input');
        input.setAttribute('value', content);
        document.body.appendChild(input);
        input.select();
        document.execCommand('Copy');
        document.body.removeChild(input);
        message.success('Copy success!');
      } catch (error) {
        message.error('Copy error!');
      }
    } else {
      navigator.clipboard.writeText(content).then(
        () => message.success('Copy success!'),
        () => message.error('Copy error!'),
      );
    }
  }

  @autobind
  handleYamlFormat() {
    const { userInfo, isAdmin, isLocalAdmin, userList } = this.props;
    const { systemOwner, systemName, namespaces, dataTypes, dataTypeSettings } = this.state;

    const typeSettingMap = {};
    R.forEach((item) => {
      const { hiddle, name, endpoint, authentication, username, password } = item || {};
      if (!hiddle) {
        typeSettingMap[name] = { endpoint: endpoint || '' };
        if (authentication) {
          typeSettingMap[name] = { ...typeSettingMap[name], username, password };
        }
        if (!endpoint) {
          typeSettingMap[dataTypeHideMap[name]] = { enabled: true };
        }
      }
    }, dataTypeSettings || []);

    let url = window.BASE_URL || window.location.origin || '';
    if (R.includes('localhost', url)) {
      url = 'https://stg.insightfinder.com';
    }
    const findSystem = R.find((s) => s.value === systemName, this.systemOptions || []);
    const insightfinder = {
      url,
      user_name: userInfo?.userName,
      license_key: userInfo?.licenseKey,
      system_name: findSystem?.value,
      system_id: findSystem?.sid,
    };
    if ((isAdmin || isLocalAdmin) && systemOwner) {
      const findUser = R.find((item) => item.userName === systemOwner, userList || []);
      if (findUser) {
        insightfinder.user_name = findUser.userName;
        insightfinder.license_key = findUser.licenseKey;
      }
    }

    const projects = [];
    if (R.includes('Metric', dataTypes || [])) {
      projects.push({ name: 'node-metrics', type: 'metric', target: 'node' });
    }
    R.forEach((spaces) => {
      projects.push({ name: `${spaces}-namespace-event`, type: 'event', target: 'namespace', namespace: spaces });
    }, namespaces || []);
    R.forEach((item) => {
      R.forEach((spaces) => {
        if (item === 'Metric') {
          projects.push({ name: `${spaces}-pod-metric`, type: 'metric', target: 'pod', namespace: spaces });
          projects.push({ name: `${spaces}-pvc-metric`, type: 'metric', target: 'pvc', namespace: spaces });
        } else if (item === 'Log') {
          projects.push({ name: `${spaces}-pod-log`, type: 'log', target: 'pod', namespace: spaces });
        }
      }, namespaces || []);
    }, dataTypes || []);

    return { typeSettingMap, insightfinder, projects };
  }

  @autobind
  previewYamlContent() {
    const { typeSettingMap, insightfinder, projects } = this.handleYamlFormat();
    try {
      const yamlString = yaml.dump({ ...typeSettingMap, insightfinder, projects });
      this.setState({ showPreviewCodeModal: true, previewCode: yamlString });
    } catch (error) {
      message.error('Error converting to YAML:', error);
    }
  }

  @autobind
  downLoadYaml() {
    const { typeSettingMap, insightfinder, projects } = this.handleYamlFormat();
    try {
      const yamlString = yaml.dump({ ...typeSettingMap, insightfinder, projects });
      const yamlBlob = new Blob([yamlString], { type: 'text/yaml' });
      const url = window.URL.createObjectURL(yamlBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'values.yaml';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      message.success('downLoad success!');
    } catch (error) {
      message.error('Error converting to YAML:', error);
    }
  }

  @autobind
  handleSave(e) {
    e.preventDefault();
    e.stopPropagation();

    const { credentials, userInfo } = this.props;
    const { systemOwner, systemName, namespaces, dataTypes, installationMode, dataTypeSettings } = this.state;

    const findSystem = R.find((s) => s.value === systemName, this.systemOptions || []);
    const customerName = findSystem?.owner || systemOwner || credentials.userName || userInfo.userName;
    const envName = findSystem?.environmentName || 'All';
    const systemId = findSystem?.sid;
    const systemShowName = findSystem?.value || systemName;

    const namespacesStr = R.join(',', namespaces);

    const enableMetric = R.includes('Metric', dataTypes);
    let metricInstallMode;
    const enableLog = R.includes('Log', dataTypes);
    let logInstallMode;
    R.forEach((item) => {
      if (item.type === 'Metric') {
        metricInstallMode = modeMap[item.value];
      } else if (item.type === 'Log') {
        logInstallMode = modeMap[item.value];
      }
    }, installationMode || []);

    let prometheusEndpoint = '';
    let enablePrometheusAuth = false;
    let prometheusAuthUserName = '';
    let prometheusAuthPassword = '';

    let lokiEndpoint = '';
    let enableLokiAuth = false;
    let lokiAuthUserName = '';
    let lokiAuthPassword = '';
    R.forEach((item) => {
      if (item.type === 'Metric') {
        prometheusEndpoint = item.endpoint;
        enablePrometheusAuth = item.authentication;
        prometheusAuthUserName = item.username;
        prometheusAuthPassword = item.password;
      } else if (item.type === 'Log') {
        lokiEndpoint = item.endpoint;
        enableLokiAuth = item.authentication;
        lokiAuthUserName = item.username;
        lokiAuthPassword = item.password;
      }
    }, dataTypeSettings || []);

    this.setState({ saveFlag: false, btnLoading: true });
    fetchPost(getEndpoint('k8s-integration-setting'), {
      ...credentials,
      customerName,
      settings: JSON.stringify({
        systemName: systemId,
        envName,
        userName: customerName,
        systemShowName,

        namespaces: namespacesStr,

        enableMetric,
        metricInstallMode,
        enableLog,
        logInstallMode,

        prometheusEndpoint,
        enablePrometheusAuth,
        prometheusAuthUserName,
        prometheusAuthPassword,

        lokiEndpoint,
        enableLokiAuth,
        lokiAuthUserName,
        lokiAuthPassword,
      }),
    })
      .then((data) => {
        const { success, message: msg, systemName } = data || {};
        if (success || success === undefined) {
          if (!systemId) {
            const newSystemOptions = [
              ...this.systemOptions,
              { value: systemShowName, sid: systemName, owner: customerName, environmentName: envName },
            ];
            this.systemOptions = R.sortWith([R.ascend(R.compose(R.toLower, R.prop('value')))], newSystemOptions);
          }
          message.success('Save successful');
          this.setState({ saveFlag: true, btnLoading: false });
        } else {
          this.setState({ btnLoading: false });
          message.error(msg);
        }
      })
      .catch((err) => {
        this.setState({ btnLoading: false });
        message.error(err.message || String(err));
      });
  }

  @autobind
  handleFinished(e) {
    e.preventDefault();
    e.stopPropagation();
    const { systemOwner, systemName } = this.state;

    const findSystem = R.find((s) => s.value === systemName, this.systemOptions || []);
    const systemId = findSystem?.sid;

    const { createProject } = this.props;
    createProject('Kubernetes', { systemOwner, systemId }, this.state);
  }

  render() {
    const { intl, isAdmin, isLocalAdmin, userList, currentTheme } = this.props;
    const { loading, systemOwner, systemName, namespaces, dataTypes, dataTypeSettings, installationMode } = this.state;
    const { showPreviewCodeModal, previewCode, saveFlag, btnLoading } = this.state;
    const isDark = currentTheme === 'dark';

    const needOwer = isAdmin || isLocalAdmin ? !systemOwner : false;
    const namespacesError = namespaces.length === 0;
    const dataTypesError = dataTypes.length === 0;
    const dataTypeSettingError = !!R.find((item) => {
      const modeRequired = R.find((mode) => mode.type === item.type, installationMode || [])?.value === 'existing';
      return (
        !item.hiddle &&
        ((modeRequired && !item.endpoint) ||
          (item.authentication && modeRequired && (!item.username || !item.password)))
      );
    }, dataTypeSettings);

    const hasError = needOwer || !systemName || namespacesError || dataTypesError || dataTypeSettingError;
    const showDataTypeCard = R.find((item) => item.value === 'existing', installationMode || []);
    return (
      <div className="flex-col" style={{ fontSize: 14, rowGap: 16, marginRight: 16 }}>
        <Spin spinning={loading}>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: `<span>${intl.formatMessage(projectWizardMessages.KubernetesIntro)}</span>
            <span> For details, please visit
              <a
                href="https://github.com/insightfinder/InsightAgent/tree/master/kubernetes-agent"
                target="_blank"
                rel="noreferrer"
              >
                https://github.com/insightfinder/InsightAgent/tree/master/kubernetes-agent
              </a>
            </span>`,
            }}
          />

          <div>
            <div className="flex-row flex-center-align font-14">
              <div className="bold" style={{ marginRight: 8 }}>
                {intl.formatMessage(settingsMessages.exploreMoreHere)}:
              </div>
              <a href="https://insightfinder.com/kubernetes-integration" target="_blank" rel="noreferrer">
                https://insightfinder.com/kubernetes-integration
              </a>
            </div>
            <div className="font-14" style={{ marginBottom: 16 }}>
              <div className="bold" style={{ marginRight: 8 }}>
                {intl.formatMessage(settingsMessages.prerequsites)}:
              </div>
              <div style={{ paddingLeft: 8 }}>
                <div>- Kubernetes 1.12+</div>
                <div>- Valid storageClass</div>
                {false && (
                  <div>
                    <a
                      href="https://github.com/insightfinder/charts/blob/main/charts/kubernetes-agent/prometheus.md"
                      target="_blank"
                      rel="noreferrer"
                    >
                      - Prometheus
                    </a>
                  </div>
                )}
                {false && (
                  <div>
                    <a
                      href="https://github.com/insightfinder/charts/blob/main/charts/kubernetes-agent/loki.md"
                      target="_blank"
                      rel="noreferrer"
                    >
                      - Grafana Loki
                    </a>
                  </div>
                )}
                {false && <div>- Jaeger</div>}
              </div>
            </div>
          </div>

          <Card style={{ marginTop: 16 }}>
            <div className="flex-row flex-center-align flex-space-between">
              <Form layout="vertical">
                {(isAdmin || isLocalAdmin) && (
                  <Form.Item
                    label={intl.formatMessage(appFieldsMessages.owner)}
                    validateStatus={!systemOwner ? 'error' : 'success'}
                    help={!systemOwner ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                    required
                  >
                    <Select
                      showSearch
                      size="small"
                      value={systemOwner}
                      style={{ width: 800 }}
                      optionFilterProp="children"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={this.handleSystemOwnerChange}
                      dropdownMatchSelectWidth={false}
                      dropdownStyle={{ maxWidth: 650 }}
                      getPopupContainer={(triggerNode) => triggerNode?.parentElement || document.body}
                    >
                      {R.map(
                        (item) => (
                          <Select.Option key={item.userName} value={item.userName}>
                            {item.userName}
                          </Select.Option>
                        ),
                        userList || [],
                      )}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  label={intl.formatMessage(appFieldsMessages.systemName)}
                  validateStatus={!systemName ? 'error' : 'success'}
                  help={!systemName ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <AutoComplete
                    size="small"
                    value={systemName}
                    style={{ width: 800 }}
                    options={this.systemOptions}
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf((systemName || '').toUpperCase()) !== -1
                    }
                    onChange={(systemName) => this.setState({ systemName, saveFlag: false })}
                    getPopupContainer={(triggerNode) => triggerNode?.parentElement || document.body}
                  />
                </Form.Item>
                <Form.Item
                  label={intl.formatMessage(settingsMessages.namespace)}
                  validateStatus={!namespaces.length > 0 ? 'error' : 'success'}
                  help={!namespaces.length > 0 ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                  required
                >
                  <Select
                    size="small"
                    mode="tags"
                    allowClear
                    value={namespaces}
                    style={{ width: 800 }}
                    onChange={(namespaces) => this.setState({ namespaces, saveFlag: false })}
                    getPopupContainer={(triggerNode) => triggerNode?.parentElement || document.body}
                  />
                </Form.Item>
                <div className="flex-row">
                  <Form.Item
                    label={intl.formatMessage(settingsMessages.dataType)}
                    validateStatus={!dataTypes.length > 0 ? 'error' : 'success'}
                    help={!dataTypes.length > 0 ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
                    required
                    style={{ width: 130 }}
                  >
                    <Checkbox.Group
                      value={dataTypes}
                      onChange={(value) => this.handleChangeDataTypes(value, dataTypes)}
                      className="flex-col"
                    >
                      {R.addIndex(R.map)(
                        (item, idx) => (
                          <div key={item} style={{ ...(idx > 0 ? { marginTop: idx * 10 } : {}) }}>
                            <Checkbox value={item}>{item}</Checkbox>
                          </div>
                        ),
                        this.dataTypesOptions || [],
                      )}
                    </Checkbox.Group>
                  </Form.Item>
                  <Form.Item label={intl.formatMessage(settingsMessages.installationMode)}>
                    <div className="flex-col">
                      {R.addIndex(R.map)((item, idx) => {
                        const { type, value, option } = item || {};
                        const disabled = !R.includes(type, dataTypes);
                        return (
                          <Select
                            size="small"
                            key={type}
                            value={value}
                            style={{ width: 670, ...(idx > 0 ? { marginTop: 8 } : {}) }}
                            options={option}
                            disabled={disabled}
                            onChange={(value) => this.handleChangeInstallationMode(value, type)}
                            getPopupContainer={(triggerNode) => triggerNode?.parentElement || document.body}
                          />
                        );
                      }, installationMode || [])}
                    </div>
                  </Form.Item>
                </div>
              </Form>
              <div style={{ width: 340, height: 240, margin: '8px 0' }}>
                <Image width="100%" height="100%" src={kubernetesDetailsWhite} />
              </div>
            </div>
          </Card>

          {!dataTypesError && showDataTypeCard && (
            <Card style={{ marginTop: 16 }}>
              <Form layout="horizontal">
                {R.map((item) => {
                  const { type, title, endpoint, endpointUrl, authentication, username, password, hiddle } = item || {};
                  const modeRequired =
                    R.find((mode) => mode.type === type, installationMode || [])?.value === 'existing';
                  if (hiddle || !modeRequired) {
                    return <div key={type} />;
                  }
                  return (
                    <Fragment key={type}>
                      <div className="font-14 bold" style={{ marginBottom: 8 }}>
                        {title}:
                      </div>
                      <div style={{ paddingLeft: 16 }}>
                        <Form.Item
                          label={<div style={{ width: 90 }}>{intl.formatMessage(settingsMessages.endpoint)}</div>}
                          validateStatus={modeRequired && !endpoint ? 'error' : 'success'}
                          help={
                            modeRequired && !endpoint ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined
                          }
                          required={modeRequired}
                        >
                          <Input
                            size="small"
                            allowClear
                            value={endpoint}
                            style={{ width: 680 }}
                            placeholder={endpointUrl}
                            onChange={(e) => {
                              const findIdx = R.findIndex((_item) => _item.type === type, dataTypeSettings);
                              this.setState({
                                dataTypeSettings: update(dataTypeSettings, {
                                  [findIdx]: { $set: { ...item, endpoint: e.target.value } },
                                }),
                                saveFlag: false,
                              });
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          colon={false}
                          label={<div style={{ width: 90 }}>{intl.formatMessage(settingsMessages.authentication)}</div>}
                        >
                          <Checkbox
                            size="small"
                            checked={authentication}
                            onChange={(e) => {
                              const findIdx = R.findIndex((_item) => _item.type === type, dataTypeSettings);
                              const { checked } = e.target;
                              let newState = { authentication: e.target.checked };
                              if (!checked) {
                                newState = { ...newState, username: undefined, password: undefined };
                              }
                              this.setState({
                                dataTypeSettings: update(dataTypeSettings, {
                                  [findIdx]: { $set: { ...item, ...newState } },
                                }),
                                saveFlag: false,
                              });
                            }}
                          />
                        </Form.Item>
                        {authentication && (
                          <>
                            <Form.Item
                              label={<div style={{ width: 90 }}>{intl.formatMessage(eventMessages.userName)}</div>}
                              validateStatus={modeRequired && !username ? 'error' : 'success'}
                              help={
                                modeRequired && !username
                                  ? intl.formatMessage(appFieldsMessages.inputRequired)
                                  : undefined
                              }
                              required={modeRequired}
                            >
                              <Input
                                size="small"
                                allowClear
                                value={username}
                                style={{ width: 680 }}
                                onChange={(e) => {
                                  const findIdx = R.findIndex((_item) => _item.type === type, dataTypeSettings);
                                  this.setState({
                                    dataTypeSettings: update(dataTypeSettings, {
                                      [findIdx]: { $set: { ...item, username: e.target.value } },
                                    }),
                                    saveFlag: false,
                                  });
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label={<div style={{ width: 90 }}>{intl.formatMessage(appFieldsMessages.password)}</div>}
                              validateStatus={modeRequired && !password ? 'error' : 'success'}
                              help={
                                modeRequired && !password
                                  ? intl.formatMessage(appFieldsMessages.inputRequired)
                                  : undefined
                              }
                              required={modeRequired}
                            >
                              <Input.Password
                                size="small"
                                allowClear
                                value={password}
                                style={{ width: 680 }}
                                onChange={(e) => {
                                  const findIdx = R.findIndex((_item) => _item.type === type, dataTypeSettings);
                                  this.setState({
                                    dataTypeSettings: update(dataTypeSettings, {
                                      [findIdx]: { $set: { ...item, password: e.target.value } },
                                    }),
                                    saveFlag: false,
                                  });
                                }}
                              />
                            </Form.Item>
                          </>
                        )}
                      </div>
                    </Fragment>
                  );
                }, dataTypeSettings || [])}
              </Form>
            </Card>
          )}

          <div style={{ margin: '16px 20px 0 0', textAlign: 'right' }}>
            <Button
              type="primary"
              style={{ width: 120 }}
              disabled={hasError}
              loading={btnLoading}
              onClick={this.handleSave}
            >
              {intl.formatMessage(appButtonsMessages.save)}
            </Button>
          </div>

          {saveFlag && (
            <Card style={{ marginTop: 16 }}>
              <div className="font-14 bold" style={{ marginBottom: 4 }}>
                {intl.formatMessage(settingsMessages.kubernetesStepInstructions)}
              </div>
              <div className="font-14 bold" style={{ marginBottom: 4 }}>
                {intl.formatMessage(settingsMessages.kubernetesInstallInstructions)}
              </div>
              <div className="font-14" style={{ marginTop: 8 }}>
                <div className="bold">1. {intl.formatMessage(settingsMessages.addTheHelmChartRepo)}:</div>
                <div
                  className="flex-row flex-center-align"
                  style={{ paddingLeft: 16, width: 800, position: 'relative' }}
                >
                  <CodeMirror
                    className="codeMirror_auto"
                    options={{
                      readOnly: 'nocursor',
                      lineNumbers: false,
                      theme: isDark ? 'material' : 'xq-light',
                    }}
                    value="helm repo add insightfinder https://insightfinder.github.io/charts"
                  />
                  <Popover content={intl.formatMessage(settingsMessages.copy)} mouseEnterDelay={0.3} placement="top">
                    <Button
                      size="small"
                      type="primary"
                      icon={<CopyOutlined />}
                      style={{ marginLeft: 8, position: 'absolute', right: 8 }}
                      disabled={hasError}
                      onClick={() =>
                        this.handleCopyClick('helm repo add insightfinder https://insightfinder.github.io/charts')
                      }
                    />
                  </Popover>
                </div>
              </div>
              <div className="font-14 flex-row flex-center-align" style={{ marginTop: 8 }}>
                <div className="bold">2. {intl.formatMessage(settingsMessages.copyOrDownloadTheValueYaml)}</div>
                <div className="flex-row flex-center-align">
                  <Popover
                    content={intl.formatMessage(appButtonsMessages.preview)}
                    mouseEnterDelay={0.3}
                    placement="top"
                  >
                    <Button
                      size="small"
                      type="primary"
                      icon={<PreviewIcon style={{ fontSize: 14 }} />}
                      onClick={this.previewYamlContent}
                      style={{ marginLeft: 8 }}
                      disabled={hasError}
                    />
                  </Popover>
                </div>
              </div>
              <div className="font-14" style={{ marginTop: 8 }}>
                <div className="bold">3. {intl.formatMessage(settingsMessages.installTheHelmChart)}</div>
                <div
                  className="flex-row flex-center-align"
                  style={{ paddingLeft: 16, width: 800, position: 'relative' }}
                >
                  <CodeMirror
                    className="codeMirror_auto"
                    options={{
                      readOnly: 'nocursor',
                      lineNumbers: false,
                      theme: isDark ? 'material' : 'xq-light',
                    }}
                    value="helm install -f ./values.yaml if-kubernetes-agent insightfinder/if-kubernetes-agent"
                  />
                  <Popover content={intl.formatMessage(settingsMessages.copy)} mouseEnterDelay={0.3} placement="top">
                    <Button
                      size="small"
                      type="primary"
                      icon={<CopyOutlined />}
                      style={{ marginLeft: 8, position: 'absolute', right: 8 }}
                      disabled={hasError}
                      onClick={() =>
                        this.handleCopyClick(
                          'helm install -f ./values.yaml if-kubernetes-agent insightfinder/if-kubernetes-agent',
                        )
                      }
                    />
                  </Popover>
                </div>
              </div>
            </Card>
          )}

          {saveFlag && (
            <div style={{ margin: '16px 20px 0 0', textAlign: 'right' }}>
              <Button type="primary" style={{ width: 120 }} onClick={this.handleFinished}>
                {intl.formatMessage(appButtonsMessages.finished)}
              </Button>
            </div>
          )}
        </Spin>

        {showPreviewCodeModal && (
          <PreviewCode
            intl={intl}
            isDark={isDark}
            previewCode={previewCode}
            handleCopyClick={this.handleCopyClick}
            downLoadYaml={this.downLoadYaml}
            onClose={() => this.setState({ showPreviewCodeModal: false, previewCode: null })}
          />
        )}
      </div>
    );
  }
}

const PreviewCode = (props: Object) => {
  const { onClose, previewCode, intl, isDark, handleCopyClick, downLoadYaml } = props;

  return (
    <Modal
      visible
      title={intl.formatMessage(appButtonsMessages.preview)}
      onCancel={() => onClose()}
      width={950}
      bodyStyle={{ height: 600, overflowY: 'auto' }}
      footer={[
        <Button size="small" key="cancel" onClick={onClose}>
          {intl.formatMessage(appButtonsMessages.cancel)}
        </Button>,
      ]}
    >
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <CodeMirror
          className="codeMirror_auto"
          options={{
            readOnly: 'nocursor',
            lineNumbers: false,
            theme: isDark ? 'material' : 'xq-light',
          }}
          value={previewCode}
        />
        <Popover content={intl.formatMessage(settingsMessages.copy)} mouseEnterDelay={0.3} placement="top">
          <Button
            size="small"
            type="primary"
            icon={<CopyOutlined />}
            style={{ marginLeft: 8, position: 'absolute', top: 8, right: 40 }}
            onClick={() => handleCopyClick(previewCode)}
          />
        </Popover>
        <Popover content={intl.formatMessage(appButtonsMessages.download)} mouseEnterDelay={0.3} placement="top">
          <Button
            size="small"
            type="primary"
            icon={<DownloadOutlined />}
            onClick={downLoadYaml}
            style={{ marginLeft: 8, position: 'absolute', top: 8, right: 8 }}
          />
        </Popover>
      </div>
    </Modal>
  );
};

export default KubernetesSettingNew;
