/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

const getNoop = () => {
  return Promise.resolve({});
};

export default getNoop;
