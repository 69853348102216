/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

/* eslint-disable no-console */
import * as R from 'ramda';
import { createLogic } from 'redux-logic';
import { get } from 'lodash';

import { ActionTypes } from '../actions';
import { createSetAction, updateLastActionInfo } from '../../app/actions';
import { getLoadStatusActions } from '../../utils';
import { getGlobalSystemLevelTimelinesV2 } from '../../apis';
import { appMessages } from '../../app/messages';

const loadGlobalSystemDetectIncidentTimelinesLogic = createLogic({
  type: [ActionTypes.LOAD_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES],
  cancelType: ActionTypes.APP_STOP,
  debounce: 300,
  latest: true,
  process: ({ getState, action }, dispatch, done) => {
    const state = getState();
    const { params, loader, callback } = action.payload;
    const { credentials } = state.auth;
    const { globalInfo } = state.app;
    const { showLoading, hideLoading, errorLoading } = getLoadStatusActions(loader);
    const { isAutoReload, environmentId } = params;

    dispatch(showLoading);
    if (!isAutoReload) dispatch(updateLastActionInfo());

    // Get the system ids for the environment
    const environment = R.find((item) => item.id === environmentId, globalInfo);
    const systemInfoMap = {};
    R.forEach((system) => {
      const projectDisplayMap = {};
      const projectNameSet = system.projectNameSet || [];
      R.forEach((item) => {
        projectDisplayMap[item.projectName] = item.projectDisplayName;
      }, projectNameSet);
      systemInfoMap[system.id] = { ...(system.systemInfo || {}), projectNameSet, projectDisplayMap };
    }, get(environment, 'systemList', []));

    getGlobalSystemLevelTimelinesV2(credentials, { ...params, systemInfoMap, anomalyType: 'detectedIncident' })
      .then((data) => {
        const { timelines } = data;
        dispatch(createSetAction(ActionTypes.SET_GLOBAL_SYSTEM_DETECT_INCIDENT_TIMELINES, params, { timelines }));
        dispatch(hideLoading);
      })
      .catch((err) => {
        console.error('[IF_API] Failed get global system incident timelines', err);
        dispatch(errorLoading(appMessages.errorsAPIMessage, { message: err.message }));
      })
      .then(() => {
        // callback function
        if (R.type(callback) === 'Function') {
          callback();
        }
        done();
      });
  },
});

export default loadGlobalSystemDetectIncidentTimelinesLogic;
