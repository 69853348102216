/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React from 'react';
// import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { autobind } from 'core-decorators';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { message, Alert, Form, Input, Button, Select } from 'antd';

import * as R from 'ramda';
import fetchPost from '../../../common/apis/fetchPost';
import getEndpoint from '../../../common/apis/getEndpoint';
import { updateLastActionInfo } from '../../../common/app/actions';
import { Modal } from '../../../lib/fui/react';

import { settingsButtonMessages } from '../../../common/settings/messages';
import { appButtonsMessages, appFieldsMessages, appMessages } from '../../../common/app/messages';

type Props = {
  activeIncident: Object,
  companies: Array,
  onClose: Function,

  intl: Object,
  // eslint-disable-next-line
  userInfo: Object,
  // eslint-disable-next-line
  credentials: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  isAdmin: boolean,
};

class AddIDPMSTeamsModalCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    const { activeIncident } = props;
    this.state = {
      isSubmiting: false,
      errMsg: null,
      isVerify: false,
      verified: undefined,

      idpName: get(activeIncident, 'idpName', ''),
      clientId: get(activeIncident, 'clientId', ''),
      clientSecret: get(activeIncident, 'clientSecret', ''),
      company: get(activeIncident, 'company', ''),
      tenant: get(activeIncident, 'tenant', ''),
      emailDomain: get(activeIncident, 'emailDomain', ''),
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  @autobind
  handleSumbit() {
    const { intl, credentials, onClose } = this.props;
    const { idpName, clientId, clientSecret, company, tenant, emailDomain } = this.state;
    const settings = [
      {
        key: {
          identityProviderType: 'Teams',
          clientId,
        },
        idpName,
        clientSecret,
        company,
        tenant,
        emailDomain,
      },
    ];

    this.setState({ isSubmiting: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('idpsetting'), {
      ...credentials,
      identityProviderType: 'Teams',
      settings: JSON.stringify(settings),
    })
      .then((data) => {
        const { success, message: errMsg } = data || {};
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));
          this.setState({ isSubmiting: false });
          onClose(true);
        } else {
          message.error(intl.formatMessage(appMessages.apiFaild));
          this.setState({ isSubmiting: false, errMsg });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isSubmiting: false, errMsg: String(err) });
      });
  }

  @autobind
  handleVerify() {
    const { intl, credentials } = this.props;
    const { idpName, clientId, clientSecret, tenant, emailDomain } = this.state;
    const settings = [
      {
        key: {
          identityProviderType: 'Teams',
          clientId,
        },
        idpName,
        clientSecret,
        tenant,
        emailDomain,
      },
    ];

    this.setState({ isVerify: true });
    this.props.updateLastActionInfo();
    fetchPost(getEndpoint('idpSettingVerify'), {
      ...credentials,
      identityProviderType: 'Teams',
      settings: JSON.stringify(settings),
    })
      .then((data) => {
        const { success } = data || {};
        if (success) {
          this.setState({ isVerify: false, verified: true });
        } else {
          this.setState({ isVerify: false, verified: false });
        }
      })
      .catch((err) => {
        message.error(intl.formatMessage(appMessages.apiFaild));
        this.setState({ isVerify: false, verified: false });
      });
  }

  render() {
    const { intl, activeIncident, onClose, isAdmin, companies } = this.props;
    const { isSubmiting, errMsg, isVerify, verified, idpName, clientId, clientSecret, company, tenant, emailDomain } =
      this.state;
    const hasError =
      !idpName ||
      !clientId ||
      !clientSecret ||
      (!company && isAdmin) ||
      (!emailDomain && isAdmin) ||
      !verified ||
      !tenant;
    return (
      <Modal
        width={850}
        title={activeIncident ? activeIncident.idpName : intl.formatMessage(settingsButtonMessages.addIdentityProvider)}
        visible
        maskClosable={false}
        onCancel={() => onClose()}
        onOk={this.handleSumbit}
        okButtonProps={{ disabled: hasError, loading: isSubmiting }}
      >
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 12 }}>
          {isAdmin && (
            <Form.Item
              label="Company"
              validateStatus={!company ? 'error' : 'success'}
              help={!company ? 'This input is required!' : undefined}
              required
            >
              <Select
                showSearch
                value={company}
                onChange={(val) => {
                  this.setState({ company: val });
                }}
              >
                {R.map(
                  (item) => (
                    <Select.Option key={item} value={item} title={item}>
                      {item}
                    </Select.Option>
                  ),
                  companies || [],
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Global External Service Provider Name"
            validateStatus={!idpName ? 'error' : 'success'}
            help={!idpName ? 'This input is required!' : undefined}
            required
          >
            <Input value={idpName} onChange={(e) => this.setState({ idpName: e.target.value, verified: undefined })} />
          </Form.Item>
          <Form.Item
            label="Client Id"
            validateStatus={!clientId ? 'error' : 'success'}
            help={!clientId ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input
              value={clientId}
              onChange={(e) => this.setState({ clientId: e.target.value, verified: undefined })}
              disabled={Boolean(activeIncident)}
            />
          </Form.Item>
          <Form.Item
            label="Client Secret"
            validateStatus={!clientSecret ? 'error' : 'success'}
            help={!clientSecret ? 'This input is required!' : undefined}
            required
          >
            <Input.Password
              autoComplete="new-password"
              value={clientSecret}
              onChange={(e) => this.setState({ clientSecret: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="Tenant Id"
            validateStatus={!tenant ? 'error' : 'success'}
            help={!tenant ? intl.formatMessage(appFieldsMessages.inputRequired) : undefined}
            required
          >
            <Input value={tenant} onChange={(e) => this.setState({ tenant: e.target.value, verified: undefined })} />
          </Form.Item>
          {isAdmin && (
            <Form.Item
              label="Email Domain"
              validateStatus={!emailDomain ? 'error' : 'success'}
              help={!emailDomain ? 'This input is required!' : undefined}
              required
            >
              <Input
                value={emailDomain}
                onChange={(e) => this.setState({ emailDomain: e.target.value, verified: undefined })}
              />
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{
              xs: { span: 24, offset: 0 },
              sm: { span: 16, offset: 8 },
            }}
          >
            <div className="flex-row flex-center-align">
              <Button
                size="small"
                type="primary"
                style={{ marginRight: 16 }}
                loading={isVerify}
                onClick={this.handleVerify}
              >
                {intl.formatMessage(appButtonsMessages.verify)}
              </Button>
              {verified === true && <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 16 }} />}
              {verified === false && <CloseCircleOutlined style={{ color: '#f5222d', fontSize: 16 }} />}
            </div>
          </Form.Item>

          {errMsg && (
            <Form.Item
              wrapperCol={{
                xs: { span: 24, offset: 0 },
                sm: { span: 16, offset: 8 },
              }}
            >
              <Alert message={errMsg} type="error" showIcon />
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

const AddIDPMSTeamsModal = injectIntl(AddIDPMSTeamsModalCore);
export default connect(
  (state) => {
    const { userInfo, credentials } = state.auth;
    const { isAdmin } = state.auth.userInfo;
    return { userInfo, credentials, isAdmin };
  },
  { updateLastActionInfo },
)(AddIDPMSTeamsModal);
