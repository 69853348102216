import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { autobind } from 'core-decorators';
import { Button, Skeleton, Spin, message } from 'antd';

import { CaretDownOutlined, CaretUpOutlined, DoubleLeftOutlined } from '@ant-design/icons';
import fetchGet from '../../../common/apis/fetchGet';
import getEndpoint from '../../../common/apis/getEndpoint';
import { State } from '../../../common/types';
import { AutoSizer, CellMeasurer, CellMeasurerCache, Container, List, Popover, Tooltip } from '../../../lib/fui/react';
import { updateLastActionInfo } from '../../../common/app/actions';
import { CellRenderers, Defaults, LogParser } from '../../../common/utils';

import { logMessages } from '../../../common/log/messages';
import { appFieldsMessages } from '../../../common/app/messages';
import { eventMessages } from '../../../common/metric/messages';
import EventContextModal from '../../../../components/log/loganalysis/EventContextModal';

type Props = {
  // eslint-disable-next-line
  refresh: Number,
  // eslint-disable-next-line
  project: Object,
  // eslint-disable-next-line
  selectProjectName: String,
  // eslint-disable-next-line
  selectInstanceName: String,
  // eslint-disable-next-line
  selectStartTimestamp: Number,
  // eslint-disable-next-line
  selectEndTimestamp: Number,
  // eslint-disable-next-line
  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  updateLastActionInfo: Function,
  // eslint-disable-next-line
  credentials: Object,
  currentTheme: String,
  changeTotal: Function,
};

const expandKey = '__content_expand_state';
const expandKey1 = '__expanded_state__';

class MetricToLoaAnomalousCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);

    this.listHeaderHeight = 40;
    this.cellMeasureCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 60,
    });

    this.state = {
      eventList: [],
      eventListTotal: 0,

      isLoading: false,
      pageNo: 1,
      pageSize: 100,
      allExpand: true,
      sortBy: null,
      sortDirection: null,

      activeIncident: null,
      activeStartTimestamp: null,
      activeEndTimestamp: null,
      contextKeywordFilter: '',
      showContextModal: false,
    };

    this.LOADING_LAST_ROW = {
      eventType: 'normal',
      index: 999999999999,
      patternId: null,
      rawData: '',
      isLastRow: true,
    };
  }

  componentDidMount() {
    this.reloadData(this.props, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.refresh !== nextProps.refresh ||
      this.props.selectProjectName !== nextProps.selectProjectName ||
      this.props.selectInstanceName !== nextProps.selectInstanceName ||
      this.props.selectStartTimestamp !== nextProps.selectStartTimestamp ||
      this.props.selectEndTimestamp !== nextProps.selectEndTimestamp
    ) {
      this.setState({ eventList: [], eventListTotal: 0 }, () => {
        this.reloadData(nextProps, true);
      });
    }
  }

  @autobind
  reloadData(props, force) {
    const { updateLastActionInfo, credentials, project } = props;
    const { selectProjectName, selectInstanceName, selectStartTimestamp, selectEndTimestamp } = props;
    const { pageSize, allExpand, sortBy, sortDirection, eventList } = this.state;
    let { pageNo } = this.state;
    pageNo = force ? 1 : pageNo + 1;

    if (selectProjectName && selectInstanceName && selectStartTimestamp && selectEndTimestamp) {
      this.setState({ isLoading: true, pageNo });

      const day = moment.utc(selectStartTimestamp).format(Defaults.DateFormat);
      const startTimestamp = moment.utc(day, Defaults.DateFormat).valueOf();
      const endTimestamp = moment.utc(day, Defaults.DateFormat).endOf('day').valueOf();
      const startTimestampList = R.map(
        (i) => startTimestamp + i * 86400000,
        R.range(0, (endTimestamp + 1 - startTimestamp) / 86400000),
      );

      const requests = [];
      R.forEach((dayTimeMillis) => {
        requests.push(
          fetchGet(getEndpoint('fetchimportantlog'), {
            ...credentials,
            projectName: selectProjectName,
            instanceName: selectInstanceName,
            // pageNo,
            // pageSize,
            startTime: selectStartTimestamp,
            endTime: selectEndTimestamp,
            day: moment.utc(dayTimeMillis).format(Defaults.DateFormat),
          }),
        );
      }, startTimestampList);

      updateLastActionInfo();
      Promise.all(requests)
        .then((results) => {
          const sets = R.slice(0, Infinity, results);
          const { importantEvents, importantTotal } = this.fetchimportantlog(sets);
          let newAllEvents = R.map(
            (event) => ({
              ...event,
              isExpand: allExpand,
              [expandKey]: allExpand,
              [expandKey1]: allExpand,
              projectName: selectProjectName,
              instanceName: event.instanceName || selectInstanceName,
              user: project.owner,
              typeAndColor: LogParser.CalculateLogType(event),
              isAnomaly: true,
            }),
            importantEvents || [],
          );
          newAllEvents = this.sortData(newAllEvents, sortBy, sortDirection);
          let newEventList = this.sortData(eventList, sortBy, sortDirection);
          newEventList = force ? newAllEvents : [...newEventList, ...newAllEvents];

          this.cellMeasureCache.clearAll();
          if (this.listNode && !force) {
            this.listNode.forceUpdateGrid();
            setTimeout(() => {
              this.listNode.scrollToRow(eventList.length - 1);
              this.listNode.forceUpdateGrid();
            }, 0);
          }

          this.props.changeTotal(importantTotal);

          this.setState({ isLoading: false, eventList: newEventList, eventListTotal: importantTotal }, () => {
            this.cellMeasureCache.clearAll();
            if (this.listNode && force) this.listNode.forceUpdateGrid();
            if (this.listNode && !force) this.listNode.scrollToRow(eventList.length - 1);
          });
        })
        .catch((err) => {
          this.cellMeasureCache.clearAll();
          this.setState({ isLoading: false });
          message.error(err.message || String(err));
        });
    }
  }

  @autobind
  fetchimportantlog(results) {
    let list = [];
    let total = 0;
    R.forEach((data) => {
      const { eventArray } = data || {};
      R.forEach((event) => {
        const { timestampMap } = event || {};
        if (timestampMap) {
          total += R.reduce((acc, val) => acc + val, 0, R.values(timestampMap || {}));
        } else {
          total += 1;
        }
      }, eventArray || []);
      list = [...list, ...(eventArray || [])];
    }, results || []);
    return { importantTotal: total, importantEvents: list };
  }

  @autobind
  sortData(eventList, sortBy, sortDirection) {
    let sortList = eventList || [];

    // sort by
    let sortFunctions = [R.ascend(R.prop('timestamp'))];
    if (sortBy && sortDirection && sortDirection !== 'NA') {
      sortFunctions = sortDirection === 'DESC' ? [R.descend(R.prop(sortBy))] : [R.ascend(R.prop(sortBy))];
    }
    sortList = R.sortWith(sortFunctions)(sortList);
    return sortList;
  }

  @autobind
  headerClick(name) {
    return (e) => {
      e.stopPropagation();
      const { sortBy, sortDirection } = this.state;
      let sortDir = sortDirection === 'ASC' ? 'DESC' : sortDirection === 'DESC' ? 'NA' : 'ASC';
      if (name !== sortBy) {
        sortDir = 'ASC';
      }
      if (name) {
        this.setState({ sortBy: name, sortDirection: sortDir }, () => {
          const { eventList } = this.state;
          const sortList = this.sortData(eventList, name, sortDir);
          this.setState({ eventList: sortList });
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
        });
      }
    };
  }

  @autobind
  sortIcon(sortBy, sortDirection, name) {
    if (sortBy !== name || sortDirection === 'NA') {
      return null;
    }
    if (sortDirection === 'ASC') {
      return <CaretUpOutlined />;
    }
    return <CaretDownOutlined />;
  }

  @autobind
  handleAllExpand() {
    const { allExpand, eventList } = this.state;
    this.setState(
      {
        allExpand: !allExpand,
        eventList: R.map(
          (item) => ({ ...item, isExpand: !allExpand, [expandKey]: !allExpand, [expandKey1]: !allExpand }),
          eventList,
        ),
      },
      () => {
        window.setTimeout(() => {
          this.cellMeasureCache.clearAll();
          if (this.listNode) this.listNode.forceUpdateGrid();
          this.forceUpdate();
        }, 50);
      },
    );
  }

  @autobind
  renderTime(rowData) {
    const { intl } = this.props;
    const { timestamp, startTime, endTime: endTimestamp, timestampMap } = rowData;

    const startTimestamp = startTime || timestamp;
    const startDayTamp = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    const startTimeStrDay = moment.utc(startTimestamp).format(Defaults.ShortDayFormat);
    const startTimeStrTime = moment.utc(startTimestamp).format(Defaults.ShortTimeOnlyFormat);

    const endDayTamp = moment.utc(endTimestamp).format(Defaults.ShortDayFormat);
    const endTimeStr = moment.utc(endTimestamp).format(Defaults.ShortTimeOnlyFormat);
    const isSameDay = startDayTamp === endDayTamp;

    let timestampList = [];
    R.forEachObjIndexed((count, time) => {
      timestampList.push({ time, count });
    }, timestampMap || {});
    timestampList = R.sortWith([R.ascend(R.prop('time'))])(timestampList);

    const content = (
      <div className="flex-col" style={{ width: 110 - 12 }}>
        <div className="flex-row flex-center-align">
          <span className="flex-grow" style={{ textAlign: 'right' }}>
            {startTimeStrDay}
          </span>
          <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{startTimeStrTime}</span>
        </div>

        {isSameDay && (
          <div className="flex-row flex-center-align">
            <span className="flex-grow" style={{ textAlign: 'right' }} />
            <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStr}</span>
          </div>
        )}

        {!isSameDay && (
          <div className="flex-row flex-center-align">
            <span className="flex-grow" style={{ textAlign: 'right' }}>
              {endDayTamp}
            </span>
            <span style={{ display: 'inline-block', width: 52, textAlign: 'right' }}>{endTimeStr}</span>
          </div>
        )}
      </div>
    );

    if (timestampList.length > 0) {
      return (
        <Popover
          placement="right"
          content={
            <div style={{ maxHeight: 300, overflowY: 'auto', paddingRight: 10 }}>
              {R.map((item) => {
                return (
                  <div key={item.time} className="flex-row flex-center-align">
                    <div style={{ marginRight: 16, width: 130, flexShrink: 0 }}>
                      <span className="light-label bold" style={{ marginRight: 4 }}>
                        {intl.formatMessage(appFieldsMessages.time)}:
                      </span>
                      <span>{moment.utc(Number(item?.time || 0)).format(Defaults.ShortDateTimeFormat)}</span>
                    </div>
                    <div>
                      <span className="light-label bold" style={{ marginRight: 4 }}>
                        {intl.formatMessage(appFieldsMessages.count)}:
                      </span>
                      <span>{item.count}</span>
                    </div>
                  </div>
                );
              }, timestampList || [])}
            </div>
          }
        >
          {startTime && endTimestamp && <>{content}</>}
          {(!startTime || !endTimestamp) && <div>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</div>}
        </Popover>
      );
    } else {
      return (
        <>
          {startTime && endTimestamp && (
            <Popover
              placement="right"
              content={
                <div>
                  <span className="light-label bold" style={{ marginRight: 4 }}>
                    {intl.formatMessage(appFieldsMessages.startTime)}:
                  </span>
                  <span>
                    {startTimeStrDay} {startTimeStrTime}
                  </span>
                  <span className="light-label bold" style={{ marginRight: 4, marginLeft: 8 }}>
                    {intl.formatMessage(appFieldsMessages.endTime)}:
                  </span>
                  <span>
                    {endDayTamp} {endTimeStr}
                  </span>
                </div>
              }
            >
              {content}
            </Popover>
          )}
          {(!startTime || !endTimestamp) && <div>{moment.utc(timestamp).format(Defaults.ShortDateTimeFormat)}</div>}
        </>
      );
    }
  }

  @autobind
  loadingRendererWrapper(rowData, rowIndex) {
    const { isLoading } = this.state;
    const { isLastRow } = rowData;

    if (isLoading) {
      return (
        <div style={{ lineHeight: '40px', alignItems: 'center' }}>
          <Skeleton.Button size="small" active />
        </div>
      );
    } else if (isLastRow) {
      return (
        <a
          style={{ lineHeight: '40px', minWidth: 130 }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            this.setState({ sortBy: null, sortDirection: null }, () => {
              this.reloadData(this.props);
            });
          }}
        >
          Load more: +1 page
        </a>
      );
    }
    return this.renderTime(rowData);
  }

  @autobind
  renderType(rowData) {
    const { intl } = this.props;
    const { eventType, isLastRow } = rowData;

    if (isLastRow) {
      return <div />;
    }

    return (
      <div className="flex-row flex-wrap full-width">
        {CellRenderers.logShortTypeRenderer({ intl, type: eventType })}
      </div>
    );
  }

  @autobind
  logPatternIdRenderer(rowData) {
    const { typeAndColor, patternId, isLastRow } = rowData || {};

    if (isLastRow) {
      return <div />;
    }

    const pid = patternId;
    const { patternIdStr: msg } = Defaults.PatternIdNameStr({ patternId: pid }, {});
    const { color } = (typeAndColor && typeAndColor.length) > 0 ? typeAndColor[0] : 'gray';
    return (
      <div className="flex-row" style={{ height: '40px', alignItems: 'center' }}>
        <div className="flex-grow flex-min-width flex-row">
          <Tooltip placement="top" mouseEnterDelay={0.3} title={msg}>
            <span
              style={{
                color,
                lineHeight: '14px',
                height: 14,
                minWidth: 10,
                textAlign: 'center',
                border: `1px solid ${color}`,
                borderRadius: '20%',
                padding: '0 2px',
                cursor: 'default',
              }}
            >
              {pid}
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }

  @autobind
  onToggleCollapse() {
    this.cellMeasureCache.clearAll();
    if (this.listNode) this.listNode.forceUpdateGrid();
  }

  @autobind
  renderLogEntry(props) {
    const { intl, currentTheme } = this.props;
    const { eventList } = this.state;
    const { rowData } = props;
    props.cellData = rowData.rawData;
    props.style = {};

    if (rowData?.isLastRow) {
      return <div />;
    }

    return (
      <div style={{ width: '100%' }}>
        {CellRenderers.logContent(props, this.cellMeasureCache, {
          width: '90%',
          highlightWord: this.keywords,
          isCritical: false,
          onChanged: this.onToggleCollapse,
          showRawDataFrequency: false,
          frequencyStr: '',
          allParsedEventList: eventList,
          intl,
          summarySettings: [],
          enableJsonSummary: true,
          currentTheme,
          isCacheColumn: true,
          isKeywordQuery: true,
        })}
      </div>
    );
  }

  @autobind
  handleLogContextOneMinClick(rowData) {
    const { timestamp, startTime } = rowData;
    const activeStartTimestamp = timestamp ? timestamp - 9 * 60 * 1000 : startTime - 9 * 60 * 1000;
    const activeEndTimestamp = timestamp + 60 * 1000 || startTime + 60 * 1000;
    this.setState(
      {
        activeIncident: rowData,
        activeStartTimestamp,
        activeEndTimestamp,
        contextKeywordFilter: '',
      },
      () => {
        this.setState({ showContextModal: true });
      },
    );
  }

  @autobind
  renderControl(rowData) {
    const { intl } = this.props;
    const { eventType, isLastRow } = rowData;

    if (isLastRow) {
      return <div />;
    }

    return (
      <>
        {eventType !== 'normal' && (
          <Button size="small" onClick={() => this.handleLogContextOneMinClick(rowData)}>
            {intl.formatMessage(eventMessages.context)}
          </Button>
        )}
      </>
    );
  }

  @autobind
  renderListItem(events) {
    return (props: Object) => {
      const { key, index: rowIndex, style, parent } = props;
      const rowData = events[rowIndex];
      if (!rowData) return null;
      return (
        <CellMeasurer key={key} cache={this.cellMeasureCache} parent={parent} columnIndex={0} rowIndex={rowIndex}>
          <div className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`} style={{ ...style, minHeight: 40 }}>
            <div className="row-column" style={{ width: 110 }}>
              {this.loadingRendererWrapper(rowData, rowIndex)}
            </div>
            <div className="row-column" style={{ width: 60 }}>
              {this.renderType(rowData)}
            </div>
            <div className="row-column" style={{ width: 100 }}>
              {this.logPatternIdRenderer(rowData)}
            </div>
            <div className="row-column" style={{ width: 100, flex: 1 }}>
              {this.renderLogEntry({ ...props, rowData, dataKey: key, rowIndex })}
            </div>
            <div className="row-column" style={{ width: 70 }}>
              {this.renderControl(rowData)}
            </div>
          </div>
        </CellMeasurer>
      );
    };
  }

  render() {
    const { intl } = this.props;
    const { isLoading, sortBy, sortDirection, allExpand, eventListTotal } = this.state;
    let { eventList } = this.state;
    const needAddloading = eventListTotal !== 0 && eventList.length !== 0 && eventList.length < eventListTotal && false;

    if (needAddloading) eventList = [...eventList, this.LOADING_LAST_ROW];
    return (
      <Container className="full-width full-height flex-col">
        <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
          <div className="flex-grow flex-min-height flex-col ">
            <div className="flex-grow">
              <AutoSizer
                onResize={() => {
                  this.cellMeasureCache.clearAll();
                  if (this.listNode) this.listNode.forceUpdateGrid();
                  this.forceUpdate();
                }}
              >
                {({ width, height }) => (
                  <div className="event-list">
                    <div
                      className="event-list-header"
                      style={{
                        height: this.listHeaderHeight,
                        width,
                        paddingRight: this.listNodeHeaderScrollbar ? 17 : 0,
                      }}
                    >
                      <div className="header-column" style={{ width: 110 }} onClick={this.headerClick('timestamp')}>
                        <span>{intl.formatMessage(logMessages.dateTime)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'timestamp')}
                      </div>
                      <div className="header-column" style={{ width: 60 }} onClick={this.headerClick('eventType')}>
                        <span>{intl.formatMessage(appFieldsMessages.type)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'eventType')}
                      </div>
                      <div className="header-column" style={{ width: 100 }} onClick={this.headerClick('patternId')}>
                        <span>{intl.formatMessage(logMessages.patternId)}</span>
                        {this.sortIcon(sortBy, sortDirection, 'patternId')}
                      </div>
                      <div className="header-column" style={{ width: 100, flex: 1 }}>
                        {intl.formatMessage(eventMessages.shortDescription)}
                        <div onClick={this.handleAllExpand}>
                          <DoubleLeftOutlined rotate={allExpand ? 90 : -90} style={{ marginLeft: 4 }} />
                        </div>
                        <div className="flex-grow" />
                        <div style={{ marginRight: 30 }}>{`(${eventList.length - (needAddloading ? 1 : 0)})`}</div>
                      </div>
                    </div>
                    <List
                      className="event-list-grid corner-0-0-8-8"
                      ref={(listNode) => {
                        this.listNode = listNode;
                      }}
                      width={width}
                      height={height - this.listHeaderHeight}
                      rowCount={eventList.length}
                      overscanRowCount={4}
                      deferredMeasurementCache={this.cellMeasureCache}
                      rowHeight={this.cellMeasureCache.rowHeight}
                      rowRenderer={this.renderListItem(eventList)}
                      onScrollbarPresenceChange={({ horizontal, vertical }) => {
                        if (vertical) {
                          this.listNodeHeaderScrollbar = true;
                          this.cellMeasureCache.clearAll();
                          if (this.listNode) this.listNode.forceUpdateGrid();
                        } else {
                          this.listNodeHeaderScrollbar = false;
                        }
                        this.forceUpdate();
                      }}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
          </div>
        </Spin>
        {this.state.showContextModal && (
          <EventContextModal
            incident={this.state.activeIncident}
            projectName={this.props.selectProjectName}
            instanceName={this.props.selectInstanceName}
            startTimestamp={this.state.activeStartTimestamp}
            endTimestamp={this.state.activeEndTimestamp}
            keywordFilter={this.state.contextKeywordFilter}
            onClose={() => this.setState({ showContextModal: false })}
            useTimeRange
          />
        )}
      </Container>
    );
  }
}

const MetricToLoaAnomalous = injectIntl(MetricToLoaAnomalousCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { credentials } = state.auth;
    const { currentTheme } = state.app;

    return { location, credentials, currentTheme };
  },
  { updateLastActionInfo },
)(MetricToLoaAnomalous);
