/* @flow */
/*
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2018
 * *****************************************************************************
 */

import React from 'react';
import { autobind } from 'core-decorators';
import { get } from 'lodash';
import { connect } from 'react-redux';
import VLink from 'valuelink';
import { injectIntl } from 'react-intl';
import { message, Popover } from 'antd';

import { Modal, Input } from '../src/lib/fui/react';
import { buildLocation, getLoadStatus } from '../src/common/utils';
import { createLoadAction, showAppAlert } from '../src/common/app/actions';
import { ActionTypes, logoff } from '../src/common/auth/actions';
import { appFieldsMessages } from '../src/common/app/messages';
import { authMessages } from '../src/common/auth/messages';
import fetchPost from '../src/common/apis/fetchPost';
import getEndpoint from '../src/common/apis/getEndpoint';

type Props = {
  intl: Object,
  loadStatus: Object,
  createLoadAction: Function,
  credentials: Object,

  onClose: Function,

  showAppAlert: Function,
  logoff: Function,
};

class ChangePasswordModalCore extends React.Component {
  props: Props;

  constructor(props) {
    super(props);

    this.state = { password: '', newPassword: '', confirmPassword: '', passwordFocus: false, loading: false };
    this.submitLoader = 'auth_changepassword_submit';
    this.sumbitting = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { intl, loadStatus, onClose } = nextProps;
    const { isLoading } = getLoadStatus(get(loadStatus, this.submitLoader), intl);
    if (!isLoading && this.sumbitting) {
      this.sumbitting = false;
      onClose(true);
    }
  }

  @autobind
  handleClose() {
    const { onClose } = this.props;
    if (onClose) {
      onClose(false);
    }
  }

  @autobind
  handleSubmit() {
    const { createLoadAction, credentials, showAppAlert, logoff } = this.props;
    const { userName } = credentials;
    const { password, newPassword } = this.state;
    if ((password, newPassword)) {
      this.sumbitting = true;
      // createLoadAction(ActionTypes.CHANGE_PASSWORD, { userName, password, newPassword }, this.submitLoader);
      this.setState({ loading: true });
      fetchPost(getEndpoint('resetnewpassword'), {
        userName,
        password,
        newPassword,
      })
        .then((data) => {
          if (data.success) {
            logoff(credentials);
            showAppAlert('info', {
              defaultMessage: data.message,
              id: 'auth.info.changePassword',
            });
          } else {
            showAppAlert('error', {
              defaultMessage: data.message,
              id: 'auto.error.changePassword',
            });
          }
          this.setState({ loading: false });
        })
        .catch((e) => {
          message.error(e.message || String(e));
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const { intl, loadStatus, credentials } = this.props;
    const { userName } = credentials;
    const { loading } = this.state;
    const { isLoading, errorMessage } = getLoadStatus(get(loadStatus, this.submitLoader), intl);
    const passwordLink = VLink.state(this, 'password').check(
      (value) => value,
      intl.formatMessage(authMessages.errorsPasswordRequired),
    );
    const newpPasswordLink = VLink.state(this, 'newPassword')
      .check((value) => value, intl.formatMessage(authMessages.errorsPasswordRequired))
      .check((value) => value.length >= 8, intl.formatMessage(authMessages.errorsPasswordLength))
      .check((value) => value.match(/[0-9]/g), intl.formatMessage(authMessages.errorsPasswordNumber))
      .check((value) => value.match(/[A-Z]/g), intl.formatMessage(authMessages.errorsPasswordUppercase))
      .check((value) => value.match(/[a-z]/g), intl.formatMessage(authMessages.errorsPasswordLowercase))
      .check(
        (value) => value.match(/[~!@#$%^&*_+?:]/g),
        intl.formatMessage(authMessages.errorsPasswordSpecialCharacters),
      )
      .check(
        (value) => value.toLowerCase().indexOf(userName.toLowerCase()) === -1,
        intl.formatMessage(authMessages.errorsPasswordContain),
      );
    const confirmPasswordLink = VLink.state(this, 'confirmPassword')
      .check((value) => value, intl.formatMessage(authMessages.errorsPasswordRequired))
      .check((value) => value === this.state.newPassword, intl.formatMessage(authMessages.errorsPasswordNotMatch));
    const disabled = passwordLink.error || newpPasswordLink.error || confirmPasswordLink.error || loading;
    const hasError = Boolean(errorMessage);

    return (
      <Modal
        title={intl.formatMessage(appFieldsMessages.changePassword)}
        visible
        maskClosable={false}
        onCancel={this.handleClose}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled, loading }}
      >
        <div className="content" style={{ padding: '16px 8px' }}>
          <form className={`ui ${hasError ? 'error' : ''} form`}>
            <div className="input field required">
              <label>{intl.formatMessage(appFieldsMessages.password)}</label>
              <Input type="password" valueLink={passwordLink} icon="lock icon" />
            </div>
            <div className="input field required">
              <label>{intl.formatMessage(appFieldsMessages.newPassword)}</label>
              <Popover
                visible={this.state.passwordFocus}
                placement="right"
                title="Password requirements"
                content={
                  <div className="flex-col" style={{ width: 360 }}>
                    <div>{'Length >=8'}</div>
                    <div>Contains at least one lower case and one upper case</div>
                    <div>Contains at least one number and one special symbol (Examples: ~!@#$%^&*_+?:)</div>
                  </div>
                }
              >
                <Input
                  type="password"
                  valueLink={newpPasswordLink}
                  icon="lock icon"
                  onFocus={() => this.setState({ passwordFocus: true })}
                  onBlur={() => this.setState({ passwordFocus: false })}
                />
              </Popover>
            </div>
            <div className="input field required">
              <label>{intl.formatMessage(appFieldsMessages.confirmPassword)}</label>
              <Input type="password" valueLink={confirmPasswordLink} icon="lock icon" />
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const ChangePasswordModal = injectIntl(ChangePasswordModalCore);
export default connect(
  (state) => {
    const { credentials } = state.auth;
    return {
      loadStatus: state.app.loadStatus,
      credentials,
    };
  },
  { createLoadAction, showAppAlert, logoff },
)(ChangePasswordModal);
