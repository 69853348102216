/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 * */

import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { autobind } from 'core-decorators';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { Form, Button, message, Popconfirm, Spin, Select, Input, Switch, InputNumber } from 'antd';

import fetchGet from '../../../../common/apis/fetchGet';
import fetchPost from '../../../../common/apis/fetchPost';
import getEndpoint from '../../../../common/apis/getEndpoint';
// import { Defaults } from '../../../../common/utils';
import { ActionTypes, createSetAction, updateLastActionInfo } from '../../../../common/app/actions';
import { State } from '../../../../common/types';
import { Container, Modal, Popover } from '../../../../lib/fui/react';

import { appMessages, appFieldsMessages, appButtonsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { eventMessages } from '../../../../common/metric/messages';

type Props = {
  // eslint-disable-next-line
  activeSystemTab: String,
  systemId: String,

  intl: Object,
  // eslint-disable-next-line
  location: Object,
  // eslint-disable-next-line
  loadStatus: Object,
  // eslint-disable-next-line
  systemsMap: Object,
  credentials: Object,
  // eslint-disable-next-line
  userInfo: Object,
  updateLastActionInfo: Function,
  createSetAction: Function,
};

class SystemGeneralSettingCore extends React.PureComponent {
  props: Props;

  constructor(props) {
    super(props);
    const { systemId, systemsMap } = props;
    const systemInfo = systemsMap[systemId] || {};

    this.state = {
      isLoadingGlobalKnowledgeBase: false,
      isSubmittingGlobalKnowledgeBase: false,

      timelineTopK: 50,
      actionExecutionTime: 15,
      autoFixValidationWindow: 1,
      longTerm: systemInfo.longTerm || false,
    };
    this.localShareUserList = [];
    this.healthViewUpdatePeriodOption = [
      { label: 'Short', value: false },
      { label: 'Long', value: true },
    ];
  }

  componentDidMount() {
    this.reloadGlobalKnowledgeBase(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.systemId !== this.props.systemId) {
      const { systemId, systemsMap } = nextProps;
      const systemInfo = systemsMap[systemId] || {};
      this.setState({ longTerm: systemInfo.longTerm || false });
      this.reloadGlobalKnowledgeBase(nextProps);
    }
  }

  @autobind
  handleRefreshClick() {
    window.location.reload();
  }

  @autobind
  reloadGlobalKnowledgeBase(props) {
    const { intl, credentials, activeSystemTab, systemId, systemsMap } = props;

    if (activeSystemTab === 'general' && systemId) {
      this.setState({ isLoadingGlobalKnowledgeBase: true });
      const systemInfo = systemsMap[systemId] || {};
      this.props.updateLastActionInfo();

      const request = [
        fetchGet(getEndpoint('globalknowledgebasesetting'), {
          ...credentials,
          customerName: systemInfo.owner,
          systemIds: JSON.stringify([systemId]),
        }),
      ];

      Promise.all(request)
        .then((res) => {
          const [data] = res;
          const {
            timelineTopK,
            actionExecutionTime,
            autoFixValidationWindow,
            compositeValidThreshold = 0,
            enableGlobalKnowledgeBase = false,
          } = data[0] || {};

          this.setState({
            isLoadingGlobalKnowledgeBase: false,
            timelineTopK: timelineTopK || 50,
            actionExecutionTime,
            autoFixValidationWindow,
            compositeValidThreshold,
            enableGlobalKnowledgeBase,
          });
        })
        .catch((err) => {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
          this.setState({ isLoadingGlobalKnowledgeBase: false, timelineTopK: 0 });
        });
    }
  }

  @autobind
  handleUpdateGlobalKnowledgeBaseClick() {
    const { intl, credentials, systemId, systemsMap, createSetAction } = this.props;
    const {
      timelineTopK,
      actionExecutionTime,
      autoFixValidationWindow,
      compositeValidThreshold,
      enableGlobalKnowledgeBase,
      longTerm,
    } = this.state;
    if (timelineTopK === 0) {
      message.error('Top anomalous instance number limit value must > 0');
      return;
    }

    this.setState({ isSubmittingGlobalKnowledgeBase: true });
    const systemInfo = systemsMap[systemId] || {};

    const newSystemsMap = { ...systemsMap, [systemId]: { ...(systemsMap[systemId] || {}), longTerm } };
    const config = [
      {
        systemName: systemInfo?.systemId,
        environmentName: systemInfo?.environmentName,
        order: systemInfo?.order,
        longTerm,
      },
    ];

    this.props.updateLastActionInfo();

    const request = [
      fetchPost(getEndpoint('globalknowledgebasesetting'), {
        ...credentials,
        customerName: systemInfo.owner,
        settingModels: JSON.stringify([
          {
            systemId,
            timelineTopK,
            actionExecutionTime,
            autoFixValidationWindow,
            compositeValidThreshold,
            enableGlobalKnowledgeBase,
          },
        ]),
      }),
      fetchPost(
        getEndpoint('systemframework', 2),
        {
          ...credentials,
          operation: 'hideOrOrderOrLongTerm',
          customerName: systemInfo?.owner,
          config: JSON.stringify(config),
        },
        {},
        false,
      ),
    ];

    Promise.all(request)
      .then((res) => {
        const [data1, data2] = res || [];
        const success = (data1?.success || R.isNil(data1?.success)) && (data2?.success || R.isNil(data2?.success));
        const msg = data1?.message || data2?.message;
        if (success) {
          message.success(intl.formatMessage(appMessages.apiSuccess));

          createSetAction(ActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { systemsMap: newSystemsMap });

          this.reloadGlobalKnowledgeBase(this.props);

          this.setState({ isSubmittingGlobalKnowledgeBase: false });
        } else {
          message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${msg}`);
          this.setState({ isSubmittingGlobalKnowledgeBase: false });
        }
      })
      .catch((err) => {
        message.error(`${intl.formatMessage(appMessages.apiFaild)}. ${err.message || String(err)}`);
        createSetAction(ActionTypes.SET_INFO_SYSTEM_CONFIGS, {}, { systemsMap: newSystemsMap });
        this.setState({ isSubmittingGlobalKnowledgeBase: false });
      });
  }

  render() {
    const { intl, userInfo } = this.props;
    const { isLoadingGlobalKnowledgeBase, isSubmittingGlobalKnowledgeBase, longTerm } = this.state;
    const { timelineTopK, actionExecutionTime, autoFixValidationWindow } = this.state;
    const hasError = Number(timelineTopK) === 0 || !timelineTopK;

    return (
      <Container className={`full-height flex-min-width flex-min-height flex-col `}>
        <Spin spinning={isLoadingGlobalKnowledgeBase} wrapperClassName="full-height spin-full-height spin-overflow-y">
          <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
            <div className="light-label font-14">
              {intl.formatMessage(settingsMessages.TopAnomalousInstanceNumberLimit)}:
            </div>
            <InputNumber
              style={{ width: 180 }}
              value={timelineTopK}
              onChange={(timelineTopK) => this.setState({ timelineTopK })}
            />
          </div>
          <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
            <div className="light-label font-14">{intl.formatMessage(settingsMessages.healthViewUpdatePeriod)}:</div>
            <Select
              style={{ width: 180 }}
              value={longTerm}
              onChange={(longTerm) => this.setState({ longTerm })}
              options={this.healthViewUpdatePeriodOption}
            />
          </div>
          <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
            <div className="light-label font-14">{intl.formatMessage(settingsMessages.actionExecutionTime)}:</div>
            <InputNumber
              style={{ width: 180 }}
              min={0}
              value={actionExecutionTime}
              onChange={(actionExecutionTime) => this.setState({ actionExecutionTime: actionExecutionTime || 0 })}
            />
          </div>
          <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
            <div className="light-label font-14">{intl.formatMessage(settingsMessages.autoFixValidationWindow)}:</div>
            <InputNumber
              style={{ width: 180 }}
              min={0}
              value={autoFixValidationWindow}
              onChange={(autoFixValidationWindow) =>
                this.setState({ autoFixValidationWindow: autoFixValidationWindow || 0 })
              }
            />
          </div>
          <div>
            <Popover
              content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
            >
              <Button
                size="small"
                type="primary"
                disabled={hasError || userInfo.isReadUser}
                loading={isSubmittingGlobalKnowledgeBase}
                onClick={this.handleUpdateGlobalKnowledgeBaseClick}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </Popover>
          </div>
        </Spin>
      </Container>
    );
  }
}

const SystemGeneralSetting = injectIntl(SystemGeneralSettingCore);
export default connect(
  (state: State) => {
    const { location } = state.router;
    const { loadStatus, systemsMap } = state.app;
    const { credentials, userInfo } = state.auth;
    return { location, loadStatus, systemsMap, credentials, userInfo };
  },
  {
    updateLastActionInfo,
    createSetAction,
  },
)(SystemGeneralSetting);
