import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as R from 'ramda';
import { get, isInteger } from 'lodash';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, InputNumber, Pagination, Select, Spin, Tabs, Upload, Input, message, AutoComplete } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import fetchGet from '../../../../common/apis/fetchGet';
import getEndpoint from '../../../../common/apis/getEndpoint';
import fetchPostForm from '../../../../common/apis/fetchPostForm';
import { updateLastActionInfo } from '../../../../common/app/actions';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, Popover } from '../../../../lib/fui/react';

import { eventMessages } from '../../../../common/metric/messages';
import { appButtonsMessages, appFieldsMessages } from '../../../../common/app/messages';
import { settingsMessages } from '../../../../common/settings/messages';
import { EChart } from '../../../share';

const intervalUnit = [
  { label: 'Hours', value: 3600000 },
  { label: 'Days', value: 86400000 },
];

const cellMeasureCache = new CellMeasurerCache({ fixedWidth: true, minHeight: 40 });

function CumulativeDistributionFunctionCore(props: Object) {
  const { intl, credentials, projectName, userInfo, currentProject, fieldNameOptions } = props || {};
  const { currentLoadingComponents, handleRefreshClick, projects } = props || {};
  const { cdfSetting: localCdfSetting = {}, saveCFD, submitLoader, cdfSettings } = props || {};

  const cdfSettingKey = JSON.stringify(localCdfSetting);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useReducer((oldVal, newVal) => ({ ...oldVal, ...newVal }), {
    cdfSetting: {},
    isLoading: false,
    uploadLoading: false,

    activeKey: '1',
    page: 1,
    pageSize: 10,
    total: 0,
    referenceModelData: [],
    metricProjectOptions: [],
  });
  const listRef = useRef(null);
  const { cdfSetting, isLoading, uploadLoading, activeKey, page, pageSize } = state;
  const { referenceModelData, total, metricProjectOptions } = state;

  const parseItemData = (rowData) => {
    const { dataWithBuckets } = rowData || {};
    const seriesData = R.map((item) => item.value, R.sortWith([R.ascend(R.prop('level'))])(dataWithBuckets || []));
    const xAxisData = R.map((item) => item.label, R.sortWith([R.ascend(R.prop('level'))])(dataWithBuckets || []));
    const option = {
      color: ['#3878A1'],
      backgroundColor: 'transparent',
      xAxis: {
        type: 'category',
        data: xAxisData,
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: { textStyle: { color: 'gray' } },
      },
      yAxis: {
        type: 'value',
        splitLine: { show: false },
        splitArea: { show: false },
        axisLabel: { textStyle: { color: 'gray' } },
      },
      grid: { left: 50, right: 30, top: 30, bottom: 30 },
      // legend: { textStyle: { color: 'gray' } },
      tooltip: {
        backgroundColor: 'var(--component-background)',
        borderColor: 'transparent',
        trigger: 'axis',
        confine: true,
        appendToBody: true,
        formatter: (params, ticket, callback) => {
          return ReactDOMServer.renderToStaticMarkup(
            <>
              {R.addIndex(R.map)((item, idx) => {
                const { seriesName, color, value, name } = item;
                return (
                  <div key={idx}>
                    <div>{name}</div>
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: 5,
                        borderRadius: 10,
                        width: 9,
                        height: 9,
                        backgroundColor: color,
                      }}
                    />
                    {`${seriesName}: ${Number(Number.parseFloat(Math.abs(value || 0)).toFixed(2))}`}
                  </div>
                );
              }, params || [])}
            </>,
          );
        },
        textStyle: {
          color: 'var(--text-color)',
        },
      },
      series: [
        {
          name: 'Reference data',
          type: 'line',
          smooth: false,
          data: seriesData,
          lineStyle: { width: 3 },
          symbolSize: 6,
        },
      ],
    };
    return { ...rowData, option };
  };

  const getReferenceModel = ({ page, pageSize }) => {
    updateLastActionInfo();
    return fetchGet(getEndpoint('uploadcdfreferencemodel'), {
      ...credentials,
      projectName,
      pageNo: page,
      pageSize,
      scoreKey: localCdfSetting?.scoreKey,
    })
      .then((res) => {
        const { success, message: msg, totalCount, data } = res || {};
        let newReferenceModelData = [];
        if (success || success === undefined) {
          newReferenceModelData = R.map((item) => parseItemData(item), data || []);
        } else {
          message.error(msg);
        }
        return { totalCount: totalCount || 0, newReferenceModelData };
      })
      .catch((err) => {
        message.error(err.message || String(err));
        return { totalCount: 0, newReferenceModelData: [] };
      });
  };

  const handlecValue = (modelSpan) => {
    let intervalNum = modelSpan || 1;
    let intervalUtil = 86400000;

    if (isInteger(intervalNum / 86400000)) {
      intervalUtil = 86400000;
      intervalNum /= intervalUtil;
      return { intervalNum, intervalUtil };
    }

    if (isInteger(intervalNum / 3600000)) {
      intervalUtil = 3600000;
      intervalNum /= intervalUtil;
      return { intervalNum, intervalUtil };
    }

    return { intervalNum, intervalUtil };
  };

  const reloadData = async () => {
    const newPageMap = { page: 1, pageSize: 10 };
    setState({ isLoading: true, ...newPageMap });
    const logProject = R.find((item) => item.projectName === projectName, projects || []);
    const { owner } = logProject || {};

    const { totalCount, newReferenceModelData } = await getReferenceModel(newPageMap);

    let metricProjects = R.filter((item) => item.isMetric, projects || []);
    if (owner) metricProjects = R.filter((item) => item.owner === owner, metricProjects || []);
    const metricProjectOptions = R.map(
      (item) => ({
        value: item.projectShortName,
        label: `${item.projectDisplayName}${
          userInfo.isAdmin || userInfo.isLocalAdmin || item.owner !== userInfo.userName ? `@${item.owner}` : ''
        }`,
      }),
      metricProjects,
    );

    const newCdfSetting = {
      ...localCdfSetting,
      ...handlecValue(localCdfSetting?.modelSpan),
      buckets: R.values(R.clone(localCdfSetting.buckets || {})),
    };

    setState({
      cdfSetting: newCdfSetting,
      isLoading: false,
      referenceModelData: newReferenceModelData,
      total: totalCount,
      metricProjectOptions,
    });
  };

  useEffect(() => {
    reloadData();
  }, [cdfSettingKey]);

  const handleSave = () => {
    const { projectShortName, owner } = currentProject;
    const { intervalNum, intervalUtil, buckets, metricProjectName, ...rest } = cdfSetting || {};

    const logProject = R.find((item) => item.projectName === projectName, projects || []);
    const { owner: user } = logProject || {};
    let logProjects = R.filter((item) => !item.isMetric, projects || []);
    if (user) logProjects = R.filter((item) => item.owner === user, logProjects || []);
    const findProject = R.find((item) => item.projectShortName === metricProjectName, logProjects || []);
    if (findProject) {
      message.warning(`Project (${metricProjectName}) already exist!`);
      return;
    }

    const bucketMap = {};
    R.addIndex(R.forEach)((item, idx) => {
      bucketMap[idx] = item;
    }, buckets || []);

    const settings = {
      ...rest,
      modelSpan: intervalNum * intervalUtil,
      buckets: bucketMap,
      metricProjectName: metricProjectName || null,
    };

    saveCFD(projectName.indexOf('@') >= 0 ? projectName : `${projectShortName}@${owner}`, settings);
  };

  const selectRender = ({ valueKey }) => {
    return (
      <Select
        showSearch
        size="small"
        style={{ width: 500 }}
        className="full-width"
        value={cdfSetting?.[valueKey]}
        options={fieldNameOptions}
        onChange={(value) => {
          if (valueKey === 'scoreKey') {
            const findKey = R.find((item) => item.scoreKey === value, cdfSettings || []);
            if (findKey) {
              message.warning('Do not set duplicate score keys!');
              return;
            }
          }
          setState({ cdfSetting: { ...cdfSetting, [valueKey]: value } });
        }}
      />
    );
  };

  const inputNumberRender = ({ valueKey, defaultValue = 0, option = {} }) => {
    return (
      <InputNumber
        style={{ width: 500 }}
        value={cdfSetting?.[valueKey] || defaultValue}
        precision={0}
        onChange={(value) => setState({ cdfSetting: { ...cdfSetting, [valueKey]: value || defaultValue } })}
        {...option}
      />
    );
  };

  const handleUpload = (options) => {
    setState({ uploadLoading: true });
    const { file } = options || {};
    const fetchUrl = `uploadcdfreferencemodel?projectName=${projectName}`;
    const fd = new FormData();
    fd.append('data', file);
    fd.append('scoreKey', localCdfSetting?.scoreKey);
    fetchPostForm(getEndpoint(fetchUrl), fd)
      .then((data) => {
        const { success, message: msg } = data || {};
        if (success || success === undefined) {
          reloadData();
          handleRefreshClick();
          message.success(msg);
        } else {
          message.error(msg);
        }
        setState({ uploadLoading: false });
      })
      .catch((err) => {
        setState({ uploadLoading: false });
        message.error(err.message || String(err));
      });
  };

  const handleAddGroups = (index) => {
    const newGroups = R.insert(index + 1, '', cdfSetting?.selectedGroups || []);
    setState({ cdfSetting: { ...cdfSetting, selectedGroups: newGroups } });
  };

  const handleDeleteGroups = (index) => {
    const newGroups = R.addIndex(R.filter)((item, idx) => idx !== index, cdfSetting?.selectedGroups || []);
    setState({ cdfSetting: { ...cdfSetting, selectedGroups: newGroups } });
  };

  const handleAddBuckets = (index) => {
    let newBuckets = R.insert(index + 1, { min: 0, max: 0 }, cdfSetting?.buckets || []);
    newBuckets = R.addIndex(R.map)((item, idx) => {
      if (idx === 0) {
        return { ...item, max: item.max || 0 };
      }
      if (idx === newBuckets.length - 1) {
        return { ...item, min: item.min || 0 };
      }
      return { min: item.min || 0, max: item.max || 0 };
    }, newBuckets);
    cellMeasureCache.clearAll();
    if (listRef.current) listRef.current.forceUpdateGrid();
    setState({ cdfSetting: { ...cdfSetting, buckets: newBuckets } });
  };

  const handleDeleteBuckets = (index) => {
    const newBuckets = R.addIndex(R.filter)((item, idx) => idx !== index, cdfSetting?.buckets || []);
    cellMeasureCache.clearAll();
    if (listRef.current) listRef.current.forceUpdateGrid();
    setState({ cdfSetting: { ...cdfSetting, buckets: newBuckets } });
  };

  const changePagination = async (page, pageSize) => {
    setState({ isLoading: true, page, pageSize });
    const { totalCount, newReferenceModelData } = await getReferenceModel({ page, pageSize });
    setState({ isLoading: false, referenceModelData: newReferenceModelData, total: totalCount });
  };

  const intervalNumError = cdfSetting?.intervalNum <= 0;
  const hasGroupError =
    R.filter((item) => !!item, cdfSetting?.selectedGroups || []).length !== (cdfSetting?.selectedGroups || []).length;
  const hasBucketError = R.find((item) => {
    return (item.min || item.min === 0) && (item.max || item.max === 0) ? item.max <= item.min : false;
  }, cdfSetting?.buckets || []);
  const hasScoreKeyError = !cdfSetting?.scoreKey;
  const hasError = intervalNumError || hasBucketError || hasGroupError || hasScoreKeyError;
  const isSubmitting = get(currentLoadingComponents, submitLoader, false);

  return (
    <Spin spinning={isLoading} wrapperClassName="full-width full-height spin-full-height">
      <Tabs
        className="full-height ant-tabs-content-full-height"
        tabBarStyle={{ width: '100%', paddingLeft: 4 }}
        tabPosition="top"
        activeKey={activeKey}
        onChange={(activeKey) => setState({ activeKey })}
      >
        <Tabs.TabPane
          tab={intl.formatMessage(settingsMessages.setting)}
          key="1"
          className="overflow-y-auto"
          style={{ paddingLeft: 4 }}
        >
          <div className="flex-column flex-center-align" style={{ marginBottom: 24, marginTop: 20 }}>
            <div className="font-14">{intl.formatMessage(settingsMessages.metricProjects)}:</div>
            <AutoComplete
              allowClear
              size="small"
              value={cdfSetting.metricProjectName}
              options={metricProjectOptions}
              style={{ width: 500 }}
              onChange={(metricProjectName) => setState({ cdfSetting: { ...cdfSetting, metricProjectName } })}
              filterOption={(inputValue, option) =>
                (option?.value || '').toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1 ||
                (option?.label || '').toUpperCase().indexOf((inputValue || '').toUpperCase()) !== -1
              }
            />
          </div>
          <div className="flex-column flex-center-align" style={{ marginBottom: 24, marginTop: 20 }}>
            <div className="font-14">{intl.formatMessage(settingsMessages.groupNameKey)}:</div>
            {selectRender({ valueKey: 'groupNameKey' })}
          </div>
          <div className="flex-column flex-center-align" style={{ marginBottom: 24 }}>
            <div className="font-14">{intl.formatMessage(settingsMessages.scoreKey)}:</div>
            {selectRender({ valueKey: 'scoreKey' })}
          </div>
          <div className="flex-column flex-center-align" style={{ marginBottom: 24, width: 500 }}>
            <div className="flex-row flex-center-align">
              <div className="font-14">Selected groups:</div>
              {(cdfSetting?.selectedGroups || []).length === 0 && (
                <Button size="small" type="primary" style={{ marginLeft: 16 }} onClick={() => handleAddGroups(-1)}>
                  {intl.formatMessage(appButtonsMessages.add)}
                </Button>
              )}
            </div>
            <div className="flex-grow flex-min-height" style={{ marginTop: 10 }}>
              <>
                {R.addIndex(R.map)((x, idx) => {
                  const hasError = !x;
                  return (
                    <div className="flex-row" style={{ paddingTop: 4, paddingBottom: 4 }}>
                      <div className="row-column" style={{ flex: 1, paddingRight: 12 }}>
                        <Input
                          style={{ width: '100%' }}
                          value={x}
                          onChange={(value) => {
                            cdfSetting.selectedGroups[idx] = value.target.value;
                            setState({ cdfSetting: { ...cdfSetting } });
                          }}
                          className={`${hasError ? 'inputIsNil' : ''}`}
                        />
                      </div>
                      <div className="row-column" style={{ width: 120 }}>
                        <Button
                          size="small"
                          type="primary"
                          style={{ marginRight: 16 }}
                          onClick={() => handleAddGroups(idx)}
                        >
                          {intl.formatMessage(appButtonsMessages.add)}
                        </Button>
                        <Button size="small" type="primary" onClick={() => handleDeleteGroups(idx)}>
                          {intl.formatMessage(appButtonsMessages.delete)}
                        </Button>
                      </div>
                    </div>
                  );
                }, cdfSetting?.selectedGroups || [])}
              </>
            </div>
          </div>
          <div className="flex-column flex-center-align" style={{ marginBottom: 24, width: 500 }}>
            <div className="flex-row flex-center-align">
              <div className="font-14">Bucket descriptions:</div>
              {(cdfSetting?.buckets || []).length === 0 && (
                <Button size="small" type="primary" style={{ marginLeft: 16 }} onClick={() => handleAddBuckets(-1)}>
                  {intl.formatMessage(appButtonsMessages.add)}
                </Button>
              )}
            </div>
            <div className="flex-grow flex-min-height" style={{ marginTop: 10, height: 250 }}>
              <AutoSizer>
                {({ width, height }) => (
                  <div className="event-list">
                    <div className="event-list-header" style={{ height: 30, width }}>
                      <div className="header-column" style={{ flex: 1 }}>
                        {intl.formatMessage(appFieldsMessages.min)}
                      </div>
                      <div className="header-column" style={{ flex: 1 }}>
                        {intl.formatMessage(appFieldsMessages.max)}
                      </div>
                      <div className="header-column" style={{ width: 120 }} />
                    </div>
                    <List
                      className="event-list-grid"
                      ref={listRef}
                      width={width}
                      height={210}
                      rowCount={(cdfSetting?.buckets || []).length}
                      overscanRowCount={4}
                      rowHeight={cellMeasureCache.rowHeight}
                      rowRenderer={({ key, index: rowIndex, style, parent }) => {
                        const rowData = (cdfSetting?.buckets || [])[rowIndex];
                        if (!rowData) return null;
                        const { min, max } = rowData;
                        const hasError = (min || min === 0) && (max || max === 0) ? max <= min : false;
                        return (
                          <CellMeasurer
                            key={key}
                            cache={cellMeasureCache}
                            parent={parent}
                            columnIndex={0}
                            rowIndex={rowIndex}
                          >
                            <div
                              className={`event-list-row ${rowIndex % 2 === 1 ? ' odd-row' : ''}`}
                              style={{ ...style, paddingTop: 4, paddingBottom: 4 }}
                            >
                              <div className="row-column" style={{ flex: 1 }}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  value={min}
                                  onChange={(value) => {
                                    rowData.min = rowIndex === 0 ? value : value || 0;
                                    cellMeasureCache.clearAll();
                                    if (listRef.current) listRef.current.forceUpdateGrid();
                                    forceUpdate();
                                  }}
                                  className={`${hasError ? 'inputIsNil' : ''}`}
                                />
                              </div>
                              <div className="row-column" style={{ flex: 1 }}>
                                <InputNumber
                                  style={{ width: '100%' }}
                                  value={max}
                                  onChange={(value) => {
                                    rowData.max =
                                      rowIndex === (cdfSetting?.buckets || []).length - 1 ? value : value || 0;
                                    cellMeasureCache.clearAll();
                                    if (listRef.current) listRef.current.forceUpdateGrid();
                                    forceUpdate();
                                  }}
                                  className={`${hasError ? 'inputIsNil' : ''}`}
                                />
                              </div>
                              <div className="row-column" style={{ width: 120 }}>
                                <Button
                                  size="small"
                                  type="primary"
                                  style={{ marginRight: 16 }}
                                  onClick={() => handleAddBuckets(rowIndex)}
                                >
                                  {intl.formatMessage(appButtonsMessages.add)}
                                </Button>
                                <Button size="small" type="primary" onClick={() => handleDeleteBuckets(rowIndex)}>
                                  {intl.formatMessage(appButtonsMessages.delete)}
                                </Button>
                              </div>
                            </div>
                          </CellMeasurer>
                        );
                      }}
                    />
                  </div>
                )}
              </AutoSizer>
            </div>
            <div style={{ color: 'red' }}>{hasBucketError ? 'Minimum and Maximum conflict' : ''}</div>
          </div>
          <div className="flex-column flex-center-align" style={{ marginBottom: 60 }}>
            <div className="font-14">{intl.formatMessage(settingsMessages.samplingInterval)}:</div>
            <div>
              {inputNumberRender({
                valueKey: 'intervalNum',
                defaultValue: 1,
                option: { min: 1, style: { width: 392 }, className: intervalNumError ? 'inputIsNil' : '' },
              })}
              <Select
                style={{ width: 100, marginLeft: 8 }}
                options={intervalUnit}
                value={cdfSetting?.intervalUtil}
                onChange={(intervalUtil) => setState({ cdfSetting: { ...cdfSetting, intervalUtil } })}
              />
            </div>
          </div>
          <div style={{ position: 'fixed', bottom: 26 }}>
            <Popover
              content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
            >
              <Button
                size="small"
                type="primary"
                disabled={userInfo.isReadUser || hasError}
                onClick={handleSave}
                loading={isSubmitting}
              >
                {intl.formatMessage(appButtonsMessages.update)}
              </Button>
            </Popover>
            <Popover
              content={userInfo.isReadUser ? intl.formatMessage(eventMessages.isReadUserDisable) : null}
              mouseEnterDelay={0.3}
              placement="left"
            >
              <Upload showUploadList={false} maxCount={1} customRequest={handleUpload}>
                <Button
                  icon={<UploadOutlined />}
                  size="small"
                  type="primary"
                  style={{ marginLeft: 16 }}
                  loading={uploadLoading}
                >
                  Upload reference model
                </Button>
              </Upload>
            </Popover>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Reference models" key="2" className="ui form flex-col" style={{ paddingLeft: 4 }}>
          <div className="flex-grow overflow-y-auto">
            {R.addIndex(R.map)((item, idx) => {
              const { option, component, instance, container } = item;
              return (
                <div key={idx} className="flex-col" style={{ width: 700, height: 300, marginTop: 20 }}>
                  {component && (
                    <div>
                      {intl.formatMessage(appFieldsMessages.component)}: {component}
                    </div>
                  )}
                  {instance && (
                    <div style={{ marginTop: 8 }}>
                      {intl.formatMessage(appFieldsMessages.instance)}: {instance}
                    </div>
                  )}
                  {container && (
                    <div style={{ marginTop: 8 }}>
                      {intl.formatMessage(appFieldsMessages.container)}: {container}
                    </div>
                  )}
                  <div className="flex-grow" style={{ marginTop: 8 }}>
                    <EChart width="100%" height="100%" option={option} />
                  </div>
                </div>
              );
            }, referenceModelData || [])}
          </div>
          <div className="flex-row flex-end-justify" style={{ marginTop: 16, marginRight: 16 }}>
            <Pagination
              size="small"
              showTotal={(total, range) => `${range[0]}-${range[1]} / ${total}`}
              total={total}
              current={page}
              pageSize={pageSize}
              onChange={changePagination}
              showSizeChanger
              pageSizeOptions={['10', '20', '40', '60', '100', '500', '1000']}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Spin>
  );
}

const CumulativeDistributionFunction = injectIntl(CumulativeDistributionFunctionCore);
export default connect(
  (state: State) => {
    const { projects } = state.app;
    const { credentials, userInfo } = state.auth;

    return { credentials, userInfo, projects };
  },
  {
    updateLastActionInfo,
  },
)(CumulativeDistributionFunction);
