/* @flow */
/**
 * *****************************************************************************
 * copyright insightfinder inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';
import { round } from 'lodash';
import tinygradient from 'tinygradient';
import { colorMap } from '../../web/share';

/**
 * Calculate the background and color from the level.
 */

const CalcColorOfAnomalyScore = {
  calcColorOfHealthScore: (healthScore, score) => {
    if (healthScore && score && score !== healthScore) {
      const negativeScore = R.max(0, score);
      const colorPersent = round((healthScore - negativeScore) / healthScore, 2);
      const gradient = tinygradient([
        { color: '#ffffbf', pos: 0 },
        { color: '#fdae61', pos: 0.4 },
        { color: '#f46d43', pos: 0.6 },
        { color: '#a50026', pos: 1 },
      ]);
      return gradient.rgbAt(colorPersent).toString();
    }
    return colorMap.HealthBackground;
  },
  calcColorOfScoreRange: (score, healthScore, noneHealthScore) => {
    if (score && healthScore && score < healthScore) {
      const newScore = score - noneHealthScore;
      if (newScore < 0) return '#a50026';

      const newHealthScore = healthScore - noneHealthScore;
      const colorPersent = round((newHealthScore - newScore) / newHealthScore, 2);
      const gradient = tinygradient([
        { color: '#ffffbf', pos: 0 },
        { color: '#fdae61', pos: 0.4 },
        { color: '#f46d43', pos: 0.6 },
        { color: '#a50026', pos: 1 },
      ]);
      return gradient.rgbAt(colorPersent).toString();
    }
    return colorMap.HealthBackground;
  },
};

export default CalcColorOfAnomalyScore;
