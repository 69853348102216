/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchPost from './fetchPost';

const getCausalLogEvents = (credentials: Credentials, params: Object) => {
  const { customerName, ...rest } = params;

  // Use the time as the incident id.
  return fetchPost(getEndpoint('loadlogeventsforcausal'), {
    ...credentials,
    ...rest,
    customerName,
  }).then((d) => {
    const causalLogEvents = {};
    R.forEachObjIndexed((value, instanceId) => {
      causalLogEvents[instanceId] = R.reduce(R.concat, [], R.values(value));
    }, d || {});
    return causalLogEvents;
  });
};

export default getCausalLogEvents;
