/* @flow */
/**
 * *****************************************************************************
 * Copyright InsightFinder Inc., 2017
 * *****************************************************************************
 **/

import moment from 'moment';
import { get, isObject, isNumber } from 'lodash';
import * as R from 'ramda';

import type { Credentials } from '../types';
import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';
import { Defaults } from '../app';
import { parseJSON } from '../utils';
import { logPatternAnomalyToMap, logPatternTopKToArray } from './magicParsers';
import fetchPost from './fetchPost';
import removeNoLogEntry from './magicParsers/removeNoLogEntry';

const getLogStats = (credentials: Credentials, params: Object) => {
  const { projectName, instanceName, day } = params;
  const startTimeObj = moment.utc(day, Defaults.DateFormat).startOf('day');

  const requests = [];

  // Get frequency anomalies
  requests.push(
    fetchGet(getEndpoint('logfrequencyanomaly'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
    })
      .then((d) => {
        const derivedAnomalyString = get(d, ['data', 'derivedAnomalyString'], []);
        const totalFreqVector = get(d, ['data', 'globalFreqVector'], {});
        const totalFreqVectorNidMap = get(d, ['data', 'globalFreqVectorNidMap'], {});
        const freqVector = R.map((x) => parseJSON(x), get(d, ['data', 'freqVector'], {}));
        const frequencyMap = logPatternAnomalyToMap(derivedAnomalyString);
        return { freqVector, frequencyMap, totalFreqVector, totalFreqVectorNidMap };
      })
      .catch((e) => {
        console.error('[IF_API]', e);
        return { frequencyMap: {}, totalFreqVector: {}, totalFreqVectorNidMap: {} };
      }),
  );

  requests.push(
    fetchGet(getEndpoint('logstreamingevent'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
      isRare: true,
    })
      .then((d) => {
        const rareEntries = R.filter((e) => e.timestamp, removeNoLogEntry(get(d, 'eventArray', [])));
        return { rareEntries };
      })
      .catch((e) => {
        console.error('[IF_API]', e);
        return { rareEntries: [] };
      }),
  );

  requests.push(
    fetchGet(getEndpoint('logcriticalkeywordevent'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
    })
      .then((d) => {
        const wlist = [];
        R.forEach((el) => {
          const { criticalWord } = el;
          if (criticalWord) {
            R.forEach((ev) => {
              const { rawData, timestamp } = ev;
              wlist.push({
                ...ev,
                criticalWord,
                keyword: criticalWord,
                datetime: timestamp,
                timestamp,
                rawData,
                eventType: 'criticalWord',
              });
            }, el.events || []);
          } else {
            console.warn('[IF_API] logcriticalkeywordevent entry has no criticalWord entry');
          }
        }, d || []);
        const criticalKeywordEntries = R.sort((a, b) => a.timestamp - b.timestamp, wlist);
        return criticalKeywordEntries;
      })
      .catch((err) => {
        console.warn('[IF_API] get critical keyword event failed, ignored', err);
        return [];
      }),
  );

  requests.push(
    fetchGet(getEndpoint('logstreaminglistpatterns'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
    })
      .then((d) => {
        let patterns = get(d, 'patternArray', []);
        let maxEventsCount = 0;
        patterns = R.map((p) => {
          // Convert the topK string into array.
          const keywords = logPatternTopKToArray(p.topK);
          let featureKeywords = [];
          R.forEachObjIndexed((vals, featureKey) => {
            const categorys = vals.length;
            R.forEach((val) => {
              const value = R.split('(', val)[0];
              const count = Number(R.split(')', R.split('(', val)[1])[0]);
              featureKeywords.push({
                featureKey,
                categorys,
                valueStr: val,
                value,
                count,
              });
            }, vals);
          }, p.featureMap || {});
          featureKeywords = R.sortWith([R.ascend(R.prop('categorys')), R.descend(R.prop('count'))], featureKeywords);
          const featureK = R.join(', ', R.map((keyword) => keyword.valueStr, featureKeywords));
          const featureKeywordsList = R.take(3, R.map((keyword) => keyword.value, featureKeywords));

          maxEventsCount = Math.max(maxEventsCount, p.count);

          const sampleMsgContent = get(p, ['sampleMsg'], [])[0] || '';
          let msgName = sampleMsgContent;
          let mjson;
          try {
            mjson = JSON.parse(sampleMsgContent);
          } catch (e) {}

          if (mjson && mjson.msg) {
            msgName = mjson.msg;
          }

          const name = p.patternName === `Pattern ${p.nid}` ? '' : p.patternName;
          return {
            ...p,
            keywords,
            keywordsStr: R.join('-', featureKeywordsList.length > 0 ? featureKeywordsList : keywords),
            featureKeywords,
            featureK,
            name,
            msgName: msgName || name,
            sampleMsgContent,
            patternName: p.patternName,
            eventsCount: p.count,
          };
        }, patterns);
        patterns = R.sort((a, b) => b.eventsCount - a.eventsCount, patterns);

        // const totalCount = R.reduce((acc, p) => acc + p.eventsCount, 0, patterns);
        R.forEach((p) => (p.countRatio = p.eventsCount / maxEventsCount), patterns);
        const clusterInfoList = patterns;
        return clusterInfoList;
      })
      .catch((err) => {
        console.warn('[IF_API] get pattern list failed, ignored', err);
        return [];
      }),
  );

  requests.push(
    fetchGet(getEndpoint('logpatternsequencelist'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
    })
      .then((d) => {
        const sequences = get(d, 'data', []);
        let sequenceList = [];
        let maxSequencesCount = 0;

        R.forEach((seq) => {
          const { count: seqencesCount, pattern: id, hasPatternName, patternInfoList } = seq;
          maxSequencesCount = Math.max(maxSequencesCount, seqencesCount);

          if (id) {
            let seqKeywords = [];
            const seqNameWords = [];
            const patternsInfo = R.filter((p) => isObject(p), patternInfoList || []);

            R.forEach((p) => {
              const { nid, patternName } = p;
              const keywords = logPatternTopKToArray(p.topK);
              let name = patternName;
              if (name === `Pattern ${nid}`) {
                seqNameWords.push(keywords[0] || name);
                name = keywords.join('-') || name;
              }
              seqKeywords = seqKeywords.concat(keywords);
            }, patternsInfo);

            sequenceList.push({
              id,
              patterns: seq.pattern,
              isSequence: true,
              name: hasPatternName ? seq.patternName : seqNameWords.join('-'),
              keywords: R.uniq(seqKeywords),
              seqencesCount,
            });
          }
        }, sequences);

        sequenceList = R.sort((a, b) => b.seqencesCount - a.seqencesCount, sequenceList);
        // const totalCount = R.reduce((acc, i) => acc + i.seqencesCount, 0, sequenceList);
        R.forEach((p) => (p.countRatio = p.seqencesCount / maxSequencesCount), sequenceList);
        const sequenceInfoList = sequenceList;
        return sequenceInfoList;
      })
      .catch((err) => {
        console.warn('[IF_API] get sequence list failed, ignored', err);
        return [];
      }),
  );

  requests.push(
    fetchGet(getEndpoint('anomalylogpatternsequencelist'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
    })
      .then((d) => {
        const sequences = get(d, 'data', []);
        let sequenceList = [];
        let maxSequencesCount = 0;

        R.forEach((seq) => {
          const { count: seqencesCount, pattern: id, hasPatternName, patternInfoList } = seq;
          maxSequencesCount = Math.max(maxSequencesCount, seqencesCount);

          if (id) {
            let seqKeywords = [];
            const seqNameWords = [];
            const patternsInfo = R.filter((p) => isObject(p), patternInfoList || []);

            R.forEach((p) => {
              const { nid, patternName } = p;
              const keywords = logPatternTopKToArray(p.topK);
              let name = patternName;
              if (name === `Pattern ${nid}`) {
                seqNameWords.push(keywords[0] || name);
                name = keywords.join('-') || name;
              }
              seqKeywords = seqKeywords.concat(keywords);
            }, patternsInfo);

            sequenceList.push({
              id,
              patterns: seq.pattern,
              isSequence: true,
              name: hasPatternName ? seq.patternName : seqNameWords.join('-'),
              keywords: R.uniq(seqKeywords),
              seqencesCount,
            });
          }
        }, sequences);

        sequenceList = R.sort((a, b) => b.seqencesCount - a.seqencesCount, sequenceList);
        // const totalCount = R.reduce((acc, i) => acc + i.seqencesCount, 0, sequenceList);
        R.forEach((p) => (p.countRatio = p.seqencesCount / maxSequencesCount), sequenceList);
        const anomalySequenceInfoList = sequenceList;
        return anomalySequenceInfoList;
      })
      .catch((err) => {
        console.warn('[IF_API] get anomaly sequence list failed, ignored', err);
        return [];
      }),
  );
  requests.push(
    fetchPost(getEndpoint('fetchglobalmappinginfo'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
      dayString: day,
    })
      .then((d) => {
        return d || {};
      })
      .catch((err) => {
        console.warn('[IF_API] get mapping info list failed, ignored', err);
        return {};
      }),
  );

  requests.push(
    fetchGet(getEndpoint('getmisclusterinfo'), {
      ...credentials,
      projectName,
      instanceName,
      dayTimeMillis: startTimeObj.valueOf(),
    })
      .then((d) => {
        return d || {};
      })
      .catch((err) => {
        console.warn('[IF_API] get miscluster info failed, ignored', err);
        return {};
      }),
  );

  return Promise.all(requests).then((results) => {
    const { frequencyMap, totalFreqVector, freqVector, totalFreqVectorNidMap } = results[0];
    const rareEntries = R.map(
      (r) => ({ ...r, isRare: true, datetime: r.timestamp, type: 'Rare' }),
      get(results[1], 'rareEntries', []),
    );
    const criticalKeywordEntries = results[2] || [];
    const clusterInfoList = results[3] || [];
    // Ignore keyword count now
    // const { keywordInfoList, logKeywordFrequency, keywordTotalCount } = results[4] || {};
    const { keywordInfoList, logKeywordFrequency } = {};
    const sequenceInfoList = results[4] || [];
    const anomalySequenceInfoList = results[5] || [];
    const mappingInfo = results[6] || {};
    const misClusterInfo = results[7] || {};

    const { clusterData: misClusterData } = misClusterInfo;
    const mappingInfoList = [];
    const clusterMappingWordMap = {};
    R.forEachObjIndexed((patterns, mapping) => {
      R.forEachObjIndexed((values, nid) => {
        nid = Number(nid);
        if (isNumber(nid)) {
          clusterMappingWordMap[nid] = [...(clusterMappingWordMap[nid] || []), mapping];
          mappingInfoList.push(nid);
        }
      }, patterns || {});
    }, mappingInfo);

    let totalCount = 0;
    let hotPeriods = 0;
    let coldPeriods = 0;
    const rareEventCount = rareEntries.length;
    const criticalKeywordCount = criticalKeywordEntries.length;

    R.forEachObjIndexed((val) => (totalCount += val), totalFreqVector);

    R.forEachObjIndexed((val) => {
      const vlist = R.values(val || {});
      hotPeriods += R.filter((v) => v.isHot, vlist).length;
      coldPeriods += R.filter((v) => v.isCold, vlist).length;
    }, frequencyMap || {});

    R.forEach((c) => {
      const hots = [];
      const colds = [];
      const as = get(frequencyMap, [c.nid], {});
      R.forEachObjIndexed((i, ts) => {
        if (i.isHot) hots.push(ts);
        if (i.isCold) colds.push(ts);
      }, as);
      if (hots.length > 0) c.hasHot = true;
      if (colds.length > 0) c.hasCold = true;
      if (mappingInfoList.indexOf(c.nid) >= 0) {
        c.clusterMappingWordList = clusterMappingWordMap[c.nid];
        c.hasMappingInfo = true;
      }
    }, clusterInfoList);

    const patternFreqVector = {};
    R.forEachObjIndexed((val, nid) => {
      const fvector = {};
      R.forEach((v) => {
        fvector[v.timestamp] = v.count;
      }, val || []);
      patternFreqVector[nid] = fvector;
    }, freqVector);

    return {
      instanceName,
      frequencyMap,
      totalFreqVector,
      totalFreqVectorNidMap,
      patternFreqVector,
      rareEntries,
      criticalKeywordEntries,
      clusterInfoList,
      misClusterData,
      keywordInfoList,
      keywordTotalCount: null,
      logKeywordFrequency,
      sequenceInfoList,
      anomalySequenceInfoList,
      stats: {
        totalCount,
        hotPeriods,
        coldPeriods,
        rareEventCount,
        criticalKeywordCount,
      },
    };
  });
};

export default getLogStats;
