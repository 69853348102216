import getEndpoint from './getEndpoint';
import fetchGet from './fetchGet';

const getUserMissingInfo = (credentials) => {
  return fetchGet(getEndpoint('get-missing-info'), {
    ...credentials,
  })
    .then((data) => {
      return data || {};
    })
    .catch((err) => {
      console.error('[IF_API] Failed get user missing info', err);
      return {};
    });
};

export default getUserMissingInfo;
