/* @flow */
/* eslint-disable no-console */

import * as R from 'ramda';
import type { UseCaseState, Action } from '../types';

const initialState: UseCaseState = {
  opensourceSystemNames: ['Cassandra', 'Hadoop', 'Apache', 'Tomcat', 'MySQL', 'HDFS', 'Spark', 'Lighttpd', 'Memcached'],
  bugRepository: {
    openSource: {},
    custom: {},
  },
};

const reducer = (state: UseCaseState = initialState, action: Action): UseCaseState => {
  if (action.type === 'SET_BUG_DETAILS') {
    const { bugDetails } = action.payload.data;
    return {
      ...state,
      bugDetails,
    };
  } else if (action.type === 'SET_BUG_REPOSITORY') {
    const { bugRepository } = action.payload;
    return {
      ...state,
      bugRepository,
    };
  } else if (action.type === 'UPDATE_BUG_REPOSITORY') {
    const { pdKey, ...props } = action.payload.params;
    const { bugRepository } = state;

    // Find the isuses in the repository
    let found = false;
    R.forEachObjIndexed((sgroup) => {
      R.forEachObjIndexed((issues) => {
        const issue = R.find((i) => i.pdKey === pdKey, issues);
        if (issue) {
          found = true;
          issue.metaData = { ...issue.metaData, ...props };
        }
      }, sgroup);
    }, bugRepository);
    return { ...state, bugRepository: found ? { ...bugRepository } : bugRepository };
  }

  return { ...initialState, ...state };
};

export default reducer;
